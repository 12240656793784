import Spinner from "components/Spinner/Spinner"

export interface IDisplayLoading {
  isLoading: boolean
}
export default function DisplayLoading(props: IDisplayLoading) {
  const { isLoading } = props
  return (
    <>
      {isLoading ? (
        <div className="pt-2 flex items-center justify-center">
          <Spinner />
        </div>
      ) : null}
    </>
  )
}
