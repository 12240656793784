import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import ButtonIcon from "components/Button/ButtonIcon"
import useModal from "components/Modal/useModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useCreateCopromoter,
  useListCopromoterAssociates,
} from "core/query-hooks/useCopromoters"
import i18next, { t } from "i18next"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { MdAdd } from "react-icons/md"
import { toast } from "react-toastify"
import { TMutateCopromoter } from "shared/types/copromoter.type"
import * as yup from "yup"
import { AddCopromoterAssociateModal } from "./AddCopromoterAssociateModal"

export interface ICreateCopromoterProps {
  operationId: number
}

type FormValues = {
  operationId: number
  copromoterAssociateId: number
  contactName?: string | null
  contactPhone?: string | null
  contactEmail?: string | null
}

export function CreateCopromoter(props: ICreateCopromoterProps) {
  const { operationId } = props
  const queryClient = useQueryClient()
  const createCopromoter = useCreateCopromoter()
  const { data: copromoterAssociates } = useListCopromoterAssociates()
  const [copromoterAssociate, setCopromoterAssociate] = useState<number>(-1)
  const {
    isShowing: isAddCopromoterAssociateModalShowed,
    toggle: toggleCopromoterAssociateModal,
  } = useModal()

  const validationSchema = yup.object().shape({
    operationId: yup.number().required(),
    copromoterAssociateId: yup.number().required().positive(),
    contactName: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    contactPhone: yup
      .string()
      .nullable()
      .matches(/^0[1-9]\d{8}$/, () => i18next.t("invalid-phone-format"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
    contactEmail: yup
      .string()
      .nullable()
      .email(() => i18next.t("invalid-email-format"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
  })

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      operationId,
    },
  })

  const submitForm = (data: TMutateCopromoter) => {
    createCopromoter.mutate(data, {
      onSuccess() {
        toast.success(`${t("copromoter-added-success")}`)
        queryClient.invalidateQueries({
          queryKey: ["listCopromotersByOperation", operationId],
        })
        reset()
        setCopromoterAssociate(-1)
      },
      onError: (err) => {
        toast.error(
          `${t("copromoter-added-error")} : ${err.response?.data.message}`,
        )
      },
    })
  }

  useEffect(() => {
    setValue("copromoterAssociateId", copromoterAssociate)
  }, [copromoterAssociate, setValue])

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mt-3 mb-4">
        <div className="Text__Field_Container lg:col-span-2">
          <label htmlFor="select-copromoter-associate">
            {`${t("associate-name")}`} *
          </label>
          <div className="flex items-center justify-between">
            <select
              className="Select__Field Text__Field cursor-pointer Select__Wrapper"
              value={copromoterAssociate}
              onChange={(e) => setCopromoterAssociate(Number(e.target.value))}
              name="select-copromoter-associate"
              id="select-copromoter-associate"
            >
              <option key="no-option-copromoter-associate" value={-1} disabled>
                {t("select-copromoter-associate")}
              </option>
              {copromoterAssociates?.map((ca) => (
                <option key={`${ca.id}-${ca.label}`} value={ca.id}>
                  {ca.label}
                </option>
              ))}
            </select>
            <PermissionsGuard requiredRoles={["create:copromoter-associate"]}>
              <ButtonIcon
                className="ml-2 -mt-1"
                type="button"
                onClick={toggleCopromoterAssociateModal}
              >
                <MdAdd size={22} cursor="pointer" className="text-cyan-800" />
              </ButtonIcon>
              {isAddCopromoterAssociateModalShowed && (
                <AddCopromoterAssociateModal
                  isAddCopromoterAssociateModalShowed={
                    isAddCopromoterAssociateModalShowed
                  }
                  toggleCopromoterAssociateModal={
                    toggleCopromoterAssociateModal
                  }
                  setCopromoterAssociate={setCopromoterAssociate}
                />
              )}
            </PermissionsGuard>
          </div>
        </div>

        <div>
          <div className="Text__Field_Container">
            <label htmlFor="contact-name">
              {`${t("contact")}`}
              <input
                id="contact-name"
                className="Text__Field"
                {...register("contactName")}
              />
            </label>
          </div>
          {errors.contactName && (
            <span className="text-red-500 text-xs">
              {errors.contactName.message}
            </span>
          )}
        </div>

        <div>
          <div className="Text__Field_Container">
            <label htmlFor="contact-phone">
              {`${t("phone")}`}
              <input
                id="contact-phone"
                className="Text__Field"
                {...register("contactPhone")}
              />
            </label>
          </div>
          {errors.contactPhone && (
            <span className="text-red-500 text-xs">
              {errors.contactPhone.message}
            </span>
          )}
        </div>

        <div>
          <div className="Text__Field_Container">
            <label htmlFor="contact-mail">
              {`${t("email")}`}
              <input
                id="contact-mail"
                className="Text__Field"
                {...register("contactEmail")}
              />
            </label>
          </div>
          {errors.contactEmail && (
            <span className="text-red-500 text-xs">
              {errors.contactEmail.message}
            </span>
          )}
        </div>

        <div className="flex items-center pt-4">
          <Button type="submit" size="small" mode="primary">
            {t("create")}
          </Button>
        </div>
      </div>
    </form>
  )
}
