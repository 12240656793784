import DropdownFilters from "features/programs/DropdownFilter"
import { t } from "i18next"
import { TLabelStringValue } from "shared/types/global.type"
import PRODUCT_TYPES from "../resources/products.resources"

export interface IProductsFiltersProps {
  selectedProducts: string[] | []
  onProductsChange: (selectedProduct: string[] | []) => void
}

export default function ProductsFilter(props: IProductsFiltersProps) {
  const { selectedProducts, onProductsChange } = props
  const productsOptions = PRODUCT_TYPES

  const handleProductClick = (product: TLabelStringValue) => {
    const isSelected = ((selectedProducts as string[]) ?? []).includes(
      product.value,
    )

    const updatedProducts = isSelected
      ? selectedProducts.filter(
          (currentProduct) => currentProduct !== product.value,
        )
      : [...selectedProducts, product.value]

    onProductsChange(updatedProducts)
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900">
              {t("products")}
              {selectedProducts.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-purple-800 bg-purple-100 rounded-full">
                  {selectedProducts.length}
                </div>
              )}
            </div>
          )}
          options={productsOptions}
          renderOption={(product, index) => (
            <li
              onClick={() => handleProductClick(product as TLabelStringValue)}
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              tabIndex={-1}
              id={`menu-item-${index}`}
              key={`menu-item-${index}`}
            >
              <input
                className="mr-2"
                type="checkbox"
                readOnly
                checked={(selectedProducts as string[])?.includes(
                  product.value,
                )}
              />
              {t(product.label)}
            </li>
          )}
          keyExtractor={(product) => product.value}
        />
      </div>
    </div>
  )
}
