import classNames from "classnames"
import "./EcoRangeSlide.scss"

export interface EcoRangeSlideProps {
  value: number // valeur entre 0 et 1 avec 2 décimales
}

export function EcoRangeSlide(props: EcoRangeSlideProps) {
  const { value } = props
  const percentage = (value * 100).toFixed(0)
  let textColor = ""

  switch (true) {
    case value >= 0.6:
      textColor = "text-green-600"
      break
    case value >= 0.3:
      textColor = "text-amber-500"
      break
    default:
      textColor = "text-red-500"
  }

  return (
    <div className="flex items-center">
      <input
        type="range"
        value={percentage}
        min={0}
        max={100}
        step={1}
        className={classNames(
          value === 1 ? "slider max-value" : "",
          value === 0 ? "slider min-value" : "",
        )}
        disabled
      />

      <div className={`font-semibold text-lg ${textColor} pl-2`}>{value}</div>
    </div>
  )
}
