import classNames from "classnames"
import DateFormat from "components/Date/DateFormat"
import Committee from "components/Icons/Committee"
import useModal from "components/Modal/useModal"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import { nanoid } from "nanoid"
import { Fragment } from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { TAction } from "shared/types/action.type"
import useIsMobile from "utils/isMobile"
import ActionAvatarCell from "./ActionAvatarCell"
import ModalEditAction from "./ModalEditAction"

registerLocale("fr", fr)

interface IActionRowProps {
  action: TAction
  border: boolean
  isOperation?: boolean
}

export default function ActionRow({
  action,
  border,
  isOperation = false,
}: IActionRowProps) {
  const descriptionLines = action.description.split("\\n")
  const isMobile = useIsMobile()
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  return (
    <>
      <td
        onClick={toggleModal}
        className={classNames("Table__TD cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
        })}
      >
        <span
          className={classNames({
            "opacity-60": action.isDefinitive,
          })}
        >
          {format(new Date(action.createdAt), "dd/MM/yyyy")}
        </span>
      </td>
      <td
        onClick={toggleModal}
        className={classNames("Table__TD w-100 cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
          Table__Sticky_Column: !isMobile,
        })}
      >
        <span
          className={classNames({
            "opacity-60": action.isDefinitive,
          })}
        >
          <div className="flex items-center">
            {action.isCopil && (
              <div className="pr-3">
                <Committee width={20} height={20} color="#B2C229" />
              </div>
            )}
            <button type="button" className="text-left">
              {descriptionLines.map((ligne, index) => (
                <Fragment key={nanoid()}>
                  {ligne}
                  {index !== descriptionLines.length - 1 && <br />}
                </Fragment>
              ))}
            </button>
          </div>
        </span>
      </td>
      <td
        onClick={toggleModal}
        className={classNames("Table__TD cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
        })}
      >
        {action.creator ? (
          <ActionAvatarCell
            userAzureId={action.creator.azureId}
            isLast={!border}
          />
        ) : (
          "-"
        )}
      </td>

      <td
        onClick={toggleModal}
        className={classNames("Table__TD cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
        })}
      >
        {action.personInCharge?.firstName &&
          action.personInCharge?.lastName && (
            <ActionAvatarCell
              userAzureId={action.personInCharge.azureId}
              isLast={!border}
            />
          )}
      </td>

      <td
        onClick={toggleModal}
        className={classNames("Table__TD cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
        })}
      >
        <span
          className={classNames({
            "opacity-60": action.isDefinitive,
          })}
        >
          <DateFormat
            isDefinitive={action.isDefinitive}
            date={action.deadline}
          />
        </span>
      </td>

      <ModalEditAction
        action={action}
        isOperation={isOperation}
        isModalShowed={isModalShowed}
        toggleModal={toggleModal}
      />
    </>
  )
}
