import Spinner from "components/Spinner/Spinner"
import useListPierrevalCompanies from "core/query-hooks/usePierrevalCompanies"
import { t } from "i18next"
import { TPierrevalCompany } from "shared/types/pierreval-company.type"
import DropdownFilters from "../DropdownFilter"
import useProgramsSearch from "../useProgramsSearch"

export default function CompaniesFilter() {
  const { data: companiesData, isLoading, error } = useListPierrevalCompanies()
  const { state, dispatch } = useProgramsSearch()
  const { companies } = state.filters

  if (isLoading) return <Spinner />

  if (error || !companiesData) return <p>{error?.response?.data.message}</p>

  const allCompaniesOption = {
    id: -1,
    label: t("select-all"),
  }

  const optionsWithAllCompanies = [allCompaniesOption, ...companiesData]

  const handleCompanyClick = (company: TPierrevalCompany) => {
    if (company.id === -1) {
      const shouldSelectAll = companies.length !== companiesData.length
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...state.filters,
          companies: shouldSelectAll
            ? companiesData.map((item) => item.id)
            : [],
          skip: 0,
          page: 1,
        },
      })
    } else {
      const isSelected = (state.filters.companies as number[])?.includes(
        company.id,
      )
      const updatedCompanies = isSelected
        ? state.filters.companies?.filter(
            (companyId) => companyId !== company.id,
          )
        : [...(state.filters.companies || []), company.id]

      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...state.filters,
          companies: updatedCompanies,
          skip: 0,
          page: 1,
        },
      })
    }
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="h-full text-sky-900">
              {t("companies")}
              {companies.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-yellow-800 bg-yellow-100 rounded-full">
                  {companies.length}
                </div>
              )}
            </div>
          )}
          options={optionsWithAllCompanies}
          renderOption={(company, index) => (
            <li
              onClick={() => handleCompanyClick(company)}
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              tabIndex={-1}
              id={`menu-item-${index}`}
              key={`menu-item-${index}`}
            >
              <input
                className="mr-2"
                type="checkbox"
                readOnly
                checked={
                  company.id === -1
                    ? companies.length === companiesData.length
                    : (state.filters.companies as number[])?.includes(
                        company.id,
                      )
                }
              />
              {company.label}
            </li>
          )}
          keyExtractor={(company) => company.id}
        />
      </div>
    </div>
  )
}
