import UploadIcon from "components/Icons/UploadIcon"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { RiDeleteBinFill, RiFile2Fill } from "react-icons/ri"
import bytesToSize from "utils/bytesToSize"
import truncateStringFromMiddle from "utils/truncateStringFromMiddle"

export type EhancedFile = File & {
  preview: string
}

export interface IUploadFieldDropProps {
  handleChange: (files: File[]) => void
  handleRemove: (index: number) => void
  myFiles: EhancedFile[]
  maxFiles: number
}

export function UploadFieldDrop(props: IUploadFieldDropProps) {
  const { handleChange, handleRemove, myFiles, maxFiles } = props
  const [error, setError] = useState<Error>()
  const [maxIsAllowedFilesError, setIsMaxAllowedFilesError] =
    useState<boolean>(false)

  const remainingSlots = maxFiles - myFiles.length

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: remainingSlots,
    onError: (err) => setError(err),
    onDropRejected() {
      setIsMaxAllowedFilesError(true)
    },
    onDropAccepted: (files) => {
      const newFiles = files.filter(
        (newFile) =>
          !myFiles.some((existingFile) => existingFile.name === newFile.name),
      )
      handleChange([...myFiles, ...newFiles])
      setIsMaxAllowedFilesError(false)
    },
  })

  useEffect(() => {
    return () => myFiles.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [myFiles])

  const files = myFiles.map((file: EhancedFile, index: number) => {
    return (
      <div
        key={`${file.name}`}
        className="border-t border-b border-gray-100 flex py-2 items-center justify-between"
      >
        <div className="flex items-center justify-start">
          <RiFile2Fill fontSize={50} color="#DBE0E3" className="mr-1" />
          <div className="flex flex-col">
            <a
              target="_blank"
              href={file.preview}
              rel="noreferrer"
              className="text-blue-600 text-sm whitespace-normal"
            >
              {truncateStringFromMiddle(file.name, 40)}
            </a>
            <p className="text-xs opacity-60">{bytesToSize(file.size)}</p>
          </div>
        </div>
        <button
          onClick={() => handleRemove(index)}
          className="opacity-80 hover:opacity-100"
          type="button"
        >
          <RiDeleteBinFill color="#2D6486" />
        </button>
      </div>
    )
  })

  return (
    <section>
      <div
        {...getRootProps({
          className:
            "flex flex-1 flex-col rounded-lg items-center justify-center border border-indigo-300 bg-blue-200 bg-opacity-25 border-dashed text-center p-8",
          style: {
            display: myFiles.length >= maxFiles ? "none" : "flex",
          },
        })}
      >
        <UploadIcon />
        <input {...getInputProps()} />

        <p className="pt-6 text-sm">
          {`${t("ged.upload.dropText")}`}{" "}
          <span className="text-blue-600 underline cursor-pointer">
            {`${t("ged.upload.browseText")}`}
          </span>
        </p>
      </div>
      {files}
      {error && <p className="text-red-600 text-xs mt-2">{error.message}</p>}
      {maxIsAllowedFilesError && (
        <p className="text-red-600 text-xs mt-2">
          {maxFiles === 1
            ? `${t("ged.upload.maxFilesErrorSingle")}`
            : `${t("ged.upload.maxFilesError")}`}
        </p>
      )}
    </section>
  )
}
