import Spinner from "components/Spinner/Spinner"
import { useListOperationsDataNextThreeMonths } from "core/query-hooks/useOperations"
import { t } from "i18next"
import CountUp from "react-countup"
import { FaEuroSign } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

export interface INextThreeMonthsLandPurchaseBlockProps {
  businessUnitId?: string
}

export function NextThreeMonthsLandPurchaseBlock(
  props: INextThreeMonthsLandPurchaseBlockProps,
) {
  const { businessUnitId } = props
  const navigate = useNavigate()

  const {
    data: nextThreeMonthsInfos,
    isPending = true,
    error,
  } = useListOperationsDataNextThreeMonths(Number(businessUnitId))

  return (
    <div
      className="border rounded-lg shadow-lg border-gray-200 px-3.5 py-2 h-28 bg-white relative flex flex-col justify-between cursor-pointer"
      onClick={() =>
        navigate(
          `/next-three-months/purchases${
            businessUnitId ? `/${businessUnitId}` : ""
          }`,
          {
            state: {
              operations: nextThreeMonthsInfos,
              title: t("purchases"),
            },
          },
        )
      }
    >
      <h3 className="text-xs uppercase text-cyan-950 font-medium flex items-start justify-start">
        <div className="h-5 w-5">
          <FaEuroSign size={18} />
        </div>
        <p className="ml-2">{`${t("purchases")}`}</p>
      </h3>
      {isPending ? (
        <div className="flex flex-col items-center mt-auto pb-4">
          <Spinner />
        </div>
      ) : null}
      {error ? (
        <div className="flex flex-col items-center mt-auto pb-4">
          <p className="text-xs text-red-500">{t("data-error")}</p>
        </div>
      ) : null}
      <p className="text-5xl text-center Color__Secondary">
        {nextThreeMonthsInfos &&
        nextThreeMonthsInfos.metadata.maxTotalHits !== 0 ? (
          <CountUp
            end={nextThreeMonthsInfos.metadata.maxTotalHits}
            duration={1.5}
          />
        ) : (
          <>{!isPending && !error ? 0 : null}</>
        )}
      </p>
    </div>
  )
}
