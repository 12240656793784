import { format } from "date-fns"
import { fr } from "date-fns/locale"
import { HiOutlineExternalLink } from "react-icons/hi"
import { RiFileFill } from "react-icons/ri"
import bytesToSize from "utils/bytesToSize"
import truncateStringFromMiddle from "utils/truncateStringFromMiddle"
import "./GedFile.scss"

interface IDocumentLinkProps {
  id: number
  title: string
  date?: Date
  size?: number
  stringLength?: number
}

export default function GedFile(props: IDocumentLinkProps) {
  const { id, title, date, size, stringLength = 30 } = props

  return (
    <div
      title={title}
      className="flex items-center text-blue-500 hover:text-blue-600 transition-colors ease-in-out delay-75 cursor-pointer"
      onClick={() =>
        window.open(
          `${process.env.REACT_APP_DOCUWARE_DOCUMENT_VIEWER_URL}${id}`,
          "_blank",
        )
      }
    >
      <RiFileFill className="mr-1.5 gedFileIcon" fontSize={24} />
      <span className="link">
        {" "}
        {truncateStringFromMiddle(title, stringLength)}
      </span>
      <span className="ml-1">
        <HiOutlineExternalLink />
      </span>
      {date && size && (
        <span className="rounded-full h-1.5 w-1.5 ml-1.5 mr-1.5 bg-gray-300" />
      )}

      <span className="mr-1.5 greyGedFile">
        {date
          ? format(new Date(date), "dd MMM", {
              locale: fr,
            })
          : null}
      </span>

      {size && <span className="greyGedFile">{`- ${bytesToSize(size)}`}</span>}
    </div>
  )
}
