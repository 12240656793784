import { IIconType } from "shared/types/global.type"

function SageLogo({ width = 16, height = 16 }: IIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg2623"
      viewBox="0 0 76.002 29.552"
      width={width}
      height={height}
    >
      <g id="layer1" transform="translate(85.126 -198.57)">
        <g id="g2659" transform="translate(.5 -.5)">
          <path
            id="path2629"
            d="M-19.747 199.57c-5.795 0-9.578 3.969-9.578 10.53 0 8.15 5.794 10.478 9.948 10.478 3.122 0 6.085-1.27 8.228-3.52l-2.672-2.671c-1.429 1.27-3.069 2.037-5.503 2.037-3.228 0-5.398-1.879-5.398-4.472h14.394c-.027-.026 2.487-12.382-9.42-12.382m-4.974 8.837c0-3.492 2.646-5.16 5.477-5.16 2.831 0 5.398 1.72 5.398 5.16z"
            className="st0"
            style={{
              fill: "#00dc06",
              strokeWidth: 0.26458332,
            }}
          />
          <path
            id="path2631"
            d="M-72.744 208.063c-1.852-.238-7.17-.026-7.17-2.46 0-1.43 2.25-2.223 4.287-2.223a10.95 10.95 0 0 1 6.085 1.746l2.778-2.751c-.82-.662-3.863-2.805-8.863-2.805-4.551 0-8.652 2.381-8.652 6.191 0 5.848 7.064 5.848 10 6.139 1.8.185 3.414.29 3.414 2.037 0 1.64-2.487 2.54-4.419 2.54-3.148 0-5.027-1.032-6.879-2.699l-2.963 2.99c2.566 2.487 6.032 3.836 9.604 3.81 5.292 0 9.128-2.328 9.128-7.012 0-3.095-2.09-4.947-6.35-5.503"
            className="st0"
            style={{
              fill: "#00dc06",
              strokeWidth: 0.26458332,
            }}
          />
          <path
            id="path2633"
            d="M-57.556 199.57a12.939 12.939 0 0 0-8.123 2.805l2.778 2.778a8.761 8.761 0 0 1 5.371-1.747c3.519 0 4.577 1.403 4.577 2.99v1.985h-6.27c-2.329 0-6.88.582-6.88 5.688 0 3.757 2.144 6.509 7.277 6.509 2.566 0 4.524-.82 5.873-2.434v2.037h4.551v-13.467c0-4.71-2.884-7.144-9.154-7.144m4.63 13.256c0 3.889-2.778 3.677-5.239 3.677-2.46 0-4.101-.661-4.101-2.407 0-1.614 1.296-2.17 3.995-2.17h5.345z"
            className="st0"
            style={{
              fill: "#00dc06",
              strokeWidth: 0.26458332,
            }}
          />
          <path
            id="path2635"
            d="M-34.352 199.993v2.25c-1.562-1.773-3.414-2.673-5.927-2.673-4.313 0-6.535 2.408-7.276 5-.291.953-.37 2.779-.37 5.477 0 2.54.026 4.869.899 6.642 1.244 2.487 4.233 3.889 6.747 3.889 2.434 0 4.498-.953 5.927-2.593v1.27c0 1.482-.503 2.46-1.456 3.228-.952.767-2.143 1.243-3.81 1.243-1.508 0-2.937-.82-3.757-1.402l-3.069 3.07c1.984 1.613 4.34 2.698 6.906 2.725 2.46.052 4.868-.741 6.8-2.302 1.825-1.43 2.99-4.154 2.99-7.012v-18.812zm0 10.054c0 1.562-.027 2.726-.16 3.334-.29 1.297-1.772 3.016-4.47 3.016-.689 0-4.128-.396-4.525-3.386a22.217 22.217 0 0 1-.132-2.964c0-2.328.105-3.069.211-3.65.238-1.165 1.773-2.911 4.445-2.911 3.255 0 4.313 2.222 4.472 3.095.132 1.138.185 2.302.159 3.466"
            className="st0"
            style={{
              fill: "#00dc06",
              strokeWidth: 0.26458332,
            }}
          />
        </g>
      </g>
      <style id="style2625" type="text/css">
        {".st0{fill:#00dc06}"}
      </style>
    </svg>
  )
}
export default SageLogo
