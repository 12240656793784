/* eslint-disable import/prefer-default-export */
export const GROUP = {
  COMMERCIAL_DIRECTEUR: "DW_COMMERCIAL_DIRECTEUR",
  ADMIN: "DW_ADMIN",
  CONCEPTION_ECONOMISTE: "DW_CONCEPTION_ECONOMISTE",
  CONCEPTION_INFOGRAPHISTE: "DW_CONCEPTION_INFOGRAPHISTE",
  CONCEPTION_INFOGRAPHISTE_RESPONSABLE:
    "DW_CONCEPTION_INFOGRAPHISTE_RESPONSABLE",
  CONTROLE_DE_GESTION: "DW_CONTROLE_DE_GESTION",
  DEVELOPPEMENT_ASSISTANT: "DW_DEVELOPPEMENT_ASSISTANT",
  DEVELOPPEMENT_DEVELOPPEUR: "DW_DEVELOPPEMENT_DEVELOPPEUR",
  DEVELOPPEMENT_DIRECTEUR: "DW_DEVELOPPEMENT_DIRECTEUR",
  DEVELOPPEMENT_RESPONSABLE: "DW_DEVELOPPEMENT_RESPONSABLE",
  FINANCE_COMPTABLE: "DW_FINANCE_COMPTABLE",
  FINANCE_DIRECTION: "DW_FINANCE_DIRECTION",
  JURIDIQUE_JURISTE: "DW_JURIDIQUE_JURISTE",
  JURIDIQUE_ASSISTANT: "DW_JURIDIQUE_ASSISTANT",
  COMMERCIAL_ASSISTANT: "DW_COMMERCIAL_ASSISTANT",
  PROGRAMME_ASSISTANT: "DW_PROGRAMME_ASSISTANT",
  PROGRAMME_CHARGE: "DW_PROGRAMME_CHARGE",
  PROGRAMME_RESPONSABLE: "DW_PROGRAMME_RESPONSABLE",
  CONCEPTION_CONCEPTEUR_RESPONSABLE: "DW_CONCEPTION_CONCEPTEUR_RESPONSABLE",
  PROGRAMME_DIRECTEUR: "DW_PROGRAMME_DIRECTEUR",
  TECHNIQUE_RESPONSABLE: "DW_TECHNIQUE_RESPONSABLE",
  TECHNIQUE_DIRECTEUR: "DW_TECHNIQUE_DIRECTEUR",
  TECHNIQUE_ASSISTANT: "DW_TECHNIQUE_ASSISTANT",
  CONCEPTION_CONCEPTEUR: "DW_CONCEPTION_CONCEPTEUR",
  CODIR: "DW_CODIR",
  CONCEPTION_ECONOMISTE_RESPONSABLE: "DW_CONCEPTION_ECONOMISTE_RESPONSABLE",
  COMMERCIAL: "DW_COMMERCIAL",
  DIRECTEUR_REGION_IDF: "DW_DR_IDF",
  DIRECTEUR_REGION_GO: "DW_DR_GO",
  DIRECTEUR_REGION_SE: "DW_DR_SE",
  DIRECTEUR_REGION_RA: "DW_DR_RA",
  DIRECTEUR_REGION_SO: "DW_DR_SO",
  DIRECTEUR_REGION_NE: "DW_DR_NE",
  DIRECTEUR_REGION_VDM: "DW_DR_VDM",
  DIRECTEUR_TERTIAIRE: "DW_DR_TER",
  DIRECTEUR_TECHNIQUE: "DW_DR_TEC",
  DRH: "DW_DR_DRH",
}
