import { API } from "core/api/axios"
import {
  TOperationCompany,
  TUpdateOperationCompany,
} from "shared/types/operation-company.type"

async function getOperationCompanyById(
  operationId: number,
): Promise<TOperationCompany> {
  return typeof operationId === "undefined"
    ? Promise.reject(new Error("Invalid operation id"))
    : API.get(`/operations/${operationId}/company`).then(
        (response) => response.data,
      )
}

async function updateOperationCompany(
  id: number,
  operationCompany: TUpdateOperationCompany,
): Promise<TOperationCompany> {
  return typeof id === "undefined"
    ? Promise.reject(new Error("Invalid operationId id"))
    : API.patch(`/operations/${id}/company`, operationCompany).then(
        (response) => response.data,
      )
}

export { getOperationCompanyById, updateOperationCompany }
