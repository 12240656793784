import classNames from "classnames"
import { useGetAvatar } from "core/query-hooks/useUsers"
import { useRef } from "react"
import { TUser } from "shared/types/user.type"
import { useDebounceValue, useHover } from "usehooks-ts"
import ContactPopover from "../Popover/ContactPopover"
import "./Avatar.scss"
import AvatarBlob from "./AvatarBlob"

interface IAvatarProps {
  user: TUser
  hasHoverCard?: boolean
  isDarkMode?: boolean
  size?: string
  displayTop?: boolean
}

export default function Avatar({
  user,
  size = "sm",
  hasHoverCard,
  displayTop = false,
  isDarkMode = false,
}: IAvatarProps) {
  const hoverRef = useRef(null)
  const [isHover] = useDebounceValue(useHover(hoverRef), 200)
  const { data: avatarBlob } = useGetAvatar(user?.azureId)
  const avatarBlobClassName = (): string => {
    switch (size) {
      case "small":
        return "w-5 h-5"
      default:
        return "w-9 h-9"
    }
  }

  return (
    <div ref={hoverRef} className={classNames(size, "Avatar__Container")}>
      {hasHoverCard && isHover && (
        <ContactPopover user={user} top={displayTop} />
      )}

      <AvatarBlob
        src={avatarBlob}
        className={avatarBlobClassName()}
        user={user}
        isDarkMode={isDarkMode}
      />
    </div>
  )
}
