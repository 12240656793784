import { t } from "i18next"
import { Dispatch, SetStateAction } from "react"
import "./Toggle.scss"

interface IDetailFilterProps {
  checkedDetail?: boolean
  onChangeDetail: Dispatch<SetStateAction<boolean | string>>
}

export default function DetailFilter(props: IDetailFilterProps) {
  const { checkedDetail, onChangeDetail } = props

  return (
    <div className="Toggle__Container  mb-0">
      <div className="Toggle__Input_Container">
        <input
          id="radio_operation"
          aria-label="operation"
          className="Text__Field"
          {...props}
          type="radio"
          checked={checkedDetail}
          value="operation"
          onChange={(e) => {
            onChangeDetail(e.target.value)
          }}
        />
        <label className="Left__Radio" htmlFor="radio_operation">
          <span>{t("operation")}</span>
        </label>
      </div>
      <div className="Toggle__Input_Container">
        <input
          id="radio_tranche_commerciale"
          value="tranche_commerciale"
          className="Text__Field Right__Radio"
          {...props}
          type="radio"
          checked={!checkedDetail}
          onChange={(e) => {
            onChangeDetail(e.target.value)
          }}
        />
        <label className="Right__Radio" htmlFor="radio_tranche_commerciale">
          <span>{t("tranche")}</span>
        </label>
      </div>
    </div>
  )
}
