import classNames from "classnames"
import { ComponentProps } from "react"

export interface IButtonIconProps extends ComponentProps<"button"> {}

export default function ButtonIcon({
  type,
  children,
  className,
  ...rest
}: IButtonIconProps) {
  return (
    <button
      {...rest}
      className={classNames(
        className,
        "rounded-lg flex items-center justify-center p-1 border border-slate-100 hover:bg-slate-100",
      )}
      type={type === "button" ? "button" : "submit"}
    >
      {children}
    </button>
  )
}
