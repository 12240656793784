import Spinner from "components/Spinner/Spinner"
import useListBusinessUnits from "core/query-hooks/useBusinessUnits"
import { t } from "i18next"
import { TBusinessUnit } from "shared/types/business-unit.type"

export interface ISelectedBusinessUnitsFiltersProps {
  selectedBusinessUnits: number[] | []
  onBusinessUnitsChange: (selectedBu: number[] | []) => void
}

function renderRegion(regionId: number, regions: TBusinessUnit[]) {
  const selectedStep = regions?.find((region) => region.id === regionId)
  return selectedStep?.label
}

export default function SelectedBusinessUnitsFilters(
  props: ISelectedBusinessUnitsFiltersProps,
) {
  const { selectedBusinessUnits, onBusinessUnitsChange } = props
  const { data: regionsData, isLoading, error } = useListBusinessUnits()

  if (isLoading) return <Spinner />

  if (error || !regionsData) return <p>{error?.response?.data.message}</p>

  const handleRegionRemove = (regionIdToRemove: number) => {
    // Filtrer les business units
    const updatedBuList = selectedBusinessUnits.filter(
      (regionId) => regionId !== regionIdToRemove,
    )
    onBusinessUnitsChange(updatedBuList)
  }

  return (
    <div>
      {selectedBusinessUnits.map((regionId) => (
        <span
          id="badge-dismiss-default"
          className="inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-pink-800 bg-pink-100 rounded"
          key={regionId}
        >
          {renderRegion(regionId, regionsData)}
          <button
            onClick={() => handleRegionRemove(regionId)}
            type="button"
            className="inline-flex items-center p-0.5 ml-2 my-0.5 text-sm text-pink-400 bg-transparent rounded-sm hover:bg-pink-200 hover:text-pink-900"
            data-dismiss-target="#badge-dismiss-default"
            aria-label="Remove"
          >
            <svg
              aria-hidden="true"
              className="w-3.5 h-3.5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">{t("delete")}</span>
          </button>
        </span>
      ))}
    </div>
  )
}
