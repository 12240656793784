import { useMutation, useQuery } from "@tanstack/react-query"
import {
  createSuspensiveConditions,
  deleteSuspensiveCondition,
  getDefaultSuspensiveConditions,
  getSuspensiveConditionsByAgreementId,
  getSuspensiveConditionsByOperationId,
  updateSuspensiveCondition,
} from "core/api/suspensives-conditions"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TCreateSuspensiveCondition,
  TDefaultSuspensiveCondition,
  TSuspensiveCondition,
  TUpdateSuspensiveCondition,
} from "shared/types/suspensiveCondition.type"

function useGetSuspensiveConditionsByAgreementId(agreementId?: string) {
  return useQuery<
    TSuspensiveCondition[],
    CustomAxiosError,
    TSuspensiveCondition[]
  >({
    queryKey: ["getSuspensiveConditionsByAgreementId", agreementId],
    queryFn: () => getSuspensiveConditionsByAgreementId(agreementId),
  })
}

function useGetSuspensiveConditionsByOperationId(
  operationId: string,
  lte?: Date,
  gte?: Date,
  isDefinitive?: boolean,
) {
  return useQuery<
    TSuspensiveCondition[],
    CustomAxiosError,
    TSuspensiveCondition[]
  >({
    queryKey: ["getSuspensiveConditionsByOperationId", operationId],
    queryFn: () =>
      getSuspensiveConditionsByOperationId(operationId, lte, gte, isDefinitive),
  })
}

function useGetDefaultSuspensiveConditions() {
  return useQuery<
    TDefaultSuspensiveCondition[],
    CustomAxiosError,
    TSuspensiveCondition[]
  >({
    queryKey: ["getDefaultSuspensiveConditions"],
    queryFn: () => getDefaultSuspensiveConditions(),
  })
}

function useCreateSuspensiveCondition() {
  return useMutation<
    TSuspensiveCondition,
    CustomAxiosError,
    TCreateSuspensiveCondition
  >({ mutationFn: (data) => createSuspensiveConditions(data) })
}

function useUpdateSuspensiveCondition(id: number) {
  return useMutation<
    TSuspensiveCondition,
    CustomAxiosError,
    TUpdateSuspensiveCondition
  >({
    mutationFn: (suspensiveCondition) =>
      updateSuspensiveCondition(id, suspensiveCondition),
  })
}

function useDeleteSuspensiveCondition() {
  return useMutation<unknown, CustomAxiosError, number>({
    mutationFn: (id) => deleteSuspensiveCondition(id),
  })
}

export {
  useCreateSuspensiveCondition,
  useDeleteSuspensiveCondition,
  useGetDefaultSuspensiveConditions,
  useGetSuspensiveConditionsByAgreementId,
  useGetSuspensiveConditionsByOperationId,
  useUpdateSuspensiveCondition,
}
