import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import CreateProjectForm from "features/project/CreateProject"

export default function CreateProjectPage() {
  return (
    <div className="p-4">
      <QueryBoundaries loadingComponent={<Spinner />}>
        <CreateProjectForm />
      </QueryBoundaries>
    </div>
  )
}
