import classnames from "classnames"
import DisplayError from "components/Display/DisplayError"
import DisplayLoading from "components/Display/DisplayLoading"
import { t } from "i18next"
import { ReactNode } from "react"
import CountUp from "react-countup"
import { CustomAxiosError } from "shared/types/axios.type"

export interface IitemsCounterProps {
  totalLateItems?: number
  totalItems?: number
  title: string
  Icon: ReactNode
  classNames?: string
  isLoading: boolean
  error: CustomAxiosError | null
  errorMessage: string
}

export default function ItemsCounter(props: IitemsCounterProps) {
  const {
    totalItems,
    totalLateItems,
    title,
    Icon,
    isLoading,
    error,
    errorMessage,
    classNames,
  } = props
  const displayOnlyTotal =
    totalItems !== undefined && totalLateItems === undefined

  return (
    <div
      className={classnames(
        "border rounded-lg border-gray-200 px-3.5 py-2 h-28 relative",
        {
          "bg-red-50": totalLateItems,
          "border-red-300": totalLateItems,
          "bg-white": totalLateItems === 0,
        },
        classNames,
      )}
    >
      <h3
        className={classnames(
          "text-xs uppercase text-cyan-950 font-medium flex items-center justify-start",
        )}
      >
        {Icon} <p className="ml-2">{`${t(title)}`}</p>
      </h3>
      {isLoading && !error ? <DisplayLoading isLoading={isLoading} /> : null}
      {error && !isLoading ? (
        <DisplayError errorMessage={errorMessage} error={error} />
      ) : null}
      {!isLoading && !error && (
        <>
          {displayOnlyTotal ? (
            <p className={classnames("text-7xl text-center text-blue-600")}>
              {totalItems ? <CountUp end={totalItems} duration={1.5} /> : 0}
            </p>
          ) : (
            <>
              <p className="text-lg absolute bottom-1 right-0 left-12 mx-auto text-center text-amber-500 font-semibold">
                {totalItems ? <CountUp end={totalItems} duration={1.5} /> : 0}
              </p>
              <p className="text-7xl text-center text-red-500">
                {totalLateItems ? (
                  <CountUp end={totalLateItems} duration={1.5} />
                ) : (
                  0
                )}
              </p>
            </>
          )}
        </>
      )}
    </div>
  )
}
