export const WF_REFUSED = "00 - Refusé étude"
export const DOC_REFUSED = "00 - Développement"
export const DOC_DEVELOPEMENT = "00 - Développement"
export const DOC_STUDY = "01 - Etude"
export const WF_STUDY = "01 - En étude"
export const DOC_VALIDATED = "02 - Promotion"
export const WF_VALIDATED = "01 - En étude"

export const MAX_SIZED_BYTES = 100000000
export const UNAUTHORIZED_EXTENSIONS = ["bat", "db", "exe", "lnk"]
