import classNames from "classnames"
import ButtonIcon from "components/Button/ButtonIcon"
import useModal from "components/Modal/useModal"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import AddDocumentsModalWithoutChapters from "features/ged/AddDocumentsModalWithoutChapters"
import { t } from "i18next"
import { Dispatch, SetStateAction } from "react"
import { MdAdd } from "react-icons/md"
import { TGedUploadResponse } from "shared/types/ged.type"

export interface IAddFileProps {
  documentTypes: string[]
  maxUploadFiles?: number
  setFiles?: Dispatch<SetStateAction<TGedUploadResponse[]>>
  files?: TGedUploadResponse[]
  disabled?: boolean
  hasIcon?: boolean
  handleGetDocumentsId?: (documentId: number) => Promise<void>
  isProjectStudyRequestRelated?: boolean
  numStatus?: string
  linkedFiles?: boolean
}

export function AddFile(props: IAddFileProps) {
  const { toggle, isShowing } = useModal()
  const {
    files,
    maxUploadFiles = 10,
    setFiles,
    documentTypes,
    disabled,
    handleGetDocumentsId,
    hasIcon,
    isProjectStudyRequestRelated,
    numStatus,
    linkedFiles,
  } = props
  return (
    <div>
      {files && files?.length >= 30 ? null : (
        <>
          {hasIcon ? (
            <ButtonIcon
              disabled={disabled}
              className={classNames(
                "flex items-center text-blue-500 hover:text-blue-600 transition-colors ease-in-out delay-75",
                {
                  "opacity-50": disabled,
                },
              )}
              type="button"
              onClick={toggle}
            >
              <MdAdd size={22} cursor="pointer" className="text-cyan-800" />
            </ButtonIcon>
          ) : (
            <button
              disabled={disabled}
              className={classNames(
                "flex items-center text-blue-500 hover:text-blue-600 transition-colors ease-in-out delay-75",
                {
                  "opacity-50": disabled,
                },
              )}
              type="button"
              onClick={toggle}
            >
              {t("add")}
            </button>
          )}
          {isShowing && (
            <GedAxiosInterceptor>
              <AddDocumentsModalWithoutChapters
                isModalShowed={isShowing}
                toggleModal={() => toggle()}
                maxFiles={maxUploadFiles}
                setFiles={setFiles}
                handleGetDocumentsId={handleGetDocumentsId}
                documentTypes={documentTypes}
                isProjectStudyRequestRelated={isProjectStudyRequestRelated}
                numStatus={numStatus}
                files={files}
                linkedFiles={linkedFiles}
              />
            </GedAxiosInterceptor>
          )}
        </>
      )}
    </div>
  )
}
