import { useQuery } from "@tanstack/react-query"
import listBusinessUnits from "core/api/business-units"
import { CustomAxiosError } from "shared/types/axios.type"
import { TBusinessUnit } from "shared/types/business-unit.type"

export default function useListBusinessUnits() {
  return useQuery<TBusinessUnit[], CustomAxiosError>({
    queryKey: ["businessUnits"],
    queryFn: () => listBusinessUnits(),
    ...{
      suspense: true,
    },
  })
}
