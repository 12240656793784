import { useMutation, useQuery } from "@tanstack/react-query"
import {
  addCommentToStudyRequest,
  checkStudyRequestsDocuments,
  deleteStudyRequest,
  getStudyRequestDashboard,
  getStudyRequestDocument,
  getStudyRequestsRights,
  listStudyRequests,
  listStudyRequestsTodos,
  listStudyRequestUsers,
  listTeamStudyRequestsTodos,
  listUserStudyRequests,
  updateStatus,
  updateStudyRequest,
  updateStudyRequestDocuments,
} from "core/api/study-requests"
import { CustomAxiosError } from "shared/types/axios.type"
import { TGedDocuments } from "shared/types/ged.type"
import { TLabelStringValue } from "shared/types/global.type"
import {
  TAddComment,
  TMutateStudyRequestDocuments,
  TStudyRequest,
  TStudyRequestDashboardItem,
  TStudyRequestsDashboardParams,
  TStudyRequestStatus,
  TStudyRequestTodo,
  TStudyRequestWithProject,
  TStudyRights,
  TUpdateStudyRequest,
  TUpdateStudyRequestStatus,
} from "shared/types/study.type"

function useUpdateStatus(studyRequestId: number) {
  return useMutation<
    TStudyRequestStatus,
    CustomAxiosError,
    TUpdateStudyRequestStatus
  >({
    mutationFn: (request: TUpdateStudyRequestStatus) =>
      updateStatus(request, studyRequestId),
  })
}

function useUpdateStudyRequest(requestId: number) {
  return useMutation<TStudyRequest, CustomAxiosError, TUpdateStudyRequest>({
    mutationFn: (data) => updateStudyRequest(requestId, data),
  })
}

function useListStudyRequests(projectId: number) {
  return useQuery<TStudyRequest[], CustomAxiosError>({
    queryKey: ["listStudyRequests", projectId],
    queryFn: () => listStudyRequests(projectId),
  })
}

function useStudyRequestsRights(studyRequestId: number) {
  return useQuery<TStudyRights, CustomAxiosError>({
    queryKey: ["getStudyRequestsRights", studyRequestId],
    queryFn: () => getStudyRequestsRights(studyRequestId),
    ...{ enabled: Boolean(studyRequestId), gcTime: 60 * 4000 },
  })
}

function useListUserStudyRequests(userAzureId: string) {
  return useQuery<TStudyRequestWithProject[], CustomAxiosError>({
    queryKey: ["listUserStudyRequests", userAzureId],
    queryFn: () => listUserStudyRequests(userAzureId),
  })
}

function uselistStudyRequestUsers() {
  return useQuery<TLabelStringValue[]>({
    queryKey: ["listStudyRequestUsers"],
    queryFn: listStudyRequestUsers,
  })
}

function useAddCommentToStudyRequest(studyRequestId: number) {
  return useMutation<void, CustomAxiosError, TAddComment>({
    mutationFn: (data) => addCommentToStudyRequest(data, studyRequestId),
  })
}

function useUpdateStudyRequestDocuments(id: number) {
  return useMutation<
    TMutateStudyRequestDocuments,
    CustomAxiosError,
    TMutateStudyRequestDocuments
  >({ mutationFn: (data) => updateStudyRequestDocuments(id, data) })
}

function useListStudyRequestsTodos(userAzureId: string) {
  return useQuery<TStudyRequest[], CustomAxiosError>({
    queryKey: ["listStudyRequestsTodos", userAzureId],
    queryFn: () => listStudyRequestsTodos(userAzureId),
  })
}

function useListTeamStudyRequestsTodos(userAzureId: string) {
  return useQuery<TStudyRequestTodo[], CustomAxiosError>({
    queryKey: ["listTeamStudyRequestsTodos", userAzureId],
    queryFn: () => listTeamStudyRequestsTodos(userAzureId),
  })
}

function useCheckStudyRequestsDocuments(id: number) {
  return useQuery<string[], CustomAxiosError>({
    queryKey: ["studyRequestCheckDocuments", id],
    queryFn: () => checkStudyRequestsDocuments(id),
  })
}

function useGetStudyRequestDocuments(id: number) {
  return useQuery<TGedDocuments, CustomAxiosError>({
    queryKey: ["listStudyRequestDocuments", id],
    queryFn: () => getStudyRequestDocument(id),
  })
}

function useListStudyRequestsDashboard(params: TStudyRequestsDashboardParams) {
  return useQuery<unknown, CustomAxiosError, TStudyRequestDashboardItem[]>({
    queryKey: ["listStudyRequestsDashboard", params],
    queryFn: () => getStudyRequestDashboard(params),
    ...{
      retry: 0,
      staleTime: 300 * 1000,
    },
  })
}

function useDeleteStudyRequest(id: number) {
  return useMutation<TStudyRequest, CustomAxiosError, number>({
    mutationFn: () => deleteStudyRequest(id),
  })
}

export {
  useAddCommentToStudyRequest,
  useCheckStudyRequestsDocuments,
  useDeleteStudyRequest,
  useGetStudyRequestDocuments,
  useListStudyRequests,
  useListStudyRequestsDashboard,
  useListStudyRequestsTodos,
  uselistStudyRequestUsers,
  useListTeamStudyRequestsTodos,
  useListUserStudyRequests,
  useStudyRequestsRights,
  useUpdateStatus,
  useUpdateStudyRequest,
  useUpdateStudyRequestDocuments,
}
