import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import {
  getEdvDetail,
  getEdvHome,
  getEdvLastUpdateDate,
  getEdvOverview,
  getEdvSynthesis,
} from "core/api/edv"
import {
  TEdvDetail,
  TEdvDetailParams,
  TEdvHome,
  TEdvOverview,
  TEdvOverviewParams,
  TEdvSynthesis,
  TEdvSynthesisParams,
} from "shared/types/edv.type"

export function useGetEdvHome(businessUnitId?: string) {
  return useQuery<TEdvHome[], AxiosError>({
    queryKey: ["getEdvHome", businessUnitId],
    queryFn: () => getEdvHome(businessUnitId),
  })
}

export function useGetEdvOverview(params: TEdvOverviewParams) {
  return useQuery<TEdvOverview[], AxiosError>({
    queryKey: ["getEdvOverview", params],
    queryFn: () => getEdvOverview(params),
  })
}

export function useGetEdvSynthesis(params: TEdvSynthesisParams) {
  return useQuery<TEdvSynthesis[], AxiosError>({
    queryKey: ["getEdvSynthesis", params],
    queryFn: () => getEdvSynthesis(params),
  })
}

export function useGetEdvDetail(params: TEdvDetailParams) {
  return useQuery<TEdvDetail[], AxiosError>({
    queryKey: ["getEdvDetail", params],
    queryFn: () => getEdvDetail(params),
  })
}

export function useGetEdvLastUpdateDate() {
  return useQuery<Date | null, AxiosError>({
    queryKey: ["getEdvLastUpdateDate"],
    queryFn: () => getEdvLastUpdateDate(),
  })
}

export default {
  useGetEdvHome,
  useGetEdvOverview,
  useGetEdvSynthesis,
  useGetEdvDetail,
  useGetEdvLastUpdateDate,
}
