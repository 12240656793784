import { useQuery } from "@tanstack/react-query"
import listDepartments from "core/api/departments"
import { CustomAxiosError } from "shared/types/axios.type"
import { TIdLabel } from "shared/types/global.type"

export default function useDepartments(type: boolean) {
  return useQuery<TIdLabel[], CustomAxiosError>({
    queryKey: ["programsDepartments", type],
    queryFn: () => listDepartments(type),
    ...{
      suspense: true,
    },
  })
}
