import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { useGetEdvOverview } from "core/query-hooks/useEdv"
import { t } from "i18next"
import { nanoid } from "nanoid"

export interface IEdvOverviewProps {
  businessUnits: number[]
  departments: string[]
  companies: number[]
  products: string[]
  year: number
  caType: string
}

export default function EdvOverview(props: IEdvOverviewProps) {
  const { year, caType, businessUnits, departments, companies, products } =
    props
  const {
    data: edvOverview,
    isLoading,
    error,
  } = useGetEdvOverview({
    year,
    caType,
    businessUnits,
    departments,
    companies,
    products,
  })

  function getPreviousMonth() {
    const currentMonth = new Date().getMonth()
    const previousMonthIndex = (currentMonth - 1 + 12) % 12
    const monthNames = [
      t("january-abv"),
      t("february-abv"),
      t("march-abv"),
      t("april-abv"),
      t("may-abv"),
      t("june-abv"),
      t("july-abv"),
      t("august-abv"),
      t("september-abv"),
      t("october-abv"),
      t("november-abv"),
      t("december-abv"),
    ]
    return monthNames[previousMonthIndex]
  }

  const prevMonth = getPreviousMonth()

  const headers = [
    () => "",
    () => t("nb-lots"),
    () => t("initial-stock-abrv"),
    () => "",
    () => t("january-abv"),
    () => t("february-abv"),
    () => t("march-abv"),
    () => t("april-abv"),
    () => t("may-abv"),
    () => t("june-abv"),
    () => t("july-abv"),
    () => t("august-abv"),
    () => t("september-abv"),
    () => t("october-abv"),
    () => t("november-abv"),
    () => t("december-abv"),
    () => "",
    () => `${t("cumulative-end")} ${prevMonth}`,
    () => `${t("gap-objective")} (%)`,
    () => `${t("gap-n-1")} (%)`,
    () => t("total"),
    () => t("day-stock"),
    () => t("options"),
  ]

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : null}
      {edvOverview && (
        <div className="Light_Table_Edv__Wrapper Table__Wrapper_NoTop">
          <div className="Table__Container">
            <table className="Table table-auto">
              <thead>
                <tr className="text-left">
                  {headers.map((header, index) => (
                    <th
                      className={classNames(
                        "Table__Head Table__Top_Border text-right border-b border-gray-200",
                        {
                          "w-36": index === 0,
                        },
                      )}
                      key={nanoid()}
                    >
                      {header()}
                    </th>
                  ))}
                </tr>
              </thead>
              {edvOverview.length > 0 ? (
                <tbody>
                  {edvOverview.map((item) => {
                    return (
                      <tr
                        key={nanoid()}
                        className={classNames({
                          "bg-primary-light text-white": item.code === 2024,
                          "bg-gray-100 text-gray-500":
                            item.code === "historique",
                        })}
                      >
                        <td className="Table__Bottom_Border">{item.type}</td>
                        <td className="Table__Bottom_Border text-right">
                          {item.nombre_lots}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.stock_debut}
                        </td>
                        <td className="bg-gray-200" />
                        <td className="Table__Bottom_Border text-right">
                          {item.janvier
                            ? Number(item.janvier).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.fevrier
                            ? Number(item.fevrier).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.mars
                            ? Number(item.mars).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.avril
                            ? Number(item.avril).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.mai
                            ? Number(item.mai).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.juin
                            ? Number(item.juin).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.juillet
                            ? Number(item.juillet).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.aout
                            ? Number(item.aout).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.septembre
                            ? Number(item.septembre).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.octobre
                            ? Number(item.octobre).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.novembre
                            ? Number(item.novembre).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.decembre
                            ? Number(item.decembre).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="bg-gray-200" />
                        <td className="Table__Bottom_Border text-right">
                          {item.cumul
                            ? Number(item.cumul).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td
                          className={classNames(
                            "Table__Bottom_Border text-right",
                            {
                              Red: item.ecart_objectif_pourcentage < 0,
                            },
                            {
                              Green: item.ecart_objectif_pourcentage >= 0,
                            },
                          )}
                        >
                          {item.ecart_objectif_pourcentage
                            ? `${Number(item.ecart_objectif_pourcentage).toLocaleString("fr-FR")} %`
                            : ""}
                          {item.ecart_objectif_lot
                            ? `(${Number(item.ecart_objectif_lot).toLocaleString("fr-FR")})`
                            : ""}
                        </td>
                        <td
                          className={classNames(
                            "Table__Bottom_Border text-right",
                            {
                              Red: item.ecart_n1_pourcentage < 0,
                            },
                            {
                              Green: item.ecart_n1_pourcentage >= 0,
                            },
                          )}
                        >
                          {item.ecart_n1_pourcentage
                            ? `${Number(item.ecart_n1_pourcentage).toLocaleString("fr-FR")} %`
                            : ""}
                          {item.ecart_n1_lot
                            ? `(${Number(item.ecart_n1_lot).toLocaleString("fr-FR")})`
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.total !== null && item.total !== undefined
                            ? new Intl.NumberFormat("fr-FR", {
                                maximumFractionDigits: 0,
                              }).format(item.total)
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          {item.stock_jour
                            ? Number(item.stock_jour).toLocaleString("fr-FR")
                            : ""}
                        </td>
                        <td className="Table__Bottom_Border text-right">
                          <p className="mr-3">
                            {item.nombre_options
                              ? Number(item.nombre_options).toLocaleString(
                                  "fr-FR",
                                )
                              : ""}
                          </p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              ) : (
                <tbody className="text-center p-2">
                  <tr>
                    <td colSpan={headers.length}>
                      {t("no-data-found-message")}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
      {error ? <>{t("data-error")}</> : null}
    </>
  )
}
