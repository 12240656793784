import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components/Button/Button"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import { useSaveSearch } from "core/query-hooks/useSavedSearches"
import { t } from "i18next"
import { FormEvent } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import {
  STATUS_ASSEMBLY,
  STATUS_ENGAGEMENT_COMMITTEE,
  STATUS_LAND_COMMITTEE,
  STATUS_NEGOCIATION,
  STATUS_NOT_ACTED,
  STATUS_ON_DELIVERY,
  STATUS_ON_GOING,
  STATUS_PROSPECTION,
  STATUS_STANDBY,
  STATUS_WAITING_ASSEMBLY,
} from "shared/resources/operation.resources"
import * as yup from "yup"
import CompaniesFilter from "./filters/CompaniesFilter"
import ContributorsFilters from "./filters/ContributorsFilter"
import DepartmentsFilter from "./filters/DepartmentsFilter"
import RegionsFilter from "./filters/RegionsFilter"
import StepsFilter from "./filters/StepsFilter"
import TypesFilter from "./filters/TypesFilter"
import useProgramsSearch from "./useProgramsSearch"

type FormValues = {
  name: string
}

const validationSchema = yup.object().shape({
  name: yup.string().required(() => `${t("required-name")}`),
})

interface ProgramsFiltersProps {
  isOperation: boolean
  handlePageChange: (nextPage: number) => void
}

export default function ProgramsFilters(props: ProgramsFiltersProps) {
  const { state, dispatch } = useProgramsSearch()
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const queryClient = useQueryClient()
  const saveSearch = useSaveSearch()
  const { isOperation, handlePageChange } = props

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  })

  const handleCloseModal = () => {
    toggleModal()
    reset()
  }

  const submitSave = async (data: { name: string }) => {
    const currentURL = window.location.href
    const startIndex = currentURL.indexOf("programs/search?")
    if (startIndex !== -1 && data.name) {
      const searchParams = new URLSearchParams(
        currentURL.substring(startIndex + "programs/search?".length),
      )

      searchParams.delete("skip")
      searchParams.delete("page")

      const searchString = searchParams.toString()

      saveSearch.mutateAsync(
        { name: data.name, searchUrl: searchString },
        {
          onSuccess: () => {
            toast.success(`${t("toast-save-search-success")}`)
            handleCloseModal()
            queryClient.invalidateQueries({ queryKey: ["listSavedSearches"] })
          },
          onError: (err) => {
            toast.error(
              `${t("toast-save-search-error")} ${
                err.response?.data.error.message
              }`,
            )
          },
        },
      )
    } else {
      toast.error(`${t("toast-action-create-error")}`)
      handleCloseModal()
    }
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitSave)()
  }

  function handleClearFilters() {
    handlePageChange(1)
    dispatch({
      type: "SET_FILTERS",
      payload: {
        steps: [
          STATUS_PROSPECTION,
          STATUS_NEGOCIATION,
          STATUS_LAND_COMMITTEE,
          STATUS_ENGAGEMENT_COMMITTEE,
          STATUS_STANDBY,
          STATUS_WAITING_ASSEMBLY,
          STATUS_ASSEMBLY,
          STATUS_NOT_ACTED,
          STATUS_ON_GOING,
          STATUS_ON_DELIVERY,
        ],
        departments: [],
        regions: [],
        users: [],
        companies: [],
        types: [],
        take: 20,
        skip: 0,
        search: "",
        sortOrder: "asc",
        sortColumn: "stepId",
        page: 1,
      },
    })
  }

  const hasSelectedFilters =
    state.filters.steps.length > 0 ||
    state.filters.departments.length > 0 ||
    state.filters.regions.length > 0 ||
    state.filters.users.length > 0 ||
    state.filters.companies.length > 0 ||
    state.filters.search.length > 0 ||
    (state.filters.types !== null && state.filters.types.length > 0)

  return (
    <div className="flex mb-2 md:m-0 md:max-w-300 flex-wrap">
      <QueryBoundaries loadingComponent={<Spinner />}>
        <div className="mr-2 mb-3 h-full">
          <ContributorsFilters />
        </div>
        <div className="mr-2 mb-3 h-full">
          <StepsFilter />
        </div>
        <div className="mr-2 mb-3 h-full">
          <RegionsFilter />
        </div>
        <div className="mr-2 mb-3 h-full">
          <DepartmentsFilter isOperation={isOperation} />
        </div>
        <div className="mr-2 mb-3 h-full">
          <TypesFilter />
        </div>
        <div className="mr-2 h-full">
          <CompaniesFilter />
        </div>
      </QueryBoundaries>

      {hasSelectedFilters && (
        <div className="flex items-center md:max-w-300 flex-wrap">
          <div className="flex flex-wrap w-full mb-3 mt-2 md:m-0">
            <Button
              onClick={toggleModal}
              size="medium"
              mode="primary"
              classNames="w-full md:w-auto mb-3 md:mr-2"
            >
              {`${t("save-search")}`}
            </Button>
            <Button
              type="submit"
              onClick={() => handleClearFilters()}
              size="medium"
              mode="secondary"
              classNames="w-full md:w-auto mb-3 md:mr-2"
            >
              {`${t("reset-search")}`}
            </Button>
          </div>

          <Modal
            isShowing={isModalShowed}
            closeModal={handleCloseModal}
            title={`${t("save-search")}`}
          >
            <form className="Text__Field_Container" onSubmit={onSubmit}>
              <label className="font-medium" htmlFor="name">
                {`${t("name")} *`}
                <input
                  className="Text__Field"
                  placeholder={`${t("name")}`}
                  id="name"
                  {...register("name")}
                />
              </label>
              {errors?.name && (
                <p className="text-red-600 text-sm">{errors.name.message}</p>
              )}

              <div className="Modal__Footer mt-3">
                <Button
                  type="button"
                  size="medium"
                  mode="secondary"
                  onClick={() => {
                    handleCloseModal()
                  }}
                >
                  {`${t("cancel")}`}
                </Button>
                <Button
                  marginLeft={24}
                  size="medium"
                  mode="primary"
                  type="submit"
                  isDisabled={saveSearch.isPending}
                >
                  {`${t("save")}`}
                </Button>
              </div>
            </form>
          </Modal>
        </div>
      )}
    </div>
  )
}
