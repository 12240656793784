import { useQueryClient } from "@tanstack/react-query"
import classnames from "classnames"
import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import {
  useDeleteSavedSearch,
  useListSavedSearches,
} from "core/query-hooks/useSavedSearches"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { useState } from "react"
import { FaBookmark, FaChevronDown, FaChevronUp } from "react-icons/fa"
import { RiDeleteBin7Fill } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import useProgramsSearch from "./useProgramsSearch"

export default function SavedSearches() {
  const [display, setDisplay] = useState<boolean>(true)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const deleteSavedSearch = useDeleteSavedSearch()
  const { state, dispatch } = useProgramsSearch()

  function handleChangeDisplay() {
    setDisplay(!display)
  }

  function handleDelete(id: number) {
    deleteSavedSearch.mutateAsync(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["listSavedSearches"] })
        toast.success(`${t("toast-delete-saved-search-success")}`)
      },
      onError: () => {
        toast.error(`${t("toast-delete-saved-search-error")}`)
      },
    })
  }

  function handleSelectSearch(searchUrl: string) {
    const searchParams = new URLSearchParams(searchUrl)
    const departments = searchParams.get("departments")
    const regions = searchParams.get("businessUnits")
    const steps = searchParams.get("steps")
    const users = searchParams.get("contributors")
    const companies = searchParams.get("companies")
    const types = searchParams.get("types")
    const sortOrder = searchParams.get("sortOrder")
    const sortColumn = searchParams.get("sortColumn")
    const search = searchParams.get("search")

    const selectedDepartments = departments?.split(",").map(Number)
    const selectedRegions = regions?.split(",").map(Number)
    const selectedSteps = steps?.split(",").map(Number)
    const selectedUsers = users?.split(",").map(String)
    const selectedCompanies = companies?.split(",").map(Number)
    const selectedTypes = types?.split(",").map(Number)

    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...state.filters,
        departments: selectedDepartments ?? [],
        regions: selectedRegions ?? [],
        steps: selectedSteps ?? [],
        users: selectedUsers ?? [],
        companies: selectedCompanies ?? [],
        types: selectedTypes ?? [],
        sortOrder: sortOrder ?? "",
        sortColumn: sortColumn ?? "",
        search: search ?? "",
        skip: 0,
        page: 1,
      },
    })

    navigate(`/programs/search?${searchUrl}`)
  }

  const { data: savedSearches, error, isLoading } = useListSavedSearches()

  const currentURL = window.location.href
  const startIndex = currentURL.indexOf("programs/search?")

  const searchParams = new URLSearchParams(
    currentURL.substring(startIndex + "programs/search?".length),
  )

  // Filtre pour ne pas que la pagination gêne
  searchParams.delete("skip")
  searchParams.delete("page")

  const searchString = searchParams.toString()

  return (
    <Box>
      <div className="flex items-center justify-between text-primary-dark">
        <div className="flex items-center">
          <div className="mr-2">
            <FaBookmark />
          </div>
          <div className="font-semibold">{t("saved-searches")}</div>
        </div>
        <div className="cursor-pointer" onClick={() => handleChangeDisplay()}>
          {display ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {display && (
        <div className="mt-2 pl-10 text-primary-dark">
          <div className="flex flex-wrap">
            {error && (
              <div className="mb-1 text-red-500">
                {t("saved-searches-error")}
              </div>
            )}
            {isLoading && (
              <div className="mb-1">
                <Spinner />
              </div>
            )}
            {savedSearches?.length === 0 && (
              <div className="mb-1">{t("no-saved-searches")}</div>
            )}
            {savedSearches?.map((savedSearch) => (
              <div className="flex items-center mb-1 ml-4" key={nanoid()}>
                <div
                  className={classnames("cursor-pointer", {
                    "font-bold": searchString === savedSearch.searchUrl,
                  })}
                  onClick={() => handleSelectSearch(savedSearch.searchUrl)}
                >
                  {savedSearch.name}
                </div>
                <RiDeleteBin7Fill
                  className="ml-2 cursor-pointer"
                  onClick={() => handleDelete(savedSearch.id)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </Box>
  )
}
