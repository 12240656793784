import { IIconType } from "shared/types/global.type"

function PromoleadLogo({ width = 16, height = 16 }: IIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 279.692 424.39"
    >
      <g
        style={{
          fill: "#038690",
          fillOpacity: 1,
          stroke: "none",
        }}
      >
        <path
          d="M2445 4678c-187-13-360-63-536-154-389-203-658-575-735-1019-13-71-15-139-11-325 4-202 8-252 30-355 65-302 260-769 532-1270 236-436 592-1064 619-1093 36-37 121-30 155 14 21 26 21 35 21 714v687l123 6c621 26 1152 482 1292 1110 26 115 31 392 10 519-46 276-174 532-368 735-299 313-692 462-1132 431zm237-839c74-16 170-61 229-108 270-215 295-604 54-847-118-118-241-169-410-169-158 1-283 53-395 165-142 141-199 339-155 529 49 204 219 379 418 427 66 16 191 17 259 3z"
          style={{
            fill: "#038690",
            fillOpacity: 1,
          }}
          transform="matrix(.1 0 0 -.1 -116.115 468.201)"
        />
      </g>
    </svg>
  )
}
export default PromoleadLogo
