import { useMutation } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import { TContributorCreate } from "shared/types/contributor.type"
import { TUserContributor } from "shared/types/user.type"
import {
  createContributor,
  deleteContributor,
  getContributorsByProjectId,
} from "../api/contributors"

const useGetContributorsByProjectIdQuery = (id: number) => ({
  queryKey: ["useGetContributorsByProjectId", id],
  queryFn: async () => {
    const contributors = await getContributorsByProjectId(id)
    return contributors
  },
})

function deleteContributorById(id: string) {
  return useMutation<unknown, CustomAxiosError, unknown>({
    mutationFn: () => deleteContributor(id),
  })
}

function useCreateContributor() {
  return useMutation<TUserContributor, CustomAxiosError, TContributorCreate>({
    mutationFn: (contributor) => createContributor(contributor),
  })
}

export {
  deleteContributorById,
  useCreateContributor,
  useGetContributorsByProjectIdQuery,
}
