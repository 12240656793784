import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import Box from "components/Box/Box"
import { Pagination } from "components/Pagination/Pagination"
import { SearchInput } from "components/SearchInput/SearchInput"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useGetProgressByChildOperationId,
  useValidateProgress,
} from "core/query-hooks/useProgress"
import IHOCLoader from "hoc/HocLoader"
import { t } from "i18next"
import { debounce } from "lodash"
import * as React from "react"
import { useEffect, useState } from "react"
import { MdCheckCircle, MdOutlineFiberManualRecord } from "react-icons/md"
import { TbTrendingUp } from "react-icons/tb"
import { toast } from "react-toastify"
import { useDebouncedCallback } from "use-debounce"
import useIsMobile from "utils/isMobile"
import { AddProgressModal } from "./AddProgressModal"
import EditProgressModal from "./EditProgressModal"
import "./ProgressTable.scss"

export interface IProgressListProps {
  childOperationId: number
}

export function ProgressList(props: IProgressListProps) {
  const { childOperationId } = props
  const [filterDone, setFilterDone] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [resultsLength] = useState(8)
  const [searchText, setSearchText] = React.useState<string>()
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null)
  const [sortColumn, setSortColumn] = useState<string>()
  const offset = (currentPage ? currentPage - 1 : 0) * resultsLength
  const queryClient = useQueryClient()
  const validate = useValidateProgress()
  const isMobile = useIsMobile()

  // Le back attend un parametre indiquant si un progress est isDefinitive ou pas
  // On renvoit !filterDone car si on veut masquer les progress réalisés on veut isDefinitive à false : soit l'inverse du toggle
  // On renvoit undefined sinon, car on veut les progress avec isDefinitive = true & isDefinitive = true et pas l'un ou l'autre
  const {
    data: progressData,
    error,
    isLoading,
    isError,
    refetch,
  } = useGetProgressByChildOperationId(
    childOperationId,
    searchText,
    offset,
    resultsLength,
    sortOrder,
    sortColumn,
    filterDone ? !filterDone : undefined,
  )

  useEffect(() => {
    refetch()
  }, [filterDone, sortColumn, sortOrder, searchText, offset, resultsLength])

  const handleSort = (field: string) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
    setSortColumn(field)
  }

  const handleFilterProgress = () => {
    setFilterDone(!filterDone)
    setCurrentPage(1)
  }

  const toggleValidateProgress = (
    progressId: number,
    isDefinitive: boolean,
  ) => {
    validate.mutate(
      { id: progressId, isDefinitive },
      {
        onSuccess: (data) => {
          data.isDefinitive
            ? toast.success(`${t("toast-progress-validated")}`)
            : toast.success(`${t("toast-progress-invalidated")}`)
          queryClient.invalidateQueries({
            queryKey: ["getProgressByChildOperationId", childOperationId],
          })
          queryClient.invalidateQueries({
            queryKey: ["getProgressById", data.id],
          })
        },
        onError: (err) => {
          const errorMessage =
            err.response?.status === 403
              ? `${t("toast-progress-update-error")} ${t("only-creator-and-target-can-validate-progress")}`
              : t("toast-progress-update-error")

          toast.error(errorMessage)
        },
      },
    )
  }

  const handleTextChange = useDebouncedCallback(
    React.useCallback(
      (value: string) => {
        setSearchText(value)
        setCurrentPage(1)
      },
      [searchText],
    ),
    500,
  )

  const handlePaginationOnDelete = () => {
    if (progressData) {
      // progressData.metadata.maxTotalHits - le 1 qui correspond à l'action supprimée
      const totalPages = Math.ceil(
        (progressData.metadata.maxTotalHits - 1) / resultsLength,
      )
      const newPage = currentPage > totalPages ? totalPages : currentPage
      setCurrentPage(newPage)
    }
  }

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage)
  }

  const displaySortOrder = (header: string) => {
    if (sortOrder && header === sortColumn) {
      return sortOrder === "asc" ? "↑" : "↓"
    }

    return null
  }

  return (
    <Box classNames="p-0">
      <div className="flex items-center justify-between border-gray-200 px-4 py-3">
        <div className="flex items-center text-cyan-900 font-medium">
          <TbTrendingUp />
          <h3 className="text-xs uppercase pl-2">{t("progress")}</h3>
        </div>
      </div>
      <div className="flex">
        <div className="Progress__Table w-full">
          <div className="px-2 pt-2 pr-1 md:pr-4 flex justify-between items-center flex-wrap">
            <div className={classNames(isMobile ? "w-full mb-2" : "w-1/3")}>
              <SearchInput
                searchLabel={`${t("progress-search")}`}
                handleChange={debounce(handleTextChange, 600)}
              />
            </div>
            <div className="flex items-center justify-end">
              <label
                htmlFor="progress-toggle-btn"
                className="mr-2 ml-1 text-sm Color__Primary"
              >
                {`${t("toggle-progress-done-button")} `}
                <ToggleSwitch
                  id="progress-toggle-btn"
                  name="toggle-not-done-progress"
                  disabled={false}
                  checked={filterDone}
                  onChange={handleFilterProgress}
                />
              </label>
              <PermissionsGuard requiredRoles={["create:progress"]}>
                <AddProgressModal childOperationId={childOperationId} />
              </PermissionsGuard>
            </div>
          </div>
          <div className={classNames({ "overflow-x-auto": isMobile })}>
            <IHOCLoader error={error} isLoading={isLoading} isError={isError}>
              <table className="w-full whitespace-pre-line">
                <thead>
                  <tr>
                    <th
                      className={classNames("text-left Table__Head", {
                        Table__Sticky_Column: !isMobile,
                      })}
                    >
                      {" "}
                    </th>
                    <th
                      className={classNames("text-left Table__Head md:w-48", {
                        Table__Sticky_Column: !isMobile,
                      })}
                      onClick={() => handleSort("createdAt")}
                    >
                      {`${t("creation-date")}`}
                      {displaySortOrder("createdAt")}
                    </th>
                    <th
                      className={classNames("text-left Table__Head", {
                        Table__Sticky_Column: !isMobile,
                      })}
                      onClick={() => handleSort("description")}
                    >
                      {`${t("description")}`} {displaySortOrder("description")}
                    </th>
                    <th
                      className="text-center Table__Head md:w-36"
                      onClick={() => handleSort("creator")}
                    >
                      {`${t("issuer")}`} {displaySortOrder("creator")}
                    </th>
                    <th
                      className="text-center Table__Head md:w-36"
                      onClick={() => handleSort("personInCharge")}
                    >
                      {`${t("personInChargeHeader")}`}
                      {displaySortOrder("personInCharge")}
                    </th>
                    <th
                      className="text-left Table__Head md:w-36"
                      onClick={() => handleSort("deadline")}
                    >
                      {`${t("deadline")}`} {displaySortOrder("deadline")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {progressData?.data?.map((prog, index) => (
                    <tr className="Table__Row" key={`progress-item-${prog.id}`}>
                      <td
                        className={classNames("Table__TD w-12", {
                          "border-none":
                            index === progressData.data.length - 1 &&
                            index !== progressData.data.length - 2,
                          Table__Border_Bottom:
                            index !== progressData.data.length - 1 &&
                            index !== progressData.data.length - 2,
                          Table__Sticky_Column: !isMobile,
                        })}
                      >
                        <span
                          onClick={() =>
                            toggleValidateProgress(prog.id, !prog.isDefinitive)
                          }
                          className="cursor-pointer items-center flex justify-center"
                        >
                          {prog.isDefinitive ? (
                            <MdCheckCircle size={20} color="#0CB28B" />
                          ) : (
                            <MdOutlineFiberManualRecord
                              className="text-slate-200 hover:text-emerald-600 hover:opacity-40"
                              size={26}
                            />
                          )}
                        </span>
                      </td>
                      <EditProgressModal
                        progress={prog}
                        border={
                          index !== progressData.data.length - 1 &&
                          index !== progressData.data.length - 2
                        }
                        onDeleteHandlePagination={() =>
                          handlePaginationOnDelete()
                        }
                      />
                    </tr>
                  ))}
                  {searchText &&
                    progressData?.data &&
                    progressData.data?.length === 0 && (
                      <tr>
                        <td className="text-center p-2 Color__Primary text-sm">
                          {`${t("no-progress-search-found-message")}`}{" "}
                          {searchText}
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </IHOCLoader>
          </div>
          {progressData && (
            <div className="Table__Page_Info_Container">
              <span className="Table__Page_Info_Content">
                {progressData.data.length > 0 ? (
                  <>
                    <p>
                      {(currentPage - 1) * resultsLength + 1} {`${t("to")} `}
                      {Math.min(
                        currentPage * resultsLength,
                        progressData.metadata.maxTotalHits,
                      )}
                    </p>
                    <p className="pl-1">
                      {` ${t("on")} ${progressData.metadata.maxTotalHits}`}
                    </p>
                  </>
                ) : null}
              </span>
            </div>
          )}
          {progressData &&
            progressData.metadata.maxTotalHits / resultsLength > 1 && (
              <Pagination
                previousLabel={`${t("previous")}`}
                nextLabel={`${t("next")}`}
                page={currentPage || 1}
                totalPages={Math.ceil(
                  progressData.metadata.maxTotalHits / resultsLength,
                )}
                handlePagination={(nextPage) => handlePageChange(nextPage)}
              />
            )}
        </div>
      </div>
    </Box>
  )
}
