import { useMutation, useQuery } from "@tanstack/react-query"
import {
  deleteSavedSearch,
  listSavedSearches,
  saveSearch,
} from "core/api/saved-searches"
import { CustomAxiosError } from "shared/types/axios.type"
import { TCreateSavedSeach, TSavedSearch } from "shared/types/saved-search.type"

function useSaveSearch() {
  return useMutation<TSavedSearch, CustomAxiosError, TCreateSavedSeach>({
    mutationFn: (savedSearch: TCreateSavedSeach) => saveSearch(savedSearch),
  })
}

function useListSavedSearches() {
  return useQuery<TSavedSearch[], CustomAxiosError>({
    queryKey: ["listSavedSearches"],
    queryFn: () => listSavedSearches(),
  })
}

function useDeleteSavedSearch() {
  return useMutation<TSavedSearch, CustomAxiosError, number>({
    mutationFn: (id: number) => deleteSavedSearch(id),
  })
}

export { useDeleteSavedSearch, useListSavedSearches, useSaveSearch }
