import useProgramsSearch from "features/programs/useProgramsSearch"
import { t } from "i18next"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { TTypeOperation } from "shared/types/operation.type"
import ProgramsListResults from "../../features/programs/ProgramsListResults"

const getCleanParam = (param: string | any[], isNumber = false) => {
  if (param.length === 0) return isNumber ? [] : ""
  return isNumber ? param[0]?.split(",").map(Number) : param[0]
}

export default function ProgramsSearchPage() {
  const navigate = useNavigate()
  const { state, dispatch } = useProgramsSearch()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const departmentParams = searchParams.getAll("departments")
    const companiesParams = searchParams.getAll("companies")
    const businessUnitsParams = searchParams.getAll("businessUnits")
    const stepsParams = searchParams.getAll("steps")
    const contributorsParams = searchParams.getAll("contributors")
    const searchTextParams = searchParams.getAll("search")
    const columnParams = searchParams.getAll("sortColumn")
    const orderParams = searchParams.getAll("sortOrder")
    const typesParams = searchParams.getAll("types")
    const skipParams = searchParams.get("skip")
    const pageParams = searchParams.get("page")

    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...state.filters,
        companies: getCleanParam(companiesParams, true),
        departments: getCleanParam(departmentParams, true),
        regions: getCleanParam(businessUnitsParams, true),
        steps: getCleanParam(stepsParams, true),
        users: Array.isArray(getCleanParam(contributorsParams))
          ? getCleanParam(contributorsParams)
          : [],
        types: getCleanParam(typesParams, true) as TTypeOperation[],
        take: 20,
        skip: skipParams ? parseInt(skipParams, 10) : 0,
        search: getCleanParam(searchTextParams),
        sortOrder: getCleanParam(orderParams),
        sortColumn: getCleanParam(columnParams),
        page: pageParams ? Number(pageParams) : 1,
      },
    })
  }, [])

  useEffect(() => {
    const newSearchParams = new URLSearchParams()
    const appendIfExists = (key: string, value: string | number | any[]) => {
      if (
        (typeof value === "string" || Array.isArray(value)) &&
        value.length > 0
      ) {
        newSearchParams.append(key, value.toString())
      } else if (typeof value === "number") {
        newSearchParams.append(key, value.toString())
      }
    }

    appendIfExists("companies", state.filters.companies)
    appendIfExists("departments", state.filters.departments)
    appendIfExists("steps", state.filters.steps)
    appendIfExists("businessUnits", state.filters.regions)
    appendIfExists("types", state.filters.types)
    appendIfExists("contributors", state.filters.users)
    appendIfExists("skip", state.filters.skip)
    appendIfExists("take", state.filters.take)
    appendIfExists("search", state.filters.search)
    appendIfExists("sortColumn", state.filters.sortColumn)
    appendIfExists("sortOrder", state.filters.sortOrder)
    appendIfExists("page", state.filters.page)

    setSearchParams(newSearchParams)
    localStorage.setItem("url", newSearchParams.toString())
  }, [state.filters, navigate])

  return (
    <div className="p-4">
      <h2 className="col-span-6 font-semibold md:mb-0 pb-4 Color__Primary">
        {t("program-list")}
      </h2>
      {searchParams.has("take") && <ProgramsListResults />}
    </div>
  )
}
