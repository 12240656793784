import { useMutation, useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TCreateTask,
  TDeleteTask,
  TTask,
  TUpdateTask,
} from "shared/types/task.type"
import {
  createTask,
  deleteTaskById,
  getTaskById,
  listTasksByAgreementId,
  listTasksByOperationId,
  updateTask,
} from "../api/tasks"

function useCreateTask() {
  return useMutation<TTask, CustomAxiosError, TCreateTask>({
    mutationFn: (task) => createTask(task),
  })
}

function useGetTaskById(id: string) {
  return useQuery<TTask, CustomAxiosError, TTask>({
    queryKey: ["task"],
    queryFn: () => getTaskById(id),
  })
}

function useUpdateTask(id: number) {
  return useMutation<TTask, CustomAxiosError, TUpdateTask>({
    mutationFn: (task) => updateTask(id, task),
  })
}

function useListTasksByAgreementId(agreementId: string) {
  return useQuery<TTask[], CustomAxiosError>({
    queryKey: ["listTasksByAgreementId", agreementId],
    queryFn: () => listTasksByAgreementId(agreementId),
  })
}

function useListTasksByOperationId(operationId: string) {
  return useQuery<TTask[], CustomAxiosError>({
    queryKey: ["listTasksByOperationId", operationId],
    queryFn: () => listTasksByOperationId(operationId),
  })
}

function useDeleteTask() {
  return useMutation<unknown, CustomAxiosError, TDeleteTask>({
    mutationFn: (id) => deleteTaskById(id),
  })
}

export {
  useCreateTask,
  useDeleteTask,
  useGetTaskById,
  useListTasksByAgreementId,
  useListTasksByOperationId,
  useUpdateTask,
}
