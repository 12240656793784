const truncateStringFromMiddle = (fullStr: string, strLength: number) => {
  if (fullStr.length <= strLength) return fullStr
  const midString = "..."
  const charsToShow = strLength - midString.length
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)
  return `${fullStr.substring(0, frontChars)}${midString}${fullStr.substring(
    fullStr.length - backChars,
  )}`
}

export default truncateStringFromMiddle
