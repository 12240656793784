import { useQuery } from "@tanstack/react-query"
import { t } from "i18next"
import { usersOccupationsQuery } from "../../core/query-hooks/useUsers"
import ContributorsRow from "./ContributorsRow"
import "./ContributorsTable.scss"

export default function ContributorsTable() {
  const { data: usersOccupationsData } = useQuery({
    ...usersOccupationsQuery(),
  })

  return (
    <div className="Contributors__Table_Wrapper overflow-x-auto shadow-lg">
      <table className="Contributors__Table md:table-fixed">
        <thead>
          <tr>
            <th className="text-left Contributor__Table_Head Border__Bottom_LightGrey w-1/4">
              {`${t("occupation")}`}
            </th>
            <th className="text-left Contributor__Table_Head Border__Bottom_LightGrey w-3/4">
              {`${t("contributors")}`}
            </th>
          </tr>
        </thead>
        <tbody>
          {usersOccupationsData?.map((d, index, array) => (
            <ContributorsRow
              key={`${d.id}-contributor-occupation`}
              id={d.id}
              name={d.label}
              isLast={index === array.length - 1} // Différenciation du dernier élément pour les tooltips
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
