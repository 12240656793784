import { useMutation, useQuery } from "@tanstack/react-query"
import {
  createProgress,
  deleteProgress,
  getProgressByChildOperationId,
  getProgressById,
  hasModificationRight,
  updateProgress,
  validateProgress,
} from "core/api/progress"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TMutateProgress,
  TProgress,
  TProgressList,
  TValidateProgress,
} from "shared/types/progress.type"

function useGetProgressByChildOperationId(
  childOperationId?: number,
  query?: string,
  skip?: number,
  take?: number,
  order?: "asc" | "desc" | null,
  sort?: string,
  done?: boolean,
) {
  return useQuery<TProgressList, CustomAxiosError>({
    queryKey: [
      "getProgressByChildOperationId",
      childOperationId,
      query,
      skip,
      take,
      order,
      sort,
      done,
    ],
    queryFn: () =>
      getProgressByChildOperationId(
        childOperationId,
        query,
        skip,
        take,
        order,
        sort,
        done,
      ),
    ...{
      enabled: Boolean({
        query,
        childOperationId,
        skip,
        take,
      }),
      throwOnError: (error) =>
        !!(error.response?.status && error.response?.status >= 500),
    },
  })
}

function useGetProgressById(progressId?: number) {
  return useQuery<TProgress, CustomAxiosError>({
    queryKey: ["getProgressById", progressId],
    queryFn: () => getProgressById(progressId),
  })
}

function useCreateProgress() {
  return useMutation<TProgress, CustomAxiosError, TMutateProgress>({
    mutationFn: (progress: TMutateProgress) => createProgress(progress),
  })
}

function useUpdateProgress(id: number) {
  return useMutation<TProgress, CustomAxiosError, TMutateProgress>({
    mutationFn: (progress: TMutateProgress) => updateProgress(id, progress),
  })
}

function useDeleteProgress(id: number) {
  return useMutation<TProgress, CustomAxiosError, number>({
    mutationFn: () => deleteProgress(id),
  })
}

function useValidateProgress() {
  return useMutation<TProgress, CustomAxiosError, TValidateProgress>({
    mutationFn: (progress: TValidateProgress) => validateProgress(progress),
  })
}

function useProgressModificationRight(progressId: number) {
  return useQuery<boolean, CustomAxiosError>({
    queryKey: ["getProgressModificationRight", progressId],
    queryFn: () => hasModificationRight(progressId),
  })
}

export {
  useCreateProgress,
  useDeleteProgress,
  useGetProgressByChildOperationId,
  useGetProgressById,
  useProgressModificationRight,
  useUpdateProgress,
  useValidateProgress,
}
