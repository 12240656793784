import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import SpoAxiosInterceptor from "core/interceptors/SpoInterceptor"
import { useListChildOperations } from "core/query-hooks/useOperations"
import OperationChildItemCommercialization from "features/operations/OperationChildItemCommercialization"
import i18next from "i18next"
import { useParams } from "react-router-dom"
import DivStickyMenu from "./DivStickyMenu"

export function OperationChildrenList() {
  const { id } = useParams()
  const {
    data: operationsChildren,
    isLoading,
    error,
  } = useListChildOperations(Number(id!))

  if (isLoading) return <Spinner />

  if (error)
    return (
      <p>
        {`${i18next.t("error")}`} : {error.message}
      </p>
    )

  operationsChildren?.sort((a, b) => a.id - b.id)

  return (
    <Tabs isOperationChildren>
      {operationsChildren!.map((operation) => {
        return (
          <TabContent
            title={operation.registrationNumber}
            key={operation.registrationNumber}
          >
            <SpoAxiosInterceptor>
              <OperationChildItemCommercialization operationChild={operation} />
            </SpoAxiosInterceptor>
          </TabContent>
        )
      })}
    </Tabs>
  )
}

export function OperationCommercialization() {
  return (
    <>
      <DivStickyMenu />
      <div className="p-4 pb-32">
        <Box>
          <OperationChildrenList />
        </Box>
      </div>
    </>
  )
}
