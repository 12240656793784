import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { Button } from "components"
import Box from "components/Box/Box"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import { useCreateChildOperation } from "core/query-hooks/useOperations"
import { t } from "i18next"
import { useState } from "react"
import { AiFillWarning } from "react-icons/ai"
import { BsFillBuildingsFill, BsFillHousesFill } from "react-icons/bs"
import { HiBuildingOffice } from "react-icons/hi2"
import { toast } from "react-toastify"
import { TERTIARY_BUSINESS_UNIT_ID } from "shared/resources/business.resources"
import {
  HOUSING_ESTATE_TYPE,
  RESIDENTIAL_TYPE,
  TERTIARY_TYPE,
} from "shared/resources/operation.resources"
import { TTypeOperation } from "shared/types/operation.type"

export interface ICreateOperationModalProps {
  projectId: number
  businessUnitId: number
}

export function CreateOperationChildModal(props: ICreateOperationModalProps) {
  const { projectId, businessUnitId } = props
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const queryClient = useQueryClient()
  const createOperation = useCreateChildOperation()
  const [isLoading, setIsLoading] = useState(false)
  const [operationType, setOperationType] = useState<TTypeOperation | null>(
    null,
  )

  const createOperationChild = () => {
    if (operationType) {
      setIsLoading(true)
      createOperation.mutate(
        {
          operationId: projectId,
          typeId: operationType,
          businessUnitId:
            operationType === TERTIARY_TYPE.id
              ? TERTIARY_BUSINESS_UNIT_ID
              : businessUnitId,
        },
        {
          onSuccess: () => {
            toast.success(`${t("toast-create-operation-success")}`)
            queryClient.invalidateQueries({
              queryKey: ["listChildOperations", +projectId!],
            })
            queryClient.invalidateQueries({
              queryKey: ["operation", +projectId!],
            })
            setIsLoading(false)
            setOperationType(null)
            toggleModal()
          },
          onError: (err) => {
            toast.error(
              `${t("toast-create-operation-error")} :       
              ${
                err.response?.status === 403
                  ? t("insufficient-rights")
                  : err.response?.data.message
              }`,
            )
            setIsLoading(false)
          },
        },
      )
    }
  }

  return (
    <>
      <button
        onClick={toggleModal}
        className="border hover:bg-primary-light hover:border-primary-light hover:text-white bg-white border-gray-500 text-primary-dark font-semibold w-7 h-7 rounded-full flex items-center justify-center focus:outline-none"
        type="button"
      >
        +
      </button>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("childOperationType")}`}
        displayCloseIcon
      >
        <p className="Color__Primary text-sm mb-4">
          {`${t("childOperationTypeDescription")}`}
        </p>

        {isLoading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Box classNames="p-0">
              <div
                className={classNames(
                  "rounded-lg Color__Primary flex items-center flex-col justify-center w-full p-4 transition-colors hover:bg-slate-100 ease-in-out duration-75 cursor-pointer",
                  operationType === RESIDENTIAL_TYPE.id
                    ? "bg-gray-200 border"
                    : "",
                )}
                onClick={() => setOperationType(RESIDENTIAL_TYPE.id)}
              >
                <BsFillBuildingsFill opacity={0.8} fontSize={32} />
                <span className="mt-2">{`${t("residential")}`}</span>
              </div>
            </Box>

            <Box classNames="mt-4 p-0">
              <div
                className={classNames(
                  "rounded-lg Color__Primary flex items-center flex-col justify-center w-full p-4 transition-colors hover:bg-slate-100 ease-in-out duration-75 cursor-pointer",
                  operationType === TERTIARY_TYPE.id
                    ? "bg-gray-200 border"
                    : "",
                )}
                onClick={() => setOperationType(TERTIARY_TYPE.id)}
              >
                <HiBuildingOffice opacity={0.8} fontSize={32} />
                <span className="mt-2">{`${t("tertiary-business-park")}`}</span>
              </div>
            </Box>

            <Box classNames="mt-4 p-0">
              <div
                className={classNames(
                  "rounded-lg Color__Primary flex items-center flex-col justify-center w-full p-4 transition-colors hover:bg-slate-100 ease-in-out duration-75 cursor-pointer",
                  operationType === HOUSING_ESTATE_TYPE.id
                    ? "bg-gray-200 border"
                    : "",
                )}
                onClick={() => setOperationType(HOUSING_ESTATE_TYPE.id)}
              >
                <BsFillHousesFill opacity={0.8} fontSize={32} />
                <span className="mt-2">{`${t("housing-estate")}`}</span>
              </div>
            </Box>
          </>
        )}
        {operationType ? (
          <div className="Modal__Body mt-4">
            <div className="inline-flex items-center justify-center  text-red-500  w-full">
              <AiFillWarning />
              <p className="text-xs font-bold mx-2">
                {t("once-created-cannot-be-deleted")}
              </p>
              <AiFillWarning />
            </div>

            <div className="Modal__Footer mt-2">
              <Button
                size="medium"
                mode="secondary"
                isLoading={isLoading}
                onClick={() => toggleModal()}
              >
                {`${t("cancel")}`}
              </Button>
              <Button
                marginLeft={24}
                size="medium"
                mode="danger"
                isLoading={isLoading}
                onClick={() => createOperationChild()}
              >
                {`${t("confirm")}`}
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  )
}
