import { useQueryClient } from "@tanstack/react-query"
import { useValidateAction } from "core/query-hooks/useActions"
import { useValidateProgress } from "core/query-hooks/useProgress"
import { format } from "date-fns"
import { t } from "i18next"
import { MdCheckCircle, MdOutlineFiberManualRecord } from "react-icons/md"
import { toast } from "react-toastify"

export interface IExpectedTaskToggleCellProps {
  todo: {
    id: number
    isAction: boolean
    deadline: Date
    description: string
    creator: string | null
    isDefinitive: boolean
  }
  stringifiedCurrentDate: string
  stringifiedFutureDate: string
  queryKey: string
}

export default function ExpectedTodoToggleCell(
  props: IExpectedTaskToggleCellProps,
) {
  const validateAction = useValidateAction()
  const validateProgress = useValidateProgress()
  const { todo, stringifiedCurrentDate, stringifiedFutureDate, queryKey } =
    props
  const queryClient = useQueryClient()
  const oneMonthDate = new Date()
  oneMonthDate.setMonth(oneMonthDate.getMonth() + 1)
  const stringifiedOneMonthDate = format(oneMonthDate, "yyyy-MM-dd")

  const toggleValidateProgress = () => {
    validateProgress.mutate(
      { id: todo.id, isDefinitive: true },
      {
        onSuccess: () => {
          toast.success(`${t("toast-progress-validated")}`)
          queryClient.invalidateQueries({
            queryKey: [queryKey, stringifiedCurrentDate],
          })
          queryClient.invalidateQueries({
            queryKey: [queryKey, stringifiedFutureDate],
          })
          queryClient.invalidateQueries({
            queryKey: [queryKey, stringifiedOneMonthDate],
          })
          queryClient.invalidateQueries({
            queryKey: [queryKey, ""],
          })
        },
        onError: (err) => {
          toast.error(
            `${t("toast-progress-update-error")} ${
              err.response?.data.error.message
            }`,
          )
        },
      },
    )
  }

  const toggleValidateAction = () => {
    validateAction.mutate(
      { id: todo.id, isDefinitive: true },
      {
        onSuccess: () => {
          toast.success(`${t("toast-action-validated")}`)
          queryClient.invalidateQueries({
            queryKey: [queryKey, stringifiedCurrentDate],
          })
          queryClient.invalidateQueries({
            queryKey: [queryKey, stringifiedFutureDate],
          })
          queryClient.invalidateQueries({
            queryKey: [queryKey, stringifiedOneMonthDate],
          })
          queryClient.invalidateQueries({
            queryKey: [queryKey, ""],
          })
        },
        onError: (err) => {
          toast.error(
            `${t("toast-action-update-error")} ${
              err.response?.data.error.message
            }`,
          )
        },
      },
    )
  }

  return (
    <span
      onClick={() => {
        todo.isAction ? toggleValidateAction() : toggleValidateProgress()
      }}
      className="cursor-pointer items-center flex justify-center"
    >
      {todo.isDefinitive ? (
        <MdCheckCircle size={20} color="#0CB28B" />
      ) : (
        <MdOutlineFiberManualRecord
          className="text-slate-200 hover:text-emerald-600 hover:opacity-40"
          size={26}
        />
      )}
    </span>
  )
}
