import { AxiosError } from "axios"
import { API } from "core/api/axios"

async function getPartialBatches(registrationNumber: string): Promise<number> {
  return !registrationNumber.startsWith("PR")
    ? Promise.reject(new Error("Invalid immat"))
    : API.get(`/partial-batches/${registrationNumber}`)
        .then((response) => response.data)
        .catch((error: AxiosError) => {
          return Promise.reject(error)
        })
}

export default getPartialBatches
