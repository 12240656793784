import { Button } from "components"
import ButtonIcon from "components/Button/ButtonIcon"
import { Modal } from "components/Modal/Modal"
import { t } from "i18next"
import { useState } from "react"
import { BiSolidMessageSquareError } from "react-icons/bi"
import { TCommentType } from "shared/types/study.type"

export interface IDisplayStandbyCommentProps {
  comment: TCommentType
}

export function DisplayStandbyComment(props: IDisplayStandbyCommentProps) {
  const { comment } = props
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <div>
      <ButtonIcon
        className="text-orange-400"
        onClick={() => setShowModal(true)}
      >
        <BiSolidMessageSquareError size={24} />
      </ButtonIcon>
      <Modal
        isShowing={showModal}
        closeModal={() => setShowModal(false)}
        title={t("standby-comment")}
        description={`${t("by")} ${comment.creator.firstName} ${comment.creator.lastName}`}
      >
        <div className="Text__Field_Container mb-2">
          <label className="font-medium" htmlFor="comment">
            {`${t("comment")}`}
            <textarea
              className="Textarea__Field h-48"
              placeholder={`${t("comment")}`}
              id="comment"
              value={comment.comment}
              disabled
            />
          </label>
        </div>
        <div className="flex justify-end">
          <Button
            size="medium"
            mode="primary"
            onClick={() => setShowModal(false)}
          >
            {t("close")}
          </Button>
        </div>
      </Modal>
    </div>
  )
}
