import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import BatiscriptLogo from "features/external-apps-links/logos/BatiscriptLogo"
import CentralParcLogo from "features/external-apps-links/logos/CentralParcLogo"
import DocuwareLogo from "features/external-apps-links/logos/DocuwareLogo"
import FreshserviceLogo from "features/external-apps-links/logos/FreshserviceLogo"
import JenjiLogo from "features/external-apps-links/logos/JenjiLogo"
import PromoleadLogo from "features/external-apps-links/logos/PromoleadLogo"
import SageLogo from "features/external-apps-links/logos/SageLogo"
import SpoLogo from "features/external-apps-links/logos/SpoLogo"
import YoozLogo from "features/external-apps-links/logos/YooeLogo"

export default function ExternalAppsLinks() {
  return (
    <div className="flex flex-col md:flex-row md:w-1/3 justify-between md:items-center gap-x-2 gap-y-2">
      <TooltipWithHover message="Promolead" classNames="cursor-pointer">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_PROMOLEAD_URL}`}
        >
          <div className="border rounded-lg border-gray-200 bg-white p-2 max-w-18 max-h-16 shadow-lg">
            <h3 className="flex items-center justify-center">
              <PromoleadLogo width={32} height={32} />
            </h3>
          </div>
        </a>
      </TooltipWithHover>

      <TooltipWithHover message="SPO" classNames="cursor-default">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_SPO_URL}#/tools/login/login`}
        >
          <div className="border rounded-lg border-gray-200 p-2 relative bg-white max-w-18 max-h-16 shadow-lg">
            <h3 className="flex items-center justify-center">
              <SpoLogo width={32} height={32} />
            </h3>
          </div>
        </a>
      </TooltipWithHover>

      <TooltipWithHover message="Batiscript" classNames="cursor-default">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_BATISCRIPT_URL}#/tools/login/login`}
        >
          <div className="border rounded-lg border-gray-200 p-2 relative bg-white max-w-18 max-h-16 shadow-lg">
            <h3 className="flex items-center justify-center">
              <BatiscriptLogo width={32} height={32} />
            </h3>
          </div>
        </a>
      </TooltipWithHover>

      <TooltipWithHover message="SAGE 1000" classNames="cursor-default">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_SAGE_URL}`}
        >
          <div className="border rounded-lg border-gray-200 p-2 relative bg-white  max-w-18 max-h-16 shadow-lg">
            <h3 className="flex items-center justify-center">
              <SageLogo width={45} height={32} />
            </h3>
          </div>
        </a>
      </TooltipWithHover>

      <TooltipWithHover message="Central Parc" classNames="cursor-pointer">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_CENTRAL_PARC_URL}`}
        >
          <div className="border rounded-lg border-gray-200 p-2 relative bg-white  max-w-18 max-h-16 shadow-lg">
            <h3 className="flex items-center justify-center">
              <CentralParcLogo width={45} height={35} />
            </h3>
          </div>
        </a>
      </TooltipWithHover>

      <TooltipWithHover
        message="Gestion électronique de documents"
        classNames="cursor-pointer"
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_GED_URL}`}
        >
          <div className="border rounded-lg border-gray-200 p-2 relative bg-white  max-w-18 max-h-16 shadow-lg">
            <h3 className="flex items-center justify-center">
              <DocuwareLogo width={32} height={32} />
            </h3>
          </div>
        </a>
      </TooltipWithHover>

      <TooltipWithHover message="Jenji" classNames="cursor-pointer">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_JENJI_URL}`}
        >
          <div className="border rounded-lg border-gray-200 p-2 relative bg-white  max-w-18 max-h-16 shadow-lg">
            <h3 className="flex items-center justify-center">
              <JenjiLogo width={32} height={32} />
            </h3>
          </div>
        </a>
      </TooltipWithHover>

      <TooltipWithHover message="Yooz" classNames="cursor-default">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_YOOZ_URL}`}
        >
          <div className="border rounded-lg border-gray-200 p-2 relative bg-white  max-w-18 max-h-16 shadow-lg">
            <h3 className="flex items-center justify-center">
              <YoozLogo width={40} height={32} />
            </h3>
          </div>
        </a>
      </TooltipWithHover>

      <TooltipWithHover message="Fresh service" classNames="cursor-pointer">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_FRESH_SERVICE_URL}`}
        >
          <div className="border rounded-lg border-gray-200 p-2 relative bg-white  max-w-18 max-h-16 shadow-lg">
            <h3 className="flex items-center justify-center">
              <FreshserviceLogo width={32} height={32} />
            </h3>
          </div>
        </a>
      </TooltipWithHover>
    </div>
  )
}
