import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import GedFile from "components/Ged/GedFile"
import { Modal } from "components/Modal/Modal"
import StandByHistory from "components/StandByHistory/StandByHistory"
import useDeleteDocument from "core/query-hooks/useDocuments"
import { useAddFeasibilityDocuments } from "core/query-hooks/useFeasibilities"
import {
  useAddCommentToStudyRequest,
  useUpdateStatus,
  useUpdateStudyRequest,
} from "core/query-hooks/useStudyRequests"
import { AddFile } from "features/studies/AddFile"
import {
  FEASIBILITY_RESTITUTION_GED_CAT,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
} from "features/studies/studyRequest.resources"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { sortByDate } from "pages/dashboards/study-requests/dashboard-util"
import { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { RiDeleteBin2Fill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { STAND_BY_RESTITUTION_COMMENT } from "shared/resources/study-request.resources"
import { TGedUploadResponse } from "shared/types/ged.type"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"
import * as yup from "yup"

const feasibilityRestitutionSchema = yup.object({
  numberOfLotsReturned: yup
    .number()
    .required(() => `${t("feasibility-restitution-batches-validation")}`)
    .positive(
      () => `${t("feasibility-restitution-batches-positive-validation")}`,
    )
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  creationNumberOfHours: yup
    .number()
    .positive(() => `${t("feasibility-restitution-hours-positive-validation")}`)
    .required(() => `${t("feasibility-restitution-hours-validation")}`)
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .test(
      "is-integer",
      t("feasibility-restitution-hours-integer-validation"),
      (value) => Number.isInteger(value),
    )
    .nullable(),
  files: yup
    .array()
    .of(yup.number())
    .min(1, () => `${t("feasibility-restitution-files-validation")}`)
    .required(() => `${t("feasibility-restitution-files-validation")}`),
})

type FormValues = {
  numberOfLotsReturned: number
  creationNumberOfHours: number
  files: number[]
}

export default function FeasibilityRestitutionForm(props: {
  studyRequestType: number
  requestId: number
  studyRequest: TStudyRequest
  studyRequestHistory: TStudyRequestHistory[]
  categoryId: number
}) {
  const { requestId, studyRequest, studyRequestHistory, categoryId } = props
  const { studyRequestId, id } = useParams()
  const updateStudyRequest = useUpdateStudyRequest(requestId)
  const updateStudyRequestStatus = useUpdateStatus(requestId)
  const addDocuments = useAddFeasibilityDocuments(+studyRequestId!)
  const deleteDocument = useDeleteDocument()
  const addCommentToStudyRequest = useAddCommentToStudyRequest(requestId!)
  const queryClient = useQueryClient()
  const methods = useForm<FormValues>({
    resolver: yupResolver(
      feasibilityRestitutionSchema as yup.ObjectSchema<FormValues>,
    ),
  })
  const standbyRestitutionComment = studyRequest?.comments
    ?.filter((c) => c.type.id === STAND_BY_RESTITUTION_COMMENT.id)
    .sort((a, b) => sortByDate(a.creationDate, b.creationDate))[0]?.comment
  const [formFiles, setFormFiles] = useState<TGedUploadResponse[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [standbyComment, setStandbyComment] = useState<string | undefined>(
    standbyRestitutionComment ?? undefined,
  )
  const [onStandby, setOnStandby] = useState<boolean>(
    studyRequest.statusId === STATUS_RESTITUTION_STANDBY,
  )

  const invalidateQueriesData = () => {
    queryClient.invalidateQueries({ queryKey: ["getFeasibility", requestId] })
    queryClient.invalidateQueries({ queryKey: ["getCostOfWork", requestId] })
    queryClient.invalidateQueries({ queryKey: ["getInfography", requestId] })
    queryClient.invalidateQueries({ queryKey: ["listStudyRequests", id] })
    queryClient.invalidateQueries({
      queryKey: ["getDocumentsByCategoryAndProjectId", id, categoryId],
    })
  }

  const invalidatesQueriesHistory = () => {
    queryClient.invalidateQueries({
      queryKey: ["getFeasibilityHistory", requestId],
    })
    queryClient.invalidateQueries({
      queryKey: ["getCostOfWorkHistory", requestId],
    })
    queryClient.invalidateQueries({
      queryKey: ["getInfographyHistory", requestId],
    })
  }

  const startingStandbyHistory = studyRequestHistory?.find(
    (srh) => srh.statusId === STATUS_RESTITUTION_STANDBY,
  )

  const endingStandbyHistory = studyRequestHistory?.filter(
    (srh) => srh.statusId === STATUS_PRIORIZED_AND_ASSIGNED,
  )

  useEffect(() => {
    return methods.setValue(
      "files",
      formFiles?.map((file) => file.id),
    )
  }, [formFiles])

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateStudyRequest.mutate(
      {
        creationNumberOfHours: data.creationNumberOfHours,
        numberOfLotsReturned: data.numberOfLotsReturned,
      },
      {
        onSuccess: () => {
          addDocuments.mutate(
            {
              categories: [
                {
                  categoryId: FEASIBILITY_RESTITUTION_GED_CAT.id,
                  gedDocuments: data.files,
                },
              ],
            },
            {
              onSuccess() {
                updateStudyRequestStatus.mutate(
                  {
                    statusId: STATUS_RETURNED,
                  },
                  {
                    onSuccess: () => {
                      toast.success(
                        `${t("studyRequest.toast.restitutionSuccess")}`,
                      )
                      invalidateQueriesData()
                      invalidatesQueriesHistory()
                    },
                    onError: (err) =>
                      toast.error(
                        `${t("studyRequest.toast.restitutionError")} ${
                          err.response?.data.message
                        }`,
                      ),
                  },
                )
              },
              onError: (err) =>
                toast.error(
                  `${t("studyRequest.toast.restitutionError")} ${
                    err.response?.data.message
                  }`,
                ),
            },
          )
        },
        onError: (err) =>
          toast.error(
            `${t("studyRequest.toast.restitutionError")} ${
              err.response?.data.message
            }`,
          ),
      },
    )
  }

  async function handleDelete(docId: number) {
    await deleteDocument.mutateAsync(docId, {
      onSuccess() {
        setFormFiles(formFiles.filter((file) => file.id !== docId))
        return toast.success(t("file-deleted"))
      },
      onError() {
        toast.error(t("file-deleted-error"))
      },
    })
  }

  const uploadedFiles = formFiles.map((file) => (
    <li className="flex items-center justify-start" key={file.id}>
      <GedFile stringLength={17} id={file.id} title={file.title} />
      <button
        className="opacity-50 hover:opacity-75 transition-opacity ease-in-out delay-75"
        type="button"
      >
        <RiDeleteBin2Fill
          className="ml-1"
          onClick={() => handleDelete(file.id)}
        />
      </button>
    </li>
  ))

  const updateStatus = (
    statusId: number,
    successMessage: string,
    errorMessage: string,
    standBy: boolean,
  ) => {
    updateStudyRequestStatus.mutate(
      {
        statusId,
      },
      {
        onSuccess: () => {
          if (standBy && standbyComment) {
            addCommentToStudyRequest.mutate(
              {
                typeId: STAND_BY_RESTITUTION_COMMENT.id,
                comment: standbyComment,
              },
              {
                onSuccess() {
                  invalidateQueriesData()
                },
                onError(error) {
                  toast.error(error.response?.data.error.message)
                },
              },
            )
          }
          setShowModal(false)
          toast.success(successMessage)
          invalidateQueriesData()
          invalidatesQueriesHistory()
          setOnStandby(standBy)
        },
        onError: (err) => {
          const errMessage = err.response?.data.message
          if (errMessage?.startsWith("Documents missing")) {
            const missingDocs = errMessage?.split(": ")?.pop()?.split(", ")
            toast.error(
              <>
                <p>{`${t("label-mandatory-documents-list")}`}</p>
                <ul>
                  {missingDocs?.map((doc) => (
                    <li key={nanoid()}>
                      <p>- {doc}</p>
                    </li>
                  ))}
                </ul>
              </>,
            )
          } else {
            toast.error(`${errorMessage} ${err.response?.data.message}`)
          }
        },
      },
    )
  }

  const onChangeStandby = (changedStudyRequest: TStudyRequest) => {
    if (changedStudyRequest.statusId === STATUS_PRIORIZED_AND_ASSIGNED) {
      updateStatus(
        STATUS_RESTITUTION_STANDBY,
        `${t("studyRequest.toast.addStandbySuccess")}`,
        `${t("studyRequest.toast.addStandbyError")}`,
        true,
      )
    }

    if (changedStudyRequest.statusId === STATUS_RESTITUTION_STANDBY) {
      updateStatus(
        STATUS_PRIORIZED_AND_ASSIGNED,
        `${t("studyRequest.toast.removeStandbySuccess")}`,
        `${t("studyRequest.toast.removeStandbyError")}`,
        false,
      )
    }
  }

  return (
    <FormProvider {...methods}>
      <form
        className="Text__Field_Container"
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="flex-1 pb-2 pt-4">
          <Button
            type="button"
            size="small"
            mode="validation"
            onClick={
              onStandby
                ? () => onChangeStandby(studyRequest)
                : () => setShowModal(true)
            }
            classNames={onStandby ? "validation" : "appleGreen"}
            isDisabled={updateStudyRequestStatus.isPending}
          >
            {onStandby
              ? `${t("studyRequest.removeStandby")}`
              : `${t("studyRequest.putOnStandby")}`}
          </Button>
        </div>
        <div className="flex-1 mt-1 grid grid-cols-1 mb-2">
          {startingStandbyHistory ? (
            <StandByHistory
              classNames="text-xs step-more-info"
              label={`${t("studyRequest.startingDate")}`}
              date={startingStandbyHistory.date}
            />
          ) : null}
          {endingStandbyHistory &&
          endingStandbyHistory.length > 1 &&
          !onStandby ? (
            <StandByHistory
              classNames="text-xs step-more-info"
              label={`${t("studyRequest.endingDate")}`}
              date={endingStandbyHistory[0].date}
            />
          ) : null}
        </div>
        <div>
          <label htmlFor="numberOfLotsReturned">
            {t("feasibility-restitution-followup-form-number-of-lots-label")} *
            <input
              id="numberOfLotsReturned"
              className="Text__Field"
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              disabled={onStandby}
              placeholder={`${t(
                "feasibility-restitution-followup-form-number-of-lots-placeholder",
              )}`}
              {...methods.register("numberOfLotsReturned")}
            />
          </label>
          {methods.formState.errors.numberOfLotsReturned && (
            <span className="text-xs text-red-500">
              {methods.formState.errors.numberOfLotsReturned.message}
            </span>
          )}
        </div>
        <div>
          <label htmlFor="creationNumberOfHours">
            {t("feasibility-restitution-followup-form-number-of-hours-label")} *
            <input
              id="creationNumberOfHours"
              className="Text__Field"
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              disabled={onStandby}
              placeholder={`${t(
                "feasibility-restitution-followup-form-number-of-hours-placeholder",
              )}`}
              {...methods.register("creationNumberOfHours")}
            />
          </label>
          {methods.formState.errors.creationNumberOfHours && (
            <span className="text-xs text-red-500">
              {methods.formState.errors.creationNumberOfHours.message}
            </span>
          )}
        </div>
        {!onStandby ? (
          <div className="mt-2 flex flex-col">
            <div className="flex items-center justify-between">
              <p>{t("followup-restitution-files-label")} *</p>
              <AddFile
                files={formFiles}
                setFiles={setFormFiles}
                documentTypes={[FEASIBILITY_RESTITUTION_GED_CAT.name]}
                numStatus="1"
                linkedFiles
              />
            </div>
            <div>
              {methods.formState.errors.files && formFiles.length <= 0 ? (
                <span className="text-xs text-red-500">
                  {methods.formState.errors.files.message}
                </span>
              ) : null}
            </div>
          </div>
        ) : null}
        <div>
          {formFiles.length > 0 && <ul className="mt-2">{uploadedFiles}</ul>}
        </div>
        <Button
          classNames="mt-3"
          mode="primary"
          type="submit"
          size="small"
          isDisabled={
            onStandby ||
            updateStudyRequestStatus.isPending ||
            updateStudyRequest.isPending
          }
        >
          {t("feasibility-restitution-followup-form-submit-button")}
        </Button>
      </form>
      <Modal
        isShowing={showModal}
        closeModal={() => setShowModal(false)}
        title={t("put-in-standby")}
      >
        <div className="Text__Field_Container mb-2">
          <label className="font-medium" htmlFor="comment">
            {`${t("comment")}`}
            <textarea
              className="Textarea__Field h-48"
              placeholder={`${t("comment")}`}
              id="comment"
              value={standbyComment}
              onChange={(e) => setStandbyComment(e.target.value)}
            />
          </label>
        </div>
        <div className="flex justify-end">
          <Button
            size="medium"
            mode="secondary"
            onClick={() => setShowModal(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            size="medium"
            mode="primary"
            onClick={() => onChangeStandby(studyRequest)}
            marginLeft={24}
            isLoading={updateStudyRequestStatus.isPending}
          >
            {t("confirm")}
          </Button>
        </div>
      </Modal>
    </FormProvider>
  )
}
