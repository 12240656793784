import { API } from "core/api/axios"
import { TBank } from "shared/types/bank.type"

async function getBanks(): Promise<TBank[]> {
  const res = await API.get("/banks")
  return res.data
}

async function addBank(data: { label: string }): Promise<TBank> {
  const res = await API.post("/bank", data)
  return res.data
}

export { addBank, getBanks }
