import { useGetTeamTodos } from "core/query-hooks/useTodos"
import { add, format, isBefore } from "date-fns"

export default function useCountTeamTodos() {
  const today = new Date()
  function getCurrentDate() {
    const formattedDate = format(today, "yyyy-MM-dd")
    return formattedDate
  }

  function getDateAfter30Days() {
    const dateAfter30Days = add(today, { days: 30 })
    const formattedDate = format(dateAfter30Days, "yyyy-MM-dd")
    return formattedDate
  }

  const stringifiedCurrentDate = getCurrentDate()
  const stringifiedFutureDate = getDateAfter30Days()

  const {
    data: todosData,
    isLoading: lateTodosDataIsLoading,
    error: lateTodosDataError,
  } = useGetTeamTodos(stringifiedCurrentDate)

  const {
    data: toCurrentMonthTodosData,
    isLoading: currentMonthTodosDataIsLoading,
    error: currentMonthTodosDataError,
  } = useGetTeamTodos(stringifiedFutureDate)

  const isLoading = lateTodosDataIsLoading || currentMonthTodosDataIsLoading

  const error = lateTodosDataError || currentMonthTodosDataError

  const lateMonthActionsData = todosData?.actions
    .filter(
      (action) =>
        isBefore(new Date(action.deadline), new Date(Date.now())) &&
        !action.isDefinitive,
    )
    .map((action) => {
      return {
        id: action.id,
        createdAt: action.createdAt,
        isAction: true,
        deadline: action.deadline,
        description: action.description,
        creator: action.creator
          ? `${action.creator.firstName} ${action.creator.lastName}`
          : null,
        personInCharge: action.personInCharge
          ? `${action.personInCharge.firstName} ${action.personInCharge.lastName}`
          : null,
        isDefinitive: action.isDefinitive,
        project: {
          id: action.project.id,
          label: `${action.project.address}, ${action.project.city} - ${action.project.postcode}`,
          isRegistered: action.project.isRegistered,
        },
      }
    })

  const lateMonthProgressData = todosData?.progress
    .filter(
      (progress) =>
        isBefore(new Date(progress.deadline), new Date(Date.now())) &&
        !progress.isDefinitive,
    )
    .map((progress) => {
      return {
        id: progress.id,
        createdAt: progress.createdAt,
        isAction: false,
        deadline: progress.deadline,
        description: progress.description,
        creator: progress.creator
          ? `${progress.creator.firstName} ${progress.creator.lastName}`
          : null,
        personInCharge: progress.personInCharge
          ? `${progress.personInCharge.firstName} ${progress.personInCharge.lastName}`
          : null,
        isDefinitive: progress.isDefinitive,
        project: {
          id: progress.project.id,
          label: `${progress.project.address}, ${progress.project.city} - ${progress.project.postcode}`,
          isRegistered: progress.project.isRegistered,
        },
      }
    })

  const currentMonthActionsData = toCurrentMonthTodosData?.actions
    .filter(
      (action) =>
        !action.isDefinitive &&
        !lateMonthActionsData?.some(
          (lateAction) => lateAction.id === action.id,
        ),
    )
    .map((action) => {
      return {
        id: action.id,
        createdAt: action.createdAt,
        isAction: true,
        deadline: action.deadline,
        description: action.description,
        creator: action.creator
          ? `${action.creator.firstName} ${action.creator.lastName}`
          : null,
        personInCharge: action.personInCharge
          ? `${action.personInCharge.firstName} ${action.personInCharge.lastName}`
          : null,
        isDefinitive: action.isDefinitive,
        project: {
          id: action.project.id,
          label: `${action.project.address}, ${action.project.city} - ${action.project.postcode}`,
          isRegistered: action.project.isRegistered,
        },
      }
    })

  const currentMonthProgressData = toCurrentMonthTodosData?.progress
    .filter(
      (progress) =>
        !progress.isDefinitive &&
        !lateMonthProgressData?.some(
          (lateProgress) => lateProgress.id === progress.id,
        ),
    )
    .map((progress) => {
      return {
        id: progress.id,
        createdAt: progress.createdAt,
        isAction: false,
        deadline: progress.deadline,
        description: progress.description,
        creator: progress.creator
          ? `${progress.creator.firstName} ${progress.creator.lastName}`
          : null,
        personInCharge: progress.personInCharge
          ? `${progress.personInCharge.firstName} ${progress.personInCharge.lastName}`
          : null,
        isDefinitive: progress.isDefinitive,
        project: {
          id: progress.project.id,
          label: `${progress.project.address}, ${progress.project.city} - ${progress.project.postcode}`,
          isRegistered: progress.project.isRegistered,
        },
      }
    })

  const currentTodos = [
    ...(currentMonthActionsData || []),
    ...(currentMonthProgressData || []),
  ]
  const lateTodos = [
    ...(lateMonthActionsData || []),
    ...(lateMonthProgressData || []),
  ]

  const todos = [...currentTodos, ...lateTodos]

  const countIncomingTodos =
    (currentMonthProgressData?.length ?? 0) +
    (currentMonthActionsData?.length ?? 0)

  return {
    isLoading,
    error,
    currentTodos,
    lateTodos,
    stringifiedFutureDate,
    stringifiedCurrentDate,
    todos,
    countIncomingTodos,
  }
}
