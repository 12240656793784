import { API } from "core/api/axios"
import {
  TMutateSimplifiedProgram,
  TSimplifiedProgram,
} from "shared/types/simplified-programs.type"

async function listSimplifiedPrograms(): Promise<TSimplifiedProgram[]> {
  const res = await API.get("/simplified-projects")
  return res.data
}

async function addSimplifiedProgram(
  data: Partial<TMutateSimplifiedProgram>,
): Promise<TSimplifiedProgram> {
  const res = await API.post("/simplified-project", data)
  return res.data
}

async function getSimplifiedProgram(
  simplifiedProgramId: number,
): Promise<TSimplifiedProgram> {
  const res = await API.get(`/simplified-projects/${simplifiedProgramId}`)
  return res.data
}

async function editSimplifiedProgram(
  simplifiedProgramId: number,
  data: Partial<TMutateSimplifiedProgram>,
): Promise<TSimplifiedProgram> {
  const res = await API.patch(
    `/simplified-project/${simplifiedProgramId}`,
    data,
  )
  return res.data
}

async function deleteSimplifiedProgram(
  simplifiedProgramId: number,
): Promise<unknown> {
  const res = await API.delete(`/simplified-project/${simplifiedProgramId}`)
  return res.data
}

export {
  addSimplifiedProgram,
  deleteSimplifiedProgram,
  editSimplifiedProgram,
  getSimplifiedProgram,
  listSimplifiedPrograms,
}
