import { useQuery } from "@tanstack/react-query"
import getPartialBatches from "core/api/lotsPartialSpo"
import { CustomAxiosError } from "shared/types/axios.type"

function useGetPartialBatches(registrationNumber: string) {
  return useQuery<number, CustomAxiosError>({
    queryKey: ["getOperationBatches", registrationNumber],
    queryFn: () => getPartialBatches(registrationNumber),
    gcTime: 15 * 60 * 1000,
  })
}

export default useGetPartialBatches
