import { API } from "core/api/axios"
import {
  TEnvironment,
  TReThresold,
  TUpdateEnvironment,
} from "shared/types/environment.type"

async function getEnvironmentById(projectId: number): Promise<TEnvironment> {
  return typeof projectId === "undefined"
    ? Promise.reject(new Error("Invalid project id"))
    : API.get(`/environments/${projectId}`).then((response) => response.data)
}

async function updateEnvironment(
  projectId: number,
  environment: TUpdateEnvironment,
): Promise<TEnvironment> {
  return typeof projectId === "undefined"
    ? Promise.reject(new Error("Invalid project id"))
    : API.patch(`/environment/${projectId}`, environment).then(
        (response) => response.data,
      )
}

async function listReThresold(): Promise<TReThresold[]> {
  return API.get(`/environment/re-thresold`).then((response) => response.data)
}

export { getEnvironmentById, listReThresold, updateEnvironment }
