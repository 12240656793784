import { Link } from "react-router-dom"

export interface IStudyNameFormatterProps {
  studyName: string
  studyId: number
  isOperation: boolean
  studyType: "infography" | "feasibility" | "costOfWork"
  programId: number
}

export function StudyNameFormatter(props: IStudyNameFormatterProps) {
  const { studyName, isOperation, studyId, programId, studyType } = props

  const baseUrl = isOperation ? "operation" : "project"

  return (
    <div className="flex items-center">
      <Link
        className="text-blue-700 hover:text-blue-600 transition-colors ease-in-out delay-75 hover:underline"
        to={`/${baseUrl}/${programId}/studies/${studyType}/${studyId}`}
      >
        {studyName}
      </Link>
    </div>
  )
}
