import { QueryErrorResetBoundary } from "@tanstack/react-query"
import { Button } from "components/Button/Button"
import { t } from "i18next"
import { ReactNode, Suspense } from "react"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"

function ErrorView({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div className="p-10">
      <div>{error.message}</div>
      <Button
        size="medium"
        mode="primary"
        type="button"
        onClick={resetErrorBoundary}
      >
        {t("retry")}
      </Button>
    </div>
  )
}

function LoadingView({ loadingComponent }: { loadingComponent: ReactNode }) {
  return <div>{loadingComponent}</div>
}

function QueryBoundaries({
  children,
  loadingComponent,
}: {
  children: ReactNode
  loadingComponent: ReactNode
}) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} FallbackComponent={ErrorView}>
          <Suspense
            fallback={<LoadingView loadingComponent={loadingComponent} />}
          >
            {children}
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}

export default QueryBoundaries
