import DropdownFilters from "features/programs/DropdownFilter"
import { t } from "i18next"
import { TIdLabel } from "shared/types/global.type"
import { STUDY_STATUS } from "../ressources/study.resources"

export interface IStatusFiltersProps {
  selectedStatus: number[] | []
  onStatusChange: (selectedStatus: number[] | []) => void
}

export default function StudyRequestsStatusFilter(props: IStatusFiltersProps) {
  const { selectedStatus, onStatusChange } = props
  const statusOptions = STUDY_STATUS

  const statusOptionsWithAll = [
    {
      id: -1,
      label: t("select-all"),
    },
    ...statusOptions,
  ]

  const handleStatusClick = (status: TIdLabel) => {
    if (status.id === -1) {
      const shouldSelectAll =
        selectedStatus.length !== statusOptions.length
          ? statusOptions.map((elmt) => elmt.id)
          : []

      onStatusChange(shouldSelectAll)
    } else {
      const isSelected = ((selectedStatus as number[]) ?? []).includes(
        status.id,
      )

      const updatedStatus = isSelected
        ? selectedStatus.filter((currentStatus) => currentStatus !== status.id)
        : [...selectedStatus, status.id]

      onStatusChange(updatedStatus)
    }
  }

  const isSelectedAll = selectedStatus.length === statusOptions.length

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900">
              {t("progress")}
              {selectedStatus.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-yellow-800 bg-yellow-100 rounded-full">
                  {selectedStatus.length}
                </div>
              )}
            </div>
          )}
          options={statusOptionsWithAll}
          renderOption={(status, index) => (
            <li
              onClick={() => handleStatusClick(status as TIdLabel)}
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              tabIndex={-1}
              id={`menu-item-${index}`}
              key={`menu-item-${index}`}
            >
              <input
                className="mr-2"
                type="checkbox"
                checked={
                  status.id === -1
                    ? isSelectedAll
                    : (selectedStatus as number[])?.includes(status.id)
                }
              />
              {t(status.label)}
            </li>
          )}
          keyExtractor={(status) => status.id}
        />
      </div>
    </div>
  )
}
