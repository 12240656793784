import * as React from "react"
import { MdExpandMore } from "react-icons/md"
import useOutsideAlerter from "../../hooks/useOutsideAlerter"
import "./Tooltip.scss"

interface IToolipProps {
  children: React.ReactElement
  title: string
}

export default function Tooltip({ children, title }: IToolipProps) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideAlerter(false)

  return (
    <div>
      <button
        type="button"
        className="flex items-center w-full justify-end"
        onClick={() => setIsComponentVisible(true)}
      >
        {title} <MdExpandMore className="ml-1" />
      </button>
      {isComponentVisible && (
        <div
          ref={ref}
          onClick={() => setIsComponentVisible(false)}
          className="Tooltip__Container"
        >
          <div className="Tooltip__Modal p-4 md:p-0">{children}</div>
        </div>
      )}
    </div>
  )
}
