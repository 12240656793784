import { useQuery } from "@tanstack/react-query"
import { getDatesConsultation, getDatesMontage } from "core/spo/api/dates"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TDatesConsultation,
  TDatesMontage,
} from "shared/types/spo/datesPlanning.type"

function useGetDatesMontage(registrationId: string) {
  return useQuery<TDatesMontage, CustomAxiosError>({
    queryKey: ["getDatesPlanning", registrationId],
    queryFn: () => getDatesMontage(registrationId),
  })
}

function useGetDatesConsultation(registrationId: string) {
  return useQuery<TDatesConsultation, CustomAxiosError>({
    queryKey: ["getDatesConsultation", registrationId],
    queryFn: () => getDatesConsultation(registrationId),
  })
}

export { useGetDatesConsultation, useGetDatesMontage }
