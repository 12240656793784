import ItemsCounter from "components/Counter/ItemsCounter"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useListTeamStudyRequestsTodos } from "core/query-hooks/useStudyRequests"
import { t } from "i18next"
import { FaPencilRuler } from "react-icons/fa"
import { Link } from "react-router-dom"

export interface ICountTeamStudyRequestsTodosContainer {
  azureId: string
}

export default function CountTeamStudyRequestsTodosContainer(
  props: ICountTeamStudyRequestsTodosContainer,
) {
  const { azureId } = props
  const {
    data: teamStudyRequestsTodos,
    isLoading,
    error,
  } = useListTeamStudyRequestsTodos(azureId)

  return (
    <Link to={{ pathname: "/expected-study-requests/team" }}>
      <TooltipWithHover
        message={t("study-request-team-tasks-number")}
        position="right"
      >
        <ItemsCounter
          title={t("studies")}
          Icon={<FaPencilRuler height={18} width={18} color="#00304D" />}
          totalItems={
            teamStudyRequestsTodos ? teamStudyRequestsTodos.length : 0
          }
          isLoading={isLoading}
          error={error}
          errorMessage={t("error-loading-study-requests-todos")}
          classNames="bg-white"
        />
      </TooltipWithHover>
    </Link>
  )
}
