import { TSubfoldersResponse } from "shared/types/ged.type"
import GED_API from "../axios"

export default async function getSubfolders(
  fieldName: string,
  condition: string,
  registrationNumbers: string[],
): Promise<TSubfoldersResponse> {
  const res = await GED_API.get(
    `/metadata/subfolders?fieldName=${fieldName}&condition=${condition}&registrationNumbers=${registrationNumbers}`,
  )
  return res.data
}
