import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import useModal from "components/Modal/useModal"
import {
  useAddInfography,
  useGetInfography,
  useUpdateInfography,
} from "core/query-hooks/useInfographies"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import useFeasibilityVersion from "hooks/useFeasibilityVersion"
import i18next, { t } from "i18next"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useContext, useEffect } from "react"
import { nanoid } from "nanoid"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { GROUP } from "shared/resources/groups.resources"
import * as yup from "yup"
import DeleteStudyRequestDraft from "../DeleteStudyRequestDraft"
import { InfographyContext } from "./InfographyProvider"

type TStudyRequestCreateDetailsStepForm = {
  projectId?: number
  feasibilityId?: number
  desiredGroundPlaneNumber?: number | null
  desiredModelNumber?: number | null
  desiredPerspectiveNumber?: number | null
  desiredAerialViewNumber?: number | null
  desiredOtherNumber?: number | null
  developersComment?: string
}

const validationSchema = yup
  .object({
    feasibilityId: yup
      .number()
      .nullable()
      .typeError(() => i18next.t("error-select-version-feasibility"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
    developersComment: yup
      .string()
      .required(() => i18next.t("comment-error-message"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
    desiredGroundPlaneNumber: yup
      .number()
      .min(0, () => i18next.t("zero-or-greater"))
      .integer(() => i18next.t("integer-number-message"))
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    desiredModelNumber: yup
      .number()
      .min(0, () => i18next.t("zero-or-greater"))
      .integer(() => i18next.t("integer-number-message"))
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    desiredPerspectiveNumber: yup
      .number()
      .min(0, () => i18next.t("zero-or-greater"))
      .integer(() => i18next.t("integer-number-message"))
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    desiredAerialViewNumber: yup
      .number()
      .min(0, () => i18next.t("zero-or-greater"))
      .integer(() => i18next.t("integer-number-message"))
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    desiredOtherNumber: yup
      .number()
      .min(0, () => i18next.t("zero-or-greater"))
      .integer(() => i18next.t("integer-number-message"))
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
  })
  .test("at-least-one-filled", function testDisiredValues(values) {
    const {
      desiredGroundPlaneNumber,
      desiredModelNumber,
      desiredPerspectiveNumber,
      desiredAerialViewNumber,
      desiredOtherNumber,
    } = values
    if (
      !desiredGroundPlaneNumber &&
      !desiredModelNumber &&
      !desiredPerspectiveNumber &&
      !desiredAerialViewNumber &&
      !desiredOtherNumber
    ) {
      return this.createError({
        message: () => i18next.t("at-least-one-filled"),
        path: "desiredGroundPlaneNumber",
      })
    }
    return true
  })

export interface IStepDetailsInfography {
  routeName: string
}

export function StepDetailsInfography(props: IStepDetailsInfography) {
  const { id, studyRequestId } = useParams()
  const { data: user } = useGetCurrentUser()
  const { studyRequestInfo, setStudyRequestInfo, currentId, setCurrentId } =
    useContext(InfographyContext)
  const { isShowing: isDeleteModalShowed, toggle: toggleDeleteModal } =
    useModal()
  const { routeName } = props
  // constantes en cas d'édition
  const { data: infogData } = useGetInfography(+studyRequestId!)
  const creatorId = infogData?.creator.azureId
  const userIsCreatorOrAdmin =
    !creatorId ||
    creatorId === user?.azureId ||
    user?.groups.includes(GROUP.ADMIN)
  const desiredGroundPlaneNumberValue =
    infogData?.desiredGroundPlaneNumber || ""
  const desiredModelNumberValue = infogData?.desiredModelNumber || ""
  const desiredPerspectiveNumberValue =
    infogData?.desiredPerspectiveNumber || ""
  const desiredAerialViewNumberValue = infogData?.desiredAerialViewNumber || ""
  const desiredOtherNumberValue = infogData?.desiredOtherNumber || ""
  const version = infogData?.feasibility?.id
  const commentValue = infogData?.comments?.[0]?.comment || ""
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TStudyRequestCreateDetailsStepForm>({
    resolver: yupResolver(
      validationSchema as yup.ObjectSchema<TStudyRequestCreateDetailsStepForm>,
    ),
    defaultValues: {
      desiredGroundPlaneNumber: desiredGroundPlaneNumberValue
        ? +desiredGroundPlaneNumberValue
        : undefined,
      desiredModelNumber: desiredModelNumberValue
        ? +desiredModelNumberValue
        : undefined,
      desiredPerspectiveNumber: desiredPerspectiveNumberValue
        ? +desiredPerspectiveNumberValue
        : undefined,
      desiredAerialViewNumber: desiredAerialViewNumberValue
        ? +desiredAerialViewNumberValue
        : undefined,
      desiredOtherNumber: desiredOtherNumberValue
        ? +desiredOtherNumberValue
        : undefined,
      feasibilityId: version,
      developersComment: commentValue,
    },
  })

  useEffect(() => {
    if (infogData) {
      reset({
        desiredGroundPlaneNumber: desiredGroundPlaneNumberValue
          ? +desiredGroundPlaneNumberValue
          : undefined,
        desiredModelNumber: desiredModelNumberValue
          ? +desiredModelNumberValue
          : undefined,
        desiredPerspectiveNumber: desiredPerspectiveNumberValue
          ? +desiredPerspectiveNumberValue
          : undefined,
        desiredAerialViewNumber: desiredAerialViewNumberValue
          ? +desiredAerialViewNumberValue
          : undefined,
        desiredOtherNumber: desiredOtherNumberValue
          ? +desiredOtherNumberValue
          : undefined,
        feasibilityId: version,
        developersComment: commentValue,
      })
    }
  }, [infogData])

  const queryClient = useQueryClient()
  const addInfography = useAddInfography()
  const updateInfography = studyRequestId
    ? useUpdateInfography(+studyRequestId)
    : useUpdateInfography(currentId!)
  const { restitutionDates, isLoading } = useFeasibilityVersion(+id!)

  useEffect(() => {
    reset({
      projectId: +id!,
      feasibilityId: studyRequestInfo.feasibilityId,
      desiredGroundPlaneNumber: studyRequestInfo.desiredGroundPlaneNumber,
      desiredModelNumber: studyRequestInfo.desiredModelNumber,
      desiredPerspectiveNumber: studyRequestInfo.desiredPerspectiveNumber,
      desiredAerialViewNumber: studyRequestInfo.desiredAerialViewNumber,
      desiredOtherNumber: studyRequestInfo.desiredOtherNumber,
      developersComment: studyRequestInfo.developersComment,
    })
  }, [])

  const navigate = useNavigate()
  function onSubmit(
    data: TStudyRequestCreateDetailsStepForm,
    redirectToStudies: boolean = false,
  ) {
    if (!currentId && !studyRequestId) {
      return addInfography.mutate(
        {
          projectId: parseInt(id!),
          feasibilityId: data.feasibilityId,
          desiredGroundPlaneNumber: data.desiredGroundPlaneNumber,
          desiredModelNumber: data.desiredModelNumber,
          desiredPerspectiveNumber: data.desiredPerspectiveNumber,
          desiredAerialViewNumber: data.desiredAerialViewNumber,
          desiredOtherNumber: data.desiredOtherNumber,
          developersComment: data.developersComment,
        },
        {
          onSuccess(infography) {
            setStudyRequestInfo((previousValue) => {
              return {
                ...previousValue,
                projectId: +id!,
                feasibilityId: infography.feasibilityId,
                desiredGroundPlaneNumber: infography.desiredGroundPlaneNumber,
                desiredModelNumber: infography.desiredModelNumber,
                desiredPerspectiveNumber: infography.desiredPerspectiveNumber,
                desiredAerialViewNumber: infography.desiredAerialViewNumber,
                desiredOtherNumber: infography.desiredOtherNumber,
                developersComment: infography.developersComment,
              }
            })
            setCurrentId(infography.id)
            queryClient.invalidateQueries({
              queryKey: ["listStudyRequests", id],
            })
            redirectToStudies
              ? (toast.success(t("draft-saved")),
                navigate(`/${routeName}/${id}/studies`))
              : navigate(
                  `/${routeName}/${id}/studies/infography/new/${infography.id}/documents`,
                )
          },
          onError(error) {
            toast.error(error.response?.data.error.message)
          },
        },
      )
    }
    return updateInfography.mutate(
      {
        projectId: parseInt(id!),
        feasibilityId: data.feasibilityId,
        desiredGroundPlaneNumber: data.desiredGroundPlaneNumber,
        desiredModelNumber: data.desiredModelNumber,
        desiredPerspectiveNumber: data.desiredPerspectiveNumber,
        desiredAerialViewNumber: data.desiredAerialViewNumber,
        desiredOtherNumber: data.desiredOtherNumber,
        developersComment: data.developersComment,
      },
      {
        onSuccess(infography) {
          setStudyRequestInfo((previousValue) => {
            return {
              ...previousValue,
              projectId: +id!,
              feasibilityId: infography.feasibilityId,
              desiredGroundPlaneNumber: infography.desiredGroundPlaneNumber,
              desiredModelNumber: infography.desiredModelNumber,
              desiredPerspectiveNumber: infography.desiredPerspectiveNumber,
              desiredAerialViewNumber: infography.desiredAerialViewNumber,
              desiredOtherNumber: infography.desiredOtherNumber,
              developersComment: infography.developersComment,
            }
          })
          if (studyRequestId) {
            navigate(`/${routeName}/${studyRequestId}/studies`)
          }
          setCurrentId(infography.id)
          queryClient.invalidateQueries({
            queryKey: ["listStudyRequests", id],
          })
          redirectToStudies
            ? (toast.success(t("draft-saved")),
              navigate(`/${routeName}/${id}/studies`))
            : navigate(
                `/${routeName}/${id}/studies/infography/new/${infography.id}/documents`,
              )
        },
        onError(error) {
          toast.error(error.response?.data.error.message)
        },
      },
    )
  }

  return (
    <>
      <DivStickyMenu />
      <div className="px-4 sm:px-16">
        <form>
          <div className="lg:grid grid-rows-1 lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-8 pb-8">
            <div className="col-span-2 pt-4">
              <p className="Color__Primary font-semibold text-lg">
                {`${t("desired-infographies")}`}
              </p>
            </div>
            <div className="col-span-2">
              <Box classNames="flex flex-col">
                <div className="Text__Field_Container">
                  <label htmlFor="desiredGroundPlaneNumber">
                    {`${t("desired-ground-plane")}`}
                    <div className="flex items-center justify-start">
                      <input
                        id="desiredGroundPlaneNumber"
                        className={classNames("Text__Field")}
                        type="number"
                        {...register("desiredGroundPlaneNumber")}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </label>
                </div>
                {errors.desiredGroundPlaneNumber && (
                  <span className="text-red-500 text-xs">
                    {errors.desiredGroundPlaneNumber.message}
                  </span>
                )}

                <div className="Text__Field_Container">
                  <label htmlFor="desiredModelNumber">
                    {`${t("desired-model")}`}
                    <div className="flex items-center justify-start">
                      <input
                        id="desiredModelNumber"
                        className={classNames("Text__Field")}
                        type="number"
                        {...register("desiredModelNumber")}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </label>
                </div>
                {errors.desiredModelNumber && (
                  <span className="text-red-500 text-xs">
                    {errors.desiredModelNumber.message}
                  </span>
                )}

                <div className="Text__Field_Container">
                  <label htmlFor="desiredPerspectiveNumber">
                    {`${t("desired-perspective")}`}
                    <div className="flex items-center justify-start">
                      <input
                        id="desiredPerspectiveNumber"
                        className={classNames("Text__Field")}
                        type="number"
                        {...register("desiredPerspectiveNumber")}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </label>
                </div>
                {errors.desiredPerspectiveNumber && (
                  <span className="text-red-500 text-xs">
                    {errors.desiredPerspectiveNumber.message}
                  </span>
                )}

                <div className="Text__Field_Container">
                  <label htmlFor="desiredAerialViewNumber">
                    {`${t("desired-aerial-view")}`}
                    <div className="flex items-center justify-start">
                      <input
                        id="desiredAerialViewNumber"
                        className={classNames("Text__Field")}
                        type="number"
                        {...register("desiredAerialViewNumber")}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </label>
                </div>
                {errors.desiredAerialViewNumber && (
                  <span className="text-red-500 text-xs">
                    {errors.desiredAerialViewNumber.message}
                  </span>
                )}

                <div className="Text__Field_Container">
                  <label htmlFor="desiredOtherNumber">
                    {`${t("desired-other")} (${t("specify-in-comment")})`}
                    <div className="flex items-center justify-start">
                      <input
                        id="desiredOtherNumber"
                        className={classNames("Text__Field")}
                        type="number"
                        {...register("desiredOtherNumber")}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </label>
                </div>
                {errors.desiredOtherNumber && (
                  <span className="text-red-500 text-xs">
                    {errors.desiredOtherNumber.message}
                  </span>
                )}
              </Box>
            </div>
          </div>

          <div className="lg:grid grid-rows-1 lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-8 pb-8">
            <div className="col-span-2 pt-4">
              <p className="Color__Primary font-semibold text-lg">
                {`${t("feasibility-version")}`}
              </p>
            </div>
            <div className="col-span-2">
              <Box classNames="flex flex-col Text__Field_Container">
                <label htmlFor="feasibilityId">
                  {`${t("feasibility-version-label")} *`}
                  <select
                    {...register("feasibilityId")}
                    name="feasibilityId"
                    id="feasibilityId"
                    className="Text__Field flex items-center justify-center"
                  >
                    {studyRequestId ? null : (
                      <option key="undefined-feasibilityId">-</option>
                    )}
                    {!isLoading &&
                      restitutionDates?.map((restitution) => (
                        <option key={nanoid()} value={restitution?.value}>
                          {restitution?.label}
                        </option>
                      ))}
                    <option key="external-feasibilityId" value="">
                      {t("out-of-alvis")}
                    </option>
                  </select>
                </label>
                {errors.feasibilityId && (
                  <span className="text-red-500 text-xs">
                    {errors.feasibilityId.message}
                  </span>
                )}
              </Box>
            </div>
          </div>

          <div className="divide-y divide-slate-100">
            <div className="lg:grid grid-rows-1 lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense md:pt-8 pb-8">
              <div className="col-span-2 pt-4">
                <p className="Color__Primary font-semibold text-lg">
                  {`${t("comment")}`}
                </p>
                <p className="Color__Primary mt-2 text-sm mb-4 md:mb-0">
                  {`${t("comment-helper-message-study-request")}`}
                </p>
              </div>
              <div className="col-span-2">
                <Box classNames="flex flex-col Text__Field_Container">
                  <label htmlFor="developersComment">
                    {`${t("comment")} *`}
                    <textarea
                      id="developersComment"
                      rows={15}
                      className="Textarea__Field"
                      {...register("developersComment")}
                    />
                  </label>
                  {errors.developersComment && (
                    <span className="text-red-500 text-xs">
                      {errors.developersComment.message}
                    </span>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </form>
        {userIsCreatorOrAdmin ? (
          <div className="flex items-center flex-row-reverse justify-between pt-4 pb-8">
            <div>
              <Button
                isLoading={updateInfography.isPending}
                onClick={handleSubmit((data) => onSubmit(data, true))}
                size="small"
                mode="secondary"
              >
                {`${t("save-draft")}`}
              </Button>
              <Button
                isLoading={updateInfography.isPending}
                onClick={handleSubmit((data) => onSubmit(data))}
                marginLeft={12}
                size="small"
                mode="primary"
              >
                {`${t("add-documents")}`}
              </Button>
            </div>
            <div>
              {studyRequestId ? (
                <DeleteStudyRequestDraft
                  isDeleteModalShowed={isDeleteModalShowed}
                  toggleDeleteModal={toggleDeleteModal}
                  routeName={routeName}
                  isPending={updateInfography.isPending}
                  studyRequestType={t("this-infography")}
                />
              ) : (
                <Button
                  size="medium"
                  mode="danger"
                  onClick={() => navigate(`/${routeName}/${id}/studies`)}
                >
                  {`${t("delete-draft")}`}
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}
