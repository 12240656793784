import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import CustomDateTimePicker from "components/DatePicker/CustomDateTimePicker"
import { Modal } from "components/Modal/Modal"
import { useEditAdministrationData } from "core/query-hooks/useAdministrationData"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { FormEvent, useState } from "react"
import { registerLocale } from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { TAdministrationDataType } from "shared/types/administrationData.type"
import * as yup from "yup"

registerLocale("fr", fr)

export interface IEditReleaseNotesProps {
  isModalShowed: boolean
  toggleModalReleaseNotes: () => void
  release: TAdministrationDataType
}

type FormValues = {
  releaseNotes: string | null
  releaseDate: Date | null
}

const editReleaseNotesValidationSchema = yup.object({
  releaseNotes: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  releaseDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
})

export function EditReleaseNotes(props: IEditReleaseNotesProps) {
  const { release: data } = props
  const [releaseDate, setReleaseDate] = useState<Date | null>(
    data?.releaseDate ?? null,
  )
  const editReleaseNotes = useEditAdministrationData()
  const queryClient = useQueryClient()

  const { isModalShowed, toggleModalReleaseNotes } = props

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(
      editReleaseNotesValidationSchema as yup.ObjectSchema<FormValues>,
    ),
    defaultValues: {
      releaseNotes: data.releaseNotes || null,
      releaseDate: data.releaseDate ? new Date(data.releaseDate) : null,
    },
  })

  async function submitForm(dataForm: FormValues) {
    editReleaseNotes.mutateAsync(
      {
        releaseNotes: dataForm.releaseNotes,
        releaseDate: dataForm.releaseDate,
      },
      {
        async onSuccess() {
          queryClient.invalidateQueries({
            queryKey: ["getAdministrationData"],
          })
          toast.success(t("toast-edited-release-notes"))
          toggleModalReleaseNotes()
        },
        onError() {
          toast.error(t("toast-error-edit-release-notes"))
        },
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <div>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModalReleaseNotes}
        title={`${t("edit-release-notes")}`}
      >
        <form className="Text__Field_Container" onSubmit={onSubmit}>
          <div>
            <label htmlFor="releaseNotes">
              {t("release-notes")}
              <textarea
                id="releaseNotes"
                className="Textarea__Field h-96"
                {...register("releaseNotes")}
              />
            </label>
            {errors?.releaseNotes && (
              <p className="text-red-600 text-sm">
                {errors.releaseNotes.message}
              </p>
            )}
          </div>

          <div className="flex flex-col mt-2">
            <label htmlFor="releaseDate">{t("release-date")}</label>
            <Controller
              name="releaseDate"
              control={control}
              render={({ field: { onChange } }) => (
                <CustomDateTimePicker
                  id="releaseDate"
                  className="Text__Field"
                  locale={fr}
                  selected={releaseDate}
                  placeholderText={`${t("select-date")}`}
                  onChange={(date) => {
                    if (date) setReleaseDate(date)
                    onChange(date)
                  }}
                />
              )}
            />
            {errors?.releaseDate && (
              <p className="text-red-600 text-sm">
                {errors.releaseDate.message}
              </p>
            )}
          </div>

          <div className="flex items-center justify-between mt-2">
            <Button
              onClick={() => toggleModalReleaseNotes()}
              size="small"
              mode="secondary"
            >
              {`${t("cancel")}`}
            </Button>

            <Button
              isLoading={editReleaseNotes.isPending}
              type="submit"
              size="small"
              mode="primary"
            >
              {`${t("edit")}`}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}
