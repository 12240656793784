import classNames from "classnames"
import { forwardRef, useState } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"

interface IToggleProps {
  ref?: React.Ref<any>
  placeholder: string
  label: string
  name: string
  props?: any
  className?: string
  id: string
  control: any
  onChange?: (value: string) => void
  options: { label: string; value: number }[]
  isDisabled?: boolean
}

const customStyles = {
  control: () => ({
    width: "auto",
    display: "flex",
    border: "1px solid #e5e7eb",
    backgroundColor: "#f9fafb",
    borderRadius: 9,
    height: 50,
  }),
}

const SelectField = forwardRef<HTMLTextAreaElement, IToggleProps>(
  ({
    label,
    placeholder,
    className,
    id,
    name,
    control,
    options,
    onChange,
    isDisabled,
    ...props
  }) => {
    const [selectedOption, setSelectedOption] = useState<null | {
      value: number
      label: string
    }>(null)

    return (
      <div>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Select
              isDisabled={isDisabled}
              id={id}
              name={name}
              styles={customStyles}
              ref={field.ref}
              isSearchable
              aria-label={label}
              classNamePrefix={classNames(["Text__Field", className])}
              placeholder={placeholder}
              {...props}
              defaultValue={selectedOption}
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption({ label: e?.label!, value: e?.value! })
                field.onChange(e?.value!)
              }}
              options={options}
            />
          )}
        />
      </div>
    )
  },
)

export default SelectField
