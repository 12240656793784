export const KBIS_DOCUMENT_TYPE = {
  label: "06.01.07 - KBIS de la société",
}
export const COMPANY_STATUS_DOCUMENT_TYPE = {
  label: "06.01.08 - Statuts de la société",
}
export const RIB_DOCUMENT_TYPE = {
  label: "03.01.06 - RIB de la société",
}
export const AMO_CONVENTION_DOCUMENT_TYPE = {
  label: "06.01.09 - Convention AMO",
}
export const COPROMOTION_CONVENTION_DOCUMENT_TYPE = {
  label: "12.02.02 - Convention AMO copromotion",
}
