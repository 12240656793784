import { useAuthState } from "react-firebase-hooks/auth"
import { Navigate } from "react-router-dom"
import { auth } from "utils/firebase"

export default function ProtectedRoute({
  children,
}: {
  children: JSX.Element
}) {
  const [user] = useAuthState(auth)

  if (!user) {
    return <Navigate to="/login" replace />
  }

  return children
}
