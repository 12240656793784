import classNames from "classnames"
import * as React from "react"
import { forwardRef } from "react"
import "./TextAreaField.scss"

interface ITextAreaProps {
  rows: number
  cols: number
  ref?: React.Ref<any>
  placeholder: string
  name: string
  props?: any
  className?: string
  id: string
  label: string
}

const TextAreaField = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    { rows = 5, cols = 33, id, name, className, placeholder, label, ...props },
    ref,
  ) => (
    <textarea
      name={name}
      ref={ref}
      aria-label={label}
      className={classNames(["Text__Field TextAreaField", className])}
      id={id}
      placeholder={placeholder}
      {...props}
      rows={rows}
      cols={cols}
    />
  ),
)

export default TextAreaField
