export const SOLD = {
  label: "Acté",
  labelPlural: "Actes",
  value: "Vendu",
}
export const RESERVED = {
  label: "Réservé",
  value: "Réservé",
}
export const IN_STOCK = {
  label: "En stock",
  value: "En stock",
}
export const PRERESERVED = {
  label: "Pré-réservé",
  value: "Pré-réservé",
}
export const OPTIONNED = {
  label: "Optionné",
  labelPlural: "Optionnés",
  value: "Optionné",
}
export const DELIVERED = {
  label: "Livré",
  value: "Livré",
}
