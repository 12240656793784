import { API } from "core/api/axios"
import { TMutatePlot, TPlot, TPlotList } from "shared/types/plot.type"

async function createPlot(plot: TMutatePlot): Promise<TPlot> {
  const res = await API.post("/plot", plot)
  return res.data
}

async function updatePlot(id: number, plot: TMutatePlot): Promise<TPlot> {
  const res = await API.patch(`/plot/${id}`, plot)
  return res.data
}

async function getPlot(id: string): Promise<TPlot> {
  const res = await API.get(`/plots/${id}`)
  return res.data
}

async function deletePlot(id: string): Promise<TPlot> {
  const res = await API.delete(`/plot/${id}`)
  return res.data
}

async function getPlots(
  projectId: string,
  search?: string,
): Promise<TPlotList> {
  const res = await API.get(`/plots?project=${projectId}&search=${search}`)
  return res.data
}

async function getFreePlotsByProjectId(projectId: string): Promise<TPlotList> {
  const res = await API.get(`/plots?project=${projectId}&agreement=0`)
  return res.data
}

async function getPlotsByAgreementId(agreementId: string): Promise<TPlotList> {
  const res = await API.get(`/plots?agreement=${agreementId}`)
  return res.data
}

export {
  createPlot,
  deletePlot,
  getFreePlotsByProjectId,
  getPlot,
  getPlots,
  getPlotsByAgreementId,
  updatePlot,
}
