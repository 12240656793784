import classnames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { useGetSyntheseFinanciere } from "core/query-hooks/useSyntheseFinanciere"
import { format } from "date-fns"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { Fragment } from "react"

export interface IChildOperationBudgetProps {
  childOperationId: string
}

export function ChildOperationBudget(props: IChildOperationBudgetProps) {
  const { childOperationId } = props
  const {
    data: budgetData,
    error,
    isLoading,
  } = useGetSyntheseFinanciere(childOperationId)

  const rfOHeader = budgetData?.find((elem) => elem.RF_0.label !== null)?.RF_0
    .label
  const rf1Header = budgetData?.find((elem) => elem.RF_1.label !== null)?.RF_1
    .label
  const rf2Header = budgetData?.find((elem) => elem.RF_2.label !== null)?.RF_2
    .label
  const rf3Header = budgetData?.find((elem) => elem.RF_3.label !== null)?.RF_3
    .label
  const rf4Header = budgetData?.find((elem) => elem.RF_4.label !== null)?.RF_4
    .label

  const headers = [
    t("k-euros-by-post"),
    t("ratio"),
    t("budget"),
    t("committed"),
    t("invoiced"),
    t("to-be-invoiced"),
    t("paid"),
  ]

  const dynamicHeaders = [
    ...headers,
    rfOHeader,
    rf1Header,
    rf2Header,
    rf3Header,
    rf4Header,
  ].filter(Boolean)

  if (isLoading) return <Spinner />

  if (error) {
    if (error?.response?.status !== 404 && error?.response?.status !== 418)
      return <p>{t("budget-error")}</p>
    if (error?.response?.status === 404)
      return <p>{t("no-budget-available")}</p>
    if (error?.response?.status === 418)
      return <p>{t("error-status-has-no-budget")}</p>
  }

  if (budgetData && budgetData?.length === 0) return <Spinner />

  function displayValue(n: number | null, isPercentage: boolean): string {
    if (!n) return "-"

    return isPercentage
      ? `${+(parseFloat(n.toFixed(2)) * 100).toFixed(2)} %`
      : n.toLocaleString("fr-FR")
  }

  function displayDate(d: string | null): string {
    if (!d) return "-"

    return `${format(new Date(new Date(d)), "dd/MM/yyyy")}`
  }

  return (
    <div className="Light_Table__Wrapper max-h-[60vh]">
      <div className="Table__Sticky_Container">
        <table className="Table">
          <thead>
            <tr className="Table__Sticky_Header">
              {dynamicHeaders.map((header, index) => (
                <Fragment key={header}>
                  {index === 0 ? (
                    <th className="Table__Head text-left">{header}</th>
                  ) : (
                    <th className="Table__Head text-right">{header}</th>
                  )}
                </Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {budgetData
              ?.filter((b) => b.level !== null && b.level === -1)
              ?.map((elem) => (
                <tr key={nanoid()}>
                  <td className="text-left">{elem.label}</td>
                  <td className="text-right">
                    {displayDate(elem?.ratio.date)}
                  </td>
                  <td className="text-right">
                    {displayDate(elem?.budget.date)}
                  </td>
                  <td className="text-right">
                    {displayDate(elem?.engaged.date)}
                  </td>
                  <td className="text-right">
                    {displayDate(elem?.billed.date)}
                  </td>
                  <td className="text-right">
                    {displayDate(elem?.leftToBill.date)}
                  </td>
                  <td className="text-right">{displayDate(elem?.paid.date)}</td>
                  {dynamicHeaders[headers.length] && (
                    <td className="text-right">
                      {displayDate(elem?.RF_0.date)}
                    </td>
                  )}
                  {dynamicHeaders[headers.length + 1] && (
                    <td className="text-right">
                      {displayDate(elem?.RF_1.date)}
                    </td>
                  )}
                  {dynamicHeaders[headers.length + 2] && (
                    <td className="text-right">
                      {displayDate(elem?.RF_2.date)}
                    </td>
                  )}
                  {dynamicHeaders[headers.length + 3] && (
                    <td className="text-right">
                      {displayDate(elem?.RF_3.date)}
                    </td>
                  )}
                  {dynamicHeaders[headers.length + 4] && (
                    <td className="text-right">
                      {displayDate(elem?.RF_4.date)}
                    </td>
                  )}
                </tr>
              ))}
            {budgetData
              ?.filter((b) => b.level !== null && b.level > -1)
              ?.map((elem) => (
                <Fragment key={`${elem.label}-${elem.level}`}>
                  <tr
                    className={classnames({
                      "bg-blue-900/40": elem.level === 0,
                      "bg-blue-900/20": elem.level === 1,
                    })}
                  >
                    <td className="text-left">{elem.label}</td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.ratio.value,
                          elem.ratio.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.budget.value,
                          elem.budget.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.engaged.value,
                          elem.engaged.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.billed.value,
                          elem.billed.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.leftToBill.value,
                          elem.leftToBill.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(elem.paid.value, elem.paid.isPercentage)}
                      </span>
                    </td>
                    {dynamicHeaders[headers.length] && (
                      <td className="text-right">
                        <span>
                          {displayValue(
                            elem.RF_0.value,
                            elem.RF_0.isPercentage,
                          )}
                        </span>
                      </td>
                    )}
                    {dynamicHeaders[headers.length + 1] && (
                      <td className="text-right">
                        <span>
                          {displayValue(
                            elem.RF_1.value,
                            elem.RF_1.isPercentage,
                          )}
                        </span>
                      </td>
                    )}
                    {dynamicHeaders[headers.length + 2] && (
                      <td className="text-right">
                        <span>
                          {displayValue(
                            elem.RF_2.value,
                            elem.RF_2.isPercentage,
                          )}
                        </span>
                      </td>
                    )}
                    {dynamicHeaders[headers.length + 3] && (
                      <td className="text-right">
                        <span>
                          {displayValue(
                            elem.RF_3.value,
                            elem.RF_3.isPercentage,
                          )}
                        </span>
                      </td>
                    )}
                    {dynamicHeaders[headers.length + 4] && (
                      <td className="text-right">
                        <span>
                          {displayValue(
                            elem.RF_4.value,
                            elem.RF_4.isPercentage,
                          )}
                        </span>
                      </td>
                    )}
                  </tr>
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
