import DropdownFilters from "features/programs/DropdownFilter"
import { t } from "i18next"
import { TIdLabel } from "shared/types/global.type"
import { STUDY_TYPES } from "../ressources/study.resources"

export interface ITypesFiltersProps {
  selectedStudyRequestTypes: number[] | []
  onStudyRequestTypesChange: (selectedType: number[] | []) => void
}

export default function selectedStudyRequestTypesFilter(
  props: ITypesFiltersProps,
) {
  const { selectedStudyRequestTypes, onStudyRequestTypesChange } = props
  const typesOptions = STUDY_TYPES

  const typeOptionWithAll = [
    {
      id: -1,
      label: t("select-all"),
    },
    ...typesOptions,
  ]

  const handleTypeClick = (type: TIdLabel) => {
    if (type.id === -1) {
      const shouldSelectAll =
        selectedStudyRequestTypes.length !== typesOptions.length
          ? typesOptions.map((elmt) => elmt.id)
          : []

      onStudyRequestTypesChange(shouldSelectAll)
    } else {
      const isSelected = (
        (selectedStudyRequestTypes as number[]) ?? []
      ).includes(type.id)

      const updatedTypes = isSelected
        ? selectedStudyRequestTypes.filter(
            (currentType) => currentType !== type.id,
          )
        : [...selectedStudyRequestTypes, type.id]

      onStudyRequestTypesChange(updatedTypes)
    }
  }

  const isSelectedAll = selectedStudyRequestTypes.length === typesOptions.length

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900">
              {t("types")}
              {selectedStudyRequestTypes.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-purple-800 bg-purple-100 rounded-full">
                  {selectedStudyRequestTypes.length}
                </div>
              )}
            </div>
          )}
          options={typeOptionWithAll}
          renderOption={(type, index) => (
            <li
              onClick={() => handleTypeClick(type as TIdLabel)}
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              tabIndex={-1}
              id={`menu-item-${index}`}
              key={`menu-item-${index}`}
            >
              <input
                className="mr-2"
                type="checkbox"
                checked={
                  type.id === -1
                    ? isSelectedAll
                    : (selectedStudyRequestTypes as number[])?.includes(type.id)
                }
              />
              {t(type.label)}
            </li>
          )}
          keyExtractor={(type) => type.id}
        />
      </div>
    </div>
  )
}
