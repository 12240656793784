import { TChildOperation } from "shared/types/operation.type"
import { ChildOperationBudget } from "./budget/ChildOperationBudget"

interface TOperationChildItemFinancialProps {
  operationChild: TChildOperation
}

export default function OperationChildItemFinancial(
  props: TOperationChildItemFinancialProps,
) {
  const { operationChild } = props

  return (
    <div className="my-2 overflow-scroll">
      <ChildOperationBudget
        childOperationId={operationChild.registrationNumber}
      />
    </div>
  )
}
