import { useQuery } from "@tanstack/react-query"
import { getNextStepsById, getSteps } from "core/api/steps"
import { CustomAxiosError } from "shared/types/axios.type"
import { TProjectNextStep, TProjectStep } from "shared/types/project.type"

function useSteps() {
  return useQuery<TProjectStep[], CustomAxiosError, TProjectStep[]>({
    queryKey: ["steps"],
    queryFn: () => getSteps(),
  })
}

function useGetNextStepsById(stepId?: number) {
  return useQuery<TProjectNextStep, CustomAxiosError, TProjectNextStep>({
    queryKey: ["getNextStepsById", stepId],
    queryFn: () => getNextStepsById(stepId),
  })
}

export { useGetNextStepsById, useSteps }
