import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import Box from "components/Box/Box"
import { useProject, useUpdateProject } from "core/query-hooks/useProjects"
import { useGetNextStepsById, useSteps } from "core/query-hooks/useSteps"
import { format } from "date-fns"
import { t } from "i18next"
import { RiRouteFill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Spinner from "../../../components/Spinner/Spinner"
import "./ProjectStepSelect.scss"

export default function ProjectStepSelect() {
  const queryClient = useQueryClient()
  const params = useParams()

  const {
    mutate: updateProjectStep,
    isPending: isLoadingUpdateSteps,
    error: updateStepErrors,
    isError: updateStepIsError,
  } = useUpdateProject(parseInt(params.id!))

  const { data: projectData } = useProject(params.id!)
  const { data: stepsData, isLoading: isLoadingStepsData } = useSteps()
  const { data: nextStepsData, error } = useGetNextStepsById(
    projectData?.step.id,
  )

  const currentStep = stepsData?.find(
    (step) => step.id === projectData?.step.id,
  )

  const onChangeStep = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateProjectStep(
      {
        stepId: parseInt(e.target.value),
      },
      {
        onSuccess: () => {
          toast.success(t("step-updated"))
          queryClient.invalidateQueries({ queryKey: ["project", params.id!] })
          queryClient.invalidateQueries({ queryKey: ["getNextStepsById"] })
        },
        onError: (err) => {
          toast.error(`${err.response?.data.error.message}`)
        },
      },
    )
  }

  return (
    <Box classNames="p-0">
      <div className="flex items-center border-b border-gray-200 px-3.5 py-2">
        <RiRouteFill size={20} className="text-cyan-900 mr-1" />
        <h3 className="text-xs uppercase text-cyan-900 font-medium">
          {`${t("step")}`}
        </h3>
      </div>

      <div className="p-4">
        {projectData?.createdAt && (
          <div className="pl-1 pb-3 text-gray-400 text-xs sm:text-sm">
            {t("date-project-created")}
            {format(new Date(projectData.createdAt), "dd/MM/yyyy")}
          </div>
        )}
        {isLoadingUpdateSteps || isLoadingStepsData ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div className="Text__Field_Container">
            <select
              onChange={onChangeStep}
              name="projectSteps"
              id="project-step-select"
              className={classNames(
                "Text__Field flex items-center justify-center",
              )}
              value={currentStep?.id}
            >
              <option value={currentStep?.id}>{currentStep?.label}</option>
              {nextStepsData?.nextSteps?.map((step) => (
                <option key={`step-${step.id}`} value={step.id}>
                  {step.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {error && (
          <p className="text-xs text-red-500">
            {error?.response?.data.error.message}
          </p>
        )}
        {updateStepIsError && (
          <p className="text-xs text-red-500">
            {updateStepErrors?.code} -{updateStepErrors?.message}
          </p>
        )}
      </div>
    </Box>
  )
}
