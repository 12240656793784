import { API } from "core/api/axios"
import { TCpr, TCreateCpr, TUpdateCpr } from "shared/types/cpr.type"

async function createCpr(cpr: TCreateCpr): Promise<TCpr> {
  const res = await API.post("/cpr", cpr)
  return res.data
}

async function updateCpr(id: number, cpr: TUpdateCpr): Promise<TCpr> {
  const res = await API.patch(`/cpr/${id}`, cpr)
  return res.data
}

async function listCprs(childOperationId: number): Promise<TCpr[]> {
  const res = await API.get(`/cprs?childOperation=${childOperationId}`)
  return res.data
}

export { createCpr, listCprs, updateCpr }
