import { Button } from "components"
import useModal from "components/Modal/useModal"
import { useGetActionById } from "core/query-hooks/useActions"
import ModalEditAction from "features/actions/ModalEditAction"
import { PiPencil } from "react-icons/pi"

interface IEditActionTodosProps {
  actionId: number
}

export default function EditActionTodos({ actionId }: IEditActionTodosProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const { data: action } = useGetActionById(actionId)

  return (
    <>
      {action ? (
        <>
          <Button onClick={toggleModal} size="small" mode="secondary">
            <PiPencil size={22} cursor="pointer" className="text-cyan-800" />
          </Button>
          <ModalEditAction
            toggleModal={toggleModal}
            isModalShowed={isModalShowed}
            action={action}
          />
        </>
      ) : null}
    </>
  )
}
