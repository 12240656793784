import { useEffect, useState } from "react"
import "./Toggle3States.scss"

interface Toggle3StatesProps {
  name: string
  leftLabel: string
  rightLabel: string
  onChange: (value: string) => void
}

export default function Toggle3States({
  name,
  leftLabel,
  rightLabel,
  onChange,
}: Toggle3StatesProps) {
  const [value, setValue] = useState<string>("")

  useEffect(() => {
    onChange(value)
  }, [value])

  return (
    <div className="text-xs text-cyan-900 inline-flex items-center justify-center">
      <p className="mr-2" onClick={() => setValue(leftLabel)}>
        {leftLabel}
      </p>
      <label className="slider">
        <input
          className="sliderOption"
          type="radio"
          name={name}
          value={leftLabel}
          checked={value === leftLabel}
          onClick={() => setValue(leftLabel)}
        />
        <input
          className="sliderOption"
          type="radio"
          name={name}
          checked={value === ""}
          onClick={() => setValue("")}
        />
        <input
          className="sliderOption"
          type="radio"
          name={name}
          value={rightLabel}
          onClick={() => setValue(rightLabel)}
          checked={value === rightLabel}
        />
      </label>
      <p className="ml-2" onClick={() => setValue(rightLabel)}>
        {rightLabel}
      </p>
    </div>
  )
}
