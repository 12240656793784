import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components/Button/Button"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { useCreateCommittee } from "core/query-hooks/useCommittes"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import * as React from "react"
import { useEffect, useState } from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { TCommittee, TCreateCommittee } from "shared/types/committee.type"
import * as yup from "yup"

registerLocale("fr", fr)

interface IAddCommitteeModalProps {
  committeesTypes?: TCommittee[]
  projectId: number
}

const createCommitteeValidationSchema = yup.object({
  typeId: yup
    .number()
    .required(() => t("error-committee-type-mandatory"))
    .transform((curr, orig) => (orig === "" ? null : curr)),
  date: yup
    .date()
    .required(() => t("error-date-mandatory"))
    .transform((curr, orig) => (orig === "" ? null : curr)),
})

export default function AddCommitteeModal({
  committeesTypes,
  projectId,
}: IAddCommitteeModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const [date, setDate] = useState<null | Date | undefined>(null)
  const createCommitte = useCreateCommittee()
  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<TCreateCommittee>({
    resolver: yupResolver(
      createCommitteeValidationSchema as yup.ObjectSchema<TCreateCommittee>,
    ),
  })

  async function submitForm(committeeData: TCreateCommittee) {
    createCommitte.mutateAsync(
      {
        typeId: committeeData.typeId,
        date: committeeData.date,
        operationId: projectId,
        isDefinitive: false,
      },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: ["listCommitteesTypes"],
          })
          queryClient.invalidateQueries({
            queryKey: ["listCommitteesByOperation", projectId],
          })
          toast.success(t("toast-committee-added-success"))
          toggleModal()
        },
        onError() {
          toast.error(t("toast-committee-creation-error"))
        },
      },
    )
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
    setDate(null)
  }

  const firstChoice = committeesTypes?.reduce((prev, current) => {
    return prev.id < current.id ? prev : current
  })

  useEffect(() => {
    if (firstChoice) {
      setValue("typeId", firstChoice.id)
    }
  }, [committeesTypes, firstChoice, setValue])

  return (
    <>
      <Button onClick={toggleModal} size="small" mode="primary">
        {`${t("add-committee")}`}
      </Button>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("add-committee")}`}
      >
        <form onSubmit={(e) => onSubmit(e)} className="Text__Field_Container">
          <div className="Text__Field_Container">
            <label htmlFor="typeId">
              {t("committee-type")}
              <select
                id="typeId"
                className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                {...register("typeId")}
              >
                {committeesTypes?.map((comType) => (
                  <option value={comType.id} key={comType.id}>
                    {comType.label}
                  </option>
                ))}
              </select>
            </label>
            {errors.typeId && (
              <p className="text-xs text-red-500">{errors.typeId?.message}</p>
            )}
          </div>

          <div className="flex flex-col mt-2">
            <label htmlFor="date">{t("date")} *</label>
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange } }) => (
                <CustomDatePicker
                  id="date"
                  className="Text__Field"
                  locale={fr}
                  selected={date}
                  required
                  placeholderText={`${t("select-date")}`}
                  onChange={(selectedDate) => {
                    if (selectedDate) setDate(selectedDate)
                    onChange(selectedDate)
                  }}
                />
              )}
            />
            {errors.date && (
              <p className="text-xs text-red-500">{errors.date?.message}</p>
            )}
          </div>

          <div className="Modal__Footer mt-4">
            <Button
              type="button"
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => toggleModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button type="submit" marginLeft={24} size="medium" mode="primary">
              {`${t("confirm")}`}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
