import { yupResolver } from "@hookform/resolvers/yup"
import classNames from "classnames"
import { Button } from "components/Button/Button"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { useUpdateTask } from "core/query-hooks/useTasks"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { FormEvent, useState } from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useForm } from "react-hook-form"
import { MdArrowForward, MdCheckCircle } from "react-icons/md"
import { toast } from "react-toastify"
import { TTask } from "shared/types/task.type"
import hasPermissions from "utils/hasPermissions"
import * as yup from "yup"

registerLocale("fr", fr)

interface IEditTaskModalProps {
  task: TTask
  deleteTask: (id: number) => void
  refetch: () => void
}

type FormValues = {
  deadline: Date
  label: string
  comment: string | null
  isDefinitive: boolean
  reminder: Date | null
}

const validationSchema = yup.object({
  label: yup.string().required(() => `${t("error-message-mandatory")}`),
  comment: yup.string().nullable(),
  isDefinitive: yup.boolean(),
  reminder: yup.date().nullable(),
  deadline: yup.date().required(() => `${t("error-message-mandatory")}`),
})

export default function EditTaskModal({
  task,
  deleteTask,
  refetch,
}: IEditTaskModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const [deadline, setDeadline] = useState<Date | null>(new Date(task.deadline))
  const [reminder, setReminder] = useState<null | Date | undefined>(
    task?.reminder ? new Date(task?.reminder) : null,
  )
  const [isDefinitive, setIsDefinitive] = useState<boolean>(task?.isDefinitive)

  const updateTask = useUpdateTask(task.id)
  const { data: user } = useGetCurrentUser()

  const handleToggleIsDefinitive = () => {
    setIsDefinitive(!isDefinitive)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema as yup.ObjectSchema<FormValues>),
    defaultValues: {
      label: task.label,
      deadline: task.deadline,
      comment: task.comment,
      isDefinitive: task.isDefinitive,
      reminder: task.reminder,
    },
  })

  const isAllowed = hasPermissions(["update:task"], user?.rights)

  const submitForm = async (data: FormValues) => {
    deadline
      ? updateTask.mutate(
          {
            label: data.label,
            deadline,
            reminder,
            comment: data.comment ? data.comment : null,
            isDefinitive,
          },
          {
            onSuccess: () => {
              toast.success(`${t("toast-task-update-success")}`)
              refetch()
              toggleModal()
            },
            onError: (err) =>
              toast.error(
                `${t("toast-task-update-error")}, ${
                  err.response?.data.error.message
                }`,
              ),
          },
        )
      : toast.error(`${t("toast-task-update-date-error")}`)
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(submitForm)()
  }

  const onDeleteTask = (id: number) => {
    deleteTask(id)
    toggleModal()
  }

  return (
    <>
      {task?.isDefinitive ? (
        <button
          type="button"
          className={classNames("flex items-center cursor-default text-left", {
            "text-blue-600 cursor-pointer": isAllowed,
          })}
          onClick={isAllowed ? toggleModal : undefined}
        >
          {isAllowed && (
            <MdCheckCircle color="#0CB28B" className="mr-2 shrink-0" />
          )}
          {task?.label}
        </button>
      ) : (
        <button
          type="button"
          className={classNames("flex items-center cursor-default text-left", {
            "text-blue-600 cursor-pointer": isAllowed,
          })}
          onClick={isAllowed ? toggleModal : undefined}
        >
          {isAllowed && <MdArrowForward className="mr-2 shrink-0" />}
          {task?.label}
        </button>
      )}
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("edit-task")}`}
      >
        <form onSubmit={onSubmit} className="Text__Field_Container">
          <div className="Text__Field_Container">
            <label htmlFor="label">
              {`${t("label")} *`}
              <input
                id="label"
                className="Text__Field"
                {...register("label")}
                type="text"
                placeholder={`${t("label")}`}
              />
            </label>
            {errors?.label && (
              <p className="text-red-600 text-sm">{errors.label.message}</p>
            )}

            <label htmlFor="comment">
              {`${t("comment")}`}
              <textarea
                id="comment"
                className="Textarea__Field"
                {...register("comment")}
                placeholder={`${t("comment")}`}
              />
            </label>
          </div>

          <div className="flex flex-col mt-1">
            <label htmlFor="reminder">{`${t("reminder-date")}`}</label>
            <CustomDatePicker
              id="reminder"
              locale={fr}
              className="Text__Field"
              placeholderText={`${t("reminder-date")}`}
              selected={reminder}
              onChange={(changedDate) => setReminder(changedDate)}
            />
          </div>

          <div className="grid sm:grid-cols-3 sm:gap-x-4 grid-cols-1">
            <div className="sm:col-span-2 flex flex-col mt-2">
              <label htmlFor="deadline">{`${t("deadline")} *`}</label>
              <CustomDatePicker
                id="deadline"
                locale={fr}
                className="Text__Field"
                placeholderText={`${t("deadline")}`}
                selected={deadline}
                onChange={(changedDate) => setDeadline(changedDate)}
                required
              />

              {errors?.deadline && (
                <p className="text-red-600 text-sm">
                  {errors.deadline.message}
                </p>
              )}
            </div>

            <div>
              <label htmlFor="actions-toggle-btn">
                {`${t("realized")}`}
                <div className="flex items-center justify-start sm:mt-2">
                  <ToggleSwitch
                    id="actions-toggle-btn"
                    name="toggle-not-done-actions"
                    disabled={false}
                    checked={isDefinitive!}
                    onChange={handleToggleIsDefinitive}
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="Modal__Footer flex justify-between mt-4">
            <div>
              <Button
                type="button"
                size="medium"
                mode="danger"
                isLoading={false}
                onClick={() => onDeleteTask(task?.id!)}
              >
                {`${t("delete")}`}
              </Button>
            </div>
            <div>
              <Button
                type="button"
                size="medium"
                mode="secondary"
                isLoading={false}
                onClick={() => toggleModal()}
              >
                {`${t("cancel")}`}
              </Button>
              <Button
                type="submit"
                marginLeft={24}
                size="medium"
                mode="primary"
                isLoading={false}
              >
                {`${t("confirm")}`}
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}
