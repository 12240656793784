import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import { useUpdateStatus } from "core/query-hooks/useStudyRequests"
import { t } from "i18next"
import { FormEvent } from "react"
import { toast } from "react-toastify"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"
import { CostOfWorkRestitutionForm } from "./costOfWork/restitution/CostOfWorkRestitutionForm"
import FeasibilityRestitutionForm from "./feasibilities/restitution/FeasibilityRestitutionForm"
import InfographyRestitutionForm from "./infographies/restitution/InfographyRestitutionForm"

import {
  ECONOMY_RESTITUTION_GED_CAT,
  FEASIBILITY_RESTITUTION_GED_CAT,
  INFOGRAPHY_RESTITUTION_GED_CAT,
  STATUS_RETURNED,
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "./studyRequest.resources"

export interface IRestitutionFormProps {
  requestId: number
  studyRequestType: number
  studyRequest: TStudyRequest
  studyRequestHistory: TStudyRequestHistory[]
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void
}

function RenderForm(props: IRestitutionFormProps) {
  const {
    requestId,
    studyRequest,
    studyRequestType,
    studyRequestHistory,
    onSubmit,
  } = props

  switch (studyRequestType) {
    case TYPE_FEASIBILITY:
      return (
        <GedAxiosInterceptor>
          <FeasibilityRestitutionForm
            categoryId={FEASIBILITY_RESTITUTION_GED_CAT.id}
            requestId={requestId}
            studyRequestType={TYPE_FEASIBILITY}
            studyRequest={studyRequest}
            studyRequestHistory={studyRequestHistory}
          />
        </GedAxiosInterceptor>
      )
    case TYPE_INFOGRAPHY:
      return (
        <GedAxiosInterceptor>
          <InfographyRestitutionForm
            categoryId={INFOGRAPHY_RESTITUTION_GED_CAT.id}
            requestId={requestId}
            studyRequestType={TYPE_INFOGRAPHY}
            studyRequest={studyRequest}
            studyRequestHistory={studyRequestHistory}
          />
        </GedAxiosInterceptor>
      )

    case TYPE_COST_OF_WORK:
      return (
        <GedAxiosInterceptor>
          <CostOfWorkRestitutionForm
            categoryId={ECONOMY_RESTITUTION_GED_CAT.id}
            requestId={requestId}
            studyRequestType={TYPE_COST_OF_WORK}
            studyRequest={studyRequest}
            studyRequestHistory={studyRequestHistory}
          />
        </GedAxiosInterceptor>
      )

    default:
      return (
        <div>
          <form className="Text__Field_Container" onSubmit={onSubmit}>
            <div className="flex flex-row-reverse mt-2">
              <Button type="submit" size="small" mode="validation">
                {`${t("studyRequest.return")}`}
              </Button>
            </div>
          </form>
        </div>
      )
  }
}

export function RestitutionForm(props: IRestitutionFormProps) {
  const { requestId } = props
  const queryClient = useQueryClient()
  const updateStudyRequestStatus = useUpdateStatus(requestId!)

  const updateStatus = () => {
    updateStudyRequestStatus.mutate(
      {
        statusId: STATUS_RETURNED,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["getFeasibility", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getCostOfWork", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getInfography", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getFeasibilityHistory", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getCostOfWorkHistory", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getInfographyHistory", requestId!],
          })
          queryClient.invalidateQueries({ queryKey: ["listStudyRequests"] })
          queryClient.invalidateQueries({
            queryKey: ["getDocumentsByCategoryAndProjectId"],
          })
          toast.success(`${t("studyRequest.toast.restitutionSuccess")}`)
        },
        onError: (err) =>
          toast.error(
            `${t("studyRequest.toast.restitutionError")} ${
              err.response?.data.message
            }`,
          ),
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    updateStatus()
  }

  return (
    <div>
      <RenderForm {...props} onSubmit={() => onSubmit} />
    </div>
  )
}
