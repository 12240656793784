import { useAuthState } from "react-firebase-hooks/auth"
import { Navigate } from "react-router-dom"
import { auth } from "utils/firebase"

function HiddenRoute({ children }: { children: JSX.Element }) {
  const [user] = useAuthState(auth)

  if (user) {
    return <Navigate to="/" replace />
  }

  return children
}

export default HiddenRoute
