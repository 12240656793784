import classNames from "classnames"
import * as React from "react"
import { forwardRef } from "react"
import { Controller } from "react-hook-form"
import "./Toggle.scss"

interface IToggleProps {
  ref?: React.Ref<any>
  placeholder: string
  name: string
  props?: any
  className?: string
  id: string
  leftLabel: string
  rightLabel: string
  control: any
}

const Toggle = forwardRef<HTMLTextAreaElement, IToggleProps>(
  (
    {
      className,
      leftLabel,
      rightLabel,
      placeholder,
      name,
      control,
      id,
      ...props
    },
    ref,
  ) => (
    <div className="Toggle__Container">
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <div className="Toggle__Input_Container">
              <input
                name={field.name}
                id="radio_left"
                ref={ref}
                aria-label={leftLabel}
                className={classNames(["Text__Field", className])}
                placeholder={placeholder}
                {...props}
                type="radio"
                checked={field.value === leftLabel}
                value={leftLabel}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
              />
              <label className="Left__Radio" htmlFor="radio_left">
                <span>{leftLabel}</span>
              </label>
            </div>
            <div className="Toggle__Input_Container">
              <input
                name={field.name}
                ref={ref}
                id="radio_right"
                value={rightLabel}
                className={classNames(["Text__Field Right__Radio", className])}
                placeholder={placeholder}
                {...props}
                type="radio"
                checked={field.value === rightLabel}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
              />
              <label className="Right__Radio" htmlFor="radio_right">
                <span>{rightLabel}</span>
              </label>
            </div>
          </>
        )}
      />
    </div>
  ),
)

export default Toggle
