import { Button } from "components"
import useModal from "components/Modal/useModal"
import LightTable from "components/Table/LightTable"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { format } from "date-fns"
import { t } from "i18next"
import { Suspense, useEffect, useState } from "react"
import { MdDelete } from "react-icons/md"
import {
  TFlattenSimplifiedProgram,
  TSimplifiedProgram,
} from "shared/types/simplified-programs.type"
import { AddSimplifiedProgram } from "./AddSimplifiedProgram"
import { DeleteSimplifiedProgram } from "./DeleteSimplifiedProgram"
import { EditSimplifiedProgram } from "./EditSimplifiedProgram"

export interface ISimplifiedProgramsListProps {
  data: TSimplifiedProgram[]
}

export function SimplifiedProgramsList(props: ISimplifiedProgramsListProps) {
  const { data } = props
  const [currentId, setCurrentId] = useState<number | undefined>(undefined)
  const [filteredData, setFilteredData] = useState<TFlattenSimplifiedProgram[]>(
    [],
  )
  const {
    isShowing: isAddSimplifiedProgramModalShowed,
    toggle: toggleSimplifiedProgramModal,
  } = useModal()

  const {
    isShowing: isEditSimplifiedProgramModalShowed,
    toggle: toggleEditSimplifiedProgramModal,
  } = useModal()

  const {
    isShowing: isDeleteSimplifiedProgramModalShowed,
    toggle: toggleDeleteSimplifiedProgramModal,
  } = useModal()

  useEffect(() => {
    document.body.style.overflow = isEditSimplifiedProgramModalShowed
      ? "hidden"
      : "visible"
  }, [isEditSimplifiedProgramModalShowed])

  useEffect(() => {
    setFilteredData(
      data.map((item: TSimplifiedProgram) => ({
        id: item.id,
        registrationNumber: item.registrationNumber,
        name: item.name,
        pierrevalCompany: item.pierrevalCompany?.label,
        city: item.city,
        postcode: item.postcode,
        numberOfLots: item.numberOfLots,
        reservation: item.reservation,
        deed: item.deed,
        provision: item.provision,
        priceHT: item.priceHT,
        pierrevalCapital: item.pierrevalCapital,
        businessUnit: item.businessUnit?.label,
      })),
    )
  }, [data])

  const headers = [
    t("registrationNumber"),
    t("simplified-program-name"),
    t("pierreval-company"),
    t("business-unit-acronym"),
    t("postcode"),
    t("city"),
    t("number-of-lots"),
    t("reservation"),
    t("deed"),
    t("provision"),
    t("pierreval-part-of-capital"),
    t("price-ht"),
    "",
  ]

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("registrationNumber")}`, value: ["registrationNumber"] },
    { key: `${t("simplified-program-name")}`, value: ["name"] },
    { key: `${t("pierreval-company")}`, value: ["pierrevalCompany"] },
    { key: `${t("business-unit-acronym")}`, value: ["businessUnit"] },
    { key: `${t("postcode")}`, value: ["postcode"] },
    { key: `${t("city")}`, value: ["city"] },
    { key: `${t("number-of-lots")}`, value: ["numberOfLots"] },
    { key: `${t("reservation")}`, value: ["reservation"] },
    { key: `${t("deed")}`, value: ["deed"] },
    { key: `${t("provision")}`, value: ["provision"] },
    { key: `${t("pierreval-part-of-capital")}`, value: ["pierrevalCapital"] },
    { key: `${t("price-ht")}`, value: ["priceHT"] },
  ]

  return (
    <>
      {isEditSimplifiedProgramModalShowed && (
        <Suspense>
          <EditSimplifiedProgram
            isEditSimplifiedProgramModalShowed={
              isEditSimplifiedProgramModalShowed
            }
            toggleEditSimplifiedProgramModal={toggleEditSimplifiedProgramModal}
            simplifiedProgramId={currentId!}
          />
        </Suspense>
      )}

      {isDeleteSimplifiedProgramModalShowed && (
        <Suspense>
          <DeleteSimplifiedProgram
            isDeleteSimplifiedProgramModalShowed={
              isDeleteSimplifiedProgramModalShowed
            }
            toggleDeleteSimplifiedProgramModal={
              toggleDeleteSimplifiedProgramModal
            }
            simplifiedProgramId={currentId!}
          />
        </Suspense>
      )}

      {isAddSimplifiedProgramModalShowed && (
        <Suspense>
          <AddSimplifiedProgram
            isAddSimplifiedProgramModalShowed={
              isAddSimplifiedProgramModalShowed
            }
            toggleSimplifiedProgramModal={toggleSimplifiedProgramModal}
          />
        </Suspense>
      )}

      <div className="p-4">
        <PermissionsGuard requiredRoles={["create:simplified-project"]}>
          <div className="flex items-center justify-end mb-2">
            <Button
              onClick={toggleSimplifiedProgramModal}
              type="button"
              size="small"
              mode="primary"
            >
              + {t("add")}
            </Button>
          </div>
        </PermissionsGuard>
        <div className="Light_Table__Wrapper overflow-auto">
          <LightTable
            headers={headers}
            items={filteredData}
            sortableFields={sortableFields}
            renderRow={(item) => (
              <>
                <td>
                  <span
                    onClick={() => {
                      setCurrentId(item.id)
                      toggleEditSimplifiedProgramModal()
                    }}
                    className="transition-colors text-blue-500 hover:text-blue-600 ease-in-out delay-75 cursor-pointer"
                  >
                    {item.registrationNumber ? item.registrationNumber : "-"}
                  </span>
                </td>
                <td>{item.name ? item.name : "-"}</td>
                <td>{item?.pierrevalCompany ? item.pierrevalCompany : "-"}</td>
                <td>{item.businessUnit ? item.businessUnit : "-"}</td>
                <td>{item.postcode ? item.postcode : "-"}</td>
                <td>{item.city ? item.city : "-"}</td>
                <td>{item.numberOfLots ? item.numberOfLots : "-"}</td>
                <td>
                  {item.reservation
                    ? format(new Date(item.reservation), "dd/MM/yyyy")
                    : "-"}
                </td>
                <td>
                  {item.deed ? format(new Date(item.deed), "dd/MM/yyyy") : "-"}
                </td>
                <td>
                  {item.provision
                    ? format(new Date(item.provision), "dd/MM/yyyy")
                    : "-"}
                </td>
                <td>
                  {item.pierrevalCapital ? `${item.pierrevalCapital} %` : "-"}
                </td>
                <td>
                  {item.priceHT
                    ? parseFloat(item.priceHT).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })
                    : "-"}
                </td>
                <td>
                  <PermissionsGuard
                    requiredRoles={["delete:simplified-project"]}
                  >
                    <MdDelete
                      title={`${t("delete")}`}
                      fontSize={18}
                      className="transition cursor-pointer opacity-70 hover:opacity-100 duration-150 ease-out hover:ease-in"
                      onClick={() => {
                        setCurrentId(item.id)
                        toggleDeleteSimplifiedProgramModal()
                      }}
                    />
                  </PermissionsGuard>
                </td>
              </>
            )}
          />
        </div>
      </div>
    </>
  )
}
