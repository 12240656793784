import Avatar from "../../components/Avatar/Avatar"
import { useGetUserByAzureId } from "../../core/query-hooks/useUsers"

interface IContributorPreviewAvatarProps {
  userAzureId: string
}

export default function ContributorPreviewAvatar({
  userAzureId,
}: IContributorPreviewAvatarProps) {
  const { data } = useGetUserByAzureId(userAzureId)

  return data ? <Avatar hasHoverCard key={data.id} user={data} /> : null
}
