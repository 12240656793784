import classNames from "classnames"
import { t } from "i18next"
import { PropsWithChildren } from "react"
import { TTooltipReservation } from "shared/types/spo/reservation.type"
import "./Tooltip.scss"
import { nanoid } from "nanoid"

interface IRythmTooltip extends PropsWithChildren {
  tooltip: TTooltipReservation
  className: string
  isHousingEstate: boolean
}

export default function RythmTooltip(props: IRythmTooltip) {
  const { children, tooltip, className, isHousingEstate } = props
  return (
    <span className="tooltipParent text-xs font-semibold">
      <span className={classNames("rythmTooltip whitespace-nowrap", className)}>
        {tooltip.reservation.length > 0 && (
          <ul className="list-inside">
            <h3 className="font-semibold">{`${t("reservation")} :`}</h3>
            {tooltip.reservation.map((r) => (
              <li
                className="list-item text-xs text-cyan-900 ml-2"
                key={nanoid()}
              >
                <p>
                  {`${r.key} ${isHousingEstate ? "m²" : ""} (${r.occurrence})`}
                </p>
              </li>
            ))}
          </ul>
        )}
        {tooltip.cancelation.length > 0 && (
          <ul className="list-inside">
            <h3 className="font-semibold">{`${t("cancelation")} :`}</h3>
            {tooltip.cancelation.map((r) => (
              <li
                className="list-item text-xs text-cyan-900 ml-2"
                key={nanoid()}
              >
                <p>
                  {`${r.key} ${isHousingEstate ? "m²" : ""} (${r.occurrence})`}
                </p>
              </li>
            ))}
          </ul>
        )}
      </span>
      <span className="text-base font-normal">{children}</span>
    </span>
  )
}
