import AvatarBlob from "components/Avatar/AvatarBlob"
import { TPersonInCharge } from "shared/types/user.type"

type IUsersProps = {
  users: TPersonInCharge[]
}

export default function AssignedUsersFormatter(props: IUsersProps) {
  const { users } = props

  return users && users.length > 0 ? (
    <div className="flex items-center mt-1">
      {users.map((user) => (
        <div
          key={user.azureId}
          className="mr-1 assigned-users flex items-center bg-fuchsia-100 w-fit h-fit px-2 py-1 rounded-md"
        >
          <AvatarBlob className="w-5 h-5" user={user} />
          <span className="text-xs font-semibold">
            {user.firstName} {user.lastName}
          </span>
        </div>
      ))}
    </div>
  ) : null
}
