import classnames from "classnames"
import { PropsWithChildren } from "react"

export interface IHeaderProps extends PropsWithChildren {
  size?: string
  classNames?: string
}

function Header({ size, children, classNames }: IHeaderProps) {
  if (size === "h2") {
    return (
      <h2 className={`${classnames(classNames)} Color__Primary font-bold`}>
        {children}
      </h2>
    )
  }

  return (
    <h1 className={`${classnames(classNames)} text-xl Color__Primary`}>
      {children}
    </h1>
  )
}

export { Header }
