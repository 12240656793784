import { KeyboardEvent } from "react"
import "./ToggleSwitch.scss"

interface ToggleSwitchProps {
  id: string
  name: string
  checked: boolean
  onChange: (value: boolean) => void
  disabled: boolean
  size?: string
}

export default function ToggleSwitch({
  id,
  name,
  checked,
  onChange,
  disabled,
  size,
}: ToggleSwitchProps) {
  function handleKeyPress(e: KeyboardEvent) {
    if (e.code !== "32") return
    e.preventDefault()
    onChange(!checked)
  }

  return (
    <div className={`toggle-switch ${size}`}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id ? (
        <p
          className="toggle-switch-label"
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => handleKeyPress(e)}
        >
          <span
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
            tabIndex={-1}
          />
        </p>
      ) : null}
    </div>
  )
}
