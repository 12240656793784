import classnames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { useGetSyntheseFinanciereSum } from "core/query-hooks/useSyntheseFinanciere"
import { format } from "date-fns"
import { t } from "i18next"
import { Fragment } from "react/jsx-runtime"

export interface IOperationBudgetProps {
  childOperationIds: string[]
}

export function OperationBudget(props: IOperationBudgetProps) {
  const { childOperationIds } = props
  const {
    isLoading,
    error,
    data: globalBudget,
  } = useGetSyntheseFinanciereSum(childOperationIds)

  if (isLoading) return <Spinner />

  if (error) {
    if (error?.response?.status !== 404 && error?.response?.status !== 418)
      return <p>{t("budget-error")}</p>
    if (error?.response?.status === 404)
      return <p>{t("no-budget-available")}</p>
    if (error?.response?.status === 418)
      return <p>{t("error-status-has-no-budget")}</p>
  }

  if (globalBudget && globalBudget?.length === 0) return <Spinner />

  const headers = [
    t("k-euros-by-post"),
    t("ratio"),
    t("budget"),
    t("committed"),
    t("invoiced"),
    t("to-be-invoiced"),
    t("paid"),
  ]

  function displayValue(n: number | null, isPercentage: boolean): string {
    if (!n) return "-"

    return isPercentage
      ? `${+(parseFloat(n.toFixed(2)) * 100).toFixed(2)} %`
      : n.toLocaleString("fr-FR")
  }

  function displayDate(d: string | null): string {
    if (!d) return "-"

    return `${format(new Date(new Date(d)), "dd/MM/yyyy")}`
  }

  return (
    <div className="Light_Table__Wrapper max-h-[60vh]">
      <div className="Table__Sticky_Container">
        <table className="Table">
          <thead>
            <tr className="Table__Sticky_Header">
              {headers.map((header, index) => (
                <Fragment key={header}>
                  {index === 0 ? (
                    <th className="Table__Head text-left">{header}</th>
                  ) : (
                    <th className="Table__Head text-right">{header}</th>
                  )}
                </Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {globalBudget
              ?.filter((b) => b.level !== null && b.level === -1)
              ?.map((elem) => (
                <tr>
                  <td className="text-left">{elem.label}</td>
                  <td className="text-right">
                    {displayDate(elem?.ratio.date)}
                  </td>
                  <td className="text-right">
                    {displayDate(elem?.budget.date)}
                  </td>
                  <td className="text-right">
                    {displayDate(elem?.engaged.date)}
                  </td>
                  <td className="text-right">
                    {displayDate(elem?.billed.date)}
                  </td>
                  <td className="text-right">
                    {displayDate(elem?.leftToBill.date)}
                  </td>
                  <td className="text-right">{displayDate(elem?.paid.date)}</td>
                </tr>
              ))}
            {globalBudget
              ?.filter((b) => b.level !== null && b.level > -1)
              ?.map((elem) => (
                <Fragment key={`${elem.label}-${elem.level}`}>
                  <tr
                    className={classnames({
                      "bg-blue-900/40": elem.level === 0,
                      "bg-blue-900/20": elem.level === 1,
                    })}
                  >
                    <td className="text-left">{elem.label}</td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.ratio.value,
                          elem.ratio.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.budget.value,
                          elem.budget.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.engaged.value,
                          elem.engaged.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.billed.value,
                          elem.billed.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(
                          elem.leftToBill.value,
                          elem.leftToBill.isPercentage,
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <span>
                        {displayValue(elem.paid.value, elem.paid.isPercentage)}
                      </span>
                    </td>
                  </tr>
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
