import classNames from "classnames"
import ItemsCounter from "components/Counter/ItemsCounter"
import DateFormat from "components/Date/DateFormat"
import ArrowLeft from "components/Icons/ArrowLeft"
import { LinkCustom } from "components/Link/Link"
import LightTable from "components/Table/LightTable"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { format } from "date-fns"
import useCountAlfredTodos from "features/count-todos/count-alfred-todos/useCountAlfredTodos"
import ExpectedTodoToggleCell from "features/expected-tasks/ExpectedTodoToggleCell"
import { t } from "i18next"
import { Fragment, useState } from "react"
import { BiTask } from "react-icons/bi"
import { Link, useNavigate } from "react-router-dom"
import EditActionTodos from "./EditActionTodos"

const headers = [
  "",
  t("creation-date"),
  `${t("project-title")}/${t("operation")}`,
  t("action"),
  t("creator"),
  t("deadline"),
  "",
]
const sortableFields: { key: string; value: string[] }[] = [
  { key: `${t("creation-date")}`, value: ["createdAt"] },
  {
    key: `${t("project-title")}/${t("operation")}`,
    value: ["project", "label"],
  },
  { key: `${t("action")}`, value: ["description"] },
  { key: `${t("creator")}`, value: ["creator"] },
  { key: `${t("deadline")}`, value: ["deadline"] },
]
export default function UserExpectedTodos() {
  const navigate = useNavigate()
  const [displayAll, setDisplayAll] = useState<boolean>(false)
  const oneMonthDate = new Date()
  oneMonthDate.setMonth(oneMonthDate.getMonth() + 1)
  const stringifiedOneMonthDate = format(oneMonthDate, "yyyy-MM-dd")
  const [dateFilter, setDateFilter] = useState(stringifiedOneMonthDate)

  const {
    error,
    isLoading,
    todos,
    lateTodos,
    stringifiedCurrentDate,
    stringifiedFutureDate,
    countIncomingTodos,
  } = useCountAlfredTodos(dateFilter)

  const handleDisplayAll = () => {
    setDisplayAll(!displayAll)
    setDateFilter(!displayAll ? "" : stringifiedOneMonthDate)
  }

  const sortedTodos = todos.sort(
    (a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime(),
  )

  return (
    <div className="lg:grid lg:grid-cols-6 lg:gap-4 px-8 py-6">
      <div className="w-full">
        <LinkCustom
          icon={ArrowLeft}
          iconSize={16}
          iconPosition="left"
          onClick={() => navigate(`/`)}
        >
          {t("back-to-home")}
        </LinkCustom>
      </div>
      <div className="col-start-6 col-span-1">
        <ItemsCounter
          totalLateItems={lateTodos ? lateTodos.length : 0}
          totalItems={countIncomingTodos ?? 0}
          title="alvis"
          isLoading={isLoading}
          error={error}
          errorMessage={t("error-loading-todos")}
          Icon={<BiTask fontSize={18} className="mr-1" />}
        />
      </div>
      <div className="col-span-6 flex justify-between">
        <h2 className="font-semibold">{t("my-expectations")}</h2>
        <div className="justify-self-end">
          <label
            className="mr-2 ml-1 text-sm Color__Primary"
            htmlFor="actions-toggle-btn"
          >
            {`${t("display-all")} `}
            <ToggleSwitch
              id="actions-toggle-btn"
              name="toggle-all-actions"
              disabled={false}
              checked={displayAll}
              onChange={handleDisplayAll}
            />
          </label>
        </div>
      </div>
      <div className="col-span-6 Light_Table__Wrapper Table__Wrapper_NoTop">
        {todos.length === 0 ? (
          <div className="rounded-lg bg-white p-4 text-center text-primary">
            {t("no-expected-todos")}
          </div>
        ) : (
          <LightTable
            showTotal={false}
            className="table-auto"
            items={sortedTodos}
            headers={headers}
            sortableFields={sortableFields}
            renderRow={(item, index) => (
              <Fragment key={item.id}>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: todos.length - 1 === index,
                  })}
                >
                  <div className="flex items-center">
                    <ExpectedTodoToggleCell
                      queryKey="getUserTodos"
                      todo={item}
                      stringifiedCurrentDate={stringifiedCurrentDate}
                      stringifiedFutureDate={stringifiedFutureDate}
                    />
                  </div>
                </td>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: todos.length - 1 === index,
                  })}
                >
                  <div className="flex items-center">
                    {format(new Date(item.createdAt), "dd/MM/yyyy")}
                  </div>
                </td>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: todos.length - 1 === index,
                  })}
                >
                  <div className="flex items-center">
                    <Link
                      to={
                        item.project.isRegistered
                          ? `/operation/${item.project.id}${
                              item.isAction
                                ? ""
                                : `/technical/${item.registrationNumber}`
                            }`
                          : `/project/${item.project.id}`
                      }
                      className="text-blue-600"
                    >
                      {item.project.label}
                    </Link>
                  </div>
                </td>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: todos.length - 1 === index,
                  })}
                >
                  <div className="flex items-center">{item.description}</div>
                </td>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: todos.length - 1 === index,
                  })}
                >
                  <div className="flex items-center">{item.creator}</div>
                </td>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: todos.length - 1 === index,
                  })}
                >
                  <div className="flex items-center">
                    <DateFormat
                      date={item.deadline}
                      isDefinitive={item.isDefinitive}
                    />
                  </div>
                </td>
                <td
                  className={classNames({
                    "border-t border-gray-200": index === 0,
                    Table__Bottom_Border: todos.length - 1 === index,
                  })}
                >
                  <div className="flex items-center">
                    {item.isAction ? (
                      <EditActionTodos actionId={item.id} />
                    ) : null}
                  </div>
                </td>
              </Fragment>
            )}
          />
        )}
      </div>
    </div>
  )
}
