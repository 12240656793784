import Spinner from "components/Spinner/Spinner"
import { useListUsers } from "core/query-hooks/useUsers"
import { t } from "i18next"
import { useState } from "react"
import DropdownFilters from "../DropdownFilter"
import useProgramsSearch from "../useProgramsSearch"

export default function ContributorsFilters() {
  const [searchWord, setSearchWord] = useState<string>("")
  const { data: usersData, isLoading, error } = useListUsers()
  const { state, dispatch } = useProgramsSearch()
  const { users } = state.filters

  if (isLoading) return <Spinner />

  if (error || !usersData) return <p>{error?.response?.data.message}</p>

  function handleChangeOptions(search: string) {
    setSearchWord(search)
  }

  const filtered = usersData.filter((user) => {
    // Remove accents and lower case both users and search before comparison
    const fullName = `${user.firstName} ${user.lastName}`
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")

    const searchTermLower = searchWord
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
    return fullName.includes(searchTermLower)
  })

  const handleClickOutside = () => {
    setSearchWord("")
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="h-full text-sky-900">
              {t("contributors")}
              {users.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-green-800 bg-green-100 rounded-full">
                  {users.length}
                </div>
              )}
            </div>
          )}
          options={filtered}
          renderOption={(user, index) => (
            <div role="menu">
              <li
                onClick={() =>
                  state.filters.users?.find((userId) => userId === user.azureId)
                    ? dispatch({
                        type: "SET_FILTERS",
                        payload: {
                          ...state.filters,
                          users: state.filters.users?.filter(
                            (userId) => userId !== user.azureId,
                          ),
                          skip: 0,
                          page: 1,
                        },
                      })
                    : dispatch({
                        type: "SET_FILTERS",
                        payload: {
                          ...state.filters,
                          users: [...state.filters.users, user.azureId],
                          skip: 0,
                          page: 1,
                        },
                      })
                }
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id={`menu-item-${index}`}
              >
                <input
                  aria-label="Select user"
                  className="mr-2"
                  type="checkbox"
                  readOnly
                  checked={
                    !!state.filters.users?.find(
                      (userId) => userId === user.azureId,
                    )
                  }
                />
                {user.firstName} {user.lastName}
              </li>
            </div>
          )}
          keyExtractor={(user) => user.id}
          handleChangeOptions={(searchText) => {
            handleChangeOptions(searchText)
          }}
          onClickOutside={handleClickOutside}
        />
      </div>
    </div>
  )
}
