import ArrowLeft from "components/Icons/ArrowLeft"
import { LinkCustom } from "components/Link/Link"
import ContributorsTable from "features/contributors/ContributorsTable"
import { t } from "i18next"
import { useNavigate, useParams } from "react-router-dom"

export default function OperationContributors() {
  const navigate = useNavigate()
  const params = useParams()
  return (
    <div className="px-4 pb-72">
      <LinkCustom
        classNames="py-4"
        icon={ArrowLeft}
        iconSize={16}
        iconPosition="left"
        onClick={() => navigate(`/operation/${params.id}`)}
      >
        {`${t("steering")}`}
      </LinkCustom>
      <ContributorsTable />
    </div>
  )
}
