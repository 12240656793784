import { API } from "core/api/axios"
import {
  TDocumentsList,
  TGedDocument,
  TGedFile,
  TGEDTree,
  TGedUploadResponse,
} from "shared/types/ged.type"
import GED_API from "../axios"

async function getTreeByProjectId(
  projectId: number,
  pageView?: number,
  query?: string,
) {
  if (typeof projectId === "undefined") {
    return Promise.reject(new Error("invalid projectId"))
  }

  let url = `/documents?project=${projectId}`
  if (pageView !== undefined) {
    url += `&view=${pageView}`
  }
  if (query !== undefined) {
    url += `&search=${query}`
  }

  const response = await API.get(url)
  return response.data
}

async function postDocumentToGed(data: TGedFile): Promise<TGedUploadResponse> {
  const fd = new FormData()

  fd.append("storage", "DEVELOPPEMENT")
  fd.append("title", data.documentName)
  fd.append("documentType", data.documentType)

  if (data.registrationNumber) {
    fd.append("registrationNumber", data.registrationNumber)
  } else if (data.projectId) {
    fd.append("projectId", data.projectId)
  }

  if (data.chapter) {
    fd.append("chapter", data.chapter)
  }

  if (data.subChapter) {
    fd.append("subChapter", data.subChapter)
  }

  if (data.subfolder1) {
    fd.append("subfolder1", data.subfolder1)
  }

  if (data.subfolder2) {
    fd.append("subfolder2", data.subfolder2)
  }

  if (data.subfolder3) {
    fd.append("subfolder3", data.subfolder3)
  }

  if (data.workflowStatus) {
    fd.append("workflowStatus", data.workflowStatus)
  }

  if (data.documentStatus) {
    fd.append("documentStatus", data.documentStatus)
  }

  if (data.numStatus) {
    fd.append("numStatus", data.numStatus)
  }

  if (data.storedBy) {
    fd.append("storedBy", data.storedBy)
  }

  fd.append("file", data.file)

  const res = await GED_API.post("/document", fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  return res?.data
}

async function getGedDocumentsByProjectId(
  projectId: number,
): Promise<TGEDTree> {
  const res = await API.get(`documents?project=${projectId}`)
  return res.data
}

async function getDocumentsByStudyRequestIdAndProjectId(
  projectId: number,
  studyRequestType: number,
): Promise<TDocumentsList> {
  const res = await API.get(
    `/documents-by-category?project=${projectId}&studyRequestType=${studyRequestType}`,
  )
  return res.data
}

async function getDocumentsByCategoryAndProjectId(
  projectId: number,
  studyRequestType: number,
): Promise<TDocumentsList> {
  const res = await API.get(
    `/documents-by-category?project=${projectId}&category=${studyRequestType}`,
  )
  return res.data
}

async function getDocumentsByType(
  projectId: string,
  documentTypeLabel: string,
): Promise<TGedDocument[]> {
  const res = await API.get(
    `/documents-by-type?project=${projectId}&typeLabel=${documentTypeLabel}`,
  )
  return res.data
}

async function getDocumentsById(documentId: number): Promise<TGedDocument> {
  const res = await GED_API.get(`/documents/${documentId}`)
  return res.data
}

async function getTreeByRegistrationNumbers(
  registrationNumbers: string[],
  viewPage?: number,
  query?: string,
): Promise<TGEDTree> {
  if (typeof registrationNumbers === "undefined") {
    return Promise.reject(new Error("invalid registrationNumbers"))
  }

  let url = `/documents?registrationNumbers=${registrationNumbers}`
  if (viewPage !== undefined) {
    url += `&view=${viewPage}`
  }
  if (query !== undefined) {
    url += `&search=${query}`
  }

  const response = await API.get(url)
  return response.data
}

export {
  getDocumentsByCategoryAndProjectId,
  getDocumentsById,
  getDocumentsByStudyRequestIdAndProjectId,
  getDocumentsByType,
  getGedDocumentsByProjectId,
  getTreeByProjectId,
  getTreeByRegistrationNumbers,
  postDocumentToGed,
}
