import axios from "axios"

export const API = axios.create({
  baseURL: process.env.REACT_APP_JARVIS_URL,
  headers: {
    "Content-Type": "application/json",
  },
})

export const AANG_API = axios.create({
  baseURL: process.env.REACT_APP_AANG_URL,
  headers: {
    "Content-Type": "image/png",
  },
})
