import { agreementDetailQuery } from "core/query-hooks/useAgreements"
import IHOCLoader from "hoc/HocLoader"
import { useParams } from "react-router-dom"
import AgreementItem from "./AgreementItem"

export interface IAgreementShow {
  projectContext: boolean
}

export function AgreementShow({ ...props }: IAgreementShow) {
  const params = useParams()
  const { projectContext } = props
  const {
    data: agreementData,
    isLoading,
    isError,
    error,
  } = agreementDetailQuery(parseInt(params.agreementId!))

  return (
    <div>
      <IHOCLoader error={error} isLoading={isLoading} isError={isError}>
        <AgreementItem
          agreement={agreementData!}
          projectContext={projectContext}
        />
      </IHOCLoader>
    </div>
  )
}
