import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from "react"
import { TCostOfWork } from "shared/types/costOfWork.type"

interface ICostOfWorkProps {
  children: React.ReactNode
}

export type TStudyRequestCreateForm = TCostOfWork

const defaultActiveStep = 1

const studyRequest: TStudyRequestCreateForm = {
  feasibilityId: undefined,
  developersComment: undefined,
}

const CostOfWorkContext = createContext<{
  getStudyRequest: () => TStudyRequestCreateForm
  setActiveStep: Dispatch<SetStateAction<number>>
  setCurrentId: Dispatch<SetStateAction<number | undefined>>
  currentId: number | undefined
  activeStep: number
  studyRequestInfo: TStudyRequestCreateForm
  setStudyRequestInfo: Dispatch<SetStateAction<TStudyRequestCreateForm>>
}>({
  getStudyRequest: () => studyRequest,
  setActiveStep: () => {},
  setCurrentId: () => {},
  currentId: undefined,
  activeStep: defaultActiveStep,
  studyRequestInfo: studyRequest,
  setStudyRequestInfo: () => {},
})

function CostOfWorkProvider({ children }: ICostOfWorkProps) {
  const [studyRequestInfo, setStudyRequestInfo] = useState(studyRequest)
  const [currentId, setCurrentId] = useState<number>()
  const [activeStep, setActiveStep] = useState(defaultActiveStep)

  const getStudyRequest = () => {
    return studyRequestInfo
  }

  const contextValue = useMemo(
    () => ({
      getStudyRequest,
      setStudyRequestInfo,
      studyRequestInfo,
      setActiveStep,
      activeStep,
      setCurrentId,
      currentId,
    }),
    [
      getStudyRequest,
      setStudyRequestInfo,
      studyRequestInfo,
      setActiveStep,
      activeStep,
      setCurrentId,
      currentId,
    ],
  )

  return (
    <CostOfWorkContext.Provider value={contextValue}>
      {children}
    </CostOfWorkContext.Provider>
  )
}

export { CostOfWorkProvider, CostOfWorkContext }
