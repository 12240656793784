import { useMutation, useQuery } from "@tanstack/react-query"
import {
  addAction,
  deleteAction,
  getActionById,
  getActionsByProjectId,
  getUserActions,
  hasModificationRight,
  updateAction,
  validateAction,
} from "core/api/actions"
import {
  TAction,
  TActionList,
  TMutateAction,
  TValidateAction,
} from "shared/types/action.type"
import { CustomAxiosError } from "shared/types/axios.type"

function useGetActionsByProjectId(
  projectId?: number,
  query?: string,
  skip?: number,
  take?: number,
  order?: "asc" | "desc",
  sort?: string,
  done?: boolean,
) {
  return useQuery<TActionList, CustomAxiosError>({
    queryKey: [
      "getActionsByProjectId",
      projectId,
      query,
      skip,
      take,
      order,
      sort,
      done,
    ],
    queryFn: () =>
      getActionsByProjectId(projectId, query, skip, take, order, sort, done),
    ...{
      enabled: Boolean({
        query,
        projectId,
        skip,
        take,
        order,
        sort,
        done,
      }),
      throwOnError: (error) =>
        !!(error.response?.status && error.response?.status >= 500),
    },
  })
}

function useGetActionById(actionId?: number) {
  return useQuery<TAction, CustomAxiosError>({
    queryKey: ["getActionByActionId", actionId],
    queryFn: () => getActionById(actionId),
  })
}

function useAddActionToProject() {
  return useMutation<TAction, CustomAxiosError, TMutateAction>({
    mutationFn: (action: TMutateAction) => addAction(action),
  })
}

function useUpdateActionToProjectByActionId(id: number) {
  return useMutation<TAction, CustomAxiosError, TMutateAction>({
    mutationFn: (action: TMutateAction) => updateAction(id, action),
  })
}

function useDeleteAction(id: number) {
  return useMutation<TAction, CustomAxiosError, number>({
    mutationFn: () => deleteAction(id),
  })
}

function useValidateAction() {
  return useMutation<TAction, CustomAxiosError, TValidateAction>({
    mutationFn: (action: TValidateAction) => validateAction(action),
  })
}

function useGetUserActions(azureId: string, date: string) {
  return useQuery<
    { data: TAction[]; metadata: { maxTotalHits: number } },
    CustomAxiosError
  >({
    queryKey: ["getUserActions", azureId, date],
    queryFn: () => getUserActions(azureId, date),
  })
}

function useActionModificationRight(actionId: number) {
  return useQuery<boolean, CustomAxiosError>({
    queryKey: ["getActionModificationRight", actionId],
    queryFn: () => hasModificationRight(actionId),
  })
}

export {
  useActionModificationRight,
  useAddActionToProject,
  useDeleteAction,
  useGetActionById,
  useGetActionsByProjectId,
  useGetUserActions,
  useUpdateActionToProjectByActionId,
  useValidateAction,
}
