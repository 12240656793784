import { useQuery, useQueryClient } from "@tanstack/react-query"
import ButtonIcon from "components/Button/ButtonIcon"
import { t } from "i18next"
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { MdAdd, MdClose } from "react-icons/md"
import { useParams } from "react-router-dom"
import Select, {
  MultiValueGenericProps,
  OptionProps,
  components,
} from "react-select"
import { toast } from "react-toastify"
import { TUser, TUserContributor } from "shared/types/user.type"
import { useOnClickOutside } from "usehooks-ts"
import Avatar from "../../components/Avatar/Avatar"
import { getContributorsByProjectId } from "../../core/api/contributors"
import { useCreateContributor } from "../../core/query-hooks/useContributors"
import { useGetUsersByOccupationId } from "../../core/query-hooks/useUsers"
import { TContributorPreview } from "../../shared/types/contributor.type"
import ContributorItem from "./ContributorItem"

interface IContributorsCellProps {
  readonly name: string
  readonly id: number
  readonly isLast: boolean
}

type ContributorOption = TUserContributor & {
  readonly value: string
  readonly label: string
}

interface IAddContributorModalProps {
  children: ReactElement
  usersData: TUserContributor[]
  setShowSelect: (value: React.SetStateAction<boolean>) => void
}

function Option(props: OptionProps) {
  const {
    data,
    children: child,
    className = "px-2 Border__Bottom_LightGrey py-2 AddContributor__Option",
    cx,
    innerRef,
    innerProps,
  } = props

  return (
    <div
      ref={innerRef}
      className={cx(
        {
          option: true,
        },
        className,
      )}
      {...innerProps}
    >
      <div className="flex items-center">
        <div className="AddContributor__Option_Avatar_Container mr-1 flex items-center justify-center">
          <Avatar size="small" user={data as TUser} />
        </div>
        {child}
      </div>
    </div>
  )
}

function MultiValueLabel(props: MultiValueGenericProps<ContributorOption>) {
  const { data, children: child } = props
  return (
    <components.MultiValueLabel {...props}>
      <div className="flex items-center">
        <div className="AddContributor__Option_Avatar_Container mr-1 flex items-center justify-center">
          <Avatar size="small" user={data} />
        </div>
        {child}
      </div>
    </components.MultiValueLabel>
  )
}

function AddContributorModal({
  children,
  usersData,
  setShowSelect,
}: IAddContributorModalProps) {
  const queryClient = useQueryClient()
  const params = useParams()
  const createContributor = useCreateContributor()
  const [selectedContributors, setSelectedContributor] = useState<
    readonly ContributorOption[] | []
  >([])
  const [filteredOptions, setFilteredOptions] = useState<
    ContributorOption[] | []
  >()
  const onChange = useCallback((options: readonly ContributorOption[]) => {
    setSelectedContributor(options)
    createContributor.mutate(
      {
        userAzureId: options[0].azureId,
        projectId: parseInt(params.id!),
        occupationId: options[0].occupationId,
      },
      {
        onSuccess: () => {
          setShowSelect(false)
          toast.success(t("contributor-added"))
          queryClient.invalidateQueries({
            queryKey: ["useGetContributorsByProjectId", parseInt(params.id!)],
          })
        },
        onError: () => {
          setShowSelect(false)
          setSelectedContributor([])
          toast.error(t("contributor-already-added"))
        },
      },
    )
  }, [])

  useEffect(() => {
    const filteredOption = (): ContributorOption[] => {
      if (!usersData) return []
      return usersData.map((user) => ({
        ...user,
        value: user.azureId,
        label: `${user.firstName} ${user.lastName}`,
      }))
    }
    setFilteredOptions(filteredOption())
  }, [usersData])

  return (
    <div className="AddContributor__Modal pb-0">
      <div className="flex justify-between items-center">
        <div className="p-2">{`${t("add-contributor")}`}</div>
        {children}
      </div>
      <Select
        styles={{
          control: (styles) => ({
            ...styles,
            marginLeft: 8,
            marginRight: 8,
            border: "1px solid #F0F0F0",
          }),
          indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "none",
          }),
          multiValue: (styles) => ({
            ...styles,
            backgroundColor: "white",
            border: "1px solid #F0F0F0",
            borderRadius: 27,
          }),
          multiValueLabel: (styles) => ({
            ...styles,
          }),
          menu: (styles) => ({
            ...styles,
            position: "relative",
            boxShadow: "none",
            marginBottom: 0,
          }),
        }}
        isMulti
        isOptionDisabled={() => selectedContributors.length > 0}
        menuIsOpen={selectedContributors.length < 1}
        onChange={onChange}
        components={{
          // @ts-ignore We're failing to provide a required index prop to SortableElement
          Option,
          MultiValueLabel,
        }}
        placeholder="Rechercher..."
        options={filteredOptions}
        noOptionsMessage={() => "Aucun résultat"}
      />
    </div>
  )
}

export default function ContributorsRow({
  name,
  id,
  isLast,
}: IContributorsCellProps) {
  const params = useParams()

  const [filteredContributors, setFilteredContributors] = useState<
    [] | TContributorPreview[]
  >()
  const [potentialCleanContributors, setPotentialCleanContributors] = useState<
    [] | TUserContributor[]
  >()
  const { data: potentialContributors } = useGetUsersByOccupationId(id)
  const [showSelect, setShowSelect] = useState(false)
  const ref = useRef(null)
  const handleClickOutside = () => {
    setShowSelect(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  const { data: contributorsData } = useQuery({
    queryKey: ["useGetContributorsByProjectId", parseInt(params.id!)],
    queryFn: () => getContributorsByProjectId(parseInt(params.id!)),
  })

  useEffect(() => {
    if (!contributorsData) return undefined
    return setFilteredContributors(
      contributorsData.filter((contributor) => contributor.occupationId === id),
    )
  }, [contributorsData])

  useEffect(() => {
    if (!potentialContributors) return undefined
    if (!contributorsData) return undefined

    const updatedPotentialCleanContributors = potentialContributors
      // filter pour enlever les contributors ajoutés des options
      .filter(
        (contributor) =>
          !contributorsData.some(
            (dataContributor) =>
              dataContributor.userAzureId === contributor.azureId,
          ),
      )
      .map((contributor) => ({
        ...contributor,
        occupationId: id,
      }))

    return setPotentialCleanContributors(updatedPotentialCleanContributors)
  }, [potentialContributors, contributorsData])

  const onRemoveContributor = useCallback((contributor: TUser) => {
    setFilteredContributors((previousContributors) =>
      previousContributors?.filter(
        (c) => c.userAzureId !== contributor.azureId,
      ),
    )
  }, [])

  return (
    <tr>
      <td className="Contributor__Table_Body_Row font-medium">{name}</td>
      <td className="Contributor__Table_Body_Row">
        <div className="flex" ref={ref}>
          {showSelect && (
            <AddContributorModal
              setShowSelect={setShowSelect}
              usersData={potentialCleanContributors!}
            >
              <button
                onClick={() => setShowSelect(false)}
                type="button"
                className="px-2"
              >
                <MdClose size={20} />
              </button>
            </AddContributorModal>
          )}
          <div className="flex items-center justify-start mr-1">
            {potentialCleanContributors &&
            potentialCleanContributors.length > 0 ? (
              <ButtonIcon type="button" onClick={() => setShowSelect(true)}>
                <MdAdd size={22} cursor="pointer" className="text-cyan-800" />
              </ButtonIcon>
            ) : null}
          </div>
          <div className="flex items-center">
            {filteredContributors?.map((c) => (
              <ContributorItem
                onRemoveContributor={(contributor) =>
                  onRemoveContributor(contributor)
                }
                contributorId={c.id}
                key={`${c.userAzureId}-contributor`}
                userAzureId={c.userAzureId}
                isLast={isLast}
              />
            ))}
          </div>
        </div>
      </td>
    </tr>
  )
}
