import { IIconType } from "shared/types/global.type"

function Close({ width = 16, height = 16, color = "#00304D" }: IIconType) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
      <path
        fill={color}
        d="M12.05 13.5 7.1 18.45q-.325.325-.762.338-.438.012-.788-.338-.35-.35-.35-.762 0-.413.35-.763l4.975-4.975-5-4.975q-.325-.325-.337-.738-.013-.412.337-.762.35-.35.762-.35.413 0 .763.35L12 10.4l4.9-4.9q.325-.325.763-.338.437-.012.787.338.35.35.35.787 0 .438-.35.788l-4.925 4.95 4.95 4.95q.3.3.313.737.012.438-.313.763t-.737.325q-.413 0-.738-.325Z"
      />
    </svg>
  )
}

export default Close
