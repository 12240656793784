import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import "./Sidebar.scss"
import SidebarItemCreateProject from "./SidebarItemCreateProject"
import SidebarItemDashboards from "./SidebarItemDashboards"
import SidebarItemFaq from "./SidebarItemFaq"
import SidebarItemHome from "./SidebarItemHome"
import SidebarItemPinnedProjectsAndOperations from "./SidebarItemPinnedProjectsAndOperations"
import SidebarItemReleaseNotes from "./SidebarItemReleaseNotes"
import SidebarItemSearch from "./SidebarItemSearch"
import SidebarItemSimplifiedPrograms from "./SidebarItemSimplifiedPrograms"

interface ISidebar {
  toggleSidebar: () => void
}

export default function Sidebar({ toggleSidebar }: ISidebar) {
  return (
    <div className="Sidebar">
      <ul className="h-32">
        <SidebarItemHome closeSidebar={toggleSidebar} />
        <SidebarItemSearch closeSidebar={toggleSidebar} />
        <PermissionsGuard requiredRoles={["create:project"]}>
          <SidebarItemCreateProject closeSidebar={toggleSidebar} />
        </PermissionsGuard>
        <SidebarItemPinnedProjectsAndOperations closeSidebar={toggleSidebar} />
        <SidebarItemDashboards closeSidebar={toggleSidebar} />
        <SidebarItemSimplifiedPrograms closeSidebar={toggleSidebar} />
        <SidebarItemFaq closeSidebar={toggleSidebar} />
        <SidebarItemReleaseNotes closeSidebar={toggleSidebar} />
      </ul>
    </div>
  )
}
