import classnames from "classnames"
import { useRef } from "react"
import ReactDOM from "react-dom"
import { MdClose } from "react-icons/md"
import { useOnClickOutside } from "usehooks-ts"
import "./Modal.scss"

export interface ModalProps {
  isShowing: boolean
  closeModal: () => void
  title: string
  description?: string
  children: React.ReactNode
  displayCloseIcon?: boolean
  useOnClickOutsideAlerter?: boolean
  width?: "medium" | "large"
}

export function Modal({
  isShowing,
  closeModal,
  title,
  children,
  description,
  displayCloseIcon = false,
  useOnClickOutsideAlerter = true,
  width = "medium",
}: ModalProps) {
  const modalRef = useRef(null)

  useOnClickOutsideAlerter && useOnClickOutside(modalRef, closeModal)

  return isShowing
    ? ReactDOM.createPortal(
        <div className="Modal__Overlay">
          <div className="Modal__Wrapper">
            <div
              ref={modalRef}
              className={classnames(
                {
                  Modal__Large__Width: width === "large",
                  Modal__Medium__Width: width === "medium",
                },
                "Modal__Container",
              )}
            >
              <div className="flex justify-between">
                <h4 className="Modal__Title Color__Primary">{title}</h4>
                {displayCloseIcon && (
                  <button
                    type="button"
                    className="absolute top-4 right-4 Modal__Close__Button flex items-center justify-center"
                  >
                    <MdClose
                      color="#3C5D72"
                      size={22}
                      onClick={() => closeModal()}
                    />
                  </button>
                )}
              </div>
              <p className="Modal__Description opacity-75">{description}</p>
              <div className="Modal__Body">{children}</div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null
}
