import Box from "components/Box/Box"
import useGetCommittees, {
  useListCommitteeByOperationId,
} from "core/query-hooks/useCommittes"
import { format } from "date-fns"
import { t } from "i18next"
import { sortBy } from "lodash"
import { MdDateRange } from "react-icons/md"
import { NavLink } from "react-router-dom"
import {
  COMMITTEE_ENGAGEMENT,
  COMMITTEE_ENGAGEMENT_MOD,
  COMMITTEE_LANCEMENT_COMMERCIAL,
} from "shared/resources/committees-types-resources"

export interface INextCommitteeProps {
  operationId: number
}

export function NextCommittee(props: INextCommitteeProps) {
  const { operationId } = props
  const { data: committeeTypes } = useGetCommittees()
  const { data: committees } = useListCommitteeByOperationId(operationId)

  const mandatoryCommitteesTypes = committeeTypes?.filter(
    (committee) => committee.mandatory,
  )

  const engagementModCommittees = committees?.filter(
    (committee) => committee.type.id === COMMITTEE_ENGAGEMENT_MOD.id,
  )
  const sortedEngagementModCommittees = sortBy(engagementModCommittees, "id")
  const lastEngagementModCommittee =
    sortedEngagementModCommittees[sortedEngagementModCommittees.length - 1]

  let nextCommittee: { label: string; date: Date | null } | null | undefined =
    null

  let noRemainingCommittees = false

  if (committees && committees.length > 0) {
    const sortedCommittees = committees
      .slice()
      .sort((a, b) => a.type.id - b.type.id)

    const lastIndex = sortedCommittees.length - 1

    // Dernier comité avec isDefinitive à true
    const lastDefinitiveCommittee = sortedCommittees
      .slice()
      .reverse()
      .find((committee) => committee.isDefinitive)

    if (lastDefinitiveCommittee) {
      // Si le dernier comité validé est le dernier comité possible
      if (lastDefinitiveCommittee.type.id === COMMITTEE_LANCEMENT_COMMERCIAL.id)
        noRemainingCommittees = true
      // Si le dernier comité validé est un comité d'engagement modificatif
      // Et que le dernier comité d'engagement modificatif a une date mais n'est pas validé
      if (
        lastDefinitiveCommittee.type.id === COMMITTEE_ENGAGEMENT_MOD.id &&
        !lastEngagementModCommittee.isDefinitive
      ) {
        nextCommittee = {
          label: `${lastEngagementModCommittee.type.label} ${sortedEngagementModCommittees.length}`,
          date: lastEngagementModCommittee.date,
        }
      } else {
        const indexLastDefinitive = sortedCommittees.findIndex(
          (committee) => committee.id === lastDefinitiveCommittee.id,
        )

        // Premier comité avec une date et isDefinitive à false
        const nextCommitteeWithDate = sortedCommittees
          .slice(indexLastDefinitive + 1, lastIndex + 1)
          .find(
            (committee) => committee.date !== null && !committee.isDefinitive,
          )

        if (nextCommitteeWithDate) {
          nextCommittee = {
            label: nextCommitteeWithDate.type.label,
            date: nextCommitteeWithDate.date,
          }
        } else {
          // Si aucun comité avec date et isDefinitive à false, prendre le premier comité obligatoire suivant le dernier comité validé
          let incomingCommittee

          for (
            let typeToFind = lastDefinitiveCommittee.type.id + 1;
            typeToFind <= COMMITTEE_LANCEMENT_COMMERCIAL.id;
            typeToFind += 1
          ) {
            incomingCommittee = mandatoryCommitteesTypes?.find(
              (committee) => committee.id === typeToFind,
            )

            if (incomingCommittee) {
              break // Sortir de la boucle dès que le comité est trouvé
            }
          }

          nextCommittee = incomingCommittee?.label
            ? { label: incomingCommittee.label, date: null }
            : null
        }
      }
    } else {
      // Si aucun comité avec isDefinitive à true, prendre le comité avec le plus petit type.id qui a une date
      const firstCommitteeWithDate = sortedCommittees.find(
        (committee) => committee.date !== null,
      )

      if (firstCommitteeWithDate) {
        nextCommittee = {
          label: firstCommitteeWithDate.type.label,
          date: firstCommitteeWithDate.date,
        }
      }
    }
  }

  if (nextCommittee) {
    return (
      <Box classNames="p-0 h-full">
        <NavLink
          className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between h-10"
          to="committees"
          type="button"
        >
          <div className="flex items-center text-cyan-900 mr-1">
            <MdDateRange size={22} />
            <h3 className="text-xs uppercase font-medium pl-1">
              {t("next-committee")}
            </h3>
          </div>
        </NavLink>
        <div className="pb-4 px-4 pt-12 flex items-center justify-between text-sm Color__Primary">
          <div>{nextCommittee.label}</div>
          <div>
            {nextCommittee.date
              ? format(new Date(nextCommittee.date), "dd/MM/yyyy")
              : "-"}
          </div>
        </div>
      </Box>
    )
  }
  const comiteEngagement =
    committeeTypes &&
    committeeTypes?.find(
      (committee) => committee.id === COMMITTEE_ENGAGEMENT.id,
    )
  return (
    <Box classNames="p-0 h-full">
      <NavLink
        className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between h-10"
        to="committees"
        type="button"
      >
        <div className="flex items-center text-cyan-900 mr-1">
          <MdDateRange size={22} />
          <h3 className="text-xs uppercase font-medium pl-1">
            {t("next-committee")}
          </h3>
        </div>
      </NavLink>
      <div className="pb-4 px-4 pt-12 flex items-center justify-between text-sm Color__Primary">
        {noRemainingCommittees ? (
          <div>{t("no-incoming-committee")}</div>
        ) : (
          <div>
            {comiteEngagement
              ? comiteEngagement.label
              : t("no-incoming-committee")}
          </div>
        )}
        <p> - </p>
      </div>
    </Box>
  )
}
