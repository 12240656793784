import Box from "components/Box/Box"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useGetTranchesCommerciales } from "core/spo/query-hooks/useTranches"
import EtudeResaStockLotissementByTranche from "features/spo/EtudeResaStockLotissementByTranche"
import EtudeResaStockResidentielByTranche from "features/spo/EtudeResaStockResidentielByTranche"
import { LotsCommercializationByTranche } from "features/spo/LotsCommercializationByTranche"
import { RhythmsSynthesisByTranche } from "features/spo/RhythmsSynthesisByTranche"
import { TranchesCommercialesOverview } from "features/spo/TranchesCommercialesOverview"
import { t } from "i18next"
import { LuBarChart4 } from "react-icons/lu"
import { RiPieChart2Fill } from "react-icons/ri"
import { Link } from "react-router-dom"
import {
  HOUSING_ESTATE_TYPE,
  RESIDENTIAL_TYPE,
  TERTIARY_TYPE,
} from "shared/resources/operation.resources"
import { TChildOperation } from "shared/types/operation.type"
import { LotStatusDiagram } from "./commercialization/LotStatusDiagram"
import { CommunicationType } from "./communication/CommunicationType"
import { PreReservationContract } from "./prc/PreReservationContract"

interface TOperationChildItemCommercializationProps {
  operationChild: TChildOperation
}

function renderEtudeResaStockByTranche(
  operationChild: TChildOperation,
  trancheCommercialeId: string,
) {
  switch (operationChild.type.id) {
    case HOUSING_ESTATE_TYPE.id:
      return (
        <div className="Light_Table__Wrapper overflow-hidden">
          <EtudeResaStockLotissementByTranche
            registrationNumber={operationChild.registrationNumber}
            trancheCommercialeId={trancheCommercialeId}
          />
        </div>
      )

    case RESIDENTIAL_TYPE.id:
    case TERTIARY_TYPE.id:
      return (
        <div className="Light_Table__Wrapper overflow-hidden">
          <EtudeResaStockResidentielByTranche
            registrationNumber={operationChild.registrationNumber}
            trancheCommercialeId={trancheCommercialeId}
          />
        </div>
      )

    default:
      return null
  }
}

export default function OperationChildItemCommercialization(
  props: TOperationChildItemCommercializationProps,
) {
  const { operationChild } = props
  const { data } = useGetTranchesCommerciales(operationChild.registrationNumber)

  return (
    <div>
      <div className="lg:grid lg:grid-cols-2 lg:grid-flow-col grid-flow-row-dense pt-4">
        <div className="grid-rows-1 grid-cols-1 mr-2 col-span-2 mb-4">
          <div className="flex-row justify-between items-center">
            <TranchesCommercialesOverview
              registrationId={operationChild.registrationNumber}
            />
          </div>
        </div>

        <div className="col-span-1">
          <div className="pt-4 lg:pt-0 mb-4">
            <PreReservationContract childOperationId={operationChild.id} />
            <CommunicationType
              childOperationId={operationChild.id}
              communicationType={operationChild?.communicationType?.id}
            />

            <Box classNames="p-0 mt-3">
              <div className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between">
                <div className="flex items-center text-cyan-900 mr-1">
                  <RiPieChart2Fill size={22} className="mr-1" />
                  <h3 className="text-xs uppercase font-medium pl-1">
                    {t("commercialization")}
                  </h3>
                </div>
                <div className="text-primary-dark">
                  <TooltipWithHover
                    position="left"
                    message={t("spo-informations")}
                  >
                    <Link
                      className="text-cyan-900 underline uppercase text-xs"
                      to={`${process.env.REACT_APP_SPO_URL}#/commercial/commercial?operation=${operationChild.registrationNumber}`}
                      target="_blank"
                    >
                      SPO
                    </Link>
                  </TooltipWithHover>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="grow-0">
                  <LotStatusDiagram
                    registrationId={operationChild.registrationNumber}
                  />
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>

      {data && (
        <Tabs>
          {data.tranchesCommerciales.map((trancheCo) => {
            return trancheCo.id !== "TOTAL" ? (
              <TabContent title={trancheCo.label} key={trancheCo.id}>
                <div className="mt-2">
                  <RhythmsSynthesisByTranche
                    registrationId={operationChild.registrationNumber}
                    trancheCommercialeId={trancheCo.id}
                  />

                  <Box classNames="p-0 mt-4">
                    <div className="flex items-center px-3.5 py-2 justify-between">
                      <div className="flex items-center text-cyan-900 mr-1">
                        <LuBarChart4 size={22} className="mr-1" />
                        <h3 className="text-xs uppercase font-medium pl-1">
                          {t("commercialization-studies-and-stock-title")}
                        </h3>
                      </div>
                      <div className="text-primary-dark">
                        <TooltipWithHover
                          position="left"
                          message={t("spo-informations")}
                        >
                          <Link
                            className="text-cyan-900 underline uppercase text-xs"
                            to={`${process.env.REACT_APP_SPO_URL}#/commercial/commercial?operation=${operationChild.registrationNumber}`}
                            target="_blank"
                          >
                            SPO
                          </Link>
                        </TooltipWithHover>
                      </div>
                    </div>
                    {renderEtudeResaStockByTranche(
                      operationChild,
                      trancheCo.id,
                    )}
                  </Box>

                  <LotsCommercializationByTranche
                    trancheCommercialeId={trancheCo.id}
                    registrationId={operationChild.registrationNumber}
                  />
                </div>
              </TabContent>
            ) : null
          })}
        </Tabs>
      )}
    </div>
  )
}
