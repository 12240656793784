import { useQueryClient } from "@tanstack/react-query"
import GedFile from "components/Ged/GedFile"
import { useUpdateStudyRequestDocuments } from "core/query-hooks/useStudyRequests"
import { TCoWCreateDocumentsStepForm } from "features/studies/costOfWork/StepDocumentsCoW"
import { TStudyRequestCreateDocumentsStepForm } from "features/studies/feasibilities/StepDocumentsFeasibility"
import { Control, Controller } from "react-hook-form"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

export type TStudyRequestDocumentsStep =
  | TStudyRequestCreateDocumentsStepForm
  | TCoWCreateDocumentsStepForm

export interface IFeasibilityFileFormFieldProps {
  category: string
  categoryId: number
  index: number
  control: Control<TStudyRequestDocumentsStep, "id">
  documentType: {
    id: number
    label: string
  }
  defaultChecked?: boolean
}

export function FeasibilityFileFormField(
  props: IFeasibilityFileFormFieldProps,
) {
  const { category, categoryId, index, control, documentType, defaultChecked } =
    props

  const { studyRequestId } = useParams()
  const queryClient = useQueryClient()
  const updateDocuments = useUpdateStudyRequestDocuments(+studyRequestId!)

  const handleCheckboxChange = (documentId: number) => {
    updateDocuments.mutate(
      {
        gedDocument: documentId,
        categoryId,
      },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: ["listStudyRequestDocuments", +studyRequestId!],
          })
        },
        onError(error) {
          toast.error(error.response?.data.error.message)
        },
      },
    )
  }

  return (
    <div className="mb-2">
      <Controller
        name={`list.${index}`}
        control={control}
        render={() => (
          <div className="flex">
            <label
              className="ml-2 Color__Primary cursor-pointer inline-flex"
              htmlFor={documentType.id.toString()}
            >
              <input
                name={category}
                className="cursor-pointer mr-1"
                onChange={() => handleCheckboxChange(documentType.id)}
                type="checkbox"
                checked={defaultChecked}
                key={documentType.id}
              />
              <GedFile id={documentType.id} title={documentType.label} />
            </label>
          </div>
        )}
      />
    </div>
  )
}
