import classNames from "classnames"
import { RiArrowRightSLine } from "react-icons/ri"
import { Link, useLocation } from "react-router-dom"
import "./Stepper.scss"

export default function Stepper({
  steps,
}: {
  steps: { id: number; name: () => string; route: string }[]
}) {
  const location = useLocation()
  return (
    <div>
      <ul className="stepper w-fit rounded-full p-4 flex items-center justify-center">
        {steps.map((step) => (
          <li
            className={classNames("flex items-center justify-start", {
              active: location.pathname === step.route,
              "mr-3": step.id !== steps.length,
            })}
            key={step.id}
          >
            <span
              className={classNames(
                "h-8 w-8 rounded-full flex items-center justify-center text-white step-number",
              )}
            >
              {step.id}
            </span>
            <Link
              to={step.route}
              className="text-white step-button ml-2"
              type="button"
            >
              {step.name()}
            </Link>

            {step.id !== steps.length && (
              <RiArrowRightSLine
                color="#A4A9AC"
                fontSize={24}
                className="ml-3"
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
