import DateFormat from "components/Date/DateFormat"
import Spinner from "components/Spinner/Spinner"
import { useGetDatesMontage } from "core/spo/query-hooks/useDates"
import { t } from "i18next"
import { Link } from "react-router-dom"
import { TChildOperation } from "shared/types/operation.type"
import { TDateChild } from "shared/types/spo/datesPlanning.type"

export interface IChildDatesTableProps {
  childOperation: TChildOperation
}

export function ChildDatesTable(props: IChildDatesTableProps) {
  const { childOperation } = props
  const {
    data: dates,
    isLoading,
    error,
  } = useGetDatesMontage(childOperation.registrationNumber)

  if (isLoading)
    return (
      <tr>
        <td align="center" colSpan={8}>
          <Spinner />
        </td>
      </tr>
    )

  if (error)
    return (
      <tr>
        <td align="center" colSpan={8}>
          <p className="text-sm text-red-500">
            {`${t("error-loading-dates-planning")}`}
          </p>
        </td>
      </tr>
    )

  function displayDate(date: TDateChild) {
    if (date.actualDate) {
      return <DateFormat date={new Date(date.actualDate)} isDefinitive />
    }
    if (date.projectedDate) {
      return (
        <DateFormat date={new Date(date.projectedDate)} isDefinitive={false} />
      )
    }
    return "-"
  }

  function displayLabel(label: string) {
    if (label === "PCPA") return "Permis"
    return label.replace("MOD", "Mod ")
  }

  return (
    <>
      {dates?.categories.map((date) => {
        const depositIsNull =
          !date.deposit?.actualDate && !date.deposit?.projectedDate
        const obtainmentIsNull =
          !date.obtainment?.actualDate && !date.obtainment?.projectedDate
        const purgeIsNull =
          !date.purge?.actualDate && !date.purge?.projectedDate
        const display1IsNull =
          !date.display1?.actualDate && !date.display1?.projectedDate

        if (
          (date.label === "PCPA" &&
            (!depositIsNull ||
              !obtainmentIsNull ||
              !purgeIsNull ||
              !display1IsNull)) ||
          (["MOD1", "MOD2", "MOD3", "MOD4", "MOD5"].includes(date.label) &&
            (!depositIsNull || !obtainmentIsNull || !purgeIsNull))
        ) {
          return (
            <tr key={`${date.label}-${childOperation.registrationNumber}`}>
              <td>
                {date.label === "PCPA" ? (
                  <Link
                    className="text-sky-700 font-semibold underline"
                    to={`${process.env.REACT_APP_SPO_URL}#/planning/gantt?operation=${childOperation.registrationNumber}`}
                    target="_blank"
                  >
                    {childOperation.registrationNumber}
                  </Link>
                ) : (
                  ""
                )}
              </td>
              <td className="px-1 py-2">{displayLabel(date.label)}</td>
              <td className="px-1 py-2">{displayDate(date?.deposit)}</td>
              <td className="px-1 py-2">{displayDate(date?.obtainment)}</td>
              <td className="px-1 py-2">{displayDate(date?.purge)}</td>
              <td className="px-1 py-2">{displayDate(date?.display1)}</td>
              <td className="px-1 py-2">
                {date.label === "PCPA" ? displayDate(date?.appeal) : ""}
              </td>
              <td className="px-1 py-2">
                {date.label === "PCPA" ? displayDate(date?.lifting) : ""}
              </td>
            </tr>
          )
        }
        return null
      })}
    </>
  )
}
