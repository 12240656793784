import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import { SelectWithOnChange } from "components/SelectWithOnChange/SelectWithOnChange"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useListCommunicationTypes,
  useUpdateChildOperation,
} from "core/query-hooks/useOperations"
import { t } from "i18next"
import { VscMegaphone } from "react-icons/vsc"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

export interface ICommunicationTypeProps {
  communicationType: number | null
  childOperationId: number
}

export function CommunicationType(props: ICommunicationTypeProps) {
  const { communicationType, childOperationId } = props
  const { data: communicationTypesData } = useListCommunicationTypes()
  const updateChildOperation = useUpdateChildOperation(childOperationId)
  const { id } = useParams()
  const queryClient = useQueryClient()

  const communicationTypes = communicationTypesData?.map((comType) => ({
    ...communicationTypesData,
    label: comType.label,
    value: comType.id,
  }))

  const handleUpdateChildOperation = async (data: string | null) => {
    let communicationTypeId: number | null = Number(data)
    if (data === null) communicationTypeId = null

    updateChildOperation.mutate(
      {
        communicationTypeId,
      },
      {
        onSuccess: () => {
          toast.success(`${t("commercialization-type-update-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["listChildOperations", +id!],
          })
        },
        onError: (err) =>
          toast.error(
            `${t("commercialization-type-update-error")}, ${
              err.response?.data.error.message
            }`,
          ),
      },
    )
  }

  return (
    <Box classNames="p-0">
      <div className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between">
        <div className="flex items-center text-cyan-900 mr-1">
          <VscMegaphone size={22} />
          <h3 className="text-xs uppercase font-medium pl-1">
            {t("commercialization-type")}
          </h3>
        </div>
      </div>

      <PermissionsGuard requiredRoles={["update:child-operation"]}>
        <SelectWithOnChange
          options={communicationTypes}
          onChange={handleUpdateChildOperation}
          defaultValue={communicationType}
          hasEmptyOption
          className="p-2"
        />
      </PermissionsGuard>
      <PermissionsGuard
        excludedRoles={["update:child-operation"]}
        requiredRoles={["read:child-operation.communication-type"]}
      >
        <div className="mx-2 my-2 flex justify-center">
          <p className="text-sm Color__Primary">
            {communicationType
              ? communicationTypes?.find((ct) => ct.value === communicationType)
                  ?.label
              : t("empty-commercialization-type")}
          </p>
        </div>
      </PermissionsGuard>
    </Box>
  )
}
