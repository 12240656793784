import DateFormat from "components/Date/DateFormat"
import { nanoid } from "nanoid"
import {
  ActualProjectedDate,
  TLotissementsProductionDate,
} from "shared/types/spo/production.type"

export interface ITechnicalProductionHousingDatesTableRowProps {
  operationHousingDate: TLotissementsProductionDate
}

function displayDate(date: ActualProjectedDate) {
  if (date.actualDate) {
    return <DateFormat date={new Date(date.actualDate)} isDefinitive />
  }
  if (date.projectedDate) {
    return <DateFormat date={new Date(date.projectedDate)} />
  }
  return "-"
}

export function TechnicalProductionHousingDatesTableRow(
  props: ITechnicalProductionHousingDatesTableRowProps,
) {
  const { operationHousingDate } = props

  return (
    <tr key={nanoid()}>
      <td className="Table__Bottom_Border">
        {displayDate(operationHousingDate.constructionLaunch)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(operationHousingDate.daact1Signed)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(operationHousingDate.housingEstateCertificate)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(operationHousingDate.delayedConstructionProcedure)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(operationHousingDate.definitiveStreetsCompleted)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(operationHousingDate.cityRetrocession)}
      </td>
    </tr>
  )
}
