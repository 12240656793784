import { t } from "i18next"
import { TLabelStringValue } from "shared/types/global.type"
import PRODUCT_TYPES from "../resources/products.resources"

export interface ISelectedProductsFiltersProps {
  selectedProducts: string[] | []
  onProductsChange: (selectedProduct: string[] | []) => void
}

function renderProduct(productValue: string, products: TLabelStringValue[]) {
  const selectedProducts = products?.find(
    (product) => product.value === productValue,
  )
  return selectedProducts?.label
}

export default function SelectedProductsFilters(
  props: ISelectedProductsFiltersProps,
) {
  const { selectedProducts, onProductsChange } = props
  const productsOptions = PRODUCT_TYPES

  const handleProductRemove = (productValueToRemove: string) => {
    const updatedProductsList = selectedProducts.filter(
      (productValue) => productValue !== productValueToRemove,
    )
    onProductsChange(updatedProductsList)
  }

  return (
    <div>
      {selectedProducts.map((productValue) => (
        <span
          id="badge-dismiss-default"
          className="inline-flex items-center px-2 py-1 mr-2 my-0.5 text-sm font-medium text-purple-800 bg-purple-100 rounded"
          key={productValue}
        >
          {renderProduct(productValue, productsOptions)}
          <button
            onClick={() => handleProductRemove(productValue)}
            type="button"
            className="inline-flex items-center p-0.5 ml-2 text-sm text-purple-400 bg-transparent rounded-sm hover:bg-purlple-200 hover:text-purple-900"
            data-dismiss-target="#badge-dismiss-default"
            aria-label="Remove"
          >
            <svg
              aria-hidden="true"
              className="w-3.5 h-3.5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">{t("delete")}</span>
          </button>
        </span>
      ))}
    </div>
  )
}
