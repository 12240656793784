import Box from "components/Box/Box"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useListChildOperations } from "core/query-hooks/useOperations"
import { t } from "i18next"
import { RiCompasses2Line } from "react-icons/ri"
import { Fragment } from "react/jsx-runtime"
import { ChildDatesTable } from "./ChildDatesTable"

export interface IDatesMontageProps {
  operationId: number
}

export function DatesMontage(props: IDatesMontageProps) {
  const { operationId } = props
  const { data: operationsChildren } = useListChildOperations(operationId)

  return (
    <Box classNames="pt-0 px-0 pb-1">
      <div className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between h-10">
        <div className="flex items-center">
          <RiCompasses2Line className="text-cyan-900 mr-1" />
          <h3 className="text-xs uppercase text-cyan-900 font-medium">
            {t("assembly")}
          </h3>
        </div>
        <div>
          <TooltipWithHover
            message={`${t("spo-informations")}`}
            position="left"
          >
            <div className="text-cyan-900 uppercase text-xs">SPO</div>
          </TooltipWithHover>
        </div>
      </div>

      <div className="w-full px-2 pb-2 overflow-auto">
        <table className="w-full text-center">
          <thead>
            <tr>
              <th> </th>
              <th> </th>
              <th className="p-2 text-sm leading-5 text-primary-light font-semibold">
                {t("deposit")}
              </th>
              <th className="p-2 text-sm leading-5 text-primary-light font-semibold">
                {t("obtain")}
              </th>
              <th className="p-2 text-sm leading-5 text-primary-light font-semibold">
                {t("purge")}
              </th>
              <th className="p-3 text-sm leading-5 text-primary-light font-semibold">
                {t("display-1")}
              </th>
              <th className="p-3 text-sm leading-5 text-primary-light font-semibold">
                {t("appeal")}
              </th>
              <th className="p-3 text-sm leading-5 text-primary-light font-semibold">
                {t("lifting")}
              </th>
            </tr>
          </thead>
          <tbody>
            {operationsChildren?.map((operation, index) => (
              <Fragment key={operation.id}>
                <ChildDatesTable childOperation={operation} />
                {index !== operationsChildren.length - 1 && (
                  <tr className="border-b border-gray-200" />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  )
}
