import { useSearchProjectsByQuery } from "core/query-hooks/useProjects"
import { useSearchOperationsByQuery } from "core/spo/query-hooks/useOperation"
import IHOCLoader from "hoc/HocLoader"
import { t } from "i18next"
import { Link } from "react-router-dom"
import { TOperationBase } from "shared/types/operation.type"
import { TProject } from "shared/types/project.type"
import { useDebounceValue } from "usehooks-ts"

interface IGlobalSearchResultsProps {
  searchQuery: string
  onClickProject: () => void
  onClickOperation: () => void
}

type TSearchResult =
  | (TProject & { type: string })
  | (TOperationBase & { type: string })

export default function GlobalSearchResults(props: IGlobalSearchResultsProps) {
  const { searchQuery, onClickOperation, onClickProject } = props
  const [debouncedValue] = useDebounceValue(searchQuery, 1000)
  const {
    data: projects,
    isError,
    isLoading,
    error,
  } = useSearchProjectsByQuery(debouncedValue)

  const {
    data: operations,
    isError: isErrorOperations,
    isLoading: isLoadingOperations,
    error: errorOperation,
  } = useSearchOperationsByQuery(debouncedValue)

  const mergedResults: TSearchResult[] = [
    ...(projects?.data.map((project) => ({ ...project, type: "project" })) ||
      []),
    ...(operations?.data.map((operation) => ({
      ...operation.operationBase,
      type: "operation",
    })) || []),
  ]

  return (
    <div>
      <IHOCLoader
        classNames="text-white"
        error={error || errorOperation}
        isLoading={isLoading || isLoadingOperations}
        isError={isError || isErrorOperations}
      >
        <ul className="overflow-auto result-list">
          {mergedResults.length > 0 ? (
            mergedResults.map((result) => (
              <li key={result.id}>
                {result.type === "project" ? (
                  <Link
                    onClick={onClickProject}
                    to={
                      result.intranetUrlSci5
                        ? `${result.intranetUrlSci5}`
                        : `/project/${result.id}`
                    }
                    target={result.intranetUrlSci5 ? "_blank" : "_self"}
                    className="text-white py-4 block hover:bg-gray-900 px-6 transition duration-150 ease-out hover:ease-in border-t Search__Link"
                  >
                    {`${result.name}, ${result.address} - ${result.city}, ${result.postcode}`}
                  </Link>
                ) : (
                  <Link
                    onClick={onClickOperation}
                    to={
                      result.intranetUrlSci5
                        ? `${result.intranetUrlSci5}`
                        : `/operation/${result.id}`
                    }
                    target={result.intranetUrlSci5 ? "_blank" : "_self"}
                    className="text-white py-4 block hover:bg-gray-900 px-6 transition duration-150 ease-out hover:ease-in border-t Search__Link"
                  >
                    {"registrationNumbers" in result
                      ? `[${result.registrationNumbers.join(", ")}] ${
                          result.name
                        }, ${result.address} - ${result.city}, ${
                          result.postcode
                        }`
                      : `${result.name}, ${result.address} - ${result.city}, ${result.postcode}`}
                  </Link>
                )}
              </li>
            ))
          ) : (
            <li>
              <p className="text-white px-6 py-4">
                {`${t("globalSearch.noResultsFound")}`} {searchQuery}
              </p>
            </li>
          )}
        </ul>
      </IHOCLoader>
    </div>
  )
}
