import { DisplayStep } from "features/programs/ProgramsListResults"
import PinProject from "features/project/PinProject"
import { nanoid } from "nanoid"
import { Link } from "react-router-dom"
import { TOperationIds } from "shared/types/operation.type"

export interface IProgramCardProps {
  program: {
    type: string
    name: string
    id: number
    step: {
      stepId: number
      stepLabel: string
    }
    businessUnit: string
    address: string
    postcode: string
    city: string
    registrationNumbers: string[] | null
    intranetUrlSci5: string | null
  }
  target: string
  link: string
  displayOperation: boolean
  operationsIds: TOperationIds | undefined
}

export default function ProgramCard(props: IProgramCardProps) {
  const { program, target, link, displayOperation, operationsIds } = props
  return (
    <div className="relative rounded-lg bg-white shadow-lg p-4 hover:shadow-xl transition-shadow duration-300 ease-in-out">
      <Link
        to={link}
        target={target}
        state={{ operations: operationsIds }}
        className="block h-full"
      >
        <div className="absolute top-2 left-2 text-sm font-semibold px-1 pt-2">
          <DisplayStep
            stepId={program.step.stepId}
            stepLabel={program.step.stepLabel}
          />
        </div>

        <div className="mt-8">
          <h2 className="text-md font-bold text-gray-800">
            {program.city} ({program.postcode})
          </h2>
          <p className="text-gray-600">{program.address}</p>

          <p className="text-gray-600">{program.businessUnit}</p>

          {displayOperation && program.registrationNumbers && (
            <div className="mt-2 text-gray-800">
              {program.registrationNumbers.map((number) => (
                <p key={nanoid()}>{number}</p>
              ))}
            </div>
          )}
        </div>
      </Link>

      <div className="absolute top-2 right-2">
        <PinProject programId={program.id} />
      </div>
    </div>
  )
}
