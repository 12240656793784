import { Button } from "components/Button/Button"
import { t } from "i18next"
import { PropsWithChildren, useRef } from "react"
import ReactDOM from "react-dom"
import { MdClose } from "react-icons/md"
import { useOnClickOutside } from "usehooks-ts"
import "../Modal/Modal.scss"

export interface DeleteModalProps extends PropsWithChildren {
  isShowing: boolean
  closeModal: () => void
  deleteItem: () => void
  title: string
  description?: string
  displayCloseIcon?: boolean
  loading?: boolean
}

export function DeleteModal({
  isShowing,
  closeModal,
  deleteItem,
  title,
  description,
  displayCloseIcon = false,
  loading = false,
  children,
}: DeleteModalProps) {
  const modalRef = useRef(null)

  useOnClickOutside(modalRef, closeModal)

  return (
    isShowing &&
    ReactDOM.createPortal(
      <div className="Modal__Overlay">
        <div className="Modal__Wrapper">
          <div ref={modalRef} className="Modal__Container Modal__Medium__Width">
            <div className="flex justify-between">
              <h4 className="Modal__Title">{title}</h4>
              {displayCloseIcon && (
                <button
                  type="button"
                  className="absolute top-4 right-4 Modal__Close__Button flex items-center justify-center"
                >
                  <MdClose
                    color="#3C5D72"
                    size={22}
                    onClick={() => closeModal()}
                  />
                </button>
              )}
            </div>
            <p className="Modal__Description opacity-75">{description}</p>
            {children}
            <div className="Modal__Body">
              <div className="Modal__Footer">
                <Button
                  size="medium"
                  mode="secondary"
                  isLoading={loading}
                  onClick={() => closeModal()}
                >
                  {`${t("cancel")}`}
                </Button>
                <Button
                  marginLeft={24}
                  size="medium"
                  mode="danger"
                  isLoading={loading}
                  onClick={() => deleteItem()}
                >
                  {`${t("confirm")}`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.body,
    )
  )
}
