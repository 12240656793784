import Spinner from "components/Spinner/Spinner"
import useListPierrevalCompanies from "core/query-hooks/usePierrevalCompanies"
import DropdownFilters from "features/programs/DropdownFilter"
import { t } from "i18next"
import { TPierrevalCompany } from "shared/types/pierreval-company.type"

export interface ICompaniesFilterProps {
  selectedCompanies: number[] | []
  onCompaniesChange: (selectedCompany: number[] | []) => void
}

export default function CompaniesFilter(props: ICompaniesFilterProps) {
  const { selectedCompanies, onCompaniesChange } = props
  const { data: companiesData, isLoading, error } = useListPierrevalCompanies()

  if (isLoading) return <Spinner />

  if (error || !companiesData) return <p>{error?.response?.data.message}</p>

  const handleCompanyClick = (company: TPierrevalCompany) => {
    const isSelected = ((selectedCompanies as number[]) ?? []).includes(
      company.id,
    )

    const updatedCompanies = isSelected
      ? selectedCompanies.filter(
          (currentCompany) => currentCompany !== company.id,
        )
      : [...selectedCompanies, company.id]

    onCompaniesChange(updatedCompanies)
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="h-full text-sky-900">
              {t("companies")}
              {selectedCompanies.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-yellow-800 bg-yellow-100 rounded-full">
                  {selectedCompanies.length}
                </div>
              )}
            </div>
          )}
          options={companiesData}
          renderOption={(company, index) => (
            <li
              onClick={() => handleCompanyClick(company)}
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              tabIndex={-1}
              id={`menu-item-${index}`}
              key={`menu-item-${index}`}
            >
              <input
                className="mr-2"
                type="checkbox"
                readOnly
                checked={(selectedCompanies as number[])?.includes(company.id)}
              />
              {company.label}
            </li>
          )}
          keyExtractor={(company) => company.id}
        />
      </div>
    </div>
  )
}
