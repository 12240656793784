import classnames from "classnames"
import { PropsWithChildren } from "react"
import "./TooltipWithHover.scss"

interface ITooltipWithHoverProps extends PropsWithChildren {
  message: string
  classNames?: string
  position?:
    | "left"
    | "right"
    | "center"
    | "bottom-left"
    | "bottom-right"
    | "middle-right"
}

export default function TooltipWithHover(props: ITooltipWithHoverProps) {
  const { message, children, classNames, position } = props
  return (
    <span
      data-message={message}
      className={classnames(
        {
          "left-tooltip-with-hover": position === "left",
          "right-tooltip-with-hover": position === "right",
          "bottom-left-tooltip-with-hover": position === "bottom-left",
          "bottom-right-tooltip-with-hover": position === "bottom-right",
          "middle-right-tooltip-with-hover": position === "middle-right",
        },

        "tooltip-with-hover text-xs font-semibold",
        classNames,
      )}
    >
      <span className="text-base font-normal">{children}</span>
    </span>
  )
}
