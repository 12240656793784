import classNames from "classnames"
import AvatarBlob from "components/Avatar/AvatarBlob"
import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { BsMicrosoftTeams } from "react-icons/bs"
import { MdEmail, MdPhone, MdWarning } from "react-icons/md"
import { TUser } from "shared/types/user.type"
import "./ContactPopover.scss"

interface IContactPopoverProps {
  user: TUser
  top?: boolean
}

export default function ContactPopover({
  user,
  top = false,
}: IContactPopoverProps) {
  const [dropdownPosition, setDropdownPosition] = useState("left-0")

  const ref = useRef<HTMLDivElement | null>(null)

  const handleResize = () => {
    if (ref.current) {
      const viewportWidth = window.innerWidth
      const containerRect = ref.current.getBoundingClientRect()
      if (containerRect.right > viewportWidth) {
        setDropdownPosition("right-0")
      } else if (containerRect.left < viewportWidth / 2) {
        setDropdownPosition("left-0")
      } else {
        setDropdownPosition("right-0")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleResize)
    handleResize()
    return () => {
      window.removeEventListener("click", handleResize)
    }
  }, [])

  return (
    <div
      ref={ref}
      className={classNames(`Contact__Popover p-4 w-max ${dropdownPosition}`, {
        "bottom-0": top,
        "-top-6": !top,
      })}
    >
      <ul>
        <li className="mb-4">
          <div className="flex items-center justify-center">
            <div className="Contact__Popover_Container flex-2">
              <AvatarBlob user={user} className="w-9 h-9" />
            </div>
            <div className="ml-2 flex-1">
              <p className="font-medium">
                {user.firstName} {user.lastName}
              </p>
              <p>
                {user.job} -{user.city}
              </p>
            </div>
          </div>
        </li>
        {!user.accountEnabled ? (
          <li className="flex items-center text-red-600">
            <MdWarning size={22} className="mr-2 flex-2" />
            <span className="flex whitespace-nowrap">
              {t("user-account-disabled")}
            </span>
          </li>
        ) : (
          <>
            <li className="flex items-center">
              <MdEmail color="#2D6486" size={22} className="mr-2 flex-2" />
              <a className="Text__Link flex-1" href={`mailto:${user.email}`}>
                {user.email}
              </a>
            </li>
            {user.mobilePhone ? (
              <li className="flex items-center mt-2">
                <MdPhone color="#2D6486" size={22} className="mr-2 flex-2" />
                <a
                  className="Text__Link flex-1"
                  href={`tel:${user.mobilePhone}`}
                >
                  {user.mobilePhone}
                </a>
              </li>
            ) : null}
            <li className="flex items-center">
              <BsMicrosoftTeams
                color="#2D6486"
                size={22}
                className="mr-2 flex-2"
              />
              <a
                className="Text__Link flex-1"
                rel="noopener noreferrer"
                href={`MSTeams:/l/chat/0/0?users=${user.email}`}
              >
                {user.email}
              </a>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}
