import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import { Modal } from "components/Modal/Modal"
import { useDeleteStudyRequest } from "core/query-hooks/useStudyRequests"
import { t } from "i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

interface IDeleteStudyRequestDraft {
  isDeleteModalShowed: boolean
  toggleDeleteModal: () => void
  routeName: string
  isPending: boolean
  studyRequestType: string
}

export default function DeleteStudyRequestDraft(
  props: IDeleteStudyRequestDraft,
) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id, studyRequestId } = useParams()
  const {
    isDeleteModalShowed,
    toggleDeleteModal,
    routeName,
    isPending,
    studyRequestType,
  } = props

  const deleteStudyRequest = useDeleteStudyRequest(+studyRequestId!)

  const onDeleteStudyRequest = async () => {
    await deleteStudyRequest.mutateAsync(+studyRequestId!, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["listStudyRequests", +id!],
        })
        toast.success(`${t("study-request-deleted")}`)
        toggleDeleteModal()
        navigate(`/${routeName}/${id}/studies`)
      },
      onError: (err) => {
        toast.error(
          `${t("delete-study-request-error-toast")} : ${err.response?.data.error.message}`,
        )
      },
    })
  }

  return (
    <>
      <div>
        <Button
          isLoading={isPending}
          onClick={() => toggleDeleteModal()}
          size="small"
          mode="danger"
        >
          {`${t("delete-draft")}`}
        </Button>
      </div>
      {isDeleteModalShowed && (
        <Modal
          isShowing={isDeleteModalShowed}
          closeModal={toggleDeleteModal}
          title={`${t("delete-study-request-title", {
            studyRequestType,
          })}`}
        >
          <div className="Modal__Body">
            <div className="Modal__Footer">
              <Button
                size="medium"
                mode="secondary"
                onClick={() => toggleDeleteModal()}
              >
                {`${t("cancel")}`}
              </Button>
              <Button
                marginLeft={24}
                size="medium"
                mode="danger"
                onClick={() => onDeleteStudyRequest()}
              >
                {`${t("confirm")}`}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
