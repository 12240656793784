import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { Button } from "components/Button/Button"
import { Modal } from "components/Modal/Modal"
import {
  EhancedFile,
  UploadFieldDrop,
} from "components/UploadField/UploadFieldDrop"
import { AuthContext } from "core/auth/AuthProvider"
import { usePostDocumentToGed } from "core/ged/query-hooks/useGed"
import { useGetUserByAzureId } from "core/query-hooks/useUsers"
import { t } from "i18next"
import { FormEvent, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { IoWarningOutline } from "react-icons/io5"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { nanoid } from "nanoid"
import * as yup from "yup"
import { MAX_SIZED_BYTES, UNAUTHORIZED_EXTENSIONS } from "./ged.resources"
import { Subfolder1Field } from "./subfolders/Subfolder1Field"
import { Subfolder2Field } from "./subfolders/Subfolder2Field"
import { Subfolder3Field } from "./subfolders/Subfolder3Field"
import useAddDocument from "./useAddDocument"

const schema = yup
  .object({
    documentName: yup.string(),
    documentType: yup.string().required(),
    chapter: yup.string().required(),
    subChapter: yup.string().required(),
  })
  .required()

export default function AddDocumentsModal({
  toggleModal,
  isModalShowed,
  view,
  registrationNumbers,
  numStatus,
}: {
  isModalShowed: boolean
  toggleModal: () => void
  view: number
  registrationNumbers: string[] | undefined
  numStatus?: string
}) {
  const {
    selectedChapter,
    chapterOptions,
    subChapterOptions,
    documentTypeOptions,
    selectedDocumentType,
    selectedSubChapter,
    onChangeChapter,
    onChangeSubChapter,
    onChangeDocumentType,
  } = useAddDocument(view)
  const { getUserInfo } = useContext(AuthContext)
  const userInfo = getUserInfo()
  const { data: user } = useGetUserByAzureId(userInfo.azureId)
  const { id } = useParams()
  const queryClient = useQueryClient()
  const [myFiles, setMyFiles] = useState<EhancedFile[]>([])
  const [documentName, setDocumentName] = useState<string>()
  const [isDocumentNameEditable, setIsDocumentNameEditable] =
    useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [failedUploads, setFailedUploads] = useState<EhancedFile[]>([])
  const uploadDocumentToGed = usePostDocumentToGed()
  const [selectedSubfolder1, setSelectedSubfolder1] = useState<string>("")
  const [selectedSubfolder2, setSelectedSubfolder2] = useState<string>("")
  const [selectedSubfolder3, setSelectedSubfolder3] = useState<string>("")
  const [selectedRegistrationNumber, setSelectedRegistrationNumber] =
    useState<string>(registrationNumbers ? registrationNumbers[0] : "")

  type FormValues = {
    documentName: string
    documentType: string
    chapter: string
    subChapter: string
    registrationNumber: string
    numStatus?: string
    storedBy?: string
  }

  const { register, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(schema as yup.ObjectSchema<FormValues>),
  })

  useEffect(() => {
    return reset({
      documentName: myFiles.length
        ? myFiles[0].name.replace(/\.[^/.]+$/, "")
        : undefined,
    })
  }, [myFiles])

  const handleFileChange = (files: File[]): void => {
    const authorizedFiles: File[] = []
    files.map((file) => {
      if (file.size > MAX_SIZED_BYTES) {
        return toast.error(`${t("file-too-big-error")} : ${file.name}`)
      }

      if (UNAUTHORIZED_EXTENSIONS.includes(file.name.split(".").pop() ?? "")) {
        return toast.error(`${t("unauthorized-file-extension")} : ${file.name}`)
      }
      return authorizedFiles.push(file)
    })

    setMyFiles(
      authorizedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      }),
    )

    setIsDocumentNameEditable(authorizedFiles.length === 1)
  }

  const handleRemoveFile = (index: number) => {
    const updatedFiles = myFiles.filter((_, i) => i !== index)
    setMyFiles(updatedFiles)

    setIsDocumentNameEditable(updatedFiles.length === 1)
  }

  const closeModal = () => {
    reset()
    setDocumentName("")
    setMyFiles([])
    toggleModal()
  }

  const handleSubfolder1Select = (selectedValue: string) => {
    setSelectedSubfolder1(selectedValue)
  }

  const handleSubfolder2Select = (selectedValue: string) => {
    setSelectedSubfolder2(selectedValue)
  }

  const handleSubfolder3Select = (selectedValue: string) => {
    setSelectedSubfolder3(selectedValue)
  }

  const submitForm = async (values: FormValues) => {
    try {
      setIsSubmitting(true)
      const successfulUploads: EhancedFile[] = []
      const failedUploadsSubmit: EhancedFile[] = []

      await Promise.all(
        myFiles.map(async (file) => {
          const formValues = {
            file,
            subfolder1:
              selectedSubfolder1 === "" ? undefined : selectedSubfolder1,
            subfolder2:
              selectedSubfolder2 === "" ? undefined : selectedSubfolder2,
            subfolder3:
              selectedSubfolder3 === "" ? undefined : selectedSubfolder3,
            documentType: values.documentType,
            chapter: values.chapter,
            subChapter: values.subChapter,
            documentName:
              myFiles.length === 1
                ? values.documentName
                : file.name.replace(/\.[^/.]+$/, ""),
            registrationNumber: selectedRegistrationNumber,
            ...(id && { projectId: id }),
            numStatus,
            storedBy: user?.docuwareName,
          }

          const isUploadSuccess =
            await uploadDocumentToGed.mutateAsync(formValues)
          isUploadSuccess
            ? successfulUploads.push(file)
            : failedUploadsSubmit.push(file)
        }),
      )

      setFailedUploads(failedUploadsSubmit)
      if (successfulUploads.length > 0) {
        const successMessage =
          successfulUploads.length === 1
            ? t("ged.upload.successMessage")
            : t("ged.upload.successMessagePlural")
        toast.success(successMessage)
        queryClient.invalidateQueries({
          queryKey: ["getTreeByRegistrationNumbers", registrationNumbers],
        })
        queryClient.invalidateQueries({
          queryKey: ["getTreeByProjectId", +id!],
        })
        reset()
        setDocumentName("")
        setMyFiles([])
        if (successfulUploads.length === myFiles.length) {
          toggleModal()
        }
      }
    } catch (error) {
      toast.error(`${t("ged.upload.errorMessage")}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <Modal
      isShowing={isModalShowed}
      closeModal={closeModal}
      title={`${t("ged.addTitlePlural")}`}
      description={`${t("ged.addDescriptionPlural")}`}
      displayCloseIcon
    >
      <UploadFieldDrop
        myFiles={myFiles}
        handleChange={handleFileChange}
        handleRemove={(index: number) => handleRemoveFile(index)}
        maxFiles={10}
      />

      {failedUploads.length > 0 && (
        <div className="rounded-lg bg-red-200 mt-4 p-3 text-primary-dark">
          <div className="flex items-center mb-1">
            <div className="mr-2 text-red-400">
              <IoWarningOutline size={28} />
            </div>
            <h2>{t("ged.filesNotUploaded")}</h2>
          </div>

          <ul className="list-disc list-inside pl-6 text-sm">
            {failedUploads.map((file) => (
              <li key={nanoid()}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}

      <form onSubmit={onSubmit} className="Text__Field_Container mt-4">
        {registrationNumbers ? (
          <label htmlFor="registrationNumber">
            {`${t("child-operation")}`}
            <select
              {...register("registrationNumber")}
              name="registrationNumber"
              id="registration-number-select"
              disabled={registrationNumbers.length === 1}
              defaultValue={selectedRegistrationNumber}
              onChange={(e) => {
                setSelectedRegistrationNumber(e.target.value)
              }}
              className={classNames(
                "Select__Field Text__Field flex items-center justify-center Select__Wrapper",
                {
                  "disabled:opacity-75": uploadDocumentToGed.isPending,
                },
              )}
            >
              {registrationNumbers?.map((registrationNumber) => (
                <option key={registrationNumber} value={registrationNumber}>
                  {registrationNumber}
                </option>
              ))}
            </select>
          </label>
        ) : null}
        {isDocumentNameEditable ? (
          <label htmlFor="documentName">
            {`${t("ged.documentName")}`}
            <input
              id="documentName"
              {...register("documentName")}
              className={classNames("Text__Field", {
                "disabled:opacity-75": uploadDocumentToGed.isPending,
              })}
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              disabled={uploadDocumentToGed.isPending}
              type="text"
              placeholder={`${t("ged.documentName")}`}
            />
          </label>
        ) : null}
        <label htmlFor="document-type-select">
          {`${t("ged.chapter")}`}
          <select
            {...register("chapter")}
            onChange={onChangeChapter}
            name="documentType"
            id="document-type-select"
            disabled={uploadDocumentToGed.isPending}
            className={classNames(
              "Select__Field Text__Field flex items-center justify-center Select__Wrapper",
              {
                "disabled:opacity-75": uploadDocumentToGed.isPending,
              },
            )}
            defaultValue={undefined}
            value={selectedChapter}
          >
            {chapterOptions?.map((chapter) => (
              <option key={`chapter-${chapter.value}`} value={chapter.value}>
                {chapter.label}
              </option>
            ))}
          </select>
        </label>

        <label htmlFor="document-type-select">
          {`${t("ged.subChapter")}`}
          <select
            {...register("subChapter")}
            onChange={onChangeSubChapter}
            disabled={uploadDocumentToGed.isPending}
            name="documentType"
            id="document-type-select"
            className={classNames(
              "Select__Field Text__Field flex items-center justify-center Select__Wrapper",
              {
                "disabled:opacity-75": uploadDocumentToGed.isPending,
              },
            )}
            defaultValue={undefined}
            value={selectedSubChapter}
          >
            {subChapterOptions?.map((subChapter) => (
              <option
                key={`sub-chapter-${subChapter.value}`}
                value={subChapter.value}
              >
                {subChapter.label}
              </option>
            ))}
          </select>
        </label>

        <label htmlFor="document-type-select">
          {`${t("ged.documentType")}`}
          <select
            {...register("documentType", { required: true })}
            onChange={onChangeDocumentType}
            disabled={uploadDocumentToGed.isPending}
            name="documentType"
            id="document-type-select"
            className={classNames(
              "Select__Field Text__Field flex items-center justify-center Select__Wrapper",
              {
                "disabled:opacity-75": uploadDocumentToGed.isPending,
              },
            )}
            defaultValue={undefined}
            value={selectedDocumentType}
          >
            {documentTypeOptions?.map((documentType) => (
              <option
                key={`document-type-${documentType.value}`}
                value={documentType.value}
              >
                {documentType.label}
              </option>
            ))}
          </select>
        </label>

        <Subfolder1Field
          documentType={selectedDocumentType}
          onSelectSubfolder1={handleSubfolder1Select}
          registrationNumbers={registrationNumbers ?? []}
        />

        <Subfolder2Field
          subfolder1={
            selectedSubfolder1 === "" ? undefined : selectedSubfolder1
          }
          onSelectSubfolder2={handleSubfolder2Select}
          registrationNumbers={registrationNumbers ?? []}
        />

        <Subfolder3Field
          subfolder2={
            selectedSubfolder2 === "" ? undefined : selectedSubfolder2
          }
          onSelectSubfolder3={handleSubfolder3Select}
          registrationNumbers={registrationNumbers ?? []}
        />

        <div className="Modal__Footer mt-4">
          <div className="flex items-center justify-end">
            <Button
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => closeModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              type="submit"
              marginLeft={24}
              size="medium"
              mode="primary"
              isLoading={isSubmitting}
              isDisabled={myFiles.length === 0}
            >
              {`${t("confirm")}`}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
