import { useQuery } from "@tanstack/react-query"
import { listExcelLinks } from "core/api/excel-links"
import { CustomAxiosError } from "shared/types/axios.type"
import { TExcelLink } from "shared/types/excel-link.type"

function useListExcelLinks(code: string, businessUnitId?: string) {
  return useQuery<TExcelLink[], CustomAxiosError>({
    queryKey: ["listExcelLinks", code, businessUnitId],
    queryFn: () => listExcelLinks(code, businessUnitId),
  })
}

export default useListExcelLinks
