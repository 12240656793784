import { API } from "core/api/axios"
import { TProjectNextStep, TProjectStep } from "shared/types/project.type"

async function getSteps(): Promise<TProjectStep[]> {
  const res = await API.get("/steps")
  return res.data
}

async function getNextStepsById(stepId?: number): Promise<TProjectNextStep> {
  return typeof stepId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/steps/${stepId}/next`).then((response) => response.data)
}

export { getNextStepsById, getSteps }
