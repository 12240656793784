import { API } from "core/api/axios"
import {
  TMutateProgress,
  TProgress,
  TProgressList,
  TProgressUsers,
  TValidateProgress,
} from "shared/types/progress.type"
import { getUser } from "./users"

async function getProgressByChildOperationId(
  childOperationId?: number,
  query?: string,
  skip?: number,
  take?: number,
  order?: "asc" | "desc" | null,
  sort?: string,
  done?: boolean,
): Promise<TProgressList> {
  const sortUrl = sort ? `&sort=${sort}` : ""
  const orderUrl = order ? `&order=${order}` : ""
  const doneUrl = typeof done !== "undefined" ? `&done=${done}` : ""

  return typeof childOperationId === "undefined"
    ? Promise.reject(new Error("Invalid child operation id"))
    : API.get(
        query
          ? `/progress?childOperation=${childOperationId}&search=${query}&skip=${skip}&take=${take}${orderUrl}${sortUrl}${doneUrl}`
          : `/progress?childOperation=${childOperationId}&skip=${skip}&take=${take}${orderUrl}${sortUrl}${doneUrl}`,
      ).then((response) => response.data)
}

async function getProgressById(progressId?: number): Promise<TProgress> {
  return typeof progressId === "undefined"
    ? Promise.reject(new Error("Invalid progress id"))
    : API.get(`/progress/${progressId}`).then((response) => response.data)
}

async function createProgress(progress: TMutateProgress): Promise<TProgress> {
  return typeof progress === "undefined"
    ? Promise.reject(new Error("Invalid progress"))
    : API.post("/progress", progress).then((response) => response.data)
}

async function updateProgress(
  progressId: number,
  progress: TMutateProgress,
): Promise<TProgress> {
  return typeof progressId === "undefined"
    ? Promise.reject(new Error("Invalid progress id"))
    : API.patch(`/progress/${progressId}`, progress).then(
        (response) => response.data,
      )
}

async function deleteProgress(id: number): Promise<TProgress> {
  const res = await API.delete(`/progress/${id}`)
  return res.data
}

async function validateProgress(
  progress: TValidateProgress,
): Promise<TProgress> {
  return typeof progress.id === "undefined"
    ? Promise.reject(new Error("Invalid progress id"))
    : API.patch(`/progress/${progress.id}`, {
        isDefinitive: progress.isDefinitive,
      }).then((response) => response.data)
}

async function getProgressPersonInChargeAndCreator(
  progressId: number,
): Promise<TProgressUsers> {
  const progress: TProgress = await getProgressById(progressId)
  const personInCharge = progress.personInCharge
    ? await getUser(progress?.personInCharge?.azureId!)
    : undefined
  const creator = await getUser(progress?.creator?.azureId!)

  return {
    progress,
    personInCharge,
    creator,
  }
}

async function hasModificationRight(progressId: number): Promise<boolean> {
  const res = await API.get(`/progress/${progressId}/modification-right`)
  return res.data
}

export {
  createProgress,
  deleteProgress,
  getProgressByChildOperationId,
  getProgressById,
  getProgressPersonInChargeAndCreator,
  hasModificationRight,
  updateProgress,
  validateProgress,
}
