import {
  STATUS_CREATED,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_VALIDATED,
  TYPE_COST_OF_WORK,
  TYPE_INFOGRAPHY,
} from "features/studies/studyRequest.resources"
import { t } from "i18next"

export function getActionLabel(statusId: number, typeId: number) {
  let studyRequestType: string = t("of-feasibility")
  switch (typeId) {
    case TYPE_COST_OF_WORK:
      studyRequestType = t("of-cost-of-work")
      break
    case TYPE_INFOGRAPHY:
      studyRequestType = t("of-infography")
      break
    default:
      break
  }

  switch (statusId) {
    case STATUS_CREATED:
      return `${t("todo-study-demande")} ${studyRequestType} ${t(
        "waiting-for-validation",
      )}`
    case STATUS_VALIDATED:
      return `${t("todo-study-prio-demande")} ${studyRequestType} ${t(
        "todo-study-en-attente",
      )}`
    case STATUS_PRIORIZED_AND_ASSIGNED:
      return `${t("todo-study-restit-demande")} ${studyRequestType} ${t(
        "todo-study-en-attente",
      )}`
    case STATUS_PRIORIZATION_STANDBY:
      return `${t("todo-study-prio-demande")} ${studyRequestType} ${t(
        "todo-study-en-stand-by",
      )}`
    case STATUS_RESTITUTION_STANDBY:
      return `${t("todo-study-restit-demande")} ${studyRequestType} ${t(
        "todo-study-en-stand-by",
      )}`
    default:
      return null
  }
}

export function getActionHtml(statusId: number, typeId: number) {
  switch (statusId) {
    case STATUS_CREATED:
      return <div>{getActionLabel(statusId, typeId)}</div>
    case STATUS_VALIDATED:
      return <div>{getActionLabel(statusId, typeId)}</div>
    case STATUS_PRIORIZED_AND_ASSIGNED:
      return <div>{getActionLabel(statusId, typeId)}</div>
    case STATUS_PRIORIZATION_STANDBY:
      return <div>{getActionLabel(statusId, typeId)}</div>
    case STATUS_RESTITUTION_STANDBY:
      return <div>{getActionLabel(statusId, typeId)}</div>
    default:
      return null
  }
}
