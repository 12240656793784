import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import { FormTextField } from "components/Form/FormTextField"
import { Header } from "components/Header/Header"
import { TCreatePlotForm } from "features/plots/PlotCreate"
import { t } from "i18next"
import { FormEvent, ReactElement, useState } from "react"
import {
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form"
import { TCurrency } from "shared/types/global.type"

interface IFormaAddProps {
  children?: ReactElement[] | ReactElement
  onceSubmited: (data: TCreatePlotForm) => void
  register: UseFormRegister<TCreatePlotForm>
  handleSubmit: UseFormHandleSubmit<TCreatePlotForm>
  errors: FieldErrors<TCreatePlotForm>
  control: Control<TCreatePlotForm>
}

export default function FormCreate({
  children,
  register,
  handleSubmit,
  errors,
  onceSubmited,
  control,
}: IFormaAddProps) {
  const [priceValue, setPriceValue] = useState<{
    value?: number
    unit: TCurrency
  }>({ unit: "EUR" })

  const submitForm = (data: TCreatePlotForm) => {
    onceSubmited({
      ...data,
      price: { val: data.price.val, unit: priceValue.unit },
    })
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <form onSubmit={onSubmit}>
      <Box>
        <div className="Padding__Bottom_32 pt-2">
          <Header size="h2">{`${t("plots.form.create.title")}`}</Header>
        </div>
        <div className="Flex Items__Start Gap__8">
          <div
            style={{
              flex: 1,
            }}
          >
            <FormTextField
              id="prefix"
              className="Text__Field_Container"
              errors={errors}
              name="prefix"
              register={register}
              label={`${t("plots.form.prefixLabel")} *`}
              type="text"
              placeholder={`${t("plots.form.prefixPlaceholder")}`}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <FormTextField
              id="section"
              errors={errors}
              name="section"
              className="Text__Field_Container"
              register={register}
              label={`${t("plots.form.sectionLabel")} *`}
              type="text"
              placeholder={`${t("plots.form.sectionPlaceholder")}`}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <FormTextField
              id="number"
              errors={errors}
              name="number"
              className="Text__Field_Container"
              register={register}
              label={`${t("plots.form.numberLabel")} *`}
              type="text"
              placeholder={`${t("plots.form.numberPlaceholder")}`}
            />
          </div>
        </div>

        <FormTextField
          id="area"
          errors={errors}
          name="area.val"
          register={register}
          className="Text__Field_Container"
          label={`${t("plots.form.surfaceLabel")} *`}
          type="number"
          placeholder=""
        />

        <FormTextField
          id="price.val"
          control={control}
          errors={errors}
          className="Text__Field_Container"
          name="price.val"
          register={register}
          setPriceValue={setPriceValue}
          label={`${t("plots.form.priceLabel")}`}
          type="currency"
          placeholder=""
        />

        <div className="Flex Items__Start Gap__8">
          <div
            style={{
              flex: 1,
            }}
          >
            <FormTextField
              id="owner"
              errors={errors}
              name="owner"
              className="Text__Field_Container"
              register={register}
              label={`${t("plots.form.ownerLabel")}`}
              type="text"
              placeholder=""
            />
          </div>
        </div>
      </Box>

      <div className="flex items-center justify-end mt-4">
        {children}
        <div className="ml-4">
          <Button type="submit" size="medium" mode="primary" isLoading={false}>
            {`${t("plots.form.create.submitButton")}`}
          </Button>
        </div>
      </div>
    </form>
  )
}
