import { TLotStatuses, TLotsCommercialization } from "shared/types/spo/lot.type"
import SPO_API from "../axios"

async function getSpoLotsByOperation(
  registrationId: string,
): Promise<TLotStatuses> {
  const res = await SPO_API.get(`/operations/${registrationId}/lots`)
  return res.data
}

async function getSpoLotsByTranche(
  registrationNumber: string,
  trancheCommercialeId: string,
): Promise<TLotStatuses> {
  const res = await SPO_API.get(
    `/operations/${registrationNumber}/tranches-commerciales/${trancheCommercialeId}/lots`,
  )
  return res.data
}

async function getLotsCommercializationByOperation(
  registrationNumber: string,
): Promise<TLotsCommercialization> {
  const res = await SPO_API.get(
    `/operations/${registrationNumber}/lots/commercialisation`,
  )
  return res.data
}

async function getLotsCommercializationByTranche(
  registrationNumber: string,
  trancheCommercialeId: string,
): Promise<TLotsCommercialization> {
  const res = await SPO_API.get(
    `/operations/${registrationNumber}/tranches-commerciales/${trancheCommercialeId}/lots/commercialisation`,
  )
  return res.data
}

async function countSpoLots(
  registrationId: string,
): Promise<{ registrationNumber: string; numberOfLots: number }> {
  return !registrationId.startsWith("PR") || registrationId.includes(",")
    ? Promise.reject(new Error("Invalid immat"))
    : SPO_API.get(`/operations/${registrationId}/lots/count`).then(
        (response) => response.data,
      )
}

export {
  countSpoLots,
  getLotsCommercializationByOperation,
  getLotsCommercializationByTranche,
  getSpoLotsByOperation,
  getSpoLotsByTranche,
}
