import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import useModal from "components/Modal/useModal"
import {
  useCreateFeasibility,
  useGetFeasibility,
  useUpdateFeasibility,
} from "core/query-hooks/useFeasibilities"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import i18next, { t } from "i18next"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useContext, useEffect, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { GROUP } from "shared/resources/groups.resources"
import * as yup from "yup"
import DeleteStudyRequestDraft from "../DeleteStudyRequestDraft"
import { FeasibilityContext } from "./FeasibilityProvider"

type TFeasibilityCreateDetailsStepForm = {
  projectId?: number
  collective?: number
  veb?: number
  demeuresDeLouise?: number
  office?: number
  businessPark?: number
  buildingLot?: number
  house?: number
  semiCollective?: number
  developersComment?: string
  marginPercentage?: number
  cashflow?: number
  isDraft?: boolean
}

const validationSchema = yup.object({
  collective: yup
    .number()
    .positive(() => i18next.t("positive-number-batch"))
    .round("round")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  veb: yup
    .number()
    .positive(() => i18next.t("positive-number-batch"))
    .round("round")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  demeuresDeLouise: yup
    .number()
    .positive(() => i18next.t("positive-number-batch"))
    .round("round")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  office: yup
    .number()
    .positive(() => i18next.t("positive-number-batch"))
    .round("round")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  businessPark: yup
    .number()
    .positive(() => i18next.t("positive-number-batch"))
    .round("round")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  buildingLot: yup
    .number()
    .positive(() => i18next.t("positive-number-batch"))
    .round("round")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  house: yup
    .number()
    .positive(() => i18next.t("positive-number-batch"))
    .round("round")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  semiCollective: yup
    .number()
    .positive(() => i18next.t("positive-number-batch"))
    .round("round")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  developersComment: yup
    .string()
    .required(() => i18next.t("comment-error-message"))
    .transform((curr, orig) => (orig === "" ? null : curr)),
  marginPercentage: yup
    .number()
    .required(() => i18next.t("mandatory"))
    .positive(() => i18next.t("positive-number-message"))
    .transform((curr, orig) => (orig === "" ? null : curr))
    .test(
      "lessThanHundred",
      () => i18next.t("hundred-or-lower"),
      (value) => value < 101,
    ),
  cashflow: yup
    .number()
    .required(() => i18next.t("mandatory"))
    .positive(() => i18next.t("positive-number-message"))
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .test(
      "is-integer",
      t("infography-restitution-hours-integer-validation"),
      (value) => Number.isInteger(value),
    ),
})

export interface IStepDetailsFeasibility {
  routeName: string
}

export function StepDetailsFeasibility(props: IStepDetailsFeasibility) {
  const { id, studyRequestId } = useParams()
  const { data: user } = useGetCurrentUser()
  const createFeasibility = useCreateFeasibility()
  const queryClient = useQueryClient()
  const { routeName } = props
  const [total, setTotal] = useState<number>()
  const [totalError, setTotalError] = useState<boolean>(false)
  const { feasibilityInfo, setFeasibilityInfo, currentId, setCurrentId } =
    useContext(FeasibilityContext)
  // constantes en cas d'édition
  const { data: fesaData } = useGetFeasibility(+studyRequestId!)
  const updateFeasibility = studyRequestId
    ? useUpdateFeasibility(+studyRequestId)
    : useUpdateFeasibility(currentId!)
  const creatorId = fesaData?.creator.azureId
  const userIsCreatorOrAdmin =
    !creatorId ||
    creatorId === user?.azureId ||
    user?.groups.includes(GROUP.ADMIN)
  const collectiveValue = fesaData?.collective
  const vebValue = fesaData?.veb
  const demeuresDeLouiseValue = fesaData?.demeuresDeLouise
  const officeValue = fesaData?.office
  const businessParkValue = fesaData?.businessPark
  const buildingLotValue = fesaData?.buildingLot
  const houseValue = fesaData?.house
  const semiCollectiveValue = fesaData?.semiCollective
  const marginPercentageValue = fesaData?.marginPercentage
  const cashflowValue = fesaData?.cashflow
  const commentValue = fesaData?.comments?.[0]?.comment || ""
  const { isShowing: isDeleteModalShowed, toggle: toggleDeleteModal } =
    useModal()
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TFeasibilityCreateDetailsStepForm>({
    resolver: yupResolver(
      validationSchema as yup.ObjectSchema<TFeasibilityCreateDetailsStepForm>,
    ),
    defaultValues: {
      collective: collectiveValue ? +collectiveValue : undefined,
      veb: vebValue ? +vebValue : undefined,
      demeuresDeLouise: demeuresDeLouiseValue
        ? +demeuresDeLouiseValue
        : undefined,
      office: officeValue ? +officeValue : undefined,
      businessPark: businessParkValue ? +businessParkValue : undefined,
      buildingLot: buildingLotValue ? +buildingLotValue : undefined,
      house: houseValue ? +houseValue : undefined,
      semiCollective: semiCollectiveValue ? +semiCollectiveValue : undefined,
      marginPercentage: marginPercentageValue
        ? +marginPercentageValue
        : undefined,
      cashflow: cashflowValue ? +cashflowValue : undefined,
      developersComment: commentValue,
    },
  })

  const collective = useWatch({
    control,
    name: "collective",
  })

  const veb = useWatch({
    control,
    name: "veb",
  })

  const demeuresDeLouise = useWatch({
    control,
    name: "demeuresDeLouise",
  })

  const office = useWatch({
    control,
    name: "office",
  })

  const businessPark = useWatch({
    control,
    name: "businessPark",
  })

  const house = useWatch({
    control,
    name: "house",
  })

  const buildingLot = useWatch({
    control,
    name: "buildingLot",
  })

  const semiCollective = useWatch({
    control,
    name: "semiCollective",
  })

  useEffect(() => {
    reset({
      projectId: +id!,
      developersComment: feasibilityInfo.developersComment,
      collective: feasibilityInfo.collective,
      veb: feasibilityInfo.veb,
      demeuresDeLouise: feasibilityInfo.demeuresDeLouise,
      office: feasibilityInfo.office,
      businessPark: feasibilityInfo.businessPark,
      buildingLot: feasibilityInfo.buildingLot,
      house: feasibilityInfo.house,
      semiCollective: feasibilityInfo.semiCollective,
      marginPercentage: feasibilityInfo.marginPercentage,
      cashflow: feasibilityInfo.cashflow,
    })
  }, [])

  useEffect(() => {
    if (fesaData) {
      reset({
        collective: collectiveValue ? +collectiveValue : undefined,
        veb: vebValue ? +vebValue : undefined,
        demeuresDeLouise: demeuresDeLouiseValue
          ? +demeuresDeLouiseValue
          : undefined,
        office: officeValue ? +officeValue : undefined,
        businessPark: businessParkValue ? +businessParkValue : undefined,
        buildingLot: buildingLotValue ? +buildingLotValue : undefined,
        house: houseValue ? +houseValue : undefined,
        semiCollective: semiCollectiveValue ? +semiCollectiveValue : undefined,
        marginPercentage: marginPercentageValue
          ? +marginPercentageValue
          : undefined,
        cashflow: cashflowValue ? +cashflowValue : undefined,
        developersComment: commentValue,
      })
    }
  }, [fesaData])

  useEffect(() => {
    if (studyRequestId) {
      setTotal(
        parseInt(collectiveValue?.toString() || "0") +
          parseInt(vebValue?.toString() || "0") +
          parseInt(demeuresDeLouiseValue?.toString() || "0") +
          parseInt(officeValue?.toString() || "0") +
          parseInt(businessParkValue?.toString() || "0") +
          parseInt(houseValue?.toString() || "0") +
          parseInt(buildingLotValue?.toString() || "0") +
          parseInt(semiCollectiveValue?.toString() || "0"),
      )
      if (total && total <= 0) {
        setTotalError(true)
      } else {
        setTotalError(false)
      }
    }
  }, [
    collectiveValue,
    vebValue,
    demeuresDeLouiseValue,
    officeValue,
    businessParkValue,
    houseValue,
    buildingLotValue,
    semiCollectiveValue,
  ])

  useEffect(() => {
    setTotal(
      parseInt(collective?.toString() || "0") +
        parseInt(veb?.toString() || "0") +
        parseInt(demeuresDeLouise?.toString() || "0") +
        parseInt(office?.toString() || "0") +
        parseInt(businessPark?.toString() || "0") +
        parseInt(house?.toString() || "0") +
        parseInt(buildingLot?.toString() || "0") +
        parseInt(semiCollective?.toString() || "0"),
    )
    if (total && total <= 0) {
      setTotalError(true)
    } else {
      setTotalError(false)
    }
  }, [
    collective,
    veb,
    demeuresDeLouise,
    office,
    businessPark,
    house,
    buildingLot,
    semiCollective,
  ])

  const navigate = useNavigate()
  const onSubmit = (
    data: TFeasibilityCreateDetailsStepForm,
    redirectToStudies: boolean = false,
  ) => {
    if (total === 0) return setTotalError(true)

    if (!currentId && !studyRequestId) {
      return createFeasibility.mutate(
        {
          projectId: parseInt(id!),
          developersComment: data.developersComment,
          collective: data.collective,
          veb: data.veb,
          demeuresDeLouise: data.demeuresDeLouise,
          office: data.office,
          businessPark: data.businessPark,
          buildingLot: data.buildingLot,
          house: data.house,
          semiCollective: data.semiCollective,
          cashflow: data.cashflow,
          marginPercentage: data.marginPercentage,
        },
        {
          onSuccess(feasibility) {
            setFeasibilityInfo((previsousValue) => {
              return {
                ...previsousValue,
                projectId: +id!,
                developersComment: feasibility.developersComment,
                collective: feasibility.collective,
                veb: data.veb,
                demeuresDeLouise: feasibility.demeuresDeLouise,
                office: feasibility.office,
                businessPark: feasibility.businessPark,
                buildingLot: feasibility.buildingLot,
                house: feasibility.house,
                semiCollective: feasibility.semiCollective,
                cashflow: feasibility.cashflow,
                marginPercentage: feasibility.marginPercentage,
              }
            })
            if (studyRequestId) {
              navigate(`/${routeName}/${studyRequestId}/studies`)
            }
            setCurrentId(feasibility.id)
            queryClient.invalidateQueries({
              queryKey: ["listStudyRequests", id],
            })
            redirectToStudies
              ? (toast.success(t("draft-saved")),
                navigate(`/${routeName}/${id}/studies`))
              : navigate(
                  `/${routeName}/${id}/studies/feasibility/new/${feasibility.id}/documents`,
                )
          },
          onError(error) {
            toast.error(error.response?.data.error.message)
          },
        },
      )
    }
    return updateFeasibility.mutate(
      {
        projectId: parseInt(id!),
        developersComment: data.developersComment,
        collective: data.collective,
        veb: data.veb,
        demeuresDeLouise: data.demeuresDeLouise,
        office: data.office,
        businessPark: data.businessPark,
        buildingLot: data.buildingLot,
        house: data.house,
        semiCollective: data.semiCollective,
        cashflow: data.cashflow,
        marginPercentage: data.marginPercentage,
      },
      {
        onSuccess(feasibility) {
          setFeasibilityInfo((previsousValue) => {
            return {
              ...previsousValue,
              developersComment: feasibility.developersComment,
              collective: feasibility.collective,
              veb: data.veb,
              demeureDeLouise: feasibility.demeuresDeLouise,
              office: feasibility.office,
              businessPark: feasibility.businessPark,
              buildingLot: feasibility.buildingLot,
              house: feasibility.house,
              semiCollective: feasibility.semiCollective,
              cashflow: feasibility.cashflow,
              marginPercentage: feasibility.marginPercentage,
            }
          })
          setCurrentId(feasibility.id)
          queryClient.invalidateQueries({
            queryKey: ["listStudyRequests", id],
          })
          redirectToStudies
            ? (toast.success(t("draft-saved")),
              navigate(`/${routeName}/${id}/studies`))
            : navigate(
                `/${routeName}/${id}/studies/feasibility/new/${feasibility.id}/documents`,
              )
        },
        onError(error) {
          toast.error(error.response?.data.error.message)
        },
      },
    )
  }

  return (
    <>
      <DivStickyMenu />
      <div className="px-4 sm:px-16">
        <form>
          <div className="lg:grid rid-rows-1 lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense md:pt-8 pb-8">
            <div className="col-span-2 pt-4">
              <p className="Color__Primary font-semibold text-lg">
                {`${t("batches-number")}`}
              </p>
            </div>
            <div className="col-span-2">
              <Box classNames="flex flex-col Text__Field_Container">
                <div>
                  <label htmlFor="collective">
                    {`${t("collective")}`}
                    <input
                      id="collective"
                      className="Text__Field"
                      type="number"
                      placeholder={`${t("batches-number")}`}
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("collective")}
                    />
                  </label>
                  {errors.collective && (
                    <span className="text-red-500 text-xs">
                      {errors.collective.message}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="veb">
                    {`${t("veb")}`}
                    <input
                      id="veb"
                      className="Text__Field"
                      type="number"
                      placeholder={`${t("batches-number")}`}
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("veb")}
                    />
                  </label>
                  {errors.veb && (
                    <span className="text-red-500 text-xs">
                      {errors.veb.message}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="demeuresDeLouise">
                    {`${t("demeure-de-louise")}`}
                    <input
                      id="demeuresDeLouise"
                      type="number"
                      className="Text__Field"
                      placeholder={`${t("batches-number")}`}
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("demeuresDeLouise")}
                    />
                  </label>
                  {errors.demeuresDeLouise && (
                    <span className="text-red-500 text-xs">
                      {errors.demeuresDeLouise.message}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="offices">
                    {`${t("offices")}`}
                    <input
                      id="offices"
                      type="number"
                      className="Text__Field"
                      placeholder={`${t("batches-number")}`}
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("office")}
                    />
                  </label>
                  {errors.office && (
                    <span className="text-red-500 text-xs">
                      {errors.office.message}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="businessPark">
                    {`${t("business-park")}`}
                    <input
                      id="businessPark"
                      type="number"
                      className="Text__Field"
                      placeholder={`${t("batches-number")}`}
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("businessPark")}
                    />
                  </label>
                  {errors.businessPark && (
                    <span className="text-red-500 text-xs">
                      {errors.businessPark.message}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="buildingLot">
                    {`${t("building-lot")}`}
                    <input
                      id="buildingLot"
                      type="number"
                      className="Text__Field"
                      placeholder={`${t("batches-number")}`}
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("buildingLot")}
                    />
                  </label>
                  {errors.buildingLot && (
                    <span className="text-red-500 text-xs">
                      {errors.buildingLot.message}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="house">
                    {`${t("houses")}`}
                    <input
                      id="house"
                      type="number"
                      className="Text__Field"
                      placeholder={`${t("batches-number")}`}
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("house")}
                    />
                  </label>
                  {errors.house && (
                    <span className="text-red-500 text-xs">
                      {errors.house.message}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="semiCollective">
                    {`${t("semi-collective")}`}
                    <input
                      id="semiCollective"
                      type="number"
                      className="Text__Field"
                      placeholder={`${t("batches-number")}`}
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("semiCollective")}
                    />
                  </label>
                  {errors.semiCollective && (
                    <span className="text-red-500 text-xs">
                      {errors.semiCollective.message}
                    </span>
                  )}
                </div>

                <p className="mt-4">{`${t(
                  "studyRequest.feasibility.totalLabel",
                )}`}</p>
                <p className="Color__Primary">
                  {!Number.isNaN(total) ? total : 0}
                </p>
              </Box>
            </div>
          </div>

          <div className="lg:grid grid-rows-1 lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-8 pb-8">
            <div className="col-span-2 pt-4">
              <p className="Color__Primary font-semibold text-lg">
                {`${t("margin-percent")}`}
              </p>
            </div>
            <div className="col-span-2">
              <Box classNames="flex flex-col Text__Field_Container">
                <label htmlFor="marginPercentage">
                  {`${t("margin-percent")} *`}
                  <input
                    id="marginPercentage"
                    type="number"
                    className="Text__Field"
                    onWheel={(event) => event.currentTarget.blur()}
                    min="0"
                    {...register("marginPercentage")}
                  />
                </label>
                {errors.marginPercentage && (
                  <span className="text-red-500 text-xs">
                    {errors.marginPercentage.message}
                  </span>
                )}
              </Box>
            </div>
          </div>

          <div className="lg:grid grid-rows-1 lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-8 pb-8">
            <div className="col-span-2 pt-4">
              <p className="Color__Primary font-semibold text-lg">
                {`${t("cashflow")}`}
              </p>
            </div>
            <div className="col-span-2">
              <Box classNames="flex flex-col Text__Field_Container">
                <label htmlFor="cashflow">
                  {`${t("cashflow")}`}
                  <input
                    id="cashflow"
                    type="number"
                    className="Text__Field"
                    onWheel={(e) => e.currentTarget.blur()}
                    {...register("cashflow")}
                  />
                </label>
                {errors.cashflow && (
                  <span className="text-red-500 text-xs">
                    {errors.cashflow.message}
                  </span>
                )}
              </Box>
            </div>
          </div>

          <div className="divide-y divide-slate-100">
            <div className="lg:grid rid-rows-1 lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense md:pt-8 pb-8">
              <div className="col-span-2 pt-4">
                <p className="Color__Primary font-semibold text-lg">
                  {`${t("comment")}`}
                </p>
                <p className="Color__Primary mt-2 text-sm mb-4 md:mb-0">
                  {`${t("comment-helper-message-study-request")}`}
                </p>
              </div>
              <div className="col-span-2">
                <Box classNames="flex flex-col Text__Field_Container">
                  <label htmlFor="developersComment">
                    {`${t("comment")} *`}
                    <textarea
                      id="developersComment"
                      rows={15}
                      className="Textarea__Field"
                      {...register("developersComment")}
                    />
                  </label>
                  {errors.developersComment && (
                    <span className="text-red-500 text-xs">
                      {errors.developersComment.message}
                    </span>
                  )}
                </Box>

                {totalError ? (
                  <Box classNames="flex flex-col Text__Field_Container mt-2">
                    <span className="text-red-500 text-xs">
                      {`${t("studyRequest.feasibility.totalErrorMessage")}`}
                    </span>
                  </Box>
                ) : null}
              </div>
            </div>
          </div>
        </form>
        {userIsCreatorOrAdmin ? (
          <div className="flex items-center flex-row-reverse justify-between pt-4 pb-8">
            <div>
              <Button
                isLoading={updateFeasibility.isPending}
                onClick={handleSubmit((data) => onSubmit(data, true))}
                size="small"
                mode="secondary"
              >
                {`${t("save-draft")}`}
              </Button>
              <Button
                isLoading={updateFeasibility.isPending}
                onClick={handleSubmit((data) => onSubmit(data))}
                marginLeft={12}
                size="small"
                mode="primary"
              >
                {`${t("add-documents")}`}
              </Button>
            </div>
            <div>
              {studyRequestId ? (
                <DeleteStudyRequestDraft
                  isDeleteModalShowed={isDeleteModalShowed}
                  toggleDeleteModal={toggleDeleteModal}
                  routeName={routeName}
                  isPending={updateFeasibility.isPending}
                  studyRequestType={t("this-feasibility")}
                />
              ) : (
                <Button
                  size="medium"
                  mode="danger"
                  onClick={() => navigate(`/${routeName}/${id}/studies`)}
                >
                  {`${t("delete-draft")}`}
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}
