import classNames from "classnames"
import ItemsCounter from "components/Counter/ItemsCounter"
import DateFormat from "components/Date/DateFormat"
import ArrowLeft from "components/Icons/ArrowLeft"
import { LinkCustom } from "components/Link/Link"
import LightTable from "components/Table/LightTable"
import { AuthContext } from "core/auth/AuthProvider"
import { useListSubordinates } from "core/query-hooks/useUsers"
import { format } from "date-fns"
import useCountTeamTodos from "features/count-todos/count-team-todos/useCountTeamTodos"
import ExpectedTodoToggleCell from "features/expected-tasks/ExpectedTodoToggleCell"
import { t } from "i18next"
import { Fragment, useContext } from "react"
import { BiTask } from "react-icons/bi"
import { Link, useNavigate } from "react-router-dom"
import EditActionTodos from "./EditActionTodos"

const headers = [
  "",
  t("creation-date"),
  `${t("project-title")}/${t("operation")}`,
  t("action"),
  t("creator"),
  t("personInChargeHeader"),
  t("deadline"),
  "",
]

const sortableFields: { key: string; value: string[] }[] = [
  { key: `${t("creation-date")}`, value: ["createdAt"] },
  {
    key: `${t("project-title")}/${t("operation")}`,
    value: ["project", "label"],
  },
  { key: `${t("action")}`, value: ["description"] },
  { key: `${t("creator")}`, value: ["creator"] },
  { key: `${t("personInChargeHeader")}`, value: ["personInCharge"] },
  { key: `${t("deadline")}`, value: ["deadline"] },
]

export default function TeamExpectedTodos() {
  const navigate = useNavigate()
  const {
    error,
    isLoading,
    todos,
    lateTodos,
    stringifiedCurrentDate,
    stringifiedFutureDate,
    countIncomingTodos,
  } = useCountTeamTodos()

  const { getUserInfo } = useContext(AuthContext)
  const userInfo = getUserInfo()
  const { data: subordinatesData } = useListSubordinates(userInfo.azureId)

  return (
    <div className="lg:grid lg:grid-cols-6 lg:gap-4 px-8 py-6">
      {subordinatesData?.subordinates &&
      subordinatesData.subordinates.length > 0 ? (
        <>
          <div className="w-full">
            <LinkCustom
              icon={ArrowLeft}
              iconSize={16}
              iconPosition="left"
              onClick={() => navigate(`/`)}
            >
              {t("back-to-home")}
            </LinkCustom>
          </div>
          <div className="col-start-6 col-span-1">
            <ItemsCounter
              totalLateItems={lateTodos ? lateTodos.length : 0}
              totalItems={countIncomingTodos ?? 0}
              title="alvis"
              isLoading={isLoading}
              error={error}
              errorMessage={t("error-loading-todos")}
              Icon={<BiTask fontSize={18} className="mr-1" />}
            />
          </div>
          <h2 className="col-span-6 font-semibold">
            {t("my-team-expectations")}
          </h2>
          <div className="col-span-6 Light_Table__Wrapper Table__Wrapper_NoTop">
            <LightTable
              showTotal={false}
              className="table-auto"
              items={todos}
              headers={headers}
              sortableFields={sortableFields}
              renderRow={(item, index) => (
                <Fragment key={item.id}>
                  <td
                    className={classNames({
                      "border-t border-gray-200": index === 0,
                      Table__Bottom_Border: todos.length - 1 === index,
                    })}
                  >
                    <div className="flex items-center">
                      <ExpectedTodoToggleCell
                        queryKey="getTeamTodos"
                        todo={item}
                        stringifiedCurrentDate={stringifiedCurrentDate}
                        stringifiedFutureDate={stringifiedFutureDate}
                      />
                    </div>
                  </td>
                  <td
                    className={classNames({
                      "border-t border-gray-200": index === 0,
                      Table__Bottom_Border: todos.length - 1 === index,
                    })}
                  >
                    <div className="flex items-center">
                      {format(new Date(item.createdAt), "dd/MM/yyyy")}
                    </div>
                  </td>
                  <td
                    className={classNames({
                      "border-t border-gray-200": index === 0,
                      Table__Bottom_Border: todos.length - 1 === index,
                    })}
                  >
                    <div className="flex items-center">
                      <Link
                        to={
                          item.project.isRegistered
                            ? `/operation/${item.project.id}`
                            : `/project/${item.project.id}`
                        }
                        className="text-blue-600"
                      >
                        {item.project.label}
                      </Link>
                    </div>
                  </td>
                  <td
                    className={classNames({
                      "border-t border-gray-200": index === 0,
                      Table__Bottom_Border: todos.length - 1 === index,
                    })}
                  >
                    <div className="flex items-center">{item.description}</div>
                  </td>
                  <td
                    className={classNames({
                      "border-t border-gray-200": index === 0,
                      Table__Bottom_Border: todos.length - 1 === index,
                    })}
                  >
                    <div className="flex items-center">{item.creator}</div>
                  </td>
                  <td
                    className={classNames({
                      "border-t border-gray-200": index === 0,
                      Table__Bottom_Border: todos.length - 1 === index,
                    })}
                  >
                    <div className="flex items-center">
                      {item.personInCharge}
                    </div>
                  </td>
                  <td
                    className={classNames({
                      "border-t border-gray-200": index === 0,
                      Table__Bottom_Border: todos.length - 1 === index,
                    })}
                  >
                    <div className="flex items-center">
                      <DateFormat
                        date={item.deadline}
                        isDefinitive={item.isDefinitive}
                      />
                    </div>
                  </td>
                  <td
                    className={classNames({
                      "border-t border-gray-200": index === 0,
                      Table__Bottom_Border: todos.length - 1 === index,
                    })}
                  >
                    <div className="flex items-center">
                      {item.isAction ? (
                        <EditActionTodos actionId={item.id} />
                      ) : null}
                    </div>
                  </td>
                </Fragment>
              )}
            />
          </div>
        </>
      ) : null}
    </div>
  )
}
