import { useMutation, useQuery } from "@tanstack/react-query"
import {
  addFeasibilityDocuments,
  createFeasibility,
  getFeasibility,
  getFeasibilityDocuments,
  getFeasibilityGedDocuments,
  getFeasibilityHistory,
  listFeasibilities,
  listFeasibilitiesStudies,
  updateFeasibility,
} from "core/api/feasabilities"

import { CustomAxiosError } from "shared/types/axios.type"
import {
  TFeasibility,
  TMutateFeasibilityDocuments,
  TStudyFeasibilityDashboard,
} from "shared/types/feasibility.type"
import { TDocumentCategory, TGedDocuments } from "shared/types/ged.type"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"

function useCreateFeasibility() {
  return useMutation<TFeasibility, CustomAxiosError, TFeasibility>({
    mutationFn: (data) => createFeasibility(data),
  })
}

function useUpdateFeasibility(id: number) {
  return useMutation<TFeasibility, CustomAxiosError, TFeasibility>({
    mutationFn: (data) => updateFeasibility(id, data),
  })
}

function useAddFeasibilityDocuments(id: number) {
  return useMutation<
    TMutateFeasibilityDocuments,
    CustomAxiosError,
    TMutateFeasibilityDocuments
  >({ mutationFn: (data) => addFeasibilityDocuments(id, data) })
}

function useGetFeasibilityDocuments(id: number) {
  return useQuery<unknown, CustomAxiosError, TDocumentCategory[]>({
    queryKey: ["getFeasibilityDocuments", id],
    queryFn: () => getFeasibilityDocuments(id),
  })
}

function useGetFeasibilityGedDocuments(id?: string) {
  return useQuery<unknown, CustomAxiosError, TGedDocuments>({
    queryKey: ["getFeasibilityGedDocuments"],
    queryFn: () => getFeasibilityGedDocuments(id),
  })
}

function useGetFeasibility(id: number) {
  return useQuery<TStudyRequest, CustomAxiosError>({
    queryKey: ["getFeasibility", id],
    queryFn: () => getFeasibility(id),
  })
}

function useGetFeasibilityHistory(id: number) {
  return useQuery<TStudyRequestHistory[], CustomAxiosError>({
    queryKey: ["getFeasibilityHistory", id],
    queryFn: () => getFeasibilityHistory(id),
  })
}

function useListFeasibilities(projectId: number) {
  return useQuery<TFeasibility[], CustomAxiosError>({
    queryKey: ["listFeasibilities", projectId],
    queryFn: () => listFeasibilities(projectId),
  })
}

function useListFeasibilitiesStudies(showAllReturned: boolean) {
  return useQuery<unknown, CustomAxiosError, TStudyFeasibilityDashboard>({
    queryKey: ["listFeasabilitiesStudies", showAllReturned],
    queryFn: () => listFeasibilitiesStudies(showAllReturned),
  })
}

export {
  useAddFeasibilityDocuments,
  useCreateFeasibility,
  useGetFeasibility,
  useGetFeasibilityDocuments,
  useGetFeasibilityGedDocuments,
  useGetFeasibilityHistory,
  useListFeasibilities,
  useListFeasibilitiesStudies,
  useUpdateFeasibility,
}
