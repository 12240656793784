import classNames from "classnames"
import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import {
  useGetOperationById,
  useGetOperationStepHistory,
} from "core/query-hooks/useOperations"
import { useSteps } from "core/query-hooks/useSteps"
import { t } from "i18next"
import { RiRouteFill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import {
  STATUS_ABANDONMENT,
  STATUS_ABANDONMENT_ON_GOING,
  STATUS_ASSEMBLY,
  STATUS_NOT_ACTED,
  STATUS_ON_DELIVERY,
  STATUS_ON_GOING,
  STATUS_STANDBY,
  STATUS_WAITING_ASSEMBLY,
} from "shared/resources/operation.resources"
import useIsMobile from "utils/isMobile"
import { DisplayCircleStep } from "./DisplayCircleStep"
import "./operation-stepper.scss"

export interface IOperationStep {
  className: string
}

export function OperationStep(props: IOperationStep) {
  const { className } = props
  const params = useParams()
  const isMobile = useIsMobile()
  const { data: stepsData, isLoading: isLoadingStepsData } = useSteps()
  const {
    data: operationData,
    isLoading,
    error,
  } = useGetOperationById(+params.id!)

  const { data: stepHistory, isLoading: isLoadingStepHistory } =
    useGetOperationStepHistory(+params.id!)

  function getAbandonnedStep(): number {
    if (!stepHistory) return STATUS_STANDBY

    const previousId = stepHistory.find(
      (step) =>
        step.stepId !== STATUS_ABANDONMENT &&
        step.stepId !== STATUS_ABANDONMENT_ON_GOING,
    )?.stepId

    return previousId ?? STATUS_STANDBY
  }

  // Retourne l'id du statut en cours
  // Ou l'id du statut de l'opération avant son passage au statut abandonné
  // Ou l'id du statut stand-by si l'historique ne comporte que le changement au statut abandonné
  function getStep(stepId: number): number {
    if (stepId === STATUS_ABANDONMENT) {
      return getAbandonnedStep()
    }

    if (stepId === STATUS_ABANDONMENT_ON_GOING) {
      return getAbandonnedStep()
    }

    return stepId
  }

  function displayLabel(statusToCompare: number, stepId: number) {
    if (stepId === STATUS_ABANDONMENT && getStep(stepId) === statusToCompare)
      return stepsData?.find((step) => step.id === STATUS_ABANDONMENT)?.label
    if (
      stepId === STATUS_ABANDONMENT_ON_GOING &&
      getStep(stepId) === statusToCompare
    )
      return stepsData?.find((step) => step.id === STATUS_ABANDONMENT_ON_GOING)
        ?.label
    return stepsData?.find((step) => step.id === statusToCompare)?.label
  }

  return (
    <Box classNames={classNames("operation-stepper p-0", className)}>
      {(isLoading || isLoadingStepsData || isLoadingStepHistory) && <Spinner />}
      {operationData &&
        operationData?.operationBase?.step.id &&
        stepsData &&
        stepHistory && (
          <>
            <div
              className={classNames(
                "flex items-center border-b border-gray-200 px-3.5 py-2",
                isMobile ? "mb-4" : "",
              )}
            >
              <RiRouteFill size={20} className="text-cyan-900 mr-1" />
              <h3 className="text-xs uppercase text-cyan-900 font-medium">
                {`${t("steps")}`}
              </h3>
            </div>
            <div className="h-4/5 flex justify-center items-center">
              <div className="px-6 pb-4 w-full">
                {operationData?.operationBase?.step.id >=
                  STATUS_ABANDONMENT && (
                  <ol className="flex w-full items-center">
                    <li
                      className={classNames(
                        "operation-stepper-step flex items-center w-full",
                        {
                          "operation-stepper-validated":
                            operationData.operationBase.step.id >
                              STATUS_STANDBY &&
                            operationData.operationBase.step.id !==
                              STATUS_ABANDONMENT_ON_GOING,
                          "operation-stepper-future":
                            operationData.operationBase.step.id <=
                            STATUS_STANDBY,
                          "operation-stepper-abandoned":
                            (operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                              operationData.operationBase.step.id ===
                                STATUS_ABANDONMENT_ON_GOING) &&
                            getStep(operationData.operationBase.step.id) >
                              STATUS_STANDBY,
                        },
                      )}
                    >
                      <span className="relative">
                        <DisplayCircleStep
                          stepId={getStep(operationData.operationBase.step.id)}
                          statusToCompare={STATUS_STANDBY}
                          steps={stepsData}
                          isAbandoned={
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT_ON_GOING
                          }
                        />
                        <p
                          className={classNames(
                            "hidden md:block",
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT_ON_GOING
                              ? "long-operation-stepper-label"
                              : "operation-stepper-label",
                          )}
                        >
                          {displayLabel(
                            STATUS_STANDBY,
                            operationData.operationBase.step.id,
                          )}
                        </p>
                      </span>
                    </li>
                    <li
                      className={classNames(
                        "operation-stepper-step flex items-center w-full",
                        {
                          "operation-stepper-validated":
                            operationData.operationBase.step.id >
                              STATUS_WAITING_ASSEMBLY &&
                            operationData.operationBase.step.id !==
                              STATUS_ABANDONMENT_ON_GOING,
                          "operation-stepper-future":
                            operationData.operationBase.step.id <=
                            STATUS_WAITING_ASSEMBLY,
                          "operation-stepper-abandoned":
                            (operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                              operationData.operationBase.step.id ===
                                STATUS_ABANDONMENT_ON_GOING) &&
                            getStep(operationData.operationBase.step.id) >
                              STATUS_WAITING_ASSEMBLY,
                        },
                      )}
                    >
                      <span className="relative">
                        <DisplayCircleStep
                          stepId={getStep(operationData.operationBase.step.id)}
                          statusToCompare={STATUS_WAITING_ASSEMBLY}
                          steps={stepsData}
                          isAbandoned={
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT_ON_GOING
                          }
                        />
                        <p className="operation-stepper-label hidden md:block">
                          {displayLabel(
                            STATUS_WAITING_ASSEMBLY,
                            operationData.operationBase.step.id,
                          )}
                        </p>
                      </span>
                    </li>
                    <li
                      className={classNames(
                        "operation-stepper-step flex items-center w-full",
                        {
                          "operation-stepper-validated":
                            operationData.operationBase.step.id >
                              STATUS_ASSEMBLY &&
                            operationData.operationBase.step.id !==
                              STATUS_ABANDONMENT_ON_GOING,
                          "operation-stepper-future":
                            operationData.operationBase.step.id <=
                            STATUS_ASSEMBLY,
                          "operation-stepper-abandoned":
                            (operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                              operationData.operationBase.step.id ===
                                STATUS_ABANDONMENT_ON_GOING) &&
                            getStep(operationData.operationBase.step.id) >
                              STATUS_ASSEMBLY,
                        },
                      )}
                    >
                      <span className="relative">
                        <DisplayCircleStep
                          stepId={getStep(operationData.operationBase.step.id)}
                          statusToCompare={STATUS_ASSEMBLY}
                          steps={stepsData}
                          isAbandoned={
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT_ON_GOING
                          }
                        />
                        <p className="operation-stepper-label hidden md:block">
                          {displayLabel(
                            STATUS_ASSEMBLY,
                            operationData.operationBase.step.id,
                          )}
                        </p>
                      </span>
                    </li>
                    <li
                      className={classNames(
                        "operation-stepper-step flex items-center w-full",
                        {
                          "operation-stepper-validated":
                            operationData.operationBase.step.id >
                              STATUS_NOT_ACTED &&
                            operationData.operationBase.step.id !==
                              STATUS_ABANDONMENT_ON_GOING,
                          "operation-stepper-future":
                            operationData.operationBase.step.id <=
                            STATUS_NOT_ACTED,
                          "operation-stepper-abandoned":
                            (operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                              operationData.operationBase.step.id ===
                                STATUS_ABANDONMENT_ON_GOING) &&
                            getStep(operationData.operationBase.step.id) >
                              STATUS_NOT_ACTED,
                        },
                      )}
                    >
                      <span className="relative">
                        <DisplayCircleStep
                          stepId={getStep(operationData.operationBase.step.id)}
                          statusToCompare={STATUS_NOT_ACTED}
                          steps={stepsData}
                          isAbandoned={
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT_ON_GOING
                          }
                        />
                        <p className="operation-stepper-label hidden md:block">
                          {displayLabel(
                            STATUS_NOT_ACTED,
                            operationData.operationBase.step.id,
                          )}
                        </p>
                      </span>
                    </li>
                    <li
                      className={classNames(
                        "operation-stepper-step flex items-center w-full",
                        {
                          "operation-stepper-validated":
                            operationData.operationBase.step.id >
                              STATUS_ON_GOING &&
                            operationData.operationBase.step.id !==
                              STATUS_ABANDONMENT_ON_GOING,
                          "operation-stepper-future":
                            operationData.operationBase.step.id <=
                            STATUS_ON_GOING,
                          "operation-stepper-abandoned":
                            (operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                              operationData.operationBase.step.id ===
                                STATUS_ABANDONMENT_ON_GOING) &&
                            getStep(operationData.operationBase.step.id) >
                              STATUS_ON_GOING,
                        },
                      )}
                    >
                      <span className="relative">
                        <DisplayCircleStep
                          stepId={getStep(operationData.operationBase.step.id)}
                          statusToCompare={STATUS_ON_GOING}
                          steps={stepsData}
                          isAbandoned={
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT_ON_GOING
                          }
                        />
                        <p className="operation-stepper-label hidden md:block">
                          {displayLabel(
                            STATUS_ON_GOING,
                            operationData.operationBase.step.id,
                          )}
                        </p>
                      </span>
                    </li>
                    <li className="operation-stepper-step flex items-center">
                      <span className="relative">
                        <DisplayCircleStep
                          stepId={getStep(operationData.operationBase.step.id)}
                          statusToCompare={STATUS_ON_DELIVERY}
                          steps={stepsData}
                          isAbandoned={
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT ||
                            operationData.operationBase.step.id ===
                              STATUS_ABANDONMENT_ON_GOING
                          }
                        />
                        <p className="operation-stepper-label hidden md:block">
                          {displayLabel(
                            STATUS_ON_DELIVERY,
                            operationData.operationBase.step.id,
                          )}
                        </p>
                      </span>
                    </li>
                  </ol>
                )}
              </div>
            </div>
          </>
        )}
      {error && (
        <p className="text-red-600 text-sm">
          {error.code} {error.message}
        </p>
      )}
    </Box>
  )
}
