import { TExcelLink } from "shared/types/excel-link.type"
import { API } from "./axios"

export async function listExcelLinks(
  code: string,
  businessUnitId?: string,
): Promise<TExcelLink[]> {
  const env = process.env.REACT_APP_ENV
  const res = await API.get(
    `/excel-links?environment=${env}&code=${code}${businessUnitId ? `&businessUnitId=${businessUnitId}` : ""}`,
  )
  return res.data
}

export default {
  listExcelLinks,
}
