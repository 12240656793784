import { AxiosResponse, InternalAxiosRequestConfig } from "axios"
import { AANG_API } from "core/api/axios"
import getLocalStorageToken from "core/api/token"
import { signOut } from "firebase/auth"
import { ReactNode, useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { toast } from "react-toastify"
import { CustomAxiosError } from "shared/types/axios.type"
import { auth } from "utils/firebase"

interface Props {
  children: ReactNode
}

export default function AangAxiosInterceptor({ children }: Props) {
  const [user] = useAuthState(auth)
  const [interceptorIsMounted, setInterceptorIsMounted] = useState(false)

  useEffect(() => {
    const onRequest = (
      config: InternalAxiosRequestConfig,
    ): InternalAxiosRequestConfig => {
      const token = getLocalStorageToken()

      const modifiedConfig = { ...config }
      if (modifiedConfig.headers && token) {
        modifiedConfig.headers.Authorization = `Bearer ${JSON.parse(token)}`
      }
      return modifiedConfig
    }

    const onRequestError = (
      error: CustomAxiosError,
    ): Promise<CustomAxiosError> => {
      return Promise.reject(error)
    }

    const resInterceptor = (response: AxiosResponse) => {
      return response
    }

    async function errInterceptor(error: CustomAxiosError) {
      if (error.response?.status === 401) {
        if (user) {
          try {
            const res = await user.getIdTokenResult()
            localStorage.setItem("token", JSON.stringify(res.token))

            if (res.claims.sub) {
              return localStorage.setItem("firebaseId", res.claims.sub)
            }
          } catch (error_auth) {
            toast.error("Impossible de s'authentifier")
            localStorage.removeItem("token")
            localStorage.removeItem("firebaseId")
            signOut(auth)
          }
        }
      }

      return Promise.reject(error)
    }

    const interceptorRequest = AANG_API.interceptors.request.use(
      onRequest,
      onRequestError,
    )

    const interceptorResponse = AANG_API.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    )

    setInterceptorIsMounted(true)

    return () => {
      AANG_API.interceptors.request.eject(interceptorRequest)
      AANG_API.interceptors.response.eject(interceptorResponse)
    }
  }, [user])

  return <>{interceptorIsMounted && children}</>
}
