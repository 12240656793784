import { t } from "i18next"
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import ReactDOM from "react-dom"
import { MdClose, MdSearch } from "react-icons/md"
import { useDebouncedCallback } from "use-debounce"
import { useMediaQuery, useOnClickOutside } from "usehooks-ts"
import "./GlobalModal.scss"
import GlobalSearchResults from "./GlobalSearchResults"

export interface GlobalSearchModalProps {
  isShowing: Boolean
  closeModal: () => void
}

export function GlobalSearchModal({
  closeModal,
  isShowing,
}: GlobalSearchModalProps) {
  const ref = useRef(null)
  const [searchText, setSearchText] = useState("")
  const isMobile = useMediaQuery("(max-width: 768px)")
  const onHandleSearch = useDebouncedCallback(
    useCallback(
      (value: string) => {
        setSearchText(value)
      },
      [searchText],
    ),
    50,
  )

  useOnClickOutside(ref, () => {
    closeModal()
    setSearchText("")
  })

  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { key } = event

      if (key === "Escape") {
        closeModal()
        setSearchText("")
      }
    }

    window.addEventListener("keydown", handleUserKeyPress)
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress)
    }
  }, [])

  useLayoutEffect(() => {
    isShowing && (document.body.style.overflow = "hidden")
    return () => {
      document.body.style.overflow = "visible"
    }
  }, [])

  return isShowing
    ? ReactDOM.createPortal(
        <div className="Global_Search_Modal__Overlay">
          <div className="Global_Search_Modal__Wrapper">
            <div
              ref={ref}
              className="Global_Search_Modal__Container overflow-hidden md:rounded-lg"
            >
              <div className="relative">
                <input
                  autoFocus
                  maxLength={30}
                  onChange={(e) => onHandleSearch(e.target.value)}
                  className={`caret-white outline Background__Primary w-full pb-4 pt-4 pl-12 outline-0 text-white ${
                    isMobile ? "text-sm" : "text-lg"
                  }`}
                  type="text"
                  placeholder={`${t("search-project-op")}`}
                />
                <div className="Global_Search_Modal__ESC absolute flex">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="text-xs uppercase Global_Search_Modal__Button"
                  >
                    <span className="text-white opacity-1 hidden md:block">
                      {`${t("globalSearch.escButton")}`}
                    </span>
                    <span className="text-white opacity-1 block md:hidden">
                      <MdClose size={22} />
                    </span>
                  </button>
                </div>
                <MdSearch color="white" className="absolute Search__Icon" />
              </div>
              <div className="Global_Search_Modal__Body">
                {searchText ? (
                  <GlobalSearchResults
                    onClickProject={() => {
                      setSearchText("")
                      closeModal()
                    }}
                    onClickOperation={() => {
                      setSearchText("")
                      closeModal()
                    }}
                    searchQuery={searchText}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null
}
