// Ajoute un 0 avant les premiers chiffres,
// depuis un string ou un number, renvoie un String (c'est pour de l'affichage)
function addLeadingZero(input: string | number): string {
  let number: number

  if (typeof input === "string") {
    number = parseInt(input, 10)
  } else if (typeof input === "number") {
    number = input
  } else {
    return ""
  }

  if (!Number.isNaN(number) && number >= 0 && number <= 9) {
    return `0${number}`
  }
  return input.toString()
}
// Obtiens l'id secteur (département) en number depuis le Code postal en string
function getDepartmentFromPostalCode(cp: string | undefined): number {
  if (typeof cp === "string" && cp.length >= 2 && /^\d+$/.test(cp)) {
    return parseInt(cp.substring(0, 2), 10)
  }
  return 0
}

export { addLeadingZero, getDepartmentFromPostalCode }
