import classnames from "classnames"
import { PropsWithChildren } from "react"
import "./Label.scss"

interface IChipProps extends PropsWithChildren {
  label: string
  labelColor: string
  backgroundColor: string
  classNames?: string
}

export default function Label(props: IChipProps) {
  const { label, labelColor, backgroundColor, children, classNames } = props
  return (
    <div>
      <span
        className={classnames(
          "chip w-fit rounded-full p-1 flex items-center justify-center",
          backgroundColor,
          classNames,
        )}
      >
        {children}
        <p className={classnames("chip-label", labelColor)}>{label}</p>
      </span>
    </div>
  )
}
