import { IIconType } from "shared/types/global.type"

function SpoLogo({ width = 16, height = 16 }: IIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 209.951 281.675"
      width={width}
      height={height}
    >
      <g
        style={{
          fill: "#000",
          stroke: "none",
        }}
      >
        <path
          d="M1290 2899c-134-19-215-36-257-55-17-8-36-14-41-14-9 0-74-30-112-51-8-5-30-16-47-25-18-9-33-20-33-24 0-5-9-12-20-15-54-17-240-240-240-288 0-7-3-17-7-21-10-10-33-76-46-131-14-62-14-306 0-360 46-180 165-337 303-401 71-33 72-33 190-55 62-12 178-6 230 12 87 30 135 53 164 81 11 10 25 18 31 18 14 0 210 198 203 205-3 4-28-5-54-18-131-66-227-64-376 7-63 30-168 141-168 178 0 9-6 21-14 27-14 12-23 47-33 135-5 50 7 107 33 159 8 16 14 33 14 38 0 29 120 143 178 170 121 56 161 64 327 64 169 0 213-9 325-63 57-27 95-56 158-119 45-45 82-86 82-92 0-5 6-16 13-23s23-29 36-48c35-51 102-119 133-136 34-17 150-19 181-1 37 20 77 60 92 91 20 39 19 178-1 236-18 51-55 123-66 128-5 2-8 9-8 16 0 22-132 147-199 190-60 38-153 86-206 106-68 26-113 40-131 40-11 0-24 4-30 9-5 5-29 11-54 14s-81 10-125 16c-87 12-337 12-425 0z"
          style={{
            fill: "#3b3b3b",
            fillOpacity: 1,
          }}
          transform="matrix(.1 0 0 -.1 -45 290.8)"
        />
        <path
          d="M1820 1530c-8-5-25-10-37-10-24 0-145-60-169-84-8-9-20-16-25-16-14 0-189-176-189-190 0-7 20 0 46 14s55 26 65 26 21 5 24 10c3 6 44 10 90 10s87-4 90-10c3-5 14-10 23-10 33 0 128-56 177-105s91-122 115-201c12-39 10-116-5-179-23-99-114-210-210-254-27-13-58-27-67-32-10-5-26-9-36-9s-24-6-30-12c-18-18-376-18-394-1-6 7-22 13-34 13-69 0-271 148-325 238-13 20-26 39-29 42-4 3-20 25-37 50-33 48-93 107-130 128-13 6-53 12-90 12-57-1-74-5-107-28-63-44-86-97-86-197 0-46 5-87 10-90 6-3 10-14 10-25 0-20 47-117 67-138 7-7 13-16 13-20 0-15 98-111 155-153 83-61 142-93 240-129 11-4 34-13 50-20 45-19 119-35 275-59 80-13 356-13 442 0 151 22 228 40 291 68 27 11 55 21 63 21s14 5 14 10c0 6 7 10 16 10 27 0 167 97 220 152 52 54 110 134 131 178 69 152 73 169 80 340 6 161-3 207-68 345-66 141-182 247-309 284-25 7-59 17-76 22-40 12-235 11-254-1z"
          style={{
            fill: "#cd1c1a",
            fillOpacity: 1,
          }}
          transform="matrix(.1 0 0 -.1 -45 290.8)"
        />
      </g>
    </svg>
  )
}
export default SpoLogo
