import { API } from "core/api/axios"
import { TOperationsList } from "shared/types/operation.type"
import {
  TIsOperationFrozen,
  TSpoOperation,
} from "shared/types/spo/operation.type"
import SPO_API from "../axios"

async function searchOperations(query: string): Promise<TOperationsList> {
  const res = await API.get(`/operations?search=${query}`)
  return res.data
}

async function getOperationFromSpo(
  registrationNumber: string,
): Promise<TSpoOperation> {
  const res = await SPO_API.get(`/operation/${registrationNumber}`)
  return res.data
}

async function isOperationFrozen(
  registrationNumber: string,
): Promise<TIsOperationFrozen> {
  const res = await SPO_API.get(`/operation/${registrationNumber}/freeze`)
  return res.data
}

export { getOperationFromSpo, isOperationFrozen, searchOperations }
