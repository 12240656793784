import { API } from "core/api/axios"
import { TCreateSavedSeach, TSavedSearch } from "shared/types/saved-search.type"

async function saveSearch(
  savedSearch: TCreateSavedSeach,
): Promise<TSavedSearch> {
  return typeof savedSearch === "undefined"
    ? Promise.reject(new Error("Invalid saved search"))
    : API.post("/users/saved-search", savedSearch).then(
        (response) => response.data,
      )
}

async function listSavedSearches(): Promise<TSavedSearch[]> {
  return API.get(`/users/me/saved-searches`).then((response) => response.data)
}

async function deleteSavedSearch(id: number): Promise<TSavedSearch> {
  const res = await API.delete(`/users/saved-search/${id}`)
  return res.data
}

export { deleteSavedSearch, listSavedSearches, saveSearch }
