import { useGetUserTodos } from "core/query-hooks/useTodos"
import { add, format, isBefore, startOfDay } from "date-fns"

export default function useCountAlfredTodos(paramDate?: string) {
  const today = new Date()
  function getCurrentDate() {
    const formattedDate = format(today, "yyyy-MM-dd")
    return formattedDate
  }

  function getDateAfter30Days() {
    const dateAfter30Days = add(today, { days: 30 })
    const formattedDate = format(dateAfter30Days, "yyyy-MM-dd")
    return formattedDate
  }

  const stringifiedCurrentDate = getCurrentDate()
  const stringifiedFutureDate = getDateAfter30Days()

  const {
    data: todosData,
    isLoading: lateTodosDataIsLoading,
    error: lateTodosDataError,
  } = useGetUserTodos(stringifiedCurrentDate)

  const {
    data: toCurrentMonthTodosData,
    isLoading: currentMonthTodosDataIsLoading,
    error: currentMonthTodosDataError,
  } = useGetUserTodos(stringifiedFutureDate)

  const {
    data: fullTodosData,
    isLoading: isLoadingFullTodosData,
    error: fullTodosDataError,
  } = useGetUserTodos(paramDate ?? stringifiedCurrentDate)

  const isLoading =
    lateTodosDataIsLoading ||
    currentMonthTodosDataIsLoading ||
    isLoadingFullTodosData

  const error =
    lateTodosDataError || currentMonthTodosDataError || fullTodosDataError

  const lateMonthActionsData = todosData?.actions
    .filter(
      (action) =>
        isBefore(new Date(action.deadline), startOfDay(new Date())) &&
        !action.isDefinitive,
    )
    .map((action) => {
      return {
        id: action.id,
        createdAt: action.createdAt,
        isAction: true,
        deadline: action.deadline,
        description: action.description,
        creator: action.creator
          ? `${action.creator.firstName} ${action.creator.lastName}`
          : null,
        isDefinitive: action.isDefinitive,
        project: {
          id: action.project.id,
          label: `${action.project.address}, ${action.project.city} - ${action.project.postcode}`,
          isRegistered: action.project.isRegistered,
        },
        registrationNumber: undefined,
      }
    })

  const lateMonthProgressData = todosData?.progress
    .filter(
      (progress) =>
        isBefore(new Date(progress.deadline), startOfDay(new Date())) &&
        !progress.isDefinitive,
    )
    .map((progress) => {
      return {
        id: progress.id,
        createdAt: progress.createdAt,
        isAction: false,
        deadline: progress.deadline,
        description: progress.description,
        creator: progress.creator
          ? `${progress.creator.firstName} ${progress.creator.lastName}`
          : null,
        isDefinitive: progress.isDefinitive,
        project: {
          id: progress.project.id,
          label: `${progress.project.address}, ${progress.project.city} - ${progress.project.postcode}`,
          isRegistered: progress.project.isRegistered,
        },
        registrationNumber: progress.childOperationRegistrationNumber,
      }
    })

  const currentMonthActionsData = toCurrentMonthTodosData?.actions
    .filter(
      (action) =>
        !action.isDefinitive &&
        !lateMonthActionsData?.some(
          (lateAction) => lateAction.id === action.id,
        ),
    )
    .map((action) => {
      return {
        id: action.id,
        createdAt: action.createdAt,
        isAction: true,
        deadline: action.deadline,
        description: action.description,
        creator: action.creator
          ? `${action.creator.firstName} ${action.creator.lastName}`
          : null,
        isDefinitive: action.isDefinitive,
        project: {
          id: action.project.id,
          label: `${action.project.address}, ${action.project.city} - ${action.project.postcode}`,
          isRegistered: action.project.isRegistered,
        },
        registrationNumber: undefined,
      }
    })

  const currentMonthProgressData = toCurrentMonthTodosData?.progress
    .filter(
      (progress) =>
        !progress.isDefinitive &&
        !lateMonthProgressData?.some(
          (lateProgress) => lateProgress.id === progress.id,
        ),
    )
    .map((progress) => {
      return {
        id: progress.id,
        createdAt: progress.createdAt,
        isAction: false,
        deadline: progress.deadline,
        description: progress.description,
        creator: progress.creator
          ? `${progress.creator.firstName} ${progress.creator.lastName}`
          : null,
        isDefinitive: progress.isDefinitive,
        project: {
          id: progress.project.id,
          label: `${progress.project.address}, ${progress.project.city} - ${progress.project.postcode}`,
          isRegistered: progress.project.isRegistered,
        },
        registrationNumber: progress.childOperationRegistrationNumber,
      }
    })

  const fullActionsData = fullTodosData?.actions
    .filter((action) => !action.isDefinitive)
    .map((action) => {
      return {
        id: action.id,
        createdAt: action.createdAt,
        isAction: true,
        deadline: action.deadline,
        description: action.description,
        creator: action.creator
          ? `${action.creator.firstName} ${action.creator.lastName}`
          : null,
        isDefinitive: action.isDefinitive,
        project: {
          id: action.project.id,
          label: `${action.project.address}, ${action.project.city} - ${action.project.postcode}`,
          isRegistered: action.project.isRegistered,
        },
        registrationNumber: undefined,
      }
    })

  const fullProgressData = fullTodosData?.progress
    .filter((progress) => !progress.isDefinitive)
    .map((progress) => {
      return {
        id: progress.id,
        createdAt: progress.createdAt,
        isAction: false,
        deadline: progress.deadline,
        description: progress.description,
        creator: progress.creator
          ? `${progress.creator.firstName} ${progress.creator.lastName}`
          : null,
        isDefinitive: progress.isDefinitive,
        project: {
          id: progress.project.id,
          label: `${progress.project.address}, ${progress.project.city} - ${progress.project.postcode}`,
          isRegistered: progress.project.isRegistered,
        },
        registrationNumber: progress.childOperationRegistrationNumber,
      }
    })

  const currentTodos = [
    ...(currentMonthActionsData || []),
    ...(currentMonthProgressData || []),
  ]
  const lateTodos = [
    ...(lateMonthActionsData || []),
    ...(lateMonthProgressData || []),
  ]

  const todos = [...(fullActionsData || []), ...(fullProgressData || [])]

  const countIncomingTodos =
    (currentMonthProgressData?.length ?? 0) +
    (currentMonthActionsData?.length ?? 0)

  return {
    isLoading,
    error,
    currentTodos,
    lateTodos,
    stringifiedFutureDate,
    stringifiedCurrentDate,
    todos,
    countIncomingTodos,
  }
}
