import Avatar from "components/Avatar/Avatar"
import { useGetUserByAzureId } from "core/query-hooks/useUsers"

interface ProgressAvatarCellProps {
  userAzureId: string
  isLast: boolean
}

export default function ProgressAvatarCell({
  userAzureId,
  isLast,
}: ProgressAvatarCellProps) {
  const { data: user } = useGetUserByAzureId(userAzureId)

  if (!user) return <p>-</p>

  return (
    <div className="flex items-center justify-center">
      <Avatar
        hasHoverCard
        size="small"
        user={user}
        displayTop={isLast}
        key={user.id}
      />
    </div>
  )
}
