import classNames from "classnames"
import { differenceInCalendarDays, format } from "date-fns"
import "./DateFormat.scss"

interface IDateFormatProps {
  date: Date
  isDefinitive?: boolean
  isDarkMode?: boolean
}

export default function DateFormat({
  isDefinitive = false,
  ...props
}: IDateFormatProps) {
  const { date, isDarkMode } = props

  if (!date) return null

  const differenceInCalendarDaysValue = differenceInCalendarDays(
    new Date(Date.now()),
    new Date(date),
  )

  const formatedDate = format(new Date(date), "dd/MM/yyyy")

  return (
    <span
      className={classNames("text-sm date-format mr-3 mb-2", {
        "date-darkmode": isDarkMode,
        "date-normal": !isDefinitive && differenceInCalendarDaysValue < -30,
        "date-alert": !isDefinitive && differenceInCalendarDaysValue <= 0,
        "date-danger": !isDefinitive && differenceInCalendarDaysValue > 0,
      })}
    >
      {`${formatedDate}`}
    </span>
  )
}
