import { TIdLabel } from "shared/types/global.type"

export const PIERREVAL: TIdLabel = {
  id: 1,
  label: "Pierreval",
}
export const PIERREVAL_AMENAGEMENT: TIdLabel = {
  id: 2,
  label: "Pierreval Aménagement",
}
export const NOVALYS: TIdLabel = {
  id: 3,
  label: "Novalys",
}
export const DEMEURES_DE_LOUISE: TIdLabel = {
  id: 4,
  label: "Demeures de Louise",
}
