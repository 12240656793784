import classnames from "classnames"
import React from "react"
import { IIconType } from "../../shared/types/global.type"
import "./Link.scss"

type IconType = (props: IIconType) => JSX.Element

export interface LinkProps {
  children: string
  onClick: () => void
  icon?: IconType
  iconSize?: number
  iconPosition?: string
  classNames?: string
}

function Link({
  children,
  onClick,
  icon,
  iconSize,
  iconPosition,
  classNames,
}: LinkProps) {
  const className = `${
    icon && iconPosition === "left" ? "Link__Icon_Left" : "Link__Icon_Right"
  } Link__Item`

  const iconMargin = `${
    icon && iconPosition === "left"
      ? "Link__Icon_Left_Margin"
      : "Link__Icon_Right_Margin"
  }`

  return (
    <button
      type="button"
      className={classnames(className, classNames)}
      onClick={onClick}
    >
      {icon &&
        iconSize &&
        React.createElement(icon, {
          width: iconSize,
          height: iconSize,
          color: "#0B6CFF",
        })}
      <span className={iconMargin}>{children}</span>
    </button>
  )
}

export { Link as LinkCustom }
