import DateFormat from "components/Date/DateFormat"
import { Link } from "react-router-dom"
import {
  ActualProjectedDate,
  TCollectifProductionDate,
} from "shared/types/spo/production.type"

export interface ISteeringProductionCollectiveRowProps {
  lotsCollectifDate: TCollectifProductionDate
}

function displayDate(date: ActualProjectedDate) {
  if (date.actualDate) {
    return <DateFormat date={new Date(date.actualDate)} isDefinitive />
  }
  if (date.projectedDate) {
    return <DateFormat date={new Date(date.projectedDate)} />
  }
  return "-"
}

export function SteeringProductionCollectiveRow(
  props: ISteeringProductionCollectiveRowProps,
) {
  const { lotsCollectifDate } = props

  return (
    <tr
      key={lotsCollectifDate.registrationId}
      className="Table__Body_Row hover:bg-gray-100 transition-colors"
    >
      <td>
        <Link
          className="text-sky-700 font-semibold underline"
          to={`${process.env.REACT_APP_SPO_URL}#/planning/gantt?operation=${lotsCollectifDate.registrationId}`}
          target="_blank"
        >
          {lotsCollectifDate.registrationId}
        </Link>
      </td>
      <td>{lotsCollectifDate.trancheTravauxLibelle}</td>
      <td>{displayDate(lotsCollectifDate.constructionLaunch)}</td>
      <td>{displayDate(lotsCollectifDate.earthworkEnd)}</td>
      <td>{displayDate(lotsCollectifDate.foundationsCompleted)}</td>
      <td>{displayDate(lotsCollectifDate.firstLevelSlabCompleted)}</td>
      <td>{displayDate(lotsCollectifDate.lastLevelSlabCompleted)}</td>
      <td>{displayDate(lotsCollectifDate.outOfWater)}</td>
      <td>{displayDate(lotsCollectifDate.outOfAir)}</td>
      <td>{displayDate(lotsCollectifDate.wallsCompleted)}</td>
      <td>{displayDate(lotsCollectifDate.completion)}</td>
      <td>{displayDate(lotsCollectifDate.provision)}</td>
    </tr>
  )
}
