import { useQueries } from "@tanstack/react-query"
import { getFeasibilityHistory } from "core/api/feasabilities"
import { useListFeasibilities } from "core/query-hooks/useFeasibilities"
import { format } from "date-fns"
import { STATUS_RETURNED } from "features/studies/studyRequest.resources"
import { t } from "i18next"
import { TStudyRequestHistory } from "shared/types/study.type"

export default function useFeasibilityVersion(id: number) {
  const { data: faisabilities } = useListFeasibilities(id)

  const returnedFeasibilities = faisabilities?.filter(
    (feasability) => feasability.statusId === STATUS_RETURNED,
  )

  const userFeasibilitesHistoryQueries = useQueries({
    queries: (returnedFeasibilities || []).map((feasa) => {
      return {
        queryKey: ["feasibilityHistories", feasa.id],
        queryFn: () => getFeasibilityHistory(feasa.id!),
        enabled: !!faisabilities,
        select: (res: TStudyRequestHistory[]) =>
          res.map((studyRequest) => {
            return {
              ...studyRequest,
              feasibility: feasa,
            }
          }),
      }
    }),
  })

  const isLoading = userFeasibilitesHistoryQueries.some(
    (history) => history.isLoading,
  )

  const restitutionDates = userFeasibilitesHistoryQueries.map(
    (studyRequests) => {
      const foundStudy = studyRequests.data?.find(
        (studyRequest) => studyRequest.statusId === STATUS_RETURNED,
      )
      if (foundStudy) {
        const date = new Date(foundStudy.date!)
        const formattedDate = format(date, "dd/MM/yyyy")

        return {
          value: foundStudy.feasibility.id!,
          label: `${foundStudy.feasibility.version} - ${
            foundStudy.feasibility.numberOfLotsReturned
          } ${t("lots")} - ${t("returned")} ${formattedDate}`,
        }
      }
      return null
    },
  )

  return { restitutionDates, isLoading }
}
