import DateFormat from "components/Date/DateFormat"
import {
  ActualProjectedDate,
  TCollectifProductionDate,
} from "shared/types/spo/production.type"

export interface ISteeringProductionCollectiveRowProps {
  lotsCollectifDate: TCollectifProductionDate
}

function displayDate(date: ActualProjectedDate) {
  if (date.actualDate) {
    return <DateFormat date={new Date(date.actualDate)} isDefinitive />
  }
  if (date.projectedDate) {
    return <DateFormat date={new Date(date.projectedDate)} />
  }
  return "-"
}

export function TechnicalProductionCollectiveRow(
  props: ISteeringProductionCollectiveRowProps,
) {
  const { lotsCollectifDate } = props

  return (
    <tr
      key={lotsCollectifDate.registrationId}
      className="Table__Body_Row hover:bg-gray-100 transition-colors"
    >
      <td className="Table__Bottom_Border">
        {lotsCollectifDate.trancheTravauxLibelle}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.constructionLaunch)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.earthworkEnd)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.foundationsCompleted)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.firstLevelSlabCompleted)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.lastLevelSlabCompleted)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.outOfWater)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.outOfAir)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.wallsCompleted)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.completion)}
      </td>
      <td className="Table__Bottom_Border">
        {displayDate(lotsCollectifDate.provision)}
      </td>
    </tr>
  )
}
