export default function formatNumberInKiloEuros(number: number | undefined) {
  if (!number) return null
  const kiloEuros = number / 1000

  return kiloEuros.toLocaleString("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}
