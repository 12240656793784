import { getStudyRequestLabel } from "features/studies/StudyRequestsList"
import { t } from "i18next"
import { Column } from "react-data-grid"
import {
  CowRow,
  FeasibilityRow,
  InfoRow,
  TUserDashboard,
} from "shared/types/dashboard.type"
import { TStudyRequestDashboardItem } from "shared/types/study.type"
import { TMinifiedUser } from "shared/types/user.type"
import { getStudyRequestStatus } from "../components/StatusFormatter"

type DashboardColmuns =
  | readonly Column<CowRow, unknown>[]
  | readonly Column<InfoRow, unknown>[]
  | readonly Column<FeasibilityRow, unknown>[]
type DashboardRows =
  | readonly CowRow[]
  | readonly InfoRow[]
  | readonly FeasibilityRow[]
  | undefined

export function sortByName(a: { firstName: string }, b: { firstName: string }) {
  const firstNameA = a.firstName.toLowerCase()
  const firstNameB = b.firstName.toLowerCase()
  if (firstNameA < firstNameB) {
    return -1
  }
  if (firstNameA > firstNameB) {
    return 1
  }
  return 0
}

export function sortByDate(dateA: string | Date, dateB: string | Date): number {
  const timeA = new Date(dateA).getTime()
  const timeB = new Date(dateB).getTime()

  return timeB - timeA
}

export const exportDashboardToCsv = (
  headers: DashboardColmuns,
  rows: DashboardRows,
): string[][] => {
  if (rows) {
    const csv = []
    csv.push(
      headers.map((c) => {
        return `${c.name}`
      }),
    )

    /* eslint-disable no-restricted-syntax */
    /* eslint-disable @typescript-eslint/dot-notation */
    for (const row of rows) {
      const csvLine = []
      for (const header of headers) {
        if (row[header.key as keyof DashboardRows]) {
          if (header.key === "status") {
            csvLine.push(
              getStudyRequestStatus(
                row[header.key as keyof DashboardRows] as number,
              ),
            )
          } else if (
            typeof row[header.key as keyof DashboardRows] === "string"
          ) {
            csvLine.push(
              `${(row[header.key as keyof DashboardRows] as string).replaceAll(",", "")}`,
            )
          } else if (
            typeof row[header.key as keyof DashboardRows] === "number"
          ) {
            csvLine.push(`${row[header.key as keyof DashboardRows] as string}`)
          } else if (
            typeof row[header.key as keyof DashboardRows] === "object" &&
            "firstName" in row[header.key as keyof DashboardRows]
          ) {
            csvLine.push(
              `${row[header.key as keyof DashboardRows]["firstName"]} ${row[header.key as keyof DashboardRows]["lastName"]}`,
            )
          } else if (Array.isArray(row[header.key as keyof DashboardRows])) {
            let str = ""
            const attributedUsers = row[
              header.key as keyof DashboardRows
            ] as Array<TUserDashboard>
            attributedUsers.forEach((item) => {
              if (typeof item === "object" && "firstName" in item) {
                str += `${item.firstName} ${item.lastName} `
              }
            })
            csvLine.push(str)
          }
        } else {
          csvLine.push("")
        }
      }
      csv.push(csvLine)
    }

    return csv
  }
  return []
}

export const exportStudyRequestsToCSv = (
  headers: Array<{ key: string; value: string[] }>,
  rows: TStudyRequestDashboardItem[],
): string[][] => {
  const csv = []
  csv.push(
    headers.map((header) => {
      return `${header.key}`
    }),
  )

  /* eslint-disable no-restricted-syntax */
  /* eslint-disable @typescript-eslint/dot-notation */
  for (const row of rows) {
    const csvLine = []
    for (const header of headers) {
      const key = header.value[0]
      if (row[key as keyof TStudyRequestDashboardItem]) {
        if (header.key === t("study-request-type")) {
          csvLine.push(
            getStudyRequestLabel(
              row[key as keyof TStudyRequestDashboardItem] as number,
            ),
          )
        } else if (header.key === t("progress")) {
          csvLine.push(
            getStudyRequestStatus(row[key as keyof DashboardRows] as number),
          )
        } else if (
          typeof row[key as keyof TStudyRequestDashboardItem] === "string"
        ) {
          csvLine.push(
            `${(row[key as keyof TStudyRequestDashboardItem] as string).replaceAll(",", "")}`,
          )
        } else if (
          typeof row[key as keyof TStudyRequestDashboardItem] === "number"
        ) {
          csvLine.push(
            `${row[key as keyof TStudyRequestDashboardItem] as string}`,
          )
        } else if (
          typeof row[key as keyof TStudyRequestDashboardItem] === "object" &&
          row[key as keyof TStudyRequestDashboardItem]?.hasOwnProperty.call(
            row[key as keyof TStudyRequestDashboardItem],
            "firstName",
          )
        ) {
          const user: TMinifiedUser | undefined = row[
            key as keyof TStudyRequestDashboardItem
          ]
            ? (row[key as keyof TStudyRequestDashboardItem] as TMinifiedUser)
            : undefined
          csvLine.push(`${user?.firstName} ${user?.lastName}`)
        } else if (Array.isArray(row[key as keyof DashboardRows])) {
          let str = ""
          const attributedUsers = row[
            key as keyof DashboardRows
          ] as Array<TUserDashboard>
          attributedUsers.forEach((item) => {
            if (typeof item === "object" && "firstName" in item) {
              str += `${item.firstName} ${item.lastName} `
            }
          })
          csvLine.push(str)
        }
      } else {
        csvLine.push("")
      }
    }
    csv.push(csvLine)
  }

  return csv
}
