import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import SpoAxiosInterceptor from "core/interceptors/SpoInterceptor"
import { useListChildOperations } from "core/query-hooks/useOperations"
import { OperationChildItemTechnical } from "features/operations/OperationChildItemTechnical"
import i18next from "i18next"
import { useParams } from "react-router-dom"
import DivStickyMenu from "./DivStickyMenu"

interface TOperationChildrenListProps {
  registrationNumber?: string
}

export function OperationChildrenList(props: TOperationChildrenListProps) {
  const { registrationNumber } = props
  const { id } = useParams()
  const {
    data: operationsChildren,
    isLoading,
    error,
  } = useListChildOperations(Number(id!))

  if (isLoading) return <Spinner />

  if (error)
    return (
      <p>
        {`${i18next.t("error")}`} : {error.message}
      </p>
    )

  return (
    <Tabs isOperationChildren selectedRegistrationNumber={registrationNumber}>
      {operationsChildren!.map((operation) => {
        return (
          <TabContent
            title={operation.registrationNumber}
            key={operation.registrationNumber}
          >
            <SpoAxiosInterceptor>
              <OperationChildItemTechnical operationChild={operation} />
            </SpoAxiosInterceptor>
          </TabContent>
        )
      })}
    </Tabs>
  )
}

export function OperationTechnical() {
  const { registrationNumber } = useParams()

  return (
    <>
      <DivStickyMenu />
      <div className="p-4 pb-32">
        <Box>
          <OperationChildrenList registrationNumber={registrationNumber} />
        </Box>
      </div>
    </>
  )
}
