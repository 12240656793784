import Spinner from "components/Spinner/Spinner"
import { uselistStudyRequestUsers } from "core/query-hooks/useStudyRequests"
import DropdownFilters from "features/programs/DropdownFilter"
import { t } from "i18next"
import { useState } from "react"
import { TLabelStringValue } from "shared/types/global.type"

export interface IUsersFiltersProps {
  selectedStudyRequestUsers: string[] | []
  onStudyRequestUsersChange: (selectedUser: string[] | []) => void
}

export default function selectedStudyRequestUsersFilter(
  props: IUsersFiltersProps,
) {
  const { selectedStudyRequestUsers, onStudyRequestUsersChange } = props
  const {
    data: usersData,
    isLoading: isLoadingUser,
    error: errorUser,
  } = uselistStudyRequestUsers()
  const [searchWord, setSearchWord] = useState<string>("")
  if (isLoadingUser) return <Spinner />

  if (errorUser || !usersData) return <p>{errorUser?.message}</p>

  function handleChangeOptions(search: string) {
    setSearchWord(search)
  }

  const handleClickOutside = () => {
    setSearchWord("")
  }

  const filtered = usersData.filter((user) => {
    const fullName = user.label
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
    const searchTermLower = searchWord
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
    return fullName.includes(searchTermLower)
  })

  const handleProductClick = (user: TLabelStringValue) => {
    const isSelected = ((selectedStudyRequestUsers as string[]) ?? []).includes(
      user.value,
    )

    const updatedUsers = isSelected
      ? selectedStudyRequestUsers.filter(
          (currentUser) => currentUser !== user.value,
        )
      : [...selectedStudyRequestUsers, user.value]

    onStudyRequestUsersChange(updatedUsers)
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900">
              {t("contributors")}
              {selectedStudyRequestUsers.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-blue-800 bg-blue-100 rounded-full">
                  {selectedStudyRequestUsers.length}
                </div>
              )}
            </div>
          )}
          options={filtered}
          renderOption={(user, index) => (
            <li
              onClick={() => handleProductClick(user as TLabelStringValue)}
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              tabIndex={-1}
              id={`menu-item-${index}`}
              key={`menu-item-${index}`}
            >
              <input
                className="mr-2"
                type="checkbox"
                checked={(selectedStudyRequestUsers as string[])?.includes(
                  user.value,
                )}
              />
              {t(user.label)}
            </li>
          )}
          keyExtractor={(user) => user.value}
          handleChangeOptions={(searchText) => {
            handleChangeOptions(searchText)
          }}
          onClickOutside={handleClickOutside}
        />
      </div>
    </div>
  )
}
