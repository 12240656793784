import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { useGetEdvDetail } from "core/query-hooks/useEdv"
import { t } from "i18next"
import { useState } from "react"
import { nanoid } from "nanoid"
import { Link } from "react-router-dom"
import DisplayDetailSwtich from "./filters/DetailSwitch"

export interface IEdvDetailProps {
  businessUnits: number[]
  departments: string[]
  companies: number[]
  products: string[]
  year: number
  caType: string
  detail: string
  tab: string
}

export default function EdvDetail(props: IEdvDetailProps) {
  const {
    year,
    caType,
    detail,
    tab,
    businessUnits,
    departments,
    companies,
    products,
  } = props
  const {
    data: edvDetail,
    isLoading,
    error,
  } = useGetEdvDetail({
    year,
    caType,
    detail,
    businessUnits,
    departments,
    companies,
    products,
    tab,
  })

  function getPreviousMonth() {
    const currentMonth = new Date().getMonth()
    const previousMonthIndex = (currentMonth - 1 + 12) % 12
    const monthNames = [
      t("january-abv"),
      t("february-abv"),
      t("march-abv"),
      t("april-abv"),
      t("may-abv"),
      t("june-abv"),
      t("july-abv"),
      t("august-abv"),
      t("september-abv"),
      t("october-abv"),
      t("november-abv"),
      t("december-abv"),
    ]
    return monthNames[previousMonthIndex]
  }

  const prevMonth = getPreviousMonth()

  const headers = [
    () => "",
    () => "",
    () => t("nb-lots"),
    () => t("initial-stock-abrv"),
    () => "",
    () => t("january-abv"),
    () => t("february-abv"),
    () => t("march-abv"),
    () => t("april-abv"),
    () => t("may-abv"),
    () => t("june-abv"),
    () => t("july-abv"),
    () => t("august-abv"),
    () => t("september-abv"),
    () => t("october-abv"),
    () => t("november-abv"),
    () => t("december-abv"),
    () => "",
    () => `${t("cumulative-end")} ${prevMonth}`,
    () => `${t("gap-objective")} (%)`,
    () => `${t("gap-n-1")} (%)`,
    () => t("total"),
    () => t("day-stock"),
    () => t("options"),
  ]

  let totalRows = 0

  const [hideDetail, setHideDetail] = useState<boolean>(false)

  const handleHideDetail = () => {
    setHideDetail(!hideDetail)
  }

  const [hideCA, setHideCA] = useState<boolean>(true)

  const handleHideCA = () => {
    setHideCA(!hideCA)
  }

  return (
    <>
      {isLoading && (
        <div className="flex justify-center">
          <Spinner />
        </div>
      )}
      {edvDetail && (
        <div className="Light_Table_Edv__Wrapper Table__Wrapper_NoTop">
          <div className="Table__Container Table__Sticky_Container">
            <table className="Table">
              <thead>
                <tr className="text-left Table__Sticky_Header">
                  {headers.map((header, index) => (
                    <th
                      className={classNames(
                        "Table__Head Table__Top_Border text-right border-b border-gray-200",
                        {
                          "w-72": index === 0,
                        },
                      )}
                      key={nanoid()}
                    >
                      {index === 0 ? (
                        <div className="flex items-center">
                          <div className="mr-5">
                            <DisplayDetailSwtich
                              label="Détail"
                              checked={hideDetail}
                              onChange={handleHideDetail}
                            />
                          </div>
                          <DisplayDetailSwtich
                            label="CA"
                            checked={hideCA}
                            onChange={handleHideCA}
                          />
                        </div>
                      ) : (
                        header()
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              {edvDetail.length > 0 ? (
                <tbody>
                  {edvDetail.map((item) => {
                    return (
                      <>
                        {item.sum.map((line, index) => {
                          const isFirstRow = index === 0
                          let rowSpan = isFirstRow ? 3 : 0 // rowSpan pour la première cellule
                          if (hideCA && rowSpan === 3) rowSpan = 2 // Si on cache le CA alors que deux lignes
                          return (
                            <tr
                              key={nanoid()}
                              className={classNames({
                                "bg-blue-200": !hideDetail,
                                hidden: line.label === "CA (K€)" && hideCA,
                              })}
                            >
                              {isFirstRow && (
                                <td
                                  rowSpan={rowSpan}
                                  className="Table__Bottom_Border font-semibold align-top text-left"
                                >
                                  {item.tab}
                                </td>
                              )}
                              <td className="Table__Bottom_Border font-semibold">
                                {line.label}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.nombre_lots}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.stock_debut}
                              </td>
                              <td className="bg-gray-200" />
                              <td className="Table__Bottom_Border text-right">
                                {line.janvier
                                  ? line.janvier.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.fevrier
                                  ? line.fevrier.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.mars
                                  ? line.mars.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.avril
                                  ? line.avril.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.mai
                                  ? line.mai.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.juin
                                  ? line.juin.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.juillet
                                  ? line.juillet.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.aout
                                  ? line.aout.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.septembre
                                  ? line.septembre.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.octobre
                                  ? line.octobre.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.novembre
                                  ? line.novembre.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.decembre
                                  ? line.decembre.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="bg-gray-200" />
                              <td className="Table__Bottom_Border text-right">
                                {line.cumul
                                  ? Number(line.cumul).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td
                                className={classNames(
                                  "Table__Bottom_Border text-right",
                                  {
                                    Red: line.ecart_objectif_pourcentage < 0,
                                  },
                                  {
                                    Green: line.ecart_objectif_pourcentage >= 0,
                                  },
                                )}
                              >
                                {line.ecart_objectif_pourcentage
                                  ? `${Number(line.ecart_objectif_pourcentage).toLocaleString("fr-FR")} %`
                                  : ""}
                                {line.ecart_objectif_lot
                                  ? `(${Number(line.ecart_objectif_lot).toLocaleString("fr-FR")})`
                                  : ""}
                              </td>
                              <td
                                className={classNames(
                                  "Table__Bottom_Border text-right",
                                  {
                                    Red: line.ecart_n1_pourcentage < 0,
                                  },
                                  {
                                    Green: line.ecart_n1_pourcentage >= 0,
                                  },
                                )}
                              >
                                {line.ecart_n1_pourcentage
                                  ? `${Number(line.ecart_n1_pourcentage).toLocaleString("fr-FR")} %`
                                  : ""}
                                {line.ecart_n1_lot
                                  ? `(${Number(line.ecart_n1_lot).toLocaleString("fr-FR")})`
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.total
                                  ? line.total.toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.stock_jour
                                  ? Number(line.stock_jour).toLocaleString(
                                      "fr-FR",
                                    )
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                <p className="mr-3">
                                  {line.nombre_options
                                    ? Number(
                                        line.nombre_options,
                                      ).toLocaleString("fr-FR")
                                    : ""}
                                </p>
                              </td>
                            </tr>
                          )
                        })}
                        {!hideDetail &&
                          item.operations.map((op) => {
                            totalRows = 0
                            return (
                              <>
                                {op.values &&
                                  Array.isArray(op.values) &&
                                  op.values.map((line, index) => {
                                    const isFirstRow = index === 0
                                    let rowSpan = isFirstRow
                                      ? op.values.length
                                      : 0
                                    if (hideCA && rowSpan === op.values.length)
                                      rowSpan = op.values.length - 1

                                    totalRows += 1

                                    return (
                                      <tr
                                        key={line.type}
                                        className={classNames({
                                          hidden:
                                            line.type === "CA (K€)" && hideCA,
                                        })}
                                      >
                                        {isFirstRow && (
                                          <td
                                            rowSpan={rowSpan}
                                            className="Table__Bottom_Border align-top text-left"
                                          >
                                            <Link
                                              target="_blank"
                                              to={
                                                op.projet_id
                                                  ? `/operation/${op.projet_id}/commercialization`
                                                  : `/simplified-programs`
                                              }
                                              className="text-sky-800 underline"
                                            >
                                              {op.operation}
                                            </Link>
                                          </td>
                                        )}
                                        <td className="Table__Bottom_Border">
                                          {line.type}
                                        </td>
                                        <td className="Table__Bottom_Border text-right">
                                          {line.nombre_lots}
                                        </td>
                                        <td className="Table__Bottom_Border text-right">
                                          {line.stock_debut}
                                        </td>
                                        <td className="bg-gray-200" />
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.janvier &&
                                                line.janvier.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.janvier &&
                                                line.janvier.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.janvier &&
                                                line.janvier.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.janvier && line.janvier.value
                                            ? Number(
                                                line.janvier.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.fevrier &&
                                                line.fevrier.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.fevrier &&
                                                line.fevrier.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.fevrier &&
                                                line.fevrier.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.fevrier && line.fevrier.value
                                            ? Number(
                                                line.fevrier.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.mars && line.mars.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.mars &&
                                                line.mars.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.mars &&
                                                line.mars.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.mars && line.mars.value
                                            ? Number(
                                                line.mars.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.avril && line.avril.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.avril &&
                                                line.avril.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.avril &&
                                                line.avril.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.avril && line.avril.value
                                            ? Number(
                                                line.avril.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.mai && line.mai.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.mai && line.mai.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.mai &&
                                                line.mai.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.mai && line.mai.value
                                            ? Number(
                                                line.mai.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.juin && line.juin.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.juin &&
                                                line.juin.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.juin &&
                                                line.juin.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.juin && line.juin.value
                                            ? Number(
                                                line.juin.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.juillet &&
                                                line.juillet.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.juillet &&
                                                line.juillet.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.juillet &&
                                                line.juillet.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.juillet && line.juillet.value
                                            ? Number(
                                                line.juillet.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.aout && line.aout.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.aout &&
                                                line.aout.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.aout &&
                                                line.aout.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line?.aout && line.aout.value
                                            ? Number(
                                                line.aout.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.septembre &&
                                                line.septembre.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.septembre &&
                                                line.septembre.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.septembre &&
                                                line.septembre.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.septembre &&
                                          line.septembre.value
                                            ? Number(
                                                line.septembre.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.octobre &&
                                                line.octobre.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.octobre &&
                                                line.octobre.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.octobre &&
                                                line.octobre.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.octobre && line.octobre.value
                                            ? Number(
                                                line.octobre.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.novembre &&
                                                line.novembre.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.novembre &&
                                                line.novembre.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.novembre &&
                                                line.novembre.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.novembre && line.novembre.value
                                            ? Number(
                                                line.novembre.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              "bg-gray-400":
                                                line.decembre &&
                                                line.decembre.frozen,
                                            },
                                            {
                                              "bg-red-400":
                                                line.decembre &&
                                                line.decembre.abandoned,
                                            },
                                            {
                                              "bg-green-400":
                                                line.decembre &&
                                                line.decembre.commercialLaunch,
                                            },
                                          )}
                                        >
                                          {line.decembre && line.decembre.value
                                            ? Number(
                                                line.decembre.value,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td className="bg-gray-200" />
                                        <td className="Table__Bottom_Border text-right">
                                          {line.cumul
                                            ? Number(line.cumul).toLocaleString(
                                                "fr-FR",
                                              )
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              Red:
                                                line.ecart_objectif_pourcentage <
                                                0,
                                            },
                                            {
                                              Green:
                                                line.ecart_objectif_pourcentage >=
                                                0,
                                            },
                                          )}
                                        >
                                          {line.ecart_objectif_pourcentage
                                            ? `${Number(line.ecart_objectif_pourcentage).toLocaleString("fr-FR")} %`
                                            : ""}
                                          {line.ecart_objectif_lot
                                            ? `(${Number(line.ecart_objectif_lot).toLocaleString("fr-FR")})`
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "Table__Bottom_Border text-right",
                                            {
                                              Red:
                                                line.ecart_n1_pourcentage < 0,
                                            },
                                            {
                                              Green:
                                                line.ecart_n1_pourcentage >= 0,
                                            },
                                          )}
                                        >
                                          {line.ecart_n1_pourcentage
                                            ? `${Number(line.ecart_n1_pourcentage).toLocaleString("fr-FR")} %`
                                            : ""}
                                          {line.ecart_n1_lot
                                            ? `(${Number(line.ecart_n1_lot).toLocaleString("fr-FR")})`
                                            : ""}
                                        </td>
                                        <td className="Table__Bottom_Border text-right">
                                          {line.total
                                            ? Number(line.total).toLocaleString(
                                                "fr-FR",
                                              )
                                            : ""}
                                        </td>
                                        <td className="Table__Bottom_Border text-right">
                                          {line.stock_jour
                                            ? Number(
                                                line.stock_jour,
                                              ).toLocaleString("fr-FR")
                                            : ""}
                                        </td>
                                        <td className="Table__Bottom_Border text-right">
                                          <p className="mr-3">
                                            {line.nombre_options
                                              ? Number(
                                                  line.nombre_options,
                                                ).toLocaleString("fr-FR")
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                {totalRows > 1 && (
                                  <tr
                                    className={classNames("Table__Separator", {
                                      hidden: hideDetail,
                                    })}
                                  >
                                    <td
                                      colSpan={headers.length}
                                      className="Table_Separator_td"
                                    />
                                  </tr>
                                )}
                              </>
                            )
                          })}
                        <tr
                          className={classNames("Table__Separator", {
                            hidden: !hideDetail,
                          })}
                        >
                          <td
                            colSpan={headers.length}
                            className="Table_Separator_td"
                          />
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              ) : (
                <tbody className="text-center p-2">
                  <tr>
                    <td colSpan={headers.length}>
                      {t("no-data-found-message")}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
      {error ? <>{t("data-error")}</> : null}
    </>
  )
}
