import * as React from "react"
import "./Navbar.scss"

interface INavbarProps {
  children: React.ReactNode
}

export default function Navbar({ children }: INavbarProps) {
  const env = process.env.REACT_APP_ENV

  let navbarClassName = "Navbar Prod"
  if (env === "dev") {
    navbarClassName = "Navbar Dev"
  } else if (env === "qa") {
    navbarClassName = "Navbar Qa"
  } else if (env === "local") {
    navbarClassName = "Navbar Local"
  }

  return (
    <div className="Navbar__Container">
      <header className={navbarClassName}>{children}</header>
    </div>
  )
}
