import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { useCreateCpr } from "core/query-hooks/useCprs"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { FormEvent, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { TCreateCpr } from "shared/types/cpr.type"
import * as yup from "yup"
import CprToggle from "./CprToggle"

const validationSchema = yup.object().shape({
  childOperationId: yup.number().required(),
  clientName: yup
    .string()
    .trim()
    .min(1, `${t("required-client-name")}`)
    .required(() => `${t("required-client-name")}`),
  cprSignature: yup.date().nullable(),
  isCprSignatureDefinitive: yup.boolean(),
  cprDueDate: yup.date().nullable(),
  isCprDueDateDefinitive: yup.boolean(),
})

export interface IAddCprModalProps {
  childOperationId: number
}

export default function AddCprModal(props: IAddCprModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const { childOperationId } = props
  const queryClient = useQueryClient()
  const [cprSignature, setCprSignature] = useState<Date>()
  const [cprDueDate, setCprDueDate] = useState<Date>()
  const [isCprSignatureDefinitive, setIsCprSignatureDefinitive] =
    useState<boolean>(false)
  const [isCprDueDateDefinitive, setIsCprDueDateDefinitive] =
    useState<boolean>(false)

  const mutation = useCreateCpr()
  const {
    handleSubmit,
    control,
    reset,
    register,
    formState,
    setValue,
    formState: { errors, isSubmitSuccessful, isSubmitted },
  } = useForm<TCreateCpr>({
    resolver: yupResolver(validationSchema as yup.ObjectSchema<TCreateCpr>),
    defaultValues: {
      childOperationId,
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [formState, isSubmitted, reset])

  const handleToggleIsSignatureDefinitive = () => {
    setValue("isCprSignatureDefinitive", !isCprSignatureDefinitive)
    setIsCprSignatureDefinitive(!isCprSignatureDefinitive)
  }

  const handleToggleIsDueDateDefinitive = () => {
    setValue("isCprDueDateDefinitive", !isCprDueDateDefinitive)
    setIsCprDueDateDefinitive(!isCprDueDateDefinitive)
  }

  const handleCloseModal = () => {
    toggleModal()
    setCprDueDate(undefined)
    setCprSignature(undefined)
    setIsCprSignatureDefinitive(false)
    setIsCprDueDateDefinitive(false)
  }

  const submitForm = async (data: TCreateCpr) => {
    mutation.mutateAsync(data, {
      onSuccess: () => {
        toast.success(`${t("toast-cpr-added-success")}`)
        handleCloseModal()
        queryClient.invalidateQueries({
          queryKey: ["listCprs", childOperationId],
        })
      },
      onError: (err) => {
        toast.error(
          `${t("toast-cpr-added-error")} ${err.response?.data.error.message}`,
        )
      },
    })
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <>
      <Button onClick={toggleModal} size="small" mode="primary">
        {`${t("add")}`}
      </Button>
      <Modal
        isShowing={isModalShowed}
        closeModal={handleCloseModal}
        title={`${t("add-cpr")}`}
      >
        <form className="Text__Field_Container" onSubmit={onSubmit}>
          <label className="font-medium w-full" htmlFor="clientName">
            {`${t("client-name")}`}
            <input
              id="clientName"
              className="Text__Field"
              type="text"
              {...register("clientName")}
            />
          </label>
          {errors?.clientName && (
            <p className="text-red-600 text-sm">{errors.clientName.message}</p>
          )}

          <div className="mt-4 flex flex-col">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <label className="font-medium mr-4" htmlFor="cprSignature">
                  {`${t("prc-signature")}`}
                </label>
                <Controller
                  name="cprSignature"
                  control={control}
                  render={({ field }) => (
                    <CustomDatePicker
                      id="cprSignature"
                      locale={fr}
                      className="Text__Field"
                      placeholderText={`${t("prc-signature")}`}
                      selected={cprSignature}
                      onChange={(date) => {
                        setCprSignature(date!)
                        field.onChange(date)
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col">
                <label className="font-medium mr-4" htmlFor="cprSignature">
                  {`${t("realized")}`}
                </label>
                <div className="pt-2">
                  <CprToggle
                    id="is-cpr-signature-definitive"
                    name="toggle-is-cpr-signature-definitive"
                    checked={isCprSignatureDefinitive}
                    onChange={handleToggleIsSignatureDefinitive}
                  />
                </div>
              </div>
            </div>
            {errors?.cprSignature && (
              <p className="text-red-600 text-sm">
                {errors.cprSignature.message}
              </p>
            )}
          </div>

          <div className="mt-4 flex flex-col">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <label className="font-medium mr-4" htmlFor="cprDueDate">
                  {`${t("due-date")}`}
                </label>
                <Controller
                  name="cprDueDate"
                  control={control}
                  render={({ field }) => (
                    <CustomDatePicker
                      id="cprDueDate"
                      locale={fr}
                      className="Text__Field"
                      placeholderText={`${t("due-date")}`}
                      selected={cprDueDate}
                      onChange={(date) => {
                        setCprDueDate(date!)
                        field.onChange(date)
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col">
                <label className="font-medium mr-4" htmlFor="cprSignature">
                  {`${t("realized")}`}
                </label>
                <div className="pt-2">
                  <CprToggle
                    id="is-cpr-due-date-definitive"
                    name="toggle-is-cpr-due-date-definitive"
                    checked={isCprDueDateDefinitive}
                    onChange={handleToggleIsDueDateDefinitive}
                  />
                </div>
              </div>
            </div>
            {errors?.cprDueDate && (
              <p className="text-red-600 text-sm">
                {errors.cprDueDate.message}
              </p>
            )}
          </div>

          <div className="Modal__Footer mt-2">
            <Button
              type="button"
              size="medium"
              mode="secondary"
              onClick={() => {
                toggleModal()
                reset()
              }}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              marginLeft={24}
              size="medium"
              mode="primary"
              isLoading={mutation.isPending}
              type="submit"
            >
              {`${t("create")}`}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
