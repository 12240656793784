import Spinner from "components/Spinner/Spinner"
import useDepartments from "core/query-hooks/useDepartments"
import DropdownFilters from "features/programs/DropdownFilter"
import { t } from "i18next"
import { useState } from "react"
import { TIdLabel } from "shared/types/global.type"
import { addLeadingZero } from "utils/departments"

interface DepartmentsFilterProps {
  selectedDepartments: string[] | []
  onDepartmentsChange: (selectedDepartement: string[] | []) => void
  isOperation: boolean
}

export default function DepartmentsFilter(props: DepartmentsFilterProps) {
  const { selectedDepartments, onDepartmentsChange, isOperation } = props
  const {
    data: departmentData,
    isLoading: isLoadingDp,
    error: errorDp,
  } = useDepartments(isOperation)

  if (isLoadingDp) return <Spinner />

  if (errorDp || !departmentData)
    return <p>{errorDp?.response?.data.message}</p>

  const [searchWord, setSearchWord] = useState<string>("")
  function handleChangeOptions(search: string) {
    setSearchWord(search)
  }

  const filtered = departmentData.filter((department) => {
    return department.label.includes(searchWord)
  })

  const handleClickOutside = () => {
    setSearchWord("")
  }

  const handleDpClick = (department: TIdLabel) => {
    if (department.id === -1) {
      const updatedBuList =
        selectedDepartments.length !== departmentData.length
          ? departmentData.map((item) => item.label)
          : []

      onDepartmentsChange(updatedBuList)
    } else {
      const isDpSelected = ((selectedDepartments as string[]) ?? []).includes(
        department.label,
      )

      if (isDpSelected) {
        const updatedBuList = selectedDepartments?.filter(
          (departmentLabel) => departmentLabel !== department.label,
        )
        onDepartmentsChange(updatedBuList || [])
      } else {
        const updatedBuList = selectedDepartments
          ? [...selectedDepartments, department.label]
          : [department.label]
        onDepartmentsChange(updatedBuList)
      }
    }
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900">
              {t("county")}
              {selectedDepartments.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-gray-800 bg-gray-100 rounded-full">
                  {selectedDepartments.length}
                </div>
              )}
            </div>
          )}
          options={filtered}
          renderOption={(department, index) => (
            <li
              onClick={() => handleDpClick(department)}
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              tabIndex={-1}
              id={`menu-item-${index}`}
              key={`menu-item-${index}`}
            >
              <input
                className="mr-2"
                type="checkbox"
                readOnly
                checked={
                  !!selectedDepartments?.find(
                    (departmentLabel) => departmentLabel === department.label,
                  )
                }
              />
              {addLeadingZero(department.label)}
            </li>
          )}
          keyExtractor={(department) => department.id}
          handleChangeOptions={(searchText) => {
            handleChangeOptions(searchText)
          }}
          onClickOutside={handleClickOutside}
        />
      </div>
    </div>
  )
}
