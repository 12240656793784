import { useMutation, useQuery } from "@tanstack/react-query"
import {
  getEnvironmentById,
  listReThresold,
  updateEnvironment,
} from "core/api/environments"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TEnvironment,
  TReThresold,
  TUpdateEnvironment,
} from "shared/types/environment.type"

function useGetEnvironmentById(projectId: number) {
  return useQuery<TEnvironment, CustomAxiosError>({
    queryKey: ["getEnvironment", projectId],
    queryFn: () => getEnvironmentById(projectId),
  })
}

function useUpdateEnvironment(id: number) {
  return useMutation<TEnvironment, CustomAxiosError, TUpdateEnvironment>({
    mutationFn: (environment: TUpdateEnvironment) =>
      updateEnvironment(id, environment),
  })
}

function useListReThresold() {
  return useQuery<TReThresold[], CustomAxiosError>({
    queryKey: ["listReThresold"],
    queryFn: () => listReThresold(),
  })
}

export { useGetEnvironmentById, useListReThresold, useUpdateEnvironment }
