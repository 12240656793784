import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import Box from "components/Box/Box"
import DateFormat from "components/Date/DateFormat"
import { Header } from "components/Header/Header"
import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useCreateSuspensiveCondition,
  useDeleteSuspensiveCondition,
} from "core/query-hooks/useSuspensiveConditions"
import { formatDistanceToNow, isBefore } from "date-fns"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TSuspensiveCondition } from "shared/types/suspensiveCondition.type"
import AddSuspensiveConditionsModal from "./AddSuspensiveConditionsModal"
import EditSuspensiveConditionModal from "./EditSuspensiveConditionModal"
import "./suspensive-conditions.scss"
import useSuspensiveConditions from "./useGetSuspensiveConditions"

export default function SuspensiveConditionsBox() {
  const { agreementId } = useParams()
  const queryClient = useQueryClient()
  const createSuspensiveCondition = useCreateSuspensiveCondition()
  const { groupedByDateSC } = useSuspensiveConditions()
  const deleteSuspensiveCondition = useDeleteSuspensiveCondition()

  const formatDate = (date: string) =>
    formatDistanceToNow((Date.now(), new Date(date)), {
      locale: fr,
    })

  const dateIsBefore = (date: string) => isBefore(new Date(date), Date.now())

  const refetchList = () => {
    queryClient.invalidateQueries({
      queryKey: ["getSuspensiveConditionsByAgreementId", agreementId],
    })
  }

  const onDeleteSuspensiveCondition = async (id: number) => {
    await deleteSuspensiveCondition.mutateAsync(id, {
      onSuccess: () => {
        refetchList()
        toast.success(`${t("toast-suspensive-condition-delete-success")}`)
      },
      onError: (err) => {
        toast.error(
          `${t("toast-suspensive-condition-delete-error")}, ${
            err.response?.data.error.message
          }`,
        )
      },
    })
  }

  const onCreateSuspensiveCondition = (
    label: string,
    date: Date,
    isDefinitive: boolean,
  ) => {
    createSuspensiveCondition.mutate(
      {
        label,
        date,
        isDefinitive,
        agreementId: +agreementId!,
      },
      {
        onSuccess() {
          toast.success(`${t("toast-suspensive-condition-create-success")}`)
          refetchList()
        },
        onError() {
          toast.error(`${t("toast-suspensive-condition-create-error")}`)
          refetchList()
        },
      },
    )
  }

  const orderedSuspensiveConditions: {
    [x: string]: TSuspensiveCondition[]
  } = Object.keys(groupedByDateSC)
    .sort()
    .reduce((obj: { [x: string]: TSuspensiveCondition[] }, key: string) => {
      const modifiedObj = { ...obj }
      modifiedObj[key] = groupedByDateSC[key as keyof typeof groupedByDateSC]
      return modifiedObj
    }, {})

  return (
    <Box classNames="py-4 px-6 md:mb-4 sm:mb-4 mb-4">
      <div className="flex items-center justify-between mb-4">
        <Header size="h2" classNames="pt-2 mb-2">
          {`${t("suspensive-conditions")}`}
        </Header>

        <QueryBoundaries loadingComponent={<Spinner />}>
          <PermissionsGuard requiredRoles={["create:suspensive-condition"]}>
            <AddSuspensiveConditionsModal
              suspensiveCondition={undefined}
              addSuspensiveConditions={(label, isDefinitive, date) =>
                onCreateSuspensiveCondition(label, date!, isDefinitive)
              }
            />
          </PermissionsGuard>
        </QueryBoundaries>
      </div>

      {Object.keys(orderedSuspensiveConditions).length ? (
        <div
          className={classNames({
            "pl-4": Object.keys(orderedSuspensiveConditions).length > 1,
          })}
        >
          {Object.entries(orderedSuspensiveConditions).map(
            ([key, value], index) => (
              <div
                key={key}
                className="border border-gray-200 mb-6 suspensive-conditions-list rounded-lg"
              >
                <div
                  className={classNames(
                    {
                      "suspensive-conditions-bullet":
                        Object.keys(orderedSuspensiveConditions).length > 1,
                    },
                    {
                      "suspensive-conditions-bullet-after":
                        Object.keys(orderedSuspensiveConditions).length - 1 !==
                        index,
                    },
                  )}
                >
                  <div className="rounded-t-lg overflow-hidden border-b border-gray-200 px-4 py-4 bg-zinc-50">
                    {key === "null" ? (
                      "Date à préciser"
                    ) : (
                      <div className="flex flex-wrap">
                        {`${
                          dateIsBefore(key) ? `${t("ago")}` : `${t("in")}`
                        } ${formatDate(key)} - `}
                        {value.map((suspensiveCondition) =>
                          suspensiveCondition?.date ? (
                            <DateFormat
                              key={suspensiveCondition.id}
                              isDefinitive={suspensiveCondition.isDefinitive}
                              date={suspensiveCondition.date}
                            />
                          ) : (
                            "-"
                          ),
                        )}
                      </div>
                    )}
                  </div>
                  <div className="px-4 py-4">
                    {value.map((suspensiveCondition, i) => (
                      <div
                        key={`${key}-${suspensiveCondition.label}`}
                        className={classNames(
                          i !== value.length - 1 ? "mb-1" : "mb-0",
                          "flex items-center text-blue-600",
                        )}
                      >
                        <EditSuspensiveConditionModal
                          deleteSuspensiveCondition={(id: number) =>
                            onDeleteSuspensiveCondition(id)
                          }
                          suspensiveCondition={suspensiveCondition}
                          refetch={() => refetchList()}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      ) : (
        <p>{`${t("no-suspensive-condition-found")}`}</p>
      )}
    </Box>
  )
}
