import {
  TDatesConsultation,
  TDatesMontage,
} from "shared/types/spo/datesPlanning.type"
import SPO_API from "../axios"

async function getDatesMontage(registrationId: string): Promise<TDatesMontage> {
  const res = await SPO_API.get(`/operations/${registrationId}/dates-montage`)
  return res.data
}

async function getDatesConsultation(
  registrationId: string,
): Promise<TDatesConsultation> {
  const res = await SPO_API.get(
    `/operations/${registrationId}/dates-consultation`,
  )
  return res.data
}

export { getDatesMontage, getDatesConsultation }
