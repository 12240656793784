export interface IYearFilterProps {
  selectedYear: number
  onYearChange: (selectedYear: number) => void
}

export default function YearFilter(props: IYearFilterProps) {
  const { selectedYear, onYearChange } = props
  // Obtiens l'année en cours
  const currentYear = new Date().getFullYear()

  // Crée une liste d'années, en remontant de l'année actuelle jusqu'à 4 ans en arrière
  const years = Array.from({ length: 4 }, (_, index) => currentYear - index)

  const handleYearChange = (year: number) => {
    onYearChange(year)
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <select
          value={selectedYear}
          onChange={(e) => handleYearChange(Number(e.target.value))}
          className="block w-full text-sky-900 font-semibold bg-white border border-gray-300 rounded-md px-3 py-2.5 text-sm focus:outline-none"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
