import Spinner from "components/Spinner/Spinner"
import useDepartments from "core/query-hooks/useDepartments"
import { t } from "i18next"
import { TIdLabel } from "shared/types/global.type"

function renderDepartment(departementId: string, departments: TIdLabel[]) {
  const selectedStep = departments?.find(
    (departement) => departement.id === Number(departementId),
  )
  return selectedStep?.label
}
interface DepartmentsFilterProps {
  selectedDepartments: string[] | []
  onDepartmentsChange: (selectedDepartement: string[] | []) => void
  isOperation: boolean
}

export default function SelectedDepartmentsFilters(
  props: DepartmentsFilterProps,
) {
  const { selectedDepartments, onDepartmentsChange, isOperation } = props
  const {
    data: departmentsData,
    isLoading,
    error,
  } = useDepartments(isOperation)

  if (isLoading) return <Spinner />

  if (error || !departmentsData) return <p>{error?.response?.data.message}</p>

  const handleDepartementRemove = (departementIdToRemove: string) => {
    const updatedDpList = selectedDepartments.filter(
      (departementId) => departementId !== departementIdToRemove,
    )
    onDepartmentsChange(updatedDpList)
  }

  return (
    <div>
      {selectedDepartments.map((departmentId) => (
        <span
          id="badge-dismiss-default"
          className="inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded"
          key={departmentId}
        >
          {renderDepartment(departmentId, departmentsData)}
          <button
            onClick={() => handleDepartementRemove(departmentId)}
            type="button"
            className="inline-flex items-center p-0.5 ml-2 my-0.5 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900"
            data-dismiss-target="#badge-dismiss-default"
            aria-label="Remove"
          >
            <svg
              aria-hidden="true"
              className="w-3.5 h-3.5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">{t("delete")}</span>
          </button>
        </span>
      ))}
    </div>
  )
}
