import {
  STATUS_CANCELLED,
  STATUS_CREATED,
  STATUS_DRAFT,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_REJECTED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  STATUS_VALIDATED,
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "features/studies/studyRequest.resources"

export const STUDY_TYPES = [
  {
    id: TYPE_FEASIBILITY,
    label: "Faisabilités",
  },
  {
    id: TYPE_COST_OF_WORK,
    label: "Coût travaux",
  },
  {
    id: TYPE_INFOGRAPHY,
    label: "Infographies",
  },
]

export const STUDY_STATUS = [
  {
    id: STATUS_CREATED,
    label: "En attente de validation",
  },
  {
    id: STATUS_CANCELLED,
    label: "Annulé",
  },
  {
    id: STATUS_DRAFT,
    label: "Brouillon",
  },
  {
    id: STATUS_PRIORIZATION_STANDBY,
    label: "Priorisation en stand by",
  },
  {
    id: STATUS_PRIORIZED_AND_ASSIGNED,
    label: "Priorisé et assigné",
  },
  {
    id: STATUS_REJECTED,
    label: "Demande refusée",
  },
  {
    id: STATUS_RESTITUTION_STANDBY,
    label: "Restitution en stand by",
  },
  {
    id: STATUS_RETURNED,
    label: "Restitué",
  },
  {
    id: STATUS_VALIDATED,
    label: "Validé",
  },
]
