import { useMutation, useQuery } from "@tanstack/react-query"
import {
  addCostOfWork,
  addCostOfWorkDocuments,
  getCostOfWork,
  getCostOfWorkDocuments,
  getCostOfWorkHistory,
  getCostOfWorkStudyGedDocuments,
  listCostOfWorkStudies,
  updateCostOfWork,
} from "core/api/costsOfWork"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  StudyCostOfWorkDashboard,
  TCostOfWork,
  TMutateCostOfWorkDocuments,
} from "shared/types/costOfWork.type"
import { TDocumentCategory, TGedDocuments } from "shared/types/ged.type"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"

function useAddCostOfWork() {
  return useMutation<TCostOfWork, CustomAxiosError, TCostOfWork>({
    mutationFn: (cow) => addCostOfWork(cow),
  })
}

function useUpdateCostOfWork(id: number) {
  return useMutation<TCostOfWork, CustomAxiosError, TCostOfWork>({
    mutationFn: (cow) => updateCostOfWork(id, cow),
  })
}
function useAddCostOfWorkDocuments(id: number) {
  return useMutation<
    TMutateCostOfWorkDocuments,
    CustomAxiosError,
    TMutateCostOfWorkDocuments
  >({ mutationFn: (data) => addCostOfWorkDocuments(id, data) })
}

function useGetCostOfWorkDocuments(id: number) {
  return useQuery<unknown, CustomAxiosError, TDocumentCategory[]>({
    queryKey: ["getCostOfWorkDocuments", id],
    queryFn: () => getCostOfWorkDocuments(id),
  })
}

function useGetCostOfWork(id: number) {
  return useQuery<TStudyRequest, CustomAxiosError>({
    queryKey: ["getCostOfWork", id],
    queryFn: () => getCostOfWork(id),
  })
}

function useGetCostOfWorkHistory(id: number) {
  return useQuery<TStudyRequestHistory[], CustomAxiosError>({
    queryKey: ["getCostOfWorkHistory", id],
    queryFn: () => getCostOfWorkHistory(id),
  })
}

function useGetCostOfWorkStudyDocuments(id?: string) {
  return useQuery<unknown, CustomAxiosError, TGedDocuments>({
    queryKey: ["getCostOfWorkGedDocuments"],
    queryFn: () => getCostOfWorkStudyGedDocuments(id),
  })
}

function useListCostOfWorkStudies(showAllReturned: boolean) {
  return useQuery<unknown, CustomAxiosError, StudyCostOfWorkDashboard>({
    queryKey: ["listCostOfWorkStudies", showAllReturned],
    queryFn: () => listCostOfWorkStudies(showAllReturned),
  })
}

export {
  useAddCostOfWork,
  useAddCostOfWorkDocuments,
  useGetCostOfWork,
  useGetCostOfWorkDocuments,
  useGetCostOfWorkHistory,
  useGetCostOfWorkStudyDocuments,
  useListCostOfWorkStudies,
  useUpdateCostOfWork,
}
