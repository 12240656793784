import "./Container.scss"

interface IContainerProps {
  children: React.ReactNode
  backgroundColor: "white" | "light-grey"
  paddingHorizontal?: 0 | 8 | 16 | 24 | 32 | 64 | 128
  paddingVertical?: 0 | 8 | 16 | 24 | 32 | 64 | 128
  marginTop?: 0 | 8 | 16 | 24 | 32 | 64 | 128
  minHeight?: string
  classNames?: string
}

export default function Container({
  children,
  backgroundColor = "white",
  paddingHorizontal = 0,
  paddingVertical = 0,
  marginTop = 0,
  minHeight,
  classNames,
}: IContainerProps) {
  return (
    <div
      style={{
        padding: `${paddingVertical}px ${paddingHorizontal}px`,
        minHeight: `calc(${minHeight} - 3.5rem)`,
        marginTop: `${marginTop}px`,
        zIndex: 200,
      }}
      className={[
        classNames,
        backgroundColor === "white"
          ? "Background__White"
          : "Background__Light_Grey",
        "Container__Wrapper",
      ].join(" ")}
    >
      {children}
    </div>
  )
}
