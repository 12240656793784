import classNames from "classnames"
import { AuthContext } from "core/auth/AuthProvider"
import { useListPinnedProjectsByUser } from "core/query-hooks/useUsers"
import { t } from "i18next"
import { useContext, useRef, useState } from "react"
import { BsPinAngleFill } from "react-icons/bs"
import { Link } from "react-router-dom"
import { useOnClickOutside } from "usehooks-ts"

type OnCloseMenu = () => void

interface ISidebarItemPinnedProjectsAndOperations {
  closeSidebar: () => void
}

function RenderPinnedProjectsAndOperations({
  onCloseMenu,
}: {
  onCloseMenu: OnCloseMenu
}) {
  const { getUserInfo } = useContext(AuthContext)
  const userInfo = getUserInfo()
  const { data: pinnedProjects } = useListPinnedProjectsByUser(userInfo.azureId)
  return (
    <>
      <h3 className="font-semibold">{t("pinned-projects")}</h3>
      <ul>
        {pinnedProjects
          ?.filter((project) => project.isRegistered === false)
          .sort((a, b) => {
            const nameA = a.project.name.toUpperCase() // Convertir en majuscules pour ignorer la casse
            const nameB = b.project.name.toUpperCase()

            if (nameA < nameB) {
              return -1 // a vient avant b
            }
            if (nameA > nameB) {
              return 1 // a vient après b
            }
            return 0 // Les noms sont égaux
          })
          .map((project) => (
            <li className="Sidebar__Item" key={project.project.id}>
              <Link
                to={
                  project.project.intranetUrlSci5
                    ? project.project.intranetUrlSci5
                    : `/project/${project.project.id}`
                }
                target={project.project.intranetUrlSci5 ? "_blank" : "_self"}
                className="Sidebar__Item_Content"
                onClick={onCloseMenu}
              >
                <span className="Sidebar__Item_TextLink">
                  {project.project.name}
                </span>
              </Link>
            </li>
          ))}
      </ul>

      <h3 className="font-semibold mt-2">{t("pinned-operations")}</h3>
      <ul>
        {pinnedProjects
          ?.filter((project) => project.isRegistered)
          .sort((a, b) => {
            const nameA = a.project.name.toUpperCase() // Convertir en majuscules pour ignorer la casse
            const nameB = b.project.name.toUpperCase()

            if (nameA < nameB) {
              return -1 // a vient avant b
            }
            if (nameA > nameB) {
              return 1 // a vient après b
            }
            return 0 // Les noms sont égaux
          })
          .map((project) => (
            <li className="Sidebar__Item" key={project.project.id}>
              <Link
                to={
                  project.project.intranetUrlSci5
                    ? project.project.intranetUrlSci5
                    : `/operation/${project.project.id}`
                }
                target={project.project.intranetUrlSci5 ? "_blank" : "_self"}
                className="Sidebar__Item_Content"
                onClick={onCloseMenu}
              >
                <span className="Sidebar__Item_TextLink">
                  {project.project.name}
                </span>
              </Link>
            </li>
          ))}
      </ul>
    </>
  )
}

export default function SidebarItemPinnedProjectsAndOperations({
  closeSidebar,
}: ISidebarItemPinnedProjectsAndOperations) {
  const [isCliked, setIsClicked] = useState(false)
  const SidebarItemRef = useRef(null)
  useOnClickOutside(SidebarItemRef, () => setIsClicked(false))

  const handleCloseMenu = () => {
    setIsClicked(false)
    closeSidebar()
  }

  return (
    <li ref={SidebarItemRef}>
      <button
        data-title={t("favorite")}
        onClick={() => setIsClicked(!isCliked)}
        className="sidebar-title flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 group"
        type="button"
      >
        <BsPinAngleFill
          className={classNames(
            "w-6 h-6 transition duration-75 group-hover:text-sky-800",
            {
              "text-sky-800": isCliked,
              "text-gray-500": !isCliked,
            },
          )}
        />
      </button>
      {isCliked && (
        <div className="sidebar-child-container p-4">
          <RenderPinnedProjectsAndOperations onCloseMenu={handleCloseMenu} />
        </div>
      )}
    </li>
  )
}
