import { useGetProjectChildren } from "core/query-hooks/useProjects"
import { t } from "i18next"

interface FormatProjectChildrenProps {
  projectId: string
}

export default function FormatProjectChildren({
  projectId,
}: FormatProjectChildrenProps) {
  const { data: childRecords } = useGetProjectChildren(projectId)

  if (childRecords) {
    const { plots, agreements, actions } = childRecords

    return (
      <div className="text-sm mb-4">
        {plots.length > 0 || agreements.length > 0 || actions.length > 0 ? (
          <p className="pb-3">
            {t("project.delete.descriptionWithChildren1")}{" "}
            <b>{t("project.delete.deletion")}</b>{" "}
            {t("project.delete.descriptionWithChildren2")} :
          </p>
        ) : (
          <p className="pb-3">{t("project.delete.description")}</p>
        )}
        <div className="ml-4">
          {plots.length > 0 && (
            <ul className="pt-1">
              <b>{t("project.plots")} :</b>
              {plots.map((plot) => (
                <li key={plot.id}>
                  - {plot.prefix} {plot.section} {plot.number}
                </li>
              ))}
            </ul>
          )}

          {agreements.length > 0 && (
            <ul className="pt-1">
              <b>{t("project.agreements")} :</b>
              {agreements.map((agreement) => (
                <li key={agreement.id}>- {agreement.name}</li>
              ))}
            </ul>
          )}

          {actions.length > 0 && (
            <ul className="pt-1">
              <b>
                {actions.length} {t("actions")}
              </b>
            </ul>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="text-sm mb-4">
      <p className="pb-3">{t("project.delete.description")}</p>
    </div>
  )
}
