import { ErrorMessage } from "@hookform/error-message"
import classNames from "classnames"
import get from "lodash/get"
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form"
import { TCurrency } from "../../shared/types/global.type"
import DateField from "../DateField/DateField"
import SelectField from "../SelectField/SelectField"
import TextAreaField from "../TextAreaField/TextAreaField"
import { TextField, TextFieldsProps } from "../TextField/TextField"
import Toggle from "../Toggle/Toggle"
import { FormErrorMessage } from "./FormErrorMessage"

export type FormInputProps<TFormValues extends FieldValues> = {
  label: string
  name: Path<TFormValues>
  rightLabel?: string
  leftLabel?: string
  register?: UseFormRegister<TFormValues>
  control?: Control<TFormValues>
  errors?: FieldErrorsImpl<TFormValues>
  setPriceValue?: React.Dispatch<
    React.SetStateAction<{
      value?: number
      unit: TCurrency
    }>
  >
} & Omit<TextFieldsProps, "name">

export type RenderInputProps = {
  type: string
  rows: number
  cols: number
  label: string
  rightLabel?: string
  leftLabel?: string
  name: string
  hasError: boolean
  className: string | undefined
  register: any
  setPriceValue?: React.Dispatch<
    React.SetStateAction<{
      value?: number
      unit: TCurrency
    }>
  >
}

function RenderInput({
  type,
  rows,
  cols,
  label,
  name,
  hasError,
  className,
  register,
  rightLabel,
  leftLabel,
  setPriceValue,
  ...props
}: RenderInputProps) {
  if (type === "textarea") {
    return (
      <TextAreaField
        rows={0}
        cols={0}
        label={label}
        name={name}
        aria-invalid={hasError}
        className={classNames({
          Focus__Error: hasError,
        })}
        {...props}
        {...(register && register(name))}
      />
    )
  }

  if (type === "checkbox") {
    return (
      <Toggle
        label={label}
        name={name}
        rightLabel={rightLabel}
        leftLabel={leftLabel}
        aria-invalid={hasError}
        className={classNames({
          Focus__Error: hasError,
        })}
        {...props}
        {...(register && register(name))}
      />
    )
  }

  if (type === "date") {
    return (
      <DateField
        label={label}
        name={name}
        aria-invalid={hasError}
        className={classNames({
          Focus__Error: hasError,
        })}
        {...props}
        {...(register && register(name))}
      />
    )
  }

  if (type === "select") {
    return (
      <SelectField
        label={label}
        name={name}
        aria-invalid={hasError}
        className={classNames({
          Focus__Error: hasError,
        })}
        {...props}
        {...(register && register(name))}
      />
    )
  }

  return (
    <TextField
      setPriceValue={setPriceValue}
      type={type}
      name={name}
      aria-invalid={hasError}
      className={classNames({
        Focus__Error: hasError,
      })}
      {...props}
      {...(register && register(name))}
    />
  )
}

export function FormTextField<TFormValues extends Record<string, unknown>>({
  name,
  register,
  errors,
  className,
  setPriceValue,
  rightLabel,
  leftLabel,
  label,
  type,
  ...props
}: FormInputProps<TFormValues>): JSX.Element {
  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)

  return (
    <div className={classNames(className, "relative mb-4")} aria-live="polite">
      <label htmlFor={name}>
        {label}
        <RenderInput
          rows={3}
          cols={30}
          label={label}
          rightLabel={rightLabel}
          leftLabel={leftLabel}
          hasError={hasError}
          register={register}
          setPriceValue={setPriceValue}
          type={type}
          name={name}
          aria-invalid={hasError}
          className={classNames({
            Focus__Error: hasError,
          })}
          {...props}
          {...(register && register(name))}
        />
      </label>
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className="mt-1">{message}</FormErrorMessage>
        )}
      />
    </div>
  )
}
