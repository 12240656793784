import { useGetCurrentUser } from "core/query-hooks/useUsers"
import * as React from "react"

export interface IPermissionsGuardProps extends React.PropsWithChildren {
  requiredRoles: string[]
  excludedRoles?: string[]
}

export function PermissionsGuard(props: IPermissionsGuardProps) {
  const { data: user } = useGetCurrentUser()
  const { children, requiredRoles, excludedRoles } = props

  const hasPermission = requiredRoles.some((requiredRole) =>
    user?.rights.includes(requiredRole),
  )

  const hasExcludedRoles = excludedRoles
    ? excludedRoles.some((requiredRole) => user?.rights.includes(requiredRole))
    : false

  if (!hasPermission || hasExcludedRoles) return null

  return <>{children}</>
}
