import { useQuery } from "@tanstack/react-query"
import {
  getBudgetOverview,
  getSyntheseFinanciere,
  getSyntheseFinanciereSum,
} from "core/api/syntheseFinanciere"
import { CustomAxiosError } from "shared/types/axios.type"
import { TBudget, TBudgetOverview } from "shared/types/spo/budget.type"

function useGetSyntheseFinanciere(childOperationId?: string) {
  return useQuery<TBudget[], CustomAxiosError>({
    queryKey: ["getSyntheseFinanciereByChildOperationid", childOperationId],
    queryFn: () => getSyntheseFinanciere(childOperationId),
    ...{
      retry: false,
    },
  })
}

function useGetSyntheseFinanciereSum(childOperationIds: string[]) {
  return useQuery<TBudget[], CustomAxiosError>({
    queryKey: ["getSyntheseFinanciereByOperation", childOperationIds],
    queryFn: () => getSyntheseFinanciereSum(childOperationIds),
    ...{
      retry: false,
    },
  })
}

function useGetBudgetOverview(operationId: number) {
  return useQuery<TBudgetOverview, CustomAxiosError>({
    queryKey: ["getBudgetOverviewByOperationId", operationId],
    queryFn: () => getBudgetOverview(operationId),
    ...{
      staleTime: 180000,
      retry: (_, error) => {
        if (
          error?.response &&
          (error.response.status === 404 || error.response.status === 418)
        )
          return false
        return true
      },
    },
  })
}

export {
  useGetBudgetOverview,
  useGetSyntheseFinanciere,
  useGetSyntheseFinanciereSum,
}
