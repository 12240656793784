import { API } from "core/api/axios"
import {
  TFeasibility,
  TMutateFeasibilityDocuments,
  TStudyFeasibilityDashboard,
} from "shared/types/feasibility.type"
import { TDocumentCategory, TGedDocuments } from "shared/types/ged.type"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"

async function createFeasibility(data: TFeasibility): Promise<TFeasibility> {
  const res = await API.post("/feasibility", data)
  return res.data
}

async function updateFeasibility(
  id: number,
  data: TFeasibility,
): Promise<TFeasibility> {
  const res = await API.patch(`/feasibility/${id}`, data)
  return res.data
}

async function getFeasibilityDocuments(
  id: number,
): Promise<TDocumentCategory[]> {
  const res = await API.get(`/document-categories?studyRequestType=${id}`)
  return res.data
}

async function getFeasibilityGedDocuments(
  feasibilityId?: string,
): Promise<TGedDocuments> {
  return typeof feasibilityId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/feasibilities/${feasibilityId}/documents`).then(
        (res) => res.data,
      )
}

async function addFeasibilityDocuments(
  id: number,
  data: TMutateFeasibilityDocuments,
) {
  const res = await API.post(`/feasibilities/${id}/documents`, data)
  return res.data
}

async function getFeasibility(id: number): Promise<TStudyRequest> {
  return typeof id === "undefined" || Number.isNaN(id)
    ? Promise.reject(new Error("Invalid feasibility id"))
    : API.get(`/feasibilities/${id}`).then((res) => res.data)
}

async function getFeasibilityHistory(
  id: number,
): Promise<TStudyRequestHistory[]> {
  const res = await API.get(`/feasibilities/${id}/history`)
  return res.data
}

async function listFeasibilities(projectId: number): Promise<TFeasibility[]> {
  const res = await API.get(`/feasibilities?project=${projectId}`)
  return res.data
}

async function listFeasibilitiesStudies(
  showAllReturned: boolean,
): Promise<TStudyFeasibilityDashboard> {
  const res = await API.get(
    `/feasibilities/dashboard?showAllReturned=${showAllReturned}`,
  )
  return res.data
}

export {
  addFeasibilityDocuments,
  createFeasibility,
  getFeasibility,
  getFeasibilityDocuments,
  getFeasibilityGedDocuments,
  getFeasibilityHistory,
  listFeasibilities,
  listFeasibilitiesStudies,
  updateFeasibility,
}
