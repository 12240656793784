import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { TDocumentTypeList } from "shared/types/ged.type"
import listDocumentTypesByViewTag from "../api/document-types"

function useListDocumentTypesByViewTag(viewTag: number) {
  return useQuery<TDocumentTypeList, AxiosError>({
    queryKey: ["listDocumentTypesByViewTag"],
    queryFn: () => listDocumentTypesByViewTag(viewTag),
  })
}

export default useListDocumentTypesByViewTag
