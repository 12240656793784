import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import { Modal } from "components/Modal/Modal"
import { useDeleteSimplifiedProgram } from "core/query-hooks/useSimplifiedPrograms"
import { t } from "i18next"
import { toast } from "react-toastify"

export interface IDeleteSimplifiedProgramProps {
  isDeleteSimplifiedProgramModalShowed: boolean
  toggleDeleteSimplifiedProgramModal: () => void
  simplifiedProgramId: number
}

export function DeleteSimplifiedProgram(props: IDeleteSimplifiedProgramProps) {
  const {
    isDeleteSimplifiedProgramModalShowed,
    toggleDeleteSimplifiedProgramModal,
    simplifiedProgramId,
  } = props

  const queryClient = useQueryClient()

  const deleteSimplifiedProgram =
    useDeleteSimplifiedProgram(simplifiedProgramId)

  function handleDeleteSimplifiedProgram() {
    deleteSimplifiedProgram.mutate(undefined, {
      async onSuccess() {
        queryClient.invalidateQueries({
          queryKey: ["getSimplifiedPrograms"],
        })
        toast.success(t("toast-deleted-simplified-program"))
        toggleDeleteSimplifiedProgramModal()
      },
      onError() {
        toast.error(t("toast-error-delete-simplified-program"))
      },
    })
  }

  return (
    <Modal
      isShowing={isDeleteSimplifiedProgramModalShowed}
      closeModal={toggleDeleteSimplifiedProgramModal}
      title={`${t("delete-simplified-program")}`}
    >
      <div className="flex items-center justify-between mt-2">
        <Button
          onClick={() => toggleDeleteSimplifiedProgramModal()}
          size="small"
          mode="secondary"
        >
          {`${t("cancel")}`}
        </Button>

        <Button
          onClick={() => handleDeleteSimplifiedProgram()}
          isLoading={deleteSimplifiedProgram.isPending}
          type="submit"
          size="small"
          mode="danger"
        >
          {`${t("confirm")}`}
        </Button>
      </div>
    </Modal>
  )
}
