import Box from "components/Box/Box"
import { useGetFeasibility } from "core/query-hooks/useFeasibilities"
import { format } from "date-fns"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { useParams } from "react-router-dom"
import { DEVELOPER_COMMENT } from "shared/resources/study-request.resources"
import { TStudyRequest } from "shared/types/study.type"

function countBatches(studyRequest: TStudyRequest): number {
  let count: number = 0
  studyRequest?.collective ? (count += studyRequest.collective) : null
  studyRequest?.veb ? (count += studyRequest.veb) : null
  studyRequest?.demeuresDeLouise
    ? (count += studyRequest.demeuresDeLouise)
    : null
  studyRequest?.office ? (count += studyRequest.office) : null
  studyRequest?.businessPark ? (count += studyRequest.businessPark) : null
  studyRequest?.buildingLot ? (count += studyRequest.buildingLot) : null
  studyRequest?.house ? (count += studyRequest.house) : null
  studyRequest?.semiCollective ? (count += studyRequest.semiCollective) : null

  return count
}

export default function FeasibilityDetails() {
  const { studyRequestId } = useParams()
  const { data: feasibilityData } = useGetFeasibility(+studyRequestId!)
  const totalLots = countBatches(feasibilityData!)
  const feasibilityDeveloperComments = feasibilityData?.comments
    ? feasibilityData.comments.filter(
        (comment) => comment.type.id === DEVELOPER_COMMENT.id,
      )
    : []

  return (
    <>
      <Box classNames="flex flex-col Container">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("concerned-service")}`}
        </p>
        <p className="Color__Primary">{`${t("design")}`}</p>
      </Box>
      <Box classNames="flex flex-col Container my-4">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("batches-number")}`}
        </p>
        <div className="Text__Field_Container pr-2">
          <table className="w-full">
            <tbody className="Text__Field_Container text-cyan-950">
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary" colSpan={2}>
                  {t("collective")}
                </td>
                <td className="text-right">
                  {feasibilityData?.collective ? feasibilityData.collective : 0}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary" colSpan={2}>
                  {t("veb")}
                </td>
                <td className="text-right">
                  {feasibilityData?.veb ? feasibilityData.veb : 0}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary" colSpan={2}>
                  {t("demeure-de-louise")}
                </td>
                <td className="text-right">
                  {feasibilityData?.demeuresDeLouise
                    ? feasibilityData.demeuresDeLouise
                    : 0}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary" colSpan={2}>
                  {t("offices")}
                </td>
                <td className="text-right">
                  {feasibilityData?.office ? feasibilityData.office : 0}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary" colSpan={2}>
                  {t("business-park")}
                </td>
                <td className="text-right">
                  {feasibilityData?.businessPark
                    ? feasibilityData.businessPark
                    : 0}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary" colSpan={2}>
                  {t("building-lot")}
                </td>
                <td className="text-right">
                  {feasibilityData?.buildingLot
                    ? feasibilityData.buildingLot
                    : 0}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary" colSpan={2}>
                  {t("houses")}
                </td>
                <td className="text-right">
                  {feasibilityData?.house ? feasibilityData.house : 0}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary" colSpan={2}>
                  {t("semi-collective")}
                </td>
                <td className="text-right">
                  {feasibilityData?.semiCollective
                    ? feasibilityData.semiCollective
                    : 0}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors font-bold">
                <td className="text-sm Color__Primary" colSpan={2}>
                  {t("total")}
                </td>
                <td className="text-right">{totalLots}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
      <Box classNames="flex flex-row Container my-4 justify-between items-center">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("studyRequest.costOfWork.margin")}`}
        </p>
        <div className="Color__Primary">
          <p>
            {feasibilityData?.marginPercentage
              ? `${feasibilityData.marginPercentage} %`
              : `0 %`}
          </p>
        </div>
      </Box>
      <Box classNames="flex flex-row Container my-4 justify-between items-center">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("cashflow")}`}
        </p>
        <div className="Color__Primary">
          <p>
            {feasibilityData?.cashflow
              ? `${feasibilityData.cashflow.toLocaleString("fr-FR")} €`
              : `0 €`}
          </p>
        </div>
      </Box>
      <Box classNames="flex flex-col Container lg:mb-4">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("comment")}`}
        </p>
        {feasibilityDeveloperComments.length > 0 ? (
          feasibilityDeveloperComments.map((comment, index) => (
            <p
              className="Color__Primary break-all whitespace-pre-line"
              key={nanoid()}
            >
              {index === 0
                ? comment.comment
                : `Mis à jour le ${format(
                    new Date(comment.creationDate),
                    "dd/MM/yyyy HH:mm",
                  )} : ${comment.comment}`}
            </p>
          ))
        ) : (
          <p className="Color__Primary">{`${t("no-comment")}`}</p>
        )}
      </Box>
    </>
  )
}
