import {
  TCopromoter,
  TCopromoterAssociate,
  TMutateCopromoter,
} from "shared/types/copromoter.type"
import { API } from "./axios"

async function createCopromoter(
  copromoter: TMutateCopromoter,
): Promise<TCopromoter> {
  return API.post(`/copromoter`, copromoter).then((response) => response.data)
}

async function updateCopromoter(
  copromoterId: number,
  data: TMutateCopromoter,
): Promise<TCopromoter> {
  const res = await API.patch(`/copromoter/${copromoterId}`, data)
  return res.data
}

async function listCopromotersByOperation(
  operationId: number,
): Promise<TCopromoter[]> {
  return typeof operationId === "undefined"
    ? Promise.reject(new Error("Invalid operation id"))
    : API.get(`/copromoters?operation=${operationId}`).then(
        (response) => response.data,
      )
}

async function listCopromoterAssociates(): Promise<TCopromoterAssociate[]> {
  const res = await API.get("/copromoter-associates")
  return res.data
}

async function addCopromoterAssociate(data: {
  label: string
}): Promise<TCopromoterAssociate> {
  const res = await API.post("/copromoter-associate", data)
  return res.data
}

export {
  addCopromoterAssociate,
  createCopromoter,
  listCopromoterAssociates,
  listCopromotersByOperation,
  updateCopromoter,
}
