import Spinner from "components/Spinner/Spinner"
import { useGetSuspensiveConditionsByOperationId } from "core/query-hooks/useSuspensiveConditions"
import { addMonths } from "date-fns"
import { useParams } from "react-router-dom"
import SuspensiveConditionsList from "./SuspensiveConditionsList"

export default function SuspensiveConditionsContainer() {
  const { id } = useParams()
  const inThreeMonths = addMonths(new Date(), 3)

  const {
    data: suspensiveConditions,
    isLoading,
    error,
  } = useGetSuspensiveConditionsByOperationId(
    id!,
    inThreeMonths,
    undefined,
    false,
  )

  if (isLoading) return <Spinner />
  if (error || !suspensiveConditions)
    return <p>{error?.response?.data.error.message}</p>

  return <SuspensiveConditionsList data={suspensiveConditions} />
}
