import { useMutation, useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TMutateProject,
  TProject,
  TProjectChildren,
  TProjectCreate,
  TProjectsList,
} from "shared/types/project.type"
import {
  createProject,
  deleteProject,
  getProjectById,
  getProjectChildren,
  getProjectsByQuery,
  listProjects,
  updateProject,
} from "../api/projects"

function useCreateProject() {
  return useMutation<TProject, CustomAxiosError, TProjectCreate>({
    mutationFn: (project) => createProject(project),
  })
}

function useProject(id: string) {
  return useQuery<TProject, CustomAxiosError, TProject>({
    queryKey: ["project", id],
    queryFn: () => getProjectById(id),
  })
}

function useUpdateProject(id: number) {
  return useMutation<TProject, CustomAxiosError, Partial<TMutateProject>>({
    mutationFn: (project) => updateProject(id, project),
  })
}

function useListProjectsWithParams(params?: URLSearchParams) {
  return useQuery<TProjectsList, CustomAxiosError, TProjectsList>({
    queryKey: ["listProjectsWithParams", params],
    queryFn: () => listProjects(params),
    ...{
      retry: false,
    },
  })
}

function useSearchProjectsByQuery(query: string) {
  return useQuery<TProjectsList, CustomAxiosError, TProjectsList>({
    queryKey: ["searchProjectsByQuery", query],
    queryFn: () => getProjectsByQuery(query),
    ...{ enabled: Boolean(query) },
  })
}

function useDeleteProject(id: string) {
  return useMutation<TProject, CustomAxiosError, string>({
    mutationFn: () => deleteProject(id),
  })
}

function useGetProjectChildren(id: string) {
  return useQuery<TProjectChildren, CustomAxiosError>({
    queryKey: ["getProjectChildren", id],
    queryFn: () => getProjectChildren(id),
  })
}

export {
  useCreateProject,
  useDeleteProject,
  useGetProjectChildren,
  useListProjectsWithParams,
  useProject,
  useSearchProjectsByQuery,
  useUpdateProject,
}
