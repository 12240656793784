import ItemsCounter from "components/Counter/ItemsCounter"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { t } from "i18next"
import { BiTask } from "react-icons/bi"
import { Link } from "react-router-dom"
import useCountTeamTodos from "./useCountTeamTodos"

export default function CountTeamTodosContainer() {
  const { error, isLoading, lateTodos, countIncomingTodos } =
    useCountTeamTodos()

  return (
    <Link to={{ pathname: "/expected-tasks/team" }}>
      <TooltipWithHover message={t("alvis-team-tasks-number")} position="right">
        <ItemsCounter
          totalLateItems={lateTodos?.length || 0}
          totalItems={countIncomingTodos ?? 0}
          title="alvis"
          Icon={<BiTask fontSize={18} />}
          isLoading={isLoading}
          errorMessage={t("error-loading-todos")}
          error={error}
        />
      </TooltipWithHover>
    </Link>
  )
}
