import React from "react"

interface CprToggleProps {
  id: string
  name: string
  checked: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
  size?: "small" | "medium" | "large"
}

function CprToggle({
  id,
  name,
  checked,
  onChange,
  disabled = false,
  size = "medium",
}: CprToggleProps) {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Enter") return
    e.preventDefault()
    onChange(!checked)
  }

  const sizes = {
    small: "w-8 h-4",
    medium: "w-12 h-6",
    large: "w-16 h-8",
  }

  const switchSizes = {
    small: "w-3 h-3",
    medium: "w-5 h-5",
    large: "w-7 h-7",
  }

  const translateSizes = {
    small: "translate-x-4",
    medium: "translate-x-6",
    large: "translate-x-8",
  }

  return (
    <div
      className={`relative inline-block ${sizes[size]} ${
        disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={() => !disabled && onChange(!checked)}
      onKeyDown={(e) => !disabled && handleKeyPress(e)}
      role="checkbox"
      aria-checked={checked}
      tabIndex={disabled ? -1 : 0}
    >
      <input
        type="checkbox"
        name={name}
        id={id}
        className="sr-only"
        checked={checked}
        onChange={() => onChange(!checked)}
        disabled={disabled}
      />
      <div
        className={`absolute top-0 left-0 bottom-0 right-0 rounded-full transition-colors ${
          checked ? "bg-green-800" : "bg-gray-400"
        }`}
      />
      <div
        className={`absolute top-0.5 left-0.5 bg-white rounded-full transition-transform ${
          switchSizes[size]
        } ${checked ? translateSizes[size] : ""}`}
      />
    </div>
  )
}

export default CprToggle
