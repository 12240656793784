import classNames from "classnames"
import GlobalSearchInput from "components/GlobalSearch/GlobalSearchInput"
import Alvis from "components/Icons/Alvis"
import Sidebar from "components/Sidebar/Sidebar"
import { AuthContext } from "core/auth/AuthProvider"
import { useGetUserByAzureId } from "core/query-hooks/useUsers"
import { t } from "i18next"
import { useContext } from "react"
import { useSignOut } from "react-firebase-hooks/auth"
import { MdOutlineLogout, MdOutlineAdminPanelSettings } from "react-icons/md"
import { BiSolidErrorAlt } from "react-icons/bi"
import { Link, Outlet, ScrollRestoration, useNavigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { auth } from "utils/firebase"
import QueryBoundaries from "core/QueryBoundaries"
import Spinner from "components/Spinner/Spinner"
import Avatar from "../components/Avatar/Avatar"
import Close from "../components/Icons/Close"
import Menu from "../components/Icons/Menu"
import Layout from "../components/Layout/Layout"
import Navbar from "../components/Navbar/Navbar"
import useSidebar from "../components/Sidebar/useSidebar"
import "../translations/i18n"

function MainLayout() {
  const { getUserInfo } = useContext(AuthContext)
  const userInfo = getUserInfo()

  const { isShowing: isSidebarShowed, toggle: toggleSidebar } = useSidebar()

  const { data: user, isLoading, error } = useGetUserByAzureId(userInfo.azureId)
  const [signOut] = useSignOut(auth)

  const navigate = useNavigate()

  const logout = async () => {
    const success = await signOut()
    if (success) {
      localStorage.removeItem("token")
      localStorage.removeItem("gedCookie")
      localStorage.removeItem("firebaseId")
      navigate("/login")
      alert(`${t("disconnectedMsg")}`)
    }
  }

  function displayErrorMessage() {
    if (
      error &&
      error.response?.status &&
      (error.response.status === 403 || error.response.status === 404)
    ) {
      return (
        <div className="flex items-center justify-center h-screen px-4 text-indigo-950">
          <div className="bg-white p-8 rounded-lg shadow-xl border border-gray-100">
            <div className="flex justify-center mb-4">
              <MdOutlineAdminPanelSettings size={100} />
            </div>
            <div>
              <p className="text-lg text-center text-gray-800">
                {t("unsufficient-rights-for-this-page")}
              </p>
              <div className="text-center mt-3">
                <div
                  className="text-sm transition-colors ease-in-out delay-50 text-white hover:bg-primary-light bg-primary-dark rounded-lg px-4 py-2 cursor-pointer"
                  onClick={logout}
                >
                  {`${t("logout")}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="flex items-center justify-center h-screen px-4 text-indigo-950">
        <div className="bg-white p-8 rounded-lg shadow-xl border border-gray-100">
          <div className="flex justify-center mb-4">
            <BiSolidErrorAlt size={100} />
          </div>
          <div>
            <p className="text-lg text-center text-gray-800">
              {t("an-error-has-occurred")}.
            </p>
            <p className="text-lg text-center text-gray-800">
              {t("please-contact-support")}
            </p>
            {error?.message ? (
              <span className="text-center text-xs text-gray-500">
                ({error.message} / {error.response?.data?.message})
              </span>
            ) : null}
            <div className="text-center mt-3">
              <div
                className="text-sm transition-colors ease-in-out delay-50 text-white hover:bg-primary-light bg-primary-dark rounded-lg px-4 py-2 cursor-pointer"
                onClick={logout}
              >
                {`${t("logout")}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {isLoading && (
        <div className="flex items-center justify-center pt-8 w-full">
          <Spinner />
        </div>
      )}
      {error && displayErrorMessage()}
      {user && !error && (
        <QueryBoundaries loadingComponent={<Spinner />}>
          <Layout>
            <Navbar>
              <div className="Navbar__Elements_Container">
                <Link className="Navbar__Logo" to="/">
                  <Alvis />
                  <span className="pt-2.5">
                    {process.env.REACT_APP_ENV !== "prod"
                      ? process.env.REACT_APP_ENV?.toUpperCase()
                      : null}
                  </span>
                </Link>

                <button
                  type="button"
                  onClick={() => toggleSidebar()}
                  className="Navbar__Toggle"
                >
                  {!isSidebarShowed ? (
                    <Menu width={24} height={24} color="#00304D" />
                  ) : (
                    <Close width={24} height={24} color="#00304D" />
                  )}
                </button>
                <GlobalSearchInput />
                <div className="flex items-center">
                  <button
                    type="button"
                    className="Navbar__Item"
                    onClick={() => null}
                  >
                    <Avatar user={user!} />
                  </button>
                  <MdOutlineLogout
                    id="logout"
                    className="text-white ml-2 cursor-pointer"
                    onClick={logout}
                  />
                </div>
              </div>
            </Navbar>
            <div className="Sidebar__Wrapper">
              <div
                className={classNames("Sidebar__Container", {
                  Sidebar__Show: isSidebarShowed,
                })}
              >
                <Sidebar toggleSidebar={toggleSidebar} />
              </div>
            </div>
            <div
              style={{ minHeight: "calc(100vh - 3.5rem)" }}
              className="Route__Container Background__Light_Grey"
            >
              <Outlet />
              <ScrollRestoration />
            </div>

            <ToastContainer />
          </Layout>
        </QueryBoundaries>
      )}
    </>
  )
}

export default MainLayout
