import useModal from "components/Modal/useModal"
import { t } from "i18next"
import { MdSearch } from "react-icons/md"
import { useMediaQuery } from "usehooks-ts"
import "./GlobalSearchInput.scss"
import { GlobalSearchModal } from "./GlobalSearchModal"

export default function GlobalSearchInput() {
  const { isShowing, toggle } = useModal()

  const isMobile = useMediaQuery("(max-width: 768px)")

  return (
    <div className="px-2 md:px-0">
      <div className="relative">
        <button
          type="button"
          onClick={toggle}
          className="border border-gray-200 bg-zinc-100 rounded-full pl-8 pr-8 py-1 text-sky-900 text-sm"
        >
          {isMobile ? `${t("search")}` : `${t("search-project-op")}`}
        </button>
        <MdSearch color="#00304D" className="absolute Search__Icon" />
      </div>
      {isShowing && (
        <GlobalSearchModal isShowing={isShowing} closeModal={() => toggle()} />
      )}
    </div>
  )
}
