import Box from "components/Box/Box"
import GedFile from "components/Ged/GedFile"
import Spinner from "components/Spinner/Spinner"
import { useGetDocumentsByStudyRequestTypeAndProjectId } from "core/ged/query-hooks/useGed"
import { useGetStudyRequestDocuments } from "core/query-hooks/useStudyRequests"
import {
  FEASIBILITY_RESTITUTION_GED_CAT,
  TYPE_FEASIBILITY,
} from "features/studies/studyRequest.resources"
import { useParams } from "react-router-dom"

export default function FeasibilityDocumentsPage() {
  const { id, studyRequestId } = useParams()
  const { data, isLoading } = useGetStudyRequestDocuments(+studyRequestId!)
  const { data: documents, isLoading: isLoadingFiles } =
    useGetDocumentsByStudyRequestTypeAndProjectId(+id!, TYPE_FEASIBILITY)

  return (
    <ul>
      {isLoading && isLoadingFiles && <Spinner />}
      {!isLoading &&
        !isLoadingFiles &&
        data?.documents.map((category) =>
          category.category.id !== FEASIBILITY_RESTITUTION_GED_CAT.id ? (
            <Box classNames="mb-4">
              <h3 className="Color__Primary font-semibold text-lg mb-3">
                {category.category.label}
              </h3>
              {category.gedDocuments.map((f) =>
                documents?.documentCategories.map((filesList) =>
                  filesList.gedDocuments.map(
                    (file) =>
                      file.id === f.id && (
                        <GedFile id={f.id} title={file.title} />
                      ),
                  ),
                ),
              )}
            </Box>
          ) : null,
        )}
    </ul>
  )
}
