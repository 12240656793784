import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useListBusinessUnits from "core/query-hooks/useBusinessUnits"
import useListPierrevalCompanies from "core/query-hooks/usePierrevalCompanies"
import { useAddSimplifiedProgram } from "core/query-hooks/useSimplifiedPrograms"
import { fr } from "date-fns/locale"
import SelectCityForm from "features/project/SelectCityForm"
import i18next, { t } from "i18next"
import { FormEvent, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { registerLocale } from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { TAddSimplifiedProgramForm } from "shared/types/simplified-programs.type"
import * as yup from "yup"

registerLocale("fr", fr)

export interface IAddSimplifiedProgramProps {
  isAddSimplifiedProgramModalShowed: boolean
  toggleSimplifiedProgramModal: () => void
}

const addSimplifiedProgramValidationSchema = yup.object({
  registrationNumber: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required(() => t("registration-number-required")),
  name: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  pierrevalCompanyId: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  businessUnitId: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  numberOfLots: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  reservation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  deed: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  provision: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  priceHT: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  pierrevalCapital: yup
    .number()
    .min(0, () => i18next.t("zero-or-greater"))
    .max(100, () => i18next.t("hundred-or-lower")),
  localisation: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .default(undefined),
})

export function AddSimplifiedProgram(props: IAddSimplifiedProgramProps) {
  const [reservationDate, setReservationDate] = useState<Date | null>(null)
  const [deedDate, setDeedDate] = useState<Date | null>(null)
  const [provisionDate, setProvisionDate] = useState<Date | null>(null)
  const { data: pierrevalCompanies } = useListPierrevalCompanies()
  const addSimplifiedProgram = useAddSimplifiedProgram()
  const queryClient = useQueryClient()
  const { data: businessUnits } = useListBusinessUnits()

  const { isAddSimplifiedProgramModalShowed, toggleSimplifiedProgramModal } =
    props

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TAddSimplifiedProgramForm>({
    resolver: yupResolver(
      addSimplifiedProgramValidationSchema as yup.ObjectSchema<TAddSimplifiedProgramForm>,
    ),
    defaultValues: {
      pierrevalCapital: 40,
    },
  })

  async function submitForm(simplifiedProgrammData: TAddSimplifiedProgramForm) {
    addSimplifiedProgram.mutateAsync(
      {
        registrationNumber: simplifiedProgrammData.registrationNumber,
        name: simplifiedProgrammData.name,
        pierrevalCompanyId: simplifiedProgrammData.pierrevalCompanyId,
        businessUnitId: simplifiedProgrammData.businessUnitId,
        numberOfLots: simplifiedProgrammData.numberOfLots,
        reservation: simplifiedProgrammData.reservation,
        deed: simplifiedProgrammData.deed,
        provision: simplifiedProgrammData.provision,
        priceHT: simplifiedProgrammData.priceHT,
        pierrevalCapital: simplifiedProgrammData.pierrevalCapital,
        city: simplifiedProgrammData.localisation
          ? simplifiedProgrammData.localisation.label
          : undefined,
        postcode: simplifiedProgrammData.localisation
          ? simplifiedProgrammData.localisation.value
          : undefined,
      },
      {
        async onSuccess() {
          queryClient.invalidateQueries({
            queryKey: ["getSimplifiedPrograms"],
          })
          toast.success(t("toast-added-simplified-program"))
          toggleSimplifiedProgramModal()
        },
        onError() {
          toast.error(t("toast-error-add-simplified-program"))
        },
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <div>
      <Modal
        isShowing={isAddSimplifiedProgramModalShowed}
        closeModal={toggleSimplifiedProgramModal}
        title={`${t("add-simplified-program")}`}
      >
        <form className="Text__Field_Container" onSubmit={onSubmit}>
          <div>
            <label htmlFor="registrationNumber">
              {t("registration-number")} *
              <input
                id="registrationNumber"
                className="Text__Field"
                placeholder={`${t("registration-number-placeholder")}`}
                type="text"
                {...register("registrationNumber")}
              />
            </label>
            {errors.registrationNumber && (
              <p className="text-xs text-red-500">
                {errors.registrationNumber?.message}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="name">
              {t("name")}
              <input
                id="name"
                className="Text__Field"
                placeholder={`${t("name-placeholder")}`}
                type="text"
                {...register("name")}
              />
            </label>
          </div>

          <div className="Text__Field_Container">
            <label htmlFor="pierrevalCompanyId">
              {t("pierreval-company")}
              <select
                id="pierrevalCompany"
                defaultValue=""
                className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                {...register("pierrevalCompanyId")}
              >
                <option key="company-disabled" disabled value="">
                  {t("choose-pierreval-company-option")}
                </option>
                {pierrevalCompanies?.map((company) => (
                  <option
                    key={`${company.id}-${company.label}`}
                    value={company.id}
                  >
                    {company.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="businessUnit">
              {t("business-unit-acronym")}
              <select
                id="businessUnit"
                defaultValue=""
                className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                {...register("businessUnitId")}
              >
                <option key="businessUnit-disabled" disabled value="">
                  {t("choose-bu-option")}
                </option>
                {businessUnits?.map((bu) => (
                  <option key={`${bu.id}-${bu.label}`} value={bu.id}>
                    {bu.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <SelectCityForm
            noOptionsMessage={() => `${t("no-results")}`}
            placeholder={`${t("search-city")}`}
            name="localisation"
            label={`${t("city")}`}
            className="w-full"
            control={control}
          />

          <label htmlFor="numberOfLots">
            {t("number-of-lots")}
            <input
              id="numberOfLots"
              className="Text__Field"
              placeholder={`${t("number-of-lots-placeholder")}`}
              type="number"
              {...register("numberOfLots")}
            />
          </label>

          <div className="flex flex-col mt-2">
            <label htmlFor="reservation">{t("reservation")}</label>
            <Controller
              name="reservation"
              control={control}
              render={({ field: { onChange } }) => (
                <CustomDatePicker
                  id="reservation"
                  className="Text__Field"
                  selected={reservationDate}
                  locale={fr}
                  placeholderText={`${t("select-date")}`}
                  onChange={(date) => {
                    if (date) setReservationDate(date)
                    onChange(date)
                  }}
                />
              )}
            />
          </div>

          <div className="flex flex-col mt-2">
            <label htmlFor="deed">{t("deed")}</label>
            <Controller
              name="deed"
              control={control}
              render={({ field: { onChange } }) => (
                <CustomDatePicker
                  id="deed"
                  className="Text__Field"
                  locale={fr}
                  selected={deedDate}
                  placeholderText={`${t("select-date")}`}
                  onChange={(date) => {
                    if (date) setDeedDate(date)
                    onChange(date)
                  }}
                />
              )}
            />
          </div>

          <div className="flex flex-col mt-2">
            <label htmlFor="provision">{t("provision")}</label>
            <Controller
              name="provision"
              control={control}
              render={({ field: { onChange } }) => (
                <CustomDatePicker
                  id="provision"
                  className="Text__Field"
                  locale={fr}
                  selected={provisionDate}
                  placeholderText={`${t("select-date")}`}
                  onChange={(date) => {
                    if (date) setProvisionDate(date)
                    onChange(date)
                  }}
                />
              )}
            />
          </div>

          <div className="flex flex-col mt-2">
            <label htmlFor="pierrevalCapital">{`${t(
              "pierreval-part-of-capital-in-percent",
            )}`}</label>
            <input
              id="label"
              className="Text__Field"
              {...register("pierrevalCapital")}
              type="number"
              step="0.01"
            />
            {errors.pierrevalCapital && (
              <span className="text-red-500 text-xs">
                {errors.pierrevalCapital.message}
              </span>
            )}
          </div>

          <div>
            <label htmlFor="priceHT">
              {t("price-ht")}
              <Controller
                name="priceHT"
                control={control}
                render={({ field: { onChange } }) => (
                  <CurrencyInput
                    placeholder={`${t("price-ht-placeholder")}`}
                    className="Text__Field"
                    allowDecimals={false}
                    onValueChange={(e) => {
                      onChange(Number(e))
                    }}
                    intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                  />
                )}
              />
            </label>
          </div>

          <div className="flex items-center justify-between mt-2">
            <Button
              onClick={() => toggleSimplifiedProgramModal()}
              size="small"
              mode="secondary"
            >
              {`${t("cancel")}`}
            </Button>

            <Button
              isLoading={addSimplifiedProgram.isPending}
              type="submit"
              size="small"
              mode="primary"
            >
              {`${t("create")}`}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}
