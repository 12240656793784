import React, { useCallback } from "react"
import { IIconType } from "../../shared/types/global.type"

type IconType = (props: IIconType) => JSX.Element

export interface ITabProps {
  setSelectedTab: (index: number) => void
  index: number
  title: string
  icon?: IconType
  isActive?: boolean
  iconSize?: number
}

export function Tab({
  setSelectedTab,
  index,
  title,
  isActive,
  icon,
  iconSize,
}: ITabProps) {
  const handleOnClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <li className={`${isActive && "Tab__Active"}`}>
      <button type="button" className="Tab__Button" onClick={handleOnClick}>
        {icon &&
          iconSize &&
          React.createElement(icon, {
            width: iconSize,
            height: iconSize,
            color: "#00304D",
          })}
        <span className={`${icon ? "Tab__Icon" : ""}`}>{title}</span>
      </button>
    </li>
  )
}
