import { useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import { TReservations } from "shared/types/spo/reservation.type"
import {
  getReservationsActeesByOperation,
  getReservationsActeesByTrancheCommerciale,
  getReservationsByOperation,
  getReservationsByTrancheCommerciale,
  getReservationsNonActeesByOperation,
  getReservationsNonActeesByTrancheCommerciale,
} from "../api/reservations"

function useGetReservationsByOperation(registrationId: string) {
  return useQuery<TReservations, CustomAxiosError>({
    queryKey: ["getReservationsByOperation", registrationId],
    queryFn: () => getReservationsByOperation(registrationId),
  })
}

function useGetReservationsNonActeesByOperation(registrationId: string) {
  return useQuery<TReservations, CustomAxiosError>({
    queryKey: ["getReservationsNonActeesByOperation", registrationId],
    queryFn: () => getReservationsNonActeesByOperation(registrationId),
  })
}

function useGetReservationsActeesByOperation(registrationId: string) {
  return useQuery<TReservations, CustomAxiosError>({
    queryKey: ["getReservationsActeesByOperation", registrationId],
    queryFn: () => getReservationsActeesByOperation(registrationId),
  })
}

function useGetReservationsByTrancheCommerciale(trancheCommercialeId: string) {
  return useQuery<TReservations, CustomAxiosError>({
    queryKey: ["getReservationsByTrancheCommerciale", trancheCommercialeId],
    queryFn: () => getReservationsByTrancheCommerciale(trancheCommercialeId),
  })
}

function useGetReservationsNonActeesByTrancheCommerciale(
  trancheCommercialeId: string,
) {
  return useQuery<TReservations, CustomAxiosError>({
    queryKey: [
      "getReservationsNonActeesByTrancheCommerciale",
      trancheCommercialeId,
    ],
    queryFn: () =>
      getReservationsNonActeesByTrancheCommerciale(trancheCommercialeId),
  })
}

function useGetReservationsActeesByTrancheCommerciale(
  trancheCommercialeId: string,
) {
  return useQuery<TReservations, CustomAxiosError>({
    queryKey: [
      "getReservationsActeesByTrancheCommerciale",
      trancheCommercialeId,
    ],
    queryFn: () =>
      getReservationsActeesByTrancheCommerciale(trancheCommercialeId),
  })
}

export {
  useGetReservationsActeesByOperation,
  useGetReservationsActeesByTrancheCommerciale,
  useGetReservationsByOperation,
  useGetReservationsByTrancheCommerciale,
  useGetReservationsNonActeesByOperation,
  useGetReservationsNonActeesByTrancheCommerciale,
}
