import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import getInfosByAddress from "core/api/open-street-map"
import { OsmInfos } from "shared/types/osm.type"

function useGetInfosByAddress(query: string) {
  return useQuery<OsmInfos[], AxiosError>({
    queryKey: ["getInfosByAddress", query],
    queryFn: () => getInfosByAddress(query),
    ...{
      retry: false,
    },
  })
}

export default useGetInfosByAddress
