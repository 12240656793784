import classNames from "classnames"
import { t } from "i18next"

function ControlledRowTable<T>({
  headers,
  items,
  totalItems,
  renderTotal,
  renderRow,
  showTotal,
  hasBorders = false,
  isTight = false,
  className,
  headerPosition = "text-left",
}: React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
> & {
  headers: (string | (() => string))[]
  items: T[]
  headerPosition?: "text-left" | "text-right"
  showTotal?: boolean
  hasBorders?: boolean
  isTight?: boolean
  totalItems?: { title: string; id: number }[]
  renderTotal?: (totalItem: string, index: number) => React.ReactNode
  renderRow: (item: T, index: number) => React.ReactNode
  className?: string
}) {
  return (
    <div className="Table__Container">
      <table className={`${className} Table`}>
        <thead className={classNames(isTight ? "Table__Head__Tight" : "")}>
          <tr className={headerPosition}>
            {headers.map((header, index) => (
              <th
                className={classNames(
                  "Table__Head Table__Top_Border",
                  index === 0 ? "w-36" : "w-52",
                  {
                    "border-r border-grey-200":
                      hasBorders && index !== headers.length - 1,
                  },
                )}
                key={`header-${typeof header === "string" ? header : header()}`}
              >
                {typeof header === "string" ? header : header()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={classNames(isTight ? "Table__Body__Tight" : "")}>
          {renderTotal && (
            <tr className="Table__Total">
              {totalItems?.map((totalItem, index) => (
                <td
                  key={`${totalItem.title}-${totalItem.id}`}
                  className="Table__Body_Row"
                >
                  {renderTotal(totalItem.title, index)}
                </td>
              ))}
            </tr>
          )}
          {items.map((item, index) => renderRow(item, index))}
        </tbody>
      </table>
      {showTotal && (
        <p className="p-2 text-sm text-sky-900 flex">
          {`${items.length} ${t("lightTable.resultsFound")}`}
        </p>
      )}
    </div>
  )
}

export default ControlledRowTable
