import { useMutation, useQuery } from "@tanstack/react-query"
import { addBank, getBanks } from "core/api/banks"
import { CustomAxiosError } from "shared/types/axios.type"
import { TBank } from "shared/types/bank.type"

function useGetBanks() {
  return useQuery<TBank[], CustomAxiosError>({
    queryKey: ["banks"],
    queryFn: () => getBanks(),
  })
}

function useAddBank() {
  return useMutation<TBank, CustomAxiosError, { label: string }>({
    mutationFn: (data) => addBank(data),
  })
}

export { useAddBank, useGetBanks }
