import { useQueries } from "@tanstack/react-query"
import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { getLotissementsProductionDates } from "core/spo/api/production"
import { t } from "i18next"
import { Fragment } from "react"
import { nanoid } from "nanoid"
import { HOUSING_ESTATE_TYPE } from "shared/resources/operation.resources"
import { TChildOperation } from "shared/types/operation.type"
import { TechnicalProductionHousingDatesTableRow } from "./TechnicalProductionHousingDatesTableRow"

export interface ITechnicalProductionHousingDatesTableProps {
  operationsChildren: TChildOperation[]
}

export function TechnicalProductionHousingDatesTable(
  props: ITechnicalProductionHousingDatesTableProps,
) {
  const { operationsChildren } = props

  const headers = [
    `${t("start-work")}`,
    `${t("completion-statement")}`,
    `${t("completion-certificate")}`,
    `${t("stop-allowing-works-postpone")}`,
    `${t("definitive-roads")}`,
    `${t("retrocession-city")}`,
  ]

  const operationsDate = useQueries({
    queries: operationsChildren
      ?.filter((operation) => operation.type.id === HOUSING_ESTATE_TYPE.id)
      .map((operation) => {
        return {
          queryKey: ["operationChildren", operation.id],
          queryFn: () =>
            getLotissementsProductionDates(operation.registrationNumber),
        }
      }),
  })

  if (operationsDate[0] && operationsDate[0].isLoading) return <Spinner />

  return (
    <div className="pb-2 overflow-auto">
      {operationsDate[0] &&
      operationsDate[0].data &&
      operationsDate[0].data.length > 0 ? (
        <div className="Light_Table__Wrapper">
          <table className="table-auto Table">
            <thead>
              <tr className="text-left">
                {headers.map((header, index) => (
                  <th
                    className={classNames(
                      "Table__Head Table__Top_Border",
                      index === 0 ? "w-36" : "w-52",
                    )}
                    key={nanoid()}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {operationsDate[0].data.map((operationHousingDate) => (
                <Fragment key={operationHousingDate.registrationId}>
                  <TechnicalProductionHousingDatesTableRow
                    operationHousingDate={operationHousingDate}
                  />
                </Fragment>
              ))}
            </tbody>
          </table>
          <p className="p-2 text-sm text-sky-900 flex" />
        </div>
      ) : (
        <p className="text-sm ml-4 text-primary-dark">
          {t("no-operation-production-date")}
        </p>
      )}
    </div>
  )
}
