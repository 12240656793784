import { useMutation, useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import { TCpr, TCreateCpr, TUpdateCpr } from "shared/types/cpr.type"
import { createCpr, listCprs, updateCpr } from "../api/cprs"

function useCreateCpr() {
  return useMutation<TCpr, CustomAxiosError, TCreateCpr>({
    mutationFn: (cpr) => createCpr(cpr),
  })
}

function useUpdateCpr(id: number) {
  return useMutation<TCpr, CustomAxiosError, TUpdateCpr>({
    mutationFn: (cpr) => updateCpr(id, cpr),
  })
}

function useListCprs(childOperationId: number) {
  return useQuery<TCpr[], CustomAxiosError, TCpr[]>({
    queryKey: ["listCprs", childOperationId],
    queryFn: () => listCprs(childOperationId),
  })
}

export { useCreateCpr, useListCprs, useUpdateCpr }
