export default function hasPermissions(
  requiredPermissions: string[],
  permissions?: string[],
) {
  const findPermissions = requiredPermissions.filter((requiredRole) =>
    permissions?.includes(requiredRole),
  )

  return findPermissions.length === requiredPermissions.length
}
