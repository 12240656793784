import { AuthContext } from "core/auth/AuthProvider"
import { useListSubordinates } from "core/query-hooks/useUsers"
import { useContext } from "react"
import { Navigate } from "react-router-dom"

export default function ManagerGuard(props: { children: React.ReactNode }) {
  const { getUserInfo } = useContext(AuthContext)
  const userInfo = getUserInfo()
  const { data: subordinatesData } = useListSubordinates(userInfo.azureId)
  const { children } = props

  if (
    subordinatesData?.subordinates &&
    subordinatesData.subordinates.length > 0
  )
    return <>{children}</>

  return <Navigate to="/" replace />
}
