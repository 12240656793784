import classNames from "classnames"
import { Button } from "components/Button/Button"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { useUpdateAmendment } from "core/query-hooks/useAmendments"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import * as React from "react"
import { useState } from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { MdArrowForward } from "react-icons/md"
import { toast } from "react-toastify"
import { TAmendment } from "shared/types/amendment.type"
import hasPermissions from "utils/hasPermissions"

registerLocale("fr", fr)

interface IEditAmendmentModalProps {
  amendment: TAmendment
  deleteAmendment: (id: number) => void
  refetch: () => void
}

export default function EditAmendmentModal({
  amendment,
  deleteAmendment,
  refetch,
}: IEditAmendmentModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const [date, setDate] = useState<null | Date | undefined>(
    amendment?.date ? new Date(amendment?.date) : null,
  )
  const [label, setLabel] = useState<string>(amendment.label)
  const updateAmendment = useUpdateAmendment(amendment.id)
  const { data: user } = useGetCurrentUser()
  const isAllowed = hasPermissions(["update:amendment"], user?.rights)

  const onUpdateAmendment = async (
    e: React.FormEvent<HTMLFormElement>,
    updatedLabel: string,
    updatedDate: Date,
  ) => {
    e.preventDefault()
    updateAmendment.mutate(
      { label: updatedLabel, date: updatedDate },
      {
        onSuccess: () => {
          toast.success(`${t("toast-amendment-update-success")}`)
          refetch()
        },
        onError: () => {
          toast.error(`${t("toast-amendment-update-error")}`)
        },
      },
    )
    toggleModal()
  }

  const onDeleteAmendment = (id: number) => {
    deleteAmendment(id)
    toggleModal()
  }

  return (
    <>
      <button
        type="button"
        className={classNames("flex items-center cursor-default", {
          "text-blue-600 cursor-pointer": isAllowed,
        })}
        onClick={isAllowed ? toggleModal : undefined}
      >
        {isAllowed && <MdArrowForward className="mr-2" />}
        {amendment?.label}
      </button>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("edit-amendment")}`}
      >
        <form
          onSubmit={(e) => onUpdateAmendment(e, label, date!)}
          className="Text__Field_Container"
        >
          <div className="Text__Field_Container mb-6">
            <label htmlFor="label">
              {`${t("label")} *`}
              <input
                id="label"
                className="Text__Field"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                type="text"
                required
                placeholder={`${t("label")}`}
              />
            </label>
            <div className="flex flex-col mt-2">
              <label htmlFor="date">{`${t("date")}`}</label>
              <CustomDatePicker
                id="date"
                locale={fr}
                className="Text__Field"
                placeholderText={`${t("date")}`}
                selected={date}
                onChange={(changedDate) => setDate(changedDate)}
              />
            </div>
          </div>
          <div className="Modal__Footer flex justify-between">
            <div>
              <Button
                type="button"
                size="medium"
                mode="danger"
                isLoading={false}
                onClick={() => onDeleteAmendment(amendment?.id!)}
              >
                {`${t("delete")}`}
              </Button>
            </div>
            <div>
              <Button
                type="button"
                size="medium"
                mode="secondary"
                isLoading={false}
                onClick={() => toggleModal()}
              >
                {`${t("cancel")}`}
              </Button>
              <Button
                type="submit"
                marginLeft={24}
                size="medium"
                mode="primary"
              >
                {`${t("confirm")}`}
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}
