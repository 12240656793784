import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import ButtonIcon from "components/Button/ButtonIcon"
import DateFormat from "components/Date/DateFormat"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import {
  useUpdateOperation,
  useUpdateOperationStep,
} from "core/query-hooks/useOperations"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { FormEvent } from "react"
import { registerLocale } from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import { MdNotInterested } from "react-icons/md"
import { toast } from "react-toastify"
import { GROUP } from "shared/resources/groups.resources"
import {
  STATUS_ABANDONMENT,
  STATUS_ABANDONMENT_ON_GOING,
} from "shared/resources/operation.resources"
import { TOperation } from "shared/types/operation.type"
import * as yup from "yup"

export interface IAbandonOperation {
  operation: TOperation
}

export default function AbandonOperation(props: IAbandonOperation) {
  registerLocale("fr", fr)

  const { operation } = props
  const operationId = operation.operationBase.id
  const queryClient = useQueryClient()
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const mutateLandPurchaseDate = useUpdateOperation(operationId)
  const mutateStepOperation = useUpdateOperationStep(operationId)
  const validationSchema = yup.object().shape(
    {
      isProgramAbandonedDateDefinitive: yup.boolean(),
      isJuristAbandonedDateDefinitive: yup.boolean(),
      programAbandonedDate: yup
        .date()
        .when("isProgramAbandonedDateDefinitive", {
          is: (isProgramAbandonedDateDefinitive: boolean) =>
            isProgramAbandonedDateDefinitive,
          then: () => yup.date().required(() => `${t("required-date")}`),
          otherwise: () => yup.date().nullable().default(null),
        }),
      juristAbandonedDate: yup.date().when("isJuristAbandonedDateDefinitive", {
        is: (isJuristAbandonedDateDefinitive: boolean) =>
          isJuristAbandonedDateDefinitive,
        then: () => yup.date().required(() => `${t("required-date")}`),
        otherwise: () => yup.date().nullable().default(null),
      }),
    },
    [
      ["isProgramAbandonedDateDefinitive", "programAbandonedDate"],
      ["isJuristAbandonedDateDefinitive", "juristAbandonedDate"],
    ],
  )

  type FormValues = {
    isProgramAbandonedDateDefinitive: boolean
    programAbandonedDate: Date | null
    isJuristAbandonedDateDefinitive: boolean
    juristAbandonedDate: Date | null
  }

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema as yup.ObjectSchema<FormValues>),
    defaultValues: {
      isProgramAbandonedDateDefinitive:
        operation.operationData.isProgramAbandonedDateDefinitive,
      programAbandonedDate:
        operation.operationData.programAbandonedDate ?? null,
      isJuristAbandonedDateDefinitive:
        operation.operationData.isJuristAbandonedDateDefinitive,
      juristAbandonedDate: operation.operationData.juristAbandonedDate ?? null,
    },
  })

  const submitForm = async (data: FormValues) => {
    mutateLandPurchaseDate.mutate(
      {
        isProgramAbandonedDateDefinitive: data.isProgramAbandonedDateDefinitive,
        programAbandonedDate: data.programAbandonedDate,
        isJuristAbandonedDateDefinitive: data.isJuristAbandonedDateDefinitive,
        juristAbandonedDate: data.juristAbandonedDate,
      },
      {
        onSuccess(ope) {
          if (
            ope.operationData.isProgramAbandonedDateDefinitive ||
            ope.operationData.isJuristAbandonedDateDefinitive
          ) {
            mutateStepOperation.mutate(
              {
                stepId: ope.operationData.isJuristAbandonedDateDefinitive
                  ? STATUS_ABANDONMENT
                  : STATUS_ABANDONMENT_ON_GOING,
              },
              {
                onSuccess() {
                  toast.success(t("operation-updated"))
                  queryClient.invalidateQueries({
                    queryKey: ["operation", +operationId!],
                  })
                  queryClient.invalidateQueries({
                    queryKey: ["getOperationStepHistory", +operationId!],
                  })
                  toggleModal()
                },
                onError() {
                  toast.error(t("abandonment-update-error"))
                },
              },
            )
          } else {
            queryClient.invalidateQueries({
              queryKey: ["operation", +operationId!],
            })
            toast.success(t("operation-updated"))
            toggleModal()
          }
        },
        onError() {
          toast.error(t("abandonment-update-error"))
        },
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(submitForm)()
  }

  return (
    <>
      <GroupsGuard
        requiredGroups={[
          GROUP.PROGRAMME_ASSISTANT,
          GROUP.PROGRAMME_CHARGE,
          GROUP.PROGRAMME_RESPONSABLE,
          GROUP.PROGRAMME_DIRECTEUR,
          GROUP.JURIDIQUE_JURISTE,
          GROUP.JURIDIQUE_ASSISTANT,
          GROUP.CONTROLE_DE_GESTION,
          GROUP.ADMIN,
        ]}
      >
        <ButtonIcon className="ml-2" type="button" onClick={toggleModal}>
          <MdNotInterested
            size={22}
            cursor="pointer"
            className="text-cyan-800"
          />
        </ButtonIcon>
      </GroupsGuard>
      {isModalShowed && (
        <Modal
          isShowing={isModalShowed}
          closeModal={toggleModal}
          title={t("abandonment")}
        >
          <form className="Text__Field_Container" onSubmit={onSubmit}>
            <div className="gap-4 mb-4">
              <div className="mt-2 mb-2">
                <h2 className="font-semibold mb-2">{t("program-service")}</h2>
                <GroupsGuard
                  requiredGroups={[
                    GROUP.PROGRAMME_ASSISTANT,
                    GROUP.PROGRAMME_CHARGE,
                    GROUP.PROGRAMME_RESPONSABLE,
                    GROUP.PROGRAMME_DIRECTEUR,
                    GROUP.CONTROLE_DE_GESTION,
                  ]}
                >
                  {operation?.operationBase.step.id === STATUS_ABANDONMENT &&
                  operation.operationData.programAbandonedDate ? (
                    <div className="mt-2 mb-2">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2">
                        <div className="col-span-4">
                          <DateFormat
                            date={
                              new Date(
                                operation.operationData.programAbandonedDate,
                              )
                            }
                            isDefinitive={
                              operation?.operationData
                                .isProgramAbandonedDateDefinitive
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2">
                      <div className="col-span-4">
                        <label
                          htmlFor="program-abandoned-date"
                          className="mr-2"
                        >
                          {t("abandon-date")}
                        </label>

                        <Controller
                          name="programAbandonedDate"
                          control={control}
                          render={({ field }) => (
                            <CustomDatePicker
                              id="programAbandonedDate"
                              locale={fr}
                              className="Text__Field"
                              disabled={
                                operation?.operationData
                                  .isJuristAbandonedDateDefinitive === true
                              }
                              selected={
                                field.value ??
                                operation?.operationData.programAbandonedDate
                              }
                              placeholderText={`${t("select-date")}`}
                              onChange={(date) => {
                                field.onChange(date)
                              }}
                            />
                          )}
                        />
                        {errors?.programAbandonedDate && (
                          <p className="text-red-600 text-sm">
                            {errors.programAbandonedDate.message}
                          </p>
                        )}
                      </div>
                      <div className="col-span-2">
                        <div className="flex items-center justify-between sm:mt-2">
                          <label htmlFor="isProgramAbandonedDateDefinitive">
                            {`${t("operation-abandonment")}`}
                            <div className="flex items-center justify-start sm:mt-2">
                              <Controller
                                name="isProgramAbandonedDateDefinitive"
                                control={control}
                                render={({ field }) => (
                                  <ToggleSwitch
                                    id="isProgramAbandonedDateDefinitive"
                                    disabled={
                                      operation?.operationData
                                        .isJuristAbandonedDateDefinitive ===
                                      true
                                    }
                                    checked={field.value}
                                    {...register(
                                      "isProgramAbandonedDateDefinitive",
                                    )}
                                    onChange={(toggle) => {
                                      field.onChange(toggle)
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </GroupsGuard>

                <GroupsGuard
                  requiredGroups={[
                    GROUP.JURIDIQUE_JURISTE,
                    GROUP.JURIDIQUE_ASSISTANT,
                  ]}
                  excludeAdmin
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2">
                    <div className="col-span-6">
                      {operation?.operationData.programAbandonedDate ? (
                        <DateFormat
                          date={
                            new Date(
                              operation?.operationData.programAbandonedDate,
                            )
                          }
                          isDefinitive={
                            operation?.operationData
                              .isProgramAbandonedDateDefinitive
                          }
                        />
                      ) : (
                        <p className="Modal__Description opacity-75">
                          {t("program-abandon-date-is-not-set")}
                        </p>
                      )}
                    </div>
                  </div>
                </GroupsGuard>
              </div>

              {(operation?.operationBase.step.id ===
                STATUS_ABANDONMENT_ON_GOING ||
                (operation?.operationBase.step.id !== STATUS_ABANDONMENT &&
                  operation?.operationData
                    .isJuristAbandonedDateDefinitive)) && (
                <div className="mt-2 mb-2">
                  <h2 className="font-semibold mb-2">{t("legal-service")}</h2>
                  <GroupsGuard
                    requiredGroups={[
                      GROUP.JURIDIQUE_JURISTE,
                      GROUP.JURIDIQUE_ASSISTANT,
                      GROUP.ADMIN,
                    ]}
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2">
                      <div className="col-span-4">
                        <label htmlFor="jurist-abandoned-date" className="mr-2">
                          {t("abandon-date")}
                        </label>

                        <Controller
                          name="juristAbandonedDate"
                          control={control}
                          render={({ field }) => (
                            <CustomDatePicker
                              id="juristAbandonedDate"
                              locale={fr}
                              className="Text__Field"
                              selected={
                                field.value ??
                                operation?.operationData.juristAbandonedDate
                              }
                              placeholderText={`${t("select-date")}`}
                              disabled={
                                operation?.operationData
                                  .isProgramAbandonedDateDefinitive === false ||
                                (operation?.operationData
                                  .isJuristAbandonedDateDefinitive === true &&
                                  operation?.operationData
                                    .juristAbandonedDate !== null)
                              }
                              onChange={(date) => {
                                field.onChange(date)
                              }}
                            />
                          )}
                        />
                        {errors?.juristAbandonedDate && (
                          <p className="text-red-600 text-sm">
                            {errors.juristAbandonedDate.message}
                          </p>
                        )}
                      </div>
                      <div className="col-span-2">
                        <label htmlFor="isJuristAbandonedDateDefinitive">
                          {`${t("abandonment-validation")}`}
                          <div className="flex items-center justify-start sm:mt-2">
                            <Controller
                              name="isJuristAbandonedDateDefinitive"
                              control={control}
                              render={({ field }) => (
                                <ToggleSwitch
                                  id="isJuristAbandonedDateDefinitive"
                                  disabled={
                                    operation?.operationData
                                      .isProgramAbandonedDateDefinitive ===
                                      false ||
                                    (operation?.operationData
                                      .isJuristAbandonedDateDefinitive ===
                                      true &&
                                      operation?.operationData
                                        .juristAbandonedDate !== null)
                                  }
                                  checked={field.value}
                                  {...register(
                                    "isJuristAbandonedDateDefinitive",
                                  )}
                                  onChange={(toggle) => {
                                    field.onChange(toggle)
                                  }}
                                />
                              )}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </GroupsGuard>
                  <GroupsGuard
                    requiredGroups={[
                      GROUP.PROGRAMME_ASSISTANT,
                      GROUP.PROGRAMME_CHARGE,
                      GROUP.PROGRAMME_RESPONSABLE,
                      GROUP.PROGRAMME_DIRECTEUR,
                    ]}
                    excludeAdmin
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2">
                      <div className="col-span-6">
                        {operation?.operationData.juristAbandonedDate ? (
                          <DateFormat
                            date={
                              new Date(
                                operation?.operationData.juristAbandonedDate,
                              )
                            }
                            isDefinitive={
                              operation?.operationData
                                .isJuristAbandonedDateDefinitive
                            }
                          />
                        ) : (
                          <p className="Modal__Description opacity-75">
                            {t("legal-abandon-date-is-not-set")}
                          </p>
                        )}
                      </div>
                    </div>
                  </GroupsGuard>
                </div>
              )}
              {operation?.operationBase.step.id === STATUS_ABANDONMENT &&
                operation?.operationData.isJuristAbandonedDateDefinitive &&
                operation?.operationData.juristAbandonedDate && (
                  <div className="mt-2 mb-2">
                    <h2 className="font-semibold mb-2">{t("legal-service")}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2">
                      <div className="col-span-4">
                        <DateFormat
                          date={
                            new Date(
                              operation.operationData.juristAbandonedDate,
                            )
                          }
                          isDefinitive={
                            operation?.operationData
                              .isJuristAbandonedDateDefinitive
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

              <div className="flex items-center justify-between mt-6">
                <Button
                  onClick={() => toggleModal()}
                  size="small"
                  mode="secondary"
                >
                  {`${t("cancel")}`}
                </Button>

                {operation?.operationData.isJuristAbandonedDateDefinitive ===
                  true &&
                operation?.operationData.juristAbandonedDate !== null ? null : (
                  <Button
                    isLoading={false}
                    type="submit"
                    size="small"
                    mode="primary"
                  >
                    {`${t("update")}`}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </Modal>
      )}
    </>
  )
}
