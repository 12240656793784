import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import { FormTextField } from "components/Form/FormTextField"
import { Header } from "components/Header/Header"
import { IUpdatePlotForm } from "features/plots/PlotUpdate"
import { t } from "i18next"
import { Dispatch, FormEvent, ReactElement, SetStateAction } from "react"
import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form"
import { useParams } from "react-router-dom"
import { TCurrency } from "shared/types/global.type"
import { TPlot } from "shared/types/plot.type"

type IFormUpdateProps = {
  children?: ReactElement[] | ReactElement
  onceSubmited: (data: IUpdatePlotForm) => void
  register: UseFormRegister<IUpdatePlotForm>
  handleSubmit: UseFormHandleSubmit<IUpdatePlotForm>
  errors: FieldErrors<TPlot>
  setPriceValue: Dispatch<
    SetStateAction<{
      value?: number
      unit: TCurrency
    }>
  >
  priceValue: {
    value?: number
    unit: TCurrency
  }
}

export default function FormUpdate({
  children,
  register,
  handleSubmit,
  errors,
  onceSubmited,
  setPriceValue,
  priceValue,
}: IFormUpdateProps) {
  const { plotId } = useParams()

  if (!plotId) {
    return <p>Missing Id</p>
  }

  const submitForm = (data: IUpdatePlotForm) => onceSubmited(data)

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <form onSubmit={onSubmit}>
      <Box>
        <div className="Padding__Bottom_32 pt-2">
          <Header size="h2">{`${t("plots.form.update.title")}`}</Header>
        </div>
        <div className="Flex Items__Center Gap__8">
          <div
            style={{
              flex: 1,
            }}
          >
            <FormTextField
              id="prefix"
              className="Text__Field_Container"
              errors={errors}
              name="prefix"
              register={register}
              label={`${t("plots.form.prefixLabel")} *`}
              type="text"
              placeholder={`${t("plots.form.prefixPlaceholder")}`}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <FormTextField
              id="section"
              errors={errors}
              name="section"
              className="Text__Field_Container"
              register={register}
              label={`${t("plots.form.sectionLabel")} *`}
              type="text"
              placeholder={`${t("plots.form.sectionPlaceholder")}`}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <FormTextField
              id="number"
              errors={errors}
              name="number"
              className="Text__Field_Container"
              register={register}
              label={`${t("plots.form.numberLabel")} *`}
              type="text"
              placeholder={`${t("plots.form.numberPlaceholder")}`}
            />
          </div>
        </div>

        <FormTextField
          id="surface"
          errors={errors}
          name="area.val"
          register={register}
          className="Text__Field_Container"
          label={`${t("plots.form.surfaceLabel")} *`}
          type="number"
          placeholder=""
        />

        <FormTextField
          id="price"
          errors={errors}
          className="Text__Field_Container"
          name="price.val"
          register={register}
          setPriceValue={setPriceValue}
          priceValue={priceValue}
          label={`${t("plots.form.priceLabel")}`}
          type="currency"
          placeholder=""
        />

        <div className="Flex Items__Start Gap__8">
          <div
            style={{
              flex: 1,
            }}
          >
            <FormTextField
              id="owner"
              errors={errors}
              name="owner"
              className="Text__Field_Container"
              register={register}
              label={`${t("plots.form.ownerLabel")}`}
              type="text"
              placeholder=""
            />
          </div>
        </div>
      </Box>

      <div className="flex items-center justify-end mt-4">
        {children}
        <div className="ml-4">
          <Button type="submit" size="medium" mode="primary" isLoading={false}>
            {`${t("update")}`}
          </Button>
        </div>
      </div>
    </form>
  )
}
