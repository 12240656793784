import useListDocumentTypesByViewTag from "core/ged/query-hooks/useDocumentTypes"
import { ChangeEvent, useEffect, useState } from "react"
import { TDocumentType } from "shared/types/ged.type"

export type TChapterOption = {
  value: string
  label: string
}

export type TSubChapterOption = TChapterOption & {
  chapter: string
}

export type TDocumentTypeOption = TSubChapterOption & {
  subChapter: string
}

function useAddDocument(view: number) {
  const { data: documentTypes } = useListDocumentTypesByViewTag(view)
  const [chapterOptions, setChapterOptions] = useState<TChapterOption[]>([])
  const [subChapterOptions, setSubChapterOptions] = useState<
    TSubChapterOption[]
  >([])
  const [documentTypeOptions, setDocumentTypeOptions] = useState<
    TDocumentTypeOption[]
  >([])
  const [selectedChapter, setSelectedChapter] = useState<string | undefined>(
    documentTypes?.data[0]?.chapter,
  )
  const [selectedSubChapter, setSelectedSubChapter] = useState<
    string | undefined
  >(documentTypes?.data[0]?.subChapter)
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    string | undefined
  >(documentTypes?.data[0]?.label)

  useEffect(() => {
    setSelectedChapter(
      documentTypes?.data?.length ? documentTypes?.data[0].chapter : undefined,
    )

    setSelectedSubChapter(
      documentTypes?.data.length
        ? documentTypes?.data[0].subChapter
        : undefined,
    )

    setSelectedDocumentType(
      documentTypes?.data.length ? documentTypes?.data[0].label : undefined,
    )
  }, [setSelectedChapter, setSelectedSubChapter, documentTypes])

  useEffect(() => {
    documentTypes?.data?.map((documentType: TDocumentType) => {
      setChapterOptions((prevOptions: TChapterOption[]) => {
        if (
          prevOptions.filter(
            (option: TChapterOption) => option.value === documentType.chapter,
          ).length === 0
        ) {
          return [
            ...prevOptions,
            {
              value: documentType.chapter,
              label: documentType.chapter,
            },
          ]
        }
        return prevOptions
      })
      setSubChapterOptions((prevOptions: TSubChapterOption[]) => {
        if (
          prevOptions.filter(
            (option: TSubChapterOption) =>
              option.value === documentType.subChapter,
          ).length === 0
        ) {
          return [
            ...prevOptions,
            {
              chapter: documentType.chapter,
              value: documentType.subChapter,
              label: documentType.subChapter,
            },
          ]
        }
        return prevOptions
      })
      setDocumentTypeOptions((prevOptions: TDocumentTypeOption[]) => [
        ...prevOptions,
        {
          chapter: documentType.chapter,
          subChapter: documentType.subChapter,
          value: documentType.label,
          label: documentType.label,
        },
      ])
      return documentType
    })
  }, [
    documentTypes,
    setDocumentTypeOptions,
    setSubChapterOptions,
    setChapterOptions,
  ])

  const onChangeChapter = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedChapter(e.target.value)
    const selectedChapterDocType = documentTypes?.data.find(
      (docType) => docType.chapter === e.target.value,
    )
    setSelectedSubChapter(
      selectedChapterDocType ? selectedChapterDocType.subChapter : undefined,
    )
    setSelectedDocumentType(
      selectedChapterDocType ? selectedChapterDocType.label : undefined,
    )
  }

  const onChangeSubChapter = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubChapter(e.target.value)
    subChapterOptions?.map((subchapter: TSubChapterOption) => {
      if (e.target.value === subchapter.value) {
        setSelectedChapter(subchapter.chapter)
      }
      return subchapter
    })
    const selectedSubChapterDocType = documentTypes?.data.find(
      (docType) => docType.subChapter === e.target.value,
    )
    setSelectedDocumentType(
      selectedSubChapterDocType ? selectedSubChapterDocType.label : undefined,
    )
  }

  const onChangeDocumentType = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDocumentType(e.target.value)
    documentTypes?.data.map((documentType: TDocumentType) => {
      if (e.target.value === documentType.label) {
        setSelectedChapter(documentType.chapter)
        setSelectedSubChapter(documentType.subChapter)
      }
      return documentType
    })
  }

  useEffect(() => {
    setSubChapterOptions([])
    setDocumentTypeOptions([])
    documentTypes?.data.map((documentType: TDocumentType) => {
      if (selectedChapter === documentType.chapter) {
        setSubChapterOptions((prevOptions: TSubChapterOption[]) => {
          if (
            prevOptions.filter(
              (option: TSubChapterOption) =>
                option.value === documentType.subChapter,
            ).length === 0
          ) {
            return [
              ...prevOptions,
              {
                chapter: documentType.chapter,
                value: documentType.subChapter,
                label: documentType.subChapter,
              },
            ]
          }
          return prevOptions
        })
        setDocumentTypeOptions((prevOptions: TDocumentTypeOption[]) => [
          ...prevOptions,
          {
            chapter: documentType.chapter,
            subChapter: documentType.subChapter,
            value: documentType.label,
            label: documentType.label,
          },
        ])
      }
      return documentType
    })
  }, [
    selectedChapter,
    documentTypes,
    setDocumentTypeOptions,
    setSubChapterOptions,
  ])

  useEffect(() => {
    setDocumentTypeOptions([])
    documentTypes?.data.map((documentType: TDocumentType) => {
      if (selectedSubChapter === documentType.subChapter) {
        setDocumentTypeOptions((prevOptions: TDocumentTypeOption[]) => [
          ...prevOptions,
          {
            chapter: documentType.chapter,
            subChapter: documentType.subChapter,
            value: documentType.label,
            label: documentType.label,
          },
        ])
      }
      return documentType
    })
  }, [selectedSubChapter, documentTypes, setDocumentTypeOptions])
  return {
    selectedChapter,
    selectedSubChapter,
    chapterOptions,
    subChapterOptions,
    documentTypeOptions,
    selectedDocumentType,
    onChangeChapter,
    onChangeSubChapter,
    onChangeDocumentType,
  }
}

export default useAddDocument
