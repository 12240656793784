import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import ButtonIcon from "components/Button/ButtonIcon"
import useModal from "components/Modal/useModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useListCopromoterAssociates,
  useUpdateCopromoter,
} from "core/query-hooks/useCopromoters"
import i18next, { t } from "i18next"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { LuUndo2 } from "react-icons/lu"
import { MdAdd, MdEdit } from "react-icons/md"
import { toast } from "react-toastify"
import { TCopromoter, TMutateCopromoter } from "shared/types/copromoter.type"
import * as yup from "yup"
import { AddCopromoterAssociateModal } from "./AddCopromoterAssociateModal"

export interface IEditCopromoterProps {
  copromoter: TCopromoter
}

type FormValues = {
  operationId: number
  copromoterAssociateId: number
  contactName?: string | null
  contactPhone?: string | null
  contactEmail?: string | null
}

export function EditCopromoter(props: IEditCopromoterProps) {
  const { copromoter } = props
  const queryClient = useQueryClient()
  const updateCopromoter = useUpdateCopromoter(copromoter.id)
  const { data: copromoterAssociates } = useListCopromoterAssociates()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [copromoterAssociate, setCopromoterAssociate] = useState<number>(
    copromoter.copromoterAssociate.id ?? -1,
  )
  const {
    isShowing: isAddCopromoterAssociateModalShowed,
    toggle: toggleCopromoterAssociateModal,
  } = useModal()

  const validationSchema = yup.object().shape({
    operationId: yup.number().required(),
    copromoterAssociateId: yup.number().required().positive(),
    contactName: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    contactPhone: yup
      .string()
      .nullable()
      .matches(/^0[1-9]\d{8}$/, () => i18next.t("invalid-phone-format"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
    contactEmail: yup
      .string()
      .nullable()
      .email(() => i18next.t("invalid-email-format"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
  })

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      copromoterAssociateId: copromoter.copromoterAssociate.id,
      operationId: copromoter.operationId,
      contactName: copromoter.contactName,
      contactPhone: copromoter.contactPhone,
      contactEmail: copromoter.contactEmail,
    },
  })

  const submitForm = (data: TMutateCopromoter) => {
    updateCopromoter.mutate(data, {
      onSuccess() {
        toast.success(`${t("copromoter-updated-success")}`)
        queryClient.invalidateQueries({
          queryKey: ["listCopromotersByOperation", copromoter.operationId],
        })
        reset()
        setIsEdit(false)
      },
      onError: (err) => {
        toast.error(
          `${t("copromoter-updated-error")} : ${err.response?.data.message}`,
        )
      },
    })
  }

  useEffect(() => {
    reset({
      contactName: copromoter.contactName,
      contactPhone: copromoter.contactPhone,
      contactEmail: copromoter.contactEmail,
      operationId: copromoter.operationId,
      copromoterAssociateId: copromoter.copromoterAssociate.id,
    })
  }, [copromoter])

  useEffect(() => {
    setValue("copromoterAssociateId", copromoterAssociate)
  }, [copromoterAssociate, setValue])

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  function cancelEdit() {
    reset({
      contactName: copromoter.contactName,
      contactPhone: copromoter.contactPhone,
      contactEmail: copromoter.contactEmail,
      operationId: copromoter.operationId,
      copromoterAssociateId: copromoter.copromoterAssociate.id,
    })
    setIsEdit(false)
    setCopromoterAssociate(copromoter.copromoterAssociate.id)
  }

  if (isEdit) {
    return (
      <form onSubmit={onSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mt-3 mb-4">
          <div className="Text__Field_Container lg:col-span-2">
            <label htmlFor="select-copromoter-associate">
              {`${t("associate-name")}`} *
            </label>
            <div className="flex items-center justify-between">
              <select
                className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                value={copromoterAssociate}
                onChange={(e) => setCopromoterAssociate(Number(e.target.value))}
                name="select-copromoter-associate"
              >
                <option
                  key="no-option-copromoter-associate"
                  value={-1}
                  disabled
                >
                  {t("select-copromoter-associate")}
                </option>
                {copromoterAssociates?.map((ca) => (
                  <option key={`${ca.id}-${ca.label}`} value={ca.id}>
                    {ca.label}
                  </option>
                ))}
              </select>
              <PermissionsGuard requiredRoles={["create:copromoter-associate"]}>
                <ButtonIcon
                  className="ml-2 -mt-1"
                  type="button"
                  onClick={toggleCopromoterAssociateModal}
                >
                  <MdAdd size={22} cursor="pointer" className="text-cyan-800" />
                </ButtonIcon>
                {isAddCopromoterAssociateModalShowed && (
                  <AddCopromoterAssociateModal
                    isAddCopromoterAssociateModalShowed={
                      isAddCopromoterAssociateModalShowed
                    }
                    toggleCopromoterAssociateModal={
                      toggleCopromoterAssociateModal
                    }
                    setCopromoterAssociate={setCopromoterAssociate}
                  />
                )}
              </PermissionsGuard>
            </div>
          </div>

          <div>
            <div className="Text__Field_Container">
              <label htmlFor="contact-name">
                {`${t("contact")}`}
                <input
                  id="contact-name"
                  className="Text__Field"
                  {...register("contactName")}
                />
              </label>
            </div>
            {errors.contactName && (
              <span className="text-red-500 text-xs">
                {errors.contactName.message}
              </span>
            )}
          </div>

          <div>
            <div className="Text__Field_Container">
              <label htmlFor="contact-phone">
                {`${t("phone")}`}
                <input
                  id="contact-phone"
                  className="Text__Field"
                  {...register("contactPhone")}
                />
              </label>
            </div>
            {errors.contactPhone && (
              <span className="text-red-500 text-xs">
                {errors.contactPhone.message}
              </span>
            )}
          </div>

          <div>
            <div className="Text__Field_Container">
              <label htmlFor="contact-mail">
                {`${t("email")}`}
                <input
                  id="contact-mail"
                  className="Text__Field"
                  {...register("contactEmail")}
                />
              </label>
            </div>
            {errors.contactEmail && (
              <span className="text-red-500 text-xs">
                {errors.contactEmail.message}
              </span>
            )}
          </div>

          <div className="flex items-center pt-4">
            <ButtonIcon
              className="mr-2"
              type="button"
              onClick={() => cancelEdit()}
            >
              <LuUndo2 size={22} cursor="pointer" className="text-cyan-800" />
            </ButtonIcon>
            <Button type="submit" size="small" mode="primary">
              {t("edit")}
            </Button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mt-3 mb-4 text-primary-dark">
      <div className="lg:col-span-2 flex flex-col">
        <span className="label">{`${t("associate-name")}`}</span>
        <p>{copromoter.copromoterAssociate.label}</p>
      </div>
      <div className="flex flex-col">
        <span className="label">{`${t("contact")}`}</span>
        <p>{copromoter.contactName ?? "-"}</p>
      </div>
      <div className="flex flex-col">
        <span className="label">{`${t("phone")}`}</span>
        <p>{copromoter.contactPhone ?? "-"}</p>
      </div>
      <div className="flex flex-col">
        <span className="label">{`${t("email")}`}</span>
        <p>{copromoter.contactEmail ?? "-"}</p>
      </div>
      <div>
        <PermissionsGuard requiredRoles={["update:copromoter"]}>
          <ButtonIcon
            className="ml-2 mt-2"
            type="button"
            onClick={() => setIsEdit(true)}
          >
            <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
          </ButtonIcon>
        </PermissionsGuard>
      </div>
    </div>
  )
}
