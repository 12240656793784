import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from "react"
import { TInfography } from "shared/types/infography.type"

interface ICostOfWorkProps {
  children: React.ReactNode
}

export type TStudyRequestCreateForm = TInfography

const defaultActiveStep = 1

const studyRequest: TStudyRequestCreateForm = {
  feasibilityId: undefined,
  desiredGroundPlaneNumber: undefined,
  desiredModelNumber: undefined,
  desiredPerspectiveNumber: undefined,
  desiredAerialViewNumber: undefined,
  desiredOtherNumber: undefined,
  developersComment: undefined,
}

const InfographyContext = createContext<{
  getStudyRequest: () => TStudyRequestCreateForm
  setActiveStep: Dispatch<SetStateAction<number>>
  setCurrentId: Dispatch<SetStateAction<number | undefined>>
  currentId: number | undefined
  activeStep: number
  studyRequestInfo: TStudyRequestCreateForm
  setStudyRequestInfo: Dispatch<SetStateAction<TStudyRequestCreateForm>>
}>({
  getStudyRequest: () => studyRequest,
  setActiveStep: () => {},
  setCurrentId: () => {},
  currentId: undefined,
  activeStep: defaultActiveStep,
  studyRequestInfo: studyRequest,
  setStudyRequestInfo: () => {},
})

function InfographyProvider({ children }: ICostOfWorkProps) {
  const [studyRequestInfo, setStudyRequestInfo] = useState(studyRequest)
  const [currentId, setCurrentId] = useState<number>()
  const [activeStep, setActiveStep] = useState(defaultActiveStep)

  const getStudyRequest = () => {
    return studyRequestInfo
  }

  const contextValue = useMemo(
    () => ({
      getStudyRequest,
      setStudyRequestInfo,
      studyRequestInfo,
      setActiveStep,
      activeStep,
      setCurrentId,
      currentId,
    }),
    [
      getStudyRequest,
      setStudyRequestInfo,
      studyRequestInfo,
      setActiveStep,
      activeStep,
      setCurrentId,
      currentId,
    ],
  )

  return (
    <InfographyContext.Provider value={contextValue}>
      {children}
    </InfographyContext.Provider>
  )
}

export { InfographyContext, InfographyProvider }
