import classnames from "classnames"
import { Button } from "components/Button/Button"
import { PropsWithChildren } from "react"

export interface RewindButtonProps extends PropsWithChildren {
  size: "small" | "medium" | "large"
  mode: "primary" | "secondary" | "outline" | "danger"
  onClick?: () => void
  classNames?: string
}

const DEFAULT_SIZE = "medium"
const DEFAULT_MODE = "primary"

export default function RewindButton({
  size = DEFAULT_SIZE,
  mode = DEFAULT_MODE,
  classNames,
  onClick,
  children,
}: RewindButtonProps) {
  return (
    <Button
      onClick={onClick}
      mode={mode}
      size={size}
      classNames={classnames(
        "rounded-full items-center justify-center",
        mode,
        size,
        classNames,
      )}
    >
      {children}
    </Button>
  )
}
