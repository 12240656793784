import Box from "components/Box/Box"
import { t } from "i18next"
import { Link } from "react-router-dom"

export default function NoMatch() {
  return (
    <div className="md:px-96 md:py-12">
      <Box classNames="text-center">
        <p className="text-sm Color__Primary">
          {`${t("notFoundPageMessage")}`}
        </p>
        <Link
          className="text-sm transition-colors ease-in-out delay-50 text-blue-600 hover:text-blue-700"
          to="/"
        >
          {`${t("back-to-home")}`}
        </Link>
      </Box>
    </div>
  )
}
