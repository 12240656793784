import classNames from "classnames"
import { Button } from "components/Button/Button"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import QueryBoundaries from "core/QueryBoundaries"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useUpdateSuspensiveCondition } from "core/query-hooks/useSuspensiveConditions"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import * as React from "react"
import { useState } from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { MdArrowForward, MdCheckCircle } from "react-icons/md"
import { toast } from "react-toastify"
import { GROUP } from "shared/resources/groups.resources"
import { TSuspensiveCondition } from "shared/types/suspensiveCondition.type"
import hasPermissions from "utils/hasPermissions"

registerLocale("fr", fr)

interface IEditSuspensiveConditionModalProps {
  suspensiveCondition: TSuspensiveCondition
  deleteSuspensiveCondition: (id: number) => void
  refetch: () => void
}

export default function EditSuspensiveConditionModal({
  suspensiveCondition,
  deleteSuspensiveCondition,
  refetch,
}: IEditSuspensiveConditionModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const [date, setDate] = useState<null | Date | undefined>(
    suspensiveCondition?.date ? new Date(suspensiveCondition?.date) : null,
  )
  const [label, setLabel] = useState<string | null>(suspensiveCondition?.label!)
  const [isDefinitive, setIsDefinitive] = useState<boolean | undefined>(
    suspensiveCondition?.isDefinitive,
  )
  const [isDefault] = useState<boolean | undefined>(
    suspensiveCondition?.isDefault,
  )

  const { data: user } = useGetCurrentUser()
  const isAllowed = hasPermissions(
    ["update:suspensive-condition"],
    user?.rights,
  )

  const canEditDefault =
    user?.groups.includes(GROUP.JURIDIQUE_ASSISTANT) ||
    user?.groups.includes(GROUP.JURIDIQUE_JURISTE)

  const updateSuspensiveCondition = useUpdateSuspensiveCondition(
    suspensiveCondition.id,
  )

  const handleToggleIsDefinitive = () => {
    setIsDefinitive(!isDefinitive)
  }

  const onUpdateSuspensiveCondition = async (
    e: React.FormEvent<HTMLFormElement>,
    updatedLabel: string,
    updatedDate: Date,
    updatedIsDefinitive: boolean,
    updatedIsDefault: boolean,
  ) => {
    e.preventDefault()
    await updateSuspensiveCondition.mutateAsync(
      {
        date: updatedDate,
        isDefinitive: updatedIsDefinitive,
        ...((!updatedIsDefault || canEditDefault) && { label: updatedLabel }),
      },
      {
        onSuccess() {
          toast.success(`${t("toast-suspensive-condition-update-success")}`)
          refetch()
        },
        onError() {
          toast.error(`${t("toast-suspensive-condition-update-error")}`)
        },
      },
    )
    toggleModal()
  }

  const onDeleteSuspensiveCondition = (id: number) => {
    deleteSuspensiveCondition(id)
    toggleModal()
  }

  return (
    <>
      <button
        type="button"
        className={classNames("flex items-center cursor-default", {
          "cursor-pointer": isAllowed,
        })}
        onClick={isAllowed ? toggleModal : undefined}
      >
        <div className="w-8">
          {suspensiveCondition?.isDefinitive ? (
            <MdCheckCircle color="#0CB28B" className="mr-2" />
          ) : (
            <MdArrowForward className="mr-2" />
          )}
        </div>
        <p className="text-justify">{suspensiveCondition?.label}</p>
      </button>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("edit-suspensive-condition")}`}
      >
        <form
          onSubmit={(e) =>
            onUpdateSuspensiveCondition(
              e,
              label!,
              date!,
              isDefinitive!,
              isDefault!,
            )
          }
          className="Text__Field_Container"
        >
          <div className="Text__Field_Container mb-6">
            <label htmlFor="description">
              {`${t("description")}`}
              {!canEditDefault ? (
                <p className="text-sm Color__Primary my-1">
                  {suspensiveCondition?.label}
                </p>
              ) : (
                <input
                  id="description"
                  className="Text__Field"
                  value={label!}
                  required
                  onChange={(e) => setLabel(e.target.value)}
                  type="text"
                  placeholder={`${t("description")} *`}
                />
              )}
            </label>
            <div className="grid sm:grid-cols-3 sm:gap-x-4 grid-cols-1">
              <div className="sm:col-span-2 flex flex-col mt-2">
                <label htmlFor="date">{`${t("date")}`}</label>
                <CustomDatePicker
                  id="date"
                  locale={fr}
                  className="Text__Field"
                  placeholderText={`${t("date")}`}
                  selected={date}
                  onChange={(changedDate) => setDate(changedDate)}
                />
              </div>

              <div>
                <label htmlFor="actions-toggle-btn">
                  {`${t("realized")}`}
                  <div className="flex items-center justify-start sm:mt-2">
                    <ToggleSwitch
                      id="actions-toggle-btn"
                      name="toggle-not-done-actions"
                      disabled={false}
                      checked={isDefinitive!}
                      onChange={handleToggleIsDefinitive}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="Modal__Footer flex justify-between">
            <div>
              <QueryBoundaries loadingComponent={<Spinner />}>
                <PermissionsGuard
                  requiredRoles={["delete:suspensive-condition"]}
                >
                  <Button
                    type="button"
                    size="medium"
                    mode="danger"
                    isLoading={false}
                    onClick={() =>
                      onDeleteSuspensiveCondition(suspensiveCondition.id)
                    }
                  >
                    {`${t("delete")}`}
                  </Button>
                </PermissionsGuard>
              </QueryBoundaries>
            </div>
            <div>
              <Button
                type="button"
                size="medium"
                mode="secondary"
                isLoading={false}
                onClick={() => toggleModal()}
              >
                {`${t("cancel")}`}
              </Button>
              <Button
                type="submit"
                marginLeft={24}
                size="medium"
                mode="primary"
              >
                {`${t("confirm")}`}
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}
