import DateFormat from "components/Date/DateFormat"
import { nanoid } from "nanoid"
import { Link } from "react-router-dom"
import {
  ActualProjectedDate,
  TLotissementsProductionDate,
} from "shared/types/spo/production.type"

export interface ISteeringProductionHousingDatesTableRowProps {
  operationHousingDate: TLotissementsProductionDate
}

function displayDate(date: ActualProjectedDate) {
  if (date.actualDate) {
    return <DateFormat date={new Date(date.actualDate)} isDefinitive />
  }
  if (date.projectedDate) {
    return <DateFormat date={new Date(date.projectedDate)} />
  }
  return "-"
}

export function SteeringProductionHousingDatesTableRow(
  props: ISteeringProductionHousingDatesTableRowProps,
) {
  const { operationHousingDate } = props

  return (
    <tr key={nanoid()}>
      <td>
        <Link
          className="text-sky-700 font-semibold underline"
          to={`${process.env.REACT_APP_SPO_URL}#/planning/gantt?operation=${operationHousingDate.registrationId}`}
          target="_blank"
        >
          {operationHousingDate.registrationId}
        </Link>
      </td>
      <td>{operationHousingDate.trancheTravauxLibelle}</td>
      <td>{displayDate(operationHousingDate.constructionLaunch)}</td>
      <td>{displayDate(operationHousingDate.daact1Signed)}</td>
      <td>{displayDate(operationHousingDate.housingEstateCertificate)}</td>
      <td>{displayDate(operationHousingDate.delayedConstructionProcedure)}</td>
      <td>{displayDate(operationHousingDate.definitiveStreetsCompleted)}</td>
      <td>{displayDate(operationHousingDate.cityRetrocession)}</td>
    </tr>
  )
}
