import GedFile from "components/Ged/GedFile"
import { useGetDocumentsByCategoryAndProjectId } from "core/ged/query-hooks/useGed"
import { useGetStudyRequestDocuments } from "core/query-hooks/useStudyRequests"
import { INFOGRAPHY_RESTITUTION_GED_CAT } from "features/studies/studyRequest.resources"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export interface IInfographyFilesRestitutionProps {
  categoryId: number
}

export function InfographyFilesRestitution(
  props: IInfographyFilesRestitutionProps,
) {
  const { id, studyRequestId } = useParams()
  const { categoryId } = props
  const { data: documents } = useGetDocumentsByCategoryAndProjectId(
    +id!,
    categoryId,
  )
  const { data: otherDocuments } = useGetStudyRequestDocuments(+studyRequestId!)
  const [files, setFiles] = useState<{ title: string; id: number }[]>()

  function filterDocuments() {
    const feasibilityDocuments = otherDocuments?.documents.find(
      (file) => file.category.id === INFOGRAPHY_RESTITUTION_GED_CAT.id,
    )

    const initialDocuments = documents?.documentCategories?.find(
      (docs) => docs.category.id === INFOGRAPHY_RESTITUTION_GED_CAT.id,
    )

    const result = initialDocuments?.gedDocuments.filter((file) =>
      feasibilityDocuments?.gedDocuments.find((f) => f.id === file.id),
    )

    return result
  }

  useEffect(() => {
    setFiles(filterDocuments())
  }, [documents, otherDocuments])

  return (
    <div>
      {files?.map((file) => (
        <GedFile id={file.id} stringLength={24} title={file.title} />
      ))}
    </div>
  )
}
