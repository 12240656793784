import classNames from "classnames"
import { t } from "i18next"
import { FaClipboardList } from "react-icons/fa"
import { NavLink } from "react-router-dom"

interface ISidebarItemSimplifiedPrograms {
  closeSidebar: () => void
}

export default function SidebarItemSimplifiedPrograms({
  closeSidebar,
}: ISidebarItemSimplifiedPrograms) {
  const handleClick = () => {
    closeSidebar()
  }

  return (
    <li>
      <NavLink
        to="/simplified-programs"
        data-title={t("simplified-programs")}
        className="sidebar-title flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 group"
        type="button"
        onClick={handleClick}
      >
        <FaClipboardList
          className={classNames(
            "text-gray-500 w-6 h-6 transition duration-75 group-hover:text-sky-800",
          )}
        />
      </NavLink>
    </li>
  )
}
