import { TAdministrationDataType } from "shared/types/administrationData.type"
import { API } from "./axios"

async function getAdministrationData(): Promise<TAdministrationDataType> {
  const res = await API.get(`/administration/data`)
  return res.data
}

async function editAdministrationData(
  data: Partial<TAdministrationDataType>,
): Promise<TAdministrationDataType> {
  const res = await API.patch(`/administration/release-notes`, data)
  return res.data
}

export { editAdministrationData, getAdministrationData }
