export const ACTION_DEPOT_PERMIS = {
  label: "Dépôt de permis",
  actionId: "26",
}
export const ACTION_LANCEMENT_COMMERCIAL = {
  label: "Lancement commercial",
  actionId: "107",
}
export const ACTION_LANCEMENT_TRAVAUX = {
  label: "Lancement travaux",
  actionId: "LTX",
}
export const ACTION_MISE_A_DISPO = {
  label: "Mise à disposition",
  actionId: "189",
}
