import { SVGProps } from "react"

function UploadIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={72}
      height={60}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.617 14.602a.728.728 0 0 1-.507-.91c1.385-4.52 5.698-7.84 10.829-7.84.416 0 .753.327.753.731a.743.743 0 0 1-.753.732c-4.3 0-8.16 2.794-9.385 6.794a.756.756 0 0 1-.937.493Z"
        fill="#0B6CFF"
      />
      <path
        d="M58.482 42.438h-4.519a.743.743 0 0 1-.753-.731c0-.404.337-.732.753-.732h4.519c6.229 0 11.296-4.924 11.296-10.976 0-6.052-5.067-10.976-11.296-10.976h-.109a.762.762 0 0 1-.569-.252.717.717 0 0 1-.176-.584c.067-.456.1-.913.1-1.36 0-5.244-4.392-9.512-9.79-9.512-2.1 0-4.102.638-5.792 1.845a.765.765 0 0 1-1.113-.25C36.25.06 23.755-1.129 17.277 6.57c-2.73 3.244-3.801 7.464-2.942 11.576.095.454-.263.878-.738.878h-.302C7.066 19.024 1.998 23.948 1.998 30c0 6.051 5.068 10.975 11.297 10.975h4.519c.416 0 .753.328.753.732a.743.743 0 0 1-.753.732h-4.519c-7.06 0-12.803-5.58-12.803-12.44 0-6.666 5.426-12.125 12.212-12.426-.638-4.266.581-8.57 3.407-11.929 6.935-8.244 20.227-7.32 25.845 1.873a11.442 11.442 0 0 1 5.982-1.665c6.54 0 11.723 5.409 11.27 11.728 6.723.366 12.076 5.796 12.076 12.419 0 6.86-5.743 12.44-12.802 12.44Z"
        fill="#0B6CFF"
      />
      <path
        d="M16.81 41.294c0 10.174 8.517 18.443 18.978 18.443 10.46 0 18.977-8.27 18.977-18.444S46.248 22.85 35.788 22.85c-10.461 0-18.978 8.27-18.978 18.444Zm1.806 0c0-9.193 7.7-16.68 17.172-16.68 9.472 0 17.17 7.486 17.17 16.68 0 9.192-7.698 16.68-17.17 16.68-9.473 0-17.172-7.487-17.172-16.68Z"
        fill="#0B6CFF"
        stroke="#F9FFF9"
        strokeWidth={0.3}
      />
      <path
        d="M36.132 49.193a.544.544 0 0 1-.552-.535V36.024l-3.47 3.37a.563.563 0 0 1-.78 0 .525.525 0 0 1 0-.757l4.39-4.265a.557.557 0 0 1 .412-.179c.164 0 .311.07.412.18l4.388 4.264a.525.525 0 0 1 0 .758.559.559 0 0 1-.78 0l-3.469-3.371v12.634a.544.544 0 0 1-.551.535Z"
        fill="#0B6CFF"
      />
      <path
        d="M36.132 49.193a.544.544 0 0 1-.552-.535V36.024l-3.47 3.37a.563.563 0 0 1-.78 0 .525.525 0 0 1 0-.757l4.39-4.265a.557.557 0 0 1 .412-.179c.164 0 .311.07.412.18l4.388 4.264a.525.525 0 0 1 0 .758.559.559 0 0 1-.78 0l-3.469-3.371v12.634a.544.544 0 0 1-.551.535Z"
        stroke="#0B6CFF"
      />
    </svg>
  )
}

export default UploadIcon
