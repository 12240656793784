import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import { Modal } from "components/Modal/Modal"
import { useAddCopromoterAssociate } from "core/query-hooks/useCopromoters"
import { t } from "i18next"
import { FormEvent, useState } from "react"
import { toast } from "react-toastify"

export interface IAddCopromoterAssociateModalProps {
  isAddCopromoterAssociateModalShowed: boolean
  toggleCopromoterAssociateModal: () => void
  setCopromoterAssociate: (createdAssociate: number) => void
}

export function AddCopromoterAssociateModal(
  props: IAddCopromoterAssociateModalProps,
) {
  const {
    isAddCopromoterAssociateModalShowed,
    toggleCopromoterAssociateModal,
    setCopromoterAssociate,
  } = props

  const addCopromoterAssociate = useAddCopromoterAssociate()

  const [copromoterAssociateName, setCopromoterAssociateName] = useState("")

  const queryClient = useQueryClient()

  async function handleSubmitPurchaseLandDateForm(
    e: FormEvent<HTMLFormElement>,
  ) {
    e.stopPropagation()
    e.preventDefault()

    addCopromoterAssociate.mutate(
      {
        label: copromoterAssociateName,
      },
      {
        onSuccess(value) {
          toast.success(t("add-copromoter-associate-success"))
          queryClient.invalidateQueries({
            queryKey: ["listCopromoterAssociates"],
          })
          setCopromoterAssociate(value.id)
          toggleCopromoterAssociateModal()
        },
        onError(error) {
          toast.error(
            `${t("add-copromoter-associate-error")} : ${error.response?.data.message}`,
          )
        },
      },
    )
  }

  return (
    <Modal
      isShowing={isAddCopromoterAssociateModalShowed}
      closeModal={toggleCopromoterAssociateModal}
      title={`${t("add-copromoter-associate")}`}
    >
      <form
        className="Text__Field_Container"
        onSubmit={(e) => handleSubmitPurchaseLandDateForm(e)}
      >
        <div className="mt-2 mb-2">
          <label htmlFor="label-copromoter-associate-name">
            {t("associate-name")}
            <input
              id="label-copromoter-associate-name"
              required
              onChange={(e) => setCopromoterAssociateName(e.target.value)}
              type="text"
              className="Text__Field"
              name="copromoter-associate-name"
            />
          </label>
        </div>
        <div className="flex items-center justify-between mt-6">
          <Button
            onClick={() => toggleCopromoterAssociateModal()}
            size="small"
            mode="secondary"
          >
            {`${t("cancel")}`}
          </Button>

          <Button
            isLoading={addCopromoterAssociate.isPending}
            type="submit"
            size="small"
            mode="primary"
          >
            {`${t("create")}`}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
