import { Button } from "components/Button/Button"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import * as React from "react"
import { useState } from "react"
import { registerLocale } from "react-datepicker"

registerLocale("fr", fr)
interface IAddTaskModalProps {
  addTask: (
    label: string,
    isDefinitive: boolean,
    date?: Date | null,
    comment?: string,
    reminder?: Date | null,
  ) => void
}

export default function AddTaskModal({ addTask }: IAddTaskModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  const [date, setDate] = useState<null | Date | undefined>()
  const [label, setLabel] = useState<string>()
  const [comment, setComment] = useState<string | undefined>()
  const [reminder, setReminder] = useState<null | Date | undefined>()
  const [isDefinitive, setIsDefinitive] = useState<boolean>(false)

  const handleToggleIsDefinitive = () => {
    setIsDefinitive(!isDefinitive)
  }

  const onAddTask = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    addTask(label!, isDefinitive, date, comment, reminder)
    setDate(null)
    setLabel("")
    setComment(undefined)
    setReminder(null)
    setIsDefinitive(false)
    toggleModal()
  }

  return (
    <>
      <Button onClick={toggleModal} size="small" mode="primary">
        {`${t("add")}`}
      </Button>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("add-task")}`}
      >
        <form onSubmit={(e) => onAddTask(e)} className="Text__Field_Container">
          <label htmlFor="label">
            {`${t("label")} *`}
            <input
              id="label"
              className="Text__Field"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              type="text"
              name="label"
              required
              placeholder={`${t("label")}`}
            />
          </label>

          <label htmlFor="comment">
            {`${t("comment")}`}
            <textarea
              id="comment"
              className="Textarea__Field"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={`${t("comment")}`}
            />
          </label>

          <div className="flex flex-col mt-1">
            <label htmlFor="reminder">{`${t("reminder-date")}`}</label>
            <CustomDatePicker
              id="reminder"
              locale={fr}
              className="Text__Field"
              placeholderText={`${t("reminder-date")}`}
              selected={reminder}
              onChange={(changedDate) => setReminder(changedDate)}
            />
          </div>

          <div className="grid sm:grid-cols-3 sm:gap-x-4 grid-cols-1 mt-2">
            <div className="sm:col-span-2 flex flex-col">
              <label htmlFor="deadline">{`${t("deadline")} *`}</label>
              <CustomDatePicker
                id="deadline"
                locale={fr}
                className="Text__Field"
                placeholderText={`${t("deadline")}`}
                selected={date}
                onChange={(changedDate) => setDate(changedDate)}
                required
              />
            </div>

            <div>
              <label htmlFor="actions-toggle-btn">
                {`${t("realized")}`}
                <div className="flex items-center justify-start sm:mt-2">
                  <ToggleSwitch
                    id="actions-toggle-btn"
                    name="toggle-not-done-actions"
                    disabled={false}
                    checked={isDefinitive}
                    onChange={handleToggleIsDefinitive}
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="Modal__Footer mt-4">
            <Button
              type="button"
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => toggleModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button type="submit" marginLeft={24} size="medium" mode="primary">
              {`${t("confirm")}`}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
