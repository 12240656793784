import { useQueries } from "@tanstack/react-query"
import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { getCollectifsProductionDates } from "core/spo/api/production"
import { t } from "i18next"
import { Fragment } from "react"
import {
  RESIDENTIAL_TYPE,
  TERTIARY_TYPE,
} from "shared/resources/operation.resources"
import { TChildOperation } from "shared/types/operation.type"
import { TechnicalProductionCollectiveRow } from "./TechnicalProductionCollectiveRow"

export interface IOperationProductionProps {
  operationsChildren: TChildOperation[]
}

export function TechnicalProductionCollectiveDatesTable(
  props: IOperationProductionProps,
) {
  const { operationsChildren } = props

  const operationsDate = useQueries({
    queries: operationsChildren
      ?.filter(
        (operation) =>
          operation.type.id === TERTIARY_TYPE.id ||
          operation.type.id === RESIDENTIAL_TYPE.id,
      )
      .map((operation) => {
        return {
          queryKey: ["operationChildren", operation.id],
          queryFn: () =>
            getCollectifsProductionDates(operation.registrationNumber),
        }
      }),
  })

  const headers = [
    `${t("tranche-travaux")}`,
    `${t("start-work")}`,
    `${t("levelling")}`,
    `${t("fundation")}`,
    `${t("first-level-slab")}`,
    `${t("last-level-slab")}`,
    `${t("set-rain-tight")}`,
    `${t("set-wind-tight")}`,
    `${t("end-partition")}`,
    `${t("completion")}`,
    `${t("disposal")}`,
  ]

  if (operationsDate[0] && operationsDate[0].isLoading) return <Spinner />

  return (
    <div className="overflow-auto">
      {operationsDate[0] &&
      operationsDate[0].data &&
      operationsDate[0].data.length > 0 ? (
        <div className="Light_Table__Wrapper">
          <table className="table-auto Table">
            <thead>
              <tr className="text-left">
                {headers.map((header, index) => (
                  <th
                    className={classNames(
                      "Table__Head Table__Top_Border",
                      index === 0 ? "w-36" : "w-52",
                    )}
                    key={header}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {operationsDate[0].data.map((lotsCollectifDate) => (
                <Fragment
                  key={`${lotsCollectifDate.registrationId}-${lotsCollectifDate.trancheTravauxId}`}
                >
                  <TechnicalProductionCollectiveRow
                    lotsCollectifDate={lotsCollectifDate}
                  />
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-sm ml-4 mb-2 text-primary-dark">
          {t("no-operation-production-date")}
        </p>
      )}
    </div>
  )
}
