import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import Avatar from "components/Avatar/Avatar"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { useUpdateStudyRequest } from "core/query-hooks/useStudyRequests"
import { useGetUsersByOccupationId } from "core/query-hooks/useUsers"
import { t } from "i18next"
import { useState } from "react"
import { RiPencilFill } from "react-icons/ri"
import Select, {
  MultiValue,
  MultiValueGenericProps,
  OptionProps,
  components,
} from "react-select"
import { toast } from "react-toastify"
import { TStudyRequest } from "shared/types/study.type"
import { TUser } from "shared/types/user.type"
import {
  CONCEPTEUR_OCCUPATION_ID,
  ECONOMIST_OCCUPATION_ID,
  INFOGRAPHIST_OCCUPATION_ID,
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "./studyRequest.resources"

function MultiValueLabel(properties: MultiValueGenericProps<any>) {
  const { data, children } = properties
  return (
    <components.MultiValueLabel {...properties}>
      <div className="flex items-center">
        <div className="AddContributor__Option_Avatar_Container mr-1 flex items-center justify-center">
          <Avatar size="small" user={data} />
        </div>
        {children}
      </div>
    </components.MultiValueLabel>
  )
}

function Option(properties: OptionProps) {
  const {
    data,
    children,
    className = "px-2 Border__Bottom_LightGrey py-2 AddContributor__Option",
    cx,
    innerRef,
    innerProps,
  } = properties

  return (
    <div
      ref={innerRef}
      className={cx(
        {
          option: true,
        },
        className,
      )}
      {...innerProps}
    >
      <div className="flex items-center cursor-pointer">
        <div className="AddContributor__Option_Avatar_Container mr-1 flex items-center justify-center">
          <Avatar size="small" user={data as TUser} />
        </div>
        {children}
      </div>
    </div>
  )
}

export interface IEditAttributionModalProps {
  studyRequest: TStudyRequest
}

export function EditAttributionModal(props: IEditAttributionModalProps) {
  const { studyRequest } = props
  const [assignedUsers, setAttributedUsers] = useState<string[] | undefined>(
    studyRequest?.assignedUsers,
  )
  const updateStudyRequest = useUpdateStudyRequest(studyRequest.id)
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const queryClient = useQueryClient()
  let occupationId: number = 0
  switch (studyRequest.typeId) {
    case TYPE_FEASIBILITY:
      occupationId = CONCEPTEUR_OCCUPATION_ID
      break
    case TYPE_INFOGRAPHY:
      occupationId = INFOGRAPHIST_OCCUPATION_ID
      break
    case TYPE_COST_OF_WORK:
      occupationId = ECONOMIST_OCCUPATION_ID
      break
    default:
  }

  const { data: attributableUsers } = useGetUsersByOccupationId(occupationId)
  const formattedAttributableUsers = attributableUsers?.map((user) => ({
    ...user,
    label: `${user.firstName} ${user.lastName}`,
    value: user.azureId,
  }))

  // Formatage des utilisateurs attribuées pour les default values
  const formattedHistoryAttributedUsers: any[] = []
  if (attributableUsers && studyRequest?.assignedUsers) {
    attributableUsers?.forEach((user) => {
      if (
        studyRequest.assignedUsers?.some((azureId) => azureId === user.azureId)
      ) {
        formattedHistoryAttributedUsers.push({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
          value: user.azureId,
        })
      }
    })
  }

  function invalidateQueries() {
    queryClient.invalidateQueries({
      queryKey: ["getCostOfWork", studyRequest.id],
    })
    queryClient.invalidateQueries({
      queryKey: ["getFeasibility", studyRequest.id],
    })
    queryClient.invalidateQueries({
      queryKey: ["getInfography", studyRequest.id],
    })
    queryClient.invalidateQueries({
      queryKey: ["listStudyRequests", studyRequest.projectId!],
    })
    queryClient.invalidateQueries({
      queryKey: ["getFeasibilityHistory", studyRequest.id],
    })
    queryClient.invalidateQueries({
      queryKey: ["getCostOfWorkHistory", studyRequest.id],
    })
    queryClient.invalidateQueries({
      queryKey: ["getInfographyHistory", studyRequest.id],
    })
  }

  async function submitForm() {
    assignedUsers && assignedUsers.length > 0
      ? updateStudyRequest.mutate(
          {
            assignedUsers,
          },
          {
            onSuccess: () => {
              toast.success(`${t("studyRequest.toast.attributionSuccess")}`)
              invalidateQueries()
              toggleModal()
            },
            onError: (err) =>
              toast.error(
                `${t("studyRequest.toast.attributionError")} ${
                  err.response?.data.message
                }`,
              ),
          },
        )
      : toast.error(`${t("studyRequest.toast.requiredAttributedUsers")}`)
  }

  function handleOnChange(usersValue: MultiValue<TUser>) {
    setAttributedUsers(usersValue.map((user) => user.azureId))
  }

  return (
    <div>
      <button
        type="button"
        onClick={toggleModal}
        className="ml-4 text-cyan-800 rounded-lg flex items-center justify-center p-1 border border-slate-100 hover:bg-slate-100"
        title="Modifier l'attribution"
      >
        <RiPencilFill size={20} />
      </button>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("edit-attribution")}`}
      >
        <div className="Text__Field_Container">
          <label htmlFor="attribution">
            {`${t("studyRequest.attribution")}`} {`${t("max3persons")}`} *
            {formattedAttributableUsers ? (
              <div className="AddPlot__Modal pb-2">
                <Select
                  id="attribution"
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "#F9FAFB",
                      border: "1px solid #F0F0F0",
                    }),
                    indicatorSeparator: (styles) => ({
                      ...styles,
                      backgroundColor: "none",
                    }),
                    multiValue: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      border: "1px solid #F0F0F0",
                      borderRadius: 27,
                    }),
                  }}
                  isMulti
                  onChange={(users) => handleOnChange(users)}
                  placeholder="Sélectionner"
                  options={formattedAttributableUsers}
                  defaultValue={formattedHistoryAttributedUsers}
                  components={{
                    MultiValueLabel,
                    Option,
                  }}
                  isOptionDisabled={() =>
                    assignedUsers !== undefined && assignedUsers.length >= 3
                  }
                  isClearable={false}
                />
              </div>
            ) : null}
          </label>

          <div className="Modal__Footer flex justify-between mt-4">
            <Button
              type="button"
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => toggleModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              type="submit"
              marginLeft={24}
              size="medium"
              mode="primary"
              isLoading={false}
              onClick={() => submitForm()}
            >
              {`${t("confirm")}`}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
