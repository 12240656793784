import LightTable from "components/Table/LightTable"
import { format } from "date-fns"
import { getLabelFromTypeId } from "features/studies/StudyRequestsList"
import { StudyStatus } from "features/studies/StudyStatus"
import {
  STATUS_DRAFT,
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "features/studies/studyRequest.resources"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import { TStudyRequestWithProject } from "shared/types/study.type"
import getStudyRequestUrl from "utils/getStudyRequestUrl"

export interface IUserStudyRequestsProps {
  studyRequests: TStudyRequestWithProject[]
}

export function UserStudyRequests(props: IUserStudyRequestsProps) {
  const { studyRequests } = props
  const navigate = useNavigate()

  const studyRequestsList = studyRequests
    .sort((a, b) => {
      if (a.statusId < b.statusId) return -1
      if (a.statusId > b.statusId) return 1
      return 0
    })
    .map((s) => {
      if (s.typeId === TYPE_COST_OF_WORK) {
        return { costOfWorkVersion: s.version, ...s }
      }
      if (s.typeId === TYPE_INFOGRAPHY) {
        return { infographyVersion: s.version, ...s }
      }
      return s
    })

  const headers = [
    () => t("studyRequest.date"),
    () => t("project-title"),
    () => t("type"),
    () => t("studyRequest.feasibilityVersionRaw"),
    () => t("studyRequest.costOfWorkVersionRaw"),
    () => t("studyRequest.infographyVersionRaw"),
    () => t("batches-number"),
    () => t("progress"),
  ]

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("studyRequest.date")}`, value: ["createdAt"] },
    { key: `${t("project-title")}`, value: ["project", "name"] },
    { key: `${t("type")}`, value: ["typeId"] },
    { key: `${t("creator")}`, value: ["creator", "firstName"] },
    {
      key: `${t("studyRequest.feasibilityVersionRaw")}`,
      value: ["feasibilityVersion"],
    },
    {
      key: `${t("studyRequest.costOfWorkVersionRaw")}`,
      value: ["costOfWorkVersion"],
    },
    {
      key: `${t("studyRequest.infographyVersionRaw")}`,
      value: ["infographyVersion"],
    },
    { key: `${t("batches-number")}`, value: ["numberOfLots"] },
    { key: `${t("progress")}`, value: ["statusId"] },
  ]

  return (
    <div className="rounded-lg shadow-lg">
      {studyRequestsList?.length ? (
        <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
          <LightTable
            showTotal={false}
            className="table-auto"
            items={studyRequestsList}
            headers={headers}
            sortableFields={sortableFields}
            renderRow={(item) => {
              const routeName: string = item.project.isRegistered
                ? "operation"
                : "project"
              return (
                <>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          item.projectId,
                          routeName,
                          item.typeId,
                          item.statusId === STATUS_DRAFT,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    <div className="flex items-center">
                      {format(new Date(item.createdAt), "dd/MM/yyyy")}
                    </div>
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          item.projectId,
                          routeName,
                          item.typeId,
                          item.statusId === STATUS_DRAFT,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    {item.typeId ? (
                      <div className="flex items-center">
                        {item.project.name}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          item.projectId,
                          routeName,
                          item.typeId,
                          item.statusId === STATUS_DRAFT,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    {item.typeId ? (
                      <div className="flex items-center">
                        {getLabelFromTypeId(item.typeId)}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          item.projectId,
                          routeName,
                          item.typeId,
                          item.statusId === STATUS_DRAFT,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    <div className="flex items-center">
                      {item.feasibilityVersion ?? "-"}
                    </div>
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          item.projectId,
                          routeName,
                          item.typeId,
                          item.statusId === STATUS_DRAFT,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    {item.typeId === TYPE_COST_OF_WORK ? (
                      <div className="flex items-center">
                        {item.costOfWorkVersion}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          item.projectId,
                          routeName,
                          item.typeId,
                          item.statusId === STATUS_DRAFT,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    {item.typeId === TYPE_INFOGRAPHY ? (
                      <div className="flex items-center">
                        {item.infographyVersion}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          item.projectId,
                          routeName,
                          item.typeId,
                          item.statusId === STATUS_DRAFT,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    {item.typeId === TYPE_FEASIBILITY ? (
                      <div className="flex items-center">
                        {item.numberOfLots}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          item.projectId,
                          routeName,
                          item.typeId,
                          item.statusId === STATUS_DRAFT,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    <StudyStatus requestStatusId={item.statusId} />
                  </td>
                </>
              )
            }}
          />
        </div>
      ) : (
        <p className="text-sm ml-2">{t("no-study-request")}</p>
      )}
    </div>
  )
}
