import Spinner from "components/Spinner/Spinner"
import { useGetSimplifiedPrograms } from "core/query-hooks/useSimplifiedPrograms"
import { SimplifiedProgramsList } from "features/simplifiedPrograms/SimplifiedProgramsList"
import { t } from "i18next"

export default function ListSimplifiedPrograms() {
  const { data, isLoading, error } = useGetSimplifiedPrograms()

  if (isLoading) return <Spinner />

  if (error || !data) return <p>{error?.response?.data.message}</p>

  return (
    <>
      <h2 className="col-span-6 font-semibold md:mb-0 px-4 pt-4 Color__Primary">
        {t("simplified-programs")}
      </h2>
      <SimplifiedProgramsList data={data} />
    </>
  )
}
