import Box from "components/Box/Box"
import DateFormat from "components/Date/DateFormat"
import LightTable from "components/Table/LightTable"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { FaRegBell } from "react-icons/fa"
import { MdCalendarToday } from "react-icons/md"
import { useNavigate, useParams } from "react-router-dom"
import { TTask } from "shared/types/task.type"

interface TasksListProps {
  tasks: TTask[]
}

export default function OperationTasksList(props: TasksListProps) {
  const { tasks } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const headers = [t("agreement"), t("label"), t("reminder"), t("deadline")]

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("agreement")}`, value: ["agreement", "name"] },
    { key: `${t("label")}`, value: ["label"] },
    { key: `${t("reminder")}`, value: ["reminder"] },
    { key: `${t("deadline")}`, value: ["deadline"] },
  ]

  function onClickRow(agreeementId: number) {
    navigate(`/operation/${id}/agreements/${agreeementId}`)
  }

  return (
    <Box classNames="p-0">
      <div className="flex items-center justify-between border-gray-200 px-3.5 py-2">
        <div className="flex items-center text-cyan-900 font-medium">
          <h3 className="text-xs uppercase">{t("tasks")}</h3>
        </div>
      </div>
      <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
        <LightTable
          showTotal={false}
          className="table-auto"
          items={tasks}
          sortableFields={sortableFields}
          headers={headers}
          renderRow={(task) => (
            <>
              <td
                onClick={() => onClickRow(task.agreement.id)}
                className="font-semibold cursor-pointer w-1/5"
              >
                {task?.agreement.name}
              </td>
              <td
                onClick={() => onClickRow(task.agreement.id)}
                className="cursor-pointer w-1/2"
              >
                <div className="grid grid-rows-2 grid-flow-col">
                  <p className="text-blue-500">{task.label}</p>
                  <p>{task.comment}</p>
                </div>
              </td>
              <td
                onClick={() => onClickRow(task.agreement.id)}
                className="cursor-pointer"
              >
                <div className="flex items-center">
                  <TooltipWithHover message={`${t("reminder")}`}>
                    <FaRegBell size={16} color="#00304d" className="mr-1" />
                  </TooltipWithHover>
                  <p className="text-sm Color__Primary">
                    {task?.reminder
                      ? format(new Date(task.reminder), "dd/MM/yyyy")
                      : "-"}
                  </p>
                </div>
              </td>
              <td
                onClick={() => onClickRow(task.agreement.id)}
                className="cursor-pointer"
              >
                <div className="flex items-center">
                  <TooltipWithHover message={`${t("deadline")}`}>
                    <MdCalendarToday
                      color="#00304d"
                      size={16}
                      className="mr-1"
                    />
                  </TooltipWithHover>
                  <p>
                    <DateFormat
                      isDefinitive={task.isDefinitive}
                      date={task.deadline}
                    />
                  </p>
                </div>
              </td>
            </>
          )}
        />
      </div>
    </Box>
  )
}
