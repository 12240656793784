import classNames from "classnames"
import { ChangeEventHandler, useEffect, useState } from "react"

export interface ISelectWithOnChangeProps {
  options:
    | {
        value: number
        label: string
      }[]
    | undefined
  onChange: (selectedValue: string | null) => void
  defaultValue?: number | null
  hasEmptyOption?: boolean
  className?: string
}

export function SelectWithOnChange(props: ISelectWithOnChangeProps) {
  const {
    options,
    onChange,
    defaultValue = null,
    hasEmptyOption = false,
    className,
  } = props

  const [selectedValue, setSelectedValue] = useState(defaultValue || "")

  useEffect(() => {
    setSelectedValue(defaultValue || "")
  }, [defaultValue])

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target
    setSelectedValue(value)
    onChange(value === "" ? null : value)
  }

  const emptyOption = hasEmptyOption ? [{ value: "", label: "" }] : []

  return (
    <div className={classNames("Text__Field_Container px-4", className)}>
      <select
        className="Select__Wrapper Select__Field Text__Field cursor-pointer"
        value={selectedValue}
        onChange={handleChange}
      >
        {options !== undefined
          ? [...emptyOption, ...options].map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          : null}
      </select>
    </div>
  )
}
