import { t } from "i18next"
import { useNavigate, useParams } from "react-router-dom"
import ArrowLeft from "../../components/Icons/ArrowLeft"
import { LinkCustom } from "../../components/Link/Link"
import ContributorsTable from "../contributors/ContributorsTable"

export default function ProjectContributors() {
  const navigate = useNavigate()
  const params = useParams()
  return (
    <div className="px-4 pb-72">
      <LinkCustom
        classNames="py-4"
        icon={ArrowLeft}
        iconSize={16}
        iconPosition="left"
        onClick={() => navigate(`/project/${params.id}`)}
      >
        {`${t("general-informations")}`}
      </LinkCustom>
      <ContributorsTable />
    </div>
  )
}
