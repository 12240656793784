import { useQuery } from "@tanstack/react-query"
import Box from "components/Box/Box"
import { getContributorsByProjectId } from "core/api/contributors"
import { t } from "i18next"
import { HiUsers } from "react-icons/hi"
import { Link } from "react-router-dom"
import ContributorPreviewAvatar from "./ContributorPreviewAvatar"

export interface IContributorsPreviewProps {
  projectId: number
}

export function ContributorsPreview(props: IContributorsPreviewProps) {
  const { projectId } = props

  const { data: contributorsData } = useQuery({
    queryKey: ["useGetContributorsByProjectId", projectId],
    queryFn: () => getContributorsByProjectId(projectId),
  })

  return (
    <Box classNames="p-0">
      <div className="flex items-center border-b border-gray-200 px-3.5 py-2">
        <HiUsers size={20} className="text-cyan-900 mr-1" />
        <h3 className="text-xs uppercase text-cyan-900 font-medium">
          {`${t("contributors")}`}
        </h3>
      </div>
      <div className="py-4 px-4">
        <ul className="flex flex-wrap gap-x-2 mb-6">
          {contributorsData &&
            contributorsData.map((contributor) => (
              <li
                key={`${contributor.userAzureId}-contributor-preview`}
                className="mb-2"
              >
                <ContributorPreviewAvatar
                  userAzureId={contributor.userAzureId}
                />
              </li>
            ))}
        </ul>
        <Link className="Text__Link" to="contributors">
          {contributorsData?.length ? (
            <p> {`${t("view-all")}`}</p>
          ) : (
            <p>{`${t("add")}`}</p>
          )}
        </Link>
      </div>
    </Box>
  )
}
