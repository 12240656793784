import { API } from "core/api/axios"
import {
  TAgreement,
  TAgreementChildren,
  TAgreementList,
  TMutateAgreement,
} from "shared/types/agreement.type"

async function getAgreement(id: number): Promise<TAgreement> {
  return typeof id === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/agreements/${id}`).then((response) => response.data)
}

async function createAgreement(
  agreement: TMutateAgreement,
): Promise<TAgreement> {
  const res = await API.post("/agreement", agreement)
  return res.data
}

async function getAgreementsByProjectId(
  projectId?: string,
  search?: string,
): Promise<TAgreementList> {
  return typeof projectId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/agreements?project=${projectId}&search=${search}`).then(
        (response) => response.data,
      )
}

async function updateAgreement(
  id: number,
  agreement: TMutateAgreement,
): Promise<TAgreement> {
  const res = await API.patch(`/agreement/${id}`, agreement)
  return res.data
}

async function deleteAgreement(id: string): Promise<TAgreement> {
  const res = await API.delete(`/agreement/${id}`)
  return res.data
}

async function getAgreementChildren(id: string): Promise<TAgreementChildren> {
  return typeof id === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/agreements/${id}/child-records`).then(
        (response) => response.data,
      )
}

async function addAgreementPlot(id: number, plotId: number): Promise<void> {
  const res = await API.post(`/agreements/${id}/plot`, { plotId })
  return res.data
}

async function deleteAgreementPlot(id: number, plotId: number): Promise<void> {
  const res = await API.delete(`/agreements/${id}/plot`, { data: { plotId } })
  return res.data
}

export {
  addAgreementPlot,
  createAgreement,
  deleteAgreement,
  deleteAgreementPlot,
  getAgreement,
  getAgreementChildren,
  getAgreementsByProjectId,
  updateAgreement,
}
