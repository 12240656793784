import classNames from "classnames"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { AiFillCloseCircle } from "react-icons/ai"
import { RiCheckboxCircleFill } from "react-icons/ri"
import { TProjectStep } from "shared/types/project.type"

export interface IDisplayCircleStep {
  stepId: number
  statusToCompare: number
  steps: TProjectStep[]
  isAbandoned?: boolean
}

export function DisplayCircleStep(props: IDisplayCircleStep) {
  const { steps, stepId, statusToCompare, isAbandoned } = props
  function displayTooltip(status: number) {
    return steps?.find((step) => step.id === status)?.tooltip || ""
  }

  if (stepId && stepId > statusToCompare) {
    return (
      <>
        {isAbandoned ? (
          <span className="flex items-center operation-stepper-abandoned-validated" />
        ) : (
          <TooltipWithHover
            position="right"
            message={displayTooltip(statusToCompare)}
          >
            <RiCheckboxCircleFill
              fontSize={32}
              className="operation-stepper-done-icon z-10"
            />
          </TooltipWithHover>
        )}
      </>
    )
  }

  if (stepId === statusToCompare) {
    return (
      <TooltipWithHover message={displayTooltip(statusToCompare)}>
        <span
          className={classNames("flex items-center", {
            "operation-stepper-outter": !isAbandoned,
            "operation-stepper-outter-abandoned": isAbandoned,
          })}
        >
          {isAbandoned ? (
            <AiFillCloseCircle
              className="operation-stepper-abandoned-icon z-10"
              fontSize={32}
            />
          ) : (
            <span className="flex items-center operation-stepper-inner" />
          )}
        </span>
      </TooltipWithHover>
    )
  }

  if (stepId && stepId < statusToCompare) {
    return (
      <TooltipWithHover message={displayTooltip(statusToCompare)}>
        <span className="flex items-center operation-stepper-in-progress" />
      </TooltipWithHover>
    )
  }

  return null
}
