import classNames from "classnames"
import { useGetAvatar } from "core/query-hooks/useUsers"
import { ComponentProps } from "react"
import {
  TPersonInCharge,
  TPersonInChargeOption,
  TUser,
} from "shared/types/user.type"

export interface IAvatarBlob extends ComponentProps<"img"> {
  user: TUser | TPersonInChargeOption | TPersonInCharge
  isDarkMode?: boolean
  initials?: boolean
}
export default function AvatarBlob(props: IAvatarBlob) {
  const { className, user, isDarkMode, initials = false } = props
  if (!initials) {
    const { data: avatarBlob } = useGetAvatar(user?.azureId)

    return avatarBlob ? (
      <div className={classNames(className, "overflow-hidden mr-1")}>
        <img
          loading="lazy"
          className="rounded-full AvatarWrapper"
          src={avatarBlob}
          alt="avatar"
        />
      </div>
    ) : (
      <span
        className={classNames(
          "Avatar__Content Avatar__Container rounded-full mr-1",
          isDarkMode ? "DarkMode" : "LightMode",
          className,
        )}
      >
        {user?.firstName.slice(0, 1)}
        {user?.lastName.slice(0, 1)}
      </span>
    )
  }

  return (
    <span
      className={classNames(
        "Avatar__Content Avatar__Container rounded-full mr-1",
        isDarkMode ? "DarkMode" : "LightMode",
        className,
      )}
    >
      {user?.firstName.slice(0, 1)}
      {user?.lastName.slice(0, 1)}
    </span>
  )
}
