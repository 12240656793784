import { t } from "i18next"
import { Dispatch, ReactElement, SetStateAction } from "react"

import classNames from "classnames"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { FormTextField } from "components/Form/FormTextField"
import { Header } from "components/Header/Header"
import { fr } from "date-fns/locale"
import { registerLocale } from "react-datepicker"
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form"
import { TCurrency } from "shared/types/global.type"
import useIsMobile from "utils/isMobile"
import { CreateAgreementForm } from "../AgreementCreate"

registerLocale("fr", fr)

interface IFormaAddProps {
  children?: ReactElement[] | ReactElement
  onceSubmited: (data: CreateAgreementForm) => void
  register: UseFormRegister<CreateAgreementForm>
  handleSubmit: UseFormHandleSubmit<CreateAgreementForm>
  errors: FieldErrorsImpl<CreateAgreementForm>
  control: Control<any>
  setCashPriceValue: Dispatch<
    SetStateAction<{
      value?: number
      unit: TCurrency
    }>
  >
  cashPriceValue: {
    value?: number
    unit: TCurrency
  }
  setDationValue: Dispatch<
    SetStateAction<{
      value?: number
      unit: TCurrency
    }>
  >
  dationValue: {
    value?: number
    unit: TCurrency
  }
  setInjonctionDeFaireValue: Dispatch<
    SetStateAction<{
      value?: number
      unit: TCurrency
    }>
  >
  injonctionDeFaireValue: {
    value?: number
    unit: TCurrency
  }
  setImmoCompensationValue: Dispatch<
    SetStateAction<{
      value?: number
      unit: TCurrency
    }>
  >
  immoCompensationValue: {
    value?: number
    unit: TCurrency
  }
  isCreating: boolean
}

export default function FormCreate({
  children,
  register,
  handleSubmit,
  errors,
  control,
  onceSubmited,
  setCashPriceValue,
  cashPriceValue,
  immoCompensationValue,
  setImmoCompensationValue,
  setInjonctionDeFaireValue,
  injonctionDeFaireValue,
  setDationValue,
  dationValue,
  isCreating,
}: IFormaAddProps) {
  const onSubmit = (data: CreateAgreementForm) => onceSubmited(data)
  const isMobile = useIsMobile()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box classNames="px-4 mb-8">
        <Header size="h2" classNames="pt-2">
          {`${t("agreements.formSectionTitle.general")}`}
        </Header>
        <FormTextField
          id="name"
          className="Text__Field_Container my-2"
          errors={errors}
          name="name"
          register={register}
          label={`${t("agreements.create.name")} *`}
          type="text"
        />
        <Header size="h2" classNames="pt-2">
          {`${t("price")}`}
        </Header>
        <div
          className={classNames("flex gap-4", [isMobile ? "flex-wrap" : ""])}
        >
          <div className="w-full md:w-1/3">
            <FormTextField
              id="cashPrice"
              errors={errors}
              setPriceValue={setCashPriceValue}
              priceValue={cashPriceValue}
              name="cashPrice"
              className="Text__Field_Container my-2"
              register={register}
              label={`${t("agreements.create.cashPrice")}`}
              type="currency"
            />
          </div>
          <div className="w-full md:w-1/3">
            <FormTextField
              id="dation"
              errors={errors}
              setPriceValue={setDationValue}
              priceValue={dationValue}
              name="dation"
              className="Text__Field_Container my-2"
              register={register}
              label={`${t("agreements.create.gift")}`}
              type="currency"
            />
          </div>
          <div className="w-full md:w-1/3">
            <FormTextField
              id="injonctionDeFaire"
              errors={errors}
              setPriceValue={setInjonctionDeFaireValue}
              priceValue={injonctionDeFaireValue}
              name="injonctionDeFaire"
              className="Text__Field_Container my-2"
              register={register}
              label={`${t("agreements.create.mustDo")}`}
              type="currency"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
          <div className="md:col-start-3 md:col-end-5 w-full">
            <FormTextField
              id="dationDetails"
              control={control}
              errors={errors}
              name="dationDetails"
              className="Text__Field_Container my-2 flex-1"
              register={register}
              label={`${t("details")}`}
              type="text"
            />
          </div>
          <div className="md:col-start-5 md:col-end-7 w-full">
            <FormTextField
              id="injonctionDeFaireDetails"
              control={control}
              errors={errors}
              name="injonctionDeFaireDetails"
              className="Text__Field_Container my-2 flex-1"
              register={register}
              label={`${t("details")}`}
              type="text"
            />
          </div>
        </div>
        <Header size="h2" classNames="pt-2">
          {`${t("agreements.formSectionTitle.immoCompensation")}`}
        </Header>
        <div className="flex flex-wrap items-end gap-y-4 gap-x-8 w-full">
          <div className="w-full md:w-auto">
            <FormTextField
              id="immoCompensationPrice"
              setPriceValue={setImmoCompensationValue}
              priceValue={immoCompensationValue}
              errors={errors}
              name="immoCompensationPrice"
              className="Text__Field_Container my-2"
              register={register}
              label={`${t("amount")}`}
              type="currency"
            />
          </div>
          <div className="w-full md:flex-1">
            <FormTextField
              id="immoCompensationStatus"
              control={control}
              errors={errors}
              name="immoCompensationStatus"
              className="Text__Field_Container my-2"
              register={register}
              leftLabel="A virer"
              rightLabel="A cautionner"
              label={`${t("status")}`}
              type="checkbox"
            />
          </div>
          <div className="Text__Field_Container w-full md:flex-1 mb-4 mr-3">
            <label
              htmlFor="immoCompensationDate"
              className="mr-2"
            >{`${t("before-the")}`}</label>
            <Controller
              control={control}
              name="immoCompensationDate"
              render={({ field }) => (
                <CustomDatePicker
                  id="immoCompensationDate"
                  locale={fr}
                  className="Text__Field"
                  selected={field.value ? new Date(field.value) : null}
                  placeholderText={`${t("datePlaceholder")}`}
                  onChange={(e) => field.onChange(e)}
                />
              )}
            />
          </div>
        </div>
        <Header size="h2" classNames="pt-2">
          {`${t("timing")}`}
        </Header>
        <div className="flex flex-wrap Gap__8 Text__Field_Container mt-1 mb-2">
          <div className="flex flex-col flex-1">
            <label htmlFor="signature">{`${t("signature-date")}`}</label>
            <Controller
              control={control}
              name="signature"
              render={({ field }) => (
                <CustomDatePicker
                  id="signature"
                  locale={fr}
                  className="Text__Field"
                  selected={field.value ? new Date(field.value) : null}
                  placeholderText={`${t("datePlaceholder")}`}
                  onChange={(e) => field.onChange(e)}
                />
              )}
            />
          </div>
          <div className="Text__Field_Container flex flex-col flex-1">
            <label htmlFor="termDate">{`${t("term-date")}`}</label>
            <Controller
              control={control}
              name="termDate"
              render={({ field }) => (
                <CustomDatePicker
                  id="termDate"
                  locale={fr}
                  className="Text__Field"
                  selected={field.value ? new Date(field.value) : null}
                  placeholderText={`${t("datePlaceholder")}`}
                  onChange={(e) => field.onChange(e)}
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-end items-center mt-4 Padding__Top_24">
          {children}
          <div style={{ marginLeft: 16 }}>
            <Button
              type="submit"
              size="medium"
              mode="primary"
              isLoading={false}
              isDisabled={isCreating}
            >
              {`${t("create")}`}
            </Button>
          </div>
        </div>
      </Box>

      <Box classNames="relative mb-8 py-4 opacity-50">
        <Header size="h2" classNames="mb-2">
          {`${t("suspensive-conditions")}`}
        </Header>
        <p className="text-xs text-primary-font">
          {`${t("agreements.emptySuspensiveConditions")}`}
        </p>
      </Box>

      <Box classNames="relative mb-8 py-4 opacity-50">
        <Header size="h2" classNames="mb-2">
          {`${t("agreements.create.plots")}`}
        </Header>
        <p className="text-xs text-primary-font">
          {`${t("agreements.emptyPlots")}`}
        </p>
      </Box>

      <Box classNames="relative mb-8 py-4 opacity-50">
        <Header size="h2" classNames="mb-2">
          {`${t("tasks")}`}
        </Header>
        <p className="text-xs text-primary-font">
          {`${t("agreements.emptyTasks")}`}
        </p>
      </Box>

      <Box classNames="relative mb-8 py-4 opacity-50">
        <Header size="h2" classNames="mb-2">
          {`${t("amendments")}`}
        </Header>
        <p className="text-xs text-primary-font">
          {`${t("agreements.emptyAmendments")}`}
        </p>
      </Box>
    </form>
  )
}
