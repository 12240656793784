import { API } from "core/api/axios"
import { TUserContributor } from "shared/types/user.type"
import {
  TContributorCreate,
  TContributorPreview,
} from "../../shared/types/contributor.type"

async function getContributorsByProjectId(
  projectId: number,
): Promise<TContributorPreview[]> {
  const res = await API.get(`/contributors?project=${projectId}`)
  return res.data
}

async function deleteContributor(contributorId: string): Promise<unknown> {
  const res = await API.delete(`/contributor/${contributorId}`)
  return res.data
}

async function createContributor(
  contributor: TContributorCreate,
): Promise<TUserContributor> {
  const res = await API.post("/contributor", contributor)
  return res.data
}

export { createContributor, deleteContributor, getContributorsByProjectId }
