import { IIconType } from "shared/types/global.type"

function Docuware({ width = 16, height = 16, color = "#3640B4" }: IIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1"
      viewBox="0 0 176 176"
    >
      <path
        d="M705 1724c-332-74-587-322-665-646C3 925 15 730 70 582 195 248 520 20 874 20c398 0 733 261 838 652 30 113 30 307 0 419-81 302-299 526-597 614-115 35-302 43-410 19zm275-314c231-67 391-280 393-525 1-156-52-284-163-395-98-98-233-160-347-160h-44l3 112 3 113 46 6c108 16 200 85 247 188 21 44 26 71 27 131 0 66-5 85-33 142-18 37-49 81-69 99-44 38-122 75-180 84l-43 7V990H600V770h220V550H600v220H380v220h220v220h220v228l48-5c26-2 76-13 112-23z"
        transform="matrix(.1 0 0 -.1 0 176)"
        fill={color}
      />
    </svg>
  )
}

export default Docuware
