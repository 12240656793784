import axios from "axios"
import classNames from "classnames"
import "components/AsyncSelectForm/AsyncSelectForm.scss"
import { FormErrorMessage } from "components/Form/FormErrorMessage"
import { Control, Controller, FieldValues, Path } from "react-hook-form"
import AsyncSelect from "react-select/async"
import { TGeoCityResponse } from "shared/types/geo.type"

export default function SelectCityForm<T extends FieldValues>({
  control,
  className,
  name,
  label,
  isDisabled = false,
  defaultValue,
  placeholder,
  handleChange,
  noOptionsMessage,
}: {
  control: Control<T>
  className?: string
  name: Path<T>
  label: string
  isDisabled?: boolean
  defaultValue?: { label: string; value: string }
  placeholder?: string
  handleChange?: (inputValue: string) => void
  noOptionsMessage: () => string
}) {
  const loadOptions = async (
    citySearch: string,
  ): Promise<{ label: string; value: string }[]> => {
    const postalCode = Number.isNaN(parseInt(citySearch)) ? null : citySearch
    const township = citySearch.replace(/[0-9]/g, "")

    const api = postalCode
      ? `https://geo.api.gouv.fr/communes?codePostal=${postalCode}&boost=population&fields=code,nom,codesPostaux`
      : `https://geo.api.gouv.fr/communes?nom=${township}&boost=population&fields=code,nom,codesPostaux`

    const res = await axios.get<TGeoCityResponse>(api)

    const formattedOptions = res.data.map((city) =>
      city.codesPostaux.map((cp) => {
        return {
          value: cp,
          label: `${city.nom} - ${cp}`,
        }
      }),
    )

    return formattedOptions.flat()
  }

  return (
    <div className="SelectFieldForm_Container">
      <label htmlFor={name}>
        {label}
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, ref }, fieldState: { error } }) => (
            <>
              <AsyncSelect
                isDisabled={isDisabled}
                defaultValue={defaultValue}
                ref={ref}
                styles={{
                  container: (styles) => ({
                    ...styles,
                    minWidth: "100%",
                    fontSize: "14px",
                  }),
                  control: (styles) => ({
                    ...styles,
                    height: "50px",
                    borderRadius: "8px",
                    border: "1px solid #e5e7eb",
                    backgroundColor: "#F9FAFB",
                  }),
                  indicatorSeparator: (styles) => ({
                    ...styles,
                    backgroundColor: "none",
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    opacity: 0.7,
                  }),
                }}
                name={name}
                isSearchable
                aria-label={label}
                className={classNames([
                  error ? "container_error" : "container",
                  className,
                ])}
                placeholder={placeholder}
                onInputChange={(value) =>
                  handleChange ? handleChange(value) : null
                }
                isMulti={false}
                noOptionsMessage={noOptionsMessage}
                onChange={(e) => {
                  onChange(e)
                }}
                loadOptions={loadOptions}
                components={{
                  DropdownIndicator: () => null,
                }}
              />

              {error && (
                <FormErrorMessage className="mt-1">
                  {error.message}
                </FormErrorMessage>
              )}
            </>
          )}
        />
      </label>
    </div>
  )
}
