import { FaArrowLeft, FaArrowRight } from "react-icons/fa6"
import useIsMobile from "utils/isMobile"
import "./Pagination.scss"

export interface Props {
  page: number
  totalPages: number
  handlePagination: (page: number) => void
  previousLabel?: string
  nextLabel?: string
}

export function Pagination({
  nextLabel = "Suivant",
  handlePagination,
  page,
  previousLabel = "Précédent",
  totalPages,
}: Props) {
  const isMobile = useIsMobile()
  const currentPage = page <= totalPages ? page : 1

  return (
    totalPages >= 1 &&
    currentPage >= 1 && (
      <div className="Pagination__Container">
        {currentPage !== 1 && (
          <button
            onClick={() => handlePagination(currentPage - 1)}
            type="button"
            className={`Pagination__item ${isMobile && "Pagination__item__Button"}`}
          >
            {isMobile ? <FaArrowLeft /> : previousLabel}
          </button>
        )}
        <button
          onClick={() => handlePagination(1)}
          type="button"
          disabled={currentPage === 1}
          className={[
            "Pagination__item",
            currentPage === 1 ? "Pagination__item_active" : "",
          ].join(" ")}
        >
          {1}
        </button>
        {currentPage > 3 && <div className="Pagination__item_Elipsis">...</div>}
        {currentPage === totalPages && totalPages > 3 && (
          <button
            onClick={() => handlePagination(currentPage - 2)}
            type="button"
            className="Pagination__item"
          >
            {currentPage - 2}
          </button>
        )}
        {currentPage > 2 && (
          <button
            onClick={() => handlePagination(currentPage - 1)}
            type="button"
            className="Pagination__item"
          >
            {currentPage - 1}
          </button>
        )}
        {currentPage !== 1 && currentPage !== totalPages && (
          <button
            onClick={() => handlePagination(currentPage)}
            type="button"
            className={["Pagination__item", "Pagination__item_active"].join(
              " ",
            )}
          >
            {currentPage}
          </button>
        )}
        {currentPage < totalPages - 1 && (
          <button
            onClick={() => handlePagination(currentPage + 1)}
            type="button"
            className="Pagination__item"
          >
            {currentPage + 1}
          </button>
        )}
        {currentPage === 1 && totalPages > 3 && (
          <button
            onClick={() => handlePagination(currentPage + 2)}
            type="button"
            className="Pagination__item"
          >
            {currentPage + 2}
          </button>
        )}
        {currentPage < totalPages - 2 && (
          <div className="Pagination__item_Elipsis">...</div>
        )}
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePagination(totalPages)}
          type="button"
          className={[
            "Pagination__item",
            currentPage === totalPages ? "Pagination__item_active" : "",
          ].join(" ")}
        >
          {totalPages}
        </button>
        {currentPage !== totalPages && (
          <button
            onClick={() => handlePagination(currentPage + 1)}
            type="button"
            className={`Pagination__item ${isMobile && "Pagination__item__Button"}`}
          >
            {isMobile ? <FaArrowRight /> : nextLabel}
          </button>
        )}
      </div>
    )
  )
}
