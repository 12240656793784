export type SortArg<T> = keyof T | `-${string & keyof T}`

function recurseObjProp<T extends object>(root: T, leafs: T, index?: number) {
  let localIndex = index ?? 0
  const upper = root
  const lower: T = upper[leafs[localIndex as keyof T] as keyof T] as T

  const firstElement: T = upper[0 as keyof T] as T
  let nestedArrayValue = null
  if (firstElement) {
    nestedArrayValue = firstElement[
      leafs[localIndex as keyof T] as keyof T
    ] as T
  }

  if (!lower && !nestedArrayValue) {
    return upper
  }

  localIndex += 1

  return recurseObjProp(lower ?? nestedArrayValue, leafs, localIndex)
}

/**
 * Returns a comparator for objects of type T that can be used by sort
 * functions, were T objects are compared by the specified T properties.
 *
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
function byPropertiesOf<T extends object>(
  sortBy: Array<SortArg<T>>,
  sortOrder: "asc" | "desc",
) {
  function compareByProperty(arg: SortArg<T>) {
    const order = sortOrder === "asc" ? 1 : -1
    // eslint-disable-next-line
    return function (a: T, b: T) {
      const propertyOfA = a[arg as keyof T] ?? recurseObjProp(a, sortBy as T)
      const propertyOfB = b[arg as keyof T] ?? recurseObjProp(b, sortBy as T)

      // Dans le cas des objets nested, on a d'un côté le "dernier étage valide"
      // (on recherche au plus profond de l'arbre la valeur associée, mais si on ne trouve rien on retour le dernier élément remonté)
      // et de l'autre la valeur associée. Du coup si on a qu'une valeur c'est qu'il faut mettre l'autre au fond du tableau
      if (typeof propertyOfA !== "object" && typeof propertyOfB === "object") {
        return -Infinity
      }

      if (typeof propertyOfB !== "object" && typeof propertyOfA === "object") {
        return Infinity
      }

      let result = 0

      if (typeof propertyOfA === "string" && typeof propertyOfB === "string") {
        result = (propertyOfA as string).localeCompare(
          propertyOfB as string,
          "fr",
          {
            ignorePunctuation: true,
            numeric: true,
          },
        )
      } else {
        if (propertyOfA < propertyOfB) result = -1
        if (propertyOfA > propertyOfB) result = 1
      }

      return result * order
    }
  }
  // eslint-disable-next-line
  return function (obj1: T, obj2: T) {
    let i = 0
    let result = 0
    const numberOfProperties = sortBy?.length
    while (result === 0 && i < numberOfProperties) {
      result = compareByProperty(sortBy[i])(obj1, obj2)
      i += 1
    }

    return result
  }
}

/**
 * Sorts an array of T by the specified properties of T.
 *
 * @param arr - the array to be sorted, all of the same type T
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
export function sort<T extends object>(
  arr: T[],
  sortBy: Array<SortArg<T>>,
  sortOrder: "asc" | "desc",
): T[] {
  return arr.sort(byPropertiesOf<T>(sortBy, sortOrder))
}
