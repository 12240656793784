import { useContext } from "react"
import ProgramSearchContext from "./ProgramsSearchContext"

export default function useProgramsSearch() {
  const searchProgramContext = useContext(ProgramSearchContext)
  if (
    !searchProgramContext?.state?.results ||
    !searchProgramContext?.state.filters
  )
    throw new Error(
      "The Search Program Context was accessed outside of the provider tree",
    )
  return searchProgramContext
}
