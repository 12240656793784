import { AxiosError } from "axios"
import { CustomAxiosError } from "shared/types/axios.type"

export interface IDisplayError {
  error: AxiosError | CustomAxiosError | null
  errorMessage: string
}
export default function DisplayError(props: IDisplayError) {
  const { error, errorMessage } = props
  return (
    <>
      {error && <p className="text-xs text-red-500 pt-2"> {errorMessage} </p>}
    </>
  )
}
