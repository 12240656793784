import {
  TCommittee,
  TCommitteeStep,
  TMutateCommittee,
} from "shared/types/committee.type"
import { API } from "./axios"

export default async function listCommitteesTypes(): Promise<TCommittee[]> {
  const res = await API.get("/committees/types")
  return res.data
}

async function listCommitteeByOperationId(
  operationId: number,
): Promise<TCommitteeStep[]> {
  const res = await API.get(`/committees?operation=${operationId}`)
  return res.data
}

async function updateCommittee(
  committeeId: number,
  data: Omit<TMutateCommittee, "typeId" | "operationId">,
): Promise<TCommitteeStep> {
  const res = await API.patch(`/committee/${committeeId}`, data)
  return res.data
}

async function createCommittee(
  data: TMutateCommittee,
): Promise<TCommitteeStep> {
  const res = await API.post(`/committee`, data)
  return res.data
}

export {
  createCommittee,
  listCommitteeByOperationId,
  listCommitteesTypes,
  updateCommittee,
}
