import { useGetSuspensiveConditionsByAgreementId } from "core/query-hooks/useSuspensiveConditions"
import { groupBy } from "lodash"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { TSuspensiveCondition } from "shared/types/suspensiveCondition.type"

const useSuspensiveConditions = () => {
  const { agreementId } = useParams()
  const { data: suspensiveConditionsData } =
    useGetSuspensiveConditionsByAgreementId(agreementId)

  const [suspensiveConditions, setSuspensiveConditions] = useState<
    TSuspensiveCondition[] | []
  >([])

  const [groupedByDateSC, setGroupedBySC] = useState<
    { [x: string]: TSuspensiveCondition[] } | {}
  >([])

  useEffect(() => {
    if (!suspensiveConditionsData) return undefined
    return setSuspensiveConditions(
      suspensiveConditionsData?.map((suspensiveCondition) => ({
        ...suspensiveCondition,
      })),
    )
  }, [suspensiveConditionsData])

  useEffect(() => {
    if (!suspensiveConditions) return undefined
    return setGroupedBySC(groupBy(suspensiveConditions, "date"))
  }, [suspensiveConditions])

  return {
    suspensiveConditions,
    groupedByDateSC,
    setSuspensiveConditions,
  }
}

export default useSuspensiveConditions
