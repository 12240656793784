import { useListAmendmentsByAgreementId } from "core/query-hooks/useAmendments"
import IHOCLoader from "hoc/HocLoader"
import { useParams } from "react-router-dom"
import { AmendmentsList } from "./AmendmentsList"

export default function AmendmentsBox() {
  const params = useParams()

  const {
    data: amendmentsData,
    isLoading,
    isError,
    error,
  } = useListAmendmentsByAgreementId(params.agreementId!)

  return (
    <div>
      <IHOCLoader error={error} isLoading={isLoading} isError={isError}>
        <AmendmentsList amendments={amendmentsData!} />
      </IHOCLoader>
    </div>
  )
}
