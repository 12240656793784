import { useMutation, useQuery } from "@tanstack/react-query"
import {
  addInfography,
  addInfographyDocuments,
  getInfography,
  getInfographyDocuments,
  getInfographyHistory,
  getInfographyStudyGedDocuments,
  listInfographiesStudies,
  updateInfography,
} from "core/api/infographies"
import { CustomAxiosError } from "shared/types/axios.type"
import { TDocumentCategory, TGedDocuments } from "shared/types/ged.type"
import {
  StudyInfographyDashboard,
  TInfography,
  TMutateInfographyDocuments,
} from "shared/types/infography.type"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"

function useAddInfography() {
  return useMutation<TInfography, CustomAxiosError, TInfography>({
    mutationFn: (infography) => addInfography(infography),
  })
}

function useUpdateInfography(id: number) {
  return useMutation<TInfography, CustomAxiosError, TInfography>({
    mutationFn: (infography) => updateInfography(id, infography),
  })
}

function useAddInfographyDocuments(id: number) {
  return useMutation<
    TMutateInfographyDocuments,
    CustomAxiosError,
    TMutateInfographyDocuments
  >({ mutationFn: (data) => addInfographyDocuments(id, data) })
}

function useGetInfographyDocuments(id: number) {
  return useQuery<unknown, CustomAxiosError, TDocumentCategory[]>({
    queryKey: ["getInfographyGedDocuments", id],
    queryFn: () => getInfographyDocuments(id),
  })
}

function useGetInfography(id: number) {
  return useQuery<TStudyRequest, CustomAxiosError>({
    queryKey: ["getInfography", id],
    queryFn: () => getInfography(id),
  })
}

function useGetInfographyHistory(id: number) {
  return useQuery<TStudyRequestHistory[], CustomAxiosError>({
    queryKey: ["getInfographyHistory", id],
    queryFn: () => getInfographyHistory(id),
  })
}

function useGetInfographyStudyDocuments(id: string) {
  return useQuery<unknown, CustomAxiosError, TGedDocuments>({
    queryKey: ["getInfographyStudyGedDocuments", id],
    queryFn: () => getInfographyStudyGedDocuments(id),
  })
}

function useListInfographiesStudies(showAllReturned: boolean) {
  return useQuery<unknown, CustomAxiosError, StudyInfographyDashboard>({
    queryKey: ["listInfographiesStudies", showAllReturned],
    queryFn: () => listInfographiesStudies(showAllReturned),
  })
}

export {
  useAddInfography,
  useAddInfographyDocuments,
  useGetInfography,
  useGetInfographyDocuments,
  useGetInfographyHistory,
  useGetInfographyStudyDocuments,
  useListInfographiesStudies,
  useUpdateInfography,
}
