import classNames from "classnames"
import { t } from "i18next"
import { BsBuildingFillAdd } from "react-icons/bs"
import { NavLink } from "react-router-dom"

interface ISidebarItemCreateProject {
  closeSidebar: () => void
}

export default function SidebarItemCreateProject({
  closeSidebar,
}: ISidebarItemCreateProject) {
  const handleClick = () => {
    closeSidebar()
  }

  return (
    <li>
      <NavLink
        to="/project/create"
        data-title={t("create-project")}
        className="sidebar-title flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 group"
        type="button"
        onClick={handleClick}
      >
        <BsBuildingFillAdd
          className={classNames(
            "text-gray-500 w-6 h-6 transition duration-75 group-hover:text-sky-800",
          )}
        />
      </NavLink>
    </li>
  )
}
