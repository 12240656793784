import { API } from "core/api/axios"

import { TDocumentTypeList } from "shared/types/ged.type"

async function listDocumentTypesByViewTag(
  viewTag: number,
): Promise<TDocumentTypeList> {
  const res = await API.get(`/document-types?viewTag=${viewTag}`)
  return res.data
}

export default listDocumentTypesByViewTag
