import { useQueries, useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import { TOperationsList } from "shared/types/operation.type"
import {
  getOperationFromSpo,
  isOperationFrozen,
  searchOperations,
} from "../api/operations"

function useSearchOperationsByQuery(query: string) {
  return useQuery<TOperationsList, CustomAxiosError>({
    queryKey: ["searchOperation", query],
    queryFn: () => searchOperations(query),
    ...{
      enabled: !!query,
    },
  })
}

function useGetOperationsFromSpo(registrationNumbers: string[]) {
  return useQueries({
    queries: registrationNumbers.map((registrationNumber) => {
      return {
        queryKey: ["operationFromSpo", registrationNumber],
        queryFn: () => getOperationFromSpo(registrationNumber),
        enabled: Boolean(registrationNumber),
      }
    }),
  })
}

function useIsOperationFrozen(registrationNumbers: string[]) {
  return useQueries({
    queries: registrationNumbers?.map((registrationNumber) => {
      return {
        queryKey: ["isOperationFrozen", registrationNumber],
        queryFn: () => isOperationFrozen(registrationNumber),
        enabled: Boolean(registrationNumber),
      }
    }),
  })
}

export {
  useGetOperationsFromSpo,
  useIsOperationFrozen,
  useSearchOperationsByQuery,
}
