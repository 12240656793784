import { Button } from "components"
import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useListCopromotersByOperation } from "core/query-hooks/useCopromoters"
import { useGetOperationCompanyById } from "core/query-hooks/useOperationCompanies"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { GROUP } from "shared/resources/groups.resources"
import { DisplayOperationCompany } from "./DisplayOperationCompany"
import { EditOperationCompany } from "./EditOperationCompany"
import { OperationCopromotion } from "./OperationCopromotion"

export interface IOperationCompanyProps {
  operationId: number
}

export function OperationCompany(props: IOperationCompanyProps) {
  const { operationId } = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const {
    data: company,
    isPending,
    error,
  } = useGetOperationCompanyById(operationId)
  const { data: copromoters } = useListCopromotersByOperation(operationId)
  const [hasCopromotion, setHasCopromotion] = useState<boolean>(
    Boolean(company && company.copromotionConvention) ||
      Boolean(copromoters && copromoters.length > 0),
  )

  useEffect(() => {
    if (
      (company && company.copromotionConvention) ||
      (copromoters && copromoters.length > 0)
    ) {
      setHasCopromotion(true)
    }
  }, [company, copromoters])

  if (isPending) {
    return <Spinner />
  }

  if (error) {
    return <div>{error.message}</div>
  }

  return (
    <>
      <Box>
        {isEdit ? (
          <PermissionsGuard requiredRoles={["update:operation.company"]}>
            <EditOperationCompany
              id={operationId}
              company={company}
              setIsEdit={setIsEdit}
            />
          </PermissionsGuard>
        ) : (
          <DisplayOperationCompany company={company} setIsEdit={setIsEdit} />
        )}

        {!hasCopromotion ? (
          <GroupsGuard
            requiredGroups={[
              GROUP.JURIDIQUE_JURISTE,
              GROUP.JURIDIQUE_ASSISTANT,
            ]}
          >
            <Button
              type="button"
              onClick={() => setHasCopromotion(true)}
              size="small"
              mode="primary"
            >
              {t("add-copromoter")}
            </Button>
          </GroupsGuard>
        ) : null}
      </Box>
      {hasCopromotion ? (
        <OperationCopromotion
          id={operationId}
          company={company}
          copromoters={copromoters}
        />
      ) : null}
    </>
  )
}
