import Spinner from "components/Spinner/Spinner"
import { useListTasksByOperationId } from "core/query-hooks/useTasks"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import OperationTasksList from "../OperationTasksList"

export default function TaskListContainer() {
  const { id } = useParams()
  const { t } = useTranslation()
  const { data: tasksData, isLoading, error } = useListTasksByOperationId(id!)

  if (isLoading) return <Spinner />

  if (error || !tasksData) return <p>{t("error-loading-tasks")}</p>

  return <OperationTasksList tasks={tasksData} />
}
