import { IIconType } from "shared/types/global.type"

function Layer({ width = 16, height = 16, color = "#00304D" }: IIconType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00015 12.0169C6.86388 12.0169 6.73018 11.9973 6.59905 11.9581C6.46792 11.9194 6.34892 11.8561 6.24205 11.7684L2.33325 8.70661C2.16805 8.58015 2.08545 8.41961 2.08545 8.22501C2.08545 8.03041 2.16805 7.86988 2.33325 7.74341C2.44012 7.65615 2.56658 7.61251 2.71265 7.61251C2.85872 7.61251 2.98518 7.65615 3.09205 7.74341L7.00015 10.7772L10.9082 7.74341C11.0151 7.65615 11.1416 7.61251 11.2876 7.61251C11.4337 7.61251 11.5602 7.65615 11.667 7.74341C11.8322 7.86988 11.9148 8.03041 11.9148 8.22501C11.9148 8.41961 11.8322 8.58015 11.667 8.70661L7.75825 11.7684C7.65138 11.8561 7.53238 11.9194 7.40125 11.9581C7.27012 11.9973 7.13642 12.0169 7.00015 12.0169V12.0169ZM7.00015 8.91031C6.86388 8.91031 6.73018 8.88838 6.59905 8.84451C6.46792 8.80111 6.34892 8.73555 6.24205 8.64781L2.37735 5.62941C2.29942 5.57108 2.23852 5.49805 2.19465 5.41031C2.15125 5.32305 2.12955 5.23555 2.12955 5.14781C2.12955 5.06055 2.15125 4.97305 2.19465 4.88531C2.23852 4.79805 2.29942 4.72525 2.37735 4.66691L6.24205 1.64782C6.34892 1.56055 6.46792 1.49498 6.59905 1.45111C6.73018 1.40725 6.86388 1.38531 7.00015 1.38531C7.13642 1.38531 7.27012 1.40725 7.40125 1.45111C7.53238 1.49498 7.65138 1.56055 7.75825 1.64782L11.6229 4.66691C11.7009 4.72525 11.7618 4.79805 11.8056 4.88531C11.849 4.97305 11.8707 5.06055 11.8707 5.14781C11.8707 5.23555 11.849 5.32305 11.8056 5.41031C11.7618 5.49805 11.7009 5.57108 11.6229 5.62941L7.75825 8.64781C7.65138 8.73555 7.53238 8.80111 7.40125 8.84451C7.27012 8.88838 7.13642 8.91031 7.00015 8.91031V8.91031ZM7.00015 7.68531L10.2523 5.14781L7.00015 2.61032L3.74795 5.14781L7.00015 7.68531Z"
        fill={color}
      />
    </svg>
  )
}

export default Layer
