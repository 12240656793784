import { API } from "core/api/axios"
import {
  StudyCostOfWorkDashboard,
  TCostOfWork,
  TMutateCostOfWorkDocuments,
} from "shared/types/costOfWork.type"
import { TDocumentCategory, TGedDocuments } from "shared/types/ged.type"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"

async function addCostOfWork(cow: TCostOfWork): Promise<TCostOfWork> {
  const res = await API.post("/cost-of-work", cow)
  return res.data
}

async function updateCostOfWork(
  id: number,
  cow: TCostOfWork,
): Promise<TCostOfWork> {
  const res = await API.patch(`/cost-of-work/${id}`, cow)
  return res.data
}

async function getCostOfWorkDocuments(
  id: number,
): Promise<TDocumentCategory[]> {
  const res = await API.get(`/document-categories?studyRequestType=${id}`)
  return res.data
}

async function getCostOfWorkGedDocuments(id: number): Promise<TGedDocuments> {
  const res = await API.get(`/costs-of-work/${id}/documents`)
  return res.data
}

async function addCostOfWorkDocuments(
  id: number,
  data: TMutateCostOfWorkDocuments,
) {
  const res = await API.post(`/costs-of-work/${id}/documents`, data)
  return res.data
}

async function getCostOfWork(id: number): Promise<TStudyRequest> {
  return typeof id === "undefined" || Number.isNaN(id)
    ? Promise.reject(new Error("Invalid cost of work id"))
    : API.get(`/cost-of-work/${id}`).then((res) => res.data)
}

async function getCostOfWorkHistory(
  id: number,
): Promise<TStudyRequestHistory[]> {
  const res = await API.get(`/costs-of-work/${id}/history`)
  return res.data
}

async function getCostOfWorkStudyGedDocuments(
  costOfWorkId?: string,
): Promise<TGedDocuments> {
  return typeof costOfWorkId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/costs-of-work/${costOfWorkId}/documents`).then(
        (res) => res.data,
      )
}

async function listCostOfWorkStudies(
  showAllReturned: boolean,
): Promise<StudyCostOfWorkDashboard> {
  const res = await API.get(
    `/costs-of-work/dashboard?showAllReturned=${showAllReturned}`,
  )
  return res.data
}

export {
  addCostOfWork,
  addCostOfWorkDocuments,
  getCostOfWork,
  getCostOfWorkDocuments,
  getCostOfWorkGedDocuments,
  getCostOfWorkHistory,
  getCostOfWorkStudyGedDocuments,
  listCostOfWorkStudies,
  updateCostOfWork,
}
