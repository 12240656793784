import {
  TTranchesCommerciales,
  TTranchesCommercialesMinified,
  TTranchesTravaux,
} from "shared/types/spo/tranche.type"
import SPO_API from "../axios"

async function getTranchesCommerciales(
  registrationId: string,
): Promise<TTranchesCommerciales> {
  const res = await SPO_API.get(
    `/operations/${registrationId}/tranches-commerciales`,
  )
  return res.data
}

async function getTranchesCommercialesByTrancheTravaux(
  registrationId: string,
  trancheTravauxId: string,
): Promise<TTranchesCommercialesMinified> {
  const res = await SPO_API.get(
    `/operations/${registrationId}/tranches-commerciales/${trancheTravauxId}`,
  )
  return res.data
}

async function getTranchesTravaux(
  registrationId: string,
): Promise<TTranchesTravaux> {
  const res = await SPO_API.get(
    `/operations/${registrationId}/tranches-travaux`,
  )
  return res.data
}

export {
  getTranchesCommerciales,
  getTranchesCommercialesByTrancheTravaux,
  getTranchesTravaux,
}
