import { Button } from "components"
import Spinner from "components/Spinner/Spinner"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import useListBusinessUnits from "core/query-hooks/useBusinessUnits"
import { useListFeasibilitiesStudies } from "core/query-hooks/useFeasibilities"
import { format } from "date-fns"
import {
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  STATUS_VALIDATED,
} from "features/studies/studyRequest.resources"
import { t } from "i18next"
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react"
import { CSVLink } from "react-csv"
import DataGrid, {
  Column,
  HeaderRendererProps,
  SortColumn,
} from "react-data-grid"
import "react-data-grid/lib/styles.css"
import { TBusinessUnit } from "shared/types/business-unit.type"
import { FeasibilityRow, TUserDashboard } from "shared/types/dashboard.type"
import { DateFormatter } from "../components/DateFormatter"
import {
  StudyStatusFormatter,
  getStudyRequestStatus,
} from "../components/StatusFormatter"
import { StudyNameFormatter } from "../components/StudyNameFormatter"
import UsersFormatter from "../components/UsersFormatter"
import "../dashboard.scss"
import { exportDashboardToCsv, sortByName } from "./dashboard-util"

interface Filter
  extends Pick<
    FeasibilityRow,
    | "status"
    | "bu"
    | "validatorAzureId"
    | "creatorAzureId"
    | "attributedOn"
    | "conceptorAzureId"
  > {
  enabled: boolean
}

const FilterContext = createContext<Filter | undefined>(undefined)

type Comparator = (a: FeasibilityRow, b: FeasibilityRow) => number

function getComparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    case "name":
    case "address":
    case "city":
    case "creatorJob":
    case "comment":
    case "priorisation":
    case "version":
      return (a, b) => {
        if (!a[sortColumn] && !b[sortColumn]) return 0
        if (!a[sortColumn] && b[sortColumn]) return 1
        if (a[sortColumn] && !b[sortColumn]) return -1

        return a[sortColumn]!.localeCompare(b[sortColumn]!, "fr", {
          ignorePunctuation: true,
          numeric: true,
        })
      }
    case "requestedOn":
    case "scheduledOn":
    case "returnedOn":
    case "postcode":
    case "creationNumberOfHours":
    case "gap":
    case "status":
      return (a, b) => {
        if (!a[sortColumn] && !b[sortColumn]) return 0
        if (!a[sortColumn] && b[sortColumn]) return 1
        if (a[sortColumn] && !b[sortColumn]) return -1

        return a[sortColumn]! > b[sortColumn]! ? -1 : 1
      }
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`)
  }
}

function FilterRenderer<R>({
  tabIndex,
  column,
  children,
}: HeaderRendererProps<R> & {
  children: (args: { tabIndex: number; filters: Filter }) => React.ReactElement
}) {
  const filters = useContext(FilterContext)!
  return (
    <>
      <div>{column.name}</div>
      <div>{children({ tabIndex, filters })}</div>
    </>
  )
}

function getColumns(
  setFilters: Dispatch<SetStateAction<Filter>>,
  studyRequestStatuses: number[],
  businessUnits: TBusinessUnit[],
  validators: TUserDashboard[],
  creators: TUserDashboard[],
  assignedUsers: TUserDashboard[],
): readonly Column<FeasibilityRow>[] {
  return [
    {
      key: "address",
      name: t("address")!,
      formatter(props) {
        return (
          <StudyNameFormatter
            isOperation={props.row.isRegisteredProject}
            studyId={props.row.id}
            studyName={props.row.address}
            studyType="feasibility"
            programId={props.row.programId}
          />
        )
      },
      frozen: true,
      sortable: true,
      width: "500px",
    },
    {
      key: "bu",
      name: t("bu")!,
      resizable: true,
      width: "max-content",
      headerCellClass: "filter-header-cell",
      headerRenderer: (p) => (
        <FilterRenderer<FeasibilityRow> {...p}>
          {({ filters, ...rest }) => (
            <select
              {...rest}
              className="select-header"
              value={filters.bu}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  bu: e.target.value,
                })
              }
            >
              <option value="All"> {t("select-all")}</option>
              {businessUnits.map((bu) => (
                <option key={bu.id} value={bu.label}>
                  {bu.label}
                </option>
              ))}
            </select>
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "city",
      name: t("city")!,
      resizable: true,
      sortable: true,
      width: "max-content",
    },
    {
      key: "status",
      name: t("progress")!,
      resizable: true,
      sortable: true,
      width: "max-content",
      headerCellClass: "filter-header-cell",
      cellClass() {
        return "overflow-visible"
      },
      formatter(props) {
        return <StudyStatusFormatter statusId={props.row.status} />
      },
      headerRenderer: (p) => (
        <FilterRenderer<FeasibilityRow> {...p}>
          {({ filters, ...rest }) => (
            <select
              {...rest}
              className="select-header"
              value={filters.status}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  status: Number(e.target.value),
                })
              }
            >
              <option value="0"> {t("select-all")}</option>
              {studyRequestStatuses.map((status) => (
                <option key={status} value={status}>
                  {getStudyRequestStatus(status)}
                </option>
              ))}
            </select>
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "conceptors",
      name: t("conceptors")!,
      formatter(props) {
        return <UsersFormatter users={props.row.conceptors} />
      },
      resizable: true,
      width: "max-content",
      headerCellClass: "filter-header-cell",
      headerRenderer: (p) => (
        <FilterRenderer<FeasibilityRow> {...p}>
          {({ filters, ...rest }) => (
            <select
              {...rest}
              className="select-header"
              value={filters.conceptorAzureId}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  conceptorAzureId: e.target.value,
                })
              }
            >
              <option value="All"> {t("select-all")}</option>
              {assignedUsers.map((user) => (
                <option key={user.azureId} value={user.azureId}>
                  {user.firstName} {user.lastName}
                </option>
              ))}
            </select>
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "postcode",
      name: t("postcode")!,
      resizable: true,
      sortable: true,
      width: "max-content",
    },
    {
      key: "validator",
      name: t("validator")!,
      resizable: true,
      width: "max-content",
      headerCellClass: "filter-header-cell",
      formatter(props) {
        return (
          <UsersFormatter
            users={props.row.validator ? [props.row.validator] : []}
          />
        )
      },
      headerRenderer: (p) => (
        <FilterRenderer<FeasibilityRow> {...p}>
          {({ filters, ...rest }) => (
            <select
              {...rest}
              className="select-header"
              value={filters.validatorAzureId}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  validatorAzureId: e.target.value,
                })
              }
            >
              <option value="All"> {t("select-all")}</option>
              {validators.map((validator) => (
                <option key={validator.azureId} value={validator.azureId}>
                  {validator.firstName} {validator.lastName}
                </option>
              ))}
            </select>
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "creator",
      name: t("creator")!,
      resizable: true,
      width: "max-content",
      formatter(props) {
        return <UsersFormatter users={[props.row.creator]} />
      },
      headerCellClass: "filter-header-cell",
      headerRenderer: (p) => (
        <FilterRenderer<FeasibilityRow> {...p}>
          {({ filters, ...rest }) => (
            <select
              {...rest}
              className="select-header"
              value={filters.creatorAzureId}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  creatorAzureId: e.target.value,
                })
              }
            >
              <option value="All"> {t("select-all")}</option>
              {creators.map((creator) => (
                <option key={creator.azureId} value={creator.azureId}>
                  {creator.firstName} {creator.lastName}
                </option>
              ))}
            </select>
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "creatorJob",
      name: t("creator-job")!,
      resizable: true,
      sortable: true,
      width: "max-content",
    },
    {
      key: "requestedOn",
      name: t("requested-on")!,
      formatter(props) {
        return <DateFormatter date={props.row.requestedOn} />
      },
      width: "max-content",
      resizable: true,
      sortable: true,
    },
    {
      key: "version",
      name: t("version-feasibility")!,
      resizable: true,
      sortable: true,
      width: "max-content",
    },
    {
      key: "attributedOn",
      name: t("attributed-on")!,
      width: "max-content",
      headerCellClass: "filter-header-cell",
      formatter(props) {
        return <DateFormatter date={props.row.attributedOn} />
      },
      resizable: true,
      headerRenderer: (p) => (
        <FilterRenderer<FeasibilityRow> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className="Text__Field"
              value={filters.attributedOn ?? undefined}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  attributedOn: e.target.value,
                })
              }
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "scheduledOn",
      name: t("scheduled-on")!,
      width: "max-content",
      formatter(props) {
        return <DateFormatter date={props.row.scheduledOn} />
      },
      resizable: true,
      sortable: true,
    },
    {
      key: "returnedOn",
      name: t("returned-on")!,
      width: "max-content",
      formatter(props) {
        return <DateFormatter date={props.row.returnedOn} />
      },
      resizable: true,
      sortable: true,
    },
    {
      key: "gap",
      name: "Ecart",
      resizable: true,
      sortable: true,
      width: "max-content",
    },
    {
      key: "creationNumberOfHours",
      name: t("passed-time")!,
      resizable: true,
      sortable: true,
      width: "max-content",
    },
    {
      key: "comment",
      name: t("comment")!,
      resizable: true,
      sortable: true,
      width: "max-content",
    },
    {
      key: "priorisation",
      name: t("priorisation")!,
      resizable: true,
      sortable: true,
      width: "max-content",
    },
  ]
}

export default function FeasibilityDashboardPage() {
  const [showAllReturned, setShowAllReturned] = useState<boolean>(false)

  const {
    data: studies,
    isLoading,
    error,
  } = useListFeasibilitiesStudies(showAllReturned)
  let validators = studies?.items
    .filter((sr) => sr.feasibility.validator)
    .map((sr) => sr.feasibility.validator)
    .sort(sortByName)
  validators = validators?.filter(
    (a, i) => validators?.findIndex((s) => a.azureId === s.azureId) === i,
  )
  let assignedUsers = studies?.items
    .filter((sr) => sr.feasibility.assignedUsers)
    .map((sr) => sr.feasibility.assignedUsers)
    .flat()
    .sort(sortByName)
  assignedUsers = assignedUsers?.filter(
    (a, i) => assignedUsers?.findIndex((s) => a.azureId === s.azureId) === i,
  )

  let creators = studies?.items
    .filter((sr) => sr.feasibility?.creator)
    .map((sr) => sr.feasibility?.creator)
    .sort(sortByName)
  creators = creators?.filter(
    (a, i) => creators?.findIndex((s) => a.azureId === s.azureId) === i,
  )

  const { data: businessUnitsData } = useListBusinessUnits()
  const studyRequestStatuses = [
    STATUS_VALIDATED,
    STATUS_PRIORIZED_AND_ASSIGNED,
    STATUS_RETURNED,
    STATUS_PRIORIZATION_STANDBY,
    STATUS_RESTITUTION_STANDBY,
  ]
  const [filters, setFilters] = useState(
    (): Filter => ({
      bu: "All",
      status: 0,
      validatorAzureId: "All",
      creatorAzureId: "All",
      conceptorAzureId: "All",
      attributedOn: "",
      enabled: true,
    }),
  )
  const columns = useMemo(
    () =>
      getColumns(
        setFilters,
        studyRequestStatuses ?? [],
        businessUnitsData ?? [],
        validators ?? [],
        creators ?? [],
        assignedUsers ?? [],
      ),
    [
      studyRequestStatuses,
      businessUnitsData,
      validators,
      creators,
      assignedUsers,
    ],
  )

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([])
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<string> => new Set(),
  )

  const rowKeyGetter = (row: FeasibilityRow) => {
    return row.id.toString()
  }

  const rows = useMemo(
    () =>
      studies?.items
        .map((study) => {
          return {
            isRegisteredProject: study.project.isRegistered,
            programId: study.project.id,
            id: study.feasibility.id,
            postcode: study.project.postcode,
            city: study.project.city,
            name: `${study.project.name}, ${study.project.city}`,
            version: study.feasibility.version
              ? study.feasibility.version
              : "-",
            address: `${study.project.address}, ${study.project.city}`,
            bu: study.project.businessUnit.label,
            validator: study.feasibility.validator,
            validatorAzureId: "",
            creatorAzureId: "",
            creator: study.feasibility.creator,
            creatorJob: study.feasibility.creator.job
              ? study.feasibility.creator.job
              : "-",
            conceptors: study.feasibility.assignedUsers,
            conceptorAzureId: "",
            requestedOn: study.feasibility.requestedOn,
            status: study.feasibility.statusId,
            scheduledOn: study.feasibility.scheduledOn,
            returnedOn: study.feasibility.returnedOn,
            gap: study.feasibility.gap,
            creationNumberOfHours: study.feasibility.creationNumberOfHours,
            comment: study.feasibility.comment,
            priorisation: study.feasibility.priorisation,
            attributedOn: study.feasibility.attributedOn ?? "",
          }
        })
        .sort((a, b) => {
          return (
            new Date(b.requestedOn).valueOf() -
            new Date(a.requestedOn).valueOf()
          )
        }),
    [studies],
  )

  const compareDate = (dateFilter: string | null, row: string): boolean => {
    if (dateFilter) {
      return new Date(row).toLocaleDateString().includes(dateFilter)
    }
    return true
  }

  const sortedRows = useMemo((): readonly FeasibilityRow[] | undefined => {
    if (rows) {
      return [...rows]
        .sort((a, b) => {
          /* eslint-disable no-restricted-syntax */
          for (const sort of sortColumns) {
            const comparator = getComparator(sort.columnKey)
            const compResult = comparator(a, b)

            if (compResult !== 0) {
              return sort.direction === "ASC" ? compResult : -compResult
            }
          }
          return 0
        })
        .filter((r) => {
          return (
            compareDate(filters.attributedOn, r.attributedOn) &&
            (filters.status !== 0 ? r.status === filters.status : true) &&
            (filters.bu !== "All" ? r.bu === filters.bu : true) &&
            (filters.validatorAzureId !== "All"
              ? r.validator?.azureId === filters.validatorAzureId
              : true) &&
            (filters.creatorAzureId !== "All"
              ? r.creator?.azureId === filters.creatorAzureId
              : true) &&
            (filters.conceptorAzureId !== "All"
              ? r.conceptors.some(
                  (conceptor) => conceptor.azureId === filters.conceptorAzureId,
                )
              : true)
          )
        })
    }
    return undefined
  }, [rows, sortColumns, filters])

  if (isLoading) return <Spinner />

  if (error) return <p>{t("error-dashboard-feasibility")}</p>

  return (
    <div>
      <div className="flex items-center justify-between mb-1">
        <h2 className="col-span-6 font-semibold md:mb-0 p-3 Color__Primary">
          {t("feasibility-dashboard")}
        </h2>
        <div className="flex items-center">
          <label
            className="mr-2 ml-1 text-sm Color__Primary"
            htmlFor="filter-restitution-date-toggle-btn"
          >
            {t("studyToogle")}
            <ToggleSwitch
              id="filter-restitution-date-toggle-btn"
              name="filter-restitution-date"
              disabled={false}
              checked={showAllReturned!}
              onChange={() => setShowAllReturned(!showAllReturned)}
              size="small-switch"
            />
          </label>
          <Button size="small" mode="primary" classNames="mr-2">
            <CSVLink
              data={exportDashboardToCsv(columns, sortedRows)}
              separator=";"
              filename={`faisabilites-${format(new Date(), "dd/MM/yyyy")}.csv`}
              className="primary"
              target="_blank"
            >
              {t("download")}
            </CSVLink>
          </Button>
        </div>
      </div>
      <FilterContext.Provider value={filters}>
        <DataGrid
          className="rdg-light"
          rowKeyGetter={rowKeyGetter}
          columns={columns}
          rows={sortedRows ?? []}
          selectedRows={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          headerRowHeight={75}
        />
      </FilterContext.Provider>
    </div>
  )
}
