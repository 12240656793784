import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { useUpdateOperation } from "core/query-hooks/useOperations"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { FormEvent, useState } from "react"
import { registerLocale } from "react-datepicker"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

registerLocale("fr", fr)

export interface IEditBankApprovalDateModalProps {
  isBankApprovalModalShowed: boolean
  operationBankApproval: Date | null | undefined
  operationIsBankApprovalDefinitive: null | boolean
  toggleBankApprovalModal: () => void
}

export function EditBankApprovalDateModal(
  props: IEditBankApprovalDateModalProps,
) {
  const {
    isBankApprovalModalShowed,
    toggleBankApprovalModal,
    operationBankApproval,
    operationIsBankApprovalDefinitive,
  } = props
  const { id } = useParams()

  const mutateOperation = useUpdateOperation(+id!)

  const [bankApproval, setBankApproval] = useState<Date | null>(
    operationBankApproval ? new Date(operationBankApproval) : null,
  )
  const [isBankApprovalDefinitive, setIsBankApprovalDefinitive] = useState<
    boolean | null
  >(operationIsBankApprovalDefinitive || false)

  const queryClient = useQueryClient()

  async function handleSubmitPurchaseLandDateForm(
    e: FormEvent<HTMLFormElement>,
  ) {
    e.stopPropagation()
    e.preventDefault()

    mutateOperation.mutate(
      {
        bankApproval,
        isBankApprovalDefinitive,
      },
      {
        onSuccess() {
          toast.success(t("success-mutate-deposit-date"))
          queryClient.invalidateQueries({ queryKey: ["operation", +id!] })
          toggleBankApprovalModal()
        },
        onError() {
          toast.error(t("error-mutate-deposit-date"))
        },
      },
    )
  }

  return (
    <Modal
      isShowing={isBankApprovalModalShowed}
      closeModal={toggleBankApprovalModal}
      title={`${t("edit-bank-approval-date")}`}
    >
      <form
        className="Text__Field_Container"
        onSubmit={(e) => handleSubmitPurchaseLandDateForm(e)}
      >
        <div className="mt-2 mb-2 flex flex-col">
          <label htmlFor="bank-approval-date">
            {t("label-bank-approval-date")}
          </label>
          <CustomDatePicker
            id="bank-approval-date"
            locale={fr}
            className="Text__Field"
            placeholderText={`${t("date")}`}
            selected={bankApproval}
            onChange={(date) => setBankApproval(date!)}
          />
        </div>

        <div>
          <label htmlFor="bank-approval-toggle-btn">
            {`${t("date-is-definitive")}`}
            <div className="flex items-center justify-start sm:mt-2">
              <ToggleSwitch
                id="bank-approval-toggle-btn"
                name="toggle-bank-approval-date"
                disabled={false}
                checked={isBankApprovalDefinitive!}
                onChange={(status) => setIsBankApprovalDefinitive(status)}
              />
            </div>
          </label>
        </div>
        <div className="flex items-center justify-between mt-6">
          <Button
            onClick={() => toggleBankApprovalModal()}
            size="small"
            mode="secondary"
          >
            {`${t("cancel")}`}
          </Button>

          <Button
            isLoading={mutateOperation.isPending}
            type="submit"
            size="small"
            mode="primary"
          >
            {`${t("update")}`}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
