import Spinner from "components/Spinner/Spinner"
import useListExcelLinks from "core/query-hooks/useExcelLinks"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export default function DashboardExcel() {
  const { code } = useParams()

  if (!code) {
    return <div>{t("data-error")}</div>
  }

  const { data: user } = useGetCurrentUser()
  const [businessUnitId, setBusinessUnitId] = useState<string | undefined>(
    user?.businessUnit ? String(user.businessUnit) : undefined,
  )
  const { data: excelLinks, isLoading } = useListExcelLinks(
    code,
    businessUnitId,
  )

  useEffect(() => {
    setBusinessUnitId(
      user?.businessUnit ? String(user.businessUnit) : undefined,
    )
  }, [user])

  useEffect(() => {
    const adjustIframeHeight = () => {
      const iframe = document.getElementById("excel")
      if (iframe) {
        const windowHeight = window.innerHeight + 30
        iframe.style.height = `${windowHeight}px`
      }
    }

    window.addEventListener("resize", adjustIframeHeight)

    adjustIframeHeight()

    return () => {
      window.removeEventListener("resize", adjustIframeHeight)
    }
  }, [excelLinks])

  const bloqueScroll = () => {
    document.body.style.overflow = "hidden"
  }

  const activeScroll = () => {
    document.body.style.overflow = "auto"
  }

  useEffect(() => {
    bloqueScroll()

    return () => {
      activeScroll()
    }
  }, [])

  if (isLoading) {
    return (
      <div className="flex items-center justify-center align middle pt-8">
        <Spinner />
      </div>
    )
  }

  if (excelLinks && excelLinks.length > 0) {
    return (
      <iframe
        title={code}
        id="excel"
        width="100%"
        src={
          excelLinks[0].link &&
          excelLinks[0].link.startsWith("https://pierreval0.sharepoint.com")
            ? excelLinks[0].link
            : undefined
        }
      />
    )
  }

  if (excelLinks && excelLinks.length === 0) {
    return (
      <div className="flex items-center justify-center align middle pt-8">
        {t("data-error")}
      </div>
    )
  }
}
