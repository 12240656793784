import { Button } from "components"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import QueryBoundaries from "core/QueryBoundaries"
import {
  STATUS_CREATED,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  STATUS_VALIDATED,
} from "features/studies/studyRequest.resources"
import { t } from "i18next"
import { difference, isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { GROUP } from "shared/resources/groups.resources"
import BusinessUnitsFilter from "../pdp/filters/BusinessUnitsFilter"
import DepartmentsFilter from "../pdp/filters/DepartmentsFilter"
import SelectedBusinessUnitsFilters from "../pdp/filters/SelectedBusinessUnitsFilters"
import SelectedDepartmentsFilters from "../pdp/filters/SelectedDepartmentsFilters"
import SelectedStudyRequestsStatusFilters from "./filters/SelectedStudyRequestsStatusFilters"
import SelectedStudyRequestsTypeFilters from "./filters/SelectedStudyRequestsTypeFilters"
import SelectedStudyRequestsUsersFilters from "./filters/SelectedStudyRequestsUsersFilters"
import StudyRequestsStatusFilter from "./filters/StudyRequestsStatusFilter"
import StudyRequestsTypesFilter from "./filters/StudyRequestsTypesFilter"
import StudyRequestsUsersFilter from "./filters/StudyRequestsUsersFilter"
import OverviewStudyRequests from "./OverviewStudyRequests"

export default function OverviewStudyRequestsPage() {
  const { data: user } = useGetCurrentUser()
  const defaultStatuses = [
    STATUS_CREATED,
    STATUS_VALIDATED,
    STATUS_PRIORIZED_AND_ASSIGNED,
    STATUS_RETURNED,
    STATUS_PRIORIZATION_STANDBY,
    STATUS_RESTITUTION_STANDBY,
  ]
  const [businessUnits, setBusinessUnits] = useState<number[]>([])
  const [departments, setDepartments] = useState<string[]>([])
  const [status, setStatus] = useState<number[]>(defaultStatuses) // avancement
  const [studyRequestType, setStudyRequestType] = useState<number[]>([]) // faisa, infographie, cow
  const [studyRequestUser, setStudyRequestUser] = useState<string[]>([]) // Regroupe demandeur, valideur et concepteur
  const [showAllReturned, setShowAllReturned] = useState<boolean>(false)

  const drGroups = [
    GROUP.DIRECTEUR_REGION_IDF,
    GROUP.DIRECTEUR_REGION_GO,
    GROUP.DIRECTEUR_REGION_SE,
    GROUP.DIRECTEUR_REGION_RA,
    GROUP.DIRECTEUR_REGION_SO,
    GROUP.DIRECTEUR_REGION_NE,
    GROUP.DIRECTEUR_REGION_VDM,
    GROUP.DIRECTEUR_TERTIAIRE,
    GROUP.DIRECTEUR_TECHNIQUE,
  ]

  const userIsDR = drGroups.some((group) => user?.groups.includes(group))

  useEffect(() => {
    if (drGroups.some((group) => user?.groups.includes(group))) {
      setBusinessUnits(user?.businessUnit ? [user.businessUnit] : [])
    }
  }, [user])

  const handelSwitch = () => {
    setShowAllReturned(!showAllReturned)
  }

  const handleClearFilters = () => {
    setBusinessUnits([])
    setDepartments([])
    setStatus(defaultStatuses)
    setStudyRequestType([])
    setStudyRequestUser([])
  }

  const arraysHaveSameElements = (arr1: number[], arr2: number[]) => {
    return isEmpty(difference(arr1, arr2)) && isEmpty(difference(arr2, arr1))
  }

  const hasSelectedFilters =
    businessUnits.length > 0 ||
    departments.length > 0 ||
    (status.length > 0 && !arraysHaveSameElements(status, defaultStatuses)) ||
    studyRequestType.length > 0 ||
    studyRequestUser.length > 0

  return (
    <div className="p-4">
      <h2 className="col-span-6 font-semibold md:mb-0 Color__Primary pb-4">
        {t("study-request-from-my-area")}
      </h2>
      <div className="flex items-center justify-between flex-wrap">
        <div className="flex flex-wrap mb-2 md:m-0 items-center">
          <QueryBoundaries loadingComponent={<Spinner />}>
            {!userIsDR && (
              <div className="mr-2 mb-3 h-full">
                <BusinessUnitsFilter
                  selectedBusinessUnits={businessUnits}
                  onBusinessUnitsChange={setBusinessUnits}
                />
              </div>
            )}
            <div className="mr-2 mb-3 h-full">
              <DepartmentsFilter
                selectedDepartments={departments}
                onDepartmentsChange={setDepartments}
                isOperation
              />
            </div>
            <div className="mr-2 mb-3 h-full">
              <StudyRequestsStatusFilter
                selectedStatus={status}
                onStatusChange={setStatus}
              />
            </div>
            <div className="mr-2 mb-3 h-full">
              <StudyRequestsTypesFilter
                selectedStudyRequestTypes={studyRequestType}
                onStudyRequestTypesChange={setStudyRequestType}
              />
            </div>
            <div className="mr-2 mb-3 h-full">
              <StudyRequestsUsersFilter
                selectedStudyRequestUsers={studyRequestUser}
                onStudyRequestUsersChange={setStudyRequestUser}
              />
            </div>
          </QueryBoundaries>
          {hasSelectedFilters && (
            <div>
              <Button
                type="submit"
                onClick={handleClearFilters}
                size="medium"
                mode="primary"
                classNames="w-full md:w-auto mb-3"
              >
                {`${t("reset-filters")}`}
              </Button>
            </div>
          )}
        </div>
        <div className="flex items-center justify-end">
          <label
            className="mr-2 ml-1 text-sm Color__Primary"
            htmlFor="show-restitutions-toggle-btn"
          >
            {`${t("show-all-restitutions")} `}

            <ToggleSwitch
              id="show-restitutions-toggle-btn"
              name="toggle-show-restitutions"
              disabled={false}
              checked={showAllReturned}
              onChange={handelSwitch}
            />
          </label>
        </div>
      </div>

      <QueryBoundaries loadingComponent={<Spinner />}>
        <div className="flex items-center flex-wrap">
          {!userIsDR && (
            <div>
              <SelectedBusinessUnitsFilters
                selectedBusinessUnits={businessUnits}
                onBusinessUnitsChange={setBusinessUnits}
              />
            </div>
          )}
          <div>
            <SelectedDepartmentsFilters
              selectedDepartments={departments}
              onDepartmentsChange={setDepartments}
              isOperation
            />
          </div>
          <div>
            <SelectedStudyRequestsStatusFilters
              selectedStatus={status}
              onStatusChange={setStatus}
            />
          </div>
          <div>
            <SelectedStudyRequestsTypeFilters
              selectedStudyRequestTypes={studyRequestType}
              onStudyRequestsTypesChange={setStudyRequestType}
            />
          </div>
          <div>
            <SelectedStudyRequestsUsersFilters
              selectedStudyRequestUsers={studyRequestUser}
              onStudyRequestUsersChange={setStudyRequestUser}
            />
          </div>
        </div>
      </QueryBoundaries>

      <div className="mt-2">
        <Tabs>
          <TabContent title="Types" key="types">
            <div className="mt-2">
              <OverviewStudyRequests
                businessUnits={businessUnits}
                departments={departments}
                types={studyRequestType}
                users={studyRequestUser}
                tab="types"
                status={status}
                showAllReturned={showAllReturned}
              />
            </div>
          </TabContent>
          <TabContent title="BU" key="bu">
            <div className="mt-2">
              <OverviewStudyRequests
                businessUnits={businessUnits}
                departments={departments}
                types={studyRequestType}
                users={studyRequestUser}
                tab="bu"
                status={status}
                showAllReturned={showAllReturned}
              />
            </div>
          </TabContent>
        </Tabs>
      </div>
    </div>
  )
}
