import { IIconType } from "shared/types/global.type"

function JenjiLogo({ width = 16, height = 16 }: IIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={width}
      height={height}
      viewBox="-153 -46 599.907 293.423"
    >
      <path
        d="M225.107-46h213.4c3.1 0 5.4 2.5 5.4 5.4v28c0 3.1-2.5 5.4-5.4 5.4h-213.4c-3.1 0-5.4-2.5-5.4-5.4v-28c0-2.9 2.5-5.4 5.4-5.4z"
        style={{
          fill: "#ff7247",
        }}
      />
      <path
        d="M-132.893 245.5c1.9 2.3 5.2 2.5 7.5.8 23.6-18.7 37.3-34.2 37.3-51.7v-196c0-3.1-2.5-5.4-5.4-5.4h-32.8c-3.1 0-5.4 2.5-5.4 5.4v189.2c0 9.5-1.9 12.9-19.3 27.2-2.3 1.9-2.7 5.4-.8 7.7zm98.3-57.5h99.1c3.1 0 5.4-2.5 5.4-5.4V159c0-3.1-2.5-5.4-5.4-5.4h-59.7c-1 0-1.7-.8-1.7-1.7v-22.2c0-1 .8-1.7 1.7-1.7h54.3c3.1 0 5.4-2.5 5.4-5.4V99c0-3.1-2.5-5.4-5.4-5.4h-54.3c-1 0-1.7-.8-1.7-1.7V72c0-1 .8-1.7 1.7-1.7h59.7c3.1 0 5.4-2.5 5.4-5.4V41.3c0-3.1-2.5-5.4-5.4-5.4h-99.1c-3.1 0-5.4 2.5-5.4 5.4v141.1c-.2 3.1 2.3 5.6 5.4 5.6zm245.6 0h42.7c3.1 0 5.4-2.5 5.4-5.4V41.2c0-3.1-2.5-5.4-5.4-5.4h-29.9c-3.1 0-5.4 2.5-5.4 5.4v81.1c0 1.7-2.3 2.3-3.1.8l-46-84.6c-1-1.7-2.9-2.9-4.8-2.9h-42.7c-3.1 0-5.4 2.5-5.4 5.4v141.1c0 3.1 2.5 5.4 5.4 5.4h29.9c3.1 0 5.4-2.5 5.4-5.4v-80.4c0-1.7 2.3-2.3 3.1-.8l46 84.2c1 1.7 2.7 2.9 4.8 2.9zm198.3 0h32.2c3.1 0 5.4-2.5 5.4-5.4V41.2c0-3.1-2.5-5.4-5.4-5.4h-32.2c-3.1 0-5.4 2.5-5.4 5.4v141.1c0 3.2 2.5 5.7 5.4 5.7zm-100.8 49.2c1.9 2.3 5.2 2.5 7.5.8 23.6-18.7 37.3-34.2 37.3-51.7V41.4c0-3.1-2.5-5.4-5.4-5.4h-33c-3.1 0-5.4 2.5-5.4 5.4v138.3c0 9.5-1.9 12.9-19.3 27.2-2.3 1.9-2.7 5.4-.8 7.7z"
        style={{
          fill: "#383534",
        }}
      />
    </svg>
  )
}
export default JenjiLogo
