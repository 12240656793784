import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import { useGetDocumentsByStudyRequestTypeAndProjectId } from "core/ged/query-hooks/useGed"
import { useGetCostOfWorkDocuments } from "core/query-hooks/useCostOfWork"
import { useUpdateStatus } from "core/query-hooks/useStudyRequests"
import GedCategories from "features/ged/GedCategories"
import { CostOfWorkContext } from "features/studies/costOfWork/CostOfWorkProvider"
import {
  STATUS_CREATED,
  TYPE_COST_OF_WORK,
  sortByRequiredFields,
} from "features/studies/studyRequest.resources"
import { t } from "i18next"
import { useContext } from "react"
import { useForm } from "react-hook-form"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TMutateCostOfWorkDocuments } from "shared/types/costOfWork.type"
import * as yup from "yup"
import DeleteStudyRequestDraft from "../DeleteStudyRequestDraft"

const schema = yup.object({
  list: yup.array().of(
    yup.object().shape({
      documentId: yup.number(),
      documentCategoryId: yup.number(),
    }),
  ),
})

export type TCoWCreateDocumentsStepForm = {
  list: {
    documentIds?: number[]
    documentCategoryId?: number
  }[]
}

export interface IStepDocumentsCoW {
  routeName: string
}

export function StepDocumentsCoW(props: IStepDocumentsCoW) {
  const navigate = useNavigate()
  const { routeName } = props
  const queryClient = useQueryClient()
  const { setStudyRequestInfo, setCurrentId } = useContext(CostOfWorkContext)
  const { id, studyRequestId } = useParams()
  if (studyRequestId === "undefined")
    return (
      <Navigate to={`/${routeName}/${id}/studies/costOfWork/new`} replace />
    )

  const redirectEditCoW = () =>
    navigate(`/${routeName}/${id}/studies/costOfWork/edit/${studyRequestId}`)

  const { handleSubmit, control } = useForm<TCoWCreateDocumentsStepForm>({
    resolver: yupResolver(
      schema as yup.ObjectSchema<TCoWCreateDocumentsStepForm>,
    ),
  })

  const { data: tree } = useGetCostOfWorkDocuments(TYPE_COST_OF_WORK)
  const { data: files, isLoading: isLoadingFiles } =
    useGetDocumentsByStudyRequestTypeAndProjectId(+id!, TYPE_COST_OF_WORK)

  const updateStudyRequest = useUpdateStatus(+studyRequestId!)

  const onSubmit = (data: TCoWCreateDocumentsStepForm) => {
    setStudyRequestInfo((previsousValue) => {
      return {
        ...previsousValue,
      }
    })

    const documents: TMutateCostOfWorkDocuments = {
      categories: [],
    }

    data?.list?.filter((doc) => {
      if (
        doc.documentCategoryId &&
        doc.documentIds &&
        doc.documentIds.length > 0
      ) {
        documents.categories.push({
          categoryId: doc.documentCategoryId,
          gedDocuments: doc.documentIds!,
        })
      }
      return false
    })

    updateStudyRequest.mutateAsync(
      { statusId: STATUS_CREATED },
      {
        onSuccess() {
          setCurrentId(undefined)
          queryClient.invalidateQueries({
            queryKey: ["listStudyRequests", +id!],
          })
          toast.success(t("studyRequest.successMsg"))
          navigate(`/${routeName}/${id}/studies`)
        },
        onError(error) {
          toast.error(error.response?.data.message)
        },
      },
    )
  }

  const { isShowing: isDeleteModalShowed, toggle: toggleDeleteModal } =
    useModal()

  return (
    <div className="px-4 sm:px-16">
      <form>
        <div className="divide-y divide-slate-100">
          <div className="pt-6">
            <div>
              <p className="Color__Primary font-semibold text-lg">
                {`${t("studyRequest.documentsLabel")}`}
              </p>
              <p className="Color__Primary mt-2 text-sm">
                {`${t("studyRequest.documentsHelperMsg")}`}
              </p>
            </div>
            <div className="grid grid-cols- md:grid-cols-2 gap-x-12 gap-y-4 pt-6">
              {sortByRequiredFields(tree)?.map((elm) => (
                <Box
                  key={elm.frenchLabel}
                  classNames="flex flex-col mb-8 md:p-6"
                >
                  <div>
                    {isLoadingFiles ? <Spinner /> : null}

                    {elm.documentTypes.length > 0 ? (
                      files?.documentCategories
                        .filter((category) => elm.id === category.category.id)
                        .map((category) => (
                          <GedCategories
                            category={category}
                            element={elm}
                            isLoading={isLoadingFiles}
                            control={control}
                            isProjectStudyRequestRelated={
                              routeName === "project"
                            }
                          />
                        ))
                    ) : (
                      <p className="Color__Primary">
                        {`${t("studyRequest.notAvailableCategoryMsg")}`}
                      </p>
                    )}
                  </div>
                </Box>
              ))}
            </div>
          </div>
        </div>
      </form>
      <div className="flex items-center pt-4 pb-8 float-left">
        <DeleteStudyRequestDraft
          isDeleteModalShowed={isDeleteModalShowed}
          toggleDeleteModal={toggleDeleteModal}
          routeName={routeName}
          isPending={updateStudyRequest.isPending}
          studyRequestType={t("this-cost-of-work")}
        />
      </div>
      <div className="flex items-center justify-end pt-4 pb-8">
        <div>
          <Button
            isLoading={updateStudyRequest.isPending}
            onClick={() => redirectEditCoW()}
            size="small"
            mode="secondary"
          >
            {`${t("details-draft")}`}
          </Button>
          <Button
            onClick={handleSubmit((data) => onSubmit(data))}
            size="small"
            mode="primary"
            marginLeft={12}
            isDisabled={updateStudyRequest.isPending}
          >
            {`${t("studyRequest.submitStudyRequestButton")}`}
          </Button>
        </div>
      </div>
    </div>
  )
}
