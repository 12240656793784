import ButtonIcon from "components/Button/ButtonIcon"
import DateFormat from "components/Date/DateFormat"
import useModal from "components/Modal/useModal"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { useGetAgreementsByProjectId } from "core/query-hooks/useAgreements"
import { useGetOperationById } from "core/query-hooks/useOperations"
import { closestTo } from "date-fns/closestTo"
import { t } from "i18next"
import { useState } from "react"
import { MdEdit } from "react-icons/md"
import { useParams } from "react-router-dom"
import { GROUP } from "shared/resources/groups.resources"
import { EditAgreementDatesModal } from "./EditAgreementDatesModal"

export default function AgreementDates() {
  const {
    isShowing: isEditAgreementDateModalShowed,
    toggle: toggleEditAgreementDate,
  } = useModal()

  const { id } = useParams()

  const { data: operationData } = useGetOperationById(+id!)
  const { data: agreements } = useGetAgreementsByProjectId(id!)
  const [field, setField] = useState<string | null>(null)

  const agreementTermDate = agreements?.data
    ? closestTo(
        new Date(),
        agreements.data.map((a) => new Date(a.termDate)),
      )
    : null

  const handleToggle = (agreementField: string) => {
    setField(agreementField)
    toggleEditAgreementDate()
  }

  return (
    <>
      <ul className="pb-4">
        <li className="flex items-center justify-between pb-2">
          <p className="text-body text-cyan-950"> {t("land-purchase-title")}</p>
          <span className="grid grid-cols-3 items-center">
            {operationData?.operationData?.landPurchaseDate ? (
              <div className="col-span-2">
                <DateFormat
                  isDefinitive={
                    operationData?.operationData?.isLandPurchaseDateDefinitive
                  }
                  date={
                    new Date(operationData?.operationData?.landPurchaseDate)
                  }
                />
              </div>
            ) : (
              <div className="col-span-2">-</div>
            )}
            <ButtonIcon
              className="ml-2"
              type="button"
              onClick={() => handleToggle("landPurchaseDate")}
            >
              <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
            </ButtonIcon>
          </span>
        </li>
        <li className="flex items-center justify-between pb-2">
          <p className="text-body text-cyan-950"> {t("agreement-term")}</p>
          <span className="grid grid-cols-3 items-center">
            {agreementTermDate ? (
              <div className="col-span-2">
                <DateFormat date={new Date(agreementTermDate)} isDefinitive />
              </div>
            ) : (
              <div className="col-span-2">-</div>
            )}
          </span>
        </li>
        <li className="flex items-center justify-between pb-2">
          <p className="text-body text-cyan-950"> {t("reliability")}</p>
          <span className="grid grid-cols-3 items-center">
            {operationData?.operationData?.reliabilityDate ? (
              <div className="col-span-2">
                <DateFormat
                  isDefinitive={
                    operationData?.operationData?.isReliabilityDateDefinitive
                  }
                  date={new Date(operationData?.operationData?.reliabilityDate)}
                />
              </div>
            ) : (
              <div className="col-span-2">-</div>
            )}
            <GroupsGuard
              requiredGroups={[
                GROUP.JURIDIQUE_JURISTE,
                GROUP.JURIDIQUE_ASSISTANT,
                GROUP.CODIR,
              ]}
            >
              <ButtonIcon
                className="ml-2"
                type="button"
                onClick={() => handleToggle("reliabilityDate")}
              >
                <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
              </ButtonIcon>
            </GroupsGuard>
          </span>
        </li>
        <li className="flex items-center justify-between pb-1">
          <p className="text-body text-cyan-950"> {t("cancelation")}</p>
          <span className="grid grid-cols-3 items-center">
            {operationData?.operationData?.cancelationDate ? (
              <div className="col-span-2">
                <DateFormat
                  isDefinitive={
                    operationData?.operationData?.isCancelationDateDefinitive
                  }
                  date={new Date(operationData?.operationData?.cancelationDate)}
                />
              </div>
            ) : (
              <div className="col-span-2">-</div>
            )}
            <GroupsGuard
              requiredGroups={[
                GROUP.JURIDIQUE_JURISTE,
                GROUP.JURIDIQUE_ASSISTANT,
                GROUP.CODIR,
              ]}
            >
              <ButtonIcon
                className="ml-2"
                type="button"
                onClick={() => handleToggle("cancelationDate")}
              >
                <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
              </ButtonIcon>
            </GroupsGuard>
          </span>
        </li>
      </ul>
      {isEditAgreementDateModalShowed && (
        <EditAgreementDatesModal
          isEditAgreementDateModalShowed={isEditAgreementDateModalShowed}
          field={field}
          operationData={operationData?.operationData}
          toggleEditAgreementDate={toggleEditAgreementDate}
          operationId={id!}
        />
      )}
    </>
  )
}
