import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import Avatar from "components/Avatar/Avatar"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import StandByHistory from "components/StandByHistory/StandByHistory"
import {
  useAddCommentToStudyRequest,
  useUpdateStatus,
  useUpdateStudyRequest,
} from "core/query-hooks/useStudyRequests"
import { useGetUsersByOccupationId } from "core/query-hooks/useUsers"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { sortByDate } from "pages/dashboards/study-requests/dashboard-util"
import { useEffect, useState } from "react"
import Select, {
  MultiValue,
  MultiValueGenericProps,
  OptionProps,
  components,
} from "react-select"
import { toast } from "react-toastify"
import { STAND_BY_PRIORIZATION_COMMENT } from "shared/resources/study-request.resources"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"
import { TUser } from "shared/types/user.type"
import {
  CONCEPTEUR_OCCUPATION_ID,
  ECONOMIST_OCCUPATION_ID,
  INFOGRAPHIST_OCCUPATION_ID,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_VALIDATED,
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "./studyRequest.resources"

export interface IPriorisationAttributionFormProps {
  requestId: number
  studyRequest: TStudyRequest
  studyRequestHistory: TStudyRequestHistory[]
  studyRequestType: number
}

function MultiValueLabel(properties: MultiValueGenericProps<any>) {
  const { data, children } = properties
  return (
    <components.MultiValueLabel {...properties}>
      <div className="flex items-center">
        <div className="AddContributor__Option_Avatar_Container mr-1 flex items-center justify-center">
          <Avatar size="small" user={data} />
        </div>
        {children}
      </div>
    </components.MultiValueLabel>
  )
}

function Option(properties: OptionProps) {
  const {
    data,
    children,
    className = "px-2 Border__Bottom_LightGrey py-2 AddContributor__Option",
    cx,
    innerRef,
    innerProps,
  } = properties

  return (
    <div
      ref={innerRef}
      className={cx(
        {
          option: true,
        },
        className,
      )}
      {...innerProps}
    >
      <div className="flex items-center cursor-pointer">
        <div className="AddContributor__Option_Avatar_Container mr-1 flex items-center justify-center">
          <Avatar size="small" user={data as TUser} />
        </div>
        {children}
      </div>
    </div>
  )
}

export function PriorisationAttributionForm(
  props: IPriorisationAttributionFormProps,
) {
  const queryClient = useQueryClient()
  const { requestId, studyRequest, studyRequestHistory, studyRequestType } =
    props
  const [showModal, setShowModal] = useState<boolean>(false)
  const standbyPriorizationComment = studyRequest?.comments
    ?.filter((c) => c.type.id === STAND_BY_PRIORIZATION_COMMENT.id)
    .sort((a, b) => sortByDate(a.creationDate, b.creationDate))[0]?.comment
  const [standbyComment, setStandbyComment] = useState<string | undefined>(
    standbyPriorizationComment ?? undefined,
  )

  // Récupération des utilisateurs attribuables en fonction du type de demande
  let occupationId: number = 0
  let studyRequestLabel = ""
  switch (studyRequestType) {
    case TYPE_FEASIBILITY:
      occupationId = CONCEPTEUR_OCCUPATION_ID
      studyRequestLabel = `${t("studyRequest.versionLabel")} *`
      break
    case TYPE_INFOGRAPHY:
      occupationId = INFOGRAPHIST_OCCUPATION_ID
      studyRequestLabel = `${t("studyRequest.infography-versionLabel")} *`
      break
    case TYPE_COST_OF_WORK:
      occupationId = ECONOMIST_OCCUPATION_ID
      studyRequestLabel = `${t("studyRequest.cow-versionLabel")} *`
      break
    default:
  }

  const { data: attributableUsers } = useGetUsersByOccupationId(occupationId)

  const updateStudyRequestStatus = useUpdateStatus(requestId!)
  const updateStudyRequest = useUpdateStudyRequest(requestId)
  const addCommentToStudyRequest = useAddCommentToStudyRequest(requestId!)

  const [comment, setComment] = useState<string | undefined>(
    studyRequest?.assignmentComment,
  )
  const [priorisation, setPriorisation] = useState<string | undefined>(
    studyRequest?.priorisation,
  )
  const [assignedUsers, setAttributedUsers] = useState<string[] | undefined>(
    studyRequest?.assignedUsers,
  )
  const [onStandby, setOnStandby] = useState<boolean>(
    studyRequest.statusId === STATUS_PRIORIZATION_STANDBY,
  )
  const [version, setVersion] = useState<string>(
    studyRequest?.version || "v0.0.1",
  )

  const [versionError, setVersionError] = useState<boolean>(false)

  const [estimatedRestitutionDate, setEstimatedRestitutionDate] =
    useState<Date | null>(
      studyRequest.estimatedRestitutionDate
        ? new Date(studyRequest.estimatedRestitutionDate)
        : null,
    )
  const startingStandbyHistory = studyRequestHistory?.find(
    (srh) => srh.statusId === STATUS_PRIORIZATION_STANDBY,
  )

  const endingStandbyHistory = studyRequestHistory?.filter(
    (srh) => srh.statusId === STATUS_VALIDATED,
  )

  const formattedAttributableUsers = attributableUsers?.map((user) => ({
    ...user,
    label: `${user.firstName} ${user.lastName}`,
    value: user.azureId,
  }))

  // Formatage des utilisateurs attribuées pour les default values
  const formattedHistoryAttributedUsers: any[] = []
  if (attributableUsers && studyRequest?.assignedUsers) {
    attributableUsers?.forEach((user) => {
      if (
        studyRequest.assignedUsers?.some((azureId) => azureId === user.azureId)
      ) {
        formattedHistoryAttributedUsers.push({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
          value: user.azureId,
        })
      }
    })
  }

  function invalidateQueriesData() {
    queryClient.invalidateQueries({ queryKey: ["getCostOfWork", requestId!] })
    queryClient.invalidateQueries({ queryKey: ["getFeasibility", requestId!] })
    queryClient.invalidateQueries({ queryKey: ["getInfography", requestId!] })
    queryClient.invalidateQueries({
      queryKey: ["listStudyRequests", studyRequest.projectId!],
    })
  }

  function invalidatesQueriesHistory() {
    queryClient.invalidateQueries({
      queryKey: ["getFeasibilityHistory", requestId!],
    })
    queryClient.invalidateQueries({
      queryKey: ["getCostOfWorkHistory", requestId!],
    })
    queryClient.invalidateQueries({
      queryKey: ["getInfographyHistory", requestId!],
    })
  }

  const onComment = (assignmentComment: string) => {
    updateStudyRequest.mutate(
      {
        assignmentComment: assignmentComment || null,
      },
      {
        onSuccess: () => {
          assignmentComment
            ? toast.success(`${t("studyRequest.toast.commentSuccess")}`)
            : toast.success(`${t("studyRequest.toast.commentUpdated")}`)
          invalidateQueriesData()
        },
        onError: (err) =>
          toast.error(
            `${t("studyRequest.toast.commentError")} ${
              err.response?.data.message
            }`,
          ),
      },
    )
  }

  const onPriorize = (prio: string) => {
    updateStudyRequest.mutate(
      {
        priorisation: prio || null,
      },
      {
        onSuccess: () => {
          prio
            ? toast.success(`${t("studyRequest.toast.priorisationSuccess")}`)
            : toast.success(`${t("studyRequest.toast.priorisationUpdated")}`)
          invalidateQueriesData()
        },
        onError: (err) =>
          toast.error(
            `${t("studyRequest.toast.priorisationError")} ${
              err.response?.data.message
            }`,
          ),
      },
    )
  }

  const onAttribute = (users: string[]) => {
    updateStudyRequest.mutate(
      {
        assignedUsers: users,
      },
      {
        onSuccess: () => {
          toast.success(`${t("studyRequest.toast.attributionSuccess")}`)
          invalidateQueriesData()
          invalidatesQueriesHistory()
        },
        onError: (err) =>
          toast.error(
            `${t("studyRequest.toast.attributionError")} ${
              err.response?.data.message
            }`,
          ),
      },
    )
  }

  const onEditEstimatedRestitutionDate = (restitutionDate: Date) => {
    updateStudyRequest.mutate(
      {
        estimatedRestitutionDate: restitutionDate,
      },
      {
        onSuccess: () => {
          toast.success(
            `${t("studyRequest.toast.edit-estimated-restitution-date")}`,
          )
          invalidateQueriesData()
          invalidatesQueriesHistory()
        },
        onError: (err) =>
          toast.error(
            `${t(
              "studyRequest.toast.edit-estimated-restitution-date-error",
            )} ${err.response?.data.message}`,
          ),
      },
    )
  }
  const onChangeStep = (
    statusId: number,
    successMessage: string,
    errorMessage: string,
  ) => {
    updateStudyRequestStatus.mutate(
      {
        statusId,
      },
      {
        onSuccess: () => {
          if (statusId === STATUS_PRIORIZATION_STANDBY) {
            setOnStandby(true)
            if (standbyComment) {
              addCommentToStudyRequest.mutate(
                {
                  typeId: STAND_BY_PRIORIZATION_COMMENT.id,
                  comment: standbyComment,
                },
                {
                  onSuccess() {
                    invalidateQueriesData()
                  },
                  onError(error) {
                    toast.error(error.response?.data.error.message)
                  },
                },
              )
            }
          } else {
            setOnStandby(false)
          }
          toast.success(successMessage)
          setShowModal(false)
          invalidateQueriesData()
          invalidatesQueriesHistory()
        },
        onError: (err) => {
          const errMessage = err.response?.data.message
          if (errMessage?.startsWith("Documents missing")) {
            const missingDocs = errMessage?.split(": ")?.pop()?.split(", ")
            toast.error(
              <>
                <p>{`${t("label-mandatory-documents-list")}`}</p>
                <ul>
                  {missingDocs?.map((doc) => (
                    <li key={doc}>
                      <p>- {doc}</p>
                    </li>
                  ))}
                </ul>
              </>,
            )
          } else {
            toast.error(`${errorMessage} ${err.response?.data.message}`)
          }
        },
      },
    )
  }

  const onSubmitChangeStep = () => {
    if (!estimatedRestitutionDate) {
      return toast.error(
        `${t("studyRequest.toast.required-estimated-restitution-date")}`,
      )
    }
    return assignedUsers
      ? onChangeStep(
          STATUS_PRIORIZED_AND_ASSIGNED,
          `${t("studyRequest.toast.priorizationAndAttributionSuccess")}`,
          `${t("studyRequest.toast.priorizationAndAttributionError")}`,
        )
      : toast.error(`${t("studyRequest.toast.requiredAttributedUsers")}`)
  }

  function handleOnChange(usersValue: MultiValue<TUser>) {
    onAttribute(usersValue?.map((user) => user.azureId))

    setAttributedUsers(usersValue.map((user) => user.azureId))
  }

  const onChangeStandby = (studyRqst: TStudyRequest) => {
    if (studyRqst.statusId === STATUS_VALIDATED) {
      onChangeStep(
        STATUS_PRIORIZATION_STANDBY,
        `${t("studyRequest.toast.addStandbySuccess")}`,
        `${t("studyRequest.toast.addStandbyError")}`,
      )
    }

    if (studyRqst.statusId === STATUS_PRIORIZED_AND_ASSIGNED) {
      onChangeStep(
        STATUS_PRIORIZATION_STANDBY,
        `${t("studyRequest.toast.addStandbySuccess")}`,
        `${t("studyRequest.toast.addStandbyError")}`,
      )
    }

    if (studyRqst.statusId === STATUS_PRIORIZATION_STANDBY) {
      onChangeStep(
        STATUS_VALIDATED,
        `${t("studyRequest.toast.removeStandbySuccess")}`,
        `${t("studyRequest.toast.removeStandbyError")}`,
      )
    }
  }

  const onUpdateVersion = (updatedVersion: string, displayToast = true) => {
    if (!updatedVersion) {
      toast.warning(`${t("cannot-be-empty")}`)
    } else {
      updateStudyRequest.mutate(
        {
          version: updatedVersion,
        },
        {
          onSuccess: () => {
            if (displayToast) {
              updatedVersion
                ? toast.success(`${t("studyRequest.toast.versionSuccess")}`, {
                    toastId: "updateVersion",
                  })
                : toast.success(`${t("studyRequest.toast.versionUpdated")}`, {
                    toastId: "updateVersion",
                  })
            }
            invalidateQueriesData()
          },
          onError: (err) =>
            toast.error(
              `${t("studyRequest.toast.commentError")} ${
                err.response?.data.message
              }`,
            ),
        },
      )
    }
  }

  // Enregistre en base la première version par défaut ("v0.0.1")
  useEffect(() => {
    if (!studyRequest?.version) {
      onUpdateVersion(version, false)
    }
  }, [version])

  return (
    <div>
      <form className="Text__Field_Container">
        <div className="flex-1 pb-2 pt-4">
          <Button
            type="button"
            size="small"
            mode="validation"
            onClick={
              onStandby
                ? () => onChangeStandby(studyRequest)
                : () => setShowModal(true)
            }
            classNames={onStandby ? "validation" : "appleGreen"}
            isDisabled={updateStudyRequestStatus.isPending}
          >
            {onStandby
              ? `${t("studyRequest.removeStandby")}`
              : `${t("studyRequest.putOnStandby")}`}
          </Button>
        </div>
        <div className="flex-1 mt-1 grid grid-cols-1 mb-2">
          {startingStandbyHistory ? (
            <StandByHistory
              classNames="text-xs step-more-info"
              label={`${t("studyRequest.startingDate")}`}
              date={startingStandbyHistory.date}
            />
          ) : null}
          {endingStandbyHistory &&
          endingStandbyHistory.length > 1 &&
          !onStandby ? (
            <StandByHistory
              classNames="text-xs step-more-info"
              label={`${t("studyRequest.endingDate")}`}
              date={endingStandbyHistory[0].date}
            />
          ) : null}
        </div>
        <div className="col-span-2">
          <label htmlFor="version">
            {studyRequestLabel}
            <input
              id="version"
              className="Text__Field"
              type="string"
              placeholder={`${t("studyRequest.versionLabel")}`}
              disabled={onStandby}
              value={version}
              onChange={(e) => {
                setVersion(e.target.value)
                setVersionError(e.target.value === "")
              }}
              onBlur={(e) => onUpdateVersion(e.target.value)}
            />
            {versionError ? (
              <span className="text-red-500 text-xs">
                {`${t("required-version")}`}
              </span>
            ) : null}
          </label>
        </div>
        <label htmlFor="comment">
          {`${t("comment")}`}
          <textarea
            id="comment"
            className="Textarea__Field"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={`${t("comment")}`}
            onBlur={(e) => onComment(e.target.value)}
            disabled={onStandby}
          />
        </label>

        <label htmlFor="priorisation">
          {`${t("studyRequest.priorisation")}`}
          <textarea
            id="priorisation"
            className="Textarea__Field"
            value={priorisation}
            onChange={(e) => setPriorisation(e.target.value)}
            placeholder={`${t("studyRequest.priorisation")}`}
            onBlur={(e) => onPriorize(e.target.value)}
            disabled={onStandby}
          />
        </label>

        <div className="mt-1 flex flex-col">
          <label className="mr-4" htmlFor="estimatedRestitution">
            {`${t("estimated-restitution-date")} *`}
          </label>
          <CustomDatePicker
            id="estimatedRestitution"
            locale={fr}
            className="Text__Field"
            placeholderText={`${t("estimated-restitution-date")}`}
            selected={estimatedRestitutionDate}
            onChange={(date) => {
              setEstimatedRestitutionDate(date)
              if (date) {
                onEditEstimatedRestitutionDate(date)
              }
            }}
          />
        </div>

        <label htmlFor="attribution">
          {`${t("studyRequest.attribution")}`} {`${t("max3persons")}`} *
          {formattedAttributableUsers ? (
            <div className="AddPlot__Modal pb-2">
              <Select
                id="attribution"
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "#F9FAFB",
                    border: "1px solid #F0F0F0",
                  }),
                  indicatorSeparator: (styles) => ({
                    ...styles,
                    backgroundColor: "none",
                  }),
                  multiValue: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    border: "1px solid #F0F0F0",
                    borderRadius: 27,
                  }),
                }}
                isMulti
                onChange={(users) => handleOnChange(users)}
                placeholder="Sélectionner"
                options={formattedAttributableUsers}
                defaultValue={formattedHistoryAttributedUsers}
                isDisabled={onStandby}
                components={{
                  MultiValueLabel,
                  Option,
                }}
                isOptionDisabled={() =>
                  onStandby ||
                  (assignedUsers !== undefined && assignedUsers.length >= 3)
                }
                isClearable={false}
              />
            </div>
          ) : null}
        </label>

        <div className="mt-2">
          <Button
            type="button"
            size="small"
            mode="validation"
            isDisabled={
              !(
                studyRequest?.assignedUsers &&
                studyRequest.assignedUsers.length > 0 &&
                studyRequest?.estimatedRestitutionDate &&
                !versionError
              ) ||
              onStandby ||
              updateStudyRequestStatus.isPending
            }
            onClick={() => onSubmitChangeStep()}
          >
            {`${t("studyRequest.nextStep")}`}
          </Button>
        </div>
      </form>
      <Modal
        isShowing={showModal}
        closeModal={() => setShowModal(false)}
        title={t("put-in-standby")}
      >
        <div className="Text__Field_Container mb-2">
          <label className="font-medium" htmlFor="comment">
            {`${t("comment")}`}
            <textarea
              className="Textarea__Field h-48"
              placeholder={`${t("comment")}`}
              id="comment"
              value={standbyComment}
              onChange={(e) => setStandbyComment(e.target.value)}
            />
          </label>
        </div>
        <div className="flex justify-end">
          <Button
            size="medium"
            mode="secondary"
            onClick={() => setShowModal(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            size="medium"
            mode="primary"
            onClick={() => onChangeStandby(studyRequest)}
            marginLeft={24}
            isLoading={updateStudyRequestStatus.isPending}
          >
            {t("confirm")}
          </Button>
        </div>
      </Modal>
    </div>
  )
}
