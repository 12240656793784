import { useQueryClient } from "@tanstack/react-query"
import {
  useAddAgreementPlot,
  useDeleteAgreementPlot,
} from "core/query-hooks/useAgreements"
import { useGetFreePlotsByProjectId } from "core/query-hooks/usePlots"
import { t } from "i18next"
import { MdLayers } from "react-icons/md"
import { useParams } from "react-router-dom"
import Select, {
  MultiValue,
  MultiValueGenericProps,
  components,
} from "react-select"
import { OptionProps } from "react-select/dist/declarations/src/components/Option"
import { toast } from "react-toastify"
import { TPlot, TPlotList } from "shared/types/plot.type"

export type TPlotOption = TPlot & {
  label: string
  value: string
}

function MultiValueLabel(props: MultiValueGenericProps<TPlotOption>) {
  const { children } = props
  return (
    <components.MultiValueLabel {...props}>
      <div className="flex items-center">
        <MdLayers className="mr-1" />
        {children}
      </div>
    </components.MultiValueLabel>
  )
}

function Option(props: OptionProps<TPlotOption>) {
  const {
    children,
    className = "px-2 Border__Bottom_LightGrey py-2 AddContributor__Option",
    cx,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
  } = props

  return (
    <div
      ref={innerRef}
      className={cx(
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
        },
        className,
      )}
      {...innerProps}
    >
      <div className="flex items-center">
        <MdLayers className="mr-1" />
        {children}
      </div>
    </div>
  )
}

export interface IPlotInputProps {
  linkedPlots: TPlotList
}

export function PlotInput(props: IPlotInputProps) {
  const queryClient = useQueryClient()
  const { id, agreementId } = useParams()
  const { data: freePlots } = useGetFreePlotsByProjectId(`${id}`)
  const addAgreementPlot = useAddAgreementPlot(+agreementId!)
  const deleteAgreementPlot = useDeleteAgreementPlot(+agreementId!)
  const { linkedPlots } = props

  const formattedPlotsOptions = freePlots?.data?.map((plot) => ({
    ...plot,
    label: `${plot.prefix} ${plot.section} ${plot.number} - ${plot.area.val} ${plot.area.unit}`,
    value: plot.id.toString(),
  }))

  const formattedLinkedPlots = linkedPlots?.data?.map((plot) => ({
    ...plot,
    label: `${plot.prefix} ${plot.section} ${plot.number} - ${plot.area.val} ${plot.area.unit}`,
    value: plot.id.toString(),
  }))

  function invalidateQueries() {
    queryClient.invalidateQueries({
      queryKey: ["getFreePlotsByProjectId", id],
    })
    return queryClient.invalidateQueries({
      queryKey: ["getPlotsByAgreementId", agreementId],
    })
  }

  function handleOnChange(plots: MultiValue<TPlot>) {
    const addedPlots = plots?.filter(
      (plot) => !formattedLinkedPlots?.find((p) => p.id === plot.id),
    )

    const deletedPlots = formattedLinkedPlots?.filter(
      (plot) => !plots.find((p) => p.id === plot.id),
    )

    if (addedPlots.length) {
      addAgreementPlot.mutateAsync(addedPlots[0].id, {
        onSuccess() {
          toast.success(`${t("plot-linked-to-agreement-success")}`)
          invalidateQueries()
        },
        onError() {
          toast.error(`${t("plot-linked-to-agreement-error")}`)
          invalidateQueries()
        },
      })
    }

    if (deletedPlots?.length) {
      deleteAgreementPlot.mutate(deletedPlots[0].id, {
        onSuccess() {
          toast.success(`${t("plot-unlinked-to-agreement-success")}`)
          invalidateQueries()
        },
        onError() {
          toast.error(`${t("plot-unlinked-to-agreement-error")}`)
          invalidateQueries()
        },
      })
    }
  }

  const noFreePlots = !freePlots || freePlots.data.length === 0
  const noAvailableFormattedLinkedPlots =
    !formattedLinkedPlots || formattedLinkedPlots.length === 0

  if (noFreePlots && noAvailableFormattedLinkedPlots) {
    return <div>{t("no-assignable-plot")}</div>
  }

  return formattedLinkedPlots ? (
    <div className="AddPlot__Modal pb-2">
      <Select
        styles={{
          control: (styles) => ({
            ...styles,

            border: "1px solid #F0F0F0",
          }),
          indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "none",
          }),
          multiValue: (styles) => ({
            ...styles,
            backgroundColor: "white",
            border: "1px solid #F0F0F0",
            borderRadius: 27,
          }),
        }}
        isMulti
        onChange={(plots) => handleOnChange(plots)}
        placeholder={`${t("plots.searchText")}`}
        options={formattedPlotsOptions}
        noOptionsMessage={() => `${t("plots.noDataFound")}`}
        defaultValue={formattedLinkedPlots}
        components={{ MultiValueLabel, Option }}
        isClearable={false}
      />
    </div>
  ) : null
}
