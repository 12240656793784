import { AuthProvider } from "core/auth/AuthProvider"
import ProtectedRoute from "core/auth/ProtectedRoute"
import AangAxiosInterceptor from "core/interceptors/AangInterceptor"
import JarvisAxiosInterceptor from "core/interceptors/JarvisInterceptor"
import { PropsWithChildren } from "react"

export interface IRoutesProviderProps extends PropsWithChildren {}

export function RoutesProvider(props: IRoutesProviderProps) {
  const { children } = props
  return (
    <AuthProvider>
      <ProtectedRoute>
        <JarvisAxiosInterceptor>
          <AangAxiosInterceptor>{children}</AangAxiosInterceptor>
        </JarvisAxiosInterceptor>
      </ProtectedRoute>
    </AuthProvider>
  )
}
