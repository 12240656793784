import {
  STATUS_CANCELLED,
  STATUS_CREATED,
  STATUS_DRAFT,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_REJECTED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  STATUS_VALIDATED,
} from "features/studies/studyRequest.resources"

export interface IStudyStatusFormatterProps {
  statusId: number
}

export function getStudyRequestStatus(statusId: number): string {
  switch (statusId) {
    case STATUS_DRAFT:
      return "Brouillon"
    case STATUS_CREATED:
      return "En attente de validation"
    case STATUS_VALIDATED:
      return "Validée"
    case STATUS_REJECTED:
      return "Refusée"
    case STATUS_PRIORIZED_AND_ASSIGNED:
      return "Priorisée & attribuée"
    case STATUS_RETURNED:
      return "Restituée"
    case STATUS_CANCELLED:
      return "Annulée"
    case STATUS_PRIORIZATION_STANDBY:
      return "Stand-by priorisation"
    case STATUS_RESTITUTION_STANDBY:
      return "Stand-by restitution"
    default:
      return ""
  }
}

export function StudyStatusFormatter(props: IStudyStatusFormatterProps) {
  const { statusId } = props

  return (
    <div className="flex items-center status-formatter">
      {getStudyRequestStatus(statusId)}
    </div>
  )
}
