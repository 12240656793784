import Box from "components/Box/Box"
import Microsoft from "components/Icons/Microsoft"
import { t } from "i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { login } from "utils/firebase"

type LocationProps = {
  state: {
    from: Location
  }
}

export default function Login() {
  const navigate = useNavigate()
  const location = useLocation() as unknown as LocationProps
  const from = location.state?.from?.pathname || "/"

  const onLogin = async () => {
    const authToken = await login()
    if (authToken) localStorage.setItem("token", JSON.stringify(authToken))
    navigate(from, { replace: true })
  }

  return (
    <div className="min-h-screen grid rid-rows-1 lg:grid-cols-4 lg:grid-flow-col grid-flow-row-dense">
      <div
        style={{
          backgroundImage: `url("/building.jpg")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="col-span-2"
      />
      <div className="col-span-2 lg:px-52 flex items-center justify-center">
        <Box classNames="flex items-center flex-col">
          <h2 className="font-bold text-lg">{`${t("welcome-to-alvis")}`}</h2>
          <p className="text-sm opacity-80 Color__Primary text-center lg:w-56 mt-1">
            {`${t("continue-connecting-with-pierreval-account")}`}
          </p>
          <button
            className="transition-colors ease-in-out delay-50 mt-4 flex items-center justify-center bg-zinc-200 hover:bg-zinc-300 px-3 py-2"
            onClick={onLogin}
            type="button"
          >
            <Microsoft />
            <span className="pl-2">{`${t("connect-with-microsoft")}`}</span>
          </button>
        </Box>
      </div>
    </div>
  )
}
