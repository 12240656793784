import { useGetCurrentUser } from "core/query-hooks/useUsers"
import * as React from "react"
import { Navigate } from "react-router-dom"
import { GROUP } from "shared/resources/groups.resources"

export interface IGroupProtectedRouteProps extends React.PropsWithChildren {
  requiredGroups: string[]
}

export function GroupProtectedRoute(props: IGroupProtectedRouteProps) {
  const { data: user } = useGetCurrentUser()
  const { children, requiredGroups } = props

  const isInGroup = requiredGroups.some((requiredGroup) =>
    user?.groups.includes(requiredGroup),
  )

  const isAdmin = user?.groups.includes(GROUP.ADMIN)

  if (!isInGroup && !isAdmin) return <Navigate to="/" replace />

  return <>{children}</>
}
