import classNames from "classnames"
import { t } from "i18next"
import "./study-status.scss"
import {
  STATUS_CANCELLED,
  STATUS_CREATED,
  STATUS_DRAFT,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_REJECTED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  STATUS_VALIDATED,
} from "./studyRequest.resources"

export interface IStudyStatusProps {
  requestStatusId: number
}

function RenderStep({
  stepStatus,
  stepLabel,
}: {
  stepStatus: string
  stepLabel: string
}) {
  return (
    <li className={classNames("step mx-0.5", stepStatus)}>
      <div className={classNames("step-tooltip", stepStatus)}>
        <div className="flex items-center">
          <span className={classNames("step-dot mr-2", stepStatus)} />
          <span>{stepLabel}</span>
        </div>
      </div>
    </li>
  )
}

function RenderStatus({ requestStatusId }: { requestStatusId: number }) {
  switch (requestStatusId) {
    case STATUS_DRAFT:
      return (
        <>
          <RenderStep stepLabel={t("draft")} stepStatus="draft" />
          <RenderStep stepLabel={t("study-step-validate")} stepStatus="next" />
          <RenderStep
            stepLabel={t("study-step-priorise-next")}
            stepStatus="next"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-next")}
            stepStatus="next"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-done")}
            stepStatus="next"
          />
        </>
      )
    case STATUS_PRIORIZED_AND_ASSIGNED:
      return (
        <>
          <RenderStep stepLabel={t("draft")} stepStatus="restitued" />
          <RenderStep
            stepLabel={t("study-step-validate")}
            stepStatus="restitued"
          />
          <RenderStep
            stepLabel={t("study-step-priorise-next")}
            stepStatus="restitued"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-doing")}
            stepStatus="current"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-done")}
            stepStatus="next"
          />
        </>
      )
    case STATUS_VALIDATED:
      return (
        <>
          <RenderStep stepLabel={t("draft")} stepStatus="restitued" />
          <RenderStep
            stepLabel={t("study-step-validate")}
            stepStatus="restitued"
          />
          <RenderStep
            stepLabel={t("study-step-priorise")}
            stepStatus="current"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-next")}
            stepStatus="next"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-done")}
            stepStatus="next"
          />
        </>
      )
    case STATUS_REJECTED:
      return (
        <>
          <RenderStep stepLabel={t("draft")} stepStatus="restitued" />
          <RenderStep
            stepLabel={t("study-step-refused")}
            stepStatus="refused"
          />
          <RenderStep
            stepLabel={t("study-step-priorise-next")}
            stepStatus="next"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-next")}
            stepStatus="next"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-done")}
            stepStatus="next"
          />
        </>
      )
    case STATUS_RETURNED:
      return (
        <>
          <RenderStep stepLabel={t("draft")} stepStatus="restitued" />
          <RenderStep
            stepLabel={t("study-step-validate")}
            stepStatus="restitued"
          />
          <RenderStep
            stepLabel={t("study-step-priorise-next")}
            stepStatus="restitued"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-next")}
            stepStatus="restitued"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-done")}
            stepStatus="restitued"
          />
        </>
      )
    case STATUS_CREATED:
      return (
        <>
          <RenderStep stepLabel={t("draft")} stepStatus="restitued" />
          <RenderStep
            stepLabel={t("study-step-validate")}
            stepStatus="current"
          />
          <RenderStep
            stepLabel={t("study-step-priorise-next")}
            stepStatus="next"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-next")}
            stepStatus="next"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-done")}
            stepStatus="next"
          />
        </>
      )
    case STATUS_PRIORIZATION_STANDBY:
      return (
        <>
          <RenderStep stepLabel={t("draft")} stepStatus="restitued" />
          <RenderStep
            stepLabel={t("study-step-validate")}
            stepStatus="restitued"
          />
          <RenderStep
            stepLabel={t("study-step-priorise-standby")}
            stepStatus="standby"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-next")}
            stepStatus="next"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-done")}
            stepStatus="next"
          />
        </>
      )
    case STATUS_CANCELLED:
      return (
        <>
          <RenderStep
            stepLabel={t("study-step-cancelled")}
            stepStatus="refused"
          />
          <RenderStep
            stepLabel={t("study-step-cancelled")}
            stepStatus="refused"
          />
          <RenderStep
            stepLabel={t("study-step-cancelled")}
            stepStatus="refused"
          />
          <RenderStep
            stepLabel={t("study-step-cancelled")}
            stepStatus="refused"
          />
          <RenderStep
            stepLabel={t("study-step-cancelled")}
            stepStatus="refused"
          />
        </>
      )
    case STATUS_RESTITUTION_STANDBY:
      return (
        <>
          <RenderStep stepLabel={t("draft")} stepStatus="restitued" />
          <RenderStep
            stepLabel={t("study-step-validate")}
            stepStatus="restitued"
          />
          <RenderStep
            stepLabel={t("study-step-priorise")}
            stepStatus="restitued"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-standby")}
            stepStatus="standby"
          />
          <RenderStep
            stepLabel={t("study-step-restitution-done")}
            stepStatus="next"
          />
        </>
      )
    default:
      return null
  }
}

export function StudyStatus(props: IStudyStatusProps) {
  const { requestStatusId } = props
  return (
    <ul className="study-status flex">
      <RenderStatus requestStatusId={requestStatusId} />
    </ul>
  )
}
