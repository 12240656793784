import ItemsCounter from "components/Counter/ItemsCounter"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useListStudyRequestsTodos } from "core/query-hooks/useStudyRequests"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import { t } from "i18next"
import { FaPencilRuler } from "react-icons/fa"
import { Link } from "react-router-dom"

export default function CountStudyRequestsTodosContainer() {
  const { data: user } = useGetCurrentUser()
  const {
    data: studyRequestsTodos,
    isLoading,
    error,
  } = useListStudyRequestsTodos(user?.azureId!)

  return (
    <Link to={{ pathname: "/expected-study-requests" }}>
      <TooltipWithHover
        message={t("study-request-tasks-number")}
        position="right"
      >
        <ItemsCounter
          title={t("studies")}
          Icon={<FaPencilRuler height={18} width={18} color="#00304D" />}
          totalItems={studyRequestsTodos ? studyRequestsTodos.length : 0}
          isLoading={isLoading}
          error={error}
          errorMessage={t("error-loading-study-requests-todos")}
          classNames="bg-white"
        />
      </TooltipWithHover>
    </Link>
  )
}
