import { useQueryClient } from "@tanstack/react-query"
import UserPopover from "components/Avatar/UserPopover"
import { t } from "i18next"
import { MdClose } from "react-icons/md"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Avatar from "../../components/Avatar/Avatar"
import Spinner from "../../components/Spinner/Spinner"
import { deleteContributorById } from "../../core/query-hooks/useContributors"
import { useGetUserByAzureId } from "../../core/query-hooks/useUsers"
import { TUser } from "../../shared/types/user.type"

interface IContributorItemProps {
  userAzureId: string
  contributorId: number
  isLast: boolean
  onRemoveContributor: (contributor: TUser) => void
}

export default function ContributorItem({
  userAzureId,
  onRemoveContributor,
  contributorId,
  isLast,
}: IContributorItemProps) {
  const queryClient = useQueryClient()
  const params = useParams()
  const { data } = useGetUserByAzureId(userAzureId)
  const deleteContributor = deleteContributorById(contributorId.toString())
  function onRemove(contributor: TUser) {
    deleteContributor.mutate(
      { id: contributor.id },
      {
        onSuccess: () => {
          onRemoveContributor(contributor)
          queryClient.invalidateQueries({
            queryKey: ["useGetContributorsByProjectId", parseInt(params.id!)],
          })
          toast.success(t("contributor-deleted"))
        },
        onError: (err) =>
          toast.error(
            err.response?.status === 403
              ? `${t("error-at-least-one-developper")}`
              : `${err.response?.data.message}`,
          ),
      },
    )
  }

  return data ? (
    <div className="flex items-center AddContributor__Option_Container mr-1">
      <div className="AddContributor__Option_Avatar_Container mr-1 flex items-center justify-center">
        <Avatar hasHoverCard size="small" user={data} displayTop={isLast} />
      </div>
      <UserPopover hasHoverCard size="small" user={data} isLast={isLast} />
      <button type="button" onClick={() => onRemove(data)}>
        <MdClose className="ml-1" />
      </button>
    </div>
  ) : (
    <Spinner />
  )
}
