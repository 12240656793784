import { useMutation, useQuery } from "@tanstack/react-query"
import {
  createCommittee,
  listCommitteeByOperationId,
  listCommitteesTypes,
  updateCommittee,
} from "core/api/committee"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TCommittee,
  TCommitteeStep,
  TMutateCommittee,
} from "shared/types/committee.type"

export default function useListCommitteesTypes() {
  return useQuery<TCommittee[], CustomAxiosError>({
    queryKey: ["listCommitteesTypes"],
    queryFn: () => listCommitteesTypes(),
  })
}

function useListCommitteeByOperationId(operationId: number) {
  return useQuery<TCommitteeStep[], CustomAxiosError>({
    queryKey: ["listCommitteesByOperation", operationId],
    queryFn: () => listCommitteeByOperationId(operationId),
    gcTime: 15 * 60 * 1000,
  })
}

function useUpdateCommittee(committeeId: number) {
  return useMutation<
    TCommitteeStep,
    CustomAxiosError,
    Omit<TMutateCommittee, "typeId" | "operationId">
  >({ mutationFn: (data) => updateCommittee(committeeId, data) })
}

function useCreateCommittee() {
  return useMutation<TCommitteeStep, CustomAxiosError, TMutateCommittee>({
    mutationFn: (data) => createCommittee(data),
  })
}

export {
  useCreateCommittee,
  useListCommitteeByOperationId,
  useListCommitteesTypes,
  useUpdateCommittee,
}
