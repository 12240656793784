import Box from "components/Box/Box"
import { useGetCostOfWork } from "core/query-hooks/useCostOfWork"
import { format } from "date-fns"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { Link, useParams } from "react-router-dom"
import { DEVELOPER_COMMENT } from "shared/resources/study-request.resources"

export interface ICostOfWorkDetails {
  routeName: string
}

export function CostOfWorkDetails(props: ICostOfWorkDetails) {
  const { studyRequestId, id } = useParams()
  const { routeName } = props
  const { data: costOfWorkData } = useGetCostOfWork(+studyRequestId!)
  const cowDeveloperComments = costOfWorkData?.comments
    ? costOfWorkData.comments.filter(
        (comment) => comment.type.id === DEVELOPER_COMMENT.id,
      )
    : []

  return (
    <div>
      <Box>
        <p className="Color__Primary font-semibold text-lg">
          {`${t("concerned-service")}`}
        </p>
        <p className="Color__Primary">{`${t("building-economics")}`}</p>
      </Box>
      <Box classNames="flex flex-col Container my-4">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("feasibility-version")}`}
        </p>
        <div className="Color__Primary">
          <Link
            className="text-blue-500 "
            target="_blank"
            to={`/${routeName}/${id}/studies/feasibility/${costOfWorkData?.feasibility?.id}`}
          >
            {costOfWorkData?.feasibility?.version}
          </Link>
        </div>
      </Box>
      <Box classNames="flex flex-col Container my-4">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("cow-version")}`}
        </p>
        <div className="Color__Primary">
          <p>{costOfWorkData?.version}</p>
        </div>
      </Box>
      <Box classNames="flex flex-row Container my-4 justify-between items-center">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("margin")}`}
        </p>
        <div className="Color__Primary">
          <p>{costOfWorkData?.feasibility?.marginPercentage ?? "0"} %</p>
        </div>
      </Box>
      <Box classNames="flex flex-row Container my-4 justify-between items-center">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("cashflow")}`}
        </p>
        <div className="Color__Primary">
          <p>
            {costOfWorkData?.feasibility?.cashflow?.toLocaleString() ?? 0} €
          </p>
        </div>
      </Box>
      <Box classNames="flex flex-col Container lg:mb-4">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("comment")}`}
        </p>
        {cowDeveloperComments.length > 0 ? (
          cowDeveloperComments.map((comment, index) => (
            <p
              className="Color__Primary break-all whitespace-pre-line"
              key={nanoid()}
            >
              {index === 0
                ? comment.comment
                : `Mis à jour le ${format(
                    new Date(comment.creationDate),
                    "dd/MM/yyyy HH:mm",
                  )} : ${comment.comment}`}
            </p>
          ))
        ) : (
          <p className="Color__Primary">{`${t("no-comment")}`}</p>
        )}
      </Box>
    </div>
  )
}
