import { useCallback, useState } from "react"

const useSidebar = () => {
  const [isShowing, setIsShowing] = useState(false)

  const toggle = useCallback(() => {
    setIsShowing((prevIsShowing) => !prevIsShowing)
  }, [])

  return {
    isShowing,
    toggle,
  }
}

export default useSidebar
