import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios"
import getLocalStorageToken from "core/api/token"
import SPO_API from "core/spo/axios"
import { signOut } from "firebase/auth"
import { ReactNode, useEffect, useRef, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { auth } from "utils/firebase"

interface Props {
  children: ReactNode
}

export default function SpoAxiosInterceptor({ children }: Props) {
  const [interceptorIsMounted, setInterceptorIsMounted] = useState(false)
  const [user] = useAuthState(auth)
  const navRef = useRef(useNavigate())

  useEffect(() => {
    const onRequest = (
      config: InternalAxiosRequestConfig,
    ): InternalAxiosRequestConfig => {
      const token = getLocalStorageToken()

      const modifiedConfig = { ...config }
      if (modifiedConfig.headers && token) {
        modifiedConfig.headers.Authorization = `Bearer ${JSON.parse(token)}`
      }
      return modifiedConfig
    }

    const onRequestError = (error: AxiosError): Promise<AxiosError> => {
      return Promise.reject(error)
    }

    const resInterceptor = (response: AxiosResponse) => {
      return response
    }

    async function errInterceptor(error: AxiosError) {
      if (error.response?.status === 401) {
        if (user) {
          try {
            const res = await user.getIdTokenResult()
            localStorage.setItem("token", JSON.stringify(res.token))

            if (res.claims.sub) {
              return localStorage.setItem("firebaseId", res.claims.sub)
            }
          } catch (error_auth) {
            toast.error("Impossible de s'authentifier")
            localStorage.removeItem("token")
            localStorage.removeItem("firebaseId")
            navRef.current("/login")
            signOut(auth)
          }
        }
      }
      return Promise.reject(error)
    }

    const interceptorRequest = SPO_API.interceptors.request.use(
      onRequest,
      onRequestError,
    )

    const interceptorResponse = SPO_API.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    )

    setInterceptorIsMounted(true)

    return () => {
      SPO_API.interceptors.request.eject(interceptorRequest)
      SPO_API.interceptors.response.eject(interceptorResponse)
    }
  }, [user])

  return <>{interceptorIsMounted && children}</>
}
