import { t } from "i18next"
import { useNavigate, useRouteError } from "react-router-dom"
import { CustomAxiosError } from "shared/types/axios.type"
import Box from "../../components/Box/Box"
import { Button } from "../../components/Button/Button"

export default function ErrorPage() {
  const navigate = useNavigate()
  const error = useRouteError() as CustomAxiosError

  return (
    <div className="w-full p-3 md:p-32">
      <div className="flex items-center flex-col">
        <Box classNames="text-center px-6">
          <h1 className="text-lg pb-4">{`${t("oops")}`}</h1>
          <p> {`${t("project.errorMessage")}`}</p>
          <p className="mt-4 mb-6">
            <i>{error.status || error.response?.data.message}</i>
          </p>

          <Button
            onClick={() => navigate("/")}
            size="small"
            mode="primary"
            isLoading={false}
          >
            {`${t("project.errorButton")}`}
          </Button>
        </Box>
      </div>
    </div>
  )
}
