import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { CustomAxiosError } from "shared/types/axios.type"
import { TLotStatuses, TLotsCommercialization } from "shared/types/spo/lot.type"
import {
  countSpoLots,
  getLotsCommercializationByOperation,
  getLotsCommercializationByTranche,
  getSpoLotsByOperation,
  getSpoLotsByTranche,
} from "../api/lots"

function useGetSpoLotsByOperation(registrationNumber: string) {
  return useQuery<TLotStatuses, AxiosError>({
    queryKey: ["getSpoLotsByOperation", registrationNumber],
    queryFn: () => getSpoLotsByOperation(registrationNumber),
  })
}

function useGetSpoLotsByTranche(
  registrationNumber: string,
  trancheCommercialeId: string,
) {
  return useQuery<TLotStatuses, AxiosError>({
    queryKey: ["getSpoLotsByTranche", trancheCommercialeId],
    queryFn: () =>
      getSpoLotsByTranche(registrationNumber, trancheCommercialeId),
  })
}

function useGetLotsCommercializationByOperation(registrationNumber: string) {
  return useQuery<TLotsCommercialization, CustomAxiosError>({
    queryKey: ["getLotsCommercializationByOperation", registrationNumber],
    queryFn: () => getLotsCommercializationByOperation(registrationNumber),
  })
}

function useGetLotsCommercializationByTranche(
  registrationNumber: string,
  trancheCommercialeId: string,
) {
  return useQuery<TLotsCommercialization, CustomAxiosError>({
    queryKey: ["getLotsCommercializationByTranche", trancheCommercialeId],
    queryFn: () =>
      getLotsCommercializationByTranche(
        registrationNumber,
        trancheCommercialeId,
      ),
  })
}

function useCountSpoLot(registrationNumber: string) {
  return useQuery<
    { registrationNumber: string; numberOfLots: number },
    AxiosError
  >({
    queryKey: ["countLots", registrationNumber],
    queryFn: () => countSpoLots(registrationNumber),
    gcTime: 15 * 60 * 1000,
  })
}

export {
  useCountSpoLot,
  useGetLotsCommercializationByOperation,
  useGetLotsCommercializationByTranche,
  useGetSpoLotsByOperation,
  useGetSpoLotsByTranche,
}
