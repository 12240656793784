import { TReservations } from "shared/types/spo/reservation.type"
import SPO_API from "../axios"

async function getReservationsByOperation(
  registrationId: string,
): Promise<TReservations> {
  const res = await SPO_API.get(`/operations/${registrationId}/reservations`)
  return res.data
}

async function getReservationsNonActeesByOperation(
  registrationId: string,
): Promise<TReservations> {
  const res = await SPO_API.get(
    `/operations/${registrationId}/reservations-non-actees`,
  )
  return res.data
}

async function getReservationsActeesByOperation(
  registrationId: string,
): Promise<TReservations> {
  const res = await SPO_API.get(`/operations/${registrationId}/actes`)
  return res.data
}

async function getReservationsByTrancheCommerciale(
  registrationId: string,
): Promise<TReservations> {
  const res = await SPO_API.get(
    `/tranches-commerciales/${registrationId}/reservations`,
  )
  return res.data
}

async function getReservationsNonActeesByTrancheCommerciale(
  registrationId: string,
): Promise<TReservations> {
  const res = await SPO_API.get(
    `/tranches-commerciales/${registrationId}/reservations-non-actees`,
  )
  return res.data
}

async function getReservationsActeesByTrancheCommerciale(
  registrationId: string,
): Promise<TReservations> {
  const res = await SPO_API.get(
    `/tranches-commerciales/${registrationId}/actes`,
  )
  return res.data
}

export {
  getReservationsActeesByOperation,
  getReservationsActeesByTrancheCommerciale,
  getReservationsByOperation,
  getReservationsByTrancheCommerciale,
  getReservationsNonActeesByOperation,
  getReservationsNonActeesByTrancheCommerciale,
}
