import { useMutation, useQuery } from "@tanstack/react-query"
import {
  TAmendment,
  TCreateAmendment,
  TUpdateAmendment,
} from "shared/types/amendment.type"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  createAmendment,
  deleteAmendment,
  getAmendmentById,
  listAmendmentsByAgreementId,
  updateAmendment,
} from "../api/amendments"

function useCreateAmendment() {
  return useMutation<TCreateAmendment, CustomAxiosError, TCreateAmendment>({
    mutationFn: (amendment) => createAmendment(amendment),
  })
}

function useGetAmendmentById(id: string) {
  return useQuery<TAmendment, CustomAxiosError, TAmendment>({
    queryKey: ["amendment", id],
    queryFn: () => getAmendmentById(id),
  })
}

function useUpdateAmendment(id: number) {
  return useMutation<TAmendment, CustomAxiosError, TUpdateAmendment>({
    mutationFn: (amendment) => updateAmendment(id, amendment),
  })
}

function useListAmendmentsByAgreementId(agreementId: string) {
  return useQuery<TAmendment[], CustomAxiosError, TAmendment[]>({
    queryKey: ["listAmendmentsByAgreementId", agreementId],
    queryFn: () => listAmendmentsByAgreementId(agreementId),
  })
}

function useDeleteAmendment() {
  return useMutation<unknown, CustomAxiosError, number>({
    mutationFn: (id) => deleteAmendment(id),
  })
}

export {
  useCreateAmendment,
  useDeleteAmendment,
  useGetAmendmentById,
  useListAmendmentsByAgreementId,
  useUpdateAmendment,
}
