import { useQueries } from "@tanstack/react-query"
import { countSpoLots } from "core/spo/api/lots"
import { TChildOperation } from "shared/types/operation.type"

export default function countSpoLotsByOperation(
  operationsChildren: TChildOperation[] | undefined,
): ({ registrationNumber: string; numberOfLots: number } | undefined)[] {
  if (operationsChildren) {
    const lotsByOp = useQueries({
      queries: operationsChildren.map((operation) => {
        return {
          queryKey: ["countLots", operation.id],
          queryFn: () => countSpoLots(operation.registrationNumber),
        }
      }),
    })
    return lotsByOp.map((lots) => lots.data)
  }
  return []
}
