import { API } from "core/api/axios"
import { TIdLabel } from "shared/types/global.type"

export default async function listDepartments(
  isRegistered: boolean,
): Promise<TIdLabel[]> {
  const res = await API.get(
    `/projects/departments?isRegistered=${isRegistered}`,
  )
  return res.data
}
