type IDisplayDetailSwtichProps = {
  label: string
  checked: boolean
  onChange: (isChecked: boolean) => void
}

export default function DisplayDetailSwtich(props: IDisplayDetailSwtichProps) {
  const { label, checked, onChange } = props
  const handleChange = () => {
    onChange(!checked)
  }

  return (
    <label className="cursor-pointer">
      <div className="flex items-center">
        <div className="relative h-5">
          <input
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            className="hidden"
          />
          <span
            className={`${
              checked ? "bg-gray-400" : "bg-pierreval-green"
            } absolute h-5 w-9 rounded-full shadow-inner transform transition-transform`}
          />
          <span
            className={`${
              checked ? "translate-x-0" : "translate-x-5"
            } relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition-transform`}
          />
        </div>
        <div className="ml-7">{label}</div>
      </div>
    </label>
  )
}
