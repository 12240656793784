import { IIconType } from "shared/types/global.type"

function FreshserviceLogo({ width = 16, height = 16 }: IIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-2.94 -27.437 521.39 549.437"
      width={width}
      height={height}
    >
      <path
        fill="#09c6fa"
        d="M349 .05c33.65 0 67.31.11 101 0 17-.13 32.14 4.42 45 15.71 14.12 12.36 22.31 27.9 22.38 46.66.29 68.21 1.07 136.44-.2 204.62-1.15 61.39-23.14 115.58-63.66 161.81C411.17 477.2 357.86 506 294.18 515c-49.35 7-96.94.09-142.21-20.74C88.76 465.18 44.11 417.9 18.12 353.54 2.49 314.81-2.94 274.3 1.49 232.59Q10.22 150.5 64.43 88.3c35-39.93 78.33-66.49 129.63-80a258.84 258.84 0 0 1 67-8.22Q305 .11 349 .05zM298.94 230.4c-19.74 0-39.48-.06-59.22.06-3.43 0-4.51-.59-3.25-4.27 4.86-14.28 9.44-28.65 14.13-43q7.25-22.14 14.48-44.28c1.25-3.83 0-6.93-3.33-8.81s-6.69-1.19-9.2 1.94c-1.4 1.75-2.66 3.61-4 5.43q-34.52 48.08-69 96.13-13.73 19.12-27.55 38.16a9.35 9.35 0 0 0-1.11 10.12c1.71 3.68 4.86 5.08 8.71 5.23 1.64.06 3.29.05 4.93.05h111.26c6.72 0 6.84 0 4.7 6.6q-13.56 41.53-27.24 83c-1.65 5-.87 8.1 2.73 10.1s6.73 1.14 10-3c.47-.58.9-1.19 1.34-1.8q49-67.77 97.94-135.54c2.45-3.39 3.67-6.92 1.6-10.84s-5.59-5.35-10.06-5.33c-19.28.12-38.57.05-57.86.05z"
      />
      <path
        fill="#fbfefe"
        d="M298.94 230.4c19.29 0 38.58.07 57.87-.05 4.47 0 7.95 1.33 10.06 5.33s.85 7.45-1.6 10.84q-49 67.75-97.94 135.54c-.44.61-.87 1.22-1.34 1.8-3.3 4.12-6.37 5-10 3s-4.38-5.08-2.73-10.1q13.65-41.49 27.24-83c2.14-6.57 2-6.6-4.7-6.6H164.54c-1.64 0-3.29 0-4.93-.05-3.85-.15-7-1.55-8.71-5.23a9.35 9.35 0 0 1 1.1-10.12q13.83-19 27.58-38.15 34.53-48 69-96.13c1.31-1.82 2.57-3.68 4-5.43 2.51-3.13 5.8-3.86 9.2-1.94s4.58 5 3.33 8.81l-14.48 44.28c-4.69 14.34-9.27 28.71-14.13 43-1.26 3.68-.18 4.29 3.25 4.27 19.71-.13 39.45-.07 59.19-.07z"
      />
    </svg>
  )
}
export default FreshserviceLogo
