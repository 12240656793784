import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import GedFile from "components/Ged/GedFile"
import { useGetDocumentsById } from "core/ged/query-hooks/useGed"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useUpdateOperationCompany } from "core/query-hooks/useOperationCompanies"
import { AddFile } from "features/studies/AddFile"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { GROUP } from "shared/resources/groups.resources"
import { COPROMOTION_CONVENTION_DOCUMENT_TYPE } from "shared/resources/operation-company.resources"
import { TCopromoter } from "shared/types/copromoter.type"
import { TGedUploadResponse } from "shared/types/ged.type"
import { TOperationCompany } from "shared/types/operation-company.type"
import { CreateCopromoter } from "./CreateCopromoter"
import { EditCopromoter } from "./EditCopromoter"

export interface IOperationCopromotionProps {
  id: number
  company: TOperationCompany
  copromoters?: TCopromoter[]
}

export function OperationCopromotion(props: IOperationCopromotionProps) {
  const { id, company, copromoters } = props
  const queryClient = useQueryClient()
  const updateOperationCompany = useUpdateOperationCompany(id)

  const [copromotionConvention, setCopromotionConvention] = useState<
    TGedUploadResponse[]
  >([])

  const copromotionConventionDocument = useGetDocumentsById(
    company?.copromotionConvention ? company.copromotionConvention : 0,
  ).data

  function updateCopromotionConvention() {
    updateOperationCompany.mutate(
      {
        copromotionConvention: copromotionConvention[0].id,
      },
      {
        onError: (err) => {
          toast.error(
            `${t("copromotion-convention-update-error")} : ${
              err.response?.data.message
            }`,
          )
        },
        onSuccess() {
          toast.success(`${t("copromotion-convention-update-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["getOperationCompany", id],
          })
          setCopromotionConvention([])
        },
      },
    )
  }

  useEffect(() => {
    if (
      copromotionConvention &&
      copromotionConvention.length > 0 &&
      company.copromotionConvention !== copromotionConvention[0].id
    ) {
      updateCopromotionConvention()
    }
  }, [copromotionConvention])

  return (
    <Box classNames="mt-3">
      <h2 className="text-primary-dark font-semibold">{t("copromotion")}</h2>
      <div className="Text__Field_Container">
        <label htmlFor="copromotion-convention">{`${t("copromotion-convention")}`}</label>
        <div className="flex items-center justify-start">
          <GroupsGuard
            requiredGroups={[
              GROUP.JURIDIQUE_JURISTE,
              GROUP.JURIDIQUE_ASSISTANT,
            ]}
          >
            <AddFile
              setFiles={setCopromotionConvention}
              documentTypes={[COPROMOTION_CONVENTION_DOCUMENT_TYPE.label]}
              maxUploadFiles={1}
              hasIcon
            />
          </GroupsGuard>
          {copromotionConvention && copromotionConvention.length > 0 ? (
            <GedFile
              id={copromotionConvention[0].id}
              title={copromotionConvention[0].title}
            />
          ) : (
            <>
              {company?.copromotionConvention ? (
                <>
                  {copromotionConventionDocument ? (
                    <GedFile
                      id={copromotionConventionDocument.id}
                      title={copromotionConventionDocument.title}
                    />
                  ) : (
                    t("fetch-document-error")
                  )}
                </>
              ) : null}
            </>
          )}
        </div>
      </div>

      <PermissionsGuard requiredRoles={["read:copromoter"]}>
        <h2 className="text-primary-dark font-semibold mt-4">
          {t("copromoters")}
        </h2>
        <div>
          {copromoters && copromoters.length === 0 ? (
            <PermissionsGuard requiredRoles={["create:copromoter"]}>
              <CreateCopromoter operationId={id} />
            </PermissionsGuard>
          ) : (
            <>
              {copromoters?.map((coprom) => (
                <EditCopromoter copromoter={coprom} key={coprom.id} />
              ))}
              {copromoters && copromoters.length <= 3 ? (
                <PermissionsGuard requiredRoles={["create:copromoter"]}>
                  <CreateCopromoter operationId={id} />
                </PermissionsGuard>
              ) : null}
            </>
          )}
        </div>
      </PermissionsGuard>
    </Box>
  )
}
