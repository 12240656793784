import { IIconType } from "shared/types/global.type"

function Menu({ width = 24, height = 24, color = "#00304D" }: IIconType) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
      <path
        fill={color}
        d="M3.875 18.175q-.45 0-.762-.312Q2.8 17.55 2.8 17.1q0-.425.313-.75.312-.325.762-.325h16.3q.45 0 .763.325.312.325.312.75 0 .45-.312.763-.313.312-.763.312Zm0-5.125q-.45 0-.762-.313-.313-.312-.313-.762t.313-.763q.312-.312.762-.312h16.3q.45 0 .763.312.312.313.312.763t-.312.762q-.313.313-.763.313Zm0-5.075q-.45 0-.762-.325Q2.8 7.325 2.8 6.9q0-.45.313-.763.312-.312.762-.312h16.3q.45 0 .763.312.312.313.312.763 0 .425-.312.75-.313.325-.763.325Z"
      />
    </svg>
  )
}

export default Menu
