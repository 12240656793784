import classNames from "classnames"
import { t } from "i18next"
import { orderBy } from "lodash"
import { useEffect, useState } from "react"
import { SortArg, sort } from "utils/sort"

type ItemWithSeparator<T> = T & { [key: string]: any }

function LightTable<T extends object, E extends object = T & { id: string }>({
  headers,
  items,
  totalItems,
  renderTotal,
  renderRow,
  renderSeparator,
  className,
  headerClassName,
  hasNestedArray,
  showTotal = true,
  overflowAuto = true,
  isTight = false,
  sortableFields,
  separator,
}: React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
> & {
  headers: (string | (() => string))[]
  items: T[] | E[]
  totalItems?: { title: string; id: number }[]
  renderTotal?: (totalItem: string, index: number) => React.ReactNode
  renderRow: (item: T | E, index: number) => React.ReactNode
  renderSeparator?: (item: T | E, index: number) => React.ReactNode
  className?: string
  headerClassName?: string
  hasNestedArray?: boolean
  showTotal?: boolean
  overflowAuto?: boolean
  isTight?: boolean
  sortableFields?: { key: string; value: string[] }[]
  separator?: string
}) {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")
  const [sortColumn, setSortColumn] = useState<string>()
  const [sortItems, setSortItems] = useState<T[] | E[]>(items)

  useEffect(() => {
    setSortItems(items)
  }, [items])

  const sortTable = (fields: SortArg<T | E>[]) => {
    const total = sortItems.filter((f): f is T | E => {
      if ("id" in f) return f.id === "TOTAL"
      return false
    })

    const withoutTotal = sortItems.filter((f): f is T => {
      if ("id" in f) return f.id !== "TOTAL"
      return true
    })

    let sorted: T[]
    sorted = sort(withoutTotal, fields, sortOrder) as T[]

    // si séparateur, trie sur séparateur d'abord
    if (separator) {
      const separatorSort: SortArg<T | E> = separator as SortArg<T | E>
      sorted = orderBy(withoutTotal, separatorSort, "asc")
    }

    setSortItems(sorted.concat(total as T[]))
  }

  const handleSort = (header: string) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
    setSortColumn(header)
    const fields: string[] | string[][] = sortableFields?.find(
      (f) => f.key === header,
    )?.value!
    sortTable(fields as SortArg<T | E>[])
  }

  const displaySortOrder = (header: string) => {
    if (
      sortOrder &&
      sortableFields &&
      sortableFields.find((f) => f.key === header) &&
      header === sortColumn
    ) {
      return sortOrder === "asc" ? "↑" : "↓"
    }
    return null
  }

  let key = 0

  return (
    <div className={overflowAuto ? "Table__Container" : "overflow-visible"}>
      <table className={classNames("Table", className)}>
        <thead className={classNames(isTight ? "Table__Head__Tight" : "")}>
          <tr className="text-left">
            {headers.map((header) => (
              <th
                className={classNames(
                  "Table__Head Table__Top_Border",
                  headerClassName,
                  sortableFields?.find(
                    (f) =>
                      f.key ===
                      (typeof header === "string" ? header : header()),
                  )
                    ? "cursor-pointer"
                    : "",
                  className?.includes("table-auto") ? "" : "w-52",
                )}
                key={`header-${typeof header === "string" ? header : header()}`}
                onClick={() =>
                  handleSort(typeof header === "string" ? header : header())
                }
              >
                {typeof header === "string" ? header : header()}
                {displaySortOrder(
                  typeof header === "string" ? header : header(),
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={classNames(isTight ? "Table__Body__Tight" : "")}>
          {renderTotal && (
            <tr className="Table__Total">
              {totalItems?.map((totalItem, index) => (
                <td
                  key={`${totalItem.title}-${totalItem.id}`}
                  className="Table__Body_Row"
                >
                  {renderTotal(totalItem.title, index)}
                </td>
              ))}
            </tr>
          )}
          {sortItems.map((item, index) => {
            key += 1
            return hasNestedArray ? (
              <>{renderRow(item, index)}</>
            ) : (
              <>
                {separator &&
                  (index === 0 ||
                    (sortItems[index - 1] as ItemWithSeparator<T>)[
                      separator
                    ] !== (item as ItemWithSeparator<T>)[separator]) && (
                    <tr key={`separator-${key}`}>
                      <td colSpan={headers.length} className="bg-primary-light">
                        <span className="text-white">
                          {renderSeparator && renderSeparator(item, index)}
                        </span>
                      </td>
                    </tr>
                  )}
                <tr
                  className={classNames(
                    "Table__Body_Row hover:bg-gray-100 transition-colors",
                  )}
                  key={`table-${key}`}
                >
                  {renderRow(item, index)}
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
      {showTotal && (
        <p className="p-2 text-sm text-sky-900 flex">
          {showTotal && (
            <>{`${items.length} ${t("lightTable.resultsFound")}`}</>
          )}
        </p>
      )}
    </div>
  )
}

export default LightTable
