import {
  useGetTreeByProjectId,
  useGetTreeByRegistrationNumbers,
} from "core/ged/query-hooks/useGed"
import { useEffect, useState } from "react"
import {
  TDataFormatedTree,
  TFormatedTree,
  TGEDTree,
  TGEDTreeDocument,
} from "shared/types/ged.type"

/**
 * Permet de reconstruire l'arborescence du fichier dans le cas d'un fichier dans un sous-dossier
 * sous-dossier1-sous-dossier2-sous-dossier3
 * @param hasSearchText : Présence ou nom de recherche textuelle (Il y a un tricks pour afficher les fichiers présents dans un sous-dossier lors d'une recherche)
 * @param document
 * @param defaultParent : Arborescence classique lorsqu'il n'y a pas de sous-dossier
 * @returns
 */
const setParent = (
  hasSearchText: boolean,
  document: TGEDTreeDocument,
  defaultParent: string,
  subChapterName: string,
): string => {
  if (!hasSearchText) {
    if (document.subFolder1 && !document.subFolder2 && !document.subFolder3)
      return `${subChapterName}.${document.subFolder1}`

    if (document.subFolder1 && document.subFolder2 && !document.subFolder3)
      return `${subChapterName}.${document.subFolder1}-${document.subFolder2}`

    if (document.subFolder1 && document.subFolder2 && document.subFolder3)
      return `${subChapterName}.${document.subFolder1}-${document.subFolder2}-${document.subFolder3}`
  }

  return defaultParent
}

/**
 * Permet de changer le nom du document pour prendre en compte son arborescence lors de la recherche
 * Le titre des documents présents dans un sous-dossier sont renvoyés avec leur arborescence
 */
const setDocumentTitle = (
  hasSearchText: boolean,
  document: TGEDTreeDocument,
): string => {
  if (hasSearchText) {
    if (document.subFolder1 && !document.subFolder2 && document.subFolder3)
      return `${document.subFolder1}/${document.title}`

    if (document.subFolder1 && document.subFolder2 && document.subFolder3)
      return `${document.subFolder1}/${document.subFolder2}/${document.title}`

    if (document.subFolder1 && document.subFolder2 && document.subFolder3)
      return `${document.subFolder1}/${document.subFolder2}/${document.subFolder3}/${document.title}`
  }

  return document.title
}

const useTree = (
  projectId?: number,
  registrationNumbers?: string[],
  view?: number,
) => {
  const [tree, setTree] = useState<TFormatedTree[] | []>([])
  const [searchText, setSearchText] = useState<string>("")
  let treeData: TGEDTree | undefined
  let isLoading: boolean = false
  let isError: boolean = false
  if (projectId) {
    const {
      data: t,
      isLoading: isTreeLoading,
      isError: isErrorTree,
    } = useGetTreeByProjectId(projectId, view, searchText)
    if (t) treeData = t
    isLoading = isTreeLoading
    isError = isErrorTree
  }

  if (registrationNumbers) {
    const {
      data: t,
      isLoading: isTreeLoading,
      isError: isErrorTree,
    } = useGetTreeByRegistrationNumbers(registrationNumbers, view, searchText)
    if (t) treeData = t
    isLoading = isTreeLoading
    isError = isErrorTree
  }

  const handleDrop = (newTreeData: TFormatedTree[]) => setTree(newTreeData)

  useEffect(() => {
    function filterTree() {
      const finalTree: TFormatedTree[] = []

      function addToFinalTree(
        id: string,
        parent: string | number,
        droppable: boolean,
        text: string,
        data?: TDataFormatedTree,
      ) {
        finalTree.push({
          id,
          parent,
          droppable,
          text,
          data: {
            id: data?.id,
            label: data?.label,
            isFile: data?.isFile,
            isDocumentType: data?.isDocumentType,
            type: data?.type,
            lastModifiedDate: data?.lastModifiedDate,
          },
        })
      }

      treeData?.chapters.map((t, index) => {
        addToFinalTree(
          `${index}-${t.name}`,
          0,
          !!t.subChapters.length,
          `${t.name} (${t.count})`,
        )

        return t?.subChapters?.map((subChapter, chapterIndex) => {
          addToFinalTree(
            `${chapterIndex}-${subChapter.name}`,
            `${index}-${t.name}`,
            !!subChapter.documentTypes.length,
            `${subChapter.name} (${subChapter.count})`,
          )

          return subChapter.documentTypes?.map(
            (documentType, documentTypeIndex) => {
              const data = {
                isFile: false,
                isDocumentType: true,
                id: `${documentType.id}`,
                label: documentType.name,
              }

              addToFinalTree(
                `${documentTypeIndex}-${documentType.name}`,
                `${chapterIndex}-${subChapter.name}`,
                true,
                `${documentType.name} (${documentType.count})`,
                data,
              )

              return documentType?.documents?.map(
                (document, documentsIndex) => {
                  const docData = {
                    id: document.id,
                    label: document.title,
                    isFile: true,
                    isDocumentType: false,
                    type: document.type,
                    lastModifiedDate: document.lastModifiedDate,
                  }

                  return addToFinalTree(
                    `doc-${documentsIndex}-${document.title}`,
                    setParent(
                      !!searchText,
                      document,
                      `${documentTypeIndex}-${documentType.name}`,
                      documentType.name,
                    ),
                    false,
                    setDocumentTitle(!!searchText, document),
                    docData,
                  )
                },
              )
            },
          )
        })
      })

      setTree(finalTree)
    }

    filterTree()
  }, [treeData, searchText])

  return { tree, isError, isLoading, handleDrop, setSearchText, setTree }
}

export default useTree
