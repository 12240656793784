import classNames from "classnames"
import Alert from "../Icons/Alert"

export type FormErrorMessageProps = {
  className?: string
  children: any
}

export function FormErrorMessage({
  children,
  className,
}: FormErrorMessageProps) {
  return (
    <div
      className={classNames(
        "Text__xs Text__Red_400 Flex Items__Center Position__Absolute",
        className,
      )}
    >
      <Alert width={20} height={20} color="#D72C0D" />
      {children}
    </div>
  )
}
