import { useEffect, useRef, useState } from "react"

interface IHeightClass {
  heightClass?: string
}

export default function DivStickyMenu({ heightClass = "pt-44" }: IHeightClass) {
  const [stickyClass, setStickyClass] = useState("")
  const ref = useRef<HTMLDivElement>(null)
  const stickNavbar = () => {
    if (window) {
      const windowHeight = window.scrollY
      const maxHeightScroll =
        document.documentElement.scrollHeight - window.innerHeight

      if (
        windowHeight > 0 &&
        maxHeightScroll > ref.current?.clientHeight! &&
        maxHeightScroll > 180
      ) {
        setStickyClass(heightClass)
      } else {
        setStickyClass("")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar)
    return () => window.removeEventListener("scroll", stickNavbar)
  }, [])

  return <p ref={ref} className={stickyClass} />
}
