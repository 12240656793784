import { PropsWithChildren, useMemo, useReducer } from "react"
import {
  STATUS_ASSEMBLY,
  STATUS_ENGAGEMENT_COMMITTEE,
  STATUS_LAND_COMMITTEE,
  STATUS_NEGOCIATION,
  STATUS_NOT_ACTED,
  STATUS_ON_DELIVERY,
  STATUS_ON_GOING,
  STATUS_PROSPECTION,
  STATUS_STANDBY,
  STATUS_WAITING_ASSEMBLY,
} from "shared/resources/operation.resources"
import ProgramSearchContext from "./ProgramsSearchContext"
import searchProgramsReducer, { TSearchState } from "./searchProgramsReducer"

interface IProgramsSearchProvider extends PropsWithChildren {}

export default function ProgramsSearchProvider(props: IProgramsSearchProvider) {
  const { children } = props

  const initialSearchState: TSearchState = {
    filters: {
      steps: [
        STATUS_PROSPECTION,
        STATUS_NEGOCIATION,
        STATUS_LAND_COMMITTEE,
        STATUS_ENGAGEMENT_COMMITTEE,
        STATUS_STANDBY,
        STATUS_WAITING_ASSEMBLY,
        STATUS_ASSEMBLY,
        STATUS_NOT_ACTED,
        STATUS_ON_GOING,
        STATUS_ON_DELIVERY,
      ],
      departments: [],
      regions: [],
      companies: [],
      users: [],
      types: [],
      skip: 0,
      take: 20,
      search: "",
      sortColumn: "",
      sortOrder: "",
      page: 1,
    },
    results: [],
  }

  const [state, dispatch] = useReducer(
    searchProgramsReducer,
    initialSearchState,
  )

  const memoiziedValues = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch],
  )

  return (
    <ProgramSearchContext.Provider value={memoiziedValues}>
      {children}
    </ProgramSearchContext.Provider>
  )
}
