import { useMutation, useQuery } from "@tanstack/react-query"
import {
  addSimplifiedProgram,
  deleteSimplifiedProgram,
  editSimplifiedProgram,
  getSimplifiedProgram,
  listSimplifiedPrograms,
} from "core/api/simplifed-programs"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TMutateSimplifiedProgram,
  TSimplifiedProgram,
} from "shared/types/simplified-programs.type"

function useGetSimplifiedPrograms() {
  return useQuery<TSimplifiedProgram[], CustomAxiosError>({
    queryKey: ["getSimplifiedPrograms"],
    queryFn: () => listSimplifiedPrograms(),
  })
}

function useGetSimplifiedProgram(simplifiedProgramId: number) {
  return useQuery<TSimplifiedProgram, CustomAxiosError>({
    queryKey: ["getSimplifiedProgram", simplifiedProgramId],
    queryFn: () => getSimplifiedProgram(simplifiedProgramId),
  })
}

function useAddSimplifiedProgram() {
  return useMutation<
    TSimplifiedProgram,
    CustomAxiosError,
    Partial<TMutateSimplifiedProgram>
  >({ mutationFn: (data) => addSimplifiedProgram(data) })
}

function useDeleteSimplifiedProgram(simplifiedProgramId: number) {
  return useMutation<unknown, CustomAxiosError>({
    mutationFn: () => deleteSimplifiedProgram(simplifiedProgramId),
  })
}

function useEditSimplifiedProgram(simplifiedProgramId: number) {
  return useMutation<
    TSimplifiedProgram,
    CustomAxiosError,
    Partial<TMutateSimplifiedProgram>
  >({ mutationFn: (data) => editSimplifiedProgram(simplifiedProgramId, data) })
}

export {
  useAddSimplifiedProgram,
  useDeleteSimplifiedProgram,
  useEditSimplifiedProgram,
  useGetSimplifiedProgram,
  useGetSimplifiedPrograms,
}
