import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { useGetEdvHome } from "core/query-hooks/useEdv"
import { t } from "i18next"

export interface IEdvHomeProps {
  businessUnitId?: string
}

export function EdvHome(props: IEdvHomeProps) {
  const { businessUnitId } = props
  const { data: edv, isPending, error } = useGetEdvHome(businessUnitId)

  function getPreviousMonth() {
    const currentMonth = new Date().getMonth()
    const previousMonthIndex = (currentMonth - 1 + 12) % 12
    const monthNames = [
      t("january-abv"),
      t("february-abv"),
      t("march-abv"),
      t("april-abv"),
      t("may-abv"),
      t("june-abv"),
      t("july-abv"),
      t("august-abv"),
      t("september-abv"),
      t("october-abv"),
      t("november-abv"),
      t("december-abv"),
    ]
    return monthNames[previousMonthIndex]
  }

  const prevMonth = getPreviousMonth()

  const headers = [
    () => "",
    () => `${t("cumulative-end")} ${prevMonth}`,
    () => t("january-abv"),
    () => t("february-abv"),
    () => t("march-abv"),
    () => t("april-abv"),
    () => t("may-abv"),
    () => t("june-abv"),
    () => t("july-abv"),
    () => t("august-abv"),
    () => t("september-abv"),
    () => t("october-abv"),
    () => t("november-abv"),
    () => t("december-abv"),
    () => t("total"),
  ]

  const currentYear = new Date().getFullYear()
  const objectif = edv?.find((edvLine) => edvLine.code === "objectif")
  const currentYearEdv = edv?.find(
    (edvLine) =>
      typeof edvLine.code === "number" && edvLine.code === Number(currentYear),
  )
  const cumulColor =
    objectif && currentYearEdv && objectif.cumul > currentYearEdv.cumul
      ? "#FF2E00"
      : "#00DC06"

  return (
    <>
      {isPending ? <Spinner /> : null}
      {error ? <p className="text-xs text-red-500">{t("data-error")}</p> : null}
      {edv && cumulColor ? (
        <div className="rounded-lg shadow-lg">
          <div className="Light_Table_Edv__Wrapper Table__Wrapper_NoTop">
            <div className="Table__Container">
              <table className="Table table-auto">
                <thead>
                  <tr className="text-left">
                    {headers.map((header, index) => (
                      <th
                        className={classNames(
                          "Table__Head__Home Table__Top_Border text-right border-b border-gray-200",
                          {
                            "w-36": index === 0,
                          },
                        )}
                        key={`header-${header}`}
                      >
                        {header()}
                      </th>
                    ))}
                  </tr>
                </thead>
                {edv.length > 0 ? (
                  <tbody className="Table__Body__Home">
                    {edv.map((item) => {
                      return (
                        <tr
                          key={item.label}
                          className={classNames({
                            "font-semibold bg-gray-100": item.code === 2024,
                          })}
                        >
                          <td className="text-left w-48">{item.label}</td>
                          <td
                            className="text-right"
                            style={
                              cumulColor && item.code === currentYear
                                ? { color: cumulColor }
                                : {}
                            }
                          >
                            {item.cumul
                              ? item.cumul.toLocaleString("fr-FR")
                              : 0}
                          </td>
                          <td className="text-right">
                            {item.janvier
                              ? item.janvier.toLocaleString("fr-FR")
                              : 0}
                          </td>
                          <td className="text-right">
                            {item.fevrier
                              ? item.fevrier.toLocaleString("fr-FR")
                              : 0}
                          </td>
                          <td className="text-right">
                            {item.mars ? item.mars.toLocaleString("fr-FR") : 0}
                          </td>
                          <td className="text-right">
                            {item.avril
                              ? item.avril.toLocaleString("fr-FR")
                              : 0}
                          </td>
                          <td className="text-right">
                            {item.mai ? item.mai.toLocaleString("fr-FR") : 0}
                          </td>
                          <td className="text-right">
                            {item.juin ? item.juin.toLocaleString("fr-FR") : 0}
                          </td>
                          <td className="text-right">
                            {item.juillet
                              ? item.juillet.toLocaleString("fr-FR")
                              : 0}
                          </td>
                          <td className="text-right">
                            {item.aout ? item.aout.toLocaleString("fr-FR") : 0}
                          </td>
                          <td className="text-right">
                            {item.septembre
                              ? item.septembre.toLocaleString("fr-FR")
                              : 0}
                          </td>
                          <td className="text-right">
                            {item.octobre
                              ? item.octobre.toLocaleString("fr-FR")
                              : 0}
                          </td>
                          <td className="text-right">
                            {item.novembre
                              ? item.novembre.toLocaleString("fr-FR")
                              : 0}
                          </td>
                          <td className="text-right">
                            {item.decembre
                              ? item.decembre.toLocaleString("fr-FR")
                              : 0}
                          </td>
                          <td className="text-right">
                            {item.total
                              ? item.total.toLocaleString("fr-FR")
                              : 0}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                ) : (
                  <tbody className="text-center p-2">
                    <tr>
                      <td colSpan={headers.length}>
                        {t("no-data-found-message")}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
