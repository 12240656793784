import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { useUpdateStudyRequest } from "core/query-hooks/useStudyRequests"
import { t } from "i18next"
import { useState } from "react"
import { RiPencilFill } from "react-icons/ri"
import { toast } from "react-toastify"
import { TStudyRequest } from "shared/types/study.type"
import {
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "./studyRequest.resources"

export interface IEditVersionModalProps {
  studyRequest: TStudyRequest
}

export function EditVersionModal(props: IEditVersionModalProps) {
  const { studyRequest } = props
  const updateStudyRequest = useUpdateStudyRequest(studyRequest.id)
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const queryClient = useQueryClient()
  let studyRequestLabel = ""
  switch (studyRequest.typeId) {
    case TYPE_FEASIBILITY:
      studyRequestLabel = `${t("studyRequest.versionLabel")} *`
      break
    case TYPE_INFOGRAPHY:
      studyRequestLabel = `${t("studyRequest.infography-versionLabel")} *`
      break
    case TYPE_COST_OF_WORK:
      studyRequestLabel = `${t("studyRequest.cow-versionLabel")} *`
      break
    default:
  }
  const [version, setVersion] = useState<string>(
    studyRequest?.version || "v0.0.1",
  )
  const [versionError, setVersionError] = useState<boolean>(false)
  function invalidateQueriesData() {
    queryClient.invalidateQueries({
      queryKey: ["getCostOfWork", studyRequest.id!],
    })
    queryClient.invalidateQueries({
      queryKey: ["getFeasibility", studyRequest.id!],
    })
    queryClient.invalidateQueries({
      queryKey: ["getInfography", studyRequest.id!],
    })
    queryClient.invalidateQueries({
      queryKey: ["listStudyRequests", studyRequest.projectId!],
    })
  }

  const onUpdateVersion = (updatedVersion: string, displayToast = true) => {
    if (!updatedVersion) {
      toast.warning(`${t("cannot-be-empty")}`)
    } else {
      updateStudyRequest.mutate(
        {
          version: updatedVersion,
        },
        {
          onSuccess: () => {
            if (displayToast) {
              updatedVersion
                ? toast.success(`${t("studyRequest.toast.versionSuccess")}`, {
                    toastId: "updateVersion",
                  })
                : toast.success(`${t("studyRequest.toast.versionUpdated")}`, {
                    toastId: "updateVersion",
                  })
            }
            invalidateQueriesData()
            toggleModal()
          },
          onError: (err) =>
            toast.error(
              `${t("studyRequest.toast.commentError")} ${
                err.response?.data.message
              }`,
            ),
        },
      )
    }
  }

  return (
    <div>
      <button
        type="button"
        onClick={toggleModal}
        className="ml-4 text-cyan-800 rounded-lg flex items-center justify-center p-1 border border-slate-100 hover:bg-slate-100"
      >
        <RiPencilFill size={20} />
      </button>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("edit-version")}`}
      >
        <div className="Text__Field_Container">
          <div className="col-span-2">
            <label htmlFor="version">
              {studyRequestLabel}
              <input
                id="version"
                className="Text__Field"
                type="string"
                placeholder={`${t("studyRequest.versionLabel")}`}
                value={version}
                onChange={(e) => {
                  setVersion(e.target.value)
                  setVersionError(e.target.value === "")
                }}
              />
              {versionError ? (
                <span className="text-red-500 text-xs">
                  {`${t("required-version")}`}
                </span>
              ) : null}
            </label>
          </div>

          <div className="Modal__Footer flex justify-between mt-4">
            <Button
              type="button"
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => toggleModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              type="submit"
              marginLeft={24}
              size="medium"
              mode="primary"
              isLoading={false}
              onClick={() => onUpdateVersion(version)}
            >
              {`${t("confirm")}`}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
