import { Button } from "components/Button/Button"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { useState } from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { TSuspensiveCondition } from "shared/types/suspensiveCondition.type"

registerLocale("fr", fr)
interface IAddSuspensiveConditionModalProps {
  suspensiveCondition?: TSuspensiveCondition
  addSuspensiveConditions: (
    label: string,
    isDefinitive: boolean,
    date?: Date | null,
  ) => void
}

export default function AddSuspensiveConditionsModal({
  suspensiveCondition,
  addSuspensiveConditions,
}: IAddSuspensiveConditionModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  const [date, setDate] = useState<null | Date | undefined>(
    suspensiveCondition?.date,
  )
  const [label, setLabel] = useState<string>("")
  const [isDefinitive, setIsDefinitive] = useState<boolean | undefined>(false)

  const handleToggleIsDefinitive = () => {
    setIsDefinitive(!isDefinitive)
  }

  const onAddSuspensiveCondition = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    addSuspensiveConditions(label, isDefinitive!, date)
    setDate(null)
    setLabel("")
    setIsDefinitive(false)
    toggleModal()
  }

  return (
    <>
      <Button onClick={toggleModal} size="small" mode="primary">
        {`${t("add")}`}
      </Button>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("add-suspensive-condition")}`}
      >
        <form
          onSubmit={(e) => onAddSuspensiveCondition(e)}
          className="Text__Field_Container"
        >
          <div className="Text__Field_Container mb-6">
            <label htmlFor="description">
              {`${t("description")} *`}
              <input
                id="description"
                className="Text__Field"
                value={label}
                required
                onChange={(e) => setLabel(e.target.value)}
                type="text"
                placeholder={`${t("description")}`}
              />
            </label>

            <div className="grid sm:grid-cols-3 sm:gap-x-4 grid-cols-1">
              <div className="sm:col-span-2 flex flex-col mt-2">
                <label htmlFor="date">{`${t("date")}`} </label>
                <CustomDatePicker
                  id="date"
                  locale={fr}
                  className="Text__Field"
                  placeholderText={`${t("date")}`}
                  selected={date}
                  onChange={(changedDate) => setDate(changedDate)}
                />
              </div>

              <div>
                <label htmlFor="actions-toggle-btn">
                  {`${t("realized")}`}
                  <div className="flex items-center justify-start sm:mt-2">
                    <ToggleSwitch
                      id="actions-toggle-btn"
                      name="toggle-not-done-actions"
                      disabled={false}
                      checked={isDefinitive!}
                      onChange={handleToggleIsDefinitive}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="Modal__Footer">
            <Button
              type="button"
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => toggleModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              type="submit"
              marginLeft={24}
              size="medium"
              mode="primary"
              isLoading={false}
            >
              {`${t("confirm")}`}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
