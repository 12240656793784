import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import { Modal } from "components/Modal/Modal"
import { useAddBank } from "core/query-hooks/useBanks"
import { t } from "i18next"
import { FormEvent, useState } from "react"
import { toast } from "react-toastify"

export interface IAddBankModalProps {
  isAddBankModalShowed: boolean
  toggleBankModal: () => void
}

export function AddBankModal(props: IAddBankModalProps) {
  const { isAddBankModalShowed, toggleBankModal } = props

  const addBankMutation = useAddBank()

  const [bankName, setBankName] = useState("")

  const queryClient = useQueryClient()

  async function handleSubmitPurchaseLandDateForm(
    e: FormEvent<HTMLFormElement>,
  ) {
    e.stopPropagation()
    e.preventDefault()

    addBankMutation.mutate(
      {
        label: bankName,
      },
      {
        onSuccess() {
          toast.success(t("success-add-bank"))
          queryClient.invalidateQueries({ queryKey: ["banks"] })
          toggleBankModal()
        },
        onError(error) {
          toast.error(
            `${t("error-add-bank")} : ${error.response?.data.message}`,
          )
        },
      },
    )
  }

  return (
    <Modal
      isShowing={isAddBankModalShowed}
      closeModal={toggleBankModal}
      title={`${t("add-bank-modal-title")}`}
    >
      <form
        className="Text__Field_Container"
        onSubmit={(e) => handleSubmitPurchaseLandDateForm(e)}
      >
        <div className="mt-2 mb-2">
          <label htmlFor="label-add-bank">
            {t("label-add-bank")}
            <input
              id="label-add-bank"
              required
              onChange={(e) => setBankName(e.target.value)}
              type="text"
              className="Text__Field"
              placeholder="Ex : BNP"
              name="bank-title"
            />
          </label>
        </div>
        <div className="flex items-center justify-between mt-6">
          <Button
            onClick={() => toggleBankModal()}
            size="small"
            mode="secondary"
          >
            {`${t("cancel")}`}
          </Button>

          <Button
            isLoading={addBankMutation.isPending}
            type="submit"
            size="small"
            mode="primary"
          >
            {`${t("create")}`}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
