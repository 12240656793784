import Spinner from "components/Spinner/Spinner"
import useDepartments from "core/query-hooks/useDepartments"
import { t } from "i18next"
import { useState } from "react"
import { TIdLabel } from "shared/types/global.type"
import { addLeadingZero } from "utils/departments"
import DropdownFilters from "../DropdownFilter"
import useProgramsSearch from "../useProgramsSearch"

interface DepartmentsFilterProps {
  isOperation: boolean
}

export default function DepartmentsFilter({
  isOperation,
}: DepartmentsFilterProps) {
  const {
    data: departmentData,
    isLoading: isLoadingDp,
    error: errorDp,
  } = useDepartments(isOperation)

  const { state, dispatch } = useProgramsSearch()
  const { departments } = state.filters

  if (isLoadingDp) return <Spinner />

  if (errorDp || !departmentData)
    return <p>{errorDp?.response?.data.message}</p>

  const [searchWord, setSearchWord] = useState<string>("")
  function handleChangeOptions(search: string) {
    setSearchWord(search)
  }

  const handleClickOutside = () => {
    setSearchWord("")
  }

  const filtered = departmentData.filter((department) => {
    const name = addLeadingZero(department.label)
    return name.includes(searchWord)
  })

  const handleDpClick = (department: TIdLabel) => {
    if (department.id === -1) {
      const shouldSelectAll = departments.length !== departmentData.length
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...state.filters,
          departments: shouldSelectAll
            ? departmentData.map((item) => item.id)
            : [],
          skip: 0,
          page: 1,
        },
      })
    } else {
      const isDepartSelected = (
        state.filters.departments as number[]
      )?.includes(department.id)

      if (isDepartSelected) {
        dispatch({
          type: "SET_FILTERS",
          payload: {
            ...state.filters,
            departments: state.filters.departments?.filter(
              (departmentId) => departmentId !== department.id,
            ),
            skip: 0,
            page: 1,
          },
        })
      } else {
        dispatch({
          type: "SET_FILTERS",
          payload: {
            ...state.filters,
            departments: [...state.filters.departments, department.id],
            skip: 0,
            page: 1,
          },
        })
      }
    }
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900">
              {t("county")}
              {departments.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-gray-800 bg-gray-100 rounded-full">
                  {departments.length}
                </div>
              )}
            </div>
          )}
          options={filtered}
          renderOption={(department, index) => (
            <div role="menu">
              <li
                onClick={() => handleDpClick(department)}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id={`menu-item-${index}`}
                key={`menu-item-${index}`}
              >
                <input
                  aria-label="department-checkbox"
                  className="mr-2"
                  type="checkbox"
                  readOnly
                  checked={
                    department.id === -1
                      ? departmentData.length === departments.length
                      : !!state.filters.departments?.find(
                          (departmentId) => departmentId === department.id,
                        )
                  }
                />
                {addLeadingZero(department.label)}
              </li>
            </div>
          )}
          keyExtractor={(department) => department.id}
          handleChangeOptions={(searchText) => {
            handleChangeOptions(searchText)
          }}
          onClickOutside={handleClickOutside}
        />
      </div>
    </div>
  )
}
