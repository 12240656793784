import DisplayError from "components/Display/DisplayError"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import QueryBoundaries from "core/QueryBoundaries"
import { useGetEdvLastUpdateDate } from "core/query-hooks/useEdv"
import { format, subHours, toDate } from "date-fns"
import { t } from "i18next"
import { useState } from "react"
import EdvDetail from "./EdvDetail"
import EdvOverview from "./EdvOverview"
import EdvSynthesis from "./EdvSynthesis"
import BusinessUnitsFilter from "./filters/BusinessUnitsFilter"
import CaTypeFilter from "./filters/CaTypeFilter"
import CompaniesFilter from "./filters/CompaniesFilter"
import DepartmentsFilter from "./filters/DepartmentsFilter"
import DetailFilter from "./filters/DetailFilter"
import ProductsFilter from "./filters/ProductsFilter"
import SelectedBusinessUnitsFilters from "./filters/SelectedBusinessUnitsFilters"
import SelectedCompaniesFilters from "./filters/SelectedCompaniesFilters"
import SelectedDepartmentsFilters from "./filters/SelectedDepartmentsFilters"
import SelectedProductsFilters from "./filters/SelectedProductsFilters"
import YearFilter from "./filters/YearFilter"

export default function PdpPage() {
  const [businessUnits, setBusinessUnits] = useState<number[]>([])
  const [departments, setDepartments] = useState<string[]>([])
  const [companies, setCompanies] = useState<number[]>([1, 2, 4]) // Ne pas afficher Novalys par défaut
  const [products, setProducts] = useState<string[]>([])
  const [year, setYear] = useState<number>(new Date().getFullYear())
  const [caType, setCaType] = useState<string>("CA_agrege")
  const [detail, setDetail] = useState<string>("operation")
  const { data: lastUpdate, isLoading, error } = useGetEdvLastUpdateDate()

  const formattedLastUpdate = lastUpdate
    ? subHours(toDate(lastUpdate), -new Date().getTimezoneOffset() / 60)
    : null

  return (
    <div className="p-4">
      <h2 className="col-span-6 font-semibold md:mb-0 Color__Primary pb-4">
        {t("steering")}
      </h2>
      <div className="flex items-center justify-between flex-wrap">
        <div className="flex flex-wrap mb-2 md:m-0 items-center">
          <QueryBoundaries loadingComponent={<Spinner />}>
            <div className="mr-2 mb-3 h-full">
              <BusinessUnitsFilter
                selectedBusinessUnits={businessUnits}
                onBusinessUnitsChange={setBusinessUnits}
              />
            </div>
            <div className="mr-2 mb-3 h-full">
              <DepartmentsFilter
                selectedDepartments={departments}
                onDepartmentsChange={setDepartments}
                isOperation
              />
            </div>
            <div className="mr-2 mb-3 h-full">
              <CompaniesFilter
                selectedCompanies={companies}
                onCompaniesChange={setCompanies}
              />
            </div>
            <div className="mr-2 mb-3 h-full">
              <ProductsFilter
                selectedProducts={products}
                onProductsChange={setProducts}
              />
            </div>
            <div className="mr-2 mb-3 h-full">
              <YearFilter selectedYear={year} onYearChange={setYear} />
            </div>
            <div className="mr-2 mb-3 h-full w-full lg:w-64">
              <CaTypeFilter
                onChangeCa={() =>
                  setCaType(caType === "CA_net" ? "CA_agrege" : "CA_net")
                }
                checkedCa={caType === "CA_agrege"}
              />
            </div>
            <div className="mr-2 mb-3 h-full w-full lg:w-64">
              <DetailFilter
                onChangeDetail={() =>
                  setDetail(
                    detail === "tranche_commerciale"
                      ? "operation"
                      : "tranche_commerciale",
                  )
                }
                checkedDetail={detail === "operation"}
              />
            </div>
          </QueryBoundaries>
        </div>
        <div className="text-sm text-gray-500">
          {formattedLastUpdate
            ? `${t("updated-on")} : ${format(formattedLastUpdate, "dd/MM/yyyy HH:mm")}`
            : null}
          {isLoading ? <Spinner /> : null}
          <DisplayError
            errorMessage={t("retrieve-update-date-error")}
            error={error}
          />
        </div>
      </div>

      <QueryBoundaries loadingComponent={<Spinner />}>
        <div className="flex items-center flex-wrap">
          <div className="mt-2">
            <SelectedBusinessUnitsFilters
              selectedBusinessUnits={businessUnits}
              onBusinessUnitsChange={setBusinessUnits}
            />
          </div>
          <div className="mt-2">
            <SelectedDepartmentsFilters
              selectedDepartments={departments}
              onDepartmentsChange={setDepartments}
              isOperation
            />
          </div>
          <div className="mt-2">
            <SelectedCompaniesFilters
              selectedCompanies={companies}
              onCompaniesChange={setCompanies}
            />
          </div>
          <div className="mt-2">
            <SelectedProductsFilters
              selectedProducts={products}
              onProductsChange={setProducts}
            />
          </div>
        </div>
      </QueryBoundaries>

      <div className="mt-2">
        <EdvOverview
          year={year}
          caType={caType}
          businessUnits={businessUnits}
          departments={departments}
          companies={companies}
          products={products}
        />
      </div>

      <div className="mt-2">
        <Tabs>
          <TabContent title={`${t("synthesis")}`} key="synthese">
            <div className="mt-2">
              <EdvSynthesis
                year={year}
                caType={caType}
                businessUnits={businessUnits}
                departments={departments}
                companies={companies}
                products={products}
              />
            </div>
          </TabContent>
          <TabContent title={`${t("product")}`} key="produit">
            <div className="mt-2">
              <EdvDetail
                year={year}
                detail={detail}
                caType={caType}
                businessUnits={businessUnits}
                departments={departments}
                companies={companies}
                products={products}
                tab="produit"
              />
            </div>
          </TabContent>
          <TabContent title={`${t("department")}`} key="secteur">
            <div className="mt-2">
              <EdvDetail
                year={year}
                detail={detail}
                caType={caType}
                businessUnits={businessUnits}
                departments={departments}
                companies={companies}
                products={products}
                tab="secteur"
              />
            </div>
          </TabContent>
        </Tabs>
      </div>
    </div>
  )
}
