import { Button } from "components"
import Box from "components/Box/Box"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { t } from "i18next"
import { FaQuestionCircle } from "react-icons/fa"
import { IoEarthSharp } from "react-icons/io5"
import { PiTreeEvergreenDuotone } from "react-icons/pi"
import { TEnvironment } from "shared/types/environment.type"
import { EcoRangeSlide } from "./EcoRangeSlide"

export interface IDisplayEnvironmentProps {
  environment: TEnvironment
  setIsEdit: (value: boolean) => void
}

export default function DisplayEnvironement(props: IDisplayEnvironmentProps) {
  const { environment, setIsEdit } = props
  return (
    <>
      <PermissionsGuard requiredRoles={["update:environment"]}>
        <div className="flex items-center justify-end">
          <Button onClick={() => setIsEdit(true)} size="small" mode="primary">
            {t("edit")}
          </Button>
        </div>
      </PermissionsGuard>
      <>
        <div className="flex items-center justify-start pb-2 text-green-700">
          <IoEarthSharp size={38} color="#008000" className="pr-3" />
          <h2 className="text-xl text-green-700">{`${t("carbon-climate-indicators")}`}</h2>
        </div>
        <Box>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-4 gap-x-12">
            <div className="Text__Field_Container">
              <label htmlFor="re-thresold">
                {`${t("re-thresold")}`}
                <p className="Color__Primary text-lg">
                  {environment.reThresold ? environment.reThresold.label : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="bbca-ce-labelisation">
                {`${t("bbca-ce-labelisation")}`}
                <p className="Color__Primary text-lg">
                  {environment.bbcaLabel ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <TooltipWithHover
                message={`Equivalence RE/kg eq CO₂/m² :\nRE2020 = 740\nRE2025 = 650\nRE2028 = 580\nRE2031 = 490`}
              >
                <label htmlFor="ic-construction" className="flex items-center">
                  {`${t("ic-construction")}`}
                  <FaQuestionCircle className="ml-1" size={16} />
                </label>
                <p className="Color__Primary text-lg">
                  {environment.reThresold
                    ? `${environment.reThresold.IcConstruction} kg eq CO₂/m²`
                    : "-"}
                </p>
              </TooltipWithHover>
            </div>

            <div className="md:col-span-1" />
          </div>
        </Box>

        <div className="flex items-center justify-start pt-2 pb-1 text-green-700">
          <PiTreeEvergreenDuotone size={40} color="008000" className="pr-3" />
          <h2 className="text-xl text-green-700">{`${t("biodiversity-risk-adaptation-indicators")}`}</h2>
        </div>
        <Box>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-4 gap-x-12">
            <div className="Text__Field_Container">
              <label htmlFor="ecologist">
                {`${t("ecologist")}`}
                <p className="Color__Primary text-lg">
                  {environment.ecologist ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="md:col-span-3" />

            <div className="Text__Field_Container">
              <label htmlFor="cost-of-work-vrd">
                {`${t("cost-of-work-vrd")}`}
                <p className="Color__Primary text-lg">
                  {environment.costOfWorkVrd
                    ? `${environment.costOfWorkVrd.toLocaleString("fr-FR")} €`
                    : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="green-spaces-budget">
                {`${t("green-spaces-budget")}`}
                <p className="Color__Primary text-lg">
                  {environment.greenSpacesBudget
                    ? `${environment.greenSpacesBudget.toLocaleString("fr-FR")} €`
                    : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="green-spaces-building-part">
                {`${t("green-spaces-building-part")}`}
                <p className="Color__Primary text-lg">
                  {environment.greenSpacesBudget && environment.costOfWorkVrd
                    ? `${(
                        (environment.greenSpacesBudget /
                          environment.costOfWorkVrd) *
                        100
                      ).toFixed(2)} %`
                    : "-"}
                </p>
              </label>
            </div>
            <div className="md:col-span-1" />

            <div className="Text__Field_Container">
              <label htmlFor="refuge-area">
                {`${t("refuge-area")}`}
                <p className="Color__Primary text-lg">
                  {environment.refugeArea ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="freshness-oasis">
                {`${t("freshness-oasis")}`}
                <p className="Color__Primary text-lg">
                  {environment.freshnessOasis ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="vegetated-fences">
                {`${t("vegetated-fences")}`}
                <p className="Color__Primary text-lg">
                  {environment.vegetatedFences ? `${t("yes")}` : `${t("no")}`}
                </p>
              </label>
            </div>
            <div className="md:col-span-1" />

            <div className="Text__Field_Container">
              <label htmlFor="existing-trees">
                {`${t("existing-trees")}`}
                <p className="Color__Primary text-lg">
                  {environment.existingTrees ? environment.existingTrees : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="deleted-trees">
                {`${t("deleted-trees")}`}
                <p className="Color__Primary text-lg">
                  {environment.deletedTrees ? (
                    <div className="flex items-center">
                      {environment.deletedTrees}{" "}
                      {environment.existingTrees ? (
                        <div className="font-semibold pl-1">
                          {`(${((environment.deletedTrees / environment.existingTrees) * 100).toFixed(0)} %)`}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="preserved-trees">
                {`${t("preserved-trees")}`}
                <p className="Color__Primary text-lg">
                  {environment.existingTrees ? (
                    <div className="flex items-center">
                      {environment.deletedTrees ? (
                        <>
                          {environment.existingTrees - environment.deletedTrees}{" "}
                          <div className="font-semibold pl-1">
                            {`(${(((environment.existingTrees - environment.deletedTrees) / environment.existingTrees) * 100).toFixed(0)} %)`}
                          </div>
                        </>
                      ) : (
                        <>
                          {environment.existingTrees}{" "}
                          <div className="font-semibold pl-1">100 %</div>
                        </>
                      )}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="replanted-trees">
                {`${t("replanted-trees")}`}
                <p className="Color__Primary text-lg">
                  {environment.replantedTrees
                    ? environment.replantedTrees
                    : "-"}
                </p>
              </label>
            </div>

            <div className="Text__Field_Container">
              <label htmlFor="parkings-number">
                {`${t("parkings-number")}`}
                <p className="Color__Primary text-lg">
                  {environment.parkingsNumber
                    ? environment.parkingsNumber
                    : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="permeable-parkings-number">
                {`${t("permeable-parkings-number")}`}
                <p className="Color__Primary text-lg">
                  {environment.permeableParkings ? (
                    <div className="flex items-center">
                      {environment.permeableParkings}{" "}
                      {environment.parkingsNumber ? (
                        <div className="font-semibold pl-1">
                          {`(${((environment.permeableParkings / environment.parkingsNumber) * 100).toFixed(0)} %)`}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="md:col-span-2" />

            <div className="Text__Field_Container">
              <label htmlFor="roof-terraces">
                {`${t("roof-terraces")}`}
                <p className="Color__Primary text-lg">
                  {environment.roofTerraces ? environment.roofTerraces : "-"}
                </p>
              </label>
            </div>
            <div className="Text__Field_Container">
              <label htmlFor="revegetation-roof-terraces">
                {`${t("revegetation-roof-terraces")}`}
                <p className="Color__Primary text-lg">
                  {environment.revegetationRoofTerraces ? (
                    <div className="flex items-center">
                      {environment.revegetationRoofTerraces}{" "}
                      {environment.roofTerraces ? (
                        <div className="font-semibold pl-1">
                          {`(${((environment.revegetationRoofTerraces / environment.roofTerraces) * 100).toFixed(0)} %)`}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </label>
            </div>
            <div className="md:col-span-2" />

            <div className="Text__Field_Container">
              <label htmlFor="cbs">
                {`${t("cbs")}`}
                {environment.cbs !== null && environment.cbs !== undefined ? (
                  <div className="pt-1">
                    <EcoRangeSlide value={environment.cbs} />
                  </div>
                ) : (
                  <div>-</div>
                )}
              </label>
            </div>
            <div className="md:col-span-3" />
          </div>
        </Box>
      </>
    </>
  )
}
