import classNames from "classnames"
import { FormErrorMessage } from "components/Form/FormErrorMessage"
import { Control, Controller, FieldValues, Path } from "react-hook-form"
import Select from "react-select"
import "./SelectFieldForm.scss"

export default function SelectFieldForm<T extends FieldValues>({
  control,
  options,
  className,
  name,
  label,
  isDisabled = false,
  placeholder,
  noOptionsMessage,
}: {
  control: Control<T>
  className?: string
  name: Path<T>
  label: string
  isDisabled?: boolean
  placeholder?: string
  options: { label: string; value: string | number }[] | undefined
  noOptionsMessage: () => string
}) {
  return (
    <div className="SelectFieldForm_Container">
      <label htmlFor={name}>
        {label}
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, ref }, fieldState: { error } }) => (
            <>
              <Select
                isDisabled={isDisabled}
                ref={ref}
                styles={{
                  container: (styles) => ({
                    ...styles,
                    minWidth: "100%",
                  }),
                  control: (styles) => ({
                    ...styles,
                    height: "50px",
                    borderRadius: "8px",
                    border: "1px solid #e5e7eb",
                    backgroundColor: "#F9FAFB",
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    opacity: 0.7,
                  }),
                }}
                name={name}
                isSearchable
                aria-label={label}
                className={classNames([
                  error ? "container_error" : "container",
                  className,
                ])}
                placeholder={placeholder}
                isMulti={false}
                noOptionsMessage={noOptionsMessage}
                onChange={(e) => {
                  onChange(e)
                }}
                options={options}
                components={{
                  DropdownIndicator: () => null,
                }}
              />

              {error && (
                <FormErrorMessage className="mt-1">
                  {error.message}
                </FormErrorMessage>
              )}
            </>
          )}
        />
      </label>
    </div>
  )
}
