import { TNextThreeMonthsInfos } from "shared/types/spo/next-three-months-infos"
import SPO_API from "../axios"

export default async function getNextThreeMonthsInfos(
  action: string,
  businessUnitId?: string,
): Promise<TNextThreeMonthsInfos[]> {
  const res = await SPO_API.get(
    `/nextThreeMonthsInfos?action=${action}${
      businessUnitId ? `&businessUnit=${businessUnitId}` : ""
    }`,
  )

  return Array.isArray(res.data) ? res.data : []
}
