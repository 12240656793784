import { useQueryClient } from "@tanstack/react-query"
import ButtonIcon from "components/Button/ButtonIcon"
import Spinner from "components/Spinner/Spinner"
import { AuthContext } from "core/auth/AuthProvider"
import {
  useIsProjectPinned,
  useTogglePinProject,
} from "core/query-hooks/useUsers"
import { t } from "i18next"
import { useContext } from "react"
import { AiFillPushpin, AiOutlinePushpin } from "react-icons/ai"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

export interface IPinProject {
  isOperation?: boolean
  programId?: number
}

export default function PinProject(props: IPinProject) {
  const { id } = useParams()
  const togglePinProject = useTogglePinProject()
  const queryClient = useQueryClient()
  const { getUserInfo } = useContext(AuthContext)
  const { isOperation, programId } = props
  const userInfo = getUserInfo()
  const projectId = id || programId

  const {
    data: project,
    error,
    isError,
    isLoading,
  } = useIsProjectPinned({
    userAzureId: userInfo.azureId,
    projectId: +projectId!,
  })

  function postPinProject() {
    togglePinProject.mutate(
      {
        userAzureId: userInfo.azureId,
        projectId: +projectId!,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["isProjectPinned"] })
          queryClient.invalidateQueries({
            queryKey: ["pinnedProjects", userInfo.azureId],
          })
          toast.success(
            isOperation
              ? `${t("toast-success-toggle-pin-operation")}`
              : `${t("toast-success-toggle-pin-project")}`,
          )
        },
        onError: (err) => {
          toast.error(
            `${t("project.toastErrorTogglePin")}, ${
              err.response?.data.error.message
            }`,
          )
        },
      },
    )
  }

  if (isLoading) return <Spinner />

  if (isError) return <p>{error.message}</p>

  return (
    project && (
      <ButtonIcon
        className="ml-2"
        type="button"
        onClick={() => postPinProject()}
      >
        {project?.isPinned ? (
          <AiFillPushpin
            size={22}
            className="text-yellow-400"
            cursor="pointer"
          />
        ) : (
          <AiOutlinePushpin
            size={22}
            cursor="pointer"
            className="text-cyan-800"
          />
        )}
      </ButtonIcon>
    )
  )
}
