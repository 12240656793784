import classNames from "classnames"
import { useRef } from "react"
import { TUser } from "shared/types/user.type"
import { useDebounceValue, useHover } from "usehooks-ts"
import ContactPopover from "../Popover/ContactPopover"
import "./Avatar.scss"

interface IUserPopoverProps {
  user: TUser
  hasHoverCard?: boolean
  size?: string
  isLast?: boolean
}

export default function UserPopover({
  user,
  size = "sm",
  isLast = false,
  hasHoverCard,
}: IUserPopoverProps) {
  const hoverRef = useRef(null)
  const [isHover] = useDebounceValue(useHover(hoverRef), 200)

  return (
    <div ref={hoverRef} className={classNames(size, "relative")}>
      {hasHoverCard && isHover && <ContactPopover user={user} top={isLast} />}

      <p className="font-normal block whitespace-nowrap">
        {user.firstName} {user.lastName}
      </p>
    </div>
  )
}
