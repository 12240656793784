import { API } from "core/api/axios"
import {
  TProject,
  TProjectChildren,
  TProjectCreate,
  TProjectsList,
} from "shared/types/project.type"

export function buildProjectUrl(baseUrl: string, params?: URLSearchParams) {
  const url = new URL(baseUrl)

  if (params) {
    const filteredParams = new URLSearchParams()

    // Filtre les paramètres pour exclure "page"
    params.forEach((value, key) => {
      if (key !== "page") {
        filteredParams.append(key, value)
      }
    })

    url.search = filteredParams.toString()
  }

  return url.toString()
}

async function createProject(project: TProjectCreate): Promise<TProject> {
  const res = await API.post("/project", project)
  return res.data
}

async function listProjects(params?: URLSearchParams): Promise<TProjectsList> {
  const projectURL = buildProjectUrl(
    `${process.env.REACT_APP_JARVIS_URL}/projects`,
    params,
  )
  const res = await API.get(projectURL)
  return res.data
}

async function updateProject(
  id: number,
  project: Partial<TProject>,
): Promise<TProject> {
  const res = await API.patch(`/project/${id}`, project)
  return res.data
}

async function getProjectById(id: string): Promise<TProject> {
  const res = await API.get(`/projects/${id}`)
  return res.data
}

async function getProjectsByQuery(query: string): Promise<TProjectsList> {
  const res = await API.get(`/projects?search=${query}`)
  return res.data
}

async function deleteProject(id: string): Promise<TProject> {
  const res = await API.delete(`/project/${id}`)
  return res.data
}

async function getProjectChildren(id: string): Promise<TProjectChildren> {
  return typeof id === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/projects/${id}/child-records`).then((response) => response.data)
}

export {
  createProject,
  deleteProject,
  getProjectById,
  getProjectChildren,
  getProjectsByQuery,
  listProjects,
  updateProject,
}
