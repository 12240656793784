import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import Box from "components/Box/Box"
import { checkStudyRequestsDocuments } from "core/api/study-requests"
import { useGetDocumentsByStudyRequestTypeAndProjectId } from "core/ged/query-hooks/useGed"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useGetInfographyDocuments } from "core/query-hooks/useInfographies"
import { useAddCommentToStudyRequest } from "core/query-hooks/useStudyRequests"
import GedCategories from "features/ged/GedCategories"
import { t } from "i18next"
import { useState } from "react"
import { nanoid } from "nanoid"
import { Controller, useForm } from "react-hook-form"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { DEVELOPER_COMMENT } from "shared/resources/study-request.resources"
import * as yup from "yup"
import { TYPE_INFOGRAPHY } from "../studyRequest.resources"

const schema = yup.object({
  list: yup.array().of(
    yup.object({
      documentIds: yup.array().of(yup.number()),
      documentCategoryId: yup.number(),
    }),
  ),
  comment: yup.string(),
})

export type TStudyRequestCreateDocumentsStepForm = {
  list: {
    documentIds?: number[]
    documentCategoryId?: number
  }[]
  comment?: string
}

export interface IEditDocumentsInfography {
  routeName: string
}

export function EditDocumentsInfography(props: IEditDocumentsInfography) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id, studyRequestId } = useParams()
  const { routeName } = props
  const { data: tree } = useGetInfographyDocuments(TYPE_INFOGRAPHY)
  const { data: files, isLoading: isLoadingFiles } =
    useGetDocumentsByStudyRequestTypeAndProjectId(+id!, TYPE_INFOGRAPHY)
  const addCommentToStudyRequest = useAddCommentToStudyRequest(+studyRequestId!)

  const { handleSubmit, control } =
    useForm<TStudyRequestCreateDocumentsStepForm>({
      resolver: yupResolver(
        schema as yup.ObjectSchema<TStudyRequestCreateDocumentsStepForm>,
      ),
    })

  if (studyRequestId === "undefined")
    return (
      <Navigate to={`/${routeName}/${id}/studies/infography/new`} replace />
    )

  const [missingDocuments, setMissingDocuments] = useState<string[]>()

  const onSubmit = async (data: TStudyRequestCreateDocumentsStepForm) => {
    const missingDocs = await checkStudyRequestsDocuments(+studyRequestId!)
    setMissingDocuments(missingDocs)

    if (data?.comment && missingDocs.length === 0) {
      addCommentToStudyRequest.mutate(
        {
          typeId: DEVELOPER_COMMENT.id,
          comment: data.comment,
        },
        {
          onSuccess() {
            toast.success(t("study-request-updated"))
            navigate(
              `/${routeName}/${id}/studies/infography/${studyRequestId}/documents`,
            )
            queryClient.invalidateQueries({
              queryKey: ["getInfographyStudyGedDocuments", studyRequestId],
            })
          },
          onError(error) {
            toast.error(error.response?.data.error.message)
          },
        },
      )
    }
    if (missingDocs && missingDocs.length === 0) {
      navigate(
        `/${routeName}/${id}/studies/infography/${studyRequestId}/documents`,
      )
    } else {
      toast.error(`${t("label-missing-documents")}`)
    }
  }

  return (
    <div className="px-4 sm:px-16">
      <form>
        <div className="divide-y divide-slate-100">
          <div className="pt-6">
            <div>
              <p className="Color__Primary font-semibold text-lg">
                {`${t("studyRequest.documentsLabel")}`}
              </p>
              <p className="Color__Primary mt-2 text-sm">
                {`${t("studyRequest.documentsHelperMsg")}`}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-4 pt-6">
              {tree?.map((elm) => (
                <Box key={nanoid()} classNames="flex flex-col mb-8 md:p-6">
                  <div>
                    {elm.documentTypes.length > 0 ? (
                      files?.documentCategories
                        .filter((category) => elm.id === category.category.id)
                        .map((category) => (
                          <GedCategories
                            category={category}
                            element={elm}
                            isLoading={isLoadingFiles}
                            control={control}
                            isProjectStudyRequestRelated={
                              routeName === "project"
                            }
                          />
                        ))
                    ) : (
                      <p className="Color__Primary">
                        {`${t("studyRequest.notAvailableCategoryMsg")}`}
                      </p>
                    )}
                  </div>
                </Box>
              ))}
              {missingDocuments && missingDocuments.length > 0 && (
                <Box classNames="text-xs text-red-500">
                  <p> {`${t("label-mandatory-documents-list")}`}</p>
                  <ul>
                    {missingDocuments.map((doc) => (
                      <li key={nanoid()}>
                        <p>- {doc}</p>
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
              <Box classNames="flex flex-col mb-8 md:p-6">
                <div className="mb-4">
                  <h2 className="Color__Primary font-semibold flex-1 md:pr-6">
                    {`${t("comment")}`}
                  </h2>
                </div>
                <Controller
                  name="comment"
                  control={control}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      className="Textarea__Field h-48"
                      placeholder={`${t("comment")}`}
                      id="comment"
                    />
                  )}
                />
              </Box>
            </div>
          </div>
        </div>
      </form>
      <div className="flex items-center justify-end pt-4 pb-8">
        <div>
          <PermissionsGuard
            requiredRoles={["read:study-request.check-document"]}
          >
            <Button
              onClick={handleSubmit((data) => onSubmit(data))}
              size="small"
              mode="primary"
              marginLeft={12}
            >
              {`${t("edit-documents")}`}
            </Button>
          </PermissionsGuard>
        </div>
      </div>
    </div>
  )
}
