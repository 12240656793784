import classNames from "classnames"
import { t } from "i18next"
import { FaRegQuestionCircle } from "react-icons/fa"
import { Link } from "react-router-dom"

interface ISidebarItemFaq {
  closeSidebar: () => void
}

export default function SidebarItemFaq({ closeSidebar }: ISidebarItemFaq) {
  const handleClick = () => {
    closeSidebar()
  }

  return (
    <li>
      <Link
        to="https://pierreval.freshservice.com/support/solutions/folders/26000069720?cat_id=26000033428"
        target="_blank"
        data-title={t("alvis-support")}
        className="sidebar-title flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 group"
        type="button"
        onClick={handleClick}
      >
        <FaRegQuestionCircle
          className={classNames(
            "text-gray-500 w-6 h-6 transition duration-75 group-hover:text-sky-800",
          )}
        />
      </Link>
    </li>
  )
}
