import L from "leaflet"
import "leaflet/dist/leaflet.css"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"

import useGetInfosByAddress from "core/query-hooks/useOpenStreetMap"
import { t } from "i18next"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import { TbMapOff } from "react-icons/tb"

interface MapProps {
  location: string
}

function Map({ location }: MapProps) {
  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [30, 48],
    iconAnchor: [15, 48],
  })

  L.Marker.prototype.options.icon = DefaultIcon

  const {
    data: addressData,
    isError,
    isLoading,
  } = useGetInfosByAddress(`${location}`)

  if (isLoading) {
    return (
      <div
        style={{ height: "500px", width: "100%" }}
        className="flex items-center justify-center flex-col"
      >
        <div>
          <TbMapOff size={150} className="text-gray-200" />
        </div>
        <div>{`${t("charging-map")}`}</div>
      </div>
    )
  }

  if (isError || !addressData || addressData.length === 0) {
    return (
      <div
        style={{ height: "500px", width: "100%" }}
        className="flex items-center justify-center flex-col"
      >
        <div>
          <TbMapOff size={150} className="text-gray-200" />
        </div>
        <div>{`${t("unavailable-map")}`}</div>
      </div>
    )
  }

  const { lat, lon } = addressData[0]

  return (
    <MapContainer
      center={[parseFloat(lat), parseFloat(lon)]}
      zoom={16}
      style={{ height: "500px", width: "100%" }}
      className="z-0"
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={[parseFloat(lat), parseFloat(lon)]}>
        <Popup>{location}</Popup>
      </Marker>
    </MapContainer>
  )
}

export default Map
