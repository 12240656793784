import { AANG_API, API } from "core/api/axios"
import {
  TIsProjectPinned,
  TPinnedProject,
  TPinnedProjectQuery,
  TPinnedProjectStatus,
  TUser,
} from "shared/types/user.type"
import { TUserOccupation } from "../../shared/types/user.type"

async function getUser(uuid: string): Promise<TUser> {
  const res = await API.get(`/users/${uuid}`)
  return res.data
}

async function getUsersOccupations(): Promise<TUserOccupation[]> {
  const res = await API.get("/user-occupations")
  return res.data
}

async function getUsersByOccupationId(id: number): Promise<TUser[]> {
  const res = await API.get(`/users?occupation=${id}`)
  return res.data
}

async function getUsers(): Promise<TUser[]> {
  const res = await API.get(`/users?excludeImpersonal=true`)
  return res.data
}

async function getCheckNewUser(authToken: string) {
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }

  const res = await API.get("/users/me", config)
  return res.data
}

async function isProjectPinned(
  query: TPinnedProjectQuery,
): Promise<TIsProjectPinned> {
  const res = await API.get(
    `/users/${query.userAzureId}/pinned-project/${query.projectId}`,
  )
  return res.data
}

async function togglePinProject(
  params: TPinnedProjectQuery,
): Promise<TPinnedProjectStatus> {
  const res = await API.post(`/users/${params.userAzureId}/pinned-project`, {
    projectId: params.projectId,
  })
  return res.data
}

async function listPinnedProjectsByUser(
  userAzureId: string,
): Promise<TPinnedProject[]> {
  const res = await API.get(`/users/${userAzureId}/pinned-projects`)
  return res.data
}

async function getDocuwareCookie(userAzureId: string): Promise<string> {
  const res = await API.get(`/users/${userAzureId}/docuware`)
  return res.data
}

async function listDevelopers(): Promise<TUser[]> {
  const res = await API.get("/users/me/selectableDevelopers")
  return res.data
}

async function getCurrentUser(): Promise<TUser> {
  const res = await API.get("/users/me")
  return res.data
}

async function listSubordinates(
  userAzureId: string,
): Promise<{ subordinates: TUser[] }> {
  const res = await API.get(`/users/${userAzureId}/subordinates`)
  return res.data
}

async function getUserAvatar(userAzureId: string): Promise<any> {
  const res = userAzureId
    ? await AANG_API.get(`/users/${userAzureId}/avatar`, {
        responseType: "blob",
      })
    : null

  return res ? URL.createObjectURL(res.data) : null
}

export {
  getCheckNewUser,
  getCurrentUser,
  getDocuwareCookie,
  getUser,
  getUserAvatar,
  getUsers,
  getUsersByOccupationId,
  getUsersOccupations,
  isProjectPinned,
  listDevelopers,
  listPinnedProjectsByUser,
  listSubordinates,
  togglePinProject,
}
