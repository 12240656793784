import { t } from "i18next"
import { IoSearchCircle } from "react-icons/io5"
import { NavLink, useLocation, useNavigate } from "react-router-dom"

interface ISidebarItemSearch {
  closeSidebar: () => void
}

export default function SidebarItemSearch({
  closeSidebar,
}: ISidebarItemSearch) {
  const location = useLocation()
  const navigate = useNavigate()

  const searchURL =
    "/programs/search?steps=1%2C2%2C3%2C4%2C6%2C7%2C8%2C9%2C10%2C11&take=20&sortColumn=stepId&sortOrder=asc"

  const isCurrentPage = location.pathname === "/programs/search"

  const handleClick = () => {
    closeSidebar()

    if (!isCurrentPage) {
      navigate(searchURL)
    }
  }

  return (
    <li>
      <NavLink
        data-title={t("search")}
        className="sidebar-title flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 group"
        type="button"
        to={isCurrentPage ? "#" : searchURL}
        onClick={isCurrentPage ? (e) => e.preventDefault() : handleClick}
      >
        <IoSearchCircle className="text-gray-500 w-7 h-7 transition duration-75 group-hover:text-sky-800" />
      </NavLink>
    </li>
  )
}
