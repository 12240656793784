import Spinner from "components/Spinner/Spinner"
import { useSteps } from "core/query-hooks/useSteps"
import { t } from "i18next"
import { TProjectStep } from "shared/types/project.type"
import DropdownFilters from "../DropdownFilter"
import useProgramsSearch from "../useProgramsSearch"

export default function StepsFilter() {
  const { data: stepsData, isLoading, error } = useSteps()
  const { state, dispatch } = useProgramsSearch()
  const { steps } = state.filters

  if (isLoading) return <Spinner />

  if (error || !stepsData) return <p>{error?.response?.data.message}</p>

  const allStepsOption = {
    id: -1,
    label: t("select-all"),
  }

  const optionsWithAllSteps = [allStepsOption, ...stepsData]

  const handleStepClick = (step: Omit<TProjectStep, "tooltip">) => {
    if (step.id === -1) {
      const shouldSelectAll = steps.length !== stepsData.length
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...state.filters,
          steps: shouldSelectAll ? stepsData.map((item) => item.id) : [],
          skip: 0,
          page: 1,
        },
      })
    } else {
      const isSelected = (state.filters.steps as number[])?.includes(step.id)
      const updatedSteps = isSelected
        ? state.filters.steps?.filter((stepId) => stepId !== step.id)
        : [...(state.filters.steps || []), step.id]

      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...state.filters,
          steps: updatedSteps,
          skip: 0,
          page: 1,
        },
      })
    }
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900 w-max">
              {t("steps")}
              {steps.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-blue-800 bg-blue-100 rounded-full">
                  {steps.length}
                </div>
              )}
            </div>
          )}
          options={optionsWithAllSteps}
          renderOption={(step, index) => (
            <div role="menu">
              <li
                onClick={() => handleStepClick(step)}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id={`menu-item-${index}`}
                key={`menu-item-${index}`}
              >
                <input
                  aria-label="Select step"
                  className="mr-2"
                  type="checkbox"
                  readOnly
                  checked={
                    step.id === -1
                      ? steps.length === stepsData.length
                      : (state.filters.steps as number[])?.includes(step.id)
                  }
                />
                {step.label}
              </li>
            </div>
          )}
          keyExtractor={(step) => step.id}
        />
      </div>
    </div>
  )
}
