import { useQuery } from "@tanstack/react-query"
import { getTeamTodos, getUserTodos } from "core/api/todos"
import { TAction } from "shared/types/action.type"
import { CustomAxiosError } from "shared/types/axios.type"
import { TProgress } from "shared/types/progress.type"

export function useGetUserTodos(date: string) {
  return useQuery<
    { actions: TAction[]; progress: TProgress[] },
    CustomAxiosError
  >({ queryKey: ["getUserTodos", date], queryFn: () => getUserTodos(date) })
}

export function useGetTeamTodos(date: string) {
  return useQuery<
    { actions: TAction[]; progress: TProgress[] },
    CustomAxiosError
  >({ queryKey: ["getTeamTodos", date], queryFn: () => getTeamTodos(date) })
}
