import { yupResolver } from "@hookform/resolvers/yup"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import Avatar from "components/Avatar/Avatar"
import AvatarBlob from "components/Avatar/AvatarBlob"
import { Button } from "components/Button/Button"
import DateFormat from "components/Date/DateFormat"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import { API } from "core/api/axios"
import { getProgressPersonInChargeAndCreator } from "core/api/progress"
import {
  useDeleteProgress,
  useProgressModificationRight,
  useUpdateProgress,
} from "core/query-hooks/useProgress"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { FormEvent, Fragment } from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller, useForm } from "react-hook-form"
import { RiDeleteBin2Fill } from "react-icons/ri"
import { IndicatorSeparatorProps, OptionProps, components } from "react-select"
import AsyncSelect from "react-select/async"
import { toast } from "react-toastify"
import { TProgress } from "shared/types/progress.type"
import { TPersonInCharge, TPersonInChargeOption } from "shared/types/user.type"
import useIsMobile from "utils/isMobile"
import * as yup from "yup"
import ProgressAvatarCell from "./ProgressAvatarCell"

registerLocale("fr", fr)

type FormValues = {
  description: string
  personInCharge: {
    azureId: string
    firstName: string
    lastName: string
  }
  deadline: Date
  childOperationId: number
}

const validationSchema = yup.object().shape(
  {
    personInCharge: yup.object().when("deadline", {
      is: (deadline: Date) => !deadline,
      then: () =>
        yup.object({
          azureId: yup.string(),
          firstName: yup.string(),
          lastName: yup.string(),
        }),
      otherwise: () =>
        yup.object({
          azureId: yup
            .string()
            .required(() => `${t("progress-required-person-in-charge")}`),
          firstName: yup
            .string()
            .required(() => `${t("progress-required-person-in-charge")}`),
          lastName: yup
            .string()
            .required(() => `${t("progress-required-person-in-charge")}`),
        }),
    }),
    description: yup.string().required(() => `${t("required-description")}`),
    deadline: yup.date().when("personInCharge", {
      is: (personInCharge: TPersonInCharge) => !personInCharge.azureId,
      then: () => yup.date().nullable().default(null),
      otherwise: () => yup.date().required(() => `${t("required-deadline")}`),
    }),
  },
  [["personInCharge", "deadline"]],
)

function Option(props: OptionProps<TPersonInChargeOption>) {
  const { children, data: user } = props

  return (
    <div>
      <components.Option {...props}>
        <div className="flex items-center">
          <AvatarBlob className="w-7 h-7" user={user} initials />
          <span className="text-cyan-900">{children}</span>
        </div>
      </components.Option>
    </div>
  )
}

const loadOptions = async (
  inputValue: string,
): Promise<TPersonInChargeOption[]> => {
  const res = await API.get<TPersonInCharge[]>(`/users?search=${inputValue}`)
  const formattedOptions = res.data.map((elm) => ({
    ...elm,
    value: elm.azureId,
    label: `${elm.firstName} ${elm.lastName}`,
  }))
  return formattedOptions
}

const indicatorSeparatorStyle = {
  display: "none",
}

function IndicatorSeparator({
  innerProps,
}: IndicatorSeparatorProps<TPersonInChargeOption, true>) {
  return <span style={indicatorSeparatorStyle} {...innerProps} />
}

interface IEditProgressModalProps {
  progress: TProgress
  border: boolean
  onDeleteHandlePagination: () => void
}

export default function EditProgressModal({
  progress,
  border,
  onDeleteHandlePagination,
}: IEditProgressModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const { isShowing: isDeleteModalShowed, toggle: toggleDeleteModal } =
    useModal()
  const queryClient = useQueryClient()
  const mutation = useUpdateProgress(progress.id)
  const { data: hasRights, refetch } = useProgressModificationRight(progress.id)

  const isMobile = useIsMobile()

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema as yup.ObjectSchema<FormValues>),
    defaultValues: {
      description: progress.description,
      personInCharge: {
        ...progress?.personInCharge,
        azureId: progress.personInCharge?.azureId,
      },
      deadline: progress?.deadline ? new Date(progress.deadline) : undefined,
    },
  })

  const handleCloseModal = () => {
    toggleModal()
    reset()
  }

  const handleCloseDeleteModal = () => {
    toggleDeleteModal()
  }

  const handleDeleteChoice = () => {
    toggleModal()
    reset()
    toggleDeleteModal()
  }

  const { data: userProgress, isLoading } = useQuery({
    queryKey: ["useGetProgressPersonInChargeAndCreator", progress.id],
    queryFn: () => getProgressPersonInChargeAndCreator(progress.id),
  })

  const submitForm = async (submittedData: FormValues) => {
    mutation.mutateAsync(
      {
        ...submittedData,
        personInCharge: submittedData.personInCharge.azureId,
      },
      {
        onSuccess: (p) => {
          toast.success(`${t("toast-progress-update-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["getProgressByChildOperationId", p.childOperationId],
          })
          queryClient.invalidateQueries({ queryKey: ["getProgressById", p.id] })
          queryClient.invalidateQueries({
            queryKey: ["useGetProgressPersonInChargeAndCreator", p.id],
          })
          toggleModal()
          refetch()
        },
        onError: (err) => {
          toast.error(
            `${t("toast-progress-update-error")} ${
              err.response?.data.error.message
            }`,
          )
        },
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  const deleteProgress = useDeleteProgress(progress.id)

  const onDeleteAction = async () => {
    await deleteProgress.mutateAsync(progress.id, {
      onSuccess: () => {
        onDeleteHandlePagination()
        queryClient.invalidateQueries({
          queryKey: [
            "getProgressByChildOperationId",
            progress.childOperationId,
          ],
        })
        toast.success(`${t("delete-progress-toast")}`)
      },
      onError: (err) => {
        toast.error(
          `${err.response?.data.error.message}, ${progress.project.id}`,
        )
      },
    })
  }

  if (isLoading) return <Spinner />

  if (!userProgress) return <p>{`${t("no-progress-found")}`}</p>

  const descriptionLines = progress.description.split("\\n")
  return (
    <>
      <td
        onClick={toggleModal}
        className={classNames("Table__TD cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
        })}
      >
        <span
          className={classNames({
            "opacity-60": progress.isDefinitive,
          })}
        >
          {format(new Date(progress.createdAt), "dd/MM/yyyy")}
        </span>
      </td>
      <td
        onClick={toggleModal}
        className={classNames("Table__TD w-100 cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
          Table__Sticky_Column: !isMobile,
        })}
      >
        <span
          className={classNames({
            "opacity-60": progress.isDefinitive,
          })}
        >
          <button type="button" className="text-left">
            {descriptionLines.map((ligne, index) => (
              <Fragment key={nanoid()}>
                {ligne}
                {index !== descriptionLines.length - 1 && <br />}{" "}
              </Fragment>
            ))}
          </button>
        </span>
      </td>
      <td
        onClick={toggleModal}
        className={classNames("Table__TD cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
        })}
      >
        {progress.creator ? (
          <ProgressAvatarCell
            userAzureId={progress.creator.azureId}
            isLast={!border}
          />
        ) : (
          "-"
        )}
      </td>

      <td
        onClick={toggleModal}
        className={classNames("Table__TD cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
        })}
      >
        {progress.personInCharge?.firstName &&
          progress.personInCharge?.lastName && (
            <ProgressAvatarCell
              userAzureId={progress.personInCharge.azureId}
              isLast={!border}
            />
          )}
      </td>

      <td
        onClick={toggleModal}
        className={classNames("Table__TD cursor-pointer", {
          "border-none": border,
          Table__Border_Bottom: border!,
        })}
      >
        <span
          className={classNames({
            "opacity-60": progress.isDefinitive,
          })}
        >
          <DateFormat
            isDefinitive={progress.isDefinitive}
            date={progress.deadline}
          />
        </span>
      </td>

      <Modal
        isShowing={isModalShowed}
        closeModal={handleCloseModal}
        title={`${t("edit-progress")}`}
      >
        <form className="Text__Field_Container" onSubmit={onSubmit}>
          <label className="font-medium" htmlFor="issuer">
            {t("issuer")}
            <div className="flex items-center">
              <Avatar hasHoverCard user={userProgress?.creator!} />
              <p className="text-sm ml-2">
                {progress.creator?.firstName} {progress.creator?.lastName}
              </p>
            </div>
          </label>

          <div className="mt-3">
            <label className="font-medium" htmlFor="description">
              {`${t("description")} *`}
            </label>

            <textarea
              className="Textarea__Field h-36"
              {...register("description")}
              placeholder={`${t("description")}`}
              id="description"
            />
            {errors?.description && (
              <p className="text-red-600 text-sm">
                {errors.description.message}
              </p>
            )}
          </div>

          <div className="mt-3 flex flex-col">
            <label className="font-medium" htmlFor="deadline">
              {`${t("deadlineLabel")} *`}
            </label>
            <Controller
              name="deadline"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  id="deadline"
                  locale={fr}
                  className="Text__Field"
                  placeholderText={`${t("deadline")}`}
                  selected={field.value}
                  onChange={(date) => {
                    field.onChange(date)
                  }}
                />
              )}
            />
            {errors?.deadline && (
              <p className="text-red-600 text-sm">{errors.deadline.message}</p>
            )}
          </div>

          <div className="my-4">
            <label className="font-medium" htmlFor="personInCharge">
              {`${t("assigned-to")}`}
              <Controller
                name="personInCharge"
                control={control}
                render={({ field }) => {
                  const defaultPersonInCharge =
                    field.value.azureId !== undefined
                      ? {
                          ...field.value,
                          value: field.value.azureId,
                          label: `${field.value.firstName} ${field.value.lastName}`,
                        }
                      : null
                  return (
                    <AsyncSelect
                      defaultValue={defaultPersonInCharge}
                      isSearchable
                      loadOptions={loadOptions}
                      styles={{
                        container: (styles) => ({
                          ...styles,
                          minWidth: "100%",
                          fontSize: "14px",
                        }),
                        control: (styles) => ({
                          ...styles,
                          height: "50px",
                          borderRadius: "8px",
                          border: "1px solid #e5e7eb",
                          backgroundColor: "#F9FAFB",
                        }),
                        placeholder: (styles) => ({
                          ...styles,
                          opacity: 0.7,
                        }),
                      }}
                      noOptionsMessage={() => `${t("typeToBeginSearch")}`}
                      components={{
                        DropdownIndicator: () => null,
                        Option,
                        IndicatorSeparator,
                      }}
                      className="mb-4 text-sm"
                      placeholder={`${t("search-collaborator")}`}
                      onChange={(e) => {
                        field.onChange(e)
                      }}
                    />
                  )
                }}
              />
            </label>
            {errors?.personInCharge && errors.personInCharge?.azureId && (
              <p className="text-red-600 text-sm">
                {errors.personInCharge.azureId.message}
              </p>
            )}
          </div>

          <div className="Modal__Footer">
            <div className="flex flex-row items-center justify-between">
              {hasRights && (
                <Button
                  size="medium"
                  mode="danger"
                  onClick={() => handleDeleteChoice()}
                >
                  {isMobile ? <RiDeleteBin2Fill /> : `${t("delete")}`}
                </Button>
              )}
              <div className="mx-2">
                <Button
                  type="button"
                  size="medium"
                  mode="secondary"
                  onClick={() => {
                    toggleModal()
                    reset()
                  }}
                >
                  {`${t("cancel")}`}
                </Button>
              </div>
              {hasRights && (
                <Button
                  size="medium"
                  mode="primary"
                  isLoading={mutation.isPending}
                  type="submit"
                >
                  {`${t("update")}`}
                </Button>
              )}
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        isShowing={isDeleteModalShowed}
        closeModal={handleCloseDeleteModal}
        title={`${t("delete-progress-title")}`}
      >
        <span className="italic">
          <blockquote>&quot;{progress.description}&quot;</blockquote>
        </span>
        <p className="Modal__Description opacity-75 mt-4">
          {t("delete-progress-description")}
        </p>
        <div className="Modal__Body">
          <div className="Modal__Footer">
            <Button
              size="medium"
              mode="secondary"
              onClick={() => toggleDeleteModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              marginLeft={24}
              size="medium"
              mode="danger"
              onClick={() => onDeleteAction()}
            >
              {`${t("confirm")}`}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
