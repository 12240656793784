import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"
import { PriorisationAttributionForm } from "./PriorisationAttributionForm"
import { RestitutionForm } from "./RestitutionForm"
import {
  STATUS_CREATED,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_VALIDATED,
} from "./studyRequest.resources"
import { ValidationForm } from "./ValidationForm"

export interface IFollowUpFormsProps {
  statusId: number
  studyRequestId: number
  studyRequestHistory: TStudyRequestHistory[]
  studyRequest: TStudyRequest
  studyRequestType: number
}

export function FollowUpForms(props: IFollowUpFormsProps) {
  const {
    statusId,
    studyRequestId,
    studyRequest,
    studyRequestHistory,
    studyRequestType,
  } = props

  switch (statusId) {
    case STATUS_CREATED:
      return <ValidationForm requestId={studyRequestId!} />
    case STATUS_VALIDATED:
      return (
        <PriorisationAttributionForm
          requestId={studyRequestId!}
          studyRequest={studyRequest}
          studyRequestHistory={studyRequestHistory}
          studyRequestType={studyRequestType}
        />
      )
    case STATUS_PRIORIZED_AND_ASSIGNED:
    case STATUS_RESTITUTION_STANDBY:
      return (
        <RestitutionForm
          studyRequestType={studyRequestType}
          requestId={studyRequestId!}
          studyRequest={studyRequest}
          studyRequestHistory={studyRequestHistory}
        />
      )
    case STATUS_PRIORIZATION_STANDBY:
      return (
        <PriorisationAttributionForm
          requestId={studyRequestId!}
          studyRequest={studyRequest}
          studyRequestHistory={studyRequestHistory}
          studyRequestType={studyRequestType}
        />
      )
    default:
      return null
  }
}
