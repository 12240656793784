import Box from "components/Box/Box"
import LightTable from "components/Table/LightTable"
import RythmTooltip from "components/Tooltip/RythmTooltip"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import {
  useGetReservationsActeesByTrancheCommerciale,
  useGetReservationsByTrancheCommerciale,
  useGetReservationsNonActeesByTrancheCommerciale,
} from "core/spo/query-hooks/useReservations"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { AiOutlineFundProjectionScreen } from "react-icons/ai"
import { Link } from "react-router-dom"
import { HOUSING_ESTATE_TYPE } from "shared/resources/operation.resources"
import formatNumberNoZero from "utils/formatNumberNoZero"

export interface IRhythmsSynthesisByTranche {
  registrationId: string
  trancheCommercialeId: string
}

export function RhythmsSynthesisByTranche(props: IRhythmsSynthesisByTranche) {
  const { trancheCommercialeId, registrationId } = props
  const { data: reservations, isFetched: isFetchedReservations } =
    useGetReservationsByTrancheCommerciale(trancheCommercialeId)
  const {
    data: reservationsNonActees,
    isFetched: isFetchedReservationsNonActees,
  } = useGetReservationsNonActeesByTrancheCommerciale(trancheCommercialeId)
  const { data: reservationsActees, isFetched: isFetchedReservationsActees } =
    useGetReservationsActeesByTrancheCommerciale(trancheCommercialeId)

  const headers = [
    "",
    t("initial-stock"),
    t("january-abv"),
    t("february-abv"),
    t("march-abv"),
    t("april-abv"),
    t("may-abv"),
    t("june-abv"),
    t("july-abv"),
    t("august-abv"),
    t("september-abv"),
    t("october-abv"),
    t("november-abv"),
    t("december-abv"),
    t("total"),
    t("rythm"),
  ]
  return (
    <>
      <Box classNames="p-0 mb-4">
        <div className="flex items-center px-3.5 py-2 justify-between">
          <div className="flex items-center text-cyan-900 mr-1">
            <AiOutlineFundProjectionScreen size={22} className="mr-1" />
            <h3 className="text-xs uppercase font-medium pl-1">
              {t("reservations-rhythm")}
            </h3>
          </div>
          <div className="text-primary-dark">
            <TooltipWithHover position="left" message={t("spo-informations")}>
              <Link
                className="text-cyan-900 underline uppercase text-xs"
                to={`${process.env.REACT_APP_SPO_URL}#/commercial/commercial?operation=${registrationId}`}
                target="_blank"
              >
                {t("spo")}
              </Link>
            </TooltipWithHover>
          </div>
        </div>
        {isFetchedReservations && reservations?.years.length ? (
          <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
            <LightTable
              showTotal={false}
              className="table-auto border-b-0 ColorfullHeader"
              items={reservations.years}
              headers={headers}
              isTight
              renderRow={(item, index) => (
                <>
                  <td>
                    <div className="flex items-center">{item.year}</div>
                  </td>
                  <td className="bg-gray-100">
                    <div className="flex items-center">
                      {formatNumberNoZero(item.startingStock)}
                    </div>
                  </td>

                  {item.months.map((month) => (
                    <td key={nanoid()}>
                      <RythmTooltip
                        tooltip={month.tooltip}
                        className={
                          index === 0 || index === 1 ? "bottom-right" : ""
                        }
                        isHousingEstate={
                          registrationId.charAt(7) ===
                          HOUSING_ESTATE_TYPE.abbreviation
                        }
                      >
                        <div className="flex items-center">
                          {month.tooltip.cancelation.length > 0
                            ? month.amount
                            : formatNumberNoZero(month.amount)}
                        </div>
                      </RythmTooltip>
                    </td>
                  ))}

                  <td className="bg-gray-100">
                    <div className="flex items-center">
                      {formatNumberNoZero(
                        item.months.reduce((acc, elm) => acc + elm.amount, 0),
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="flex items-center">
                      {(
                        item.months.reduce((acc, elm) => acc + elm.amount, 0) /
                        12
                      ).toFixed(2)}
                    </div>
                  </td>
                </>
              )}
            />
          </div>
        ) : (
          <p className="text-sm p-3 Color__Primary">
            {t("reservations-no-data")}
          </p>
        )}
      </Box>

      <Box classNames="p-0 mb-4">
        <div className="flex items-center px-3.5 py-2 justify-between">
          <div className="flex items-center text-cyan-900 mr-1">
            <AiOutlineFundProjectionScreen size={22} className="mr-1" />
            <h3 className="text-xs uppercase font-medium pl-1">
              {t("acted-reservations-rhythm")}
            </h3>
          </div>
          <div className="text-primary-dark">
            <TooltipWithHover position="left" message={t("spo-informations")}>
              <Link
                className="text-cyan-900 underline uppercase text-xs"
                to={`${process.env.REACT_APP_SPO_URL}#/commercial/commercial?operation=${registrationId}`}
                target="_blank"
              >
                {t("spo")}
              </Link>
            </TooltipWithHover>
          </div>
        </div>
        {isFetchedReservationsActees && reservationsActees?.years.length ? (
          <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
            <LightTable
              showTotal={false}
              className="table-auto ColorfullHeader"
              items={reservationsActees?.years!}
              headers={headers}
              isTight
              renderRow={(item) => (
                <>
                  <td>
                    <div className="flex items-center">{item.year}</div>
                  </td>
                  <td className="bg-gray-100">
                    <div className="flex items-center">
                      {formatNumberNoZero(item.startingStock)}
                    </div>
                  </td>

                  {item.months.map((month) => (
                    <td key={nanoid()}>
                      <div className="flex items-center">
                        {month.tooltip.cancelation.length > 0
                          ? month.amount
                          : formatNumberNoZero(month.amount)}
                      </div>
                    </td>
                  ))}

                  <td className="bg-gray-100">
                    <div className="flex items-center">
                      {formatNumberNoZero(
                        item.months.reduce((acc, elm) => acc + elm.amount, 0),
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="flex items-center">
                      {(
                        item.months.reduce((acc, elm) => acc + elm.amount, 0) /
                        12
                      ).toFixed(2)}
                    </div>
                  </td>
                </>
              )}
            />
          </div>
        ) : (
          <p className="text-sm p-3 Color__Primary">
            {t("acted-reservations-no-data")}
          </p>
        )}
      </Box>

      <Box classNames="p-0">
        <div className="flex items-center px-3.5 py-2 justify-between">
          <div className="flex items-center text-cyan-900 mr-1">
            <AiOutlineFundProjectionScreen size={22} className="mr-1" />
            <h3 className="text-xs uppercase font-medium pl-1">
              {t("unacted-reservations")}
            </h3>
          </div>
          <div className="text-primary-dark">
            <TooltipWithHover position="left" message={t("spo-informations")}>
              <Link
                className="text-cyan-900 underline uppercase text-xs"
                to={`${process.env.REACT_APP_SPO_URL}#/commercial/commercial?operation=${registrationId}`}
                target="_blank"
              >
                {t("spo")}
              </Link>
            </TooltipWithHover>
          </div>
        </div>
        {isFetchedReservationsNonActees &&
        reservationsNonActees?.years.length ? (
          <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
            <LightTable
              showTotal={false}
              className="table-auto ColorfullHeader"
              items={reservationsNonActees?.years!}
              headers={headers}
              isTight
              renderRow={(item) => (
                <>
                  <td>
                    <div className="flex items-center">{item.year}</div>
                  </td>
                  <td className="bg-gray-100">
                    <div className="flex items-center">
                      {formatNumberNoZero(item.startingStock)}
                    </div>
                  </td>

                  {item.months.map((month) => (
                    <td key={nanoid()}>
                      <div className="flex items-center">
                        {month.tooltip.cancelation.length > 0
                          ? month.amount
                          : formatNumberNoZero(month.amount)}
                      </div>
                    </td>
                  ))}

                  <td className="bg-gray-100">
                    <div className="flex items-center">
                      {formatNumberNoZero(
                        item.months.reduce((acc, elm) => acc + elm.amount, 0),
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="flex items-center">
                      {(
                        item.months.reduce((acc, elm) => acc + elm.amount, 0) /
                        12
                      ).toFixed(2)}
                    </div>
                  </td>
                </>
              )}
            />
          </div>
        ) : (
          <p className="text-sm p-3 Color__Primary">
            {t("unacted-reservations-no-data")}
          </p>
        )}
      </Box>
    </>
  )
}
