import { useQueries } from "@tanstack/react-query"
import Box from "components/Box/Box"
import DateFormat from "components/Date/DateFormat"
import Spinner from "components/Spinner/Spinner"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useListCommitteeByOperationId } from "core/query-hooks/useCommittes"
import { getDatesConsultation } from "core/spo/api/dates"
import { nanoid } from "nanoid"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { BsFolderFill } from "react-icons/bs"
import { Link, NavLink, useParams } from "react-router-dom"
import { COMMITTEE_TRANSFERT } from "shared/resources/committees-types-resources"

export interface IConsultationDateProps {
  operationsIds: string[]
  withOperationNameColumn?: boolean
  technical?: boolean
}

export function ConsultationDate(props: IConsultationDateProps) {
  const { withOperationNameColumn, operationsIds, technical } = props
  const { t } = useTranslation()
  const { id } = useParams()

  const { data: committees } = useListCommitteeByOperationId(+id!)
  const transfertDate = committees?.find(
    (c) => c.type.id === COMMITTEE_TRANSFERT.id && c.isDefinitive,
  )?.date

  const consultationDateQueries = useQueries({
    queries: operationsIds.map((opId) => {
      return {
        queryKey: ["getDatesConsultation", opId],
        queryFn: () => getDatesConsultation(opId),
      }
    }),
  })

  function displayDate(date: Date | null | undefined, isDefinitive: boolean) {
    return date ? (
      <DateFormat date={new Date(date)} isDefinitive={isDefinitive} />
    ) : (
      "-"
    )
  }

  const loading = consultationDateQueries.some((query) => query.isLoading)

  return (
    <Box classNames="p-0">
      {technical ? (
        <div className="flex items-center justify-between border-gray-200 px-4 pt-2">
          <div className="flex items-center text-cyan-900 font-medium">
            <BsFolderFill />
            <h3 className="text-xs uppercase pl-2">
              {t("consultation-file-call-for-tender")}
            </h3>
          </div>
          <div className=" text-primary-dark">
            <TooltipWithHover
              position="left"
              message={`${t("spo-informations")}`}
            >
              {transfertDate ? (
                <Link
                  className="text-cyan-900 underline uppercase text-xs"
                  to={`${process.env.REACT_APP_SPO_URL}#/planning/gantt?operation=${operationsIds[0]}`}
                  target="_blank"
                >
                  {`${t("spo")}`}
                </Link>
              ) : (
                <p className="text-cyan-900 uppercase text-xs">
                  {`${t("spo")}`}
                </p>
              )}
            </TooltipWithHover>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between border-gray-200 px-4 pt-2">
          <NavLink to="technical" type="button">
            <div className="flex items-center text-cyan-900 font-medium">
              <BsFolderFill />
              <h3 className="text-xs uppercase pl-2">
                {t("consultation-file-call-for-tender")}
              </h3>
            </div>
          </NavLink>
          <div className=" text-primary-dark">
            <TooltipWithHover
              position="left"
              message={`${t("spo-informations")}`}
            >
              <div className="text-cyan-900 uppercase text-xs">SPO</div>
            </TooltipWithHover>
          </div>
        </div>
      )}
      {consultationDateQueries ? (
        <div className="Light_Table__Wrapper overflow-auto mt-2">
          <table className="Table">
            <thead>
              <tr>
                {withOperationNameColumn ? (
                  <th className="Table__Head text-left">{t("operations")}</th>
                ) : null}
                <th className="Table__Head text-left">{t("dce-launch")}</th>
                <th className="Table__Head text-left">{t("dce-synthesis")}</th>
                <th className="Table__Head text-left">{t("ao-launch")}</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td align="center" colSpan={withOperationNameColumn ? 5 : 4}>
                    <Spinner />
                  </td>
                </tr>
              )}
              {consultationDateQueries.map(
                ({ error, data: consultationDate }) => (
                  <Fragment key={nanoid()}>
                    {error && (
                      <tr>
                        <td
                          align="center"
                          colSpan={withOperationNameColumn ? 5 : 4}
                        >
                          <p className="text-sm text-red-500">
                            {t("error-loading-dates-planning")}
                          </p>
                        </td>
                      </tr>
                    )}
                    {!loading && !error && (
                      <tr
                        className="hover:bg-gray-100 transition-colors"
                        key={nanoid()}
                      >
                        {withOperationNameColumn && (
                          <td>
                            <Link
                              className="text-sky-700 font-semibold underline"
                              to={`${process.env.REACT_APP_SPO_URL}#/planning/gantt?operation=${consultationDate?.registrationId}`}
                              target="_blank"
                            >
                              {consultationDate?.registrationId}
                            </Link>
                          </td>
                        )}
                        <td>
                          {consultationDate?.dceLaunch.actualDate
                            ? displayDate(
                                consultationDate?.dceLaunch.actualDate,
                                true,
                              )
                            : displayDate(
                                consultationDate?.dceLaunch.projectedDate,
                                false,
                              )}
                        </td>
                        <td>
                          {consultationDate?.dceSynthesis.actualDate
                            ? displayDate(
                                consultationDate?.dceSynthesis.actualDate,
                                true,
                              )
                            : displayDate(
                                consultationDate?.dceSynthesis.projectedDate,
                                false,
                              )}
                        </td>
                        <td>
                          {consultationDate?.aoLaunch.actualDate
                            ? displayDate(
                                consultationDate?.aoLaunch.actualDate,
                                true,
                              )
                            : displayDate(
                                consultationDate?.aoLaunch.projectedDate,
                                false,
                              )}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ),
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="p-2 text-sm text-sky-900 flex">
          {t("no-data-found-message")}
        </p>
      )}
    </Box>
  )
}
