import { useQuery } from "@tanstack/react-query"
import listPierrevalCompanies from "core/api/pierreval-company"
import { CustomAxiosError } from "shared/types/axios.type"
import { TPierrevalCompany } from "shared/types/pierreval-company.type"

function useListPierrevalCompanies() {
  return useQuery<TPierrevalCompany[], CustomAxiosError>({
    queryKey: ["listPierrevalCompanies"],
    queryFn: () => listPierrevalCompanies(),
  })
}

export default useListPierrevalCompanies
