import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { useUpdateOperation } from "core/query-hooks/useOperations"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { FormEvent, useState } from "react"
import { registerLocale } from "react-datepicker"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

registerLocale("fr", fr)

export interface IEditDepositDateModalProps {
  isDepositDateModalShowed: boolean
  operationDepositDate: Date | null | undefined
  operationIsDepositDateDefinitive: null | boolean
  toggleDepositDateModal: () => void
}

export function EditDepositDateModal(props: IEditDepositDateModalProps) {
  const {
    isDepositDateModalShowed,
    toggleDepositDateModal,
    operationDepositDate,
    operationIsDepositDateDefinitive,
  } = props
  const { id } = useParams()

  const mutateOperation = useUpdateOperation(+id!)

  const [depositDate, setDepositDate] = useState<Date | null | undefined>(
    operationDepositDate ? new Date(operationDepositDate) : undefined,
  )
  const [isDepositDateDefinitive, setIsDepositDateDefinitive] = useState<
    boolean | null
  >(operationIsDepositDateDefinitive || false)

  const queryClient = useQueryClient()

  async function handleSubmitPurchaseLandDateForm(
    e: FormEvent<HTMLFormElement>,
  ) {
    e.stopPropagation()
    e.preventDefault()

    mutateOperation.mutateAsync(
      {
        depositDate,
        isDepositDateDefinitive,
      },
      {
        onSuccess() {
          toast.success(t("success-mutate-deposit-date"))
          queryClient.invalidateQueries({ queryKey: ["operation", +id!] })
          toggleDepositDateModal()
        },
        onError() {
          toast.error(t("error-mutate-deposit-date"))
        },
      },
    )
  }

  return (
    <Modal
      isShowing={isDepositDateModalShowed}
      closeModal={toggleDepositDateModal}
      title={`${t("edit-bank-deposit-date")}`}
    >
      <form
        className="Text__Field_Container"
        onSubmit={(e) => handleSubmitPurchaseLandDateForm(e)}
      >
        <div className="mt-2 mb-2 flex flex-col">
          <label htmlFor="bank-deposit-date">
            {t("label-bank-deposit-date")}
          </label>
          <CustomDatePicker
            id="bank-deposit-date"
            locale={fr}
            className="Text__Field"
            placeholderText={`${t("date")}`}
            selected={depositDate}
            onChange={(date) => setDepositDate(date!)}
          />
        </div>

        <div>
          <label htmlFor="deposit-date-toggle-btn">
            {`${t("date-is-definitive")}`}
            <div className="flex items-center justify-start sm:mt-2">
              <ToggleSwitch
                id="deposit-date-toggle-btn"
                name="toggle-deposit-date"
                disabled={false}
                checked={isDepositDateDefinitive!}
                onChange={(status) => setIsDepositDateDefinitive(status)}
              />
            </div>
          </label>
        </div>
        <div className="flex items-center justify-between mt-6">
          <Button
            onClick={() => toggleDepositDateModal()}
            size="small"
            mode="secondary"
          >
            {`${t("cancel")}`}
          </Button>

          <Button
            isLoading={mutateOperation.isPending}
            type="submit"
            size="small"
            mode="primary"
          >
            {`${t("update")}`}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
