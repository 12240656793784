import { t } from "i18next"
import { TIdLabel } from "shared/types/global.type"
import { STUDY_STATUS } from "../ressources/study.resources"

export interface ISelectedStatusFiltersProps {
  selectedStatus: number[] | []
  onStatusChange: (selectedStatus: number[] | []) => void
}

function renderStatus(statusValue: number, statusTab: TIdLabel[]) {
  const selectedStatus = statusTab?.find((status) => status.id === statusValue)
  return selectedStatus?.label
}

export default function SelectedStudyRequestsStatusFilters(
  props: ISelectedStatusFiltersProps,
) {
  const { selectedStatus, onStatusChange } = props
  const statusOptions = STUDY_STATUS

  const handleStatusRemove = (statusValueToRemove: number) => {
    const updatedStatusList = selectedStatus.filter(
      (statusValue) => statusValue !== statusValueToRemove,
    )
    onStatusChange(updatedStatusList)
  }

  return (
    <div>
      {selectedStatus.map((statusValue) => (
        <span
          id="badge-dismiss-default"
          className="inline-flex items-center px-2 py-1 mr-2 my-0.5 text-sm font-medium text-yellow-800 bg-yellow-100 rounded"
        >
          {renderStatus(statusValue, statusOptions)}
          <button
            onClick={() => handleStatusRemove(statusValue)}
            type="button"
            className="inline-flex items-center p-0.5 ml-2 text-sm text-yellow-400 bg-transparent rounded-sm hover:bg-purlple-200 hover:text-yellow-900"
            data-dismiss-target="#badge-dismiss-default"
            aria-label="Remove"
          >
            <svg
              aria-hidden="true"
              className="w-3.5 h-3.5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">{t("delete")}</span>
          </button>
        </span>
      ))}
    </div>
  )
}
