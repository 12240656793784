import Spinner from "components/Spinner/Spinner"
import { useGetEnvironmentById } from "core/query-hooks/useEnvironments"
import { t } from "i18next"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useState } from "react"
import { useParams } from "react-router-dom"
import DisplayEnvironement from "./DisplayEnvironment"
import EditEnvironement from "./EditEnvironment"

export default function ProjectEnvironment() {
  const { id } = useParams()
  const integerId = +id!
  const {
    data: environment,
    isLoading,
    error,
  } = useGetEnvironmentById(integerId)

  const [isEdit, setIsEdit] = useState<boolean>(false)

  return (
    <>
      <DivStickyMenu heightClass="pt-36" />
      <div className="p-4">
        {isLoading ? <Spinner /> : null}
        {environment ? (
          <>
            {isEdit ? (
              <EditEnvironement
                environment={environment}
                setIsEdit={setIsEdit}
              />
            ) : (
              <DisplayEnvironement
                environment={environment}
                setIsEdit={setIsEdit}
              />
            )}
          </>
        ) : null}
        {error ? t("environment-data-error") : null}
      </div>
    </>
  )
}
