import { t } from "i18next"
import { Fragment, ReactNode, useEffect, useRef, useState } from "react"

interface TDropdownFiltersProps<T> {
  options: T[]
  renderLabel: () => ReactNode
  renderOption: (item: T, index: number) => ReactNode
  keyExtractor: (item: T) => string | number
  handleChangeOptions?: (search: string) => void
  onClickOutside?: () => void
}

export default function DropdownFilters<T>(props: TDropdownFiltersProps<T>) {
  const {
    options,
    renderOption,
    keyExtractor,
    renderLabel,
    handleChangeOptions,
    onClickOutside,
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState("left-0")

  const ref = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref.current &&
      event.target instanceof Node &&
      !ref.current?.contains(event.target)
    ) {
      setIsOpen(false)
      if (onClickOutside) onClickOutside()
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const handleResize = () => {
    if (ref.current) {
      const viewportWidth = window.innerWidth
      const containerRect = ref.current.getBoundingClientRect()
      if (containerRect.right > viewportWidth) {
        setDropdownPosition("right-0")
      } else if (containerRect.left < viewportWidth / 2) {
        setDropdownPosition("left-0")
      } else {
        setDropdownPosition("right-0")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleResize)
    handleResize()
    return () => {
      window.removeEventListener("click", handleResize)
    }
  }, [])

  return (
    <div className="relative inline-block text-left h-full" ref={ref}>
      <button
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        className="h-full inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {renderLabel()}
      </button>
      {isOpen && (
        <div
          className={`${dropdownPosition} max-h-72 overflow-auto absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          {handleChangeOptions && (
            <input
              autoFocus
              maxLength={30}
              className="w-full pb-2 pt-2 pl-4 outline-0"
              type="text"
              placeholder={`${t("search")}`}
              onChange={(e) => handleChangeOptions(e.target.value)}
            />
          )}

          <ul role="none" className="py-1">
            {options.map((option, index) => (
              <Fragment key={keyExtractor(option)}>
                {renderOption(option, index)}
              </Fragment>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
