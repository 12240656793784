import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import { Header } from "components/Header/Header"
import { SearchInput } from "components/SearchInput/SearchInput"
import LightTable from "components/Table/LightTable"
import { useGetPlots } from "core/query-hooks/usePlots"
import GedTree from "features/ged/GedTree"
import IHOCLoader from "hoc/HocLoader"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { PROJECT_PLOTS_VIEWTAG } from "shared/resources/view-tags.resources"
import { TPlot } from "shared/types/plot.type"
import { useDebouncedCallback } from "use-debounce"

function ProjectPlots() {
  const { id } = useParams()
  const [searchText, setSearchText] = useState<string>()
  const {
    data: plotsData,
    isFetched,
    isLoading,
    isError,
    error,
  } = useGetPlots(id!, searchText)
  const { t } = useTranslation()
  const [results, setResults] = useState<[] | TPlot[]>([])
  const navigate = useNavigate()
  const [sumLine, setSumLine] = useState<{ title: string; id: number }[] | []>(
    [],
  )

  const headers = [
    t("plot"),
    t("surface"),
    t("price"),
    t("owner"),
    t("sqm-price"),
  ]

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("plot")}`, value: ["prefix", "section", "number"] },
    { key: `${t("surface")}`, value: ["area", "val"] },
    { key: `${t("price")}`, value: ["price", "val"] },
    { key: `${t("owner")}`, value: ["owner"] },
    { key: `${t("sqm-price")}`, value: ["pricePerAreaUnit", "val"] },
  ]

  const formatPlots = (plots: TPlot[]) =>
    plots?.map((plot) => ({
      ...plot,
      name: `${plot.prefix} ${plot.section} ${plot.number}`,
    }))

  useEffect(() => {
    if (plotsData) setResults(formatPlots(plotsData.data!))
  }, [plotsData])

  useEffect(() => {
    let priceSum = 0
    let surfaceSum = 0
    let currency
    const pricePerSqm: any[] = []

    if (results.length > 0) {
      for (let i = 0; i < results.length; i += 1) {
        currency = currency || (results[i]?.price?.unit ?? "EUR")

        const priceValue = results[i]?.price?.val || "0"
        priceSum += parseFloat(priceValue)

        surfaceSum += results[i].area.val
        pricePerSqm.push([
          results[i]?.price?.unit ?? "EUR",
          results[i]?.price?.val ?? 0 / results[i].area.val,
        ])
      }
    }
    setSumLine([
      { title: t("plots.sumLine.total"), id: 1 },
      {
        title: `${surfaceSum.toLocaleString("fr-FR")} ${t(
          "area.sqm.abbreviation",
        )}`,
        id: 2,
      },
      {
        title: `${priceSum.toLocaleString("fr-FR")} ${
          currency === "EUR" ? t("euro-symbol") : t("dong-symbol")
        }`,
        id: 3,
      },
      { title: "", id: 4 },
      {
        title: `${Math.round(priceSum / surfaceSum).toLocaleString("fr-FR")} ${
          pricePerSqm[0] && pricePerSqm[0][0] === "EUR" ? "€ / m²" : "₫ / m²"
        }`,
        id: 5,
      },
    ])
  }, [results, setSumLine, plotsData])

  const handleTextChange = useDebouncedCallback(
    useCallback(
      (sT: string) => {
        setSearchText(sT)
      },
      [searchText],
    ),
    500,
  )

  function onClickRow(plotId: number) {
    navigate(`/project/${id}/plots/${plotId}`)
  }

  if (error) return <p>{error.response?.data.error.message}</p>

  return (
    <>
      <DivStickyMenu heightClass="pt-36" />
      <div className="px-4">
        <div className="lg:grid lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-4">
          <div className="col-span-3">
            <div className="flex items-center mb-2 justify-between">
              <div className="pr-2">
                <Header>{`${t("plot")}`}</Header>
              </div>
              <Button
                size="medium"
                mode="primary"
                isLoading={false}
                onClick={() => navigate(`/project/${id}/plots/add`)}
              >
                {t("add")}
              </Button>
            </div>

            <div className="Light_Table__Wrapper shadow-lg rounded-lg">
              {searchText || (isFetched && results && results.length > 0) ? (
                <div className="Table__Search flex justify-between items-center">
                  <SearchInput
                    searchLabel={t("plots.searchText")}
                    handleChange={handleTextChange}
                  />
                </div>
              ) : null}
              <IHOCLoader error={error} isLoading={isLoading} isError={isError}>
                {isFetched && plotsData && results.length > 0 ? (
                  <LightTable
                    showTotal={false}
                    totalItems={sumLine}
                    className="table-auto Table__Wrapper_NoTop"
                    items={plotsData?.data!}
                    headers={headers}
                    sortableFields={sortableFields}
                    renderTotal={(totalItem) => totalItem}
                    renderRow={(item) => (
                      <>
                        <td
                          onClick={() => onClickRow(item.id)}
                          className="cursor-pointer"
                        >
                          <div className="flex items-center text-blue-500 hover:text-blue-600 transition-colors ease-in-out delay-75">
                            {item.prefix} {item.section} {item.number}
                          </div>
                        </td>
                        <td
                          onClick={() => onClickRow(item.id)}
                          className="cursor-pointer"
                        >
                          {item.area ? (
                            <div className="flex items-center">
                              {item.area.val} {item.area.unit}
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          onClick={() => onClickRow(item.id)}
                          className="cursor-pointer"
                        >
                          {item.price ? (
                            <div className="flex items-center">
                              {`${parseFloat(item.price.val).toLocaleString(
                                "fr-FR",
                              )} ${
                                item.price.unit === "EUR"
                                  ? t("euro-symbol")
                                  : t("dong-symbol")
                              }`}
                            </div>
                          ) : (
                            <div className="flex items-center">-</div>
                          )}
                        </td>
                        <td
                          onClick={() => onClickRow(item.id)}
                          className="cursor-pointer"
                        >
                          {item.owner ? (
                            <div className="flex items-center">
                              {item.owner}
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          onClick={() => onClickRow(item.id)}
                          className="cursor-pointer"
                        >
                          {item.price && item.area.val ? (
                            <div className="flex items-center">
                              {Math.round(
                                parseFloat(item.price.val) / item.area.val,
                              ).toLocaleString("fr-FR")}{" "}
                              {item.price.unit === "EUR"
                                ? t("euro-symbol")
                                : t("dong-symbol")}{" "}
                              / {item.area.unit}
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                      </>
                    )}
                  />
                ) : (
                  <p className="Color__Primary pl-2 pb-2 Table__Search rounded-lg">
                    {t("no-data-found-message")}
                  </p>
                )}
              </IHOCLoader>
            </div>
          </div>

          <div className="pt-4 lg:pt-0 col-span-1 mb-4">
            <div className="mb-4">
              <Header>{`${t("ged.documents")}`}</Header>
            </div>
            <Box>
              <GedTree view={PROJECT_PLOTS_VIEWTAG} projectId={+id!} />
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectPlots
