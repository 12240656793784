import { useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import { TSubfoldersResponse } from "shared/types/ged.type"
import getSubfolders from "../api/metadata"

export default function useGetSubfolders(
  fieldName: string,
  condition: string,
  registrationNumbers: string[],
) {
  return useQuery<TSubfoldersResponse, CustomAxiosError>({
    queryKey: ["getSubfolders", fieldName, condition],
    queryFn: () =>
      condition === undefined
        ? { subfolders: [] }
        : getSubfolders(fieldName, condition, registrationNumbers),
  })
}
