import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import ButtonIcon from "components/Button/ButtonIcon"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { useUpdateCpr } from "core/query-hooks/useCprs"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { FormEvent, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { MdEdit } from "react-icons/md"
import { toast } from "react-toastify"
import { TCpr, TCreateCpr } from "shared/types/cpr.type"
import * as yup from "yup"
import CprToggle from "./CprToggle"

const validationSchema = yup.object().shape({
  childOperationId: yup.number().required(),
  clientName: yup.string().required(() => `${t("required-client-name")}`),
  cprSignature: yup.date().nullable(),
  isCprSignatureDefinitive: yup.boolean(),
  cprDueDate: yup.date().nullable(),
  isCprDueDateDefinitive: yup.boolean(),
})

export interface IEditCprModalProps {
  cpr: TCpr
}

export default function EditCprModal(props: IEditCprModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const { cpr } = props
  const mutation = useUpdateCpr(cpr.id)
  const queryClient = useQueryClient()
  const [cprSignature, setCprSignature] = useState<null | Date | undefined>(
    cpr.cprSignature ? new Date(cpr.cprSignature) : null,
  )
  const [cprDueDate, setCprDueDate] = useState<null | Date | undefined>(
    cpr.cprDueDate ? new Date(cpr.cprDueDate) : null,
  )
  const [isCprSignatureDefinitive, setIsCprSignatureDefinitive] =
    useState<boolean>(cpr.isCprSignatureDefinitive)
  const [isCprDueDateDefinitive, setIsCprDueDateDefinitive] = useState<boolean>(
    cpr.isCprDueDateDefinitive,
  )

  const {
    handleSubmit,
    control,
    reset,
    register,
    formState,
    setValue,
    formState: { errors, isSubmitSuccessful, isSubmitted },
  } = useForm<TCreateCpr>({
    resolver: yupResolver(validationSchema as yup.ObjectSchema<TCreateCpr>),
    defaultValues: {
      childOperationId: cpr.childOperationId,
      clientName: cpr.clientName,
      cprSignature: cpr.cprSignature,
      isCprSignatureDefinitive: cpr.isCprSignatureDefinitive,
      cprDueDate: cpr.cprDueDate,
      isCprDueDateDefinitive: cpr.isCprDueDateDefinitive,
    },
  })

  useEffect(() => {
    setValue("clientName", cpr.clientName)
    setValue("cprSignature", cpr.cprSignature)
    setValue("cprDueDate", cpr.cprDueDate)
    setValue("isCprSignatureDefinitive", cpr.isCprSignatureDefinitive)
    setValue("isCprDueDateDefinitive", cpr.isCprDueDateDefinitive)
    setIsCprSignatureDefinitive(cpr.isCprSignatureDefinitive)
    setIsCprDueDateDefinitive(cpr.isCprDueDateDefinitive)
    setCprSignature(cpr.cprSignature)
    setCprDueDate(cpr.cprDueDate)
  }, [cpr])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [formState, isSubmitted, reset])

  const handleToggleIsSignatureDefinitive = () => {
    setValue("isCprSignatureDefinitive", !isCprSignatureDefinitive)
    setIsCprSignatureDefinitive(!isCprSignatureDefinitive)
  }

  const handleToggleIsDueDateDefinitive = () => {
    setValue("isCprDueDateDefinitive", !isCprDueDateDefinitive)
    setIsCprDueDateDefinitive(!isCprDueDateDefinitive)
  }

  const handleCloseModal = () => {
    reset()
    setCprDueDate(undefined)
    setCprSignature(undefined)
    setIsCprSignatureDefinitive(false)
    setIsCprDueDateDefinitive(false)
    toggleModal()
  }

  const submitForm = async (data: TCreateCpr) => {
    mutation.mutateAsync(data, {
      onSuccess: () => {
        toast.success(`${t("toast-cpr-updated-success")}`)
        queryClient.invalidateQueries({
          queryKey: ["listCprs", cpr.childOperationId],
        })
        handleCloseModal()
      },
      onError: (err) => {
        toast.error(
          `${t("toast-cpr-updated-error")} ${err.response?.data.error.message}`,
        )
      },
    })
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <>
      <ButtonIcon className="ml-2" type="button" onClick={() => toggleModal()}>
        <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
      </ButtonIcon>
      <Modal
        isShowing={isModalShowed}
        closeModal={handleCloseModal}
        title={`${t("edit-cpr")}`}
      >
        <form className="Text__Field_Container" onSubmit={onSubmit}>
          <label className="font-medium w-full" htmlFor="clientName">
            {`${t("client-name")}`}
            <input
              id="clientName"
              className="Text__Field"
              type="text"
              {...register("clientName")}
            />
          </label>
          {errors?.clientName && (
            <p className="text-red-600 text-sm">{errors.clientName.message}</p>
          )}

          <div className="mt-4 flex flex-col">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <label className="font-medium mr-4" htmlFor="cprSignature">
                  {`${t("prc-signature")}`}
                </label>
                <Controller
                  name="cprSignature"
                  control={control}
                  render={({ field }) => (
                    <CustomDatePicker
                      id="cprSignature"
                      locale={fr}
                      className="Text__Field"
                      placeholderText={`${t("prc-signature")}`}
                      selected={cprSignature}
                      onChange={(date) => {
                        setCprSignature(date!)
                        field.onChange(date)
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col">
                <label className="font-medium mr-4" htmlFor="cprSignature">
                  {`${t("realized")}`}
                </label>
                <div className="pt-2">
                  <CprToggle
                    id="is-cpr-signature-definitive"
                    name="toggle-is-cpr-signature-definitive"
                    checked={isCprSignatureDefinitive}
                    onChange={handleToggleIsSignatureDefinitive}
                  />
                </div>
              </div>
            </div>
            {errors?.cprSignature && (
              <p className="text-red-600 text-sm">
                {errors.cprSignature.message}
              </p>
            )}
          </div>

          <div className="mt-4 flex flex-col">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <label className="font-medium mr-4" htmlFor="cprDueDate">
                  {`${t("due-date")}`}
                </label>
                <Controller
                  name="cprDueDate"
                  control={control}
                  render={({ field }) => (
                    <CustomDatePicker
                      id="cprDueDate"
                      locale={fr}
                      className="Text__Field"
                      placeholderText={`${t("due-date")}`}
                      selected={cprDueDate}
                      onChange={(date) => {
                        setCprDueDate(date!)
                        field.onChange(date)
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col">
                <label className="font-medium mr-4" htmlFor="cprSignature">
                  {`${t("realized")}`}
                </label>
                <div className="pt-2">
                  <CprToggle
                    id="is-cpr-due-date-definitive"
                    name="toggle-is-cpr-due-date-definitive"
                    checked={isCprDueDateDefinitive}
                    onChange={handleToggleIsDueDateDefinitive}
                  />
                </div>
              </div>
            </div>
            {errors?.cprDueDate && (
              <p className="text-red-600 text-sm">
                {errors.cprDueDate.message}
              </p>
            )}
          </div>

          <div className="Modal__Footer mt-2">
            <Button
              type="button"
              size="medium"
              mode="secondary"
              onClick={() => {
                toggleModal()
                reset()
              }}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              marginLeft={24}
              size="medium"
              mode="primary"
              isLoading={mutation.isPending}
              type="submit"
            >
              {`${t("update")}`}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
