import { API } from "core/api/axios"
import { format } from "date-fns"
import {
  TCreateSuspensiveCondition,
  TDefaultSuspensiveCondition,
  TSuspensiveCondition,
  TUpdateSuspensiveCondition,
} from "shared/types/suspensiveCondition.type"

async function getSuspensiveConditionsByAgreementId(
  agreementId?: string,
): Promise<TSuspensiveCondition[]> {
  return typeof agreementId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/suspensive-conditions?agreement=${agreementId}`).then(
        (response) => response.data,
      )
}

async function getSuspensiveConditionsByOperationId(
  operationId: string,
  lte?: Date,
  gte?: Date,
  isDefinitive?: boolean,
): Promise<TSuspensiveCondition[]> {
  return typeof operationId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(
        `/suspensive-conditions?operation=${operationId}${lte ? `&lte=${format(lte, "yyyy-MM-dd")}` : ""}${gte ? `&gte=${format(gte, "yyyy-MM-dd")}` : ""}${typeof isDefinitive !== "undefined" ? `&isDefinitive=${isDefinitive}` : ""}`,
      ).then((response) => response.data)
}

async function getDefaultSuspensiveConditions(): Promise<
  TDefaultSuspensiveCondition[]
> {
  const res = await API.get("/default-suspensive-conditions")
  return res.data
}

async function createSuspensiveConditions(
  data: TCreateSuspensiveCondition,
): Promise<TSuspensiveCondition> {
  const res = await API.post("/suspensive-condition", data)
  return res.data
}

async function updateSuspensiveCondition(
  id: number,
  suspensiveCondition: TUpdateSuspensiveCondition,
): Promise<TSuspensiveCondition> {
  return typeof id === "undefined"
    ? Promise.reject(new Error("Invalid suspensive condition id"))
    : API.patch(`/suspensive-condition/${id}`, suspensiveCondition).then(
        (response) => response.data,
      )
}

async function deleteSuspensiveCondition(id: number) {
  return typeof id === "undefined"
    ? Promise.reject(new Error("Invalid suspensive condition id"))
    : API.delete(`/suspensive-condition/${id}`).then(
        (response) => response.data,
      )
}

export {
  createSuspensiveConditions,
  deleteSuspensiveCondition,
  getDefaultSuspensiveConditions,
  getSuspensiveConditionsByAgreementId,
  getSuspensiveConditionsByOperationId,
  updateSuspensiveCondition,
}
