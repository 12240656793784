import { format } from "date-fns"
import { HiOutlineExternalLink } from "react-icons/hi"
import { MdInsertDriveFile } from "react-icons/md"
import truncateStringFromMiddle from "utils/truncateStringFromMiddle"

interface IDocumentLinkProps {
  id: string
  title: string
  type?: string
  lastModifiedDate?: Date
}

export default function GedLink(props: IDocumentLinkProps) {
  const { id, title, type, lastModifiedDate } = props
  return (
    <div
      title={title}
      className="flex items-center text-blue-700 hover:text-blue-600 transition-colors ease-in-out delay-75 "
      onClick={() =>
        window.open(
          `${process.env.REACT_APP_DOCUWARE_DOCUMENT_VIEWER_URL}${id}`,
          "_blank",
        )
      }
    >
      <span className="mr-1.5">
        <MdInsertDriveFile />
      </span>
      {`${truncateStringFromMiddle(title, 40)}${type ?? ""} ${
        (lastModifiedDate &&
          format(new Date(lastModifiedDate), "dd/MM/yyyy")) ??
        ""
      }`}
      <span className="ml-1">
        <HiOutlineExternalLink />
      </span>
    </div>
  )
}
