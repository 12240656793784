import classnames from "classnames"
import Spinner from "components/Spinner/Spinner"
import * as React from "react"

interface IHOCLoaderProps {
  error: unknown
  isLoading: boolean
  isError: boolean
  children: React.ReactNode
  classNames?: string
  errorMessage?: string | undefined
}

function IHOCLoader({
  isLoading,
  isError,
  classNames,
  error,
  children,
  errorMessage,
}: IHOCLoaderProps) {
  if (isLoading)
    return (
      <div className="flex justify-center py-2">
        <Spinner />
      </div>
    )
  if (isError) {
    return (
      <p className={classnames("Text__Red_400 Flex Items__Center", classNames)}>
        {errorMessage || JSON.stringify(error)}
      </p>
    )
  }
  return <div>{children}</div>
}
export default IHOCLoader
