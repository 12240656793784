import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import useGetPartialBatches from "core/query-hooks/useLotsPartialSpo"
import { useCountSpoLot } from "core/spo/query-hooks/useLots"
import { t } from "i18next"
import { useCallback } from "react"
import { PiSealWarningDuotone } from "react-icons/pi"

export interface ITabOperationChildProps {
  setSelectedTab: (index: number) => void
  index: number
  title: string
  registrationNumber: string | null
  isActive?: boolean
}

export function TabOperationChild({
  setSelectedTab,
  index,
  title,
  registrationNumber,
  isActive,
}: ITabOperationChildProps) {
  const handleOnClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  let spoLots:
    | {
        registrationNumber: string
        numberOfLots: number
      }
    | undefined

  let numberOfPartialBatches: number | undefined

  if (registrationNumber) {
    spoLots = useCountSpoLot(registrationNumber).data
    numberOfPartialBatches = useGetPartialBatches(registrationNumber).data
  }

  return (
    <li className={`${isActive && "Tab__Active"}`}>
      <button type="button" className="Tab__Button" onClick={handleOnClick}>
        {numberOfPartialBatches ? (
          <TooltipWithHover
            message={`${t("real-batches-number")} : ${numberOfPartialBatches}`}
            position="middle-right"
          >
            <div className="inline-flex items-center">
              <span className="pr-2 font-semibold text-sm text-primary-light">
                {`${title}  ${
                  spoLots?.numberOfLots
                    ? ` - ${spoLots?.numberOfLots} ${t("lots-caps")}`
                    : ""
                }`}
              </span>
              <PiSealWarningDuotone className="Text__Orange" size={22} />
            </div>
          </TooltipWithHover>
        ) : (
          <span className="font-semibold text-sm text-primary-light">
            {`${title}  ${
              spoLots?.numberOfLots
                ? ` - ${spoLots?.numberOfLots} ${t("lots-caps")}`
                : ""
            }`}
          </span>
        )}
      </button>
    </li>
  )
}
