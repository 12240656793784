import classNames from "classnames"
import ItemsCounter from "components/Counter/ItemsCounter"
import ArrowLeft from "components/Icons/ArrowLeft"
import { LinkCustom } from "components/Link/Link"
import LightTable from "components/Table/LightTable"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useListStudyRequestsTodos } from "core/query-hooks/useStudyRequests"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import { format } from "date-fns"
import { t } from "i18next"
import { Fragment } from "react"
import { FaPencilRuler } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom"
import { getActionHtml, getActionLabel } from "utils/getActionLabelStudyRequest"
import getStudyRequestUrl from "utils/getStudyRequestUrl"

export default function UserStudyRequestsTodos() {
  const navigate = useNavigate()
  const { data: user } = useGetCurrentUser()
  const {
    data: studyRequestsTodos,
    isLoading,
    error,
  } = useListStudyRequestsTodos(user?.azureId!)

  const enhancedTodos = studyRequestsTodos?.map((todo) => {
    return { ...todo, statusLabel: getActionLabel(todo.statusId, todo.typeId) }
  })

  const headers = [
    t("creation-date"),
    t("city"),
    t("address"),
    t("action"),
    t("creator"),
  ]

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("creation-date")}`, value: ["createdAt"] },
    { key: `${t("city")}`, value: ["project", "city"] },
    { key: `${t("address")}`, value: ["project", "address"] },
    { key: `${t("action")}`, value: ["statusLabel"] },
    { key: `${t("creator")}`, value: ["creator", "firstName"] },
  ]

  return (
    <div className="lg:grid lg:grid-cols-6 lg:gap-4 px-8 py-6">
      <div className="w-full">
        <LinkCustom
          icon={ArrowLeft}
          iconSize={16}
          iconPosition="left"
          onClick={() => navigate(`/`)}
        >
          {t("back-to-home")}
        </LinkCustom>
      </div>
      <div className="col-start-6 col-span-1">
        <ItemsCounter
          title={t("studies")}
          Icon={<FaPencilRuler height={18} width={18} color="#00304D" />}
          totalItems={enhancedTodos ? enhancedTodos.length : 0}
          isLoading={isLoading}
          error={error}
          errorMessage={t("error-loading-ged-todos")}
          classNames="bg-white"
        />
      </div>
      <PermissionsGuard requiredRoles={["read:study-request.todo-list"]}>
        {enhancedTodos ? (
          <>
            <h2 className="col-span-6 font-semibold">
              {t("expected-study-requests")}
            </h2>
            <div className="col-span-6 Light_Table__Wrapper Table__Wrapper_NoTop">
              {enhancedTodos.length === 0 ? (
                <div className="rounded-lg bg-white p-4 text-center text-primary">
                  {t("no-study-request-todos")}
                </div>
              ) : (
                <LightTable
                  showTotal={false}
                  className="table-auto"
                  items={enhancedTodos}
                  headers={headers}
                  sortableFields={sortableFields}
                  renderRow={(item, index) => (
                    <Fragment key={item.id}>
                      <td
                        className={classNames({
                          "border-t border-gray-200": index === 0,
                          Table__Bottom_Border:
                            enhancedTodos.length - 1 === index,
                        })}
                      >
                        <div className="flex items-center">
                          {format(new Date(item.createdAt), "dd/MM/yyyy")}
                        </div>
                      </td>
                      <td
                        className={classNames({
                          "border-t border-gray-200": index === 0,
                          Table__Bottom_Border:
                            enhancedTodos.length - 1 === index,
                        })}
                      >
                        <div className="flex items-center">
                          {item.project.city}
                        </div>
                      </td>
                      <td
                        className={classNames({
                          "border-t border-gray-200": index === 0,
                          Table__Bottom_Border:
                            enhancedTodos.length - 1 === index,
                        })}
                      >
                        <div className="flex items-center">
                          <Link
                            to={getStudyRequestUrl(
                              item.id,
                              item.project.id,
                              item.project.isRegistered
                                ? "operation"
                                : "project",
                              item.typeId,
                            )}
                            className="text-blue-600"
                          >
                            {item.project.address}
                          </Link>
                        </div>
                      </td>
                      <td
                        className={classNames({
                          "border-t border-gray-200": index === 0,
                          Table__Bottom_Border:
                            enhancedTodos.length - 1 === index,
                        })}
                      >
                        <div className="flex items-center">
                          {getActionHtml(item.statusId, item.typeId)}
                        </div>
                      </td>
                      <td
                        className={classNames({
                          "border-t border-gray-200": index === 0,
                          Table__Bottom_Border:
                            enhancedTodos.length - 1 === index,
                        })}
                      >
                        <div className="flex items-center">
                          {item.creator.firstName} {item.creator.lastName}
                        </div>
                      </td>
                    </Fragment>
                  )}
                />
              )}
            </div>
          </>
        ) : null}
      </PermissionsGuard>
    </div>
  )
}
