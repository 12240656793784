import { SVGProps } from "react"

function Microsoft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.604 8.212H0V.607h7.604v7.605Z" fill="#F1511B" />
      <path d="M16 8.212H8.396V.607H16v7.605Z" fill="#80CC28" />
      <path d="M7.604 16.61H0V9.006h7.604v7.604Z" fill="#00ADEF" />
      <path d="M16 16.61H8.396V9.006H16v7.604Z" fill="#FBBC09" />
    </svg>
  )
}

export default Microsoft
