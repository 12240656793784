import { useEffect, useRef, useState } from "react"

interface ValidRefTarget {
  contains(target: EventTarget | null): any
}

function useOutsideAlerter(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (
        ref.current &&
        !(ref as React.RefObject<ValidRefTarget>).current?.contains(
          event.target,
        )
      ) {
        return setIsComponentVisible(false)
      }
      return undefined
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
  return { ref, isComponentVisible, setIsComponentVisible }
}

export default useOutsideAlerter
