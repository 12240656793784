import { useMutation, useQuery } from "@tanstack/react-query"
import {
  createPlot,
  deletePlot,
  getFreePlotsByProjectId,
  getPlot,
  getPlots,
  getPlotsByAgreementId,
  updatePlot,
} from "core/api/plots"
import { CustomAxiosError } from "shared/types/axios.type"
import { TMutatePlot, TPlot, TPlotList } from "shared/types/plot.type"

function useCreatePlot() {
  return useMutation<TPlot, CustomAxiosError, TMutatePlot>({
    mutationFn: (plot) => createPlot(plot),
  })
}

function useGetPlot(id: string) {
  return useQuery<TPlot, CustomAxiosError, TPlot>({
    queryKey: ["plot"],
    queryFn: () => getPlot(id),
  })
}

function useGetPlots(projectId: string, search: string = "") {
  return useQuery<TPlotList, CustomAxiosError, TPlotList>({
    queryKey: ["plots", projectId, search],
    queryFn: () => getPlots(projectId, search),
    ...{
      enabled: Boolean({
        projectId,
        search,
      }),
    },
  })
}

function useUpdatePlot(id: number) {
  return useMutation<TPlot, CustomAxiosError, TMutatePlot>({
    mutationFn: (plot) => updatePlot(id, plot),
  })
}

function useDeletePlot(id: string) {
  return useMutation<TPlot, CustomAxiosError, string>({
    mutationFn: () => deletePlot(id),
  })
}

function useGetFreePlotsByProjectId(projectId: string) {
  return useQuery<TPlotList, CustomAxiosError, TPlotList>({
    queryKey: ["getFreePlotsByProjectId", projectId],
    queryFn: () => getFreePlotsByProjectId(projectId),
  })
}

function useGetPlotsByAgreementId(agreementId: string) {
  return useQuery<TPlotList, CustomAxiosError, TPlotList>({
    queryKey: ["getPlotsByAgreementId", agreementId],
    queryFn: () => getPlotsByAgreementId(agreementId),
  })
}

export {
  useCreatePlot,
  useDeletePlot,
  useGetFreePlotsByProjectId,
  useGetPlot,
  useGetPlots,
  useGetPlotsByAgreementId,
  useUpdatePlot,
}
