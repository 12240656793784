import { useMutation, useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TIsProjectPinned,
  TPinnedProject,
  TPinnedProjectQuery,
  TPinnedProjectStatus,
  TUser,
} from "shared/types/user.type"
import { logout } from "utils/firebase"
import {
  getCurrentUser,
  getDocuwareCookie,
  getUser,
  getUserAvatar,
  getUsers,
  getUsersByOccupationId,
  getUsersOccupations,
  isProjectPinned,
  listDevelopers,
  listPinnedProjectsByUser,
  listSubordinates,
  togglePinProject,
} from "../api/users"

function useGetUsersByOccupationId(id: number) {
  return useQuery<TUser[], CustomAxiosError, TUser[]>({
    queryKey: ["usersByoccupationId", id],
    queryFn: () => getUsersByOccupationId(id),
  })
}

function useListUsers() {
  return useQuery<TUser[], CustomAxiosError>({
    queryKey: ["users"],
    queryFn: () => getUsers(),
  })
}

function useGetUserByAzureId(id: string) {
  return useQuery<TUser, CustomAxiosError, TUser>({
    queryKey: ["user", id],
    queryFn: () => getUser(id),
    ...{
      staleTime: Infinity,
    },
  })
}

const usersOccupationsQuery = () => ({
  queryKey: ["userOccupations", "list"],
  queryFn: async () => {
    const userOccupations = await getUsersOccupations()
    return userOccupations
  },
})

function useIsProjectPinned(query: TPinnedProjectQuery) {
  return useQuery<TIsProjectPinned, CustomAxiosError, TIsProjectPinned>({
    queryKey: ["isProjectPinned", query.userAzureId, query.projectId],
    queryFn: () => isProjectPinned(query),
    ...{ enabled: !!query.userAzureId },
  })
}

function useTogglePinProject() {
  return useMutation<
    TPinnedProjectStatus,
    CustomAxiosError,
    TPinnedProjectQuery
  >({ mutationFn: (params) => togglePinProject(params) })
}

function useListPinnedProjectsByUser(userAzureId: string) {
  return useQuery<TPinnedProject[], CustomAxiosError>({
    queryKey: ["pinnedProjects", userAzureId],
    queryFn: () => listPinnedProjectsByUser(userAzureId),
  })
}

function useGetDocuwareCookie(userAzureId: string) {
  return useQuery<string, CustomAxiosError, string>({
    queryKey: ["getDocuwareCookie", userAzureId],
    queryFn: () => getDocuwareCookie(userAzureId),
  })
}

function useListDevelopers() {
  return useQuery<TUser[], CustomAxiosError, { selectableDevelopers: TUser[] }>(
    {
      queryKey: ["listDevelopers"],
      queryFn: () => listDevelopers(),
      ...{
        suspense: true,
      },
    },
  )
}

function useGetCurrentUser() {
  return useQuery<TUser, CustomAxiosError, TUser>({
    queryKey: ["getCurrentUser"],
    queryFn: async () => {
      const user = await getCurrentUser()
      if (!user.accountEnabled) {
        await logout()
      }
      return user
    },
    ...{
      suspense: true,
    },
  })
}

function useListSubordinates(userAzureId: string) {
  return useQuery<{ subordinates: TUser[] }, CustomAxiosError>({
    queryKey: ["subordinates", userAzureId],
    queryFn: () => listSubordinates(userAzureId),
    ...{
      suspense: true,
    },
  })
}

function useGetAvatar(userAzureId: string) {
  return useQuery<string, CustomAxiosError>({
    queryKey: ["avatar", userAzureId],
    queryFn: () => getUserAvatar(userAzureId),
    ...{
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  })
}

export {
  useGetAvatar,
  useGetCurrentUser,
  useGetDocuwareCookie,
  useGetUserByAzureId,
  useGetUsersByOccupationId,
  useIsProjectPinned,
  useListDevelopers,
  useListPinnedProjectsByUser,
  useListSubordinates,
  useListUsers,
  useTogglePinProject,
  usersOccupationsQuery,
}
