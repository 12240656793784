import { useListTasksByAgreementId } from "core/query-hooks/useTasks"
import IHOCLoader from "hoc/HocLoader"
import { useParams } from "react-router-dom"
import { TasksList } from "./TasksList"

export default function TasksBox() {
  const params = useParams()

  const {
    data: tasksData,
    isLoading,
    isError,
    error,
  } = useListTasksByAgreementId(params.agreementId!)

  return (
    <div className="md:mb-4 sm:mb-4 mb-4">
      <IHOCLoader error={error} isLoading={isLoading} isError={isError}>
        <TasksList tasks={tasksData!} />
      </IHOCLoader>
    </div>
  )
}
