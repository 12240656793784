import classNames from "classnames"
import { Pagination } from "components/Pagination/Pagination"
import { SearchInput } from "components/SearchInput/SearchInput"
import LightTable from "components/Table/LightTable"
import "components/Table/LightTable.scss"
import { useGetAgreementsByProjectId } from "core/query-hooks/useAgreements"
import { format } from "date-fns"
import IHOCLoader from "hoc/HocLoader"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce"
import useIsMobile from "utils/isMobile"
import PlotCell from "./table/PlotCell"

interface IAgreementList {
  routeName: string
}

export default function AgreeementList({ ...props }: IAgreementList) {
  const { routeName } = props
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState<string>()
  const [nextPage, setNextPage] = useState(1)
  const [pageCount] = useState(0)
  const {
    data: agreements,
    isLoading,
    isFetched,
    isError,
    error,
  } = useGetAgreementsByProjectId(id, searchText)
  const isMobile = useIsMobile()

  const handleTextChange = useDebouncedCallback(
    useCallback(
      (text: string) => {
        setSearchText(text)
      },
      [searchText],
    ),
    500,
  )

  function onClickRow(agreementId: string, route: string) {
    navigate(`/${route}/${id}/agreements/${agreementId}`)
  }

  const headers = [t("entitled"), t("global-price"), t("plot"), t("term-date")]

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("entitled")}`, value: ["name"] },
    { key: `${t("price")}`, value: ["cashPrice"] },
    { key: `${t("term-date")}`, value: ["termDate"] },
  ]

  const handlePageChange = (currentPage: number) => {
    setNextPage?.(currentPage === 0 ? 1 : currentPage)
  }

  if (error) return <p>{error.response?.data.error.message}</p>

  const calculateTotal = (
    cashPrice: string | null | undefined,
    dation: number | null,
    injonctionDeFaire: number | null,
  ): number => {
    const safeNumber = (num: string | number | null | undefined): number => {
      if (typeof num === "string") {
        const parsed = parseFloat(num)
        return Number.isNaN(parsed) ? 0 : parsed
      }
      return num !== null && num !== undefined ? num : 0
    }
    return (
      safeNumber(cashPrice) + safeNumber(dation) + safeNumber(injonctionDeFaire)
    )
  }

  return (
    <div className="Light_Table__Wrapper shadow-lg">
      {searchText ||
      (isFetched && agreements && agreements.data?.length > 0) ? (
        <div className="Table__Search flex justify-between items-center">
          <SearchInput
            className="md:w-1/4 w-3/5"
            searchLabel={t("agreements.searchText")}
            handleChange={handleTextChange}
          />
        </div>
      ) : null}
      <IHOCLoader error={error} isLoading={isLoading} isError={isError}>
        {isFetched && agreements && agreements.data?.length > 0 ? (
          <div className={classNames({ "overflow-x-auto": isMobile })}>
            <LightTable
              showTotal={false}
              className="table-auto Table__Wrapper_NoTop overflow-visible w-full"
              items={agreements?.data!}
              headers={headers}
              overflowAuto={false}
              sortableFields={sortableFields}
              renderRow={(item) => {
                const globalPrice = calculateTotal(
                  item.cashPrice,
                  item.dation,
                  item.injonctionDeFaire,
                )
                return (
                  <>
                    <td
                      onClick={() => onClickRow(item.id, routeName)}
                      className="cursor-pointer"
                    >
                      <div className="flex items-center text-blue-500 hover:text-blue-600 transition-colors ease-in-out delay-75">
                        {item.name}
                      </div>
                    </td>
                    <td
                      onClick={() => onClickRow(item.id, routeName)}
                      className="cursor-pointer"
                    >
                      <div className="flex items-center">
                        {globalPrice === 0
                          ? "-"
                          : `${globalPrice.toLocaleString("fr-FR")} €`}
                      </div>
                    </td>
                    <td>
                      <div
                        className={classNames("flex items-center", {
                          relative: isMobile,
                        })}
                      >
                        <PlotCell agreementId={item.id} routeName={routeName} />
                      </div>
                    </td>
                    <td
                      onClick={() => onClickRow(item.id, routeName)}
                      className="cursor-pointer"
                    >
                      {item.termDate ? (
                        <div className="flex items-center">
                          {format(new Date(item.termDate), "dd/MM/yyyy")}
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                  </>
                )
              }}
            />
          </div>
        ) : (
          <p className="Color__Primary pl-2 pb-2 Table__Search rounded-lg">
            {t("no-data-found-message")}
          </p>
        )}
      </IHOCLoader>

      {pageCount > 1 && (
        <Pagination
          previousLabel="previousLabelPagination"
          nextLabel="nextLabelPagination"
          page={nextPage}
          totalPages={pageCount}
          handlePagination={(data) => handlePageChange(data)}
        />
      )}
    </div>
  )
}
