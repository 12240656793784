import classNames from "classnames"
import { useListChildRecords } from "core/query-hooks/useOperations"
import { differenceInDays } from "date-fns"
import { t } from "i18next"
import { useParams } from "react-router-dom"

export default function CountSuspensiveConditions() {
  const { id } = useParams()
  const { data } = useListChildRecords(id)

  const today = new Date()

  const expiredInLessThanThreeMonths = data?.suspensiveConditions?.filter(
    (sc) =>
      sc.date &&
      !sc.isDefinitive &&
      differenceInDays(new Date(sc.date), today) >= 0 &&
      differenceInDays(new Date(sc.date), today) <= 90,
  )

  const expiredSuspensiveConditions = data?.suspensiveConditions?.filter(
    (sc) => sc.date && !sc.isDefinitive && new Date(sc.date) < today,
  )

  return (
    <>
      <h3 className="text-xs uppercase text-cyan-900 font-medium">
        {t("suspensive-conditions")}
      </h3>

      <ul className="py-2">
        <li
          className={classNames(
            "px-2 flex items-center justify-between rounded mb-1",
            {
              "bg-red-200": expiredSuspensiveConditions?.length,
            },
          )}
        >
          <p className="text-body text-cyan-950">
            {t("suspensive-conditions-late-message")}
          </p>
          <span className="text-sm font-bold text-orange-600">
            {expiredSuspensiveConditions &&
            expiredSuspensiveConditions?.length > 0
              ? expiredSuspensiveConditions.length
              : 0}
          </span>
        </li>

        <li
          className={classNames(
            "px-2 flex items-center justify-between rounded",
            {
              "bg-orange-300/50": expiredInLessThanThreeMonths?.length,
            },
          )}
        >
          <p className="text-body text-cyan-950">
            {t("suspensive-conditions-soon-message")}
          </p>
          <span className="text-sm font-bold text-orange-500">
            {expiredInLessThanThreeMonths &&
            expiredInLessThanThreeMonths?.length > 0
              ? expiredInLessThanThreeMonths?.length
              : 0}
          </span>
        </li>
      </ul>
    </>
  )
}
