import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { Button } from "components"
import Box from "components/Box/Box"
import { Modal } from "components/Modal/Modal"
import Spinner from "components/Spinner/Spinner"
import { useRegisterProject } from "core/query-hooks/useOperations"
import useListPierrevalCompanies from "core/query-hooks/usePierrevalCompanies"
import i18next, { t } from "i18next"
import { FormEvent, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { BsFillBuildingsFill, BsFillHousesFill } from "react-icons/bs"
import { HiBuildingOffice } from "react-icons/hi2"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { TERTIARY_BUSINESS_UNIT_ID } from "shared/resources/business.resources"

import {
  HOUSING_ESTATE_TYPE,
  RESIDENTIAL_TYPE,
  TERTIARY_TYPE,
} from "shared/resources/operation.resources"
import { PIERREVAL } from "shared/resources/pierreval-companies.resources"
import { TRegisterProject, TTypeOperation } from "shared/types/operation.type"
import * as yup from "yup"

export interface ICreateOperationModalProps {
  projectId: number
  isShowing: boolean
  toggleModal: () => void
  businessUnitId: number
}

export function CreateOperationModal(props: ICreateOperationModalProps) {
  const { projectId, isShowing, toggleModal, businessUnitId } = props
  const registerProject = useRegisterProject(projectId)
  const { data: pierrevalCompanies } = useListPierrevalCompanies()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [operationId, setOperationId] = useState<TTypeOperation>()
  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object({
    pierrevalCompanyId: yup
      .number()
      .required(() => i18next.t("error-pierreval-company-mandatory"))
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .positive(),
    typeId: yup
      .number()
      .required(() => i18next.t("error-operation-type-mandatory"))
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .positive(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TRegisterProject>({
    resolver: yupResolver(schema as yup.ObjectSchema<TRegisterProject>),
  })

  useEffect(() => {
    isShowing && (document.body.style.overflow = "hidden")
    return () => {
      document.body.style.overflow = "visible"
    }
  }, [isShowing])

  function submitForm(formRegister: TRegisterProject) {
    setIsLoading(true)
    registerProject.mutate(
      {
        typeId: formRegister.typeId,
        pierrevalCompanyId: formRegister.pierrevalCompanyId,
        businessUnitId:
          formRegister.typeId === TERTIARY_TYPE.id
            ? TERTIARY_BUSINESS_UNIT_ID
            : businessUnitId,
      },
      {
        onSuccess: (operation) => {
          toast.success(`${t("toast-create-operation-success")}`)
          queryClient.invalidateQueries({ queryKey: ["listOperations"] })
          queryClient.invalidateQueries({ queryKey: ["listProjects"] })
          setIsLoading(false)
          toggleModal()
          navigate(`/operation/${operation.project.id}`)
        },
        onError: (err) => {
          toast.error(
            `${t("toast-create-operation-error")}: ${
              err.response?.status === 403
                ? t("toast-registration-step-error")
                : err.response?.data.message
            }`,
          )
          setIsLoading(false)
        },
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  function handleClick(opeId: TTypeOperation) {
    setValue("typeId", opeId)
    setOperationId(opeId)
  }

  return (
    <Modal
      isShowing={isShowing}
      closeModal={toggleModal}
      title={`${t("childOperationType")}`}
      displayCloseIcon
    >
      <form className="Text__Field_Container" onSubmit={onSubmit}>
        <div className="Text__Field_Container mb-4">
          <p className="Color__Primary text-sm mb-4">
            {`${t("choose-pierreval-company-option")}`}
          </p>

          {pierrevalCompanies ? (
            <>
              <select
                className="Step__Select Text__Field flex items-center justify-center"
                defaultValue={PIERREVAL.id}
                {...register("pierrevalCompanyId")}
              >
                {pierrevalCompanies.map((company) => (
                  <option value={company.id} key={company.id}>
                    {company.label}
                  </option>
                ))}
              </select>
              {errors.pierrevalCompanyId && (
                <p className="text-xs text-red-500">
                  {errors.pierrevalCompanyId?.message}
                </p>
              )}
            </>
          ) : null}
        </div>

        <p className="Color__Primary text-sm mb-4">
          {`${t("childOperationTypeDescription")}`}
        </p>

        <Box classNames="p-0">
          <Button
            type="button"
            size="small"
            mode="secondary"
            classNames={classNames(
              "rounded-lg Color__Primary w-full p-4 transition-colors hover:bg-slate-100 ease-in-out duration-75 cursor-pointer",
              {
                pressed: operationId === RESIDENTIAL_TYPE.id,
              },
            )}
            onClick={() => handleClick(RESIDENTIAL_TYPE.id)}
          >
            <div className="flex flex-col justify-center items-center">
              <BsFillBuildingsFill opacity={0.8} fontSize={32} />
              <span className="mt-2">{`${t("residential")}`}</span>
            </div>
          </Button>
        </Box>

        <Box classNames="mt-4 p-0">
          <Button
            type="button"
            size="small"
            mode="secondary"
            classNames={classNames(
              "rounded-lg Color__Primary w-full p-4 transition-colors hover:bg-slate-100 ease-in-out duration-75 cursor-pointer",
              {
                pressed: operationId === TERTIARY_TYPE.id,
              },
            )}
            onClick={() => handleClick(TERTIARY_TYPE.id)}
          >
            <div className="flex flex-col justify-center items-center">
              <HiBuildingOffice opacity={0.8} fontSize={32} />
              <span className="mt-2">{`${t("tertiary-business-park")}`}</span>
            </div>
          </Button>
        </Box>

        <Box classNames="mt-4 p-0">
          <Button
            type="button"
            size="small"
            mode="secondary"
            classNames={classNames(
              "rounded-lg Color__Primary w-full p-4 transition-colors hover:bg-slate-100 ease-in-out duration-75 cursor-pointer",
              {
                pressed: operationId === HOUSING_ESTATE_TYPE.id,
              },
            )}
            onClick={() => handleClick(HOUSING_ESTATE_TYPE.id)}
          >
            <div className="flex flex-col justify-center items-center">
              <BsFillHousesFill opacity={0.8} fontSize={32} />
              <span className="mt-2">{`${t("housing-estate")}`}</span>
            </div>
          </Button>
        </Box>
        <input type="hidden" {...register("typeId")} />
        {errors.typeId && (
          <p className="text-xs text-red-500 pt-2">{errors.typeId?.message}</p>
        )}

        {isLoading ? (
          <div className="mt-4">
            <Spinner />
          </div>
        ) : (
          <Button
            type="submit"
            size="small"
            mode="primary"
            classNames="rounded-lg Color__Primary mt-4 transition-colors hover:bg-slate-100 ease-in-out duration-75 cursor-pointer"
          >
            {`${t("register-operation")}`}
          </Button>
        )}
      </form>
    </Modal>
  )
}
