import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import useModal from "components/Modal/useModal"
import {
  useAddCostOfWork,
  useGetCostOfWork,
  useUpdateCostOfWork,
} from "core/query-hooks/useCostOfWork"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import useFeasibilityVersion from "hooks/useFeasibilityVersion"
import i18next, { t } from "i18next"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { GROUP } from "shared/resources/groups.resources"
import * as yup from "yup"
import DeleteStudyRequestDraft from "../DeleteStudyRequestDraft"
import { CostOfWorkContext } from "./CostOfWorkProvider"

type TCostOfWorkCreateDetailsStepForm = {
  projectId?: number
  feasibilityId?: number | undefined
  developersComment?: string
}

const validationSchema = yup.object({
  feasibilityId: yup
    .number()
    .nullable()
    .typeError(() => i18next.t("error-select-version-feasibility"))
    .transform((curr, orig) => (orig === "" ? null : curr)),
  developersComment: yup
    .string()
    .required(() => i18next.t("comment-error-message"))
    .transform((curr, orig) => (orig === "" ? null : curr)),
})

export interface IStepDetailsCoW {
  routeName: string
}
export function StepDetailsCoW(props: IStepDetailsCoW) {
  const { id, studyRequestId } = useParams()
  const { data: user } = useGetCurrentUser()
  const { routeName } = props
  const { studyRequestInfo, setStudyRequestInfo, currentId, setCurrentId } =
    useContext(CostOfWorkContext)
  // constantes en cas d'édition
  const { data: cowData } = useGetCostOfWork(+studyRequestId!)
  const creatorId = cowData?.creator.azureId
  const userIsCreatorOrAdmin =
    !creatorId ||
    creatorId === user?.azureId ||
    user?.groups.includes(GROUP.ADMIN)
  const comment = cowData?.comments?.[0]?.comment || ""
  const version = cowData?.feasibility?.id
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TCostOfWorkCreateDetailsStepForm>({
    resolver: yupResolver(
      validationSchema as yup.ObjectSchema<TCostOfWorkCreateDetailsStepForm>,
    ),
    defaultValues: {
      feasibilityId: version,
      developersComment: comment,
    },
  })

  useEffect(() => {
    if (cowData) {
      reset({
        feasibilityId: version,
        developersComment: comment,
      })
    }
  }, [cowData])

  const queryClient = useQueryClient()
  const addCostOfWork = useAddCostOfWork()
  const updateCostOfWork = studyRequestId
    ? useUpdateCostOfWork(+studyRequestId)
    : useUpdateCostOfWork(currentId!)
  const { restitutionDates, isLoading } = useFeasibilityVersion(+id!)
  useEffect(() => {
    reset({
      projectId: +id!,
      feasibilityId: studyRequestInfo.feasibilityId,
      developersComment: studyRequestInfo.developersComment,
    })
  }, [])

  const navigate = useNavigate()
  const { isShowing: isDeleteModalShowed, toggle: toggleDeleteModal } =
    useModal()
  const onSubmit = (
    data: TCostOfWorkCreateDetailsStepForm,
    redirectToStudies: boolean = false,
  ) => {
    if (!currentId && !studyRequestId) {
      addCostOfWork.mutate(
        {
          projectId: parseInt(id!),
          feasibilityId: data.feasibilityId,
          developersComment: data.developersComment,
        },
        {
          onSuccess(cow) {
            setStudyRequestInfo((previsousValue) => {
              return {
                ...previsousValue,
                projectId: +id!,
                feasibilityId: cow.feasibilityId,
                developersComment: cow.developersComment,
              }
            })
            setCurrentId(cow.id)
            queryClient.invalidateQueries({
              queryKey: ["listStudyRequests", id],
            })
            redirectToStudies
              ? (toast.success(t("draft-saved")),
                navigate(`/${routeName}/${id}/studies`))
              : navigate(
                  `/${routeName}/${id}/studies/costOfWork/new/${cow.id}/documents`,
                )
          },
          onError(error) {
            toast.error(error.response?.data.error.message)
          },
        },
      )
    }
    updateCostOfWork.mutate(
      {
        projectId: parseInt(id!),
        feasibilityId: data.feasibilityId,
        developersComment: data.developersComment,
      },
      {
        onSuccess(cow) {
          setStudyRequestInfo((previsousValue) => {
            return {
              ...previsousValue,
              projectId: +id!,
              feasibilityId: cow.feasibilityId,
              developersComment: cow.developersComment,
            }
          })
          setCurrentId(cow.id)
          queryClient.invalidateQueries({
            queryKey: ["listStudyRequests", id],
          })
          redirectToStudies
            ? (toast.success(t("draft-saved")),
              navigate(`/${routeName}/${id}/studies`))
            : navigate(
                `/${routeName}/${id}/studies/costOfWork/new/${cow.id}/documents`,
              )
        },
        onError(error) {
          toast.error(error.response?.data.error.message)
        },
      },
    )
  }
  return (
    <>
      <DivStickyMenu />
      <div className="px-4 sm:px-16">
        <form>
          <div className="lg:grid grid-rows-1 lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-8 pb-8">
            <div className="col-span-2 pt-4">
              <p className="Color__Primary font-semibold text-lg">
                {`${t("feasibility-version")}`}
              </p>
            </div>
            <div className="col-span-2">
              <Box classNames="flex flex-col Text__Field_Container">
                <p>{`${t("feasibility-version-label")} *`}</p>
                <select
                  {...register("feasibilityId")}
                  name="feasibilityId"
                  id="feasibilityId"
                  className="Text__Field flex items-center justify-center"
                >
                  {studyRequestId ? null : (
                    <option key="undefined-feasibilityId">-</option>
                  )}

                  {!isLoading &&
                    restitutionDates?.map((restitution) => (
                      <option
                        key={`feasibilityId-${restitution?.value}`}
                        value={restitution?.value}
                      >
                        {restitution?.label}
                      </option>
                    ))}
                  <option key="external-feasibilityId" value="">
                    {t("out-of-alvis")}
                  </option>
                </select>
                {errors.feasibilityId && (
                  <span className="text-red-500 text-xs">
                    {errors.feasibilityId.message}
                  </span>
                )}
              </Box>
            </div>
          </div>

          <div className="divide-y divide-slate-100">
            <div className="lg:grid grid-rows-1 lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense md:pt-8 pb-8">
              <div className="col-span-2 pt-4">
                <p className="Color__Primary font-semibold text-lg">
                  {`${t("comment")}`}
                </p>
                <p className="Color__Primary mt-2 text-sm mb-4 md:mb-0">
                  {`${t("comment-helper-message-study-request")}`}
                </p>
              </div>
              <div className="col-span-2">
                <Box classNames="flex flex-col Text__Field_Container">
                  <p>{`${t("comment")} *`}</p>
                  <textarea
                    id="developersComment"
                    rows={15}
                    className="Textarea__Field"
                    {...register("developersComment")}
                  />
                  {errors.developersComment && (
                    <span className="text-red-500 text-xs">
                      {errors.developersComment.message}
                    </span>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </form>
        {userIsCreatorOrAdmin ? (
          <div className="flex items-center flex-row-reverse justify-between pt-4 pb-8">
            <div>
              <Button
                isLoading={updateCostOfWork.isPending}
                onClick={handleSubmit((data) => onSubmit(data, true))}
                size="small"
                mode="secondary"
              >
                {`${t("save-draft")}`}
              </Button>
              <Button
                isLoading={updateCostOfWork.isPending}
                onClick={handleSubmit((data) => onSubmit(data))}
                marginLeft={12}
                size="small"
                mode="primary"
              >
                {`${t("add-documents")}`}
              </Button>
            </div>
            <div>
              {studyRequestId ? (
                <DeleteStudyRequestDraft
                  isDeleteModalShowed={isDeleteModalShowed}
                  toggleDeleteModal={toggleDeleteModal}
                  routeName={routeName}
                  isPending={updateCostOfWork.isPending}
                  studyRequestType={t("this-cost-of-work")}
                />
              ) : (
                <Button
                  size="medium"
                  mode="danger"
                  onClick={() => navigate(`/${routeName}/${id}/studies`)}
                >
                  {`${t("delete-draft")}`}
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}
