import { Header } from "components/Header/Header"
import Label from "components/Label/Label"
import RewindButton from "components/RewindButton/RewindButton"
import { t } from "i18next"
import { RiArrowLeftSLine, RiDraftFill } from "react-icons/ri"
import { Outlet, useNavigate, useParams } from "react-router-dom"

export interface ICreateInfography {
  routeName: string
}

export default function CreateInfography(props: ICreateInfography) {
  const { id, studyRequestId } = useParams()
  const { routeName } = props
  const navigate = useNavigate()

  return (
    <div>
      <div className="flex items-center flex-wrap justify-between px-4 pt-8 sm:px-16">
        <div className="pb-4 md:pb-0">
          <div className="flex items-center">
            <RewindButton
              classNames="h-12 w-12"
              size="small"
              mode="secondary"
              onClick={() => navigate(`/${routeName}/${id}/studies`)}
            >
              <RiArrowLeftSLine size="2em" />
            </RewindButton>
            <Header classNames="ml-2">{t("studyRequest.title")}</Header>
            {studyRequestId && (
              <Label
                classNames="text-xs ml-2"
                label={`${t("draft")}`}
                labelColor="yellow"
                backgroundColor="yellow"
              >
                <RiDraftFill className="ml-2 icon yellow" />
              </Label>
            )}
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  )
}
