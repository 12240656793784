export const COMMITTEE_FONCIER = {
  id: 1,
  label: "Comité foncier",
}
export const COMMITTEE_ENGAGEMENT = {
  id: 2,
  label: "Comité d'engagement",
}
export const COMMITTEE_ENGAGEMENT_MOD = {
  id: 3,
  label: "Comité d'engagement modificatif",
}
export const COMMITTEE_ACQUISITION = {
  id: 4,
  label: "Comité d'acquisition",
}
export const COMMITTEE_TRANSFERT = {
  id: 5,
  label: "Transfert",
}
export const COMMITTEE_LANCEMENT_MONTAGE = {
  id: 6,
  label: "Lancement montage",
}
export const COMMITTEE_PRE_COMITE_DPC = {
  id: 7,
  label: "Pré comité DPC",
}
export const COMMITTEE_COMITE_DPC = {
  id: 8,
  label: "Comité DPC",
}
export const COMMITTEE_SUIVI_ACHAT = {
  id: 9,
  label: "Achat à blanc",
}
export const COMMITTEE_LANCEMENT_COMMERCIAL = {
  id: 10,
  label: "Lancement commercial",
}
