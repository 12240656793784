import { t } from "i18next"
import { Dispatch, SetStateAction } from "react"
import "./Toggle.scss"

interface ICaTypeFilterProps {
  checkedCa?: boolean
  onChangeCa: Dispatch<SetStateAction<boolean | string>>
}

export default function CaTypeFilter(props: ICaTypeFilterProps) {
  const { checkedCa, onChangeCa } = props

  return (
    <div className="Toggle__Container mb-0">
      <div className="Toggle__Input_Container">
        <input
          id="radio_ca_agrege"
          aria-label="ca-agrege"
          className="Text__Field"
          {...props}
          type="radio"
          checked={checkedCa}
          value="CA_agrege"
          onChange={(e) => {
            onChangeCa(e.target.value)
          }}
        />
        <label className="Left__Radio" htmlFor="radio_ca_agrege">
          <span>{t("ca-agrege")}</span>
        </label>
      </div>
      <div className="Toggle__Input_Container">
        <input
          id="radio_ca_net"
          value="CA_net"
          className="Text__Field Right__Radio"
          {...props}
          type="radio"
          checked={!checkedCa}
          onChange={(e) => {
            onChangeCa(e.target.value)
          }}
        />
        <label className="Right__Radio w-full md:w-auto" htmlFor="radio_ca_net">
          <span>{t("ca-net")}</span>
        </label>
      </div>
    </div>
  )
}
