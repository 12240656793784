import { yupResolver } from "@hookform/resolvers/yup"
import { Button } from "components/Button/Button"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import WatchChangesBar from "components/WatchChangesBar/WatchChangesBar"
import { useGetPlot, useUpdatePlot } from "core/query-hooks/usePlots"
import currency from "currency.js"
import FormUpdate from "features/plots/Form/FormUpdate"
import i18n from "i18next"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TCurrency } from "shared/types/global.type"
import { TMutatePlot } from "shared/types/plot.type"
import * as yup from "yup"

export type IUpdatePlotForm = {
  prefix: string
  section: string
  number: string
  area: {
    val: number
    unit: string
  }
  price: {
    val: number
    unit: TCurrency
  }
  projectId: number
  owner?: string
}

export function PlotUpdate() {
  const { t } = useTranslation()
  const [formIsDirty] = useState<boolean>(false)
  const navigate = useNavigate()
  const { id, plotId } = useParams()
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  const [priceValue, setPriceValue] = useState<{
    value?: number
    unit: TCurrency
  }>({ unit: "EUR" })

  const redirectToPlots = () => {
    navigate(`/project/${id}/plots/${plotId}`)
    return toggleModal
  }

  const updateSchema = yup.object({
    prefix: yup
      .string()
      .matches(/^[0-9]+$/, `${i18n.t("plots.prefixTypeError")}`)
      .required(`${i18n.t("plots.form.prefix")}`)
      .max(3, `${i18n.t("plots.prefixLengthError")}`),
    section: yup
      .string()
      .required(`${i18n.t("plots.form.section")}`)
      .max(3, `${i18n.t("plots.sectionLengthError")}`),
    number: yup
      .string()
      .matches(/^[0-9]+$/, `${i18n.t("plots.numberTypeError")}`)
      .required(`${i18n.t("plots.form.number")}`)
      .max(4, `${i18n.t("plots.numberLengthError")}`),
    area: yup.object().shape({
      val: yup
        .number()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .required(`${i18n.t("plots.form.surface")}`)
        .max(2147483647, `${i18n.t("max-safe-integer-validation")}`),
    }),
    owner: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
  })

  const redirectToPlotsBtn = () => {
    if (formIsDirty) return toggleModal()
    return navigate(`/project/${id}/plots/${plotId}`)
  }

  const updatePlot = useUpdatePlot(parseFloat(plotId!))

  const { data: plotData } = useGetPlot(plotId!)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<TMutatePlot>({
    resolver: yupResolver(updateSchema as yup.ObjectSchema<TMutatePlot>),
    defaultValues: useMemo(
      () => ({
        prefix: plotData?.prefix,
        section: plotData?.section,
        number: plotData?.number,
        area: {
          val: plotData?.area.val,
          unit: plotData?.area.unit,
        },
        price: {
          val: priceValue.value,
          unit: plotData?.price?.unit,
        },
        owner: plotData?.owner,
      }),
      [plotData],
    ),
  })

  useEffect(() => {
    if (plotData) {
      if (plotData.price) {
        setPriceValue({
          value: parseFloat(plotData.price.val),
          unit: plotData.price.unit,
        })
      }
      reset({
        prefix: plotData.prefix,
        section: plotData.section,
        number: plotData.number,
        area: { val: plotData.area.val, unit: plotData.area.unit },
        price: {
          val: plotData?.price?.val
            ? Number(currency(plotData?.price.val))
            : undefined,
          unit: "EUR",
        },
        owner: plotData.owner,
      })
    }
  }, [plotData])

  const onSubmitPlotUpdate = (data: TMutatePlot) => {
    updatePlot.mutate(
      {
        ...data,
        area: { val: data.area.val, unit: data.area.unit },
        price: { val: Number(currency(data.price.val)), unit: "EUR" },
      },
      {
        onSuccess: () => {
          toast.success(`${t("success-plot-update")}`)
          navigate(`/project/${id}/plots`)
        },
        onError: () => {
          toast.error(`${t("plots.form.update.uniqueConstraintError")}`)
        },
      },
    )
  }

  const onFormSubmit = (data: TMutatePlot) => {
    onSubmitPlotUpdate(data)
  }

  return (
    <>
      {isDirty && (
        <WatchChangesBar
          onSaveAction={{
            onAction() {
              handleSubmit((data: TMutatePlot) => {
                onSubmitPlotUpdate(data)
              })()
            },
            content: `${t("update")}`,
            isLoading: false,
          }}
          onDiscardAction={{
            onAction(): void {
              toggleModal()
            },
            content: `${t("cancel")}`,
          }}
          message={`${t("plots.form.update.watchBar.message")}`}
        />
      )}

      <div style={{ padding: 24 }}>
        <FormUpdate
          priceValue={priceValue}
          setPriceValue={setPriceValue}
          handleSubmit={handleSubmit}
          register={register}
          onceSubmited={(data) => onFormSubmit(data)}
          errors={errors}
        >
          <Button
            onClick={() => redirectToPlotsBtn()}
            size="medium"
            mode="secondary"
            isLoading={false}
          >
            {`${t("cancel")}`}
          </Button>
        </FormUpdate>
        <Modal
          description={`${t("plots.cancelModal.description")}`}
          isShowing={isModalShowed}
          closeModal={toggleModal}
          title={`${t("plots.cancelModal.title")}`}
        >
          <div className="Modal__Footer">
            <Button
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => toggleModal}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              marginLeft={24}
              size="medium"
              mode="danger"
              isLoading={false}
              onClick={() => redirectToPlots()}
            >
              {`${t("confirm")}`}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}
