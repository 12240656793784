import { uselistStudyRequestUsers } from "core/query-hooks/useStudyRequests"
import { t } from "i18next"
import { TLabelStringValue } from "shared/types/global.type"

export interface ISelectedUsersFiltersProps {
  selectedStudyRequestUsers: string[]
  onStudyRequestUsersChange: (updatedUsersList: string[]) => void
}

function renderUsers(
  userValue: string,
  users: TLabelStringValue[] | undefined,
) {
  const selectedUsers = users?.find((user) => user.value === userValue)
  return selectedUsers?.label
}

export default function SelectedStudyRequestUsersFilters(
  props: ISelectedUsersFiltersProps,
) {
  const { selectedStudyRequestUsers, onStudyRequestUsersChange } = props
  const { data: usersOptions } = uselistStudyRequestUsers()

  const handleUsersRemove = (usersValueToRemove: string) => {
    const updatedUsersList = selectedStudyRequestUsers.filter(
      (usersValue) => usersValue !== usersValueToRemove,
    )
    onStudyRequestUsersChange(updatedUsersList)
  }

  return (
    <div>
      {selectedStudyRequestUsers.map((usersValue) => (
        <span
          id="badge-dismiss-default"
          className="inline-flex items-center px-2 py-1 mr-2 my-0.5 text-sm font-medium text-blue-800 bg-blue-100 rounded"
        >
          {renderUsers(usersValue, usersOptions)}
          <button
            onClick={() => handleUsersRemove(usersValue)}
            type="button"
            className="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-purlple-200 hover:text-blue-900"
            data-dismiss-target="#badge-dismiss-default"
            aria-label="Remove"
          >
            <svg
              aria-hidden="true"
              className="w-3.5 h-3.5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">{t("delete")}</span>
          </button>
        </span>
      ))}
    </div>
  )
}
