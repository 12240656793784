import classNames from "classnames"
import { Locale, addHours, getMonth, getYear, toDate } from "date-fns"
import { range } from "lodash"
import React, { useState } from "react"
import DatePicker, { DatePickerProps } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

type CustomDatePickerProps = {
  id: string
  locale?: Locale
  className?: string
  placeholderText?: string
  name?: string
  required?: boolean
  disabled?: boolean
  onChange?: (
    selectedDate: Date | null,
    event: React.SyntheticEvent<any> | undefined,
  ) => void
} & DatePickerProps

function CustomDatePicker({
  id,
  locale,
  className,
  placeholderText,
  name,
  required,
  selected,
  disabled,
  onChange,
}: CustomDatePickerProps) {
  const years = range(1990, getYear(new Date()) + 100, 1)
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ]

  // Géstion de la timezone
  const selectedDateUTC = selected
    ? addHours(toDate(selected), -new Date().getTimezoneOffset() / 60)
    : null

  const [choosedDate, setChoosedDate] = useState<Date | null>(selectedDateUTC)

  const handleChange = (
    selectedDate: Date | null,
    event: React.SyntheticEvent<any> | undefined,
  ) => {
    const dateUTC = selectedDate
      ? addHours(toDate(selectedDate), -new Date().getTimezoneOffset() / 60)
      : null
    setChoosedDate(dateUTC)
    if (onChange) {
      onChange(dateUTC, event)
    }
  }

  return (
    <DatePicker
      id={id}
      locale={locale}
      className={classNames(className, disabled ? "cursor-not-allowed" : "")}
      placeholderText={placeholderText}
      selected={choosedDate}
      name={name}
      required={required}
      onChange={(selectedDate, event) => handleChange(selectedDate, event)}
      dateFormat="dd/MM/yyyy"
      aria-label="input-date"
      disabled={disabled}
      autoComplete="off"
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="mx-4 my-1 flex justify-center ">
          <button
            type="button"
            className="p-2 border rounded-md disabled:opacity-50 bg-slate-50"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            {"<"}
          </button>

          <select
            className="mx-2 p-2 border rounded-md bg-slate-50"
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            className="mx-2 p-2 border rounded-md bg-slate-50"
            value={getYear(date)}
            onChange={({ target: { value } }) =>
              changeYear(parseInt(value, 10))
            }
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            type="button"
            className="p-2 border rounded-md disabled:opacity-50 bg-slate-50"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            {">"}
          </button>
        </div>
      )}
    />
  )
}

export default CustomDatePicker
