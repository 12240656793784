import { TTypeOperationLiteral } from "shared/types/operation.type"

export const RESIDENTIAL_TYPE: TTypeOperationLiteral = {
  id: 1,
  label: "residential",
  abbreviation: "R",
}
export const TERTIARY_TYPE: TTypeOperationLiteral = {
  id: 2,
  label: "tertiary-business-park",
  abbreviation: "T",
}
export const HOUSING_ESTATE_TYPE: TTypeOperationLiteral = {
  id: 3,
  label: "housing-estate",
  abbreviation: "L",
}

export const STATUS_PROSPECTION = 1
export const STATUS_NEGOCIATION = 2
export const STATUS_LAND_COMMITTEE = 3
export const STATUS_ENGAGEMENT_COMMITTEE = 4
export const STATUS_ABANDONMENT = 5
export const STATUS_STANDBY = 6
export const STATUS_WAITING_ASSEMBLY = 7
export const STATUS_ASSEMBLY = 8
export const STATUS_NOT_ACTED = 9
export const STATUS_ON_GOING = 10
export const STATUS_ON_DELIVERY = 11
export const STATUS_ABANDONMENT_ON_GOING = 12
export const SEARCH_OPERATION = "operations"
export const SEARCH_PROJET = "projects"
