import { API } from "core/api/axios"
import { TDocumentCategory, TGedDocuments } from "shared/types/ged.type"
import {
  StudyInfographyDashboard,
  TInfography,
  TMutateInfographyDocuments,
} from "shared/types/infography.type"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"

async function addInfography(infography: TInfography): Promise<TInfography> {
  const res = await API.post("/infography", infography)
  return res.data
}

async function updateInfography(
  id: number,
  infography: TInfography,
): Promise<TInfography> {
  const res = await API.patch(`/infography/${id}`, infography)
  return res.data
}

async function addInfographyDocuments(
  id: number,
  data: TMutateInfographyDocuments,
) {
  const res = await API.post(`/infographies/${id}/documents`, data)
  return res.data
}

async function getInfographyDocuments(
  id: number,
): Promise<TDocumentCategory[]> {
  const res = await API.get(`/document-categories?studyRequestType=${id}`)
  return res.data
}

async function getInfography(id: number): Promise<TStudyRequest> {
  return typeof id === "undefined" || Number.isNaN(id)
    ? Promise.reject(new Error("Invalid infography id"))
    : API.get(`/infographies/${id}`).then((res) => res.data)
}

async function getInfographyHistory(
  id: number,
): Promise<TStudyRequestHistory[]> {
  const res = await API.get(`/infographies/${id}/history`)
  return res.data
}

async function getInfographyStudyGedDocuments(
  infographyId?: string,
): Promise<TGedDocuments> {
  return typeof infographyId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/infographies/${infographyId}/documents`).then((res) => res.data)
}

async function listInfographiesStudies(
  showAllReturned: boolean,
): Promise<StudyInfographyDashboard> {
  const res = await API.get(
    `/infographies/dashboard?showAllReturned=${showAllReturned}`,
  )
  return res.data
}

export {
  addInfography,
  addInfographyDocuments,
  getInfography,
  getInfographyDocuments,
  getInfographyHistory,
  getInfographyStudyGedDocuments,
  listInfographiesStudies,
  updateInfography,
}
