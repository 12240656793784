import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useGetBudgetOverview } from "core/query-hooks/useSyntheseFinanciere"
import { t } from "i18next"
import { AiFillDollarCircle } from "react-icons/ai"
import { NavLink } from "react-router-dom"

export interface IFinance {
  operationId: number
}

export function Finance(props: IFinance) {
  const { operationId } = props
  const {
    data: budgetOverview,
    isLoading,
    error,
  } = useGetBudgetOverview(operationId)

  function displayKEuros(n: number | undefined): string {
    if (!n) return "-"
    return n.toLocaleString("fr-FR")
  }

  function displayPercentage(n: number | undefined): string {
    return !n
      ? "-"
      : `${Number((n * 100).toFixed(2)).toLocaleString("fr-FR")} %`
  }

  return (
    <Box classNames="p-0 h-full">
      <div className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between h-10">
        <NavLink to="financial" type="button">
          <div className="flex items-center text-cyan-900 mr-1">
            <AiFillDollarCircle size={22} />
            <h3 className="text-xs uppercase font-medium pl-1">
              {t("finance")}
            </h3>
          </div>
        </NavLink>

        <div className="text-primary-dark">
          <TooltipWithHover
            position="bottom-left"
            message={t("spo-informations")}
          >
            <div className="text-cyan-900 uppercase text-xs">{`${t("spo")}`}</div>
          </TooltipWithHover>
        </div>
      </div>
      <div className="p-4">
        {budgetOverview && (
          <table className="w-full border-separate">
            <tbody className="Text__Field_Container text-cyan-950">
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary pb-4" colSpan={2}>{`${t(
                  "revenus",
                )} ${t("k-euros")}`}</td>
                <td className="Text__Align_Right text-sm pb-4">
                  {displayKEuros(budgetOverview?.revenue)}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary pb-4" colSpan={2}>
                  {" "}
                  {`${t("land-expense")} ${t("k-euros")}`}
                </td>
                <td className="Text__Align_Right text-sm pb-4">
                  {displayKEuros(budgetOverview?.foncier)}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary pb-4" colSpan={2}>
                  {t("ccn")}
                </td>
                <td className="Text__Align_Right text-sm pb-4">
                  {displayKEuros(budgetOverview?.CCN)}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary pb-4">{`${t(
                  "margin",
                )} ${t("k-euros")}`}</td>
                <td className="text-sm text-right pb-4">
                  {displayPercentage(budgetOverview?.marginRate)}
                </td>
                <td className="Text__Align_Right text-sm pb-4">
                  {displayKEuros(budgetOverview?.margin)}
                </td>
              </tr>
              <tr className="hover:bg-gray-100 transition-colors">
                <td className="text-sm Color__Primary pb-4">{`${t(
                  "cashflow",
                )} ${t("k-euros")}`}</td>
                <td className="text-sm text-right pb-4">
                  {displayPercentage(budgetOverview?.cashFlowRate)}
                </td>
                <td className="Text__Align_Right text-sm pb-4">
                  {displayKEuros(budgetOverview?.cashFlow)}
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {isLoading && <Spinner />}
        {error && (
          <p>
            {error.response && error.response.status === 404 && (
              <table className="w-full">
                <tbody className="Text__Field_Container text-cyan-950">
                  <tr className="hover:bg-gray-100 transition-colors">
                    <td
                      className="text-sm Color__Primary pb-4"
                      colSpan={2}
                    >{`${t("revenus")} ${t("k-euros")}`}</td>
                    <td className="Text__Align_Right text-sm pb-4">-</td>
                  </tr>
                  <tr className="hover:bg-gray-100 transition-colors">
                    <td className="text-sm Color__Primary pb-4" colSpan={2}>
                      {`${t("land-expense")} ${t("k-euros")}`}
                    </td>
                    <td className="Text__Align_Right text-sm pb-4">-</td>
                  </tr>
                  <tr className="hover:bg-gray-100 transition-colors">
                    <td className="text-sm Color__Primary pb-4" colSpan={2}>
                      {t("ccn")}
                    </td>
                    <td className="Text__Align_Right text-sm pb-4">-</td>
                  </tr>
                  <tr className="hover:bg-gray-100 transition-colors">
                    <td
                      className="text-sm Color__Primary pb-4"
                      colSpan={2}
                    >{`${t("margin")} ${t("k-euros")}`}</td>
                    <td className="Text__Align_Right text-sm pb-4">-</td>
                  </tr>
                  <tr className="hover:bg-gray-100 transition-colors">
                    <td
                      className="text-sm Color__Primary pb-4"
                      colSpan={2}
                    >{`${t("cashflow")} ${t("k-euros")}`}</td>
                    <td className="Text__Align_Right text-sm pb-4">-</td>
                  </tr>
                </tbody>
              </table>
            )}
            {error.response &&
              error.response.status === 418 &&
              t("error-status-has-no-budget")}
            {error.response &&
              error.response.status !== 404 &&
              error.response.status !== 418 &&
              t("data-error")}
          </p>
        )}
      </div>
    </Box>
  )
}
