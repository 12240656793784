import { Header } from "components/Header/Header"
import ActionList from "features/actions/ActionList"
import AddActionModal from "features/actions/AddActionModal"
import { t } from "i18next"
import DivStickyMenu from "pages/operations/DivStickyMenu"

export default function ProjectActions() {
  return (
    <>
      <DivStickyMenu heightClass="pt-36" />
      <div className="px-4 py-4">
        <div className="flex justify-between items-center mb-2">
          <div>
            <Header>{`${t("actions")}`}</Header>
          </div>
          <AddActionModal />
        </div>
        <ActionList />
      </div>
    </>
  )
}
