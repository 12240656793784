import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from "react"
import { TFeasibility } from "shared/types/feasibility.type"

interface IFeasibilityProviderProps {
  children: React.ReactNode
}

export type TFeasibilityCreateForm = TFeasibility

const defaultActiveStep = 1

const feasibility: TFeasibilityCreateForm = {
  projectId: undefined,
  collective: undefined,
  demeuresDeLouise: undefined,
  office: undefined,
  businessPark: undefined,
  buildingLot: undefined,
  house: undefined,
  semiCollective: undefined,
  developersComment: undefined,
  marginPercentage: undefined,
  cashflow: undefined,
}

const FeasibilityContext = createContext<{
  getFeasibility: () => TFeasibilityCreateForm
  setActiveStep: Dispatch<SetStateAction<number>>
  setCurrentId: Dispatch<SetStateAction<number | undefined>>
  currentId: number | undefined
  activeStep: number
  feasibilityInfo: TFeasibilityCreateForm
  setFeasibilityInfo: Dispatch<SetStateAction<TFeasibilityCreateForm>>
}>({
  getFeasibility: () => feasibility,
  setActiveStep: () => {},
  setCurrentId: () => {},
  currentId: undefined,
  activeStep: defaultActiveStep,
  feasibilityInfo: feasibility,
  setFeasibilityInfo: () => {},
})

function FeasibilityProvider({ children }: IFeasibilityProviderProps) {
  const [feasibilityInfo, setFeasibilityInfo] = useState(feasibility)
  const [currentId, setCurrentId] = useState<number>()
  const [activeStep, setActiveStep] = useState(defaultActiveStep)

  const getFeasibility = () => {
    return feasibilityInfo
  }

  const contextValue = useMemo(
    () => ({
      getFeasibility,
      setActiveStep,
      activeStep,
      feasibilityInfo,
      setFeasibilityInfo,
      setCurrentId,
      currentId,
    }),
    [
      getFeasibility,
      setActiveStep,
      activeStep,
      feasibilityInfo,
      setFeasibilityInfo,
      setCurrentId,
      currentId,
    ],
  )

  return (
    <FeasibilityContext.Provider value={contextValue}>
      {children}
    </FeasibilityContext.Provider>
  )
}

export { FeasibilityProvider, FeasibilityContext }
