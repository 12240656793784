import { Button } from "components/Button/Button"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import * as React from "react"
import { useState } from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { TCreateAmendment } from "shared/types/amendment.type"

registerLocale("fr", fr)
interface IAddAmendmentModalProps {
  amendment?: TCreateAmendment
  addAmendment: (label: string, date?: Date | null) => void
}

export default function AddAmendmentModal({
  amendment,
  addAmendment,
}: IAddAmendmentModalProps) {
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  const [date, setDate] = useState<null | Date | undefined>(amendment?.date)
  const [label, setLabel] = useState<string>("")

  const onAddAmendment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    addAmendment(label!, date)
    setDate(null)
    setLabel("")
    toggleModal()
  }

  return (
    <>
      <Button onClick={toggleModal} size="small" mode="primary">
        {`${t("add")}`}
      </Button>
      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("add-amendment")}`}
      >
        <form
          onSubmit={(e) => onAddAmendment(e)}
          className="Text__Field_Container"
        >
          <label htmlFor="label">
            {`${t("label")} *`}
            <input
              id="label"
              className="Text__Field"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              type="text"
              name="label"
              required
              placeholder={`${t("label")}`}
            />
          </label>

          <div className="flex flex-col mt-2">
            <label htmlFor="date">{`${t("date")}`}</label>
            <CustomDatePicker
              id="date"
              locale={fr}
              className="Text__Field"
              placeholderText={`${t("date")}`}
              selected={date}
              onChange={(changedDate) => setDate(changedDate)}
            />
          </div>
          <div className="Modal__Footer mt-4">
            <Button
              type="button"
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => toggleModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button type="submit" marginLeft={24} size="medium" mode="primary">
              {`${t("confirm")}`}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
