import { API } from "core/api/axios"
import {
  TCreateTask,
  TDeleteTask,
  TTask,
  TUpdateTask,
} from "shared/types/task.type"

async function createTask(task: TCreateTask): Promise<TTask> {
  const res = await API.post("/task", task)
  return res.data
}

async function updateTask(id: number, task: TUpdateTask): Promise<TTask> {
  const res = await API.patch(`/task/${id}`, task)
  return res.data
}

async function getTaskById(id: string): Promise<TTask> {
  const res = await API.get(`/tasks/${id}`)
  return res.data
}

async function listTasksByAgreementId(agreementId: string): Promise<TTask[]> {
  const res = await API.get(`/tasks?agreement=${agreementId}`)
  return res.data
}

async function listTasksByOperationId(operationId: string): Promise<TTask[]> {
  const res = await API.get(`/tasks?operation=${operationId}`)
  return res.data
}

async function deleteTaskById(task: TDeleteTask): Promise<TTask> {
  const res = await API.delete(`/task/${task.id}`)
  return res.data
}

export {
  createTask,
  deleteTaskById,
  getTaskById,
  listTasksByAgreementId,
  listTasksByOperationId,
  updateTask,
}
