import { API } from "core/api/axios"
import {
  TAmendment,
  TCreateAmendment,
  TUpdateAmendment,
} from "shared/types/amendment.type"

async function createAmendment(
  amendment: TCreateAmendment,
): Promise<TAmendment> {
  const res = await API.post("/amendment", amendment)
  return res.data
}

async function updateAmendment(
  id: number,
  amendment: TUpdateAmendment,
): Promise<TAmendment> {
  const res = await API.patch(`/amendment/${id}`, amendment)
  return res.data
}

async function getAmendmentById(id: string): Promise<TAmendment> {
  const res = await API.get(`/amendments/${id}`)
  return res.data
}

async function listAmendmentsByAgreementId(
  agreementId: string,
): Promise<TAmendment[]> {
  const res = await API.get(`/amendments?agreement=${agreementId}`)
  return res.data
}

async function deleteAmendment(id: number) {
  const res = await API.delete(`/amendment/${id}`)
  return res.data
}

export {
  createAmendment,
  deleteAmendment,
  getAmendmentById,
  listAmendmentsByAgreementId,
  updateAmendment,
}
