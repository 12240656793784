type TFilters = {
  type: "SET_FILTERS"
  payload: {
    steps: number[] | []
    departments: number[] | []
    regions: number[] | []
    companies: number[] | []
    users: string[] | []
    take: number
    skip: number
    search: string
    sortColumn: string
    sortOrder: string
    types: number[] | []
    page: number
  }
}

type TResults = {
  type: "SET_RESULTS"
  payload:
    | {
        name: string
      }[]
    | []
}

export type TSearchActions = TFilters | TResults

export interface TSearchState {
  filters: TSearchStateFilters
  results: {
    name: string
  }[]
}

export type TSearchStateFilters = {
  steps: number[] | []
  departments: number[] | []
  regions: number[] | []
  companies: number[] | []
  users: string[] | []
  take: number
  skip: number
  search: string
  sortColumn: string
  sortOrder: string
  types: number[] | []
  page: number
}

export default function searchProgramsReducer(
  state: TSearchState,
  action: TSearchActions,
) {
  switch (action.type) {
    case "SET_FILTERS":
      return { ...state, filters: action.payload }
    case "SET_RESULTS":
      return { ...state, results: action.payload }
    default:
      throw new Error()
  }
}
