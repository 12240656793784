import classNames from "classnames"
import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import LightTable from "components/Table/LightTable"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useGetLotsCommercializationByTranche } from "core/spo/query-hooks/useLots"
import { t } from "i18next"
import { Fragment } from "react"
import { FaRegListAlt } from "react-icons/fa"
import { Link } from "react-router-dom"
import {
  DELIVERED,
  IN_STOCK,
  OPTIONNED,
  PRERESERVED,
  RESERVED,
  SOLD,
} from "shared/resources/lots-status.resources"
import { HOUSING_ESTATE_TYPE } from "shared/resources/operation.resources"
import "./lots-commercialization.scss"
import { nanoid } from "nanoid"

export interface ILotsCommercializationByTranche {
  trancheCommercialeId: string
  registrationId: string
}

export function LotsCommercializationByTranche(
  props: ILotsCommercializationByTranche,
) {
  const { trancheCommercialeId, registrationId } = props
  const {
    data: lotsCommercialization,
    isLoading,
    error,
    isFetched,
  } = useGetLotsCommercializationByTranche(registrationId, trancheCommercialeId)

  const isNotHousingEstate =
    registrationId.charAt(7) !== HOUSING_ESTATE_TYPE.abbreviation

  const headers = [
    t("reference"),
    t("tranche"),
    t("sqm-surface"),
    t("TVA"),
    t("ttcPrice"),
    t("reservedOrActedPrice"),
    t("status"),
  ]

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("reference")}`, value: ["reference"] },
    { key: `${t("tranche")}`, value: ["tranche"] },
    { key: `${t("type")}`, value: ["type"] },
    { key: `${t("sqm-surface")}`, value: ["surface"] },
    { key: `${t("TVA")}`, value: ["TVA"] },
    { key: `${t("ttcPrice")}`, value: ["stockPrice"] },
    { key: `${t("reservedOrActedPrice")}`, value: ["reservedOrActedPrice"] },
    { key: `${t("status")}`, value: ["status"] },
  ]

  if (isNotHousingEstate) {
    headers.splice(0, 0, t("lot"))
    headers.splice(3, 0, t("type"))
  }

  const lotsStatus = {
    [SOLD.value]: {
      label: SOLD.label,
      value: SOLD.value,
      className: "yellow",
    },
    [RESERVED.value]: {
      label: RESERVED.label,
      value: RESERVED.value,
      className: "blue",
    },
    [IN_STOCK.value]: {
      label: IN_STOCK.label,
      value: IN_STOCK.value,
      className: "green",
    },
    [PRERESERVED.value]: {
      label: PRERESERVED.label,
      value: PRERESERVED.value,
      className: "orange",
    },
    [OPTIONNED.value]: {
      label: OPTIONNED.label,
      value: OPTIONNED.value,
      className: "purple",
    },
    [DELIVERED.value]: {
      label: DELIVERED.label,
      value: DELIVERED.value,
      className: "gray",
    },
  }

  return (
    <Box classNames="p-0 mt-4">
      <div className="flex items-center px-3.5 py-2 justify-between">
        <div className="flex items-center text-cyan-900 mr-1">
          <FaRegListAlt size={22} className="mr-1" />
          <h3 className="text-xs uppercase font-medium pl-1">
            {t("details-lots")}
          </h3>
        </div>
        <div className="text-primary-dark">
          <TooltipWithHover position="left" message={t("spo-informations")}>
            <Link
              className="text-cyan-900 underline uppercase text-xs"
              to={`${process.env.REACT_APP_SPO_URL}#/grillePrix/grille-prix?operation=${registrationId}`}
              target="_blank"
            >
              SPO
            </Link>
          </TooltipWithHover>
        </div>
      </div>
      {lotsCommercialization &&
      lotsCommercialization.lots &&
      lotsCommercialization.lots.length > 0 ? (
        <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
          <LightTable
            showTotal={false}
            className="table-auto lotsCommercialization"
            items={lotsCommercialization.lots}
            headers={headers}
            sortableFields={sortableFields}
            renderRow={(item, index) => (
              <Fragment key={item.reference}>
                {isNotHousingEstate ? (
                  <td
                    className={classNames(
                      {
                        Table__Bottom_Border:
                          lotsCommercialization.lots.length - 1 === index,
                      },
                      lotsStatus[item.status].className,
                    )}
                  >
                    <div className="flex items-center">{item.lot}</div>
                  </td>
                ) : null}
                <td
                  className={classNames(
                    {
                      Table__Bottom_Border:
                        lotsCommercialization.lots.length - 1 === index,
                    },
                    lotsStatus[item.status].className,
                  )}
                >
                  <div className="flex items-center">{item.reference}</div>
                </td>
                <td
                  className={classNames(
                    {
                      Table__Bottom_Border:
                        lotsCommercialization.lots.length - 1 === index,
                    },
                    lotsStatus[item.status].className,
                  )}
                >
                  <div className="flex items-center">{item.tranche}</div>
                </td>
                {isNotHousingEstate ? (
                  <td
                    className={classNames(
                      {
                        Table__Bottom_Border:
                          lotsCommercialization.lots.length - 1 === index,
                      },
                      lotsStatus[item.status].className,
                    )}
                  >
                    <div className="flex items-center">{item.type}</div>
                  </td>
                ) : null}
                <td
                  className={classNames(
                    {
                      Table__Bottom_Border:
                        lotsCommercialization.lots.length - 1 === index,
                    },
                    lotsStatus[item.status].className,
                  )}
                >
                  <div className="flex items-center">
                    {item.surface ? item.surface.toLocaleString("fr-FR") : "-"}
                  </div>
                </td>
                <td
                  className={classNames(
                    {
                      Table__Bottom_Border:
                        lotsCommercialization.lots.length - 1 === index,
                    },
                    lotsStatus[item.status].className,
                  )}
                >
                  <div className="flex items-center">
                    {item.TVA ? item.TVA.toLocaleString("fr-FR") : ""}
                  </div>
                </td>
                <td
                  className={classNames(
                    {
                      Table__Bottom_Border:
                        lotsCommercialization.lots.length - 1 === index,
                    },
                    lotsStatus[item.status].className,
                  )}
                >
                  <div className="flex items-center">
                    {`${
                      item.stockPrice
                        ? item.stockPrice.toLocaleString("fr-FR")
                        : "-"
                    } €`}
                  </div>
                </td>
                <td
                  className={classNames(
                    {
                      Table__Bottom_Border:
                        lotsCommercialization.lots.length - 1 === index,
                    },
                    lotsStatus[item.status].className,
                  )}
                >
                  <div className="flex items-center">
                    {`${
                      item.reservedOrActedPrice
                        ? item.reservedOrActedPrice.toLocaleString("fr-FR")
                        : "-"
                    } €`}
                  </div>
                </td>
                <td
                  className={classNames(
                    {
                      Table__Bottom_Border:
                        lotsCommercialization.lots.length - 1 === index,
                    },
                    lotsStatus[item.status].className,
                  )}
                >
                  <div className="flex items-center">
                    {lotsStatus[item.status].label}
                  </div>
                </td>
              </Fragment>
            )}
          />
          <div>
            {Object.entries(lotsStatus).map(([, lot]) => (
              <div
                className="ml-4 my-2 inline-flex items-center lotsCommercialization"
                key={nanoid()}
              >
                <span
                  className={classNames(
                    "w-8 h-4 inline-block rounded-full mr-2 outline outline-1",
                    lot.className,
                  )}
                />
                <span className="Color__Primary">{lot.label}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          {isLoading && <Spinner />}
          {error && (
            <p className="text-sm text-red-500">
              {t("error-loading-lots-lotissemnts")}
            </p>
          )}
          {isFetched && !error && (
            <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
              <p className="text-sm px-2 py-2 Color__Primary">
                {t("no-data-found-message")}
              </p>
            </div>
          )}
        </>
      )}
    </Box>
  )
}
