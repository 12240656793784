import { t } from "i18next"
import {
  HOUSING_ESTATE_TYPE,
  RESIDENTIAL_TYPE,
  TERTIARY_TYPE,
} from "shared/resources/operation.resources"
import { TTypeOperationLiteral } from "shared/types/operation.type"
import useProgramsSearch from "../useProgramsSearch"

function renderType(typeId: number, types: TTypeOperationLiteral[]) {
  const selectedTypes = types?.find((type) => type.id === typeId)
  return selectedTypes?.label ? t(selectedTypes.label) : null
}

export default function SelectedTypesFilters() {
  const { state, dispatch } = useProgramsSearch()
  const typesOption = [HOUSING_ESTATE_TYPE, RESIDENTIAL_TYPE, TERTIARY_TYPE]

  return (
    <div>
      {state.filters.types.map((typeId) => (
        <span
          id="badge-dismiss-default"
          className="inline-flex items-center px-2 py-1 mr-2 my-0.5 text-sm font-medium text-purple-800 bg-purple-100 rounded"
          key={typeId}
        >
          {renderType(typeId, typesOption)}
          <button
            onClick={() =>
              dispatch({
                type: "SET_FILTERS",
                payload: {
                  ...state.filters,
                  types: [
                    ...state.filters.types.filter((type) => type !== typeId),
                  ],
                  skip: 0,
                  page: 1,
                },
              })
            }
            type="button"
            className="inline-flex items-center p-0.5 ml-2 text-sm text-purple-400 bg-transparent rounded-sm hover:bg-purlple-200 hover:text-purple-900"
            data-dismiss-target="#badge-dismiss-default"
            aria-label="Remove"
          >
            <svg
              aria-hidden="true"
              className="w-3.5 h-3.5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">{t("delete")}</span>
          </button>
        </span>
      ))}
    </div>
  )
}
