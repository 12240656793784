import { useMutation, useQuery } from "@tanstack/react-query"
import {
  editAdministrationData,
  getAdministrationData,
} from "core/api/admnistrationData"
import { TAdministrationDataType } from "shared/types/administrationData.type"
import { CustomAxiosError } from "shared/types/axios.type"

function useGetAdministrationData() {
  return useQuery<
    TAdministrationDataType,
    CustomAxiosError,
    TAdministrationDataType
  >({
    queryKey: ["getAdministrationData"],
    queryFn: () => getAdministrationData(),
  })
}

function useEditAdministrationData() {
  return useMutation<
    TAdministrationDataType,
    CustomAxiosError,
    Partial<TAdministrationDataType>
  >({ mutationFn: (data) => editAdministrationData(data) })
}

export { useEditAdministrationData, useGetAdministrationData }
