import {
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "features/studies/studyRequest.resources"
import { TProject } from "shared/types/project.type"
import { TStudyRequest, TStudyType } from "shared/types/study.type"

export default function getStudyRequestUrl(
  studyRequestId: TStudyRequest["id"],
  studyRequestProjectId: TProject["id"],
  entity: string,
  typeId: TStudyType,
  isDraft?: boolean,
) {
  const draftSnippet = isDraft ? `edit/${studyRequestId}` : studyRequestId

  if (typeId === TYPE_FEASIBILITY)
    return `/${entity}/${studyRequestProjectId}/studies/feasibility/${draftSnippet}`
  if (typeId === TYPE_COST_OF_WORK)
    return `/${entity}/${studyRequestProjectId}/studies/costOfWork/${draftSnippet}`
  if (typeId === TYPE_INFOGRAPHY)
    return `/${entity}/${studyRequestProjectId}/studies/infography/${draftSnippet}`
  return "/"
}
