import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { TNextThreeMonthsInfos } from "shared/types/spo/next-three-months-infos"
import getNextThreeMonthsInfos from "../api/data"

export default function useGetNextThreeMonthsInfos(
  action: string,
  businessUnitId?: string,
) {
  return useQuery<TNextThreeMonthsInfos[], AxiosError>({
    queryKey: ["getNextThreeMonthsInfos", action, businessUnitId],
    queryFn: () => getNextThreeMonthsInfos(action, businessUnitId),
  })
}
