import Box from "components/Box/Box"
import { useGetInfography } from "core/query-hooks/useInfographies"
import { format } from "date-fns"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { Link, useParams } from "react-router-dom"
import { DEVELOPER_COMMENT } from "shared/resources/study-request.resources"

export interface IInfographyDetails {
  routeName: string
}

export function InfographyDetails(props: IInfographyDetails) {
  const { studyRequestId, id } = useParams()
  const { routeName } = props
  const { data: infographyData } = useGetInfography(+studyRequestId!)
  const infographyDeveloperComments = infographyData?.comments
    ? infographyData.comments.filter(
        (comment) => comment.type.id === DEVELOPER_COMMENT.id,
      )
    : []

  return (
    <div className="col-span-2">
      <Box classNames="flex flex-col Container">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("concerned-service")}`}
        </p>
        <p className="Color__Primary">{`${t("infographic")}`}</p>
      </Box>
      <Box classNames="flex flex-col Container my-4">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("desired-infographies")}`}
        </p>
        <ul className="Color__Primary">
          <li>
            {`${t("ground-plane")} : ${
              infographyData?.desiredGroundPlaneNumber
                ? infographyData.desiredGroundPlaneNumber
                : 0
            }`}
          </li>
          <li>
            {`${t("model")} : ${
              infographyData?.desiredModelNumber
                ? infographyData.desiredModelNumber
                : 0
            }`}
          </li>
          <li>
            {`${t("perspective")} : ${
              infographyData?.desiredPerspectiveNumber
                ? infographyData.desiredPerspectiveNumber
                : 0
            }`}
          </li>
          <li>
            {`${t("aerial-view")} : ${
              infographyData?.desiredAerialViewNumber
                ? infographyData.desiredAerialViewNumber
                : 0
            }`}
          </li>
          <li>
            {`${t("other")} : ${
              infographyData?.desiredOtherNumber
                ? infographyData.desiredOtherNumber
                : 0
            }`}
          </li>
        </ul>
      </Box>
      <Box classNames="flex flex-col Container my-4">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("feasibility-version")}`}
        </p>
        <div className="Color__Primary">
          <Link
            className="text-blue-500 "
            target="_blank"
            to={`/${routeName}/${id}/studies/feasibility/${infographyData?.feasibility?.id}`}
          >
            {infographyData?.feasibility?.version}
          </Link>
        </div>
      </Box>
      <Box classNames="flex flex-col Container my-4">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("infography-version")}`}
        </p>
        <div className="Color__Primary">
          <p>{infographyData?.version}</p>
        </div>
      </Box>
      <Box classNames="flex flex-row Container my-4 justify-between items-center">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("margin")}`}
        </p>
        <div className="Color__Primary">
          <p>{infographyData?.feasibility?.marginPercentage ?? "0"} %</p>
        </div>
      </Box>
      <Box classNames="flex flex-row Container my-4 justify-between items-center">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("cashflow")}`}
        </p>
        <div className="Color__Primary">
          <p>
            {infographyData?.feasibility?.cashflow?.toLocaleString() ?? 0} €
          </p>
        </div>
      </Box>
      <Box classNames="flex flex-col Container lg:mb-4">
        <p className="Color__Primary font-semibold text-lg">
          {`${t("comment")}`}
        </p>
        {infographyDeveloperComments.length > 0 ? (
          infographyDeveloperComments.map((comment, index) => (
            <p
              className="Color__Primary break-all whitespace-pre-line"
              key={nanoid()}
            >
              {index === 0
                ? comment.comment
                : `Mis à jour le ${format(
                    new Date(comment.creationDate),
                    "dd/MM/yyyy HH:mm",
                  )} : ${comment.comment}`}
            </p>
          ))
        ) : (
          <p className="Color__Primary">{`${t("no-comment")}`}</p>
        )}
      </Box>
    </div>
  )
}
