import { useGetCurrentUser } from "core/query-hooks/useUsers"
import * as React from "react"
import { Navigate } from "react-router-dom"

export interface IRoleProtectedRouteProps extends React.PropsWithChildren {
  requiredRoles: string[]
}

export function RoleProtectedRoute(props: IRoleProtectedRouteProps) {
  const { data: user } = useGetCurrentUser()
  const { children, requiredRoles } = props

  const hasPermission = requiredRoles.filter((requiredRole) =>
    user?.rights.includes(requiredRole),
  )

  if (!(hasPermission.length === requiredRoles.length))
    return <Navigate to="/" replace />

  return <>{children}</>
}
