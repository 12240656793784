import { useGetCurrentUser } from "core/query-hooks/useUsers"
import * as React from "react"
import { GROUP } from "shared/resources/groups.resources"

export interface IGroupsGuardProps extends React.PropsWithChildren {
  requiredGroups: string[]
  excludeAdmin?: boolean
}

export function GroupsGuard(props: IGroupsGuardProps) {
  const { data: user } = useGetCurrentUser()
  const { children, requiredGroups, excludeAdmin } = props

  const isInGroup = requiredGroups.some((requiredGroup) =>
    user?.groups.includes(requiredGroup),
  )

  const isAdmin = user?.groups.includes(GROUP.ADMIN) && !excludeAdmin

  if (!isInGroup && !isAdmin) return null

  return <>{children}</>
}
