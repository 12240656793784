import Spinner from "components/Spinner/Spinner"
import useListPierrevalCompanies from "core/query-hooks/usePierrevalCompanies"
import { t } from "i18next"
import { TPierrevalCompany } from "shared/types/pierreval-company.type"

export interface ISelectedCompaniesFiltersProps {
  selectedCompanies: number[] | []
  onCompaniesChange: (selectedCompanies: number[] | []) => void
}

function renderCompany(companyId: number, companies: TPierrevalCompany[]) {
  const selectedStep = companies?.find((company) => company.id === companyId)
  return selectedStep?.label
}

export default function SelectedCompaniesFilters(
  props: ISelectedCompaniesFiltersProps,
) {
  const { selectedCompanies, onCompaniesChange } = props
  const { data: companiesData, isLoading, error } = useListPierrevalCompanies()

  if (isLoading) return <Spinner />

  if (error || !companiesData) return <p>{error?.response?.data.message}</p>

  const handleCompanyRemove = (companyIdToRemove: number) => {
    const updatedCompaniesList = selectedCompanies.filter(
      (companyId) => companyId !== companyIdToRemove,
    )
    onCompaniesChange(updatedCompaniesList)
  }

  return (
    <div>
      {selectedCompanies.map((companyId) => (
        <span
          id="badge-dismiss-default"
          className="inline-flex items-center px-2 py-1 mr-2 my-0.5 text-sm font-medium text-yellow-800 bg-yellow-100 rounded"
          key={companyId}
        >
          {renderCompany(companyId, companiesData)}
          <button
            onClick={() => handleCompanyRemove(companyId)}
            type="button"
            className="inline-flex items-center p-0.5 ml-2 text-sm text-yellow-400 bg-transparent rounded-sm hover:bg-yellow-200 hover:text-yellow-900"
            data-dismiss-target="#badge-dismiss-default"
            aria-label="Remove"
          >
            <svg
              aria-hidden="true"
              className="w-3.5 h-3.5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">{t("delete")}</span>
          </button>
        </span>
      ))}
    </div>
  )
}
