import axios from "axios"
import { OsmInfos } from "shared/types/osm.type"

async function getInfosByAddress(query: string): Promise<OsmInfos[]> {
  const res = await axios.get(
    `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
      query,
    )}&addressdetails=1&limit=1`,
  )
  return res.data
}

export default getInfosByAddress
