import LightTable from "components/Table/LightTable"
import { format } from "date-fns"
import { StudyStatus } from "features/studies/StudyStatus"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import {
  TStudyRequest,
  TStudyRequestWithProject,
} from "shared/types/study.type"
import getStudyRequestUrl from "utils/getStudyRequestUrl"
import { getLabelFromTypeId, headers } from "./StudyRequestsList"
import {
  STATUS_DRAFT,
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "./studyRequest.resources"

export interface IDraftListProps {
  studyRequests: TStudyRequest[] | undefined
  projectId: number
  routeName: string
}

export function DraftList(props: IDraftListProps) {
  const { studyRequests, projectId, routeName } = props

  const drafts: TStudyRequestWithProject[] | undefined = studyRequests
    ?.filter((studyRequest) => {
      return studyRequest?.statusId === STATUS_DRAFT
    })
    .map((s) => {
      if (s.typeId === TYPE_COST_OF_WORK) {
        return { costOfWorkVersion: s.version, ...s }
      }
      if (s.typeId === TYPE_INFOGRAPHY) {
        return { infographyVersion: s.version, ...s }
      }
      return s
    })

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("studyRequest.date")}`, value: ["createdAt"] },
    { key: `${t("type")}`, value: ["typeId"] },
    {
      key: `${t("studyRequest.feasibilityVersionRaw")}`,
      value: ["feasibilityVersion"],
    },
    {
      key: `${t("studyRequest.costOfWorkVersionRaw")}`,
      value: ["costOfWorkVersion"],
    },
    {
      key: `${t("studyRequest.infographyVersionRaw")}`,
      value: ["infographyVersion"],
    },
    { key: `${t("batches-number")}`, value: ["numberOfLots"] },
    { key: `${t("progress")}`, value: ["statusId"] },
  ]

  const navigate = useNavigate()

  return (
    <div className="rounded-lg">
      <h2 className="mb-1 font-semibold text-md">{t("drafts")}</h2>
      {drafts?.length ? (
        <div className="Light_Table__Wrapper Table__Wrapper_NoTop shadow-lg rounded-lg">
          <LightTable
            showTotal={false}
            className="table-auto"
            items={drafts}
            headers={headers}
            sortableFields={sortableFields}
            renderRow={(item) => (
              <>
                <td
                  onClick={() =>
                    navigate(
                      getStudyRequestUrl(
                        item.id,
                        projectId,
                        routeName,
                        item.typeId,
                        true,
                      ),
                    )
                  }
                  className="cursor-pointer"
                >
                  <div className="flex items-center">
                    {format(new Date(item.createdAt), "dd/MM/yyyy")}
                  </div>
                </td>
                <td
                  onClick={() =>
                    navigate(
                      getStudyRequestUrl(
                        item.id,
                        projectId,
                        routeName,
                        item.typeId,
                        true,
                      ),
                    )
                  }
                  className="cursor-pointer"
                >
                  {item.typeId ? (
                    <div className="flex items-center">
                      {getLabelFromTypeId(item.typeId)}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  onClick={() =>
                    navigate(
                      getStudyRequestUrl(
                        item.id,
                        projectId,
                        routeName,
                        item.typeId,
                        true,
                      ),
                    )
                  }
                  className="cursor-pointer"
                >
                  <div className="flex items-center">
                    {item.feasibilityVersion ?? "-"}
                  </div>
                </td>
                <td
                  onClick={() =>
                    navigate(
                      getStudyRequestUrl(
                        item.id,
                        projectId,
                        routeName,
                        item.typeId,
                        true,
                      ),
                    )
                  }
                  className="cursor-pointer"
                >
                  {item.typeId === TYPE_COST_OF_WORK ? (
                    <div className="flex items-center">
                      {item.costOfWorkVersion}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  onClick={() =>
                    navigate(
                      getStudyRequestUrl(
                        item.id,
                        projectId,
                        routeName,
                        item.typeId,
                        true,
                      ),
                    )
                  }
                  className="cursor-pointer"
                >
                  {item.typeId === TYPE_INFOGRAPHY ? (
                    <div className="flex items-center">
                      {item.infographyVersion}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  onClick={() =>
                    navigate(
                      getStudyRequestUrl(
                        item.id,
                        projectId,
                        routeName,
                        item.typeId,
                        true,
                      ),
                    )
                  }
                  className="cursor-pointer"
                >
                  {item.typeId === TYPE_FEASIBILITY ? (
                    <div className="flex items-center">{item.numberOfLots}</div>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  onClick={() =>
                    navigate(
                      getStudyRequestUrl(
                        item.id,
                        projectId,
                        routeName,
                        item.typeId,
                        true,
                      ),
                    )
                  }
                  className="cursor-pointer"
                >
                  <StudyStatus requestStatusId={item.statusId} />
                </td>
              </>
            )}
          />
        </div>
      ) : (
        <p className="text-sm ml-2">{t("no-study-request")}</p>
      )}
    </div>
  )
}
