import { Button } from "../Button/Button"
import "./WatchChangesBar.scss"

interface IWatchChangesBarProps {
  onSaveAction: {
    onAction: () => void
    content: string
    isLoading: boolean
  }
  onDiscardAction: {
    onAction: () => void
    content: string
  }
  message: string
}

export default function WatchChangesBar({
  message,
  onSaveAction,
  onDiscardAction,
}: IWatchChangesBarProps) {
  return (
    <div className="WatchChangesBar__Container">
      <div className="WatchChangesBar__Content">
        <p className="WatchChangesBar__Message">{message}</p>
        <div className="WatchChangesBar__Buttons">
          <div className="WatchChangesBar__Discard_Button">
            <Button
              onClick={onDiscardAction.onAction}
              size="small"
              mode="outline"
              isLoading={false}
            >
              {onDiscardAction.content}
            </Button>
          </div>
          <Button
            type="submit"
            size="small"
            mode="secondary"
            isLoading={onSaveAction.isLoading}
            onClick={onSaveAction.onAction}
          >
            {onSaveAction.content}
          </Button>
        </div>
      </div>
    </div>
  )
}
