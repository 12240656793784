import { useMutation, useQuery } from "@tanstack/react-query"
import {
  addAgreementPlot,
  createAgreement,
  deleteAgreement,
  deleteAgreementPlot,
  getAgreement,
  getAgreementChildren,
  getAgreementsByProjectId,
  updateAgreement,
} from "core/api/agreements"
import {
  TAgreement,
  TAgreementChildren,
  TAgreementList,
  TMutateAgreement,
} from "shared/types/agreement.type"
import { CustomAxiosError } from "shared/types/axios.type"

function useCreateAgreement() {
  return useMutation<TAgreement, CustomAxiosError, TMutateAgreement>({
    mutationFn: (agreement) => createAgreement(agreement),
  })
}

const agreementDetailQuery = (id: number) => {
  return useQuery<TAgreement, CustomAxiosError>({
    queryKey: ["agreement", id],
    queryFn: () => getAgreement(id),
  })
}

function useUpdateAgreement(id: number) {
  return useMutation<TAgreement, CustomAxiosError, TMutateAgreement>({
    mutationFn: (agreement) => updateAgreement(id, agreement),
  })
}

function useGetAgreementsByProjectId(projectId?: string, search: string = "") {
  return useQuery<TAgreementList, CustomAxiosError, TAgreementList>({
    queryKey: ["getAgreementsByProjectId", projectId, search],
    queryFn: () => getAgreementsByProjectId(projectId, search),
    ...{
      enabled: Boolean({
        projectId,
        search,
      }),
    },
  })
}

function useDeleteAgreement(id: string) {
  return useMutation<TAgreement, CustomAxiosError, string>({
    mutationFn: () => deleteAgreement(id),
  })
}

function useGetAgreementChildren(id: string) {
  return useQuery<TAgreementChildren, CustomAxiosError>({
    queryKey: ["getAgreementChildren", id],
    queryFn: () => getAgreementChildren(id),
  })
}

function useAddAgreementPlot(id: number) {
  return useMutation<void, CustomAxiosError, number>({
    mutationFn: (plotId) => addAgreementPlot(id, plotId),
  })
}

function useDeleteAgreementPlot(id: number) {
  return useMutation<void, CustomAxiosError, number>({
    mutationFn: (plotId) => deleteAgreementPlot(id, plotId),
  })
}

export {
  agreementDetailQuery,
  useAddAgreementPlot,
  useCreateAgreement,
  useDeleteAgreement,
  useDeleteAgreementPlot,
  useGetAgreementChildren,
  useGetAgreementsByProjectId,
  useUpdateAgreement,
}
