import DateFormat from "components/Date/DateFormat"

export interface IDateFormatterProps {
  date: Date | string | null | undefined
}

export function DateFormatter(props: IDateFormatterProps) {
  const { date } = props
  return date ? <DateFormat date={new Date(date)} isDefinitive /> : null
}
