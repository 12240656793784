import { IIconType } from "shared/types/global.type"

function ArrowLeft({ width = 16, height = 16, color = "#00304D" }: IIconType) {
  return (
    <svg
      style={{ marginTop: -4 }}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
    >
      <path
        fill={color}
        d="M7.375 14.375 3 10l4.375-4.375 1.063 1.063L5.875 9.25H17v1.5H5.875l2.563 2.562Z"
      />
    </svg>
  )
}

export default ArrowLeft
