import { IIconType } from "shared/types/global.type"

function Search({ width = 16, height = 16, color = "#00304D" }: IIconType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M19.2751 20.525L13.2751 14.55C12.7918 14.9667 12.2168 15.2917 11.5501 15.525C10.8834 15.7583 10.1751 15.875 9.4251 15.875C7.59176 15.875 6.0376 15.2417 4.7626 13.975C3.4876 12.7083 2.8501 11.175 2.8501 9.375C2.8501 7.575 3.4876 6.04167 4.7626 4.775C6.0376 3.50833 7.5751 2.875 9.3751 2.875C11.1751 2.875 12.7084 3.50833 13.9751 4.775C15.2418 6.04167 15.8751 7.575 15.8751 9.375C15.8751 10.0917 15.7584 10.7833 15.5251 11.45C15.2918 12.1167 14.9501 12.7417 14.5001 13.325L20.5501 19.3C20.7168 19.4667 20.8001 19.6667 20.8001 19.9C20.8001 20.1333 20.7084 20.3417 20.5251 20.525C20.3418 20.6917 20.1293 20.775 19.8876 20.775C19.6459 20.775 19.4418 20.6917 19.2751 20.525ZM9.4001 14.175C10.7168 14.175 11.8376 13.7042 12.7626 12.7625C13.6876 11.8208 14.1501 10.6917 14.1501 9.375C14.1501 8.05833 13.6876 6.92917 12.7626 5.9875C11.8376 5.04583 10.7168 4.575 9.4001 4.575C8.0501 4.575 6.90843 5.04583 5.9751 5.9875C5.04176 6.92917 4.5751 8.05833 4.5751 9.375C4.5751 10.6917 5.04176 11.8208 5.9751 12.7625C6.90843 13.7042 8.0501 14.175 9.4001 14.175Z"
      />
    </svg>
  )
}

export default Search
