import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios"
import GED_API from "core/ged/axios"
import { useGetDocuwareCookie } from "core/query-hooks/useUsers"
import { ReactNode, useEffect } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { toast } from "react-toastify"
import { CustomAxiosError } from "shared/types/axios.type"
import { auth } from "utils/firebase"

interface Props {
  children: ReactNode
}

export default function GedAxiosInterceptor({ children }: Props) {
  const [user] = useAuthState(auth)
  const { data: gedCookieData } = useGetDocuwareCookie(
    user?.providerData[0].uid!,
  )

  useEffect(() => {
    if (gedCookieData) localStorage.setItem("gedCookie", gedCookieData)
    const onRequest = (
      config: InternalAxiosRequestConfig,
    ): InternalAxiosRequestConfig => {
      const storedGedCookie = localStorage.getItem("gedCookie")
      if (!storedGedCookie && gedCookieData)
        localStorage.setItem("gedCookie", gedCookieData)
      const modifiedConfig = { ...config }
      if (modifiedConfig.headers && storedGedCookie)
        modifiedConfig.headers.Authorization = `Basic ${storedGedCookie}`
      return modifiedConfig
    }

    const onRequestError = (error: AxiosError): Promise<AxiosError> => {
      return Promise.reject(error)
    }

    const resInterceptor = (response: AxiosResponse) => {
      return response
    }

    const errInterceptor = async (error: CustomAxiosError) => {
      if (error.response?.status === 409) {
        if (
          error.response?.data.errorType ===
          "A file with this name already exists"
        )
          toast.error(error.response.data.message)
      }
    }

    const interceptorRequest = GED_API.interceptors.request.use(
      onRequest,
      onRequestError,
    )

    const interceptorResponse = GED_API.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    )

    return () => {
      GED_API.interceptors.request.eject(interceptorRequest)
      GED_API.interceptors.response.eject(interceptorResponse)
    }
  }, [gedCookieData])

  return <>{children}</>
}
