import { t } from "i18next"
import {
  HOUSING_ESTATE_TYPE,
  RESIDENTIAL_TYPE,
  TERTIARY_TYPE,
} from "shared/resources/operation.resources"
import { TTypeOperationLiteral } from "shared/types/operation.type"
import DropdownFilters from "../DropdownFilter"
import useProgramsSearch from "../useProgramsSearch"

export default function TypesFilter() {
  const { state, dispatch } = useProgramsSearch()
  const { types } = state.filters
  const typesOption = [HOUSING_ESTATE_TYPE, RESIDENTIAL_TYPE, TERTIARY_TYPE]
  const allTypesOption = {
    id: -1,
    label: t("select-all"),
  }

  const optionsWithAllTypes = [allTypesOption, ...typesOption]

  const handleTypeClick = (
    type: Omit<TTypeOperationLiteral, "abbreviation">,
  ) => {
    if ((type.id as number) === -1) {
      const shouldSelectAll = types.length !== typesOption.length
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...state.filters,
          types: shouldSelectAll ? typesOption.map((typ) => typ.id) : [],
          skip: 0,
          page: 1,
        },
      })
    } else {
      state.filters.types?.find((typeId) => typeId === type.id)
        ? dispatch({
            type: "SET_FILTERS",
            payload: {
              ...state.filters,
              types: state.filters.types?.filter(
                (typeId) => typeId !== type.id,
              ),
              skip: 0,
              page: 1,
            },
          })
        : dispatch({
            type: "SET_FILTERS",
            payload: {
              ...state.filters,
              types: [...state.filters.types, type.id],
              skip: 0,
              page: 1,
            },
          })
    }
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900">
              {t("types")}
              {types.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-purple-800 bg-purple-100 rounded-full">
                  {types.length}
                </div>
              )}
            </div>
          )}
          options={optionsWithAllTypes}
          renderOption={(type, index) => (
            <div role="menu">
              <li
                onClick={() =>
                  handleTypeClick(
                    type as Omit<TTypeOperationLiteral, "abbreviation">,
                  )
                }
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id={`menu-item-${index}`}
                key={`menu-item-${index}`}
              >
                <input
                  className="mr-2"
                  type="checkbox"
                  readOnly
                  checked={
                    type.id === -1
                      ? types.length === typesOption.length
                      : (
                          state.filters.types?.map(
                            (typeId) => typeId,
                          ) as number[]
                        ).includes(type.id)
                  }
                />
                {t(type.label)}
              </li>
            </div>
          )}
          keyExtractor={(type) => type.id}
        />
      </div>
    </div>
  )
}
