export default function Alvis() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Calque_1"
      x={0}
      y={0}
      height={48}
      viewBox="0 0 277.23 169.1"
    >
      <style id="style2" type="text/css">
        {
          ".st1{fill:#99ba20}.st20{fill:#aec013}.st25{fill:#b2c210}.st26{fill:#182f4d}"
        }
      </style>
      <g id="g64">
        <path
          id="path4"
          d="m187.05 15.61-34.2 112.95h-15.91L110.1 38.77s15.45-3.33 23.75 26.6l12.36 44.42 22.09-75.05c-.01-.01 6.17-22.47 18.75-19.13"
          style={{
            fill: "#cbcb13",
          }}
        />
        <g id="g62">
          <path
            id="polygon6"
            d="m146.21 109.78 6.62 18.87 4.82-15.93-11.29-3.44z"
            className="st1"
          />
          <path
            id="polygon8"
            d="m146.36 109.28 11.29 3.44.39-1.29-11.3-3.44z"
            className="st1"
          />
          <path
            id="polygon10"
            d="m146.74 107.99 11.3 3.44.4-1.29-11.32-3.44z"
            style={{
              fill: "#9aba1f",
            }}
          />
          <path
            id="polygon12"
            d="m147.12 106.7 11.32 3.44.39-1.29-11.33-3.45z"
            style={{
              fill: "#9bbb1e",
            }}
          />
          <path
            id="polygon14"
            d="m147.5 105.4 11.33 3.45.39-1.29-11.34-3.45z"
            style={{
              fill: "#9cbb1e",
            }}
          />
          <path
            id="polygon16"
            d="m147.88 104.11 11.34 3.45.39-1.29-11.35-3.45z"
            style={{
              fill: "#9ebb1d",
            }}
          />
          <path
            id="polygon18"
            d="m148.26 102.82 11.35 3.45.39-1.29-11.36-3.46z"
            style={{
              fill: "#9fbc1c",
            }}
          />
          <path
            id="polygon20"
            d="m148.64 101.52 11.36 3.46.39-1.29-11.37-3.46z"
            style={{
              fill: "#a0bc1c",
            }}
          />
          <path
            id="polygon22"
            d="m149.02 100.23 11.37 3.46.39-1.29-11.38-3.46z"
            style={{
              fill: "#a1bc1b",
            }}
          />
          <path
            id="polygon24"
            d="m149.4 98.94 11.38 3.46.39-1.29-11.39-3.47z"
            style={{
              fill: "#a2bd1b",
            }}
          />
          <path
            id="polygon26"
            d="m149.78 97.64 11.39 3.47.39-1.29-11.4-3.47z"
            style={{
              fill: "#a3bd1a",
            }}
          />
          <path
            id="polygon28"
            d="m150.16 96.35 11.4 3.47.39-1.29-11.41-3.47z"
            style={{
              fill: "#a5bd19",
            }}
          />
          <path
            id="polygon30"
            d="m150.54 95.06 11.41 3.47.39-1.29-11.42-3.48z"
            style={{
              fill: "#a6be19",
            }}
          />
          <path
            id="polygon32"
            d="m150.92 93.76 11.42 3.48.39-1.29-11.43-3.48z"
            style={{
              fill: "#a7be18",
            }}
          />
          <path
            id="polygon34"
            d="m151.3 92.47 11.43 3.48.39-1.29-11.44-3.48z"
            style={{
              fill: "#a8be17",
            }}
          />
          <path
            id="polygon36"
            d="m151.68 91.18 11.44 3.48.39-1.29-11.45-3.49z"
            style={{
              fill: "#a9bf17",
            }}
          />
          <path
            id="polygon38"
            d="m152.06 89.88 11.45 3.49.39-1.29-11.46-3.49z"
            style={{
              fill: "#aabf16",
            }}
          />
          <path
            id="polygon40"
            d="m152.44 88.59 11.46 3.49.39-1.29-11.46-3.49z"
            style={{
              fill: "#abbf15",
            }}
          />
          <path
            id="polygon42"
            d="m152.83 87.3 11.46 3.49.39-1.29-11.47-3.5z"
            style={{
              fill: "#acc015",
            }}
          />
          <path
            id="polygon44"
            d="m153.21 86 11.47 3.5.39-1.29-11.48-3.5z"
            style={{
              fill: "#adc014",
            }}
          />
          <path
            id="polygon46"
            d="m153.59 84.71 11.48 3.5.4-1.29-11.5-3.5z"
            className="st20"
          />
          <path
            id="polygon48"
            d="m153.97 83.42 11.5 3.5.39-1.29-11.51-3.51z"
            className="st20"
          />
          <path
            id="polygon50"
            d="m154.35 82.12 11.51 3.51.39-1.29-11.52-3.51z"
            style={{
              fill: "#afc112",
            }}
          />
          <path
            id="polygon52"
            d="m154.73 80.83 11.52 3.51.39-1.29-11.53-3.51z"
            style={{
              fill: "#b0c111",
            }}
          />
          <path
            id="polygon54"
            d="m155.11 79.54 11.53 3.51.39-1.29-11.54-3.52z"
            style={{
              fill: "#b1c111",
            }}
          />
          <path
            id="polygon56"
            d="m155.49 78.24 11.54 3.52.39-1.29-11.55-3.52z"
            style={{
              fill: "#b1c110",
            }}
          />
          <path
            id="polygon58"
            d="m155.87 76.95 11.55 3.52.39-1.3-11.56-3.51z"
            className="st25"
          />
          <path
            id="path60"
            d="m168.29 34.73-12.04 40.93 11.56 3.52 19.24-63.57c-.87-.23-1.71-.34-2.52-.34-10.88 0-16.24 19.46-16.24 19.46z"
            className="st25"
          />
        </g>
      </g>
      <g id="g70">
        <path
          id="path66"
          d="M42.62 57.39h12.2l27 71h-14.2l-6.6-17.7h-24.8l-6.5 17.7h-14.2l27.1-71zm15.9 43.6-9.8-27.9-10.2 27.9h20z"
          className="st26"
          style={{
            fill: "#fff",
            fillOpacity: 1,
          }}
        />
        <path
          id="path68"
          d="M88.22 55.39h13.4v56.2c0 1.93.47 3.43 1.4 4.5.93 1.07 2.27 1.6 4 1.6.8 0 1.68-.13 2.65-.4s1.88-.6 2.75-1l1.8 10.2c-1.8.87-3.9 1.54-6.3 2-2.4.47-4.57.7-6.5.7-4.2 0-7.45-1.12-9.75-3.35s-3.45-5.42-3.45-9.55v-60.9z"
          className="st26"
          style={{
            fill: "#fff",
            fillOpacity: 1,
          }}
        />
      </g>
      <g id="g76">
        <path
          id="path72"
          d="M185.35 66.65v-13.3h13.4v13.3h-13.4zm0 59.7v-52.4h13.4v52.4h-13.4z"
          className="st26"
          style={{
            fill: "#fff",
            fillOpacity: 1,
          }}
        />
        <path
          id="path74"
          d="M230.65 127.35c-4.4 0-8.7-.7-12.9-2.1-4.2-1.4-7.8-3.4-10.8-6l5-8.4c3.2 2.27 6.32 3.98 9.35 5.15 3.03 1.17 6.05 1.75 9.05 1.75 2.67 0 4.77-.5 6.3-1.5 1.53-1 2.3-2.43 2.3-4.3 0-1.87-.9-3.23-2.7-4.1-1.8-.87-4.73-1.87-8.8-3-3.4-.93-6.3-1.83-8.7-2.7-2.4-.87-4.33-1.85-5.8-2.95-1.47-1.1-2.53-2.37-3.2-3.8-.67-1.43-1-3.15-1-5.15 0-2.67.52-5.07 1.55-7.2a15.64 15.64 0 0 1 4.35-5.45c1.87-1.5 4.05-2.65 6.55-3.45 2.5-.8 5.22-1.2 8.15-1.2 3.93 0 7.62.57 11.05 1.7 3.43 1.13 6.58 2.97 9.45 5.5l-5.4 8.1c-2.67-2-5.25-3.47-7.75-4.4-2.5-.93-4.98-1.4-7.45-1.4-2.27 0-4.17.47-5.7 1.4-1.53.93-2.3 2.43-2.3 4.5 0 .93.18 1.7.55 2.3.37.6.95 1.13 1.75 1.6.8.47 1.85.92 3.15 1.35 1.3.43 2.92.88 4.85 1.35 3.6.93 6.68 1.87 9.25 2.8 2.57.93 4.67 2 6.3 3.2 1.63 1.2 2.83 2.58 3.6 4.15.77 1.57 1.15 3.45 1.15 5.65 0 5.13-1.9 9.18-5.7 12.15-3.8 2.96-8.97 4.45-15.5 4.45z"
          className="st26"
          style={{
            fill: "#fff",
            fillOpacity: 1,
          }}
        />
      </g>
    </svg>
  )
}
