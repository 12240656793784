import { ArcElement, Chart, Chart as ChartJS, Legend, Tooltip } from "chart.js"
import Spinner from "components/Spinner/Spinner"
import { useGetSpoLotsByOperation } from "core/spo/query-hooks/useLots"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { Pie } from "react-chartjs-2"
import LOT_PROGRAMME from "shared/resources/lots-reservations.resources"
import formatNumberInKiloEuros from "utils/formatNumberInKiloEuros"

ChartJS.register(ArcElement, Tooltip, Legend)

export interface ILotStatusDiagramProps {
  registrationId: string
}

export function LotStatusDiagram(props: ILotStatusDiagramProps) {
  const { registrationId } = props

  const { data, isLoading, error } = useGetSpoLotsByOperation(registrationId)

  const [chartData, setChartData] = useState({
    labels: [""],
    datasets: [
      {
        data: [1],
        backgroundColor: ["#FFBF00", "#03A9F5", "#7DB43B", "#7D56C1", "#bbb"],
        borderColor: ["#FFBF00", "#03A9F5", "#7DB43B", "#7D56C1", "#bbb"],
        borderWidth: 1,
      },
    ],
  })

  const [chartOptions, setChartOptions] = useState({
    plugins: {
      legend: {
        display: true,
        labels: {
          generateLabels: (chart: Chart) => {
            const pieChartdata = chart?.config?.data
            if (pieChartdata.labels?.length && pieChartdata.datasets.length) {
              return pieChartdata.labels.map((label, i) => ({
                text: `${label} : ${pieChartdata.datasets[0].data[i]}`,
                fillStyle: chartData.datasets[0].backgroundColor[i],
                hidden: false,
                index: i,
              }))
            }
            return []
          },
        },
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            const title = context.dataset[0].label
            return title
          },
        },
      },
    },
  })

  const programTotal = data?.lotStatuses.find(
    (status) => status.lotStatusName === LOT_PROGRAMME.label,
  )?.totalAmount

  const programTotalPrice = data?.lotStatuses.find(
    (status) => status.lotStatusName === LOT_PROGRAMME.label,
  )?.totalPrice

  const lotHasData =
    data?.lotStatuses.find(
      (status) => status.lotStatusName === LOT_PROGRAMME.label,
    )?.totalAmount !== 0

  function findTotalPrice(label: string) {
    const lot = data?.lotStatuses.find(
      (status) => status.lotStatusName === label,
    )
    if (lot) return lot?.totalPrice
    return 0
  }

  useEffect(() => {
    const getLotsSum = data?.lotStatuses
      .filter((status) => status.lotStatusName !== LOT_PROGRAMME.label)
      .map((status) => {
        return {
          amount: status.totalAmount,
          label: status.lotStatusName,
        }
      })

    if (getLotsSum) {
      setChartData((previousData) => {
        return {
          labels: getLotsSum?.map((item) => item.label),
          datasets: [
            {
              data: getLotsSum?.map((item) => item.amount),
              backgroundColor: previousData.datasets[0].backgroundColor,
              borderColor: previousData.datasets[0].borderColor,
              borderWidth: previousData.datasets[0].borderWidth,
            },
          ],
        }
      })
    }
  }, [data])

  useEffect(() => {
    setChartOptions({
      plugins: {
        legend: {
          display: true,
          labels: {
            generateLabels: (chart: Chart) => {
              const pieChartdata = chart?.config?.data
              if (pieChartdata.labels?.length && pieChartdata.datasets.length) {
                return pieChartdata.labels.map((label, i) => ({
                  text: `${label} : ${pieChartdata.datasets[0].data[i]}`,
                  fillStyle: chartData.datasets[0].backgroundColor[i],
                  hidden: false,
                  index: i,
                }))
              }
              return []
            },
          },
        },
        tooltip: {
          callbacks: {
            title: (context: any) => {
              return [
                `${context[0].label}`,
                `${context[0].formattedValue} / ${programTotal} ${t(
                  "lots",
                )} - ${
                  programTotal
                    ? (
                        (context[0].formattedValue / programTotal) *
                        100
                      ).toFixed(0)
                    : "-"
                } %`,
                `${formatNumberInKiloEuros(
                  data?.lotStatuses.find(
                    (lot) => lot.lotStatusName === context[0].label,
                  )?.totalPrice,
                )} / ${formatNumberInKiloEuros(programTotalPrice)} - ${
                  programTotalPrice && findTotalPrice(context[0].label) !== 0
                    ? (
                        (findTotalPrice(context[0].label) / programTotalPrice) *
                        100
                      ).toFixed(0)
                    : "-"
                } %`,
              ]
            },
          },
        },
      },
    })
  }, [chartData])

  if (isLoading)
    return (
      <div className="p-4">
        <Spinner />
      </div>
    )

  if (error)
    return (
      <p className="p-2 text-sm Color__Primary">
        {t("error-loading-reservations-lots")}
      </p>
    )

  return lotHasData ? (
    <Pie options={chartOptions} className="m-3" data={chartData} />
  ) : (
    <p className="text-sm Color__Primary p-2">
      {t("no-stock-reservation-lots-available")}
    </p>
  )
}
