import { Dispatch, createContext } from "react"
import { TSearchActions, TSearchState } from "./searchProgramsReducer"

interface ISearchProgramState {
  state: TSearchState
  dispatch: Dispatch<TSearchActions>
}

const ProgramSearchContext = createContext<ISearchProgramState | null>(null)

export default ProgramSearchContext
