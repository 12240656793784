import { useQueryClient } from "@tanstack/react-query"
import ButtonIcon from "components/Button/ButtonIcon"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import useListBusinessUnits from "core/query-hooks/useBusinessUnits"
import { useUpdateChildOperation } from "core/query-hooks/useOperations"
import {
  useGetTranchesCommercialesByTrancheTravaux,
  useGetTranchesTravaux,
} from "core/spo/query-hooks/useTranches"
import { format } from "date-fns"
import { t } from "i18next"
import { useState } from "react"
import { FaEye } from "react-icons/fa"
import { TbArrowBigRightFilled } from "react-icons/tb"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TChildOperation, TOperation } from "shared/types/operation.type"
import renderTranslation from "utils/renderTranslation"

interface ISelectBuChilOpe {
  idChildOpe: number
  idBu: number
}

function SelectBuChilOpe(props: ISelectBuChilOpe) {
  const { data: businessUnits } = useListBusinessUnits()
  const { idChildOpe, idBu } = props
  const businessId = 0
  const queryClient = useQueryClient()
  const updateChildOperation = useUpdateChildOperation(idChildOpe)
  const { id: opeId } = useParams()

  const [businessUnitId, setBusinessUnitId] = useState<number>(businessId)

  const handleBusinessUnitChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newBusinessUnitId = e.target.value
    setBusinessUnitId(+newBusinessUnitId)

    updateChildOperation.mutate(
      {
        businessUnitId: Number(newBusinessUnitId),
      },
      {
        onSuccess: () => {
          toast.success(t("bu-updated"))
          queryClient.invalidateQueries({
            queryKey: ["listChildOperations", +opeId!],
          })
        },
        onError: (error) => {
          toast.error(
            `${t("bu-update-error")}: ${error.response?.data.error.message}`,
          )
        },
      },
    )
  }

  return (
    <select
      id={`businessUnitId${idChildOpe}`}
      value={businessUnitId || idBu}
      onChange={(e) => handleBusinessUnitChange(e)}
      className="Select__Field Text__Field flex items-center justify-center cursor-pointer font-semibold Select__Wrapper"
    >
      {businessUnits!.map((businessUnit) => (
        <option
          key={`businessUnits-${businessUnit.id}`}
          value={businessUnit.id}
        >
          {businessUnit.label}
        </option>
      ))}
    </select>
  )
}

interface IDisplayTrancheCommerciale {
  registrationId: string
  trancheTravauxId: string
}

function DisplayTrancheCommerciale(props: IDisplayTrancheCommerciale) {
  const { trancheTravauxId, registrationId } = props
  const {
    data: tranchesCommerciales,
    isLoading,
    error,
  } = useGetTranchesCommercialesByTrancheTravaux(
    registrationId,
    trancheTravauxId,
  )

  if (isLoading) return <Spinner />

  if (error) return <p>{`${t("error-tranches-commerciales")}`}</p>

  return (
    <div>
      {tranchesCommerciales?.tranchesCommerciales.map((tc) => (
        <div className="mt-2 pl-6" key={tc.id}>
          <span className="font-bold">{`${t("tranche-commerciale")}`}</span>
          {` : ${tc.label}  - ${tc.lots} ${tc.lots <= 1 ? "lot" : "lots"}`}
        </div>
      ))}
    </div>
  )
}

interface IDisplayTrancheTravaux {
  registrationId: string
}

function DisplayTrancheTravaux(props: IDisplayTrancheTravaux) {
  const { registrationId } = props
  const {
    data: tranchesTravaux,
    isLoading,
    error,
  } = useGetTranchesTravaux(registrationId)

  if (isLoading) return <Spinner />

  if (error) return <p>{`${t("error-tranches-travaux")}`}</p>

  return (
    <div>
      {tranchesTravaux?.value.map((tt) => (
        <div key={tt.id}>
          <div className="mt-2 pl-6">
            <span className="font-bold">{`${t("tranche-travaux")}`}</span>
            {` : ${tt.libelle}`}
          </div>
          <div className="mt-2 pl-12">
            <DisplayTrancheCommerciale
              trancheTravauxId={tt.id}
              registrationId={registrationId}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export interface IOperationOverviewModalProps {
  operation: TOperation
  childOperations: TChildOperation[]
}

export function OperationOverviewModal(props: IOperationOverviewModalProps) {
  const { operation, childOperations } = props
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const handleCloseModal = () => {
    toggleModal()
  }
  const dateCratedProject = format(
    new Date(operation.operationBase.createdAt),
    "dd/MM/yyyy",
  )

  return (
    <>
      <TooltipWithHover
        message={`${t("operation-overview")}`}
        position="center"
      >
        <ButtonIcon onClick={toggleModal}>
          <FaEye size={22} className="text-cyan-800" />
        </ButtonIcon>
      </TooltipWithHover>

      <Modal
        isShowing={isModalShowed}
        closeModal={handleCloseModal}
        title={`${t("main-operation")} : ${operation.operationBase.address} - ${
          operation.operationBase.postcode
        } ${operation.operationBase.city}`}
        description={`${t("date-project-created")} ${dateCratedProject}`}
        width="large"
        displayCloseIcon
      >
        <div className="p-1 overflow-auto" style={{ maxHeight: "60vh" }}>
          {childOperations?.map((childOp) => (
            <div className="mt-4" key={childOp.id}>
              <div className="md:pl-6 pl-0 flex flex-wrap items-center">
                <TbArrowBigRightFilled size={20} className="mr-2" />
                <span className="font-bold">{`${t("child-operation")}`}</span>
                {` : ${childOp.registrationNumber} - ${renderTranslation(
                  childOp,
                )} - `}
                <SelectBuChilOpe
                  idChildOpe={childOp.id}
                  idBu={childOp.businessUnit.id}
                />
              </div>
              <div className="mt-2 md:pl-12 pl-0">
                <DisplayTrancheTravaux
                  registrationId={childOp.registrationNumber}
                />
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}
