import {
  IoChevronBackCircleSharp,
  IoChevronForwardCircle,
} from "react-icons/io5"
import { Link, useLocation, useParams } from "react-router-dom"
import { TOperationIds } from "shared/types/operation.type"

export default function OperationsNavigation() {
  const location = useLocation()
  const { id } = useParams()
  const operations = location.state?.operations as TOperationIds[]
  const getCurrentOperation = (element: TOperationIds) =>
    element.id === Number(id)

  function findIndex() {
    return operations?.findIndex(getCurrentOperation)
  }

  const currentIndex = findIndex()
  return (
    <div>
      <div className="flex text-cyan-900">
        {operations[currentIndex - 1] && (
          <Link
            to={
              operations[currentIndex - 1].intranetUrlSci5
                ? `${operations[currentIndex - 1].intranetUrlSci5}`
                : `/operation/${operations[currentIndex - 1].id}`
            }
            target={
              operations[currentIndex - 1].intranetUrlSci5 ? `_blank` : `_self`
            }
            state={{ operations }}
            className="cursor-pointer"
          >
            <IoChevronBackCircleSharp fontSize={32} />
          </Link>
        )}
        {operations[currentIndex + 1] && (
          <Link
            to={
              operations[currentIndex + 1].intranetUrlSci5
                ? `${operations[currentIndex + 1].intranetUrlSci5}`
                : `/operation/${operations[currentIndex + 1].id}`
            }
            target={
              operations[currentIndex + 1].intranetUrlSci5 ? `_blank` : `_self`
            }
            state={{ operations }}
            className="cursor-pointer"
          >
            <IoChevronForwardCircle fontSize={32} />
          </Link>
        )}
      </div>
    </div>
  )
}
