import classnames from "classnames"
import { format } from "date-fns"

interface IStandByHistoryProps {
  classNames?: string
  label: string
  date: Date
}

export default function StandByHistory(props: IStandByHistoryProps) {
  const { classNames, label, date } = props
  return (
    <span className={classnames(classNames)}>
      {`${label}: ${format(new Date(date), "dd/MM/yyyy")}`}
    </span>
  )
}
