import classNames from "classnames"
import ProgramCard from "components/Cards/ProgramCard"
import { Pagination } from "components/Pagination/Pagination"
import { SearchInput } from "components/SearchInput/SearchInput"
import Spinner from "components/Spinner/Spinner"
import ToggleStandAlone from "components/Toggle/ToggleStandAlone"
import QueryBoundaries from "core/QueryBoundaries"
import {
  useListOperationsIds,
  useListOperationsWithParams,
} from "core/query-hooks/useOperations"
import { useListProjectsWithParams } from "core/query-hooks/useProjects"
import PinProject from "features/project/PinProject"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { Fragment, useCallback, useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import {
  STATUS_ABANDONMENT,
  STATUS_ABANDONMENT_ON_GOING,
  STATUS_ASSEMBLY,
  STATUS_ENGAGEMENT_COMMITTEE,
  STATUS_LAND_COMMITTEE,
  STATUS_NEGOCIATION,
  STATUS_NOT_ACTED,
  STATUS_ON_DELIVERY,
  STATUS_ON_GOING,
  STATUS_PROSPECTION,
  STATUS_STANDBY,
  STATUS_WAITING_ASSEMBLY,
} from "shared/resources/operation.resources"
import { useDebouncedCallback } from "use-debounce"
import { addLeadingZero, getDepartmentFromPostalCode } from "utils/departments"
import useIsMobile from "utils/isMobile"
import ProgramsFilters from "./ProgramsFilters"
import SavedSearches from "./SavedSearches"
import SelectedCompaniesFilters from "./filters/SelectedCompaniesFilters"
import SelectedContributorsFilters from "./filters/SelectedContributorsFilters"
import SelectedDepartmentsFilters from "./filters/SelectedDepartmentsFilters"
import SelectedRegionsFilters from "./filters/SelectedRegionsFilters"
import SelectedStepsFilters from "./filters/SelectedStepsFilters"
import SelectedTypesFilters from "./filters/SelectedTypesFilters"
import { TSearchStateFilters } from "./searchProgramsReducer"
import useProgramsSearch from "./useProgramsSearch"

export function DisplayStep(props: { stepId: number; stepLabel: string }) {
  const { stepId, stepLabel } = props
  let bgColor: string
  let textColor: string
  switch (stepId) {
    case STATUS_ABANDONMENT:
      bgColor = "bg-white-300 border border-gray-200"
      textColor = "border border-gray-200 text-gray-900"
      break
    case STATUS_STANDBY:
      bgColor = "bg-gray-200"
      textColor = "text-gray-900"
      break
    case STATUS_WAITING_ASSEMBLY:
      bgColor = "bg-orange-200"
      textColor = "text-orange-900"
      break
    case STATUS_ASSEMBLY:
      bgColor = "bg-yellow-100"
      textColor = "text-yellow-900"
      break
    case STATUS_NOT_ACTED:
      bgColor = "bg-red-200"
      textColor = "text-red-900"
      break
    case STATUS_ON_GOING:
      bgColor = "bg-green-300"
      textColor = "text-green-900"
      break
    case STATUS_ON_DELIVERY:
      bgColor = "bg-blue-300"
      textColor = "text-blue-900"
      break
    case STATUS_ABANDONMENT_ON_GOING:
      bgColor = "bg-stone-800"
      textColor = "text-white"
      break
    default:
      bgColor = "bg-gray-100"
      textColor = "text-black"
  }
  return (
    <span className={classNames("rounded-full py-1 px-2", bgColor, textColor)}>
      {stepLabel}
    </span>
  )
}

function buildLink(type: string, id: number, intranetUrlSci5: string | null) {
  if (intranetUrlSci5) {
    return intranetUrlSci5
  }
  return type === "operation" ? `/operation/${id}` : `/project/${id}`
}

export default function ProgramsListResults() {
  const [searchParams] = useSearchParams()
  const { state, dispatch } = useProgramsSearch()
  const [displayOperation, setDisplayOperation] = useState<boolean>(true)
  const [resultsLength] = useState(20)
  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1,
  )
  const [searchText, setSearchText] = useState<string>()
  const [sortOrder, setSortOrder] = useState<string>(
    searchParams.get("sortOrder") ?? "asc",
  )
  const [sortColumn, setSortColumn] = useState<string | null>(
    searchParams.get("sortColumn") ?? "stepId",
  )

  const isMobile = useIsMobile()

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage)
    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...state.filters,
        skip: (nextPage - 1) * resultsLength,
        page: (nextPage * resultsLength) / 20,
      },
    })
  }

  const handleDisplayChange = (displayOp: boolean) => {
    setDisplayOperation(displayOp)
    handlePageChange(1)
  }

  const handleSortChange = (columnName: string) => {
    if (sortColumn === columnName) {
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...state.filters,
          sortOrder: sortOrder === "asc" ? "desc" : "asc",
          sortColumn: columnName,
        },
      })
      setSortOrder(sortOrder === "asc" ? "desc" : "asc")
    } else {
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...state.filters,
          sortOrder: "asc",
          sortColumn: columnName,
        },
      })
      setSortColumn(columnName)
      setSortOrder("asc")
    }
  }

  const {
    data: projects,
    isLoading: isLoadingProjects,
    refetch: refetchProjects,
    error: fetchProjectsError,
  } = useListProjectsWithParams(searchParams)

  const {
    data: operations,
    isLoading: isLoadingOperations,
    refetch: refetchOperations,
    error: fetchOperationsError,
  } = useListOperationsWithParams(searchParams)

  const { data: operationsIds, refetch: refetchOperationsIds } =
    useListOperationsIds(searchParams)

  useEffect(() => {
    refetchOperations()
    refetchProjects()
    refetchOperationsIds()
  }, [searchParams])

  const errors = fetchOperationsError || fetchProjectsError

  useEffect(() => {
    if (errors) {
      dispatch({
        type: "SET_FILTERS",
        payload: {
          steps: [
            STATUS_PROSPECTION,
            STATUS_NEGOCIATION,
            STATUS_LAND_COMMITTEE,
            STATUS_ENGAGEMENT_COMMITTEE,
            STATUS_STANDBY,
            STATUS_WAITING_ASSEMBLY,
            STATUS_ASSEMBLY,
            STATUS_NOT_ACTED,
            STATUS_ON_GOING,
            STATUS_ON_DELIVERY,
          ],
          departments: [],
          regions: [],
          users: [],
          companies: [],
          types: [],
          take: resultsLength,
          skip: 0,
          search: "",
          sortOrder: "asc",
          sortColumn: "stepId",
          page: 1,
        },
      })
    }
  }, [errors])

  const totalOperations = operations?.metadata?.maxTotalHits ?? 0
  const totalProjects = projects?.metadata?.maxTotalHits ?? 0

  const countResults = displayOperation ? totalOperations : totalProjects
  const isLoading = isLoadingProjects && isLoadingOperations

  const projectsPrograms = projects
    ? projects?.data?.map((p) => {
        return {
          type: "project",
          name: p.name,
          id: p.id,
          step: { stepId: p.step.id, stepLabel: p.step.label },
          businessUnit: p.businessUnit.label,
          address: p.address,
          postcode: p.postcode,
          city: p.city,
          registrationNumbers: null,
          intranetUrlSci5: p.intranetUrlSci5,
        }
      })
    : []

  const operationsPrograms = operations
    ? operations?.data?.map((p) => {
        return {
          type: "operation",
          name: p.operationBase.name,
          id: p.operationBase.id,
          step: {
            stepId: p.operationBase.step.id,
            stepLabel: p.operationBase.step.label,
          },
          businessUnit: p.operationBase.businessUnit.label,
          address: p.operationBase.address,
          postcode: p.operationBase.postcode,
          city: p.operationBase.city,
          registrationNumbers: p.operationBase.registrationNumbers,
          intranetUrlSci5: p.operationBase.intranetUrlSci5,
        }
      })
    : []

  const sortedPrograms = [
    ...(displayOperation ? operationsPrograms : projectsPrograms),
  ]

  const handleTextChange = useDebouncedCallback(
    useCallback(
      (value: string, filters: TSearchStateFilters) => {
        setSearchText(value)
        dispatch({
          type: "SET_FILTERS",
          payload: {
            ...filters,
            search: value,
            sortColumn: sortColumn ?? "",
            sortOrder,
            skip: 0,
            page: 1,
          },
        })
      },
      [searchText],
    ),
    700,
  )

  useEffect(() => {
    const page = state.filters.skip / 20 + 1
    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...state.filters,
        page,
      },
    })
  }, [state.filters.skip])

  useEffect(() => {
    setCurrentPage(state.filters.page)
  }, [state.filters.page])

  if (isLoading) return <Spinner />

  const sortOrderArrow = searchParams.get("sortOrder") === "asc" ? "↑" : "↓"

  return (
    <div className="pt-4">
      <div className="pb-4">
        <SavedSearches />
      </div>
      <div className="pb-4">
        <div className="mb-4 w-full">
          <SearchInput
            searchLabel={`${t("search-project-op")}`}
            handleChange={(s) => {
              handleTextChange(s, state.filters)
            }}
            defaultValue={searchParams.get("search") ?? ""}
            isWhite
          />
        </div>
        <div className="md:flex md:items-center md:justify-between">
          <ProgramsFilters
            isOperation={displayOperation}
            handlePageChange={handlePageChange}
          />
          <ToggleStandAlone
            onChange={() => handleDisplayChange(!displayOperation)}
            leftLabel={t("operations")}
            rightLabel={t("projects")}
            checked={displayOperation}
          />
        </div>

        <QueryBoundaries loadingComponent={<Spinner />}>
          <div className="flex items-center flex-wrap">
            <div className="mt-2">
              <SelectedContributorsFilters />
            </div>
            <div className="mt-2">
              <SelectedStepsFilters />
            </div>
            <div className="mt-2">
              <SelectedRegionsFilters />
            </div>
            <div className="mt-2">
              <SelectedDepartmentsFilters isOperation={displayOperation} />
            </div>
            <div className="mt-2">
              <SelectedTypesFilters />
            </div>
            <div className="mt-2">
              <SelectedCompaniesFilters />
            </div>
          </div>
        </QueryBoundaries>
      </div>
      {countResults > 0 ? (
        <>
          {isMobile ? (
            <div>
              {sortedPrograms.map((program) => {
                const link = buildLink(
                  program.type,
                  program.id,
                  program.intranetUrlSci5,
                )
                const target = program.intranetUrlSci5 ? "_blank" : "_self"
                return (
                  <div className="mb-2">
                    <ProgramCard
                      link={link}
                      target={target}
                      program={program}
                      displayOperation={displayOperation}
                      operationsIds={operationsIds}
                    />
                  </div>
                )
              })}
              <p className="px-2 text-sm text-sky-900 flex">
                {`${(currentPage - 1) * resultsLength + 1}-${Math.min(
                  currentPage * resultsLength,
                  countResults,
                )} ${" "}  ${t("on")} ${countResults} ${t("results")}`}
              </p>
              {sortedPrograms && countResults / resultsLength > 1 && (
                <Pagination
                  previousLabel={`${t("previous")}`}
                  nextLabel={`${t("next")}`}
                  page={currentPage || 1}
                  totalPages={Math.ceil(countResults / resultsLength)}
                  handlePagination={(nextPage) => handlePageChange(nextPage)}
                />
              )}
            </div>
          ) : (
            <div className="Light_Table__Wrapper Table__Wrapper_NoTop mt-2">
              <div className="Table__Container">
                <table className="Table">
                  <thead>
                    <tr className="text-left">
                      <th
                        className="Table__Head Table__Top_Border cursor-pointer w-48"
                        onClick={() => handleSortChange("stepId")}
                      >
                        {t("steps")}
                        {searchParams.get("sortColumn") === "stepId" && (
                          <span className="ml-2 font-bold text-lg">
                            {sortOrderArrow}
                          </span>
                        )}
                      </th>
                      <th
                        className="Table__Head Table__Top_Border cursor-pointer w-36"
                        onClick={() => handleSortChange("business-unit")}
                      >
                        {t("business-unit-acronym")}
                        {searchParams.get("sortColumn") === "business-unit" && (
                          <span className="ml-2 font-bold text-lg">
                            {sortOrderArrow}
                          </span>
                        )}
                      </th>
                      <th
                        className="Table__Head Table__Top_Border cursor-pointer w-36"
                        onClick={() => handleSortChange("departments")}
                      >
                        {t("county")}
                        {searchParams.get("sortColumn") === "departments" && (
                          <span className="ml-2 font-bold text-lg">
                            {sortOrderArrow}
                          </span>
                        )}
                      </th>
                      <th
                        className="Table__Head Table__Top_Border cursor-pointer w-64"
                        onClick={() => handleSortChange("city")}
                      >
                        {t("city")}
                        {searchParams.get("sortColumn") === "city" && (
                          <span className="ml-2 font-bold text-lg">
                            {sortOrderArrow}
                          </span>
                        )}
                      </th>
                      <th
                        className="Table__Head Table__Top_Border w-1/4 cursor-pointer"
                        onClick={() => handleSortChange("address")}
                      >
                        {t("address")}
                        {searchParams.get("sortColumn") === "address" && (
                          <span className="ml-2 font-bold text-lg">
                            {sortOrderArrow}
                          </span>
                        )}
                      </th>
                      {displayOperation ? (
                        <th className="Table__Head Table__Top_Border w-36">
                          {t("registration")}
                        </th>
                      ) : null}
                      <th className="Table__Head Table__Top_Border w-12">
                        {t("pin")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedPrograms.map((program, index) => {
                      const link = buildLink(
                        program.type,
                        program.id,
                        program.intranetUrlSci5,
                      )
                      const target = program.intranetUrlSci5
                        ? "_blank"
                        : "_self"
                      return (
                        <Fragment key={program.id}>
                          <tr className="Table__Body_Row hover:bg-gray-100 transition-colors">
                            <td
                              className={classNames("Table__Bottom_Border", {
                                "border-t border-gray-200": index === 0,
                              })}
                            >
                              <Link
                                to={link}
                                target={target}
                                state={{
                                  operations: operationsIds,
                                }}
                                className="cursor-pointer"
                              >
                                <DisplayStep
                                  stepId={program.step.stepId}
                                  stepLabel={program.step.stepLabel}
                                />
                              </Link>
                            </td>
                            <td
                              className={classNames("Table__Bottom_Border", {
                                "border-t border-gray-200": index === 0,
                              })}
                            >
                              <Link
                                to={link}
                                target={target}
                                state={{
                                  operations: operationsIds,
                                }}
                                className="cursor-pointer"
                              >
                                {program.businessUnit}
                              </Link>
                            </td>
                            <td
                              className={classNames("Table__Bottom_Border", {
                                "border-t border-gray-200": index === 0,
                              })}
                            >
                              <Link
                                to={link}
                                target={target}
                                state={{
                                  operations: operationsIds,
                                }}
                                className="cursor-pointer"
                              >
                                {addLeadingZero(
                                  getDepartmentFromPostalCode(program.postcode),
                                )}
                              </Link>
                            </td>
                            <td
                              className={classNames("Table__Bottom_Border", {
                                "border-t border-gray-200": index === 0,
                              })}
                            >
                              <Link
                                to={link}
                                target={target}
                                state={{
                                  operations: operationsIds,
                                }}
                                className="cursor-pointer"
                              >
                                {`${program.city} - ${program.postcode}`}
                              </Link>
                            </td>
                            <td
                              className={classNames("Table__Bottom_Border", {
                                "border-t border-gray-200": index === 0,
                              })}
                            >
                              <Link
                                to={link}
                                target={target}
                                state={{
                                  operations: operationsIds,
                                }}
                                className="cursor-pointer"
                              >
                                {program.address}
                              </Link>
                            </td>
                            {displayOperation ? (
                              <td
                                className={classNames("Table__Bottom_Border", {
                                  "border-t border-gray-200": index === 0,
                                })}
                              >
                                <Link
                                  to={link}
                                  target={target}
                                  state={{
                                    operations: operationsIds,
                                  }}
                                  className="cursor-pointer"
                                >
                                  {program.registrationNumbers?.map(
                                    (number) => <p key={nanoid()}>{number}</p>,
                                  )}
                                </Link>
                              </td>
                            ) : null}
                            <td
                              className={classNames("Table__Bottom_Border", {
                                "border-t border-gray-200": index === 0,
                              })}
                            >
                              <PinProject programId={program.id} />
                            </td>
                          </tr>
                        </Fragment>
                      )
                    })}
                  </tbody>
                </table>
                <p className="px-2 text-sm text-sky-900 flex">
                  {`${(currentPage - 1) * resultsLength + 1}-${Math.min(
                    currentPage * resultsLength,
                    countResults,
                  )} ${" "}  ${t("on")} ${countResults} ${t("results")}`}
                </p>
              </div>
              {sortedPrograms && countResults / resultsLength > 1 && (
                <Pagination
                  previousLabel={`${t("previous")}`}
                  nextLabel={`${t("next")}`}
                  page={currentPage || 1}
                  totalPages={Math.ceil(countResults / resultsLength)}
                  handlePagination={(nextPage) => handlePageChange(nextPage)}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <p className="mt-6 text-sky-900">{t("no-results-found")}</p>
      )}
    </div>
  )
}
