import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { useGetEdvSynthesis } from "core/query-hooks/useEdv"
import { t } from "i18next"
import { nanoid } from "nanoid"
import { Fragment } from "react/jsx-runtime"

export interface IEdvSynthesisProps {
  businessUnits: number[]
  departments: string[]
  companies: number[]
  products: string[]
  year: number
  caType: string
}

export default function EdvSynthesis(props: IEdvSynthesisProps) {
  const { year, caType, businessUnits, departments, companies, products } =
    props
  const {
    data: edvSynthesis,
    isLoading,
    error,
  } = useGetEdvSynthesis({
    year,
    caType,
    businessUnits,
    departments,
    companies,
    products,
  })

  function getPreviousMonth() {
    const currentMonth = new Date().getMonth()
    const previousMonthIndex = (currentMonth - 1 + 12) % 12
    const monthNames = [
      t("january-abv"),
      t("february-abv"),
      t("march-abv"),
      t("april-abv"),
      t("may-abv"),
      t("june-abv"),
      t("july-abv"),
      t("august-abv"),
      t("september-abv"),
      t("october-abv"),
      t("november-abv"),
      t("december-abv"),
    ]
    return monthNames[previousMonthIndex]
  }

  const prevMonth = getPreviousMonth()

  const headers = [
    () => "",
    () => "",
    () => t("nb-lots"),
    () => t("initial-stock-abrv"),
    () => "",
    () => t("january-abv"),
    () => t("february-abv"),
    () => t("march-abv"),
    () => t("april-abv"),
    () => t("may-abv"),
    () => t("june-abv"),
    () => t("july-abv"),
    () => t("august-abv"),
    () => t("september-abv"),
    () => t("october-abv"),
    () => t("november-abv"),
    () => t("december-abv"),
    () => "",
    () => `${t("cumulative-end")} ${prevMonth}`,
    () => `${t("gap-objective")} (%)`,
    () => `${t("gap-n-1")} (%)`,
    () => t("total"),
    () => t("day-stock"),
    () => t("options"),
  ]

  let totalRows = 0

  return (
    <>
      {isLoading && (
        <div className="flex justify-center">
          <Spinner />
        </div>
      )}
      {edvSynthesis && (
        <div className="Light_Table_Edv__Wrapper Table__Wrapper_NoTop">
          <div className="Table__Container">
            <table className="Table table-auto">
              <thead>
                <tr className="text-left">
                  {headers.map((header) => (
                    <th
                      className="Table__Head Table__Top_Border text-right border-b border-gray-200"
                      key={nanoid()}
                    >
                      {header()}
                    </th>
                  ))}
                </tr>
              </thead>
              {edvSynthesis.length > 0 ? (
                <tbody>
                  {edvSynthesis.map((item) => {
                    totalRows = 0
                    return (
                      <Fragment key={nanoid()}>
                        {item.values.map((line, index) => {
                          const isFirstRow = index === 0
                          const rowSpan = isFirstRow ? item.values.length : 0 // rowSpan pour la première cellule
                          totalRows += 1

                          return (
                            <tr
                              key={line.type}
                              className={classNames({
                                "bg-gray-100 text-gray-500":
                                  line.code === "objectif",
                              })}
                            >
                              {isFirstRow && (
                                <td
                                  rowSpan={rowSpan}
                                  className="Table__Bottom_Border font-semibold align-top text-left"
                                >
                                  {item.bu}
                                </td>
                              )}
                              <td className="Table__Bottom_Border text-right">
                                {line.type}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.nombre_lots}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.stock_debut}
                              </td>
                              <td className="bg-gray-200" />
                              <td className="Table__Bottom_Border text-right">
                                {line.janvier
                                  ? Number(line.janvier).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.fevrier
                                  ? Number(line.fevrier).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.mars
                                  ? Number(line.mars).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.avril
                                  ? Number(line.avril).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.mai
                                  ? Number(line.mai).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.juin
                                  ? Number(line.juin).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.juillet
                                  ? Number(line.juillet).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.aout
                                  ? Number(line.aout).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.septembre
                                  ? Number(line.septembre).toLocaleString(
                                      "fr-FR",
                                    )
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.octobre
                                  ? Number(line.octobre).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.novembre
                                  ? Number(line.novembre).toLocaleString(
                                      "fr-FR",
                                    )
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.decembre
                                  ? Number(line.decembre).toLocaleString(
                                      "fr-FR",
                                    )
                                  : ""}
                              </td>
                              <td className="bg-gray-200" />
                              <td className="Table__Bottom_Border text-right">
                                {line.cumul
                                  ? Number(line.cumul).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td
                                className={classNames(
                                  "Table__Bottom_Border text-right",
                                  {
                                    Red: line.ecart_objectif_pourcentage < 0,
                                  },
                                  {
                                    Green: line.ecart_objectif_pourcentage >= 0,
                                  },
                                )}
                              >
                                {line.ecart_objectif_pourcentage
                                  ? `${Number(line.ecart_objectif_pourcentage).toLocaleString("fr-FR")} %`
                                  : ""}
                                {line.ecart_objectif_lot
                                  ? `(${Number(line.ecart_objectif_lot).toLocaleString("fr-FR")})`
                                  : ""}
                              </td>
                              <td
                                className={classNames(
                                  "Table__Bottom_Border text-right",
                                  {
                                    Red: line.ecart_n1_pourcentage < 0,
                                  },
                                  {
                                    Green: line.ecart_n1_pourcentage >= 0,
                                  },
                                )}
                              >
                                {line.ecart_n1_pourcentage
                                  ? `${Number(line.ecart_n1_pourcentage).toLocaleString("fr-FR")} %`
                                  : ""}
                                {line.ecart_n1_lot
                                  ? `(${Number(line.ecart_n1_lot).toLocaleString("fr-FR")})`
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.total
                                  ? Number(line.total).toLocaleString("fr-FR")
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                {line.stock_jour
                                  ? Number(line.stock_jour).toLocaleString(
                                      "fr-FR",
                                    )
                                  : ""}
                              </td>
                              <td className="Table__Bottom_Border text-right">
                                <p className="mr-3">
                                  {line.nombre_options
                                    ? Number(
                                        line.nombre_options,
                                      ).toLocaleString("fr-FR")
                                    : ""}
                                </p>
                              </td>
                            </tr>
                          )
                        })}
                        {totalRows > 1 && (
                          <tr className="Table__Separator">
                            <td
                              colSpan={headers.length}
                              className="Table_Separator_td"
                            />
                          </tr>
                        )}
                      </Fragment>
                    )
                  })}
                </tbody>
              ) : (
                <tbody className="text-center p-2">
                  <tr>
                    <td colSpan={headers.length}>
                      {t("no-data-found-message")}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
      {error ? <>{t("data-error")}</> : null}
    </>
  )
}
