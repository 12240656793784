import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import SpoAxiosInterceptor from "core/interceptors/SpoInterceptor"
import { useListChildOperations } from "core/query-hooks/useOperations"
import OperationChildItemFinancial from "features/operations/OperationChildItemFinancial"
import { OperationBudget } from "features/operations/budget/OperationBudget"
import i18next, { t } from "i18next"
import { useParams } from "react-router-dom"
import DivStickyMenu from "./DivStickyMenu"

export function OperationChildrenList() {
  const { id } = useParams()
  const {
    data: operationsChildren,
    isLoading,
    error,
  } = useListChildOperations(Number(id!))

  if (isLoading) return <Spinner />

  if (error || !operationsChildren)
    return (
      <p>
        {`${i18next.t("error")}`} : {error?.message}
      </p>
    )

  return (
    <Tabs isOperationChildren>
      {operationsChildren?.length > 1 ? (
        <TabContent title={t("main-operation")}>
          <SpoAxiosInterceptor>
            <div className="my-2 overflow-scroll">
              <OperationBudget
                childOperationIds={operationsChildren!.map(
                  (operation) => operation.registrationNumber,
                )}
              />
            </div>
          </SpoAxiosInterceptor>
        </TabContent>
      ) : null}
      {operationsChildren!.map((operation) => {
        return (
          <TabContent
            title={operation.registrationNumber}
            key={operation.registrationNumber}
          >
            <SpoAxiosInterceptor>
              <OperationChildItemFinancial operationChild={operation} />
            </SpoAxiosInterceptor>
          </TabContent>
        )
      })}
    </Tabs>
  )
}

export function OperationFinancial() {
  return (
    <>
      <DivStickyMenu />
      <div className="p-4 pb-32">
        <Box>
          <OperationChildrenList />
        </Box>
      </div>
    </>
  )
}
