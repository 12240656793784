import { useMutation, useQuery } from "@tanstack/react-query"
import {
  addCopromoterAssociate,
  createCopromoter,
  listCopromoterAssociates,
  listCopromotersByOperation,
  updateCopromoter,
} from "core/api/copromoters"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TCopromoter,
  TCopromoterAssociate,
  TMutateCopromoter,
} from "shared/types/copromoter.type"

function useListCopromotersByOperation(operationId: number) {
  return useQuery<TCopromoter[], CustomAxiosError>({
    queryKey: ["listCopromotersByOperation", operationId],
    queryFn: () => listCopromotersByOperation(operationId),
  })
}

function useCreateCopromoter() {
  return useMutation<TCopromoter, CustomAxiosError, TMutateCopromoter>({
    mutationFn: (copromoter: TMutateCopromoter) => createCopromoter(copromoter),
  })
}

function useUpdateCopromoter(copromoterId: number) {
  return useMutation<TCopromoter, CustomAxiosError, TMutateCopromoter>({
    mutationFn: (copromoter: TMutateCopromoter) =>
      updateCopromoter(copromoterId, copromoter),
  })
}

function useListCopromoterAssociates() {
  return useQuery<TCopromoterAssociate[], CustomAxiosError>({
    queryKey: ["listCopromoterAssociates"],
    queryFn: () => listCopromoterAssociates(),
  })
}

function useAddCopromoterAssociate() {
  return useMutation<TCopromoterAssociate, CustomAxiosError, { label: string }>(
    {
      mutationFn: (data) => addCopromoterAssociate(data),
    },
  )
}

export {
  useAddCopromoterAssociate,
  useCreateCopromoter,
  useListCopromoterAssociates,
  useListCopromotersByOperation,
  useUpdateCopromoter,
}
