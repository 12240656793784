import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import { useListChildOperations } from "core/query-hooks/useOperations"
import GedExplorer from "features/ged/GedExplorer"
import { t } from "i18next"
import { useParams } from "react-router-dom"
import { OPERATION_DOCUMENTS_VIEWTAG } from "shared/resources/view-tags.resources"
import countSpoLotsByOperation from "utils/countsSpoLotsByOperation"
import DivStickyMenu from "./DivStickyMenu"

export default function OperationDocuments() {
  const { id } = useParams()
  const {
    data: operationsChildren,
    isLoading,
    error,
  } = useListChildOperations(Number(id!))

  if (isLoading) return <Spinner />

  if (error || !operationsChildren)
    return (
      <p>
        {`${t("error")}`} : {error?.message}
      </p>
    )

  const spoLots = countSpoLotsByOperation(operationsChildren)

  const tabs = []
  tabs.push({
    label: `${t("all-documents")}`,
    registrationNumbers: operationsChildren.map((oc) => oc.registrationNumber),
    isMother: true,
  })

  if (operationsChildren.length > 1) {
    operationsChildren.forEach((oc) => {
      const numberOfLots = spoLots.find(
        (item) => item?.registrationNumber === oc.registrationNumber,
      )?.numberOfLots
      tabs.push({
        label: `${oc.registrationNumber} ${
          numberOfLots ? `- ${numberOfLots} ${t("lots-caps")}` : " "
        } `,
        registrationNumbers: [oc.registrationNumber],
      })
    })
  }

  return (
    <>
      <DivStickyMenu />
      <div className="p-4 pb-32">
        <Box classNames="h-full">
          <Tabs isOperationChildren>
            {tabs!.map((tab) => (
              <TabContent
                title={tab.label}
                key={`tab-${tab.registrationNumbers}`}
              >
                <GedAxiosInterceptor>
                  <div className="py-4">
                    <GedExplorer
                      registrationNumbers={tab.registrationNumbers}
                      view={OPERATION_DOCUMENTS_VIEWTAG}
                      isMother={
                        (tab.isMother && operationsChildren.length > 1) ?? false
                      }
                      numStatus="2"
                    />
                  </div>
                </GedAxiosInterceptor>
              </TabContent>
            ))}
          </Tabs>
        </Box>
      </div>
    </>
  )
}
