import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import AvatarBlob from "components/Avatar/AvatarBlob"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { API } from "core/api/axios"
import { useCreateProgress } from "core/query-hooks/useProgress"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { FormEvent, useEffect, useState } from "react"
import { registerLocale } from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import { IndicatorSeparatorProps, OptionProps, components } from "react-select"
import AsyncSelect from "react-select/async"
import { toast } from "react-toastify"
import { TPersonInCharge, TPersonInChargeOption } from "shared/types/user.type"
import * as yup from "yup"

registerLocale("fr", fr)

type FormValues = {
  description: string
  personInCharge: {
    id: number
    azureId: string
    firstName: string
    lastName: string
  }
  deadline: Date
  childOperationId: number
}

function Option(props: OptionProps<TPersonInChargeOption>) {
  const { children, data: user } = props

  return (
    <div>
      <components.Option {...props}>
        <div className="flex items-center">
          <AvatarBlob className="w-7 h-7" user={user} initials />
          <span className="text-cyan-900">{children}</span>
        </div>
      </components.Option>
    </div>
  )
}

const loadOptions = async (
  inputValue: string,
): Promise<TPersonInChargeOption[]> => {
  const res = await API.get<TPersonInCharge[]>(
    `/users?search=${inputValue}&departments=PROGRAMME,TECHNIQUE`,
  )
  const formattedOptions = res.data.map((elm) => ({
    ...elm,
    value: elm.azureId,
    label: `${elm.firstName} ${elm.lastName}`,
  }))
  return formattedOptions
}

const validationSchema = yup.object().shape(
  {
    personInCharge: yup.object().when("deadline", {
      is: (deadline: Date) => !deadline,
      then: () =>
        yup.object({
          azureId: yup.string(),
          firstName: yup.string(),
          lastName: yup.string(),
        }),
      otherwise: () =>
        yup.object({
          azureId: yup
            .string()
            .required(() => `${t("progress-required-person-in-charge")}`),
          firstName: yup
            .string()
            .required(() => `${t("progress-required-person-in-charge")}`),
          lastName: yup
            .string()
            .required(() => `${t("progress-required-person-in-charge")}`),
        }),
    }),
    description: yup.string().required(() => `${t("required-description")}`),
    deadline: yup.date().when("personInCharge", {
      is: (personInCharge: TPersonInCharge) => !personInCharge.azureId,
      then: () => yup.date().nullable().default(null),
      otherwise: () => yup.date().required(() => `${t("required-deadline")}`),
    }),
  },
  [["personInCharge", "deadline"]],
)

const indicatorSeparatorStyle = {
  display: "none",
}

function IndicatorSeparator({
  innerProps,
}: IndicatorSeparatorProps<TPersonInChargeOption, true>) {
  return <span style={indicatorSeparatorStyle} {...innerProps} />
}

export interface IAddProgressModalProps {
  childOperationId: number
}

export function AddProgressModal(props: IAddProgressModalProps) {
  const { childOperationId } = props
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const queryClient = useQueryClient()
  const [startDate, setStartDate] = useState<Date | undefined>()
  const mutation = useCreateProgress()
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState,
    formState: { errors, isSubmitSuccessful, isSubmitted },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema as yup.ObjectSchema<FormValues>),
    defaultValues: {
      deadline: startDate,
      childOperationId,
      personInCharge: undefined,
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [formState, isSubmitted, reset])

  const handleCloseModal = () => {
    setStartDate(undefined)
    toggleModal()
  }

  const submitForm = async (data: FormValues) => {
    await mutation.mutateAsync(
      {
        ...data,
        personInCharge: data.personInCharge?.azureId,
      },
      {
        onSuccess: () => {
          toast.success(`${t("toast-progress-create-success")}`)
          handleCloseModal()
          queryClient.invalidateQueries({
            queryKey: ["getProgressByChildOperationId", childOperationId],
          })
        },
        onError: (err) => {
          toast.error(
            `${t("toast-progress-create-error")} 
            ${err.response?.data.error.message}`,
          )
        },
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <>
      <Button onClick={toggleModal} size="small" mode="primary">
        {`${t("add")}`}
      </Button>
      <Modal
        isShowing={isModalShowed}
        closeModal={handleCloseModal}
        title={`${t("add-progress")}`}
      >
        <form className="Text__Field_Container" onSubmit={onSubmit}>
          <label htmlFor="description" className="font-medium">
            {`${t("description")} *`}
          </label>
          <textarea
            id="description"
            className="Textarea__Field"
            {...register("description")}
            placeholder={`${t("description")}`}
          />
          {errors?.description && (
            <p className="text-red-600 text-sm">{errors.description.message}</p>
          )}

          <div className="mt-4 flex flex-col">
            <label htmlFor="deadline" className="font-medium">
              {`${t("deadlineLabel")}`}
            </label>

            <Controller
              name="deadline"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  id="deadline"
                  className="Text__Field"
                  locale={fr}
                  placeholderText={`${t("deadline")}`}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date!)
                    field.onChange(date)
                  }}
                />
              )}
            />
            {errors?.deadline && (
              <p className="text-red-600 text-sm">{errors.deadline.message}</p>
            )}
          </div>

          <div className="my-4">
            <label htmlFor="personInCharge" className="font-medium">
              {`${t("assigned-to")}`}
              <Controller
                name="personInCharge"
                control={control}
                render={({ field }) => (
                  <AsyncSelect
                    isSearchable
                    components={{
                      IndicatorSeparator,
                      Option,
                      DropdownIndicator: () => null,
                    }}
                    loadOptions={loadOptions}
                    styles={{
                      container: (styles) => ({
                        ...styles,
                        minWidth: "100%",
                        fontSize: "14px",
                      }),
                      control: (styles) => ({
                        ...styles,
                        height: "50px",
                        borderRadius: "8px",
                        border: "1px solid #e5e7eb",
                        backgroundColor: "#F9FAFB",
                      }),
                      placeholder: (styles) => ({
                        ...styles,
                        opacity: 0.7,
                      }),
                    }}
                    noOptionsMessage={() => `${t("typeToBeginSearch")}`}
                    className="mb-4"
                    placeholder={`${t("search-collaborator")}`}
                    onChange={(e) => {
                      field.onChange(e)
                    }}
                  />
                )}
              />
            </label>
            {errors?.personInCharge && errors.personInCharge?.azureId && (
              <p className="text-red-600 text-sm">
                {errors.personInCharge.azureId.message}
              </p>
            )}
          </div>

          <div className="Modal__Footer">
            <Button
              type="button"
              size="medium"
              mode="secondary"
              onClick={() => {
                toggleModal()
                setStartDate(undefined)
                reset()
              }}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              marginLeft={24}
              size="medium"
              mode="primary"
              isLoading={mutation.isPending}
              type="submit"
            >
              {`${t("create")}`}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
