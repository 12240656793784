import i18next from "i18next"
import {
  HOUSING_ESTATE_TYPE,
  RESIDENTIAL_TYPE,
  TERTIARY_TYPE,
} from "shared/resources/operation.resources"
import { TChildOperation } from "shared/types/operation.type"

const operationTypes = [RESIDENTIAL_TYPE, TERTIARY_TYPE, HOUSING_ESTATE_TYPE]

function renderTranslation(op: TChildOperation) {
  const ope = operationTypes.find((operation) => operation.id === op.type.id)
  if (ope) return i18next.t(ope.label)
  return op.type.label
}

export default renderTranslation
