import SPO_API from "core/spo/axios"
import { TBudget, TBudgetOverview } from "shared/types/spo/budget.type"

async function getSyntheseFinanciere(
  childOperationId?: string,
): Promise<TBudget[]> {
  return typeof childOperationId === "undefined"
    ? Promise.reject(new Error("Invalid child operation id"))
    : SPO_API.get(`/operations/${childOperationId}/budget`).then(
        (response) => response.data,
      )
}

async function getSyntheseFinanciereSum(
  childOperationIds: string[],
): Promise<TBudget[]> {
  return typeof childOperationIds === "undefined"
    ? Promise.reject(new Error("Invalid child operation id"))
    : SPO_API.post(`/operations/budget/sum`, { childOperationIds }).then(
        (response) => response.data,
      )
}

async function getBudgetOverview(
  operationId: number,
): Promise<TBudgetOverview> {
  return SPO_API.get(`/operation/${operationId}/budget-overview`).then(
    (response) => response.data,
  )
}

export { getBudgetOverview, getSyntheseFinanciere, getSyntheseFinanciereSum }
