import { API } from "core/api/axios"
import {
  TAction,
  TActionList,
  TActionsUsers,
  TMutateAction,
  TValidateAction,
} from "shared/types/action.type"
import { getUser } from "./users"

async function getActionsByProjectId(
  projectId?: number,
  query?: string,
  skip?: number,
  take?: number,
  order?: "asc" | "desc",
  sort?: string,
  done?: boolean,
): Promise<TActionList> {
  const search = query ? `&search=${query}` : ""
  const skipUrl = skip ? `&skip=${skip}` : ""
  const takeUrl = take ? `&take=${take}` : ""
  const orderUrl = order ? `&order=${order}` : ""
  const sortUrl = sort ? `&sort=${sort}` : ""
  const doneUrl = typeof done !== "undefined" ? `&done=${done}` : ""

  return typeof projectId === "undefined"
    ? Promise.reject(new Error("Invalid project id"))
    : API.get(
        `/actions?project=${projectId}${search}${skipUrl}${takeUrl}${orderUrl}${sortUrl}${doneUrl}`,
      ).then((response) => response.data)
}

async function getActionById(actionId?: number): Promise<TAction> {
  return typeof actionId === "undefined"
    ? Promise.reject(new Error("Invalid action id"))
    : API.get(`/actions/${actionId}`).then((response) => response.data)
}

async function addAction(action: TMutateAction): Promise<TAction> {
  return typeof action === "undefined"
    ? Promise.reject(new Error("Invalid action"))
    : API.post("/action", action).then((response) => response.data)
}

async function updateAction(
  id: number,
  action: TMutateAction,
): Promise<TAction> {
  return typeof id === "undefined"
    ? Promise.reject(new Error("Invalid action id"))
    : API.patch(`/action/${id}`, action).then((response) => response.data)
}

async function deleteAction(id: number): Promise<TAction> {
  const res = await API.delete(`/action/${id}`)
  return res.data
}

async function validateAction(action: TValidateAction): Promise<TAction> {
  return typeof action.id === "undefined"
    ? Promise.reject(new Error("Invalid action id"))
    : API.patch(`/action/${action.id}`, {
        isDefinitive: action.isDefinitive,
      }).then((response) => response.data)
}

async function getUserActions(
  azureId: string,
  date: string,
): Promise<{ data: TAction[]; metadata: { maxTotalHits: number } }> {
  return typeof azureId === "undefined" && typeof date === "undefined"
    ? Promise.reject(new Error("Invalid user azureId"))
    : API.get(`/actions/?deadline=${date}&personInCharge=${azureId}`).then(
        (response) => response.data,
      )
}

async function getActionsPersonInChargeAndCreator(
  actionId: number,
): Promise<TActionsUsers> {
  const action: TAction = await getActionById(actionId)
  const personInCharge = action.personInCharge
    ? await getUser(action?.personInCharge?.azureId!)
    : undefined
  const creator = await getUser(action?.creator?.azureId!)

  return {
    action,
    personInCharge,
    creator,
  }
}

async function hasModificationRight(actionId: number): Promise<boolean> {
  const res = await API.get(`/action/${actionId}/modification-right`)
  return res.data
}

export {
  addAction,
  deleteAction,
  getActionById,
  getActionsByProjectId,
  getActionsPersonInChargeAndCreator,
  getUserActions,
  hasModificationRight,
  updateAction,
  validateAction,
}
