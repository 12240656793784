import classnames from "classnames"
import { PropsWithChildren } from "react"
import Spinner from "../Spinner/Spinner"
import "./Button.scss"

export interface ButtonProps extends PropsWithChildren {
  size: "small" | "medium" | "large"
  mode:
    | "primary"
    | "secondary"
    | "outline"
    | "outline-thin"
    | "danger"
    | "validation"
    | "gray"
  isLoading?: boolean
  type?: "submit" | "button"
  onClick?: () => void
  marginLeft?: 0 | 6 | 8 | 12 | 24 | 32
  classNames?: string
  isDisabled?: boolean
}

const DEFAULT_SIZE = "medium"
const DEFAULT_MODE = "primary"

export function Button({
  type = "button",
  children = null,
  size = DEFAULT_SIZE,
  mode = DEFAULT_MODE,
  marginLeft = 0,
  isLoading = false,
  isDisabled = false,
  classNames,
  onClick,
}: ButtonProps) {
  const loading = isLoading ? "Loading__Spinner" : ""
  return (
    <button
      /* eslint-disable react/button-has-type */
      type={type}
      style={{ marginLeft }}
      onClick={onClick}
      aria-disabled={isLoading || isDisabled}
      disabled={isLoading || isDisabled}
      className={classnames("Button", mode, size, classNames, {
        "opacity-70": isLoading,
        "opacity-60": isDisabled,
      })}
    >
      <span className={["Content", loading].join(" ")}>
        {isLoading ? <Spinner width={15} height={15} /> : children}
      </span>
    </button>
  )
}
