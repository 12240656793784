import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import GedFile from "components/Ged/GedFile"
import { useGetDocumentsById } from "core/ged/query-hooks/useGed"
import { useUpdateOperationCompany } from "core/query-hooks/useOperationCompanies"
import { fr } from "date-fns/locale"
import { AddFile } from "features/studies/AddFile"
import i18next, { t } from "i18next"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import {
  AMO_CONVENTION_DOCUMENT_TYPE,
  COMPANY_STATUS_DOCUMENT_TYPE,
  KBIS_DOCUMENT_TYPE,
  RIB_DOCUMENT_TYPE,
} from "shared/resources/operation-company.resources"
import { TGedUploadResponse } from "shared/types/ged.type"
import {
  TOperationCompany,
  TUpdateOperationCompany,
} from "shared/types/operation-company.type"
import * as yup from "yup"

type FormValues = {
  name?: string
  companyType?: string
  siren?: string | null
  kbis?: number
  status?: number
  statusSignatureDate?: Date
  rib?: number
  amoConvention?: number
  amoPingPercentage?: number | null
  pierrevalCapital: number
  pierrevalMargin: number
  pierrevalContribution: number
}

export interface IEditOperationCompanyProps {
  id: number
  setIsEdit: (value: boolean) => void
  company: TOperationCompany
}

export function EditOperationCompany(props: IEditOperationCompanyProps) {
  const { id, setIsEdit, company } = props
  const queryClient = useQueryClient()
  const updateOperationCompany = useUpdateOperationCompany(id)

  const validationSchema = yup.object().shape({
    siren: yup
      .string()
      .nullable()
      .matches(/^(\d\s?){9}$/, t("siren-validation-error"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
    amoPingPercentage: yup
      .number()
      .min(0, () => i18next.t("zero-or-greater"))
      .max(100, () => i18next.t("hundred-or-lower"))
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    pierrevalCapital: yup
      .number()
      .required()
      .min(0, () => i18next.t("zero-or-greater"))
      .max(100, () => i18next.t("hundred-or-lower")),
    pierrevalMargin: yup
      .number()
      .required()
      .min(0, () => i18next.t("zero-or-greater"))
      .max(100, () => i18next.t("hundred-or-lower")),
    pierrevalContribution: yup
      .number()
      .required()
      .min(0, () => i18next.t("zero-or-greater"))
      .max(100, () => i18next.t("hundred-or-lower")),
  })

  const [kbis, setKbis] = useState<TGedUploadResponse[]>([])
  const [companyStatus, setCompanyStatus] = useState<TGedUploadResponse[]>([])
  const [rib, setRib] = useState<TGedUploadResponse[]>([])
  const [amoConvention, setAmoConvention] = useState<TGedUploadResponse[]>([])

  const kbisDocument = useGetDocumentsById(
    company?.kbis ? company.kbis : 0,
  ).data
  const ribDocument = useGetDocumentsById(company?.rib ? company.rib : 0).data
  const statusDocument = useGetDocumentsById(
    company?.status ? company.status : 0,
  ).data
  const amoConventionDocument = useGetDocumentsById(
    company?.amoConvention ? company.amoConvention : 0,
  ).data

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: company.name,
      companyType: company.companyType,
      siren: company.siren,
      statusSignatureDate: company.statusSignatureDate,
      amoPingPercentage: company.amoPingPercentage,
      pierrevalCapital: company.pierrevalCapital,
      pierrevalMargin: company.pierrevalMargin,
      pierrevalContribution: company.pierrevalContribution,
    },
  })

  useEffect(() => {
    if (kbis.length > 1) {
      setKbis([kbis[kbis.length - 1]])
    }
  }, [kbis])
  useEffect(() => {
    if (companyStatus.length > 1) {
      setCompanyStatus([companyStatus[companyStatus.length - 1]])
    }
  }, [companyStatus])
  useEffect(() => {
    if (rib.length > 1) {
      setRib([rib[rib.length - 1]])
    }
  }, [rib])
  useEffect(() => {
    if (amoConvention.length > 1) {
      setAmoConvention([amoConvention[amoConvention.length - 1]])
    }
  }, [amoConvention])

  const submitForm = (data: TUpdateOperationCompany) => {
    updateOperationCompany.mutate(
      {
        ...data,
        kbis: kbis && kbis.length > 0 ? kbis[0].id : undefined,
        status:
          companyStatus && companyStatus.length > 0
            ? companyStatus[0].id
            : undefined,
        rib: rib && rib.length > 0 ? rib[0].id : undefined,
        amoConvention:
          amoConvention && amoConvention.length > 0
            ? amoConvention[0].id
            : undefined,
      },
      {
        onError: (err) => {
          toast.error(
            `${t("legal-informations-update-error")} : ${
              err.response?.data.message
            }`,
          )
        },
        onSuccess() {
          toast.success(`${t("legal-informations-update-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["getOperationCompany", id],
          })
          setIsEdit(false)
        },
      },
    )
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <form onSubmit={onSubmit}>
      <h2 className="text-primary-dark font-semibold">
        {t("company-reference")}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-3">
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="name">
              {`${t("denomination")}`}
              <input id="name" className="Text__Field" {...register("name")} />
            </label>
          </div>
          {errors.name && (
            <span className="text-red-500 text-xs">{errors.name.message}</span>
          )}
        </div>
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="companyType">
              {`${t("company-type")}`}
              <input
                id="company-type"
                className="Text__Field"
                {...register("companyType")}
              />
            </label>
          </div>
          {errors.companyType && (
            <span className="text-red-500 text-xs">
              {errors.companyType.message}
            </span>
          )}
        </div>
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="siren">
              {`${t("siren")}`}
              <input
                id="siren"
                className="Text__Field"
                {...register("siren")}
              />
            </label>
          </div>
          {errors.siren && (
            <span className="text-red-500 text-xs">{errors.siren.message}</span>
          )}
        </div>
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="kbis">{`${t("kbis")}`}</label>
            <div className="flex items-center justify-start">
              <AddFile
                setFiles={setKbis}
                documentTypes={[KBIS_DOCUMENT_TYPE.label]}
                maxUploadFiles={1}
                hasIcon
                linkedFiles
              />

              {kbis && kbis.length > 0 ? (
                <GedFile id={kbis[0].id} title={kbis[0].title} />
              ) : (
                <>
                  {company?.kbis ? (
                    <>
                      {kbisDocument ? (
                        <GedFile
                          id={kbisDocument.id}
                          title={kbisDocument.title}
                        />
                      ) : (
                        t("fetch-document-error")
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
          {errors.kbis && (
            <span className="text-red-500 text-xs">{errors.kbis.message}</span>
          )}
        </div>
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="companyStatus">{`${t("company-status")}`}</label>
            <div className="flex items-center justify-start">
              <AddFile
                setFiles={setCompanyStatus}
                documentTypes={[COMPANY_STATUS_DOCUMENT_TYPE.label]}
                maxUploadFiles={1}
                hasIcon
                linkedFiles
              />
              {companyStatus && companyStatus.length > 0 ? (
                <GedFile
                  id={companyStatus[0].id}
                  title={companyStatus[0].title}
                />
              ) : (
                <>
                  {company?.status ? (
                    <>
                      {statusDocument ? (
                        <GedFile
                          id={statusDocument.id}
                          title={statusDocument.title}
                        />
                      ) : (
                        t("fetch-document-error")
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
          {errors.status && (
            <span className="text-red-500 text-xs">
              {errors.status.message}
            </span>
          )}
        </div>
        <div>
          <div className="Text__Field_Container flex-col">
            <div>
              <label htmlFor="statusSignatureDate">
                {`${t("status-signature-date")}`}
              </label>
            </div>
            <Controller
              name="statusSignatureDate"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  id="statusSignatureDate"
                  key={company.operationId}
                  locale={fr}
                  className="Text__Field"
                  selected={field.value}
                  placeholderText={`${t("select-date")}`}
                  onChange={(date) => {
                    field.onChange(date)
                  }}
                />
              )}
            />
          </div>
        </div>
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="rib">{`${t("rib")}`}</label>
            <div className="flex items-center justify-start">
              <AddFile
                setFiles={setRib}
                documentTypes={[RIB_DOCUMENT_TYPE.label]}
                maxUploadFiles={1}
                hasIcon
                linkedFiles
              />
              {rib && rib.length > 0 ? (
                <GedFile id={rib[0].id} title={rib[0].title} />
              ) : (
                <>
                  {company?.rib ? (
                    <>
                      {ribDocument ? (
                        <GedFile
                          id={ribDocument.id}
                          title={ribDocument.title}
                        />
                      ) : (
                        t("fetch-document-error")
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
          {errors.rib && (
            <span className="text-red-500 text-xs">{errors.rib.message}</span>
          )}
        </div>
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="amoConvention">{`${t("amo-convention")}`}</label>
            <div className="flex items-center justify-start">
              <AddFile
                setFiles={setAmoConvention}
                documentTypes={[AMO_CONVENTION_DOCUMENT_TYPE.label]}
                maxUploadFiles={1}
                hasIcon
                linkedFiles
              />
              {amoConvention && amoConvention.length > 0 ? (
                <GedFile
                  id={amoConvention[0].id}
                  title={amoConvention[0].title}
                />
              ) : (
                <>
                  {company?.amoConvention ? (
                    <>
                      {amoConventionDocument ? (
                        <GedFile
                          id={amoConventionDocument.id}
                          title={amoConventionDocument.title}
                        />
                      ) : (
                        t("fetch-document-error")
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
          {errors.amoConvention && (
            <span className="text-red-500 text-xs">
              {errors.amoConvention.message}
            </span>
          )}
        </div>
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="amoPingPercentage">
              {`${t("amo-ping-percentage")}`}
              <input
                id="amo-ping-percentage"
                className="Text__Field"
                {...register("amoPingPercentage")}
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                step="0.01"
              />
            </label>
          </div>
          {errors.amoPingPercentage && (
            <span className="text-red-500 text-xs">
              {errors.amoPingPercentage.message}
            </span>
          )}
        </div>
      </div>

      <h2 className="text-primary-dark font-semibold">
        {t("pierreval-part-of-margin-and-capital")}
      </h2>

      <div className="grid grid-cols-4 gap-x-2 pt-4">
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="pierrevalCapital">
              {`${t("pierreval-part-of-capital-in-percent")}`}
              <input
                id="pierreval-capital"
                className="Text__Field"
                {...register("pierrevalCapital")}
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                step="0.01"
                required
              />
            </label>
          </div>
          {errors.pierrevalCapital && (
            <span className="text-red-500 text-xs">
              {errors.pierrevalCapital.message}
            </span>
          )}
        </div>
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="pierrevalMargin">
              {`${t("pierreval-part-of-margin")}`}
              <input
                id="pierreval-margin"
                className="Text__Field"
                {...register("pierrevalMargin")}
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                step="0.01"
                required
              />
            </label>
          </div>
          {errors.pierrevalMargin && (
            <span className="text-red-500 text-xs">
              {errors.pierrevalMargin.message}
            </span>
          )}
        </div>
        <div>
          <div className="Text__Field_Container">
            <label htmlFor="pierrevalContribution">
              {`${t("pierreval-part-of-contribution")}`}
              <input
                id="pierreval-contribution"
                className="Text__Field"
                {...register("pierrevalContribution")}
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                step="0.01"
                required
              />
            </label>
          </div>
          {errors.pierrevalContribution && (
            <span className="text-red-500 text-xs">
              {errors.pierrevalContribution.message}
            </span>
          )}
        </div>
      </div>

      <div className="flex items-center justify-end">
        <Button
          onClick={() => setIsEdit(false)}
          size="small"
          mode="secondary"
          classNames="mr-2"
        >
          {t("cancel")}
        </Button>
        <Button type="submit" size="small" mode="primary">
          {t("update")}
        </Button>
      </div>
    </form>
  )
}
