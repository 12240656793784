const PRODUCT_TYPES = [
  {
    value: "Tertiaire",
    label: "Tertiaire",
  },
  {
    value: "Vente en bloc",
    label: "Vente en bloc",
  },
  {
    value: "Collectif",
    label: "Collectif",
  },
  {
    value: "Lotissement",
    label: "Lotissement",
  },
]

export default PRODUCT_TYPES
