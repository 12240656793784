import { Button } from "components"
import GedFile from "components/Ged/GedFile"
import { useGetDocumentsById } from "core/ged/query-hooks/useGed"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { format } from "date-fns"
import { t } from "i18next"
import { TOperationCompany } from "shared/types/operation-company.type"

export interface IDisplayOperationCompanyProps {
  company: TOperationCompany
  setIsEdit: (value: boolean) => void
}

export function DisplayOperationCompany(props: IDisplayOperationCompanyProps) {
  const { company, setIsEdit } = props

  const kbisDocument = useGetDocumentsById(
    company?.kbis ? company.kbis : 0,
  ).data
  const ribDocument = useGetDocumentsById(company?.rib ? company.rib : 0).data
  const statusDocument = useGetDocumentsById(
    company?.status ? company.status : 0,
  ).data
  const amoConventionDocument = useGetDocumentsById(
    company?.amoConvention ? company.amoConvention : 0,
  ).data

  function formatSiren(siren: string | null | undefined): string {
    if (!siren) return "-"
    const formattedSiren = siren.replace(/\s+/g, "")
    if (formattedSiren.length !== 9) return formattedSiren

    return `${formattedSiren.slice(0, 3)} ${formattedSiren.slice(3, 6)} ${formattedSiren.slice(6, 9)}`
  }

  return (
    <>
      <h2 className="text-primary-dark font-semibold">
        {t("company-reference")}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-3 mb-4">
        <div className="Text__Field_Container">
          <label htmlFor="name">
            {`${t("denomination")}`}
            <p className="Color__Primary text-lg">
              {company?.name ? company.name : "-"}
            </p>
          </label>
        </div>
        <div className="Text__Field_Container">
          <label htmlFor="companyType">
            {`${t("company-type")}`}
            <p className="Color__Primary text-lg">
              {company?.companyType ? company.companyType : "-"}
            </p>
          </label>
        </div>
        <div className="Text__Field_Container">
          <label htmlFor="siren">
            {`${t("siren")}`}
            <p className="Color__Primary text-lg">
              {company?.siren ? formatSiren(company.siren) : "-"}
            </p>
          </label>
        </div>
        <div className="Text__Field_Container">
          <label htmlFor="kbis">
            {`${t("kbis")}`}
            <p className="Color__Primary text-base">
              {company?.kbis ? (
                <>
                  {kbisDocument ? (
                    <GedFile id={kbisDocument.id} title={kbisDocument.title} />
                  ) : (
                    t("fetch-document-error")
                  )}
                </>
              ) : (
                "-"
              )}
            </p>
          </label>
        </div>
        <div className="Text__Field_Container">
          <label htmlFor="status">
            {`${t("company-status")}`}
            <p className="Color__Primary text-base">
              {company?.status ? (
                <>
                  {statusDocument ? (
                    <GedFile
                      id={statusDocument.id}
                      title={statusDocument.title}
                    />
                  ) : (
                    t("fetch-document-error")
                  )}
                </>
              ) : (
                "-"
              )}
            </p>
          </label>
        </div>
        <div className="Text__Field_Container">
          <label htmlFor="statusSignatureDate">
            {`${t("status-signature-date")}`}
            <p className="Color__Primary text-lg">
              {company?.statusSignatureDate
                ? format(new Date(company.statusSignatureDate), "dd/MM/yyyy")
                : "-"}
            </p>
          </label>
        </div>
        <div className="Text__Field_Container">
          <label htmlFor="rib">
            {`${t("rib")}`}
            <p className="Color__Primary text-base">
              {company?.rib ? (
                <>
                  {ribDocument ? (
                    <GedFile id={ribDocument.id} title={ribDocument.title} />
                  ) : (
                    t("fetch-document-error")
                  )}
                </>
              ) : (
                "-"
              )}
            </p>
          </label>
        </div>
        <div className="Text__Field_Container">
          <label htmlFor="amoConvention">
            {`${t("amo-convention")}`}
            <p className="Color__Primary text-base overflow-auto">
              {company?.amoConvention ? (
                <>
                  {amoConventionDocument ? (
                    <GedFile
                      id={amoConventionDocument.id}
                      title={amoConventionDocument.title}
                    />
                  ) : (
                    t("fetch-document-error")
                  )}
                </>
              ) : (
                "-"
              )}
            </p>
          </label>
        </div>
        <div className="Text__Field_Container">
          <label htmlFor="amoPingPercentage">
            {`${t("amo-ping-percentage")}`}
            <p className="Color__Primary text-lg">
              {company?.amoPingPercentage ? company.amoPingPercentage : "-"}
            </p>
          </label>
        </div>
      </div>

      <h2 className="text-primary-dark font-semibold">
        {t("pierreval-part-of-margin-and-capital")}
      </h2>
      <div className="grid grid-cols-4 gap-x-2">
        <div className="Text__Field_Container py-4">
          <label htmlFor="pierrevalCapital">
            {`${t("pierreval-part-of-capital-in-percent")}`}
            <p className="Color__Primary text-lg">{company.pierrevalCapital}</p>
          </label>
        </div>
        <div className="Text__Field_Container py-4">
          <label htmlFor="pierrevalMargin">
            {`${t("pierreval-part-of-margin")}`}
            <p className="Color__Primary text-lg">{company.pierrevalMargin}</p>
          </label>
        </div>
        <div className="Text__Field_Container py-4">
          <label htmlFor="pierrevalContribution">
            {`${t("pierreval-part-of-contribution")}`}
            <p className="Color__Primary text-lg">
              {company.pierrevalContribution}
            </p>
          </label>
        </div>
      </div>
      <PermissionsGuard requiredRoles={["update:operation.company"]}>
        <div className="flex items-center justify-end">
          <Button onClick={() => setIsEdit(true)} size="small" mode="primary">
            {t("edit")}
          </Button>
        </div>
      </PermissionsGuard>
    </>
  )
}
