import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import { FormTextField } from "components/Form/FormTextField"
import { Header } from "components/Header/Header"
import SelectFieldForm from "components/SelectFieldForm/SelectFieldForm"
import { useCreateProject } from "core/query-hooks/useProjects"
import { useGetCurrentUser, useListDevelopers } from "core/query-hooks/useUsers"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { DEFAULT_BUSINESS_UNIT_ID } from "shared/resources/business.resources"
import { TProject, TProjectCreate } from "shared/types/project.type"
import { TUser } from "shared/types/user.type"
import * as yup from "yup"
import SelectCityForm from "./SelectCityForm"

type IFormProjectSchema = {
  name: string
  address: string
  localisation: { label: string; value: string }
  businessUnitId: number
  developer: { label: string; value: string }
}

export default function CreateProjectForm() {
  const createProject = useCreateProject()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data: developers } = useListDevelopers()
  const { t } = useTranslation()
  const { data: user } = useGetCurrentUser()

  const hasSingleDeveloperOption = developers?.selectableDevelopers.length === 1
  const defaultDeveloperOption = hasSingleDeveloperOption
    ? {
        label: `${developers?.selectableDevelopers[0]?.firstName} ${developers?.selectableDevelopers[0]?.lastName}`,
        value: developers?.selectableDevelopers[0]?.azureId,
      }
    : undefined

  const createSchema = hasSingleDeveloperOption
    ? yup.object({
        name: yup.string().required(`${t("required-name")}`),
        address: yup.string().required(`${t("required-address")}`),
        localisation: yup
          .object({
            label: yup.string().required(),
            value: yup.string().required(),
          })
          .default(undefined)
          .required(`${t("required-city")}`),
      })
    : yup.object({
        name: yup.string().required(`${t("required-name")}`),
        address: yup.string().required(`${t("required-address")}`),
        localisation: yup
          .object({
            label: yup.string().required(),
            value: yup.string().required(),
          })
          .default(undefined)
          .required(`${t("required-city")}`),
        developer: yup
          .object({
            label: yup.string().required(),
            value: yup.string().required(),
          })
          .default(undefined)
          .required(() => `${t("required-developer")}`),
      })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormProjectSchema>({
    mode: "onSubmit",
    resolver: yupResolver(createSchema as yup.ObjectSchema<IFormProjectSchema>),
  })

  const onSubmitProject = (data: IFormProjectSchema) => {
    // Enlever le code postal du libellé de la ville
    const separatedCity: string[] = data.localisation.label.split(" - ")
    const city: string = separatedCity[0]
    const userBusinessUnitId = user?.businessUnit ?? DEFAULT_BUSINESS_UNIT_ID

    const projectCreatedObj: TProjectCreate = {
      name: data.name,
      address: data.address,
      city,
      postcode: data.localisation.value,
      businessUnitId: userBusinessUnitId,
      developerAzureId:
        hasSingleDeveloperOption && defaultDeveloperOption
          ? defaultDeveloperOption?.value
          : data.developer.value,
    }

    createProject.mutate(projectCreatedObj, {
      onSuccess: (projectCreatedSuccess: TProject) => {
        toast.success(`${t("create-project-success")}`)
        queryClient.invalidateQueries({ queryKey: ["listProjects"] })
        navigate(`/project/${projectCreatedSuccess.id}`, { replace: true })
      },
      onError: (err) => {
        toast.error(
          `${t("create-project-error")} : ${err.response?.data.message}`,
        )
      },
    })
  }

  return (
    <div className="p-6">
      <Header size="h2">{`${t("create-project")}`}</Header>
      <form onSubmit={handleSubmit(onSubmitProject)}>
        <Box classNames="mt-6">
          <div className="flex flex-col">
            <div className="flex-1">
              <FormTextField
                errors={errors}
                id="name"
                register={register}
                className="Text__Field_Container"
                name="name"
                label={`${t("project.form.nameLabel")} *`}
                type="text"
                placeholder={`${t("project.form.namePlaceholder")}`}
              />
            </div>

            <div className="mt-2 flex-1">
              <FormTextField
                errors={errors}
                id="address"
                register={register}
                className="Text__Field_Container"
                name="address"
                label={`${t("project.form.addressLabel")} *`}
                type="text"
                placeholder={`${t("project.form.addressPlaceholder")}`}
              />
            </div>

            <div className="mt-2 flex-1">
              <SelectCityForm
                noOptionsMessage={() => `${t("no-results")}`}
                placeholder={`${t("search-city")}`}
                name="localisation"
                label={`${t("project.form.localisationLabel")} *`}
                className="w-full"
                control={control}
              />
            </div>

            {!hasSingleDeveloperOption && (
              <div className="mt-2 flex-1">
                <SelectFieldForm
                  label={`${t("project.form.developer")} *`}
                  noOptionsMessage={() => `${t("no-results")}`}
                  placeholder={`${t("project.form.developerPlaceholder")}`}
                  name="developer"
                  control={control}
                  options={developers?.selectableDevelopers.map(
                    (developer: TUser) => ({
                      label: `${developer.firstName} ${developer.lastName}`,
                      value: developer.azureId,
                    }),
                  )}
                />
              </div>
            )}
          </div>
        </Box>

        <div className="flex justify-end items-center mt-4">
          <div className="ml-4">
            <Button
              onClick={() => navigate("/")}
              size="medium"
              mode="secondary"
              isLoading={false}
            >
              {`${t("cancel")}`}
            </Button>
          </div>

          <div className="ml-4">
            <Button
              type="submit"
              size="medium"
              mode="primary"
              isDisabled={createProject.isPending}
            >
              {`${t("create")}`}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
