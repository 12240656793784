import { useMutation, useQuery } from "@tanstack/react-query"
import {
  getOperationCompanyById,
  updateOperationCompany,
} from "core/api/operation-company"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TOperationCompany,
  TUpdateOperationCompany,
} from "shared/types/operation-company.type"

function useGetOperationCompanyById(operationId: number) {
  return useQuery<TOperationCompany, CustomAxiosError>({
    queryKey: ["getOperationCompany", operationId],
    queryFn: () => getOperationCompanyById(operationId),
  })
}

function useUpdateOperationCompany(id: number) {
  return useMutation<
    TOperationCompany,
    CustomAxiosError,
    TUpdateOperationCompany
  >({
    mutationFn: (operationCompany: TUpdateOperationCompany) =>
      updateOperationCompany(id, operationCompany),
  })
}

export { useGetOperationCompanyById, useUpdateOperationCompany }
