import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import ButtonIcon from "components/Button/ButtonIcon"
import DateFormat from "components/Date/DateFormat"
import useModal from "components/Modal/useModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useGetBanks } from "core/query-hooks/useBanks"
import {
  useGetOperationById,
  useUpdateOperation,
} from "core/query-hooks/useOperations"
import { t } from "i18next"
import { ChangeEvent } from "react"
import { BsBank2 } from "react-icons/bs"
import { MdAdd, MdEdit } from "react-icons/md"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { AddBankModal } from "./AddBank"
import { EditBankApprovalDateModal } from "./EditBankApprovalDateModal"
import { EditDepositDateModal } from "./EditDepositDateModal"

export default function ObtainingLoans() {
  const { id } = useParams()
  const { data: banks } = useGetBanks()
  const { data: operation } = useGetOperationById(+id!)
  const mutateBank = useUpdateOperation(+id!)
  const {
    isShowing: isDepositDateModalShowed,
    toggle: toggleDepositDateModal,
  } = useModal()

  const {
    isShowing: isBankApprovalModalShowed,
    toggle: toggleBankApprovalModal,
  } = useModal()

  const { isShowing: isAddBankModalShowed, toggle: toggleBank } = useModal()

  const queryClient = useQueryClient()

  function handleChangeBank(e: ChangeEvent<HTMLSelectElement>) {
    mutateBank.mutate(
      {
        bankId: Number(e.target.value),
      },
      {
        onSuccess() {
          toast.success(t("success-update-bank"))
          queryClient.invalidateQueries({ queryKey: ["operation", +id!] })
        },
        onError() {
          toast.error(t("error-update-bank"))
        },
      },
    )
  }

  return (
    <Box classNames="p-0 h-full">
      <div className="flex items-center border-b border-gray-200 px-3.5 py-3 h-10">
        <BsBank2 className="text-cyan-900 mr-1" />
        <h3 className="text-xs uppercase text-cyan-900 font-medium">
          {t("obtaining-loans")}
        </h3>
      </div>
      <div className="Text__Field_Container px-4 mt-4 flex items-center justify-between">
        {banks && banks.length > 0 ? (
          <div className="w-3/4">
            <select
              className="Select__Field Text__Field cursor-pointer Select__Wrapper"
              onChange={(e) => handleChangeBank(e)}
              value={operation?.operationData?.bank?.id || ""}
              name="select-bank"
            >
              <option key="no-option-bank" value="" disabled>
                {t("select-bank")}
              </option>
              {banks.map((bank) => (
                <option key={`${bank.id}-${bank.label}`} value={bank.id}>
                  {bank.label}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="w-full flex items-center justify-between text-primary-dark">
            <span className="text-xs">{t("bank")}</span>
            <span className="text-sm">
              {operation?.operationData?.bank?.label}
            </span>
          </div>
        )}
        <PermissionsGuard requiredRoles={["create:bank"]}>
          <ButtonIcon className="ml-2 -mt-1" type="button" onClick={toggleBank}>
            <MdAdd size={22} cursor="pointer" className="text-cyan-800" />
          </ButtonIcon>
          {isAddBankModalShowed && (
            <AddBankModal
              isAddBankModalShowed={isAddBankModalShowed}
              toggleBankModal={toggleBank}
            />
          )}
        </PermissionsGuard>
      </div>

      <ul className="py-2 pt-3 pb-4">
        <li className="px-4 flex items-center justify-between pb-1">
          <p className="text-body text-cyan-950">{t("file-submission")}</p>
          <span className="flex items-center">
            {operation?.operationData?.depositDate ? (
              <DateFormat
                isDefinitive={
                  operation?.operationData?.isDepositDateDefinitive!
                }
                date={new Date(operation?.operationData?.depositDate)}
              />
            ) : (
              "-"
            )}

            <ButtonIcon
              className="ml-2"
              type="button"
              onClick={toggleDepositDateModal}
            >
              <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
            </ButtonIcon>
          </span>
        </li>
        <li className="px-4 flex items-center justify-between pt-1">
          <p className="text-body text-cyan-950">{t("bank-agreement")}</p>
          <span className="flex items-center">
            {operation?.operationData?.bankApproval ? (
              <DateFormat
                isDefinitive={
                  operation?.operationData?.isBankApprovalDefinitive!
                }
                date={new Date(operation?.operationData?.bankApproval)}
              />
            ) : (
              "-"
            )}

            <ButtonIcon
              className="ml-2"
              type="button"
              onClick={toggleBankApprovalModal}
            >
              <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
            </ButtonIcon>
          </span>
        </li>
      </ul>
      {isDepositDateModalShowed && (
        <EditDepositDateModal
          operationDepositDate={operation?.operationData?.depositDate}
          operationIsDepositDateDefinitive={
            operation?.operationData?.isDepositDateDefinitive || null
          }
          isDepositDateModalShowed={isDepositDateModalShowed}
          toggleDepositDateModal={toggleDepositDateModal}
        />
      )}

      {isBankApprovalModalShowed && (
        <EditBankApprovalDateModal
          operationBankApproval={operation?.operationData?.bankApproval}
          operationIsBankApprovalDefinitive={
            operation?.operationData?.isBankApprovalDefinitive || null
          }
          isBankApprovalModalShowed={isBankApprovalModalShowed}
          toggleBankApprovalModal={toggleBankApprovalModal}
        />
      )}
    </Box>
  )
}
