import { Chart, ChartData, registerables } from "chart.js"
import { ChartEvent } from "chart.js/dist/core/core.plugins"
import { ActiveElement } from "chart.js/dist/plugins/plugin.tooltip"
import { Button } from "components"
import Spinner from "components/Spinner/Spinner"
import { useGetReservationsByOperation } from "core/spo/query-hooks/useReservations"
import { useGetTranchesCommerciales } from "core/spo/query-hooks/useTranches"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"

export interface ILotStatusGraphBar {
  registrationId: string
}

export function ReservationGraphBar(props: ILotStatusGraphBar) {
  Chart.register(...registerables)
  const { registrationId } = props
  const { data, isLoading, error } =
    useGetReservationsByOperation(registrationId)

  const { data: tranchesCommerciales } =
    useGetTranchesCommerciales(registrationId)

  const firstVefaActs = tranchesCommerciales?.tranchesCommerciales
    .map((item) => item.VEFA?.date)
    .filter((date): date is Date => date !== undefined && date !== null)
    .map((date) => new Date(date))

  const commercialLaunches = tranchesCommerciales?.tranchesCommerciales
    .map((item) => item.commercialization?.date)
    .filter((date): date is Date => date !== undefined && date !== null)
    .map((date) => new Date(date))

  const [chartData, setChartData] = useState<
    ChartData<"line" | "bar" | any, number[], string>
  >({
    labels: [] as string[],
    datasets: [
      {
        label: t("reservation"),
        type: "bar",
        data: [] as number[],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: t("cancelation"),
        type: "bar",
        data: [] as number[],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: t("variation"),
        type: "line",
        data: [] as number[],
        borderColor: "#000",
        backgroundColor: "#000",
        borderWidth: 1,
      },
      {
        label: `🟢 ${t("put-on-market")}`,
        type: "",
        data: [] as number[],
        backgroundColor: "rgba(200, 200, 200, 0)",
        borderColor: "rgba(200, 200, 200, 0)",
        borderWidth: 0,
      },
      {
        label: `🟡 ${t("first-VEFA-act")}`,
        type: "",
        data: [] as number[],
        backgroundColor: "rgba(200, 200, 200, 0)",
        borderColor: "rgba(200, 200, 200, 0)",
        borderWidth: 0,
      },
    ],
  })
  const [originalChartData, setOriginalChartData] = useState(chartData)
  const [detailedYear, setDetailedYear] = useState<string | null>(null)

  const monthOrder: Record<string, number> = {
    janvier: 1,
    février: 2,
    mars: 3,
    avril: 4,
    mai: 5,
    juin: 6,
    juillet: 7,
    août: 8,
    septembre: 9,
    octobre: 10,
    novembre: 11,
    décembre: 12,
  }

  const sortChartData = (
    labels: string[],
    datasets: {
      label: string
      type: string
      data: number[]
      backgroundColor: string
      borderColor: string
      borderWidth: number
    }[],
  ) => {
    const monthLabels = labels.filter((label) => label.includes(" "))
    const yearLabels = labels.filter((label) => !label.includes(" "))

    // Si monthLabels est vide, cela signifie que labels ne contient que des années
    if (monthLabels.length === 0) {
      return {
        labels,
        datasets: datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.slice(),
        })),
      }
    }

    const monthYears = monthLabels.map((label) => label.split(" ")[1])

    // Séparer les années en années avant et après les mois
    const yearsBefore = yearLabels.filter(
      (year) => parseInt(year) < parseInt(monthYears[0]),
    ) // Années avant la première année des monthLabels
    const yearsAfter = yearLabels.filter(
      (year) => parseInt(year) >= parseInt(monthYears[0]),
    ) // Années à partir de la première année des monthLabels

    // Trier les mois avec années en utilisant l'ordre numérique des mois et des années
    monthLabels.sort((a, b) => {
      const [aMonth, aYear] = a.split(" ")
      const [bMonth, bYear] = b.split(" ")

      if (aYear !== bYear) {
        return parseInt(aYear) - parseInt(bYear)
      }

      return monthOrder[aMonth] - monthOrder[bMonth]
    })

    const sortedLabels = [...yearsBefore, ...monthLabels, ...yearsAfter]

    const sortedDatasets = datasets.map((dataset) => ({
      ...dataset,
      data: sortedLabels.map((label) => {
        const index = labels.indexOf(label)
        return dataset.data[index]
      }),
    }))

    return {
      labels: sortedLabels,
      datasets: sortedDatasets,
    }
  }

  const showMonthlyDetails = (year: string) => {
    const selectedYearData = data?.years.find((y) => y.year.toString() === year)
    if (selectedYearData) {
      const monthLabels = selectedYearData.months.map(
        (month) => `${month.month} ${year}`,
      )
      const monthAmounts = selectedYearData.months.map((month) =>
        month.amount > 0 ? month.amount : 0,
      )
      const monthNegativeAmounts = selectedYearData.months.map((month) =>
        month.amount < 0 ? month.amount : 0,
      )

      const yearlyVariationAmounts: number[] = monthAmounts.map(
        (amount: number, index: number) => {
          const negativeAmount = monthNegativeAmounts[index] || 0
          return amount + negativeAmount
        },
      )

      const otherYears = data?.years.filter((y) => y.year.toString() !== year)
      const otherYearsLabels = otherYears?.map((y) => y.year.toString()) || []
      const otherYearsAmounts =
        otherYears?.map((y) =>
          y.months.reduce(
            (acc, month) => acc + (month.amount > 0 ? month.amount : 0),
            0,
          ),
        ) || []
      const otherYearsNegativeAmounts =
        otherYears?.map((y) =>
          y.months.reduce(
            (acc, month) => acc + (month.amount < 0 ? month.amount : 0),
            0,
          ),
        ) || []

      const otherYearsVariationAmounts: number[] = otherYearsAmounts.map(
        (amount: number, index: number) => {
          const negativeAmount = otherYearsNegativeAmounts[index] || 0
          return amount + negativeAmount
        },
      )

      const sortedData = sortChartData(
        [...otherYearsLabels, ...monthLabels],
        [
          {
            label: t("reservation"),
            type: "bar",
            data: [...otherYearsAmounts, ...monthAmounts],
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
          {
            label: t("cancelation"),
            type: "bar",
            data: [...otherYearsNegativeAmounts, ...monthNegativeAmounts],
            backgroundColor: "rgba(200, 200, 200, 0.5)",
            borderColor: "rgba(200, 200, 200, 1)",
            borderWidth: 1,
          },
          {
            label: t("variation"),
            type: "line",
            data: [...otherYearsVariationAmounts, ...yearlyVariationAmounts],
            borderColor: "#000",
            backgroundColor: "#000",
            borderWidth: 1,
          },
          {
            label: `🟢 ${t("put-on-market")}`,
            type: "",
            data: [] as number[],
            backgroundColor: "rgba(200, 200, 200, 0)",
            borderColor: "rgba(200, 200, 200, 0)",
            borderWidth: 0,
          },
          {
            label: `🟡 ${t("first-VEFA-act")}`,
            type: "",
            data: [] as number[],
            backgroundColor: "rgba(200, 200, 200, 0)",
            borderColor: "rgba(200, 200, 200, 0)",
            borderWidth: 0,
          },
        ],
      )

      setChartData(sortedData)
      setDetailedYear(year)
    }
  }

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        stacked: true,
        ticks: {
          callback(_: string | number, index: number): string {
            const label = chartData.labels?.[index] as string
            const [labelMonth, labelYear] = label.split(" ")
            const labelMonthNumber = monthOrder[labelMonth.toLowerCase()]

            if (labelMonthNumber === undefined) {
              return label
            }

            const labelDate = new Date(
              `${labelYear}-${String(labelMonthNumber).padStart(2, "0")}-01`,
            )

            const isVefa = firstVefaActs?.some(
              (date) =>
                date &&
                date instanceof Date &&
                date.getFullYear() === labelDate.getFullYear() &&
                date.getMonth() === labelDate.getMonth(),
            )

            const isCommercialLaunch = commercialLaunches?.some(
              (date) =>
                date &&
                date instanceof Date &&
                date.getFullYear() === labelDate.getFullYear() &&
                date.getMonth() === labelDate.getMonth(),
            )

            if (isCommercialLaunch && isVefa) {
              return `${label} 🟢🟡`
            }
            if (isCommercialLaunch) {
              return `${label} 🟢`
            }
            if (isVefa) {
              return `${label} 🟡`
            }
            return label
          },
        },
      },
    },
    onClick: (_: ChartEvent, elements: ActiveElement[]) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index
        const selectedYear = chartData.labels?.[elementIndex] ?? ""
        if (detailedYear !== selectedYear) {
          showMonthlyDetails(selectedYear)
        }
      }
    },
  }

  useEffect(() => {
    if (data) {
      const currentYear = new Date().getFullYear()
      const yearlyLabels: string[] = []
      const yearlyAmounts: number[] = []
      const yearlyNegativeAmounts: number[] = []
      const yearlyVariationAmounts: number[] = []

      data.years.forEach((year) => {
        if (year.year < currentYear) {
          let sum = 0
          let negativeSum = 0
          year.months.forEach((month) => {
            if (month.amount > 0) {
              sum += month.amount
            } else {
              negativeSum += month.amount
            }
          })
          yearlyLabels.push(year.year.toString())
          yearlyAmounts.push(sum)
          yearlyNegativeAmounts.push(negativeSum)
          yearlyVariationAmounts.push(sum + negativeSum)
        } else {
          year.months.forEach((month) => {
            if (month.monthNumber <= new Date().getMonth() + 1) {
              yearlyLabels.push(`${month.month} ${year.year}`)
              let sum = 0
              let negativeSum = 0

              if (month.amount > 0) {
                sum = month.amount
              } else {
                negativeSum = month.amount
              }
              yearlyAmounts.push(sum)
              yearlyNegativeAmounts.push(negativeSum)
              yearlyVariationAmounts.push(sum + negativeSum)
            }
          })
        }
      })

      if (yearlyVariationAmounts[yearlyVariationAmounts.length - 1] === 0)
        yearlyVariationAmounts.pop()

      const updatedChartData = sortChartData(yearlyLabels, [
        {
          label: t("reservation"),
          type: "bar",
          data: yearlyAmounts,
          backgroundColor: "rgba(54, 162, 235, 0.5)",
          borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
        },
        {
          label: t("cancelation"),
          type: "bar",
          data: yearlyNegativeAmounts,
          backgroundColor: "rgba(200, 200, 200, 0.5)",
          borderColor: "rgba(200, 200, 200, 1)",
          borderWidth: 1,
        },
        {
          label: t("variation"),
          type: "line",
          data: yearlyVariationAmounts,
          backgroundColor: "#000",
          borderColor: "#000",
          borderWidth: 1,
        },
        {
          label: `🟢 ${t("put-on-market")}`,
          type: "",
          data: [] as number[],
          backgroundColor: "rgba(200, 200, 200, 0)",
          borderColor: "rgba(200, 200, 200, 0)",
          borderWidth: 0,
        },
        {
          label: `🟡 ${t("first-VEFA-act")}`,
          type: "",
          data: [] as number[],
          backgroundColor: "rgba(200, 200, 200, 0)",
          borderColor: "rgba(200, 200, 200, 0)",
          borderWidth: 0,
        },
      ])

      setChartData(updatedChartData)
      setOriginalChartData(updatedChartData)
      setDetailedYear(null)
    }
  }, [data])

  if (isLoading) {
    return (
      <div className="p-4">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return (
      <p className="p-2 text-sm Color__Primary">
        {t("error-loading-reservations-lots")}
      </p>
    )
  }

  if (data?.years && data.years.length > 0) {
    return (
      <div>
        {detailedYear && (
          <div className="p-2 flex justify-start">
            <Button
              onClick={() => {
                setChartData(originalChartData)
                setDetailedYear(null)
              }}
              size="small"
              mode="secondary"
            >
              {t("synthesis")}
            </Button>
          </div>
        )}
        <Bar options={chartOptions} className="m-3 max-h-96" data={chartData} />
      </div>
    )
  }

  return (
    <p className="text-sm Color__Primary p-2">
      {t("no-stock-reservation-lots-available")}
    </p>
  )
}
