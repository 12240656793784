import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import { AuthContext } from "core/auth/AuthProvider"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { RoleProtectedRoute } from "core/permissions/RoleProtectedRoute"
import { useListUserStudyRequests } from "core/query-hooks/useStudyRequests"
import { useListSubordinates } from "core/query-hooks/useUsers"
import CountAlfredTodosContainer from "features/count-todos/count-alfred-todos/CountAlfredTodosContainer"
import CountStudyRequestsTodosContainer from "features/count-todos/count-study-requests-todos/CountStudyRequestsTodosContainer"
import CountTeamStudyRequestsTodosContainer from "features/count-todos/count-study-requests-todos/CountTeamStudyRequestsTodosContainer"
import CountTeamTodosContainer from "features/count-todos/count-team-todos/CountTeamTodosContainer"
import {
  STATUS_CREATED,
  STATUS_DRAFT,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_VALIDATED,
} from "features/studies/studyRequest.resources"
import { t } from "i18next"
import { useContext } from "react"
import { GROUP } from "shared/resources/groups.resources"
import EdvAndNextThreeMonths from "./EdvAndNextThreeMonths"
import ExternalAppsLinks from "./ExternalAppsLinks"
import { UserStudyRequests } from "./UserStudyRequests"

export default function Home() {
  const { getUserInfo } = useContext(AuthContext)
  const userInfo = getUserInfo()

  const { data: studyRequests, error: studyError } = useListUserStudyRequests(
    userInfo.azureId,
  )

  const currentStudyRequests = studyRequests?.filter((studyRequest) => {
    return (
      studyRequest.statusId === STATUS_DRAFT ||
      studyRequest.statusId === STATUS_CREATED ||
      studyRequest.statusId === STATUS_VALIDATED ||
      studyRequest.statusId === STATUS_PRIORIZED_AND_ASSIGNED ||
      studyRequest.statusId === STATUS_PRIORIZATION_STANDBY ||
      studyRequest.statusId === STATUS_RESTITUTION_STANDBY
    )
  })

  const { data: subordinatesData } = useListSubordinates(userInfo.azureId)

  if (studyError) {
    return <p>{studyError.response?.data.message}</p>
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="md:grid lg:grid-cols-6 md:grid-cols-4 md:gap-4 px-8 py-6 gap-y-2">
          <h2 className="col-span-6 font-semibold md:mb-0 mb-2">
            {t("my-expectations")}
          </h2>
          <div className="col-span-2  shadow-lg rounded-lg">
            <CountAlfredTodosContainer />
          </div>
          <PermissionsGuard requiredRoles={["read:study-request.todo-list"]}>
            <div className="col-span-2 mt-2 md:mt-0 shadow-lg rounded-lg">
              <CountStudyRequestsTodosContainer />
            </div>
          </PermissionsGuard>
          {/* <div className="col-span-2 mt-2 md:mt-0 shadow-lg rounded-lg">
            <CountGedTodosContainer />
          </div> */}
        </div>
        <div className="md:grid lg:grid-cols-6 md:grid-cols-4 md:gap-4 px-8 md:py-6 pb-6 gap-y-2">
          <QueryBoundaries loadingComponent={<Spinner />}>
            <RoleProtectedRoute requiredRoles={["read:user.subordinate"]}>
              <>
                {subordinatesData?.subordinates &&
                subordinatesData.subordinates.length > 0 ? (
                  <>
                    <h2 className="col-span-6 font-semibold md:mb-0 mb-2">
                      {t("my-team-expectations")}
                    </h2>
                    <div className="col-span-2  shadow-lg rounded-lg">
                      <CountTeamTodosContainer />
                    </div>

                    <GroupsGuard
                      requiredGroups={[
                        GROUP.CONCEPTION_INFOGRAPHISTE_RESPONSABLE,
                        GROUP.CONCEPTION_CONCEPTEUR_RESPONSABLE,
                        GROUP.CONCEPTION_ECONOMISTE_RESPONSABLE,
                      ]}
                    >
                      <PermissionsGuard
                        requiredRoles={["read:study-request.team.todo-list"]}
                      >
                        <div className="col-span-2 mt-2 md:mt-0 shadow-lg rounded-lg">
                          <CountTeamStudyRequestsTodosContainer
                            azureId={userInfo.azureId}
                          />
                        </div>
                      </PermissionsGuard>
                    </GroupsGuard>
                  </>
                ) : null}
              </>
            </RoleProtectedRoute>
          </QueryBoundaries>
        </div>
      </div>
      {currentStudyRequests && currentStudyRequests.length > 0 ? (
        <div className="px-8 py-6">
          <h2 className="col-span-6 mb-4 font-semibold">
            {t("my-study-requests")}
          </h2>
          <UserStudyRequests studyRequests={currentStudyRequests} />
        </div>
      ) : null}
      <div className="px-8 p-y-4">
        <EdvAndNextThreeMonths />
      </div>
      <div className="px-8 py-6">
        <h2 className="col-span-6 mb-4 font-semibold">{t("my-tools")}</h2>
        <ExternalAppsLinks />
      </div>
    </>
  )
}
