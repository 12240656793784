import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import SpoAxiosInterceptor from "core/interceptors/SpoInterceptor"
import {
  useGetOperationById,
  useListChildOperations,
} from "core/query-hooks/useOperations"
import { useIsOperationFrozen } from "core/spo/query-hooks/useOperation"
import { NextCommittee } from "features/committees/NextCommittee"
import { ContributorsPreview } from "features/contributors/ContributorsPreview"
import AgreementOperation from "features/operations/agreement/AgreementOperation"
import { ReservationGraphBar } from "features/operations/commercialization/ReservationGraphBar"
import ObtainingLoans from "features/operations/loans/ObtainingLoansBox"
import { SteeringProductionCollectiveDatesTable } from "features/operations/production/collective/steering/SteeringProductionCollectiveDatesTable"
import { SteeringProductionHousingDatesTable } from "features/operations/production/housing/steering/SteeringProductionHousingDatesTable"
import { OperationStep } from "features/operations/step/OperationStep"
import { ConsultationDate } from "features/spo/ConsultationDates"
import { DatesMontage } from "features/spo/DatesTable"
import { Finance } from "features/spo/Finance"
import i18next, { t } from "i18next"
import { useTranslation } from "react-i18next"
import { AiOutlineFundProjectionScreen } from "react-icons/ai"
import { MdDateRange } from "react-icons/md"
import { Link, NavLink, useParams } from "react-router-dom"
import {
  HOUSING_ESTATE_TYPE,
  RESIDENTIAL_TYPE,
  STATUS_ABANDONMENT,
  STATUS_ABANDONMENT_ON_GOING,
  TERTIARY_TYPE,
} from "shared/resources/operation.resources"
import { CustomAxiosError } from "shared/types/axios.type"
import { TChildOperation } from "shared/types/operation.type"
import DivStickyMenu from "./DivStickyMenu"
import OperationActions from "./OperationActions"

interface IOperationChildrenListProps {
  isLoading: boolean
  error: CustomAxiosError | null
  operationsChildren: TChildOperation[]
}

export function OperationChildrenList(props: IOperationChildrenListProps) {
  const { isLoading, error, operationsChildren } = props

  if (isLoading) return <Spinner />
  if (error)
    return (
      <p>
        {`${i18next.t("error")}`} : {error.message}
      </p>
    )

  switch (operationsChildren?.length) {
    case 0:
      return null
    case 1:
      return (
        <SpoAxiosInterceptor>
          <div>
            <ConsultationDate
              operationsIds={[operationsChildren[0]?.registrationNumber]}
              withOperationNameColumn
            />
          </div>
        </SpoAxiosInterceptor>
      )
    default:
      return (
        <SpoAxiosInterceptor>
          <div>
            <ConsultationDate
              operationsIds={operationsChildren.map(
                (op) => op.registrationNumber,
              )}
              withOperationNameColumn
            />
          </div>
        </SpoAxiosInterceptor>
      )
  }
}

export function OperationSteering() {
  const { id } = useParams()
  const { t: translation } = useTranslation()
  const { data: operation } = useGetOperationById(Number(id!))
  const { data: operationsChildren } = useListChildOperations(Number(id!))

  const registrationNumbers = operationsChildren?.map(
    (op) => op.registrationNumber,
  )
  const isOperationFrozen = registrationNumbers
    ? useIsOperationFrozen(registrationNumbers!)
    : null
  const isFrozen = isOperationFrozen?.every(
    (obj) => obj.data?.isFrozen === true,
  )

  const hasHousingChildren = operationsChildren?.some(
    (childOp) => childOp.type.id === HOUSING_ESTATE_TYPE.id,
  )
  const hasCollectiveChildren = operationsChildren?.some(
    (childOp) =>
      childOp.type.id === RESIDENTIAL_TYPE.id ||
      childOp.type.id === TERTIARY_TYPE.id,
  )

  if (!id) {
    return <p>{`${translation("project.noProjectFound")}`}</p>
  }

  return (
    <>
      <DivStickyMenu />
      {isFrozen && operation?.operationBase.step.id !== STATUS_ABANDONMENT ? (
        <div className="px-4 pt-2 pb-1">
          <div className="bg-red-300 py-2 rounded-lg text-center text-red-700">
            {t("operation-frozen")}
          </div>
        </div>
      ) : null}

      {operation?.operationBase.step.id === STATUS_ABANDONMENT_ON_GOING ? (
        <div className="px-4 pt-2 pb-1">
          <div className="bg-red-300 py-2 rounded-lg text-center text-red-700">
            {t("abandonment-on-going")}
          </div>
        </div>
      ) : null}

      <div className="px-4 lg:grid grid-rows-2 lg:grid-cols-6 lg:gap-4 grid-flow-row-dense pt-2">
        <OperationStep className="col-span-4 row-start-1 row-end-3 lg:mb-0 mb-4" />

        <div className="col-span-2 row-start-1 row-end-3 lg:mb-0 mb-4">
          <ContributorsPreview projectId={+id} />
        </div>

        <div className="col-span-6">
          <OperationActions />
        </div>
      </div>

      <div className="px-4 lg:grid grid-rows-1 lg:grid-cols-6 lg:gap-4 grid-flow-row-dense pt-4">
        <div className="col-span-2 lg:mb-0 mb-4">
          <ObtainingLoans />
        </div>

        <div className="col-span-2 lg:mb-0 mb-4">
          <Finance operationId={+id!} />
        </div>

        <div className="col-span-2">
          <AgreementOperation hasRedirection />
        </div>
      </div>

      <div className="px-4 lg:grid grid-rows-1 lg:grid-cols-6 lg:gap-4 grid-flow-row-dense pt-4">
        <div className="col-span-2 lg:mb-0 mb-4">
          <NextCommittee operationId={+id!} />
        </div>
        <div className="col-span-4">
          <DatesMontage operationId={+id!} />
        </div>
      </div>

      <div className="px-4 py-4 lg:grid lg:grid-cols-6 lg:gap-4 grid-flow-row-dense relative">
        {operationsChildren?.length && (
          <>
            <div className="col-span-6">
              <Box classNames="p-0">
                <div className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between">
                  <Link className="flex items-center" to="commercialization">
                    <AiOutlineFundProjectionScreen
                      size={20}
                      className="text-cyan-900 mr-1"
                    />
                    <h3 className="text-xs uppercase text-cyan-900 font-medium">
                      {t("reservations-rhythm")}
                    </h3>
                  </Link>
                  <div>
                    <TooltipWithHover
                      message={`${t("spo-informations")}`}
                      position="left"
                    >
                      <div className="text-cyan-900 uppercase text-xs">SPO</div>
                    </TooltipWithHover>
                  </div>
                </div>
                <Tabs isOperationChildren>
                  {operationsChildren!.map((op) => {
                    return (
                      <TabContent
                        title={`${op.registrationNumber}`}
                        key={op?.registrationNumber}
                      >
                        <ReservationGraphBar
                          registrationId={op.registrationNumber}
                        />
                      </TabContent>
                    )
                  })}
                </Tabs>
              </Box>
            </div>
            <div className="col-span-6 my-4 lg:my-0">
              <OperationChildrenList
                isLoading={false}
                error={null}
                operationsChildren={operationsChildren}
              />
            </div>
          </>
        )}
        {hasHousingChildren ? (
          <div className="col-span-6">
            <Box classNames="p-0">
              <div className="flex items-center justify-between border-gray-200 px-3.5 py-2">
                <NavLink to="technical" type="button">
                  <div className="flex items-center">
                    <MdDateRange size={20} className="text-cyan-900 mr-2" />
                    <h3 className="text-xs uppercase text-cyan-900 font-medium">
                      {t("production-follow-up-housing")}
                    </h3>
                  </div>
                </NavLink>
                <div className=" text-primary-dark">
                  <TooltipWithHover
                    position="left"
                    message={`${t("spo-informations")}`}
                  >
                    <div className="text-cyan-900 uppercase text-xs">SPO</div>
                  </TooltipWithHover>
                </div>
              </div>
              <SteeringProductionHousingDatesTable
                operationsChildren={operationsChildren || []}
              />
            </Box>
          </div>
        ) : null}
        {hasCollectiveChildren ? (
          <div className="col-span-6">
            <Box classNames="p-0">
              <div className="flex items-center justify-between border-gray-200 px-3.5 py-2">
                <NavLink to="technical" type="button">
                  <div className="flex items-center">
                    <MdDateRange size={20} className="text-cyan-900 mr-2" />
                    <h3 className="text-xs uppercase text-cyan-900 font-medium">
                      {t("production-follow-up-collective")}
                    </h3>
                  </div>
                </NavLink>
                <div className=" text-primary-dark">
                  <TooltipWithHover
                    position="left"
                    message={`${t("spo-informations")}`}
                  >
                    <div className="text-cyan-900 uppercase text-xs">SPO</div>
                  </TooltipWithHover>
                </div>
              </div>
              <SteeringProductionCollectiveDatesTable
                operationsChildren={operationsChildren || []}
              />
            </Box>
          </div>
        ) : null}
      </div>
    </>
  )
}
