import classnames from "classnames"
import * as React from "react"
import "./Box.scss"

interface IBoxProps {
  children: React.ReactNode
  classNames?: string
}

export default function Box({ children, classNames }: IBoxProps) {
  return (
    <div
      className={classnames("Box__Container shadow-lg rounded-lg", classNames)}
    >
      {children}
    </div>
  )
}
