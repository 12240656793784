import { initializeApp } from "@firebase/app"
import {
  getAuth,
  OAuthProvider,
  signInWithPopup,
  signOut,
} from "@firebase/auth"
import { getCheckNewUser } from "core/api/users"
import { getAdditionalUserInfo } from "firebase/auth"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const MICROSOFT_PROVIDER_ID = "microsoft.com"

const tenant = process.env.REACT_APP_FIREBASE_MS_TENANT
const microsoftProvider = new OAuthProvider(MICROSOFT_PROVIDER_ID)

if (tenant) {
  microsoftProvider.setCustomParameters({
    prompt: "consent",
    tenant,
  })
}

const login = async () => {
  return signInWithPopup(auth, microsoftProvider)
    .then(async (result) => {
      const idToken = await auth.currentUser?.getIdToken()
      const userDetails = getAdditionalUserInfo(result)
      if (userDetails?.isNewUser) {
        if (idToken) getCheckNewUser(idToken)
      }
      return idToken
    })
    .catch((error) => {
      console.log(error, "error when trying to login")
    })
}

const logout = async (): Promise<void> =>
  signOut(auth)
    .then((response) => response)
    .catch((error) => {
      throw error
    })

export { app, auth, login, logout }
