import { API } from "core/api/axios"
import { TStudyFeasibilityDashboard } from "shared/types/feasibility.type"
import { TDocumentCategory, TGedDocuments } from "shared/types/ged.type"
import { TLabelStringValue } from "shared/types/global.type"
import {
  TAddComment,
  TMutateStudyRequestDocuments,
  TStudyRequest,
  TStudyRequestsDashboardParams,
  TStudyRequestStatus,
  TStudyRequestTodo,
  TStudyRequestWithProject,
  TStudyRights,
  TUpdateStudyRequest,
  TUpdateStudyRequestStatus,
} from "shared/types/study.type"

async function updateStatus(
  studyRequest: TUpdateStudyRequestStatus,
  studyRequestId: number,
): Promise<TStudyRequestStatus> {
  const res = await API.post(
    `/study-requests/${studyRequestId}/status`,
    studyRequest,
  )
  return res.data
}

async function updateStudyRequest(
  requestId: number,
  data: TUpdateStudyRequest,
): Promise<TStudyRequest> {
  const res = await API.patch(`/study-request/${requestId}`, data)
  return res.data
}

async function listStudyRequests(projectId: number): Promise<TStudyRequest[]> {
  const res = await API.get(`/study-requests?project=${projectId}`)
  return res.data.studyRequests
}

async function listUserStudyRequests(
  userAzureId: string,
): Promise<TStudyRequestWithProject[]> {
  const res = await API.get(`/study-requests?creator=${userAzureId}`)
  return res.data.studyRequests
}

async function listStudyRequestUsers(): Promise<TLabelStringValue[]> {
  const res = await API.get(`/study-requests/users`)
  return res.data
}

async function getStudyRequestsRights(
  studyRequestId: number,
): Promise<TStudyRights> {
  const res = await API.get(`/study-requests/${studyRequestId}/rights`)
  return res.data
}

async function addCommentToStudyRequest(
  data: TAddComment,
  studyRequestId: number,
): Promise<void> {
  const res = await API.post(`/study-requests/${studyRequestId}/comment`, data)
  return res.data
}

async function updateStudyRequestDocuments(
  id: number,
  data: TMutateStudyRequestDocuments,
) {
  const res = await API.patch(`/study-requests/${id}/documents`, data)
  return res.data
}

async function listStudyRequestsTodos(
  userAzureId: string,
): Promise<TStudyRequest[]> {
  const res = await API.get(`/study-requests/${userAzureId}/todo-list`)
  return res.data.studyRequests
}

async function listTeamStudyRequestsTodos(
  userAzureId: string,
): Promise<TStudyRequestTodo[]> {
  const res = await API.get(`/study-requests/${userAzureId}/team/todo-list`)
  return res.data
}

async function checkStudyRequestsDocuments(id: number): Promise<string[]> {
  const res = await API.get(`/study-requests/${id}/check-documents`)
  return res.data
}

async function getStudyRequestDocument(id: number): Promise<TGedDocuments> {
  return typeof id === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/study-requests/${id}/documents`).then((res) => res.data)
}

async function getStudyRequestDocumentCategories(
  id: number,
): Promise<TDocumentCategory[]> {
  const res = await API.get(`/document-categories?studyRequestType=${id}`)
  return res.data
}

async function getStudyRequestDashboard(
  params: TStudyRequestsDashboardParams,
): Promise<TStudyFeasibilityDashboard[]> {
  const res = await API.get(
    `study-requests/dashboard?showAllReturned=${params.showAllReturned}&tab=${params.tab}${
      params.businessUnits && params.businessUnits.length > 0
        ? `&businessUnits=${params.businessUnits.join(",")}`
        : ""
    }${
      params.departments && params.departments.length > 0
        ? `&departments=${params.departments.join(",")}`
        : ""
    }${
      params.types && params.types.length > 0
        ? `&types=${params.types.join(",")}`
        : ""
    }${
      params.users && params.users.length > 0
        ? `&users=${params.users.join(",")}`
        : ""
    }${
      params.status && params.status.length > 0
        ? `&status=${params.status.join(",")}`
        : ""
    }`,
  )
  return res.data
}

async function deleteStudyRequest(id: number): Promise<TStudyRequest> {
  const res = await API.delete(`/study-request/${id}`)
  return res.data
}

export {
  addCommentToStudyRequest,
  checkStudyRequestsDocuments,
  deleteStudyRequest,
  getStudyRequestDashboard,
  getStudyRequestDocument,
  getStudyRequestDocumentCategories,
  getStudyRequestsRights,
  listStudyRequests,
  listStudyRequestsTodos,
  listStudyRequestUsers,
  listTeamStudyRequestsTodos,
  listUserStudyRequests,
  updateStatus,
  updateStudyRequest,
  updateStudyRequestDocuments,
}
