import { API } from "core/api/axios"
import { TAction } from "shared/types/action.type"
import { TProgress } from "shared/types/progress.type"

export async function getUserTodos(
  date: string,
): Promise<{ actions: TAction[]; progress: TProgress[] }> {
  return typeof date === "undefined"
    ? Promise.reject(new Error("Invalid date"))
    : API.get(
        `users/me/todo-list/?${date ? `deadline=${date}&` : ``}sort=deadline`,
      ).then((response) => response.data)
}

export async function getTeamTodos(
  date: string,
): Promise<{ actions: TAction[]; progress: TProgress[] }> {
  return typeof date === "undefined"
    ? Promise.reject(new Error("Invalid date"))
    : API.get(`users/me/team/todo-list/?deadline=${date}&sort=deadline`).then(
        (response) => response.data,
      )
}
