import { useMutation } from "@tanstack/react-query"
import deleteDocument from "core/api/documents"
import { CustomAxiosError } from "shared/types/axios.type"

function useDeleteDocument() {
  return useMutation<unknown, CustomAxiosError, number>({
    mutationFn: (id) => deleteDocument(id),
  })
}

export default useDeleteDocument
