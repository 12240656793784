import Spinner from "components/Spinner/Spinner"
import { t } from "i18next"
import { createContext, useEffect, useMemo, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { Navigate } from "react-router-dom"
import { auth } from "utils/firebase"

interface AuthProviderProps {
  children: React.ReactNode
}

const userInfo: User = {
  name: "",
  azureId: "",
}

const AuthContext = createContext<{
  getUserInfo: () => User
}>({
  getUserInfo: () => userInfo,
})

type User = {
  name?: string | null
  azureId: string
}

function AuthProvider({ children }: AuthProviderProps) {
  const [user, loading, error] = useAuthState(auth)
  const [userData, setUserData] = useState(userInfo)

  const getUserInfo = () => {
    return userData
  }

  const memoiziedContext = useMemo(() => ({ getUserInfo }), [userData])

  useEffect(() => {
    if (user)
      setUserData({
        name: user?.displayName,
        azureId: user?.providerData[0].uid!,
      })
  }, [user, setUserData])

  if (loading)
    return (
      <div className="flex justify-center h-screen items-center">
        <Spinner />
      </div>
    )

  if (error)
    return (
      <p>
        {`${t("error")}`} : {error.message}
      </p>
    )

  if (!user) return <Navigate to="/login" replace />

  return (
    <AuthContext.Provider value={memoiziedContext}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
