import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import Box from "components/Box/Box"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useListReThresold,
  useUpdateEnvironment,
} from "core/query-hooks/useEnvironments"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { IoEarthSharp } from "react-icons/io5"
import { PiTreeEvergreenDuotone } from "react-icons/pi"
import { NumericFormat } from "react-number-format"
import { toast } from "react-toastify"
import { TEnvironment, TUpdateEnvironment } from "shared/types/environment.type"
import * as yup from "yup"

type FormValues = {
  reThresoldId?: number | null
  bbcaLabel?: boolean
  ecologist?: boolean
  costOfWorkVrd?: number | null
  greenSpacesBudget?: number | null
  refugeArea?: boolean
  freshnessOasis?: boolean
  existingTrees?: number | null
  deletedTrees?: number | null
  replantedTrees?: number | null
  parkingsNumber?: number | null
  permeableParkings?: number | null
  roofTerraces?: number | null
  revegetationRoofTerraces?: number | null
  vegetatedFences?: boolean
  cbs?: number | null
}

export interface IEditEnvironmentProps {
  environment: TEnvironment
  setIsEdit: (value: boolean) => void
}

export default function EditEnvironement(props: IEditEnvironmentProps) {
  const { environment, setIsEdit } = props
  const queryClient = useQueryClient()
  const updateEnvironment = useUpdateEnvironment(environment.projectId)
  const { data: reThresold } = useListReThresold()

  const getReThresoldAllowableBbca = (reThresoldId: number) => {
    const selectedReThresold = reThresold?.find((rt) => rt.id === reThresoldId)
    return selectedReThresold ? selectedReThresold.allowableBbca : true
  }

  const validationSchema = yup.object().shape({
    reThresoldId: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    bbcaLabel: yup.boolean().test({
      name: "bbca-not-allowed",
      message: () => t("bbca-label-not-allowed"),
      test: (value, context) => {
        if (value === null || value === undefined || value === false)
          return true
        const { reThresoldId } = context.parent
        return getReThresoldAllowableBbca(reThresoldId)
      },
    }),
    ecologist: yup.boolean(),
    costOfWorkVrd: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    greenSpacesBudget: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .test({
        name: "max-green-spaces-budget",
        message: () => t("green-spaces-budget-over-total-budget-error"),
        test: (value, context) => {
          if (value === null || value === undefined) return true
          const { costOfWorkVrd } = context.parent
          if (typeof costOfWorkVrd !== "number") return false
          return value <= costOfWorkVrd
        },
      }),
    refugeArea: yup.boolean(),
    freshnessOasis: yup.boolean(),
    existingTrees: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    deletedTrees: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .test({
        name: "max-deleted-trees",
        message: () => t("deleted-trees-over-existing-trees-error"),
        test: (value, context) => {
          if (value === null || value === undefined) return true
          const { existingTrees } = context.parent
          if (typeof existingTrees !== "number") return false
          return value <= existingTrees
        },
      }),
    replantedTrees: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    parkingsNumber: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    permeableParkings: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .test({
        name: "max-permeable-parkings",
        message: () => t("permeable-parkings-over-total-parkings-error"),
        test: (value, context) => {
          if (value === null || value === undefined) return true
          const { parkingsNumber } = context.parent
          if (typeof parkingsNumber !== "number") return false
          return value <= parkingsNumber
        },
      }),
    roofTerraces: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    revegetationRoofTerraces: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .test({
        name: "max-revegetation-roof-terraces",
        message: () =>
          t("revegetation-roof-terraces-over-total-roof-terraces-error"),
        test: (value, context) => {
          if (value === null || value === undefined) return true
          const { roofTerraces } = context.parent
          if (typeof roofTerraces !== "number") return false
          return value <= roofTerraces
        },
      }),
    vegetatedFences: yup.boolean(),
    cbs: yup
      .number()
      .nullable()
      .min(0, t("zero-or-greater"))
      .max(1, t("lower-than-one"))
      .transform((curr, orig) => (orig === "" ? null : curr)),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      reThresoldId: environment.reThresold ? environment.reThresold.id : null,
      bbcaLabel: environment.bbcaLabel,
      ecologist: environment.ecologist,
      costOfWorkVrd: environment.costOfWorkVrd ?? null,
      greenSpacesBudget: environment.greenSpacesBudget ?? null,
      refugeArea: environment.refugeArea,
      freshnessOasis: environment.freshnessOasis,
      existingTrees: environment.existingTrees ?? null,
      deletedTrees: environment.deletedTrees ?? null,
      replantedTrees: environment.replantedTrees ?? null,
      parkingsNumber: environment.parkingsNumber ?? null,
      permeableParkings: environment.permeableParkings ?? null,
      roofTerraces: environment.roofTerraces ?? null,
      revegetationRoofTerraces: environment.revegetationRoofTerraces ?? null,
      vegetatedFences: environment.vegetatedFences,
      cbs: environment.cbs ?? null,
    },
  })

  const submitForm = (data: TUpdateEnvironment) => {
    updateEnvironment.mutate(data, {
      onError: (err) => {
        toast.error(
          `${t("environment-update-error")} : ${err.response?.data.message}`,
        )
      },
      onSuccess() {
        toast.success(`${t("environment-update-success")}`)
        queryClient.invalidateQueries({
          queryKey: ["getEnvironment", environment.projectId],
        })
        setIsEdit(false)
      },
    })
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  const [costOfWorkVrd, setCostOfWorkVrd] = useState<number | undefined | null>(
    environment.costOfWorkVrd,
  )
  const [greenSpacesBudget, setGreenSpacesBudget] = useState<
    number | undefined | null
  >(environment.greenSpacesBudget)

  useEffect(() => {
    setValue("costOfWorkVrd", costOfWorkVrd)
  }, [costOfWorkVrd])
  useEffect(() => {
    setValue("greenSpacesBudget", greenSpacesBudget)
  }, [greenSpacesBudget])

  return (
    <form onSubmit={onSubmit}>
      <div className="flex items-center justify-end">
        <Button
          onClick={() => setIsEdit(false)}
          size="small"
          mode="secondary"
          classNames="mr-2"
        >
          {t("cancel")}
        </Button>
        <PermissionsGuard requiredRoles={["update:environment"]}>
          <Button size="small" mode="primary" type="submit">
            {t("update")}
          </Button>
        </PermissionsGuard>
      </div>
      <div className="flex items-center justify-start pb-2 text-green-700">
        <IoEarthSharp size={38} color="008000" className="pr-3" />
        <h2 className="text-xl text-green-700">{`${t("carbon-climate-indicators")}`}</h2>
      </div>
      <Box>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-y-4 gap-x-12">
          <div className="Text__Field_Container">
            <label htmlFor="re-thresold">
              {`${t("re-thresold")}`}
              {reThresold && reThresold.length > 0 ? (
                <select
                  className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                  {...register("reThresoldId")}
                >
                  <option key="no-option-bank" value="" disabled>
                    {t("select-thresold")}
                  </option>
                  {reThresold.map((rt) => (
                    <option key={`${rt.id}-${rt.label}`} value={rt.id}>
                      {rt.label}
                    </option>
                  ))}
                </select>
              ) : null}
            </label>
            {errors.reThresoldId && (
              <span className="text-red-500 text-xs">
                {errors.reThresoldId.message}
              </span>
            )}
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="bbca-ce-labelisation">
              {`${t("bbca-ce-labelisation")}`}
              <div className="pt-3">
                <input
                  type="checkbox"
                  className="checkbox-custom"
                  {...register("bbcaLabel")}
                />
              </div>
            </label>
            {errors.bbcaLabel && (
              <span className="text-red-500 text-xs">
                {errors.bbcaLabel.message}
              </span>
            )}
          </div>
          <div className="md:col-span-2" />
        </div>
      </Box>

      <div className="flex items-center justify-start pt-2 pb-1 text-green-700">
        <PiTreeEvergreenDuotone size={40} color="008000" className="pr-3" />
        <h2 className="text-xl text-green-700">{`${t("biodiversity-risk-adaptation-indicators")}`}</h2>
      </div>
      <Box>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-y-4 gap-x-12">
          <div className="Text__Field_Container">
            <label htmlFor="ecologist">
              {`${t("ecologist")}`}
              <div className="pt-2">
                <input
                  type="checkbox"
                  className="checkbox-custom"
                  {...register("ecologist")}
                />
              </div>
            </label>
            {errors.ecologist && (
              <span className="text-red-500 text-xs">
                {errors.ecologist.message}
              </span>
            )}
          </div>
          <div className="md:col-span-3" />

          <div className="Text__Field_Container">
            <label htmlFor="cost-of-work-vrd">
              {`${t("cost-of-work-vrd")}`}
              <Controller
                name="costOfWorkVrd"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <NumericFormat
                    className="Text__Field"
                    thousandSeparator=" "
                    decimalSeparator="."
                    suffix=" €"
                    decimalScale={2}
                    getInputRef={ref}
                    {...rest}
                    onValueChange={(v) => {
                      setCostOfWorkVrd(v.floatValue ? v.floatValue : null)
                    }}
                  />
                )}
              />
            </label>
            {errors.costOfWorkVrd && (
              <span className="text-red-500 text-xs">
                {errors.costOfWorkVrd.message}
              </span>
            )}
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="green-spaces-budget">
              {`${t("green-spaces-budget")}`}
              <Controller
                name="greenSpacesBudget"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <NumericFormat
                    className="Text__Field"
                    thousandSeparator=" "
                    decimalSeparator="."
                    suffix=" €"
                    decimalScale={2}
                    getInputRef={ref}
                    {...rest}
                    onValueChange={(v) => {
                      setGreenSpacesBudget(v.floatValue ? v.floatValue : null)
                    }}
                  />
                )}
              />
            </label>
            {errors.greenSpacesBudget && (
              <span className="text-red-500 text-xs">
                {errors.greenSpacesBudget.message}
              </span>
            )}
          </div>
          <div className="md:col-span-2" />

          <div className="Text__Field_Container">
            <label htmlFor="refuge-area">
              {`${t("refuge-area")}`}
              <div className="pt-2">
                <input
                  type="checkbox"
                  className="checkbox-custom"
                  {...register("refugeArea")}
                />
              </div>
            </label>
            {errors.refugeArea && (
              <span className="text-red-500 text-xs">
                {errors.refugeArea.message}
              </span>
            )}
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="freshness-oasis">
              {`${t("freshness-oasis")}`}
              <div className="pt-2">
                <input
                  type="checkbox"
                  className="checkbox-custom"
                  {...register("freshnessOasis")}
                />
              </div>
            </label>
            {errors.freshnessOasis && (
              <span className="text-red-500 text-xs">
                {errors.freshnessOasis.message}
              </span>
            )}
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="vegetated-fences">
              {`${t("vegetated-fences")}`}
              <div className="pt-2">
                <input
                  type="checkbox"
                  className="checkbox-custom"
                  {...register("vegetatedFences")}
                />
              </div>
            </label>
            {errors.vegetatedFences && (
              <span className="text-red-500 text-xs">
                {errors.vegetatedFences.message}
              </span>
            )}
          </div>
          <div className="md:col-span-1" />

          <div className="Text__Field_Container">
            <label htmlFor="existing-trees">
              {`${t("existing-trees")}`}
              <input
                id="existingTrees"
                className="Text__Field"
                {...register("existingTrees")}
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </label>
            {errors.existingTrees && (
              <span className="text-red-500 text-xs">
                {errors.existingTrees.message}
              </span>
            )}
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="deleted-trees">
              {`${t("deleted-trees")}`}
              <input
                id="deletedTrees"
                className="Text__Field"
                {...register("deletedTrees")}
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </label>
            {errors.deletedTrees && (
              <span className="text-red-500 text-xs">
                {errors.deletedTrees.message}
              </span>
            )}
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="preserved-trees">
              {`${t("preserved-trees")}`}
              <p className="Color__Primary text-md">{t("deducted-value")}</p>
            </label>
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="replanted-trees">
              {`${t("replanted-trees")}`}
              <input
                id="replantedTrees"
                className="Text__Field"
                {...register("replantedTrees")}
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </label>
            {errors.replantedTrees && (
              <span className="text-red-500 text-xs">
                {errors.replantedTrees.message}
              </span>
            )}
          </div>

          <div className="Text__Field_Container">
            <label htmlFor="parkings-number">
              {`${t("parkings-number")}`}
              <input
                id="parkingsNumber"
                className="Text__Field"
                {...register("parkingsNumber")}
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </label>
            {errors.parkingsNumber && (
              <span className="text-red-500 text-xs">
                {errors.parkingsNumber.message}
              </span>
            )}
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="permeable-parkings-number">
              {`${t("permeable-parkings-number")}`}
              <input
                id="permeableParkings"
                className="Text__Field"
                {...register("permeableParkings")}
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </label>
            {errors.permeableParkings && (
              <span className="text-red-500 text-xs">
                {errors.permeableParkings.message}
              </span>
            )}
          </div>
          <div className="md:col-span-2" />

          <div className="Text__Field_Container">
            <label htmlFor="roof-terraces">
              {`${t("roof-terraces")}`}
              <input
                id="roofTerraces"
                className="Text__Field"
                {...register("roofTerraces")}
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </label>
            {errors.roofTerraces && (
              <span className="text-red-500 text-xs">
                {errors.roofTerraces.message}
              </span>
            )}
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="revegetation-roof-terraces">
              {`${t("revegetation-roof-terraces")}`}
              <input
                id="revegetationRoofTerraces"
                className="Text__Field"
                {...register("revegetationRoofTerraces")}
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </label>
            {errors.revegetationRoofTerraces && (
              <span className="text-red-500 text-xs">
                {errors.revegetationRoofTerraces.message}
              </span>
            )}
          </div>
          <div className="md:col-span-2" />

          <div className="Text__Field_Container">
            <label htmlFor="cbs">
              {`${t("cbs")}`}
              <input
                id="cbs"
                className="Text__Field"
                {...register("cbs")}
                type="number"
                step="0.01"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </label>
            {errors.cbs && (
              <span className="text-red-500 text-xs">{errors.cbs.message}</span>
            )}
          </div>
          <div className="md:col-span-3" />
        </div>
      </Box>
    </form>
  )
}
