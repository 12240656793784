import {
  TCollectifProductionDate,
  TLotissementsProductionDate,
} from "shared/types/spo/production.type"
import SPO_API from "../axios"

async function getLotissementsProductionDates(
  registrationId: string,
): Promise<TLotissementsProductionDate[]> {
  return typeof registrationId === "undefined"
    ? Promise.reject(new Error("error"))
    : SPO_API.get(
        `/operations/${registrationId}/dates-suivi-production/lotissement`,
      ).then((response) => response.data)
}

async function getCollectifsProductionDates(
  registrationId: string,
): Promise<TCollectifProductionDate[]> {
  return typeof registrationId === "undefined"
    ? Promise.reject(new Error("error"))
    : SPO_API.get(
        `/operations/${registrationId}/dates-suivi-production/collectif`,
      ).then((response) => response.data)
}

export { getLotissementsProductionDates, getCollectifsProductionDates }
