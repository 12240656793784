import ItemsCounter from "components/Counter/ItemsCounter"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { t } from "i18next"
import { BiTask } from "react-icons/bi"
import { Link } from "react-router-dom"
import useCountAlfredTodos from "./useCountAlfredTodos"

export default function CountAlfredTodosContainer() {
  const { error, isLoading, lateTodos, countIncomingTodos } =
    useCountAlfredTodos()

  return (
    <Link to={{ pathname: "/expected-tasks" }}>
      <TooltipWithHover message={t("alvis-tasks-number")} position="right">
        <ItemsCounter
          totalLateItems={lateTodos?.length || 0}
          totalItems={countIncomingTodos ?? 0}
          title="alvis"
          Icon={<BiTask fontSize={18} />}
          isLoading={isLoading}
          errorMessage={t("error-loading-todos")}
          error={error}
        />
      </TooltipWithHover>
    </Link>
  )
}
