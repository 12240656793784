import {
  TEdvDetail,
  TEdvDetailParams,
  TEdvHome,
  TEdvOverview,
  TEdvOverviewParams,
  TEdvSynthesis,
  TEdvSynthesisParams,
} from "shared/types/edv.type"
import { API } from "./axios"

export async function getEdvHome(businessUnitId?: string): Promise<TEdvHome[]> {
  const res = await API.get(
    `/edv-home${businessUnitId ? `?businessUnit=${businessUnitId}` : ""}`,
  )
  return res.data
}

export async function getEdvOverview(
  params: TEdvOverviewParams,
): Promise<TEdvOverview[]> {
  const res = await API.get(
    `/edv-overview?year=${params.year}&caType=${params.caType}${
      params.businessUnits && params.businessUnits.length > 0
        ? `&businessUnits=${params.businessUnits.join(",")}`
        : ""
    }${
      params.departments && params.departments.length > 0
        ? `&departments=${params.departments.join(",")}`
        : ""
    }${
      params.companies && params.companies.length > 0
        ? `&companies=${params.companies.join(",")}`
        : ""
    }${
      params.products && params.products.length > 0
        ? `&products=${params.products.join(",")}`
        : ""
    }
    `,
  )
  return res.data
}

export async function getEdvSynthesis(
  params: TEdvSynthesisParams,
): Promise<TEdvSynthesis[]> {
  const res = await API.get(
    `/edv-synthesis?year=${params.year}&caType=${params.caType}${
      params.businessUnits && params.businessUnits.length > 0
        ? `&businessUnits=${params.businessUnits.join(",")}`
        : ""
    }${
      params.departments && params.departments.length > 0
        ? `&departments=${params.departments.join(",")}`
        : ""
    }${
      params.companies && params.companies.length > 0
        ? `&companies=${params.companies.join(",")}`
        : ""
    }${
      params.products && params.products.length > 0
        ? `&products=${params.products.join(",")}`
        : ""
    }`,
  )
  return res.data
}

export async function getEdvDetail(
  params: TEdvDetailParams,
): Promise<TEdvDetail[]> {
  const res = await API.get(
    `/edv-detail?year=${params.year}&detail=${params.detail}&tab=${
      params.tab
    }&caType=${params.caType}${
      params.businessUnits && params.businessUnits.length > 0
        ? `&businessUnits=${params.businessUnits.join(",")}`
        : ""
    }${
      params.departments && params.departments.length > 0
        ? `&departments=${params.departments.join(",")}`
        : ""
    }${
      params.companies && params.companies.length > 0
        ? `&companies=${params.companies.join(",")}`
        : ""
    }${
      params.products && params.products.length > 0
        ? `&products=${params.products.join(",")}`
        : ""
    }`,
  )
  return res.data
}

export async function getEdvLastUpdateDate(): Promise<Date | null> {
  const res = await API.get(`/edv-last-update`)
  return res.data
}

export default {
  getEdvHome,
  getEdvOverview,
  getEdvSynthesis,
  getEdvDetail,
  getEdvLastUpdateDate,
}
