import { TDocumentCategory } from "shared/types/ged.type"

export const STATUS_DRAFT = 1
export const STATUS_CREATED = 2
export const STATUS_VALIDATED = 3
export const STATUS_REJECTED = 4
export const STATUS_PRIORIZED_AND_ASSIGNED = 5
export const STATUS_RETURNED = 6
export const STATUS_CANCELLED = 7
export const STATUS_PRIORIZATION_STANDBY = 8
export const STATUS_RESTITUTION_STANDBY = 9

export const TYPE_FEASIBILITY = 1
export const TYPE_COST_OF_WORK = 2
export const TYPE_INFOGRAPHY = 3

export const CONCEPTEUR_OCCUPATION_ID = 2
export const INFOGRAPHIST_OCCUPATION_ID = 4
export const ECONOMIST_OCCUPATION_ID = 3
export const TECHNICAL_MANAGER_OCCUPATION_ID = 7

export const FEASIBILITY_RESTITUTION_GED_CAT = {
  name: "01.03.07 - Livrable Faisabilité",
  id: 18,
}
export const ECONOMY_RESTITUTION_GED_CAT = {
  name: "01.03.08 - Livrable Economie",
  id: 19,
}
export const INFOGRAPHY_RESTITUTION_GED_CAT = {
  name: "01.03.09 - Livrable Infographie",
  id: 20,
}

export function sortByRequiredFields<T extends TDocumentCategory>(
  arr: T[] | undefined,
): T[] | undefined {
  return arr ? arr.sort((a) => (a.required ? -1 : 1)) : arr
}
